import { showToast, isLoading } from "../Features";
import { setEmployeeData } from "../Features/AuthSlice";
import { postRequest } from "./axios";

export const LoginUser = async (userData, actions, navigate, dispatch) => {
  dispatch(isLoading(true));
  try {
    const result = await postRequest("gettoken", userData);
    localStorage.setItem("token", result?.data?.data?.token);
    localStorage.setItem("userType", "emp");
    dispatch(setEmployeeData(result?.data?.data));
    actions.resetForm();
    navigate("/home", { replace: true });
  } catch (error) {
    dispatch(
      showToast({
        text: error?.response?.data ?? "Login Failed",
        severity: "error",
      })
    );
  } finally {
    dispatch(isLoading(false));
  }
};
