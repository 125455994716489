import React, { useState, useEffect } from "react";
import { FieldArray, Form, Formik } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { isLoading, showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { format } from "date-fns";
import dateFormat from "dateformat";
import { Loader } from "../../../../Components/FormComponent/Loader";

const opt = [{ label: "select", value: null }];

const severityOptions = [
  { label: "Critical/System Down", value: "Critical/System Down" },
  { label: "Significant Impact", value: "Significant Impact" },
  { label: "Normal/Minor Impact", value: "Normal/Minor Impact" },
  { label: "Low/Informational", value: "Low/Informational" },
];
const priorityOptions = [
  { label: "High", value: "High" },
  { label: "Low", value: "Low" },
  { label: "Normal", value: "Normal/Minor Impact" },
];

export const CreateHouseKeepingComp = ({ data, selId, formValues }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [location, setLocation] = useState([]);
  const [ticketType, setticketType] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Create House Keeping`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const returnPage = () => {
    navigate(-1);
  };
  const getWorkLocation = async () => {
    try {
      // dispatch(isLoading(true));
      // setLoading(true);
      const result = await getRequest(`PersonalSub/GetSubPersonalArea`);
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      // dispatch(isLoading(false));
      // setLoading(false);
    }
  };
  const getTicket = async () => {
    try {
      // dispatch(isLoading(true));
      // setLoading(true);
      const result = await getRequest(`HousekeepingTicket/GetTicketType`);
      // console.log("workLocation", result);
      let listData = [];
      result?.data?.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setticketType(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      // dispatch(isLoading(false));
      // setLoading(false);
    }
  };

  useEffect(() => {
    getWorkLocation();
    getTicket();
  }, []);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="formcustom twoColumn">
            <div>
              <LabelCustom labelName={"Ticket Type "} mandatory={true} />
              {/* <SelectForm
                name="typeID"
                options={ticketType}
                placeholder={"Select Ticket Type"}
              /> */}
              <SelectFormikMulti
                name="typeID"
                options={ticketType}
                placeholder={"Select Ticket Type"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Location"} mandatory={true} />
              <SelectForm
                name="locationID"
                options={location}
                placeholder={"Select Location"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Contact Number"} mandatory={true} />
              <InputCustomFormik
                maxLength={10}
                name="contactNo"
                type={"text"}
                placeholder={"Please Enter Contact Number"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Description"} mandatory={true} />
              <TextAreaFormik
                name="description"
                type={"text"}
                placeholder={"Please Enter Additional Info."}
              />
            </div>
            <div>
              <LabelCustom labelName={"Severity"} mandatory={true} />
              <SelectForm
                name="severity"
                options={severityOptions}
                placeholder={"Select Severity"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Priority"} mandatory={true} />
              <SelectForm
                name="priority"
                options={priorityOptions}
                placeholder={"Select Priority"}
              />
            </div>
            <div>
              <LabelCustom labelName={"Deadline"} />
              <DatePickerFormiks name="deadline" showTimeSelect />
            </div>

            <div>
              <LabelCustom labelName={"Upload File"} />
              <FileUploadFormik name={"attachment"} />
            </div>
          </div>
        </>
      )}
    </>
  );
};
