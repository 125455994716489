import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
// import "./Transfer.css";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { TimeAttendanceReports } from "./TimeAttendanceReports";
import { HiringReports } from "./HiringReports";
import { PrimarySourceReports } from "./PrimarySourceReports";
import { FinalTatReports } from "./FinalTatReports";
import { MonthlyAttendanceReports } from "./MonthlyAttendanceReports";
import { ApprovalReports } from "./ApprovalReports";
import { EmployeeAttendanceReports } from "./EmployeeAttendanceReports";
import Nodata from "../../../Assets/Nodata.svg";

const Reports = () => {
  const [tabIndex, setTabIndex] = useState("timeandattendance");
  const handleTabChange = (event, newTabIndex) => {
    navigate(`reports/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [featureMenu, setFeatureMenu] = useState(null);
  const { featureMenuList } = useSelector((state) => state.menu);
  const { LoggedInUser } = useSelector((state) => state.auth);
  useEffect(() => {
    if (featureMenuList) {
      featureMenuList.map((menu) => {
        if (
          menu[0]?.subMenuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "reports" &&
          menu[0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "reportskpi"
        )
          setFeatureMenu(menu);
        return null;
      });
    }
  }, [featureMenuList]);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("reportskpi/reports")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "reports");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `reports/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `reports/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, featureMenu]);

  return (
    <div className="moduleMainContainer">
      {LoggedInUser?.permissions?.includes(
        "Can Access Attendance Source Report?"
      ) ||
      LoggedInUser?.teamnames?.includes("HR Team") ||
      LoggedInUser?.isHRHead ||
      LoggedInUser?.isHRDirector ? (
        <>
          {" "}
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              {featureMenu &&
                featureMenu
                  ?.slice()
                  ?.filter((x) => x.isFeatureActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                  )
                  ?.map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        value={item?.featureName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}
                        label={
                          <span
                            className={`tabHeading ${
                              tabIndex ===
                                item?.featureName
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll("&", "")
                                  ?.toLowerCase() && "tabActive"
                            }`}
                          >
                            {item?.featureDisplayName}
                          </span>
                        }
                      />
                    );
                  })}
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {tabIndex === "dailyattendancereport" && (
            <>
              {" "}
              <TimeAttendanceReports title={"Daily Attendance Report"} />{" "}
            </>
          )}
          {tabIndex === "hiringreport" && (
            <>
              {" "}
              <HiringReports title={"Hiring Reports"} />{" "}
            </>
          )}
          {tabIndex === "primarysourcereport" && (
            <>
              {" "}
              <PrimarySourceReports title={"Primary Source Reports"} />{" "}
            </>
          )}
          {tabIndex === "finaltatreport" && (
            <>
              {" "}
              <FinalTatReports title={"Final Tat Reports"} />{" "}
            </>
          )}
          {tabIndex === "monthlyattendancereport" && (
            <>
              {" "}
              <MonthlyAttendanceReports
                title={"Monthly Attendance Reports"}
              />{" "}
            </>
          )}
          {tabIndex === "approvalreport" && (
            <>
              {" "}
              <ApprovalReports title={"Approval Reports"} />{" "}
            </>
          )}
          {tabIndex === "employeeattendancereport" && (
            <>
              {" "}
              <EmployeeAttendanceReports
                title={"Employee Attendance Reports"}
              />{" "}
            </>
          )}
        </>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"You don't have required permissions to view the content"}</h4>
        </div>
      )}
    </div>
  );
};
export { Reports };
