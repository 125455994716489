import * as Yup from "yup";

export const serviceTypeSchema = Yup.object({
  name: Yup.string().max(149," Name must be less than 150 characters").required("Please Enter Name"),
  categoryId: Yup.string().required("Please Select Service Category"),
  requestCategoryId: Yup.string().required("Please Select Request Category"),
  teamId: Yup.string().required("Please Select Team"),
  tatHigh: Yup.number().max(999, "Tat must be less than a 999 Hr ").required("Please Enter TAT"),
  tatMedium:  Yup.number().max(999, "Tat must be less than a 999 Hr ").required("Please Enter TAT"),
  tatLow:  Yup.number().max(999, "Tat must be less than a 999 Hr ").required("Please Enter TAT"),
});


