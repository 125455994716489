import * as Yup from "yup";
export const systemRolesSchema = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
  permissionSetId: Yup.string().required("Please Select Permission Set"),
});
export const schema = Yup.object({
  name: Yup.string()
    .max(50, " Name must be less than 50 characters")
    .required("Please Enter Name"),
  associatedEmployeeId: Yup.string().required(
    "Please Select Associated Employee "
  ),
});
export const teamsSchema = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
  spoc: Yup.string().required("Please Select SPOC"),
});
export const workflowSchemaGen = Yup.object({
  name: Yup.string()
    .max(149, " Name must be less than 150 characters")
    .required("Please Enter Name"),
  items: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .max(149, " Name must be less than 150 characters")
        .required("Please Enter Action Name"),

      actionType: Yup.string().required(
        "Please Select After The Workflow Is Triggered "
      ),

      approver: Yup.string().when("actionType", {
        is: (val) => val === "Initiate an Approval",
        then: () => Yup.string().required("Please Select Approver").nullable(),
      }),
      ifApproved: Yup.string().when("actionType", {
        is: (val) => val === "Initiate an Approval",
        then: () =>
          Yup.string().required("Please Select If Approved Then").nullable(),
      }),
      ifRejected: Yup.string().when("actionType", {
        is: (val) => val === "Initiate an Approval",
        then: () =>
          Yup.string().required("Please Select If Rejected Then").nullable(),
      }),
      tat: Yup.string()
        .nullable()
        .when("actionType", {
          is: (val) => val === "Initiate an Approval",
          then: () =>
            Yup.string().required("Please Enter TAT (In Hrs)").nullable(),
        }),
      emailViewers: Yup.string().when("actionType", {
        is: (val) => val === "Send an Email",
        then: () => Yup.string().required("Please Enter Email"),
      }),
    })
  ),
});
