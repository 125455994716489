import React from 'react';

import { ContactDirectoryTab } from './ContactDirectoryTab';
import { useEffect } from 'react';

const ContactDirectory = () => {
    useEffect(() => {
        document.title = `PeopleSol - Contact Directory`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
        
            
            <ContactDirectoryTab title='Contact Directory'/>
            
            
        </div>
    )
}

export { ContactDirectory }