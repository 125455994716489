/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../../Features";
import { postRequest } from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";

import "react-confirm-alert/src/react-confirm-alert.css";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
const AllGoals = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [boxView, setBoxView] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState([]);
  const [data, setData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState(null);
  const [reqData, setReqData] = useState({
    cycleStartDate: null,
    cycleEndDate: null,
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - All Goals `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("GoalSettings/List", reqData);
      if (
        result.data.count > 0 &&
        (LoggedInUser?.isHRHead ||
          LoggedInUser?.isHRDirector ||
          LoggedInUser?.hrbpDepartmentIds?.length ||
          LoggedInUser?.hrbpLocationIds?.length ||
          LoggedInUser?.teamnames?.includes("PMS"))
      ) {
        setTbodyData(JSON.parse(result.data.data));
        setCount(JSON.parse(result.data.count));
        calculateTotalPages(result.data.count);
      } else {
        setData("You Don't have required permissions to view the content");
        setTbodyData(false);
        setCount(0);      }

    } catch (error) {
      //dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      setTbodyData(false);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const location = useLocation();
 // console.log("tbody", data);
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("allgoals")) getListData();
  }, [location.pathname, reqData]);
  if (loading) return <Loader />;
  else
    return tbodyData ? (
      <div
      className="fullTableContainer"
      style={{ width: `${props.width}`, height: `${props.height}` }}
    >
      
      <div className="boxViewContainer ">
        <div className="homeCardsHolderContainer">
          <div className="homeDashboardCardHolder" key={1}>
            {tbodyData &&
              tbodyData?.map((item, index) => {
                return (
                  <div className="dashboardCard internalJobCard">
                    <div className="cardContainer cursor">
                      <div className="headingContainer">
                        <h3
                          style={{
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.Name}
                        </h3>
                      </div>
                      <div
                        className="jobDetailContainerHolder"
                        style={{ padding: "7px 0px 23px 11px" }}
                      >
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Cycle Start Date
                          </label>
                          <label>
                            {" "}
                            {item?.CycleStartDate &&
                              format(
                                new Date(item?.CycleStartDate),
                                "dd/MM/yyyy "
                              )}
                          </label>
                        </div>
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Cycle End Date
                          </label>
                          <label>
                            {" "}
                            {item?.CycleEndDate &&
                              format(
                                new Date(item?.CycleEndDate),
                                "dd/MM/yyyy "
                              )}
                          </label>
                        </div>
                      </div>
                      <div
                        className="applyJobButtonContainer "
                        style={{ flexWrap: "wrap", amrginLeft: "12px" }}
                      >
                        <button
                          onClick={() =>
                            navigate(`/pms/goals/allgoals/create/${item?.Id}`)
                          }
                          className="button primaryButton"
                        >
                          Goal Setting Summary
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      
      </div>
      <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
      </div>
    ) : (
      <div className="noDatastyle">
        <img
          src={Nodata}
          alt="No data"
          style={{ height: "150px", width: "250px" }}
        />
        <h4>{data ? data : "No Data Found"}</h4>
      </div>
    );
};
export { AllGoals };
