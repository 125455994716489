import React, { useState } from "react";
import "./OrgCompCard.css";
import { Tabs, Tab } from "@mui/material";
import { useEffect } from "react";

const OrgCompCard = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const data = [
    { name: "KPI Goal Setting", type: "2022" },
    { name: "KPI Goal Setting", type: "2022" },
    { name: "KPI Goal Setting", type: "2022" },
    { name: "KPI Goal Setting", type: "2022" },
    { name: "KPI Goal Setting", type: "2022" },
    { name: "KPI Goal Setting", type: "2022" },
    { name: "KPI Goal Setting", type: "2022" },
    { name: "KPI Goal Setting", type: "2022" },
  ];
  
  return (
    <div className="dashboardCardContentHolder">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#F7941D" } }}
        >
          <Tab
            label={
              <span
                className={`tabHeading ${
                  tabIndex === 0 && "tabActive tabActiveSecondary"
                }`}
              >
                My Action (20)
              </span>
            }
          />
          <Tab
            label={
              <span
                className={`tabHeading ${
                  tabIndex === 1 && "tabActive tabActiveSecondary"
                }`}
              >
                Team Action (30)
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <div className="approvalFrameTabData">
          <table className="teamAttendanceCard">
            <tr className="teamAttendanceTableHeader">
              <th></th>
              <th>Type</th>
              <th>title</th>
              <th className="TextAction">Action</th>
            </tr>
            {data.map((val, key) => {
              return (
                <tr key={key} className="teamAttendanceItemContainer">
                  <td></td>
                  <td className="teamAttendanceNameItem">{val.name}</td>
                  <td className="teamAttendanceItem">{val.type}</td>

                  <td className="teamAttendanceItem">
                    <div className="OrgActionsHolder">
                      <p className="OrgActionsText">Yet to Start</p>
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
      {tabIndex === 1 && (
        <div className="approvalFrameTabData">
          <table className="teamAttendanceCard">
            <tr className="teamAttendanceTableHeader">
              <th></th>
              <th>Type</th>
              <th>title</th>
              <th className="TextAction">Action</th>
            </tr>
            {data.map((val, key) => {
              return (
                <tr key={key} className="teamAttendanceItemContainer">
                  <td></td>
                  <td className="teamAttendanceNameItem">{val.name}</td>
                  <td className="teamAttendanceItem">{val.type}</td>
                  <td className="teamAttendanceItem">
                    <div className="OrgActionsHolder">
                      <p className="OrgActionsText">Yet to Start</p>
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
};

export { OrgCompCard };
