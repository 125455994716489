import React, { useState, useEffect } from 'react';
import { EventsTab } from './EventsTab';

const Events = () => {
    useEffect(() => {
        document.title = `PeopleSol - Events`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
   
    return (
        <div className="moduleMainContainer">
          
            <EventsTab title='Events'/>
           
        </div>
    )
}

export { Events }