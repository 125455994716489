import React from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useState } from "react";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";

const EventAttenderDetails = ({
  setEventAttenderDetailsActive,
  eventAttenderData,
}) => {
  const [attendList, setAttendList] = useState(true);
  const [notAttendList, setNotAttendList] = useState(false);
  const [mayAttendList, setMayAttendList] = useState(false);
  const [notResponseList, setNotResponseList] = useState(false);

  return (
    <div>
      <Popup
        firstClassName="eventActionPopupBox1"
        secondClassName="eventActionPopupBox2"
        contentClassName="likePopupContent"
        popupHeading={
          <div className="likeHeaderContainer">
            <div className="likeHeaderWrapper">
              <h2>
                Event attendees Details (
                {eventAttenderData?.attender?.length +
                  eventAttenderData?.notattender?.length +
                  eventAttenderData?.mayattender?.length}
                )
              </h2>
              <div className="likeListWithImgContainer">
                <div
                  className={`likeList ${attendList ? "active" : ""}`}
                  style={{ marginRight: "0px" }}
                  onClick={() => {
                    setAttendList(true);
                    setMayAttendList(false);
                    setNotAttendList(false);
                    setNotResponseList(false);
                  }}
                >
                  <p>Attend ({eventAttenderData?.attender?.length})</p>
                </div>
                <div
                  className={`likeListWithThumb ${
                    notAttendList ? "active" : ""
                  }`}
                  style={{ marginRight: "0px" }}
                  onClick={() => {
                    setAttendList(false);
                    setMayAttendList(false);
                    setNotAttendList(true);
                    setNotResponseList(false);
                  }}
                >
                  <p>Not Attend ({eventAttenderData?.notattender?.length})</p>
                </div>
                <div
                  className={`likeListWithClap ${
                    mayAttendList ? "active" : ""
                  }`}
                  style={{ marginRight: "0px" }}
                  onClick={() => {
                    setAttendList(false);
                    setMayAttendList(true);
                    setNotAttendList(false);
                    setNotResponseList(false);
                  }}
                >
                  <p>May Attend ({eventAttenderData?.mayattender?.length})</p>
                </div>
                <div
                  className={`likeListWithHeart ${
                    notResponseList ? "active" : ""
                  }`}
                  style={{ marginRight: "0px" }}
                  onClick={() => {
                    setAttendList(false);
                    setMayAttendList(false);
                    setNotAttendList(false);
                    setNotResponseList(true);
                  }}
                >
                  <p>No Response ({eventAttenderData?.notRespond?.length})</p>
                </div>
              </div>
            </div>
          </div>
        }
        content={
          <div className="totalLikePopupContainer">
            {attendList &&
              eventAttenderData?.attender?.map((d, i) => {
                return (
                  <div className="totalLikePopupList">
                    <div className="totalLikePopupImg">
                      <div
                        className={`${
                          d?.image
                            ? "socialProfilePicContainer"
                            : "socialCustomProfilePic"
                        }`}
                        style={{ margin: "0" }}
                      >
                        {!d?.image ? (
                          getUserInitials(d?.name)
                        ) : (
                          <img
                            className="socialProfilePic"
                            style={{ margin: "0" }}
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              profileImages +
                              d?.image
                            }
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = ProfileAvatar;
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="totalLikePopupText">
                      <h5>{d?.name}</h5>
                      <p>{d?.roleName}</p>
                    </div>
                  </div>
                );
              })}
            {notAttendList &&
              eventAttenderData?.notattender?.map((d, i) => {
                return (
                  <div className="totalLikePopupList">
                    <div className="totalLikePopupImg">
                      <div
                        className={`${
                          d?.image
                            ? "socialProfilePicContainer"
                            : "socialCustomProfilePic"
                        }`}
                        style={{ margin: "0" }}
                      >
                        {!d?.image ? (
                          getUserInitials(d?.name)
                        ) : (
                          <img
                            className="socialProfilePic"
                            style={{ margin: "0" }}
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              profileImages +
                              d?.image
                            }
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = ProfileAvatar;
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="totalLikePopupText">
                      <h5>{d?.name}</h5>
                      <p>{d?.roleName}</p>
                    </div>
                  </div>
                );
              })}
            {mayAttendList &&
              eventAttenderData?.mayattender?.map((d, i) => {
                return (
                  <div className="totalLikePopupList">
                    <div className="totalLikePopupImg">
                      <div
                        className={`${
                          d?.image
                            ? "socialProfilePicContainer"
                            : "socialCustomProfilePic"
                        }`}
                        style={{ margin: "0" }}
                      >
                        {!d?.image ? (
                          getUserInitials(d?.name)
                        ) : (
                          <img
                            className="socialProfilePic"
                            style={{ margin: "0" }}
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              profileImages +
                              d?.image
                            }
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = ProfileAvatar;
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="totalLikePopupText">
                      <h5>{d?.name}</h5>
                      <p>{d?.roleName}</p>
                    </div>
                  </div>
                );
              })}
            {notResponseList &&
              eventAttenderData?.notRespond?.map((d, i) => {
                return (
                  <div className="totalLikePopupList">
                    <div className="totalLikePopupImg">
                      <div
                        className={`${
                          d?.image
                            ? "socialProfilePicContainer"
                            : "socialCustomProfilePic"
                        }`}
                        style={{ margin: "0" }}
                      >
                        {!d?.image ? (
                          getUserInitials(d?.name)
                        ) : (
                          <img
                            className="socialProfilePic"
                            style={{ margin: "0" }}
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              profileImages +
                              d?.image
                            }
                            alt="Profile"
                            onError={(e) => {
                              e.target.src = ProfileAvatar;
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="totalLikePopupText">
                      <h5>{d?.name}</h5>
                      <p>{d?.roleName}</p>
                    </div>
                  </div>
                );
              })}
            {eventAttenderData?.attender?.length +
              eventAttenderData?.notattender?.length +
              eventAttenderData?.mayattender?.length <=
              0 && (
              <div className="noLike">
                <h3>No Action Available!!</h3>
              </div>
            )}
          </div>
        }
        handleClose={() => {
          setEventAttenderDetailsActive(false);
        }}
      />
    </div>
  );
};

export default EventAttenderDetails;
