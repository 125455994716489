import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateAnnouncementsComp } from "../FormComp/CreateAnnouncementsComp";

const CreateAnnouncements = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Announcement`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [detailsform, setDetailsform] = useState({});

  const { state } = useLocation();
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState();
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Announcements/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Announcements/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequestForm("Announcements/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      returnPage();
    }
  };
  const initialvalue = {
    title: "",
    description: "",
    _locationIds: [],
    _departmentIds: [],
    upload: null,
    attachment: null,
  };
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please Enter Tittle"),
    description: Yup.string()
      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
  });

  const onSubmit = (values) => {
    let _departmentIdsobj = {};
    let _locationIdsobj = {};
    if (values?._departmentIds?.length)
      values?._departmentIds?.forEach((item, index) => {
        _departmentIdsobj[`_departmentIds[${index}]`] = item;
      });
    if (values?._locationIds?.length)
      values?._locationIds?.forEach((item, index) => {
        _locationIdsobj[`_locationIds[${index}]`] = item;
      });

    const formData = new FormData();
    formData.append("Id", data ?? 0);
    formData.append("title", values?.title);
    formData.append("description", values?.description);
    Object.entries(_departmentIdsobj).forEach(([key, value]) => {
      formData.append(key, value);
    });
    Object.entries(_locationIdsobj).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("isDataActive", true);
    if (formValue?.upload === null) {
      formData.append("attachment", null);
    } else {
      if (typeof formValue?.upload === "object") {
        formData.append("upload", values?.upload);
      } else {
        formData.append("attachment", values?.upload);
      }
    }
    data ? Edit(formData) : createPageData(formData);
  };
  const chooseFormValues = (values) => {
    setFormValue(values);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Announcements" : "Create Announcements"}
      ButtonLabel={data ? "Edit Announcements" : "Create Announcements"}
      loading={loading}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              title: detailsform?._announcements?.title,
              description: detailsform?._announcements?.description,
              _locationIds: detailsform?._announcements?._locationIds,
              _departmentIds: detailsform?._announcements?._departmentIds,
              upload: detailsform?.posts?.attachment,
              attachment: detailsform?.posts?.attachment,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateAnnouncementsComp data={detailsform} />}
    />
  );
};

export { CreateAnnouncements };
