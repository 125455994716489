import React from 'react';
import {  StatusTab } from './StatusTab';

const Status = () => {
    return (
        <div className="moduleMainContainer">
            <StatusTab title="Status"/>
        </div>
    )
}

export { Status }