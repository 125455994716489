/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import "./TTTimesheet.css";
import { IconButton } from "@mui/material";
import { TimeSheetTable } from "../../../Components/index";
import { DateRange } from './DateRange';
import { HeaderCountDetails } from './HeaderCountDetails';
import { useEffect } from 'react';

const TTTimesheet = (props) => {

    const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

 

    const Countries = [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 }
  ];

    const actionData = [{
      img: <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z" fill="#0DB53C"/>
      <path d="M6.93986 15.4946C6.99241 15.4421 7.7807 14.4962 8.9894 13.3926C9.09451 13.2874 9.19961 13.2874 9.35727 13.2874C11.1441 13.2874 13.7717 13.2874 15.5585 13.2874C16.0314 13.2874 16.3467 12.9721 16.3467 12.4992C16.3467 10.292 16.3467 8.0322 16.3467 5.77245C16.3467 5.35203 16.0314 4.98416 15.5585 4.98416C12.3528 4.98416 9.14706 4.98416 5.94136 4.98416C5.46839 4.98416 5.15308 5.29948 5.15308 5.77245C5.15308 7.97965 5.15308 10.2394 5.15308 12.4992C5.15308 12.9196 5.46839 13.2874 5.94136 13.2874C6.09902 13.2874 6.30923 13.2874 6.46689 13.2874C6.7822 13.2874 6.93986 13.4977 6.93986 13.7604C6.93986 14.2859 6.93986 14.7589 6.93986 15.2844C6.93986 15.337 6.93986 15.3895 6.93986 15.4946Z" stroke="#0DB53C" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="7.78991" cy="9.33009" r="0.729975" fill="#0DB53C"/>
      <circle cx="10.7499" cy="9.33009" r="0.729975" fill="#0DB53C"/>
      <circle cx="13.7098" cy="9.33009" r="0.729975" fill="#0DB53C"/>
      </svg>
      
      , action: "Task Status Update",
        
  }, {
      img: <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z" fill="#FB7158"/>
      <path d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z" stroke="#FB7158" strokeWidth="0.698594" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      
      , action: "Update Task"
  }]

  const theadData = ["Customer - Project - Task - Sub Task",
  "Billing",
  "MONDAY 14 NOV",
  "TUESDAY 15 NOV",
  "WEDNESDAY 16 NOV",
  "THURSDAY 17 NOV",
  "FRIDAY 18 NOV",
  "SATURDAY 19 NOV",
  "SUNDAY 20 NOV",
  "Total Hours"];
  const tfooterData = ["Total",
  "",
  "16:45",
  "16:45",
  "16:45",
  "16:45",
  "16:45",
  "0:0",
  "0:0",
"170:40"];
const tbodyData = [
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  {
      id: "1",
      items: ["AESS - PeopleSol - PeopleSol Product Design - HRMS Time Sheet",
          "Billable",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "4:45",
          "-",
          "-",
          "1420.5"
        ],
  },
  
];
useEffect(() => {
  document.title = `PeopleSol - TTImeSheet`;
  return () => {
    document.title = 'PeopleSol';
  };
}, []);

  return (
    
                <>
                    <div className="tableHeaderContainer headerContentHolder">
                      <DateRange/>

                      <div className='headerCountContainer'>
                      <HeaderCountDetails title="Total" styles={{color:'#6AB1BF'}} count="20" />
                      <div className="timesheetVerticalLine"></div>
                      <HeaderCountDetails title="Billable" styles={{color:'#DA5DB6'}} count="17" />
                      <div className="timesheetVerticalLine"></div>
                      <HeaderCountDetails title="Non-Billable" styles={{color:'#8A88ED'}} count="03" />
                      </div>

                    </div>
                    <TimeSheetTable theadData={theadData} tbodyData={tbodyData} actionData={actionData} tfooterData={tfooterData}/>
                    <div className="horizontalLine"></div>
                    <div className="tableFooterContainer timesheetFooter">
                       

                        <IconButton>
                        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.0372925" y="0.862793" width="14.7453" height="14.7453" fill="#0DB53C"/>
                        </svg>
                        </IconButton>
                    
                        <label>Weekly Offs</label>
                        <button className='createFooterButton2 addProjectButton buttonClass' type="submit">Submit</button>
                    </div>

                </>
  )
}

export  {TTTimesheet};