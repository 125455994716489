/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import { StepsWorkflows } from "../StepsWorkflows";
import { useEffect } from "react";

const Proioroption = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
export const CreateHrdeskComp = ({ formValues }) => {
  const [optionSer, setOptionSer] = useState([]);
  const [optionReq, setOptionReq] = useState([]);
  const [option, setOption] = useState([]);
  const [location, setLocation] = useState([]);
  const [workflowData, setWorkflowData] = useState([]);

  const dispatch = useDispatch();

  const getWorkflowData = async () => {
    try {
      const result = await getRequest(
        `HrHelpdesks/Workflow?ID=${formValues.TypeId}`
      );

      setWorkflowData(result?.data?.data);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create HR Desk`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getLocation = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionSer = async () => {
    try {
      const result = await getRequest("HrHelpdesks/GetTicketTypes");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        `HRHelpdeskWorkflows/GetHRRequestTypes?RequestCategoryId=${formValues?.RequestCategoryId}&&TypeId=${formValues?.TypeId}`
      );

      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      formValues.RequestTypeId = listData[0]?.value;
      setOptionReq(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOption = async () => {
    try {
      const result = await getRequest(
        `HRHelpdeskRequestCategories/GetHRRequestCategories/${formValues.TypeId}`
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      formValues.RequestCategoryId = listData[0]?.value;

      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  // //console.log("option",option)
  React.useEffect(() => {
    getOptionSer();
    getLocation();
  }, []);
  React.useEffect(() => {
    if (formValues?.TypeId) {
      getOption();
      getWorkflowData();
    }
  }, [formValues?.TypeId]);
  React.useEffect(() => {
    if (formValues?.RequestCategoryId) {
      getOptionReq();
    }
  }, [formValues?.RequestCategoryId]);
  return (
    <div
      style={
        formValues?.TypeId === ""
          ? { display: "block" }
          : {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }
      }
    >
      <div style={{ flex: 6 }} className="formcustom">
        <div>
          <LabelCustom
            labelName={"Select HR Desk Service Type"}
            mandatory={true}
          />
          <SelectForm
            name={"TypeId"}
            d
            options={optionSer}
            placeholder={" Select  Service Type"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Request Category "} mandatory={true} />
          <SelectForm
            onDropdownOpen={
              formValues?.TypeId === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select service type",
                        severity: "error",
                      })
                    )
                : () => {}
            }
            // value={2}
            name={"RequestCategoryId"}
            options={option}
            placeholder={" Select Request Category "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Request Type"} mandatory={true} />
          <SelectForm
            name={"RequestTypeId"}
            onDropdownOpen={
              formValues?.TypeId === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select  service type",
                        severity: "error",
                      })
                    )
                : () => {}
            }
            options={optionReq}
            placeholder={" Select Request Type"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Location"} mandatory={true} />
          <SelectForm
            name="LocationId"
            options={location}
            placeholder={" Select Location "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Priority "} mandatory={true} />
          <SelectForm
            name="Priority"
            options={Proioroption}
            placeholder={" Select Priority "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Upload File(s)"} />
          <FileUploadFormik name="FileUpload" placeholder={" Select File"} />
        </div>
        <div>
          <LabelCustom labelName={"Description "} mandatory={true} />
          <TextAreaFormik
            maxlength="500"
            style={{ height: "100px" }}
            name="Description"
            placeholder={" Enter Description"}
          />
        </div>
      </div>
      <div
        style={{
          flex: 4,
          marginTop: "50px",
          background: "var(--primary-hover)",
        }}
      >
        {formValues?.TypeId === "" ? (
          ""
        ) : (
          <StepsWorkflows workflowData={workflowData} />
        )}
      </div>
    </div>
  );
};
