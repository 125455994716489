import React from 'react'
import { Holiday } from '../../HRMS/Components/Time & Attendance/Holiday';
import { useEffect } from 'react';

const ServiceHoliday = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Holiday Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      <Holiday styles={newHeight}/>
        
    </div>
  )
}

export {ServiceHoliday};
