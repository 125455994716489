import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../../Features";
import { postRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ExceptionForm } from "./Form/ExceptionForm";
import * as Yup from "yup";
import { useEffect } from "react";
const CreateException = (props) => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const data = state?.id;
  const [detailsform, setDetailsform] = useState({});
  const getInitalValues = () => {
    let returnArray = [];
    data
      ? detailsform?.expectionStatements?.map((item) => {
          return returnArray.push({
            ExceptionGroupId: item?.exceptionGroupId,
            ExceptionStatementId: item?.exceptionStatementId,
          });
        })
      : (returnArray = [
          {
            ExceptionGroupId:
              formValues?.ExceptionGroupId !== ""
                ? formValues?.ExceptionGroupId
                : "",
            ExceptionStatementId:
              formValues?.ExceptionStatementId !== ""
                ? formValues?.ExceptionStatementId
                : "",
          },
        ]);

    return returnArray;
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Exception`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const initialvalue = {
    PreviousExceptionId: null,
    ExceptionCatalogueId: data
      ? detailsform?.exceptionRequests?.exceptionCatalogueId
      : "",
    ExceptionType: data ? detailsform?.exceptionRequests?.exceptionType : "",
    AssetCategoryId: data
      ? detailsform?.exceptionRequests?.assetCategoryId
      : null,
    DeviationDescription: data
      ? detailsform?.exceptionRequests?.deviationDescription
      : "",
    FromDate: detailsform?.exceptionRequests?.fromDate
      ? new Date(detailsform?.exceptionRequests?.fromDate)
      : "",
    TillDate: detailsform?.exceptionRequests?.tillDate
      ? new Date(detailsform?.exceptionRequests?.tillDate)
      : "",
    acceptTerms: data ? true : false,
    List: getInitalValues(),
  };
  const validationSchema = Yup.object({
    ExceptionCatalogueId: Yup.string().required(
      "Please Select Exception Catalogue"
    ),
    ExceptionType: Yup.string().required("Please Select Exception Type"),
    AssetCategoryId: Yup.string()
      .nullable()
      .when("Exception Type", {
        is: (val) => {
          return val === "IT Infra";
        },
        then: () =>
          Yup.string().required("Please Select AssetCategory").nullable(),
      }),
    DeviationDescription: Yup.string()
      .max(150, "Must be 150 characters or less")
      .required("Please Enter Description"),
    FromDate: Yup.string().required("Please Select From Date "),
    TillDate: Yup.string().required("Please Select Till Date "),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions to proceed further"
    ),
    List: Yup.array().of(
      Yup.object().shape({
        ExceptionGroupId: Yup.string().required(
          "Please select Exception Group"
        ),
        ExceptionStatementId: Yup.string().required(
          "Please select Exception Statement"
        ),
      })
    ),
  });

  const postFormData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("ExceptionRequests/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const EditPageData = async (values) => {
    try {
      setLoading(true);
      const res = await postRequest("ExceptionRequests/Edit", values);
      if (res) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    const edit = {
      ...values,
      id: detailsform?.exceptionRequests?.id,
    };
    const create = {
      ...values,
      id: 0,
    };
    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    create["FromDate"] = new Date(values.FromDate - tzoffset)
      .toISOString()
      .slice(0, -1);
    create["TillDate"] = new Date(values.TillDate - tzoffset)
      .toISOString()
      .slice(0, -1);
    data ? EditPageData(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      ButtonLabel={
        data ? "Update Exception Request " : "Create Exception Request "
      }
      FormHeading={
        data ? "Edit Exception Request " : "Create Exception Request "
      }
      chooseFormValues={chooseFormValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <ExceptionForm
          formValues={formValues}
          data={data}
          setDetailsform={setDetailsform}
        />
      }
    />
  );
};

export { CreateException };
