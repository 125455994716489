import React from "react";
import { Formik, Form } from "formik";
import { CheckboxFormiks } from "../../../../Components/FormComponent/CheckboxFormiks";

const LanguageSelectionPage = () => {
  // Define initial form values
  const initialValues = {
    english: false,
    spanish: false,
    // Add more languages if needed
  };

  // Function to handle form submission
  const handleSubmit = (values) => {
    console.log("Selected languages:", values);
  };

  return (
    <div
    style={{ width: "100%",height:'100%' }}>
      
      <h6 className="langHead">Select Language</h6>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
    
          <Form>
            <CheckboxFormiks
              name="english"
              labelName="English"
            />
            <CheckboxFormiks
              name="spanish"
              labelName="Spanish"
            />
            <CheckboxFormiks
              name="french"
              labelName="French"
            />
            <CheckboxFormiks
              name="hindi"
              labelName="Hindi"
            />
          </Form>
      
      </Formik>
    </div>
  );
};

export default LanguageSelectionPage;
