/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { CreateFacilitiesComp } from "./Form/CreateFacilitiesComp";

const CreateFacilities = (props) => {
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [detailsform, setDetailsform] = useState({});
  const data = state?.id;
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`FacilityRequests/Details?ID=${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest("FacilityRequests/CreateFacilityRequest", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };
  const EditPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest("FacilityRequests/EditFacilityRequest", values);
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Facilities`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const initialvalue = {
    type: data?detailsform.request?.type:"",
    facilityCategoryId: data?detailsform.request?.facilityCategoryId:"",
    fromDate: data?detailsform?.request?.fromDate==null? null: new Date(detailsform?.request?.fromDate):null,
    toDate: data?detailsform?.request?.toDate==null? null: new Date(detailsform?.request?.toDate):null,
    reason: data?detailsform.request?.reason:"",
  };
  const validationSchema = Yup.object({
    facilityCategoryId: Yup.string().required("Please Select  Facility Type"),
    type: Yup.string().required("Please Select  Type"),
    fromDate:Yup.string().nullable().when("type", {
      is: (val) =>{ return (val ===  "Temporary")},
      then: ()=> Yup.string().required("Please Select Date")
    }),
    toDate:Yup.string().nullable().when("type", {
      is: (val) =>{ return (val ===  "Temporary")},
      then: ()=> Yup.string().required("Please Select Date")
    }),
      reason:Yup.string()
    .max(499, "Must be less than 500 characters")
    .required("Please Enter Reason"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
    };
    const payload = {
      id: data ? data : 0,
    };
    if (values) {
      var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
      payload["fromDate"] = new Date(values.fromDate - tzoffset)
        .toISOString()
        .slice(0, -1);
      payload["toDate"] = new Date(values.toDate - tzoffset)
        .toISOString()
        .slice(0, -1);
        payload["reason"]=values?.reason
        payload["type"]=values?.type
        payload["facilityCategoryId"]=values?.facilityCategoryId

    }
    data ? EditPageData(payload) : createPageData(payload);
    
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
    loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Facility Request" : "Create Facility Request"}
      ButtonLabel={data ? "Update Facility Request" : "Create Facility Request"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateFacilitiesComp formValues={formValues} />}
    />
  );
};

export { CreateFacilities };
