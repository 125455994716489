export const selfRatingObtain = (total) => {
    if (total >= 111 && total <= 120) {
      return 5;
    }
    if (total >= 101 && total <= 110) {
      return 4;
    }
    if (total >= 91 && total <= 100) {
      return 3;
    }
    if (total >= 81 && total <= 90) {
      return 2;
    }
    if (total < 80) {
      return 1;
    }
  };