/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import { auto } from "@popperjs/core";
import React, { useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { GoalGettingSummary } from "../Goals/GoalGettingSummary";
import { ServiceHRDesk } from "../../../Services/SidebarComponents/ServiceHRDesk";
import { ServiceDash } from "./ServiceDash";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
export const ReviewDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
 const {state,pathname}=useLocation()
 const items=state?.item
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSGoalReviews/ReviewSummary/?ID=${selectedId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Review Dashboard`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);
  //console.log("items",items)
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div
          className="modulecontGoal"
          style={{ overflow: "auto" }}
        >
          <div className="headingContainer">
            <div style={{ display: "flex" }}>
              <IconButton onClick={() => navigate(-1)}>
                <svg
                  width="20"
                  height="8"
                  viewBox="0 0 20 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                    fill="#1296B0"
                  />
                </svg>
              </IconButton>
              <h4>{items?.Name}</h4>
            </div>
          </div>
          <div
            className="tabularMasterDetailsPageBody"
            style={{ background: "white" ,padding:0}}
          >
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Dashboard
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Summary
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {tabIndex === 0 && (
              <div
                
              >
                <div
                  style={{
                    flexDirection: "row",
                    boxShadow: "none",
                    padding: "24px"
                  }}
                >
                  <h5>Review Pending Status</h5>
                </div>
                <div className="prpstatus" style={{justifyContent:'center'}}>
                  <div className="prrevdash ltblue" style={{flex:1}}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div className="horizontalLinePrimary"></div>
                          <div>Self Review</div>
                          <div>Pending</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">{data?.reviewSummery?.pendingSelfReview}</div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders" >
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                        
                          value={
                            data?.reviewSummery?.pendingSelfReviewPercentage
                          }
                          styles={buildStyles({
                            pathColor: "var(--primary)",
                            fontSize: "15px",
                            textColor: "var(--primary)",

                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={10}
                          text={`${data?.reviewSummery?.pendingSelfReviewPercentage}%`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="prrevdash ltpink" style={{flex:1}}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div
                            className="horizontalLinePrimary"
                            style={{
                              backgroundColor: "rgba(222, 100, 161, 1)",
                            }}
                          ></div>
                          <div className="headtexts">Appraiser Review </div>
                          <div>Pending</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">{data?.reviewSummery?.apprisarReviewPending}</div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders" >
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                          value={
                            data?.reviewSummery?.apprisarReviewPendingPercentage
                          }
                          styles={buildStyles({
                            pathColor: "rgba(222, 100, 161, 1)",
                            textColor: "rgba(222, 100, 161, 1)",
                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={5}
                          text={`${data?.reviewSummery?.apprisarReviewPendingPercentage}%`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="prrevdash ltpurple" style={{flex:1}}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div
                            className="horizontalLinePrimary"
                            style={{
                              backgroundColor: "rgba(138, 136, 237, 1)",
                            }}
                          ></div>
                          <div>Final Review</div>
                          <div>Pending</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">{data?.reviewSummery?.pendingReviewerReview}</div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders" >
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                          value={
                            data?.reviewSummery?.pendingReviewerReviewPercentage
                          }
                          styles={buildStyles({
                            pathColor: "rgba(138, 136, 237, 1)",
                            textColor: "rgba(138, 136, 237, 1)",
                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={6}
                          text={`${data?.reviewSummery?.pendingReviewerReviewPercentage}%`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="prrevdash ltpeach" style={{flex:1}}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className="prreviewBox">
                        <div className="headtexts">
                          <div
                            className="horizontalLinePrimary "
                            style={{ backgroundColor: "rgba(251, 113, 88, 1)" }}
                          ></div>
                          <div> Review</div>
                          <div>Completed</div>
                        </div>
                        <div>
                          <div className="heademp">No. of Employee</div>
                          <div className="valheademp">{data?.reviewSummery?.reviewCompleted}</div>
                        </div>
                      </div>
                    </div>
                    <div className="frameCountHolders" >
                      <div className="leaveProgressContainer">
                        <CircularProgressbarWithChildren
                          className="BessHolderas"
                          maxVal={100}
                          value={data?.reviewSummery?.reviewCompletedPercentage}
                          styles={buildStyles({
                            pathColor: "rgba(251, 113, 88, 1)",
                            textColor: "rgba(251, 113, 88, 1)",
                            trailColor: "white",
                          })}
                          minVal={0}
                          sWidth={5}
                          text={`${data?.reviewSummery?.reviewCompletedPercentage}%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    flexDirection: "row",
                    boxShadow: "none",
                    padding: "26px",
                  }}
                >
                  <h5>Rating Status</h5>
                </div>

                <div style={{ display: "flex" }}>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"

                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="18px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                  <div className="cardRatingEmp">
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>{" "}
                    <div
                      className="starholderpms"
                      onClick={() =>
                        navigate("/pms/goals/teamgoals/details/specificdetail")
                      }
                    >
                      <div>
                        <StarRatings
                          starEmptyColor={"#BFBFBF"}
                          starRatedColor={"var(--secondary)"}
                          starDimension="15px"
                          starSpacing="2px"
                          rating={5}
                        />
                      </div>
                      <label
                        className="jobDesignation"
                        style={{ color: "var(--primary)", fontSize: "15px" }}
                      >
                        100 Employee
                      </label>
                    </div>
                  </div>
                </div> */}
                <div
                  style={{
                    padding: "24px",
                    margin: "24px",
                    boxShadow: "rgba(0, 0, 0, 0.04) 0px 1.89445px 10px",
                  }}
                >
                  <table className="tableNoti tabless ">
                    <thead
                      className="headNoti"
                      style={{ borderBottom: "1px solid #1296b0 !important" }}
                    >
                      <tr>
                        <td>Department Name</td>
                        <td>Self Review</td>
                        <td>Appraiser Review</td>
                        <td>Final Review</td>
                        <td> Review Completed</td>
                        <td>Total Completed</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.departmentWiseReviewSummery?.map((item, index) => {
                        return (
                          <tr className="trStyle">
                            <td>{item?.department}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>{100 - item.pendingSelfReview}/100 </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={100 - item.pendingSelfReview}
                                  styles={buildStyles({
                                    pathColor: "var(--primary)",
                                    textColor: "var(--primary)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={3}
                                  text={`${100 - item.pendingSelfReview}%`}
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>
                                    {100 - item.apprisarReviewPendingPercentage}
                                    /100{" "}
                                  </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={
                                    100 - item.apprisarReviewPendingPercentage
                                  }
                                  styles={buildStyles({
                                    pathColor: "rgba(222, 100, 161, 1)",
                                    textColor: "rgba(222, 100, 161, 1)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={3}
                                  text={`${
                                    100 - item.apprisarReviewPendingPercentage
                                  }%`}
                                />
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>
                                    {100 - item.reviewCompletedPercentage}
                                    /100{" "}
                                  </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={
                                    100 - item.reviewCompletedPercentage
                                  }
                                  styles={buildStyles({
                                    pathColor: "rgba(138, 136, 237, 1)",
                                    textColor: "rgba(138, 136, 237, 1)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={5}
                                  text={`${
                                    100 - item.reviewCompletedPercentage
                                  }%`}
                                />
                              </div>{" "}
                            </td>
                            <td>
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <div>
                                  {" "}
                                  <b>
                                    {100 - item.pendingReviewerReviewPercentage}
                                    /100{" "}
                                  </b>
                                </div>{" "}
                                <CircularProgressbarWithChildren
                                  className="BessHolder"
                                  maxVal={100}
                                  value={
                                    100 - item.pendingReviewerReviewPercentage
                                  }
                                  styles={buildStyles({
                                    pathColor: "rgba(138, 136, 237, 1)",
                                    textColor: "rgba(138, 136, 237, 1)",
                                    trailColor: "white",
                                  })}
                                  minVal={0}
                                  sWidth={3}
                                  text={`${
                                    100 - item.pendingReviewerReviewPercentage
                                  }%`}
                                />
                              </div>{" "}
                            </td>
                            <td>{item.total}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {tabIndex === 1 && <><div>
              <ServiceDash id={selectedId} title={"Summary"} />
              </div></>}
          </div>
        </div>
      </div>
    </div>
  );
};
