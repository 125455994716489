import React from "react";
import { OrganisationBox } from "./OrganisationBox";

const OrganisationSkillsParent = ({
  Heading,
  skillAllListItem,
  allskillAllListItem,
  competencyAllListItem,
  allCompetencyAllListItem,
}) => {
  return (
    <div className="organisationparent">
      {Heading === "Skill Set" &&
        skillAllListItem &&
        skillAllListItem?.map((item) => (
          <OrganisationBox Heading={Heading} itembox={item} />
        ))}
      {Heading === "Skill Name" &&
        allskillAllListItem &&
        allskillAllListItem?.groupedQuery?.map((item) => (
          <OrganisationBox Heading={Heading} itembox={item} />
        ))}
      {Heading === "Competency Set" &&
        competencyAllListItem &&
        competencyAllListItem?.map((item) => (
          <OrganisationBox Heading={Heading} itembox={item} />
        ))}
      {Heading === "Competency Name" &&
        allCompetencyAllListItem &&
        allCompetencyAllListItem?.groupedQuery?.map((item) => (
          <OrganisationBox Heading={Heading} itembox={item} />
        ))}
    </div>
  );
};

export { OrganisationSkillsParent };
