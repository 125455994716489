import React from "react";
import ProfileAvatar from "../../../../Assets/ProfileAvatar.svg";
import { getUserInitials } from "../../../../Utilities/socialAllFunctions";
const SuccessPop = ({ dataEmp }) => {
  if (dataEmp)
    return (
      <div className="success-pop-container"  style={{ height: "355px", width: "454px" }}>
        <p
          style={{ marign:"12px 0px 24px 0px !important", height: "100px", width: "100px" }}
          className={`${dataEmp?.image ? "" : "socialCustomProfilePic noMargin"}`}
        >
          {dataEmp?.image ? (
            <img
              style={{ height: "100px" }}
              src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${dataEmp?.image}`}
              alt="Profile"
              className=" myServiceIcon"
            />
          ) : (
            getUserInitials(dataEmp?.nameWithPersonNumber)
          )}
        </p>
        <h5> Welcome to AESS,</h5>
        <h4 style={{ color: "var(--primary)",marginTop:'5px' }}>{dataEmp?.nameWithPersonNumber}</h4>
        <p className="loginCred">Your Login Credentials</p>
        {dataEmp && (
          <div className="data-dat">
            <p>
              <p>Login ID </p>
              <p
                style={{
                  color: "var(--primary)",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                {" "}
                {dataEmp?.systemId}
              </p>
            </p>
            <p>
              <p>Password</p>
              <p
                style={{
                  color: "var(--primary)",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                {" "}
                {dataEmp?.password}{" "}
              </p>
            </p>
          </div>
        )}
      </div>
    );
  else return "NO Data Found";
};

export { SuccessPop };
