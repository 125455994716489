import React from "react";

import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import { useEffect } from "react";




export const CreateEarlyGoingComp = () => {

  useEffect(() => {
    document.title = `PeopleSol - Create Early Going `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);  

  return (
    <div className="formcustom twoColumn">
      
      <div>
        <LabelCustom labelName={"Early Going Date"} mandatory={true} />
        <DatePickerFormiks
          name={"date"} 
        />
      </div>
      <div>
        <LabelCustom labelName={"Early Going Time"} mandatory={true} />
        <TimeCustomFormik
                    name="time"
                    
                      placeholder="Select Time"

                    />
      </div>
      <div>
        <LabelCustom labelName={"Early Going Reason"} mandatory={true} />
        <TextAreaFormik
          name="reason"
          
          maxLength={500}
          placeholder={"Enter the reason"}
        />
      </div>
      
      
    </div>
  );
};
