// notifiSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const notifiSlice = createSlice({
  name: 'notifi', // Change the slice name to 'notifi'
  initialState: 0, // Initial notification count
  reducers: {
    setNotiCount: (state, action) => {
      return action.payload; // Set the notification count to the payload value
    },
  },
});

export const { setNotiCount } = notifiSlice.actions;
export default notifiSlice.reducer;
