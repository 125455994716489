import React from "react";
import { useEffect } from "react";
const SummaryDetails = ({ detailTitle, Details, ...props }) => {
  useEffect(() => {
    document.title = `PeopleSol - Summary Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="detailData" {...props}>
      <label>{detailTitle ?? ""}</label>
      <label className="detailsValue">{Details ?? ""}</label>
    </div>
  );
};
export { SummaryDetails };
