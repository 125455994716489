import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { infoSchema } from "./Schema";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";

const CreateInfo = (props) => {
    const { state } = useLocation();
    
    const data = state?.selectedRecord;
    
    const dispatch = useDispatch();
    
    const initialvalue = {
      title: data?.Title
    };


  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ViewInfotypes/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }

    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Info`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = async (values) => {

    const edit = {
      ...values,
      id: data?.Id,
    };

 
Edit(edit)


    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
    ButtonLabel={"Update"}
      FormHeading={props.title}
      validationSchema={infoSchema}
      onSubmit={onSubmit}
      initialvalue={
         initialvalue
      }
      returnPage={returnPage}
      propsDiv={<InfoTypeForm />}
    />
  );
};

export { CreateInfo };

const InfoTypeForm = () => {
  return (
    <div className="formcustom">
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Name"}
        />

    </div>
  );
};


