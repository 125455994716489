import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function UploadDetail({filepath}) {
  const [fileName, setFileName] = useState(filepath);
  useEffect(() => {
    if (filepath)
      if (filepath.includes("/")) {
        let sliceIndex = filepath.indexOf("/");
        setFileName(filepath.slice(sliceIndex+1));
      }
  }, [filepath]);

  return (
    <a
      href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/${filepath}`}
      target="blank"
    >
      {fileName}
    </a>
  );
}

export default UploadDetail;
