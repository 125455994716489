import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useSelector } from "react-redux";
import { postRequestForm } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import PDFViewer from "./PDFViewer";

import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import {
  albumPostImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import {
  docsArray,
  getAttachments,
  getContentLength,
  getExtension,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import { Mention, MentionsInput } from "react-mentions";
import { useNavigate } from "react-router-dom";
import {
  setCommunityPostData,
  setSocialData,
} from "../../../../../Features/SocialSlice";
import ContentSocialDisplay from "./ContentSocialDisplay";

function EditSharePost({
  editDeleteSocialPostData,
  editDeleteSocialPostId,
  getData,
  setIsSharePosEditActive,
  isCommunityorConnect,
}) {
  const [shareTextContent, setShareTextContent] = useState(
    editDeleteSocialPostData?.sharedContent ?? ""
  );
  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { employeeListReduxData } = useSelector((state) => state.social);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socialData } = useSelector((state) => state.social);
  const { communityPostData } = useSelector((state) => state.social);
  function getDisplayName(nameWithPersonNumber) {
    let index = nameWithPersonNumber?.indexOf("(");
    let name = nameWithPersonNumber?.substring(0, index);
    return name;
  }
  const findAndWrapLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s<]+)/g;
    const processedText = text.replace(/<p>.*?<\/p>/g, (paragraph) => {
      return paragraph.replace(
        urlRegex,
        `<a href='$&' target='_blank' rel='noopener noreferrer'>$&</a>`
      );
    });

    return processedText;
  };
  const sharePost = async () => {
    if (shareTextContent.toString().length > 499) {
      dispatch(
        showToast({
          text: "Content lenght should be less than 500",
          severity: "error",
        })
      );
      return;
    }
    setLoading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("PostId", editDeleteSocialPostId);
    bodyFormData.append(
      "content",
      findAndWrapLinks(shareTextContent?.trim()?.toString("html"))
    );
    try {
      const result = await postRequestForm(
        `socialPost/EditSharedPost`,
        bodyFormData
      );
      if (result?.status === 200 && result?.statusText === "OK") {
        if (isCommunityorConnect === "connect") {
          let x = socialData?.map((d) => {
            if (d?.id === editDeleteSocialPostId) {
              return {
                ...d,
                sharedContent: shareTextContent?.trim().toString("html"),
              };
            } else {
              return d;
            }
          });
          dispatch(setSocialData(x));
        }
        if (isCommunityorConnect === "community") {
          let x = communityPostData?.map((d) => {
            if (d?.id === editDeleteSocialPostId) {
              return {
                ...d,
                sharedContent: shareTextContent?.trim().toString("html"),
              };
            } else {
              return d;
            }
          });
          dispatch(setCommunityPostData(x));
        }
        setIsSharePosEditActive(false);
        dispatch(
          showToast({
            text: "Post Edited Successfully",
            severity: "success",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   const handleTextareaInput = () => {
  //     const textarea = document.getElementById("myTextarea");
  //     textarea.style.height = "auto";
  //     textarea.style.height = textarea.scrollHeight + 10 + "px";
  //   };

  //   const textarea = document.getElementById("myTextarea");
  //   textarea.addEventListener("input", handleTextareaInput);

  //   return () => {
  //     textarea.removeEventListener("input", handleTextareaInput);
  //   };
  // }, []);
  return (
    <div>
      <Popup
        firstClassName="sharePostPopup1"
        secondClassName="sharePostPopup2"
        contentClassName="sharePostContentHolder"
        popupHeading={
          <div className="newPostHeaderContainer">
            <div
              onClick={() => {
                navigate(`/home/social/userprofile/${LoggedInUser?.id}`);
              }}
              className={`${
                LoggedInUser?.image
                  ? "socialProfilePicContainer"
                  : "socialCustomProfilePic"
              }`}
            >
              {!LoggedInUser?.image ? (
                getUserInitials(LoggedInUser?.name)
              ) : (
                <img
                  className="socialProfilePic"
                  src={
                    process.env.REACT_APP_.BASE_URL +
                    profileImages +
                    LoggedInUser?.image
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = ProfileAvatar;
                  }}
                />
              )}
            </div>

            <div className="newPostHeaderTextContainer">
              <h5>{getDisplayName(LoggedInUser?.nameWithPersonNumber)}</h5>
              <p>{LoggedInUser?.designation}</p>
              <span>
                {" "}
                {employeeListReduxData?.map((d, i) => {
                  if (d?.id === LoggedInUser?.id) {
                    return d?.location;
                  } else {
                    return null;
                  }
                })}
              </span>
            </div>
          </div>
        }
        content={
          <div
            className="newPostBodyContainer"
            style={{ paddingBottom: "12%" }}
          >
            {/* <TextAreaCustom
              name={"content"}
              autoFocus
              id="myTextarea"
              placeholder={"Type something..."}
              value={shareTextContent}
              onChange={(e) => {
                setShareTextContent(e.target.value);
              }}
              className={"createPostInputText"}
            /> */}
            <MentionsInput
              placeholder=" What do you want to talk about? "
              autoFocus
              value={shareTextContent}
              onChange={(e) => {
                setShareTextContent(e.target.value);
              }}
              maxLength={500}
              a11ySuggestionsListLabel={" What do you want to talk about? "}
              className={"createPostInputText"}
            >
              <Mention
                trigger={"@"}
                data={employeeListReduxData?.map((d) => {
                  return { id: d?.id, display: getDisplayName(d?.name) };
                })}
              />
            </MentionsInput>
            <div
              className="sharePostContainer"
              style={{
                padding: "15px",
                border: "1px solid #F1F2F2",
                borderRadius: "10px",
              }}
            >
              <div className="sharePostWrapper">
                <div className="newPostHeaderContainer">
                  <div
                    onClick={() => {
                      navigate(
                        `/home/social/userprofile/${editDeleteSocialPostData?.id}`
                      );
                    }}
                    className={`${
                      editDeleteSocialPostData?.image
                        ? "socialProfilePicContainer"
                        : "socialCustomProfilePic"
                    }`}
                  >
                    {!editDeleteSocialPostData?.image ? (
                      getUserInitials(editDeleteSocialPostData?.name)
                    ) : (
                      <img
                        className="socialProfilePic"
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          editDeleteSocialPostData?.image
                        }
                        alt="Profile"
                        onError={(e) => {
                          e.target.src = ProfileAvatar;
                        }}
                      />
                    )}
                  </div>

                  <div className="newPostHeaderTextContainer">
                    <h5>
                      {getDisplayName(
                        editDeleteSocialPostData?.nameWithPersonNumber
                      )}
                    </h5>
                    <p>{editDeleteSocialPostData?.roleName}</p>
                    <span>{editDeleteSocialPostData?.locationName}</span>
                  </div>
                </div>
                {editDeleteSocialPostData?.subType !== null && (
                  <>
                    <div className="postBodyTextContainer">
                      {editDeleteSocialPostData?.content?.split(/\r\n|\r|\n/)
                        .length > 4 && !showContent ? (
                        <ContentSocialDisplay
                          content={getContentLength(
                            editDeleteSocialPostData?.content
                          )}
                        />
                      ) : (
                        <ContentSocialDisplay
                          content={editDeleteSocialPostData?.content?.trim()}
                        />
                      )}
                      {editDeleteSocialPostData?.content?.split(/\r\n|\r|\n/)
                        .length > 4 &&
                        !showContent && (
                          <span
                            onClick={() => {
                              setShowContent(true);
                            }}
                          >
                            ...View More
                          </span>
                        )}
                    </div>

                    <div
                      className={` ${
                        editDeleteSocialPostData?.sharedEmployee !== null
                          ? "postBodyImgContainer"
                          : ""
                      }`}
                    >
                      <div
                        className={`newPostBodyImgMainWrapper`}
                        style={{ margin: "0" }}
                      >
                        <div
                          className="newPostBodyImgContainer1"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={
                              process.env.REACT_APP_.BASE_URL +
                              albumPostImages +
                              editDeleteSocialPostData?.attachment
                            }
                            alt=""
                            style={{ height: "540px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {editDeleteSocialPostData?.subType === null && (
                  <div
                    className="postBodyContainer"
                    style={{ margin: "15px 10px 0px 15px" }}
                  >
                    <div className="postBodyTextContainer">
                      {editDeleteSocialPostData?.content?.split(/\r\n|\r|\n/)
                        .length > 4 && !showContent ? (
                        <ContentSocialDisplay
                          content={getContentLength(
                            editDeleteSocialPostData?.content
                          )}
                        />
                      ) : (
                        <ContentSocialDisplay
                          content={editDeleteSocialPostData?.content?.trim()}
                        />
                      )}
                      {editDeleteSocialPostData?.content?.split(/\r\n|\r|\n/)
                        .length > 4 &&
                        !showContent && (
                          <span
                            onClick={() => {
                              setShowContent(true);
                            }}
                          >
                            ...View More
                          </span>
                        )}
                    </div>
                    {editDeleteSocialPostData?.attachment?.length > 0 &&
                      imgArray.includes(
                        getExtension(
                          getAttachments(editDeleteSocialPostData?.attachment)
                            ? getAttachments(
                                editDeleteSocialPostData?.attachment
                              )[0]
                            : ""
                        )
                      ) && (
                        <div
                          className={` ${
                            editDeleteSocialPostData?.sharedEmployee !== null
                              ? "postBodyImgContainer"
                              : ""
                          }`}
                        >
                          <div
                            className={
                              getAttachments(
                                editDeleteSocialPostData?.attachment
                              )?.length === 1
                                ? ``
                                : `newPostBodyImgMainWrapper`
                            }
                            style={{ margin: "0" }}
                          >
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 1 && (
                              <div
                                className="newPostBodyImgContainer1"
                                style={{
                                  height: "400px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    socialPostImages +
                                    getAttachments(
                                      editDeleteSocialPostData?.attachment
                                    )[0]
                                  }
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 2 && (
                              <div className="newPostBodyImgContainer2">
                                <div className="subImage2a">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="subImage2b">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 3 && (
                              <div className="newPostBodyImgContainer3">
                                <div className="subImage3a">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="subImage3b">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    alt=""
                                  />
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[2]
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 4 && (
                              <div className="newPostBodyImgContainer4">
                                <div className="subImage4a">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="subImage4b">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    alt=""
                                  />
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[2]
                                    }
                                    alt=""
                                  />
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[3]
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length > 4 && (
                              <div className="newPostBodyImgContainer4">
                                <div className="subImage4a">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="subImage4b">
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    alt=""
                                  />
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[2]
                                    }
                                    alt=""
                                  />
                                  <img
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[3]
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="imgHideMainBox">
                                  <div className="imgHideRoundBox">
                                    <span>
                                      +{" "}
                                      {getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )?.length - 4}
                                    </span>
                                  </div>
                                </div>
                                {/* {newPostImg?.map((item, index) => {
                      return <img src={item?.url} alt="" />;
                    })} */}
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {editDeleteSocialPostData?.attachment?.length > 0 &&
                      videoArray.includes(
                        getExtension(
                          getAttachments(editDeleteSocialPostData?.attachment)
                            ? getAttachments(
                                editDeleteSocialPostData?.attachment
                              )[0]
                            : ""
                        )
                      ) && (
                        <div
                          className={` ${
                            editDeleteSocialPostData?.sharedEmployee !== null
                              ? "postBodyImgContainer"
                              : ""
                          }`}
                        >
                          <div
                            className={
                              getAttachments(
                                editDeleteSocialPostData?.attachment
                              )?.length === 1
                                ? ``
                                : `newPostBodyImgMainWrapper`
                            }
                            style={{ margin: "0" }}
                          >
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 1 && (
                              <div className="newPostBodyImgContainer">
                                <video
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    socialPostImages +
                                    getAttachments(
                                      editDeleteSocialPostData?.attachment
                                    )[0]
                                  }
                                  controls
                                  width="633"
                                  height="335"
                                  style={{
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                  }}
                                />
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 2 && (
                              <div className="newPostBodyImgContainer2">
                                <div className="subImage2a">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    controls
                                    width="315"
                                    height="335"
                                  />
                                </div>
                                <div className="subImage2b">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    controls
                                    width="315"
                                    height="335"
                                  />
                                </div>
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 3 && (
                              <div className="newPostBodyImgContainer3">
                                <div className="subImage3a">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    controls
                                    width="315"
                                    height="335"
                                  />
                                </div>
                                <div className="subImage3b">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    controls
                                    width="215"
                                    height="165"
                                  />
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[2]
                                    }
                                    controls
                                    width="215"
                                    height="165"
                                  />
                                </div>
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length === 4 && (
                              <div className="newPostBodyImgContainer4">
                                <div className="subImage4a">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    controls
                                    width="315"
                                    height="335"
                                  />
                                </div>
                                <div className="subImage4b">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    controls
                                    width="215"
                                    height="110"
                                  />
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[2]
                                    }
                                    controls
                                    width="215"
                                    height="110"
                                  />
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[3]
                                    }
                                    controls
                                    width="215"
                                    height="110"
                                  />
                                </div>
                              </div>
                            )}
                            {getAttachments(
                              editDeleteSocialPostData?.attachment
                            )?.length > 4 && (
                              <div className="newPostBodyImgContainer4">
                                <div className="subImage4a">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[0]
                                    }
                                    controls
                                    width="315"
                                    height="335"
                                  />
                                </div>
                                <div className="subImage4b">
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[1]
                                    }
                                    controls
                                    width="215"
                                    height="110"
                                  />
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[2]
                                    }
                                    controls
                                    width="215"
                                    height="110"
                                  />
                                  <video
                                    src={
                                      process.env.REACT_APP_.BASE_URL +
                                      socialPostImages +
                                      getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )[3]
                                    }
                                    controls
                                    width="215"
                                    height="110"
                                  />
                                </div>
                                <div className="imgHideMainBox">
                                  <div className="imgHideRoundBox">
                                    <span>
                                      +{" "}
                                      {getAttachments(
                                        editDeleteSocialPostData?.attachment
                                      )?.length - 4}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    {editDeleteSocialPostData?.attachment?.length > 0 &&
                      docsArray.includes(
                        getExtension(
                          getAttachments(editDeleteSocialPostData?.attachment)
                            ? getAttachments(
                                editDeleteSocialPostData?.attachment
                              )[0]
                            : ""
                        )
                      ) && (
                        <div className="sharePostBodyImgContainer">
                          {getAttachments(editDeleteSocialPostData?.attachment)
                            ?.length === 1 && (
                            <div className="sharePostBodyImgContainer">
                              <PDFViewer
                                pdfUrl={
                                  process.env.REACT_APP_.BASE_URL +
                                  socialPostImages +
                                  getAttachments(
                                    editDeleteSocialPostData?.attachment
                                  )[0]
                                }
                              />
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
            <div className="shareBottomContainer">
              {!loading && (
                <button class="button primaryButton" onClick={sharePost}>
                  Save
                </button>
              )}
              {loading && <div className="saving-post">Saving...</div>}
            </div>
          </div>
        }
        handleClose={() => {
          setIsSharePosEditActive(false);
        }}
      />
    </div>
  );
}

export default EditSharePost;
