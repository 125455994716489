import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    search: false,
}

const SearchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        closeSearch: state => {
            state.search = false;
        },
        openSearch: state => {
            state.search = true; 
        },
    },
})
export const { closeSearch, openSearch } = SearchSlice.actions;
export default SearchSlice.reducer;