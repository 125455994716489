import React, {  useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { getRequest, postRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { ReqTypeForm } from "./Form/ReqTypeForm";
import { TeamMemberForm } from "./Form/TeamMemberForm";
import { teammemberSchema } from "./TeamMembersSchemas";


const CreateTeamMembers = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const [detail, setDetail] = React.useState([]);
  const dispatch = useDispatch();
  const [detailsform,setDetailsform]=useState({})
  const getFormDetails = async () => {
    try {
      const result = await getRequest(`HRHelpdeskTeamMappings/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
    } finally {
    }
  }
  const getDetails = async () => {
    try {
      const result = await getRequest(`HRHelpdeskTeamMappings/Details/${data}`);
      setDetail(result.data);
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  }, [data]);

  useEffect(() => {
    document.title = `PeopleSol - Create Team Member`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "HRHelpdeskTeamMappings/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  const initialvalue = {
    ticketTypeId:data?detail.ticketTypeId:"",
    teamId:data?detail.teamId:""
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "HRHelpdeskTeamMappings/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  useEffect(()=>{
    if(data){
      getFormDetails()
    }
  },[])
  const onSubmit = async (values) => {
    
    const edit = {
      ...values,
      Id: detailsform?.id,
 
    };
    
    const create = {
      ...values,
    };

    data?Edit(edit) :
     postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/hrdesk/teammembers");
  };

  return (
    <FormCustomFormik
      FormHeading={data?'Edit HR Desk Team Members':'Create HR Desk Team Members'}
      validationSchema={teammemberSchema}
      ButtonLabel={data?'Update HR Desk Team Members':'Create HR Desk Team Members'}
      onSubmit={onSubmit}
      initialvalue={
         initialvalue}
      returnPage={returnPage}
      propsDiv={<TeamMemberForm data={detailsform}/>}
    />
  );
};


export { CreateTeamMembers };
