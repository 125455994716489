/* eslint-disable react-hooks/exhaustive-deps */
import { Table, TableBody, TableHead, TableRow } from "@mui/material";

import { FieldArray, Form, Formik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { array, string, number, object } from "yup";
import { Loader } from "../../../../../Components/FormComponent/Loader";
const proption = [
  { label: "Critical  ", value: "Critical " },
  { label: "High  ", value: "High " },
  { label: "Medium  ", value: "Medium " },
  { label: "Low  ", value: "Low " },
];
export const RiskIdentification = ({ id, data, color, type, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [optionGroup, setOptionGroup] = useState([]);
  const [optionExcep, setOptionExcep] = useState({});
  const [optionind, setOptionInd] = useState({});
  const [detail, setDetail] = useState([]);

  const validationSchema = object({
    SecurityThreat: string().required("Please Enter  Threat/Risk").nullable(),
    MitigationPlannedDate: string().required("Please Select Planned Date"),
    MitigationImplementationDate: string().required(
      "Please Select Implementation Date"
    ),
    NumberOfUsersAffected: number()
      .required("Please Enter No. of  User Affected")
      .nullable(),
    BusinessImpact: string()
      .required("Please Select Business Impact")
      .nullable(),
    BusinessJustification: string()
      .required("Please Enter Justification")
      .nullable(),
    List: array().of(
      object().shape({
        ExceptionGroupId: string().required("Please select Group"),
        ExceptionStatementId: string().required("Please select Statement"),
      })
    ),
  });
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`ExceptionRequests/Details?ID=${id}`);
      setDetail(result?.data?.data);
      result?.data?.data?.expectionStatements.map((item, index) => {
        return getOptionExc(index, item.exceptionGroupId);
      });
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getDetails();
    }
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Risk Identification`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getInitalValues = () => {
    let returnArray = [];
    detail
      ? detail?.expectionStatements?.map((item) => {
        return  returnArray.push({
            ExceptionGroupId: item?.exceptionGroupId,
            ExceptionStatementId: item?.exceptionStatementId,
          });
        })
      : (returnArray = [
          {
            ExceptionGroupId: "",
            ExceptionStatementId:
              formValues?.exceptionStatementId !== ""
                ? formValues?.exceptionStatementId
                : "",
          },
        ]);

    return returnArray;
  };
  const dispatch = useDispatch();
  const initialvalue = {
    SecurityThreat: "",
    NumberOfUsersAffected: "",
    BusinessImpact: null,
    BusinessJustification: "",
    MitigationPlan: "",
    MitigationPlannedDate: "",
    IdentifyMitigationControl: "",
    MitigationImplementationDate: "",
    ResidualRisk: "",
    List: getInitalValues(),
  };
  const createPageData = async (values) => {
    try {
      setLoading(true);
      await postRequest("exceptionRequests/RiskIdentification", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data, severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };

  const getOptionGroup = async (index) => {
    try {
      const result = await getRequest(`ExceptionRequests/GetExceptionGroups`);
      let listData = [];
      result?.data?.results.map((item, index) => {
      return  listData.push({ label: item.text, value: item.id });
      });
      setOptionGroup(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionExc = async (index, val) => {
    try {
      const result = await getRequest(
        `ExceptionRequests/GetExceptionStatements?ExceptionGroupId=${val}`
      );
      let ListData = [];
      result?.data?.results.map((item) => {
        return ListData.push({ label: item.text, value: item.id });
      });
      setOptionExcep((prev) => {
        const x = { ...prev };
        x[index] = ListData;
        return x;
      });
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (formValues?.List[optionind]?.ExceptionGroupId) {
      getOptionExc(optionind, formValues?.List[optionind]?.ExceptionGroupId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.List[optionind]?.ExceptionGroupId]);
  useEffect(() => {
    getOptionGroup();
  }, []);
  if (loading) return <Loader/>
  return (
    <Formik
      initialValues={initialvalue}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const create = {
          ...values,
          ExceptionRequestId: id,
        };
        createPageData(create);
        // //console.log("val", create);
      }}
    >
      {(formik) => {
        setFormValues(formik.values);
        return (
          <Form>
            <div style={{ width: "100%" }}>
              <div className="formcustom twoColumn">
                <div>
                  <LabelCustom
                    labelName={
                      "Security Threat/ Vulnerability/ Risk to PeopleSol "
                    }
                    mandatory={true}
                  />
                  <TextAreaFormik
                    name="SecurityThreat"
                    placeholder={"Enter Risk Associated"}
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={
                      "Number of Users Affected (potentially affected)"
                    }
                    mandatory={true}
                  />
                  <InputCustomFormik
                    name="NumberOfUsersAffected"
                    type="number"
                           step="0.01"
                    placeholder={"Enter No. of Users Affected"}
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Business Impact  "}
                    mandatory={true}
                  />
                  <SelectForm
                    name="BusinessImpact"
                    options={proption}
                    placeholder={" Select Priority "}
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Business justification "}
                    mandatory={true}
                  />
                  <TextAreaFormik
                    style={{ height: "100px" }}
                    name="BusinessJustification"
                    placeholder={" Enter Description"}
                  />
                </div>
                <div>
                  <LabelCustom labelName={"Mitigation Plan"} />
                  <TextAreaFormik
                    style={{ height: "100px" }}
                    name="MitigationPlan"
                    placeholder={" Enter Description"}
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Mitigation Date Planned"}
                    mandatory={true}
                  />
                  <DatePickerFormiks name={"MitigationPlannedDate"} />
                </div>
                <div>
                  <LabelCustom labelName={"Identify Mitigation Control"} />
                  <TextAreaFormik
                    style={{ height: "100px" }}
                    name="IdentifyMitigationControl"
                    placeholder={" Enter Description"}
                  />
                </div>
                <div>
                  <LabelCustom
                    labelName={"Date of Mitigation Implementation"}
                    mandatory={true}
                  />
                  <DatePickerFormiks name={"MitigationImplementationDate"} />
                </div>
                <div>
                  <LabelCustom labelName={"Residual Risk"} />
                  <TextAreaFormik
                    style={{ height: "100px" }}
                    name="ResidualRisk"
                    placeholder={" Enter Description"}
                  />
                </div>
              </div>
              <div style={{ display: "grid" }}>
                <FieldArray name="List">
                  {(fieldArrayProps) => {
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { List } = values;

                    return (
                      <StyledTableCont>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableCell padding="0" align="center">
                                <LabelCustom labelName={"S.No."} />
                              </StyledTableCell>
                              <StyledTableCell padding="0" align="center">
                                <LabelCustom
                                  labelName={"Exception Group"}
                                  mandatory={true}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <LabelCustom
                                  labelName={"Exception Statement"}
                                  mandatory={true}
                                />
                              </StyledTableCell>

                              <StyledTableCell
                                sx={{
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <LabelCustom labelName={"Add/Remove"} />
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          {List.map((Lists, index) => {
                            // List.ExceptionGroupId!==""&&getOptionExc()
                            List?.ExceptionGroupId !== "" &&
                              setOptionInd(index);
                            return (
                              <>
                                <TableBody key={index}>
                                  <StyledTableCell align="center">
                                    {index + 1}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <SelectForm
                                      placeholder={" Enter ExceptionGroup"}
                                      name={`List[${index}].ExceptionGroupId`}
                                      options={optionGroup}
                                    />
                                  </StyledTableCell>

                                  <StyledTableCell>
                                    <SelectForm
                                      placeholder={" Enter ExceptionStatement"}
                                      onDropdownOpen={
                                        List?.ExceptionGroupId === ""
                                          ? () =>
                                              dispatch(
                                                showToast({
                                                  text: "Please select ExceptionGroup",
                                                  severity: "error",
                                                })
                                              )
                                          : () => {}
                                      }
                                      name={`List[${index}].ExceptionStatementId`}
                                      options={optionExcep[index]}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell>
                                      <button
                                        type="button"
                                        className="remove-btn"
                                        onClick={() =>
                                          List.length > 1
                                            ? remove(index)
                                            : dispatch(
                                                showToast({
                                                  text: "Atleast One Item is Required ",
                                                  severity: "error",
                                                })
                                              )
                                        }
                                      >
                                        -
                                      </button>
                                    
                                    <button
                                      type="button"
                                      className="add-btn"
                                      onClick={() =>
                                        push({
                                          ExceptionGroupId: "",
                                          ExceptionStatementId: "",
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </StyledTableCell>
                                </TableBody>
                              </>
                            );
                          })}
                        </Table>
                      </StyledTableCont>
                    );
                  }}
                </FieldArray>
              </div>
              <div className="createFootCust">
                <div>
                  <button
                    className="deletebtn"
                    type="button"
                    onClick={handleClose}
                  >
                    {"Cancel"}
                  </button>
                </div>
                <div>
                  <button className="button primaryButton" type="submit">
                    {type}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
