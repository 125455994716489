import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { postRequestForm } from "../../../../Services/axios";
import { CreateHrdeskComp } from "./Form/CreateHrdeskComp";
import { useSelector } from "react-redux";

const CreateHrdesk = (props) => {
  const [formValues, setFormValues] = useState(null);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm("HrHelpDesks/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data?.message, severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create HR Desk`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const SUPPORTED_FORMATS = ["application/pdf", "application/doc"];
  const initialvalue = {
    TypeId: "",
    RequestCategoryId: "",
    RequestTypeId: "",
    LocationId: LoggedInUser?.locationID,
    Priority: "",
    FileUpload: "",
    Description: "",
  };
  const validationSchema = Yup.object({
    TypeId: Yup.string().required("Please select Service Type"),
    RequestTypeId: Yup.string().required(" Please select Request Type"),
    LocationId: Yup.string().required(" Please select Location"),
    Priority: Yup.string().required(" Please select Priority"),
    Description: Yup.string()
      .max(499, "Must be less than 500 characters")
      .required("Please Enter  Description"),
    FileUpload: Yup.mixed()
      .nullable()
      .test(
        "Fichier taille",
        "File Size should be less than 2 mb",
        (value) => !value || (value && value.size <= 2048 * 2048)
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("TypeId", values?.TypeId);
    formData.append("RequestCategoryId", values?.RequestCategoryId);
    formData.append("RequestTypeId", values?.RequestTypeId);
    formData.append("LocationId", values?.LocationId);
    formData.append("Description", values?.Description);
    formData.append("Priority", values?.Priority);
    formData.append("FileUpload", values?.FileUpload);

    createPageData(formData);
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={"Create HR Desk Request"}
      ButtonLabel={"Create HR Desk Request"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateHrdeskComp formValues={formValues} />}
    />
  );
};

export { CreateHrdesk };
