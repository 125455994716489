import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import "./ITDesk.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TTProjects } from "../../HRMS/Components/TTProjects";
import { RequestCategoryIt } from "../ITDeskComponents/RequestCategoryIt";
import { RequestTypeIt } from "../ITDeskComponents/RequestTypeIt";
import { ServiceCategoryIt } from "../ITDeskComponents/ServiceCategoryIt";
import { ServiceTypeIt } from "../ITDeskComponents/ServiceTypeIt";
import { TeamMembersIt } from "../ITDeskComponents/TeamMembersIt";
import { WorkflowIt } from "../ITDeskComponents/WorkflowIt";
import { TeamsIt } from "../ITDeskComponents/TeamsIt";
import { ServiceVendors } from "../ITDeskComponents/ServiceVendors";

const ITDesk = ({ featureMenu }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    navigate(`itdesk/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `PeopleSol - IT Desk`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("itsmmaster/itdesk")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "itdesk");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `itdesk/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `itdesk/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
  }, [location.pathname, featureMenu]);
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex ===
                            item?.featureName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "tabActive"
                        }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "requestcategory" && (
        <>
          <RequestCategoryIt title={"IT Desk Request Category"} />
        </>
      )}
      {tabIndex === "requesttype" && (
        <>
          <RequestTypeIt title={"IT  Desk Request type"} />
        </>
      )}
      {tabIndex === "servicecategory" && (
        <>
          <ServiceCategoryIt title={"IT  Desk Service Category"} />
        </>
      )}
      {tabIndex === "servicetype" && (
        <>
          <ServiceTypeIt title={"IT  Desk Service Type"} />
        </>
      )}
      {tabIndex === "workflow" && (
        <>
          <WorkflowIt title={"IT  Desk Workflow"} />
        </>
      )}
      {tabIndex === "teams" && (
        <>
          <TeamsIt title={"IT  Desk Teams"} />
        </>
      )}
      {tabIndex === "teammembers" && (
        <>
          <TeamMembersIt title={"IT Desk Team Members"} />
        </>
      )}
      {tabIndex === "servicevendors" && (
        <>
          <ServiceVendors title={"IT  Desk Service Vendors"} />
        </>
      )}
    </div>
  );
};

export { ITDesk };
