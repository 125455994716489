import React, { useState } from "react";
import "./CreateRole.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateRoleComp } from "./FormComp/CreateRoleComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreateRole = (props) => {
  const { state } = useLocation();

  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    try {
      const result = await getRequest(`Role/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    document.title = `PeopleSol - Create Role `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Role/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("Role/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialvalue = {
    name: data ? detail?.name : "",
    designationID: data ? detail?.designationID : "",
    grade: data ? detail?.grade : "",
    isKpiTemplateAdded: data
      ? detail?.isKpiTemplateAdded
        ? "Yes"
        : "No"
      : "Yes",
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(149, "Name should not be greater than 150 characters").required("Please Enter Role Name"),
    designationID: Yup.string().required("Please Select Designation"),
    grade: Yup.string().max(5,"Grade must be less than 6 characters").required("Please Enter Grade"),
    isKpiTemplateAdded: Yup.string().required("Please Select An Option"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
      Designation: values.Designation,
      isKpiTemplateAdded: values.isKpiTemplateAdded === "Yes" ? true : false,
    };

    const create = {
      ...values,
      id: 0,
      designationID: values.designationID,

      isKpiTemplateAdded: values.isKpiTemplateAdded === "Yes" ? true : false,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Role" : "Create Role"}
      ButtonLabel={data ? "Update Role" : "Create Role"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateRoleComp data={data} />}
    />
  );
};

export { CreateRole };
