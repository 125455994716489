/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import "./HrDesk.css";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestCategory } from "../HRDeskComponents/RequestCategory";
import { RequestType } from "../HRDeskComponents/RequestType";
import { ServiceCategory } from "../HRDeskComponents/ServiceCategory";
import { ServiceType } from "../HRDeskComponents/ServiceType";
import { Workflow } from "../HRDeskComponents/Workflow";
import { Teams } from "../HRDeskComponents/Teams";
import { TeamMembers } from "../HRDeskComponents/TeamMembers";

const HrDesk = ({ featureMenu }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    navigate(`hrdesk/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `PeopleSol - HR Desk`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrmsmaster/hrdesk")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "hrdesk");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `hrdesk/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `hrdesk/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
  }, [location.pathname, featureMenu]);
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex ===
                            item?.featureName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "tabActive"
                        }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "requestcategory" && (
        <>
          <RequestCategory title={"Hr Desk Request Category"} />
        </>
      )}
      {tabIndex === "requesttype" && (
        <>
          <RequestType title={"Hr Desk Request Type"} />
        </>
      )}
      {tabIndex === "servicecategory" && (
        <>
          <ServiceCategory title={"Hr Desk Service Category"} />
        </>
      )}
      {tabIndex === "servicetype" && (
        <>
          <ServiceType title={"Hr Desk Service Type"} />
        </>
      )}
      {tabIndex === "workflow" && (
        <>
          <Workflow title={"Hr Desk Workflow"} />
        </>
      )}
      {tabIndex === "teams" && (
        <>
          <Teams title={"Hr Desk Teams"} />
        </>
      )}
      {tabIndex === "teammembers" && (
        <>
          <TeamMembers title={"Hr Desk TeamMembers"} />
        </>
      )}
    </div>
  );
};

export { HrDesk };
