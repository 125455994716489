import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateEmailTemplateComp } from "./FormComp/CreateEmailTemplateComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateEmailTemplate = (props) => {


  useEffect(()=>{
    document.title = `PeopleSol - Create Email Template`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`EmailTemplates/Details/${data}`);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) getEmployeeData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("EmailTemplates/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("EmailTemplates/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialvalue = {
    module: "",
    event: "",
    code: "",
    content: "",
  };
  const validationSchema = Yup.object({
    module: Yup.string()
      .max(149, "Module must be less than 150 characters")
      .required("Please Enter Module "),
    event: Yup.string()
      .max(149, "Event must be less than 150 characters")
      .required("Please Enter Event"),
    code: Yup.string()
      .max(49, "Code must be less than 50 characters")
      .required("Please Enter Code"),
    content: Yup.string()
      .max(1999, "Content must be less than 2000 characters")
      .required("Please Enter Content"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
      id: 0,

      isEnabled: true,
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Email Template" : "Create  Email Template"}
      ButtonLabel={data ? "Update  Email Template" : "Create  Email Template"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              module: eventtData?.module,
              event: eventtData?.event,
              code: eventtData?.code,
              content: eventtData?.content,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateEmailTemplateComp data={data} />}
    />
  );
};

export { CreateEmailTemplate };
