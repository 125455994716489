import { IconButton } from "@mui/material";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import "./MoreActivityContainer.css";
const MoreActivityContainer = forwardRef((props, ref) => {
  const { ActivityList, onChange, activeList } = props;
  const [isActive, setIsActive] = useState(false);
  const updateFavourite = (id, defaultList) => {
    localStorage.setItem(defaultList, id);
  };
  const updateState = () => setIsActive((prev) => !prev);
  const closeState = () =>
    setIsActive(false);
  useImperativeHandle(ref, () => ({
    updateState,
    closeState,
  }));
  return (
    <div className="moreActivityContainer">
      <IconButton color="primary" title="More Activities" onClick={updateState}>
        {!isActive ? (
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.546631"
              y="0.550781"
              width="24"
              height="24"
              rx="2"
              stroke="#1296B0"
            />
            <rect
              x="5.3396"
              y="5.5459"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="#F7941D"
            />
            <rect
              x="14.7478"
              y="5.5459"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="#F7941D"
            />
            <rect
              x="14.7478"
              y="14.5488"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="#F7941D"
            />
            <rect
              x="5.3396"
              y="14.5488"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="#F7941D"
            />
          </svg>
        ) : (
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.546631"
              y="1.41016"
              width="24"
              height="24"
              rx="2"
              fill="#1296B0"
              stroke="#1296B0"
            />
            <rect
              x="5.3396"
              y="6.40527"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="white"
            />
            <rect
              x="14.7478"
              y="6.40527"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="white"
            />
            <rect
              x="14.7478"
              y="15.4082"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="white"
            />
            <rect
              x="5.3396"
              y="15.4082"
              width="5.00512"
              height="5.00512"
              rx="0.5"
              stroke="white"
            />
          </svg>
        )}
      </IconButton>
      {isActive && (
        <div className="moreActivityHolder">
          <div className="headingFilterHeader">
            <h5 style={{ width: "120px" }}>Activities</h5>
            <IconButton onClick={updateState}>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7579 0.56897L0.757935 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 0.56897L12.7579 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          {ActivityList &&
            ActivityList.map((item, index) => {
              if (item.action)
                return (
                  <div
                    key={index}
                    className={`cursor activityTile ${
                      activeList === item.id.toString() && "activityTileActive"
                    }`}
                    onClick={() => {
                      item.action();
                      setIsActive(false);
                    }}
                  >
                    <label style={{ whiteSpace: "nowrap" }}>
                      {item.actionTitle}
                    </label>
                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    ></div>
                  </div>
                );
              else
                return (
                  <div
                    key={index}
                    className={`cursor activityTile ${
                      activeList === item.id.toString() && "activityTileActive"
                    }`}
                    onClick={() => {
                      onChange(item.id);
                      setIsActive(false);
                    }}
                  >
                    <label style={{ whiteSpace: "nowrap" }}>
                      {item.actionTitle}
                    </label>
                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                      }}
                    >
                      {item.badge > 0 && (
                        <div className="activityBadge">
                          <label>{item.badge}</label>
                        </div>
                      )}
                      <IconButton
                        title="Make default list"
                        onClick={() =>
                          updateFavourite(item.id, item.defaultList)
                        }
                      >
                        {item.favourite ? (
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.63169 0.949334C3.70505 0.800685 3.91702 0.800686 3.99038 0.949335L4.94007 2.87362C4.9692 2.93264 5.02552 2.97356 5.09066 2.98302L7.21423 3.2916C7.37827 3.31543 7.44377 3.51703 7.32507 3.63273L5.78844 5.13058C5.7413 5.17653 5.71979 5.24273 5.73092 5.3076L6.09367 7.42259C6.12169 7.58597 5.95021 7.71057 5.80348 7.63343L3.9041 6.63487C3.84584 6.60423 3.77623 6.60423 3.71797 6.63487L1.81859 7.63343C1.67186 7.71057 1.50038 7.58597 1.5284 7.42259L1.89115 5.3076C1.90228 5.24273 1.88077 5.17653 1.83363 5.13058L0.296999 3.63273C0.178295 3.51703 0.243798 3.31543 0.407842 3.2916L2.53141 2.98302C2.59655 2.97356 2.65287 2.93264 2.682 2.87362L3.63169 0.949334Z"
                              fill="#1296B0"
                            />
                          </svg>
                        ) : (
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.63169 0.949334C3.70505 0.800685 3.91702 0.800686 3.99038 0.949335L4.94007 2.87362C4.9692 2.93264 5.02552 2.97356 5.09066 2.98302L7.21423 3.2916C7.37827 3.31543 7.44377 3.51703 7.32507 3.63273L5.78844 5.13058C5.7413 5.17653 5.71979 5.24273 5.73092 5.3076L6.09367 7.42259C6.12169 7.58597 5.95021 7.71057 5.80348 7.63343L3.9041 6.63487C3.84584 6.60423 3.77623 6.60423 3.71797 6.63487L1.81859 7.63343C1.67186 7.71057 1.50038 7.58597 1.5284 7.42259L1.89115 5.3076C1.90228 5.24273 1.88077 5.17653 1.83363 5.13058L0.296999 3.63273C0.178295 3.51703 0.243798 3.31543 0.407842 3.2916L2.53141 2.98302C2.59655 2.97356 2.65287 2.93264 2.682 2.87362L3.63169 0.949334Z"
                              fill="#BFBFBF"
                            />
                          </svg>
                        )}
                      </IconButton>
                    </div>
                  </div>
                );
            })}
        </div>
      )}
    </div>
  );
});

export default MoreActivityContainer;
