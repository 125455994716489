import { configureStore } from "@reduxjs/toolkit";
import ThemeReducer from "../Features/ThemeSlice";
import MenuReducer from "../Features/MenuSlice";
import AuthReducer from "../Features/AuthSlice";
import ToastReducer from "../Features/ToastSlice";
import SearchReducer from "../Features/SearchSlice";
import NavReducer from "../Features/NavSlice";
import PopupReducer from "../Features/PopupSlice";
import NotiReducer from "../Features/NotiSlice";
import ServiceReducer from "../Features/ServiceSlice";
import ChatReducer from "../Features/ChatSlice";
import approvalReducer from '../Features/approvalSlice';
import employeeReducer from '../Features/employeeSlice';
import loaderReducer  from '../Features/loaderSlice';
import socialReducer  from '../Features/SocialSlice';
import { notifiSlice } from '../Features/notifiSlice'; 

export const store = configureStore({
  reducer: {
    theme: ThemeReducer,
    menu: MenuReducer,
    auth: AuthReducer,
    employee: employeeReducer,
    approval: approvalReducer,
    notifi: notifiSlice.reducer, // Use the named export and the '.reducer' property
    toast: ToastReducer,
    search: SearchReducer,
    nav: NavReducer,
    popup: PopupReducer,
    noti: NotiReducer,
    service: ServiceReducer,
    chat: ChatReducer,
    loader: loaderReducer,
    social: socialReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
