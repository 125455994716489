import React, { useEffect, useState } from "react";
import LoginPerson from "../../Assets/LoginPerson.png";
import LoginBack from "../../Assets/LoginBack.png";
import "../Styles.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { postRequest } from "../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../Features";
import { IconButton } from "@mui/material";
import VisibilityIcon from "../../Assets/VisibilityIcon.svg";
import VisibilityOffIcon from "../../Assets/VisibilityOffIcon.svg";
import * as Yup from "yup";
import { async } from "q";

const NewPassword = () => {
  // const [newPassword, setNewPassword] = useState("");
  const [newPasswordType, setNewPasswordType] = useState("password");
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailParam = location.state.email;
  const idParam = location.state.id;
  const otpParam = location.state.otp;

  const validationSchema = Yup.object().shape({
    newPwd: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
      .matches(/[a-z]/, "Password must contain at least 1 lowercase letter")
      .matches(/[0-9]/, "Password must contain at least 1 digit"),
    confirmPwd: Yup.string()
      .required("Confirm New Password is required")
      .oneOf([Yup.ref("newPwd"), null], "Passwords must match"),
  });
  const onSubmit = async (values) => {
  //console.log("values", values);

    try {
      const result = await postRequest(
        `SubmitCandidate/UpdatePassword?email=${emailParam}&password=${values?.newPwd}&otp=${otpParam}&ID=${idParam}`
      );
    //console.log(result);
      if (
        result.status === 200 &&
        result.statusText === "OK" &&
        result.data === "Your password has been updated successfully."
      ) {
        navigate("/login", {
          state: { ...location.state, password: values.newPwd },
        });
        dispatch(
          showToast({
            text: "Password updated successfully!",
            severity: "success",
          })
        );
      } else {
        navigate("/newpassword");
        dispatch(
          showToast({
            text: "Error in update password!",
            severity: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - New Password`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // const onSubmit = async () => {
  //   if (
  //     (newPassword === "" || newPassword === null) &&
  //     (confirmPassword === "" || confirmPassword == null)
  //   ) {
  //     dispatch(
  //       showToast({
  //         text: "Please enter password to updated!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   } else if (newPassword === "" || newPassword === null) {
  //     dispatch(
  //       showToast({
  //         text: "New Password can not be null or empty!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   } else if (confirmPassword === "" || confirmPassword === null) {
  //     dispatch(
  //       showToast({
  //         text: "Confirm Password can not be null or empty!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   } else if (newPassword.length < 8 || confirmPassword.length < 8) {
  //     dispatch(
  //       showToast({
  //         text: "Password length doesn,t match!! Please enter at least 8 character long password!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   } else if (newPassword !== confirmPassword) {
  //     dispatch(
  //       showToast({
  //         text: "Password doesn't match!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   }

  //   var lowerCaseLetters = /[a-z]/g;
  //   if (!newPassword.match(lowerCaseLetters)) {
  //     dispatch(
  //       showToast({
  //         text: "Should contain atleast 1 lowercase letter!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   }

  //   var upperCaseLetters = /[A-Z]/g;
  //   if (!newPassword.match(upperCaseLetters)) {
  //     dispatch(
  //       showToast({
  //         text: "Should contain atleast 1 uppercase letter!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   }

  //   var numbers = /[0-9]/g;
  //   if (!newPassword.match(numbers)) {
  //     dispatch(
  //       showToast({
  //         text: "Should contain atleast 1 digit!",
  //         severity: "error",
  //       })
  //     );
  //     return;
  //   }

  //   try {
  //     const result = await postRequest(
  //       `SubmitCandidate/UpdatePassword?email=${emailParam}&password=${newPassword}&otp=${otpParam}&ID=${idParam}`
  //     );
  //     if (
  //       result.status === 200 &&
  //       result.statusText === "OK" &&
  //       result.data === "Your password has been updated successfully."
  //     ) {
  //       navigate("/login", {
  //         state: { ...location.state, password: newPassword },
  //       });
  //       dispatch(
  //         showToast({
  //           text: "Password updated successfully!",
  //           severity: "success",
  //         })
  //       );
  //     } else {
  //       navigate("/newpassword");
  //       dispatch(
  //         showToast({
  //           text: "Error in update password!",
  //           severity: "error",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: error?.response?.data ?? "Error in fetching Data",
  //         severity: "error",
  //       })
  //     );
  //   }
  // };

  return (
    <div className="landing">
      <img
        src={LoginBack}
        alt="LoginBack"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
      <div className="loginGraphics">
        <img
          src={LoginPerson}
          alt="LoginPerson"
          className="newLoginGraphicImage"
        />
      </div>
      <div className="loginForm">
        <div className="loginFormBox">
          <div className="backtol" onClick={() => navigate("/login")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="8"
              viewBox="0 0 18 8"
              fill="none"
            >
              <path
                d="M16.5762 4.6748C16.8523 4.6748 17.0762 4.45095 17.0762 4.1748C17.0762 3.89866 16.8523 3.6748 16.5762 3.6748V4.6748ZM0.484619 3.82125C0.289358 4.01651 0.289358 4.3331 0.484619 4.52836L3.6666 7.71034C3.86186 7.9056 4.17844 7.9056 4.37371 7.71034C4.56897 7.51508 4.56897 7.19849 4.37371 7.00323L1.54528 4.1748L4.37371 1.34638C4.56897 1.15112 4.56897 0.834533 4.37371 0.639271C4.17844 0.444009 3.86186 0.444009 3.6666 0.639271L0.484619 3.82125ZM16.5762 3.6748L0.838173 3.6748V4.6748L16.5762 4.6748V3.6748Z"
                fill="#F7941D"
              />
            </svg>
            <span>Back to Login</span>
          </div>
          <div className="mainiconf">
            <div>
              <svg
                width="153"
                height="153"
                viewBox="0 0 153 153"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="76.2152" cy="76.1831" r="76" fill="#E3FDFC" />
                <rect
                  x="56.2458"
                  y="38.8242"
                  width="39.2221"
                  height="45.7293"
                  rx="11.6278"
                  stroke="#FFDBA6"
                  stroke-width="2.58396"
                />
                <line
                  x1="79.6797"
                  y1="38.8242"
                  x2="72.0347"
                  y2="38.8242"
                  stroke="#F7941D"
                  stroke-width="2.58396"
                />
                <rect
                  x="43.1301"
                  y="63.1677"
                  width="65.4534"
                  height="51.2244"
                  rx="5.16792"
                  fill="#1296B0"
                />
                <circle cx="56.8111" cy="88.6053" r="2.24378" fill="#E2F8F6" />
                <circle cx="69.8362" cy="88.6053" r="2.24378" fill="#E2F8F6" />
                <circle cx="82.8611" cy="88.6053" r="2.24378" fill="#E2F8F6" />
                <circle cx="95.8857" cy="88.6053" r="2.24378" fill="#E2F8F6" />
                <circle cx="104.945" cy="109.861" r="12.0571" fill="#FFDBA6" />
                <path
                  d="M98.7063 109.87L101.895 113.372L111.185 106.351"
                  stroke="#1296B0"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="forgheaddiv">
              <p className="forgotHead">Create New Password</p>
              <p className="headingTxt">Your new password must be different</p>
              <p className="headingTxt">from previously used password</p>
            </div>
            <div style={{ width: "100%" }}>
              {/* <Formik
                initialValues={{
                  newPwd: newPassword,
                  confirmPwd: confirmPassword,
                }}
              >
                <Form>
                  <div className="forgotForm">
                    <div>
                      <div className="emfor">
                        <label htmlFor="email">
                          New Password
                          <span
                            style={{
                              color: "red",
                              paddingBottom: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            *
                          </span>
                        </label>
                      </div>
                      <div className="password-container">
                        <input
                          value={newPassword}
                          className="loginInput"
                          label="password"
                          name="nPwd"
                          type={newPasswordType}
                          placeholder="Enter Password"
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                        />
                        <div className="password-icons">
                          {newPasswordType === "password" ? (
                            <IconButton
                              color="primary"
                              onClick={() => setNewPasswordType("text")}
                            >
                              <img
                                src={VisibilityIcon}
                                alt="ShowPassword"
                                className="visibilityIcon"
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => setNewPasswordType("password")}
                            >
                              <img
                                src={VisibilityOffIcon}
                                alt="HidePassword"
                                className="visibilityIcon"
                              />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="emfor">
                        <label htmlFor="email">
                          Confirm New Password
                          <span
                            style={{
                              color: "red",
                              paddingBottom: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            *
                          </span>
                        </label>
                      </div>
                      <div className="password-container">
                        <input
                          value={confirmPassword}
                          className="loginInput"
                          label="password"
                          name="cPwd"
                          type={confirmPasswordType}
                          placeholder="Enter Password"
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                        />
                        <div className="password-icons">
                          {confirmPasswordType === "password" ? (
                            <IconButton
                              color="primary"
                              onClick={() => setConfirmPasswordType("text")}
                            >
                              <img
                                src={VisibilityIcon}
                                alt="ShowPassword"
                                className="visibilityIcon"
                              />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => setConfirmPasswordType("password")}
                            >
                              <img
                                src={VisibilityOffIcon}
                                alt="HidePassword"
                                className="visibilityIcon"
                              />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className="btnfor primaryButton"
                      type="submit"
                      onClick={onSubmit}
                      style={{marginTop:"24px"}}
                    >
                      Reset Password
                    </button>
                  </div>
                </Form>
              </Formik> */}
              <Formik
                initialValues={{
                  newPwd: "",
                  confirmPwd: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="forgotForm">
                      <div>
                        <div className="emfor">
                          <label htmlFor="newPwd">
                            New Password
                            <span
                              style={{
                                color: "red",
                                paddingBottom: "5px",
                                paddingLeft: "5px",
                              }}
                            >
                              *
                            </span>
                          </label>
                        </div>
                        <div className="password-container">
                          <Field
                            name="newPwd"
                            type={newPasswordType}
                            placeholder="Enter Password"
                            className={`loginInput ${
                              errors.newPwd && touched.newPwd
                                ? "input-error"
                                : ""
                            }`}
                          />
                          <div className="password-icons">
                            {newPasswordType === "password" ? (
                              <IconButton
                                color="primary"
                                onClick={() => setNewPasswordType("text")}
                              >
                                <img
                                  src={VisibilityIcon}
                                  alt="ShowPassword"
                                  className="visibilityIcon"
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                color="primary"
                                onClick={() => setNewPasswordType("password")}
                              >
                                <img
                                  src={VisibilityOffIcon}
                                  alt="HidePassword"
                                  className="visibilityIcon"
                                />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        {errors.newPwd && touched.newPwd && (
                          <div className="error">{errors.newPwd}</div>
                        )}
                      </div>
                      <div>
                        <div className="emfor">
                          <label htmlFor="confirmPwd">
                            Confirm New Password
                            <span
                              style={{
                                color: "red",
                                paddingBottom: "5px",
                                paddingLeft: "5px",
                              }}
                            >
                              *
                            </span>
                          </label>
                        </div>
                        <div className="password-container">
                          <Field
                            name="confirmPwd"
                            type={confirmPasswordType}
                            placeholder="Enter Password"
                            className={`loginInput ${
                              errors.confirmPwd && touched.confirmPwd
                                ? "input-error"
                                : ""
                            }`}
                          />
                          <div className="password-icons">
                            {confirmPasswordType === "password" ? (
                              <IconButton
                                color="primary"
                                onClick={() => setConfirmPasswordType("text")}
                              >
                                <img
                                  src={VisibilityIcon}
                                  alt="ShowPassword"
                                  className="visibilityIcon"
                                />
                              </IconButton>
                            ) : (
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  setConfirmPasswordType("password")
                                }
                              >
                                <img
                                  src={VisibilityOffIcon}
                                  alt="HidePassword"
                                  className="visibilityIcon"
                                />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        {errors.confirmPwd && touched.confirmPwd && (
                          <div className="error">{errors.confirmPwd}</div>
                        )}
                      </div>
                      <button
                        className="btnfor primaryButton"
                        type="submit"
                        style={{ marginTop: "24px" }}
                      >
                        Reset Password
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="passwordValidateContainer">
            <h2>Password Policy</h2>
            <p>1. Should be atleast 8 characters long</p>
            <p>2. Should contain atleast 1 uppercase letter</p>
            <p>3. Should contain atleast 1 lowercase letter</p>
            <p>4. Should contain atleast 1 digit</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NewPassword };
