import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import {
  FormikMultiStepForm,
  FormikMultiStepFormStep,
} from "../../../Components/FormikMultiStepForm/FormikMultiStepForm";
import * as Yup from "yup";
import { WorkflowDetailsFormIt } from "./Form/WorkflowDetailsFormIt";
import { WorkflowStepsFormIt } from "./Form/WorkflowStepsFormIt";
import { useEffect } from "react";

const CreateWorkflowItN = () => {
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  const data = state?.id;
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Create Workflow`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`ItsmWorkflows/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const postFormData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("ItsmWorkflows/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const [formValues, setFormValues] = useState({});
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const getInitalAnswerValues = () => {
    let returnArray = [];

    data
      ? detail?.items?.map((item, index) => {
          return returnArray.push({
            workflowId: item?.workflowId,
            name: item?.name,
            actionType: item?.actionType,
            approver: item?.approver,
            viewers: item?.viewers,
            ifApproved: item?.ifApproved,
            ifRejected: item?.ifRejected,
            tat: item?.tat,
            emailViewers: item?.emailViewers == null ? "" : item?.emailViewers,
          });
        })
      : (returnArray = [
          {
            name: "",
            actionType: "",
            approver: "",
            viewers: "",
            ifApproved: "",
            ifRejected: "",
            tat: "",
            emailViewers: "",
          },
        ]);

    return returnArray;
  };
  const initialValues = {
    name: data ? detail?.name : "",
    description: data ? detail?.description : "",
    ticketTypeId: data ? detail?.ticketTypeId : "",
    requestCategoryId: data ? detail?.requestCategoryId : "",
    requestTypeId: data ? detail?.requestTypeId : null,
    items: getInitalAnswerValues(),
  };
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("ItsmWorkflows/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const onSubmit = async (values) => {
    const getTatValues = () => {
      let returnArray = [];

      values?.items?.map((item, index) => {
        return returnArray.push({
          name: item?.name,
          actionType: item?.actionType,
          approver: item?.approver,
          viewers: item?.viewers,
          ifApproved: item?.ifApproved,
          ifRejected: item?.ifRejected,
          tat: item?.tat === "" ? null : item?.tat,
          emailViewers: item?.emailViewers,
        });
      });
      return returnArray;
    };
    const edit = {
      ...values,
      id: detail?.id,
    };
    const create = {
      ...values,
      items: getTatValues(),
      id: 0,
    };
    data ? Edit(edit) : postFormData(create);

    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormikMultiStepForm
      initialValues={initialValues}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      returnPage={returnPage}
      heading={data ? "Edit Workflow" : "Create Workflow"}
      submitText={data ? "Update Workflow" : "Create Workflow"}
      loading={loading}
      chooseFormValues={chooseFormValues}
    >
      <FormikMultiStepFormStep
        label="Worklow Details"
        validationSchema={Yup.object({
          name: Yup.string()
            .max(149, "Name must be less than 150 characters")
            .required("Please Enter Name"),
          ticketTypeId: Yup.string().required("Please Select Service Type"),
          requestTypeId: Yup.string()
            .required("Please Select Request Type")
            .nullable(),
          requestCategoryId: Yup.string().required(
            "Please Select Request Category"
          ),
          description: Yup.string()
            .max(499, "Description must be less than 500 characters")
            .nullable(),
        })}
      >
        <WorkflowDetailsFormIt values={formValues} data={detail} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Workflow Steps"
        validationSchema={Yup.object({
          items: Yup.array().of(
            Yup.object().shape({
              name: Yup.string()
                
                .max(49, " Name must be less than 50 characters")
                .required("Workflow required"),

              actionType: Yup.string().required("Action Type Required"),

              emailViewers: Yup.string().when("actionType", {
                is: (val) => val === "Send an Email",
                then: () => Yup.string().min(1).max(149,"Viewer must be less than 150 characters").notRequired("Email required"),
              }),
              approver: Yup.string().when("actionType", {
                is: (val) => val === "Initiate an Approval",
                then: () => Yup.string().required("required").nullable(),
              }),
              viewers: Yup.string().when("actionType", {
                is: (val) => val === "Initiate an Approval",
                then: () =>Yup.string()
                .min(1)
                .max(49, "Viewer must be less than 50 characters")
                .notRequired("Viewer required"),
              }),
              ifApproved: Yup.string().when("actionType", {
                is: (val) => val === "Initiate an Approval",
                then: () => Yup.string().required("required").nullable(),
              }),
              ifRejected: Yup.string().when("actionType", {
                is: (val) => val === "Initiate an Approval",
                then: () => Yup.string().required("required").nullable(),
              }),
              tat: Yup.string()
                .nullable()
                .when("actionType", {
                  is: (val) => val === "Initiate an Approval",
                  then: () =>  Yup.number().max(999, "Tat must be less than a 999 Hr ").required("Please Enter TAT").nullable(),
                }),
            })
          ),
        })}
      >
        <WorkflowStepsFormIt values={formValues} data={detail} />
      </FormikMultiStepFormStep>
    </FormikMultiStepForm>
  );
};
export { CreateWorkflowItN };
