import { FieldArray, Form, Formik } from "formik";
import { useDispatch } from "react-redux";

import { useEffect, useState } from "react";
import { format } from "date-fns";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import { CheckboxFormiks } from "../../../../../Components/FormComponent/CheckboxFormiks";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import dateFormat from "dateformat";
import * as Yup from "yup";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../../Components/FormComponent/Loader";
export const NoDueForm = ({ id, data, type, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState([]);
  const [other, setOther] = useState([]);
  const [optionEmployee, setOptionEmployee] = useState([]);
  const getEmployee = async () => {
    try {
      setLoading(true);
      const result = await getRequest("LeaveTypes/GetEmployees");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionEmployee(listData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getValues = () => {
    let returnArray = [];

    returnArray = option;
    return returnArray;
  };
  const initialvalue = {
    Items: getValues(),
  };
  const dispatch = useDispatch();
  const getDepartmentData = async () => {
    try {
      const result = await getRequest("Leavetypes/GetDepartments");
      let listData = [];

      result?.data?.map((item) => {
        return listData.push({
          Checked: false,
          DepartmentName: item?.name,
          EmployeeId: null,
          Date: null,
        });
      });
      setOther(result?.data);
      setOption(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - No Due Form`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDepartmentData();
    getEmployee();
  }, []);
  const createPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "Resignations/NoDueClearanceCreate",
        values
      );
      if (result) {
        dispatch(showToast({ text: "Success", severity: "success" }));
      }
    } finally {
      data();
      setLoading(false);
      handleClose();
    }
  };
  const schema = Yup.object({
    Items: Yup.array()
      .of(
        Yup.object().shape({
          EmployeeId: Yup.number("req").required(" Select Employee").nullable(),
          Date: Yup.string("req").required(" Select Date").nullable(),
        })
      )
      .compact((v) => !v.Checked && v.EmployeeId !== "")
      .min(1, "reqaaaa"),
  });
  const onSubmit = async (values) => {
    const requestArray = [];
    const Create = {
      Items: values?.Items?.map((value, index) => {
        requestArray.push({
          Checked: value?.Checked,
          DepartmentId: other[index]?.id,
          ResignationId: id,
          EmployeeId: value?.EmployeeId,
          Date: dateFormat(value?.Date, "yyyy-mm-dd"),
        });
      }),
    };

    const creatReq = {
      ResignationId: id,
      Id: id,
      Item: requestArray,
    };
    createPageData(creatReq);
  };
  if (loading) return <Loader />;
  return (
    <Formik
      initialValues={initialvalue}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={schema}
    >
      <Form>
        <FieldArray name="Items">
          {(fieldArrayProps) => {
            // eslint-disable-next-line no-unused-vars
            const { form } = fieldArrayProps;
            const { values } = form;
            const { Items } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        sx={{
                          padding: "0px !important",
                          fontSize: "12px",
                        }}
                      >
                        <LabelCustom labelName={"S. No."} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Require Clearance"}
                          mandatory={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Subdepartment"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Employee"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Date"} mandatory={true} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {Items?.map((Items, index) => (
                    <TableBody key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      {/* 2nd column */}
                      <StyledTableCell>
                        <CheckboxFormiks
                          type="text"
                          name={`Items[${index}].Checked`}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <LabelCustom labelName={Items?.DepartmentName} />
                      </StyledTableCell>
                      <StyledTableCell>
                        <SelectForm
                          name={`Items[${index}].EmployeeId`}
                          type="text"
                          options={optionEmployee}
                        />
                      </StyledTableCell>
                      {/* 5th column */}
                      <StyledTableCell>
                        <DatePickerFormiks name={`Items[${index}].Date`} />
                      </StyledTableCell>
                      {/* 6th column */}
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ color: "white" }}
          >
            {type}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
