import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Details } from "../../DetailsComp/Details";
import { Loader } from "../../../../../Components/FormComponent/Loader";
const Day7FeedbackDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  // //console.log("id", colorAction);
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);

  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `Inductions/FirstSevenDayFeedBackDetails/${selectedId}`
      );
      //   //console.log('result.data.data',result.data.data);

      setData(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Day 7 Feedback`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // //console.log("data", data);
 if(loading) return <Loader/>
 else
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>First Week Feedback Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          {data ? (
            <div className="detailsEReq" style={{ flexDirection: "column" }}>
              <div className="headingForm"> Feedback Details</div>
              <div
                className="detailInner"
                style={{ flexDirection: "column", alignItems: "flex-start" }}
              >
                {data?.employeeFeedbackDay1?.map((item, index) => {
                  //console.log("item", item);
                  return (
                    <>
                      <Details
                        detailTitle={`${index + 1}. ${item.question.question}`}
                        Details={item?.feedback}
                        style={{ width: "100%" }}
                      />
                    </>
                  );
                })}
              </div>

              <div className="headingForm"> Feedback Given By</div>
              <div className="detailInner">
                <Details
                  detailTitle={"Employee Name"}
                  Details={
                    data?.employees?.nameWithPersonNumber ?? "--" ?? "--"
                  }
                />
                <Details
                  detailTitle={"Employee's Manager"}
                  Details={data?.employees?.rO_Name ?? "--"}
                />
                <Details
                  detailTitle={"Employee Designation"}
                  Details={data?.employees?.designation ?? "--"}
                />
                <Details
                  detailTitle={"Employee Department"}
                  Details={data?.employees?.department ?? "--"}
                />
                <Details
                  detailTitle={"Employee Email"}
                  Details={data?.employees?.email ?? "--"}
                />
                <Details
                  detailTitle={"Contact Number"}
                  Details={
                    data?.employees?.personalPhoneNumber
                      ? data?.employees?.personalPhoneNumber
                      : "--"
                  }
                />

                <Details
                  detailTitle={"Location"}
                  Details={data?.employees?.locationName ?? "--"}
                />
              </div>
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Day7FeedbackDetails };
