/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { LabelCustom } from "../../../../Components/FormComponent/Index";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { RadioFormik } from "../../../../Components/FormComponent/RadioFormik";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { useDispatch } from "react-redux";
import { ColorPickerFormik } from "../../../../Components/FormComponent/ColorPickerFormik";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { useEffect } from "react";

const opt = [{ label: "select", value: null }];

const GeneralDetails = (props) => {
  const { data, formValues } = props;
  const [leaveList, setLeaveList] = useState([]);

  useEffect(() => {
    document.title = `PeopleSol - General Details `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const leaveOptions = [
    { label: "Paid", value: "Paid" },
    { label: "Unpaid", value: "Unpaid" },
  ];
  const dispatch = useDispatch();
  const getLeaveTypeData = async () => {
    try {
      let result;
      if (Object.keys(data).length > 0) result = await getRequest("LeaveTypes/GetLeaveTypeName");
      else result = await getRequest("LeaveTypes/GetLeaveTypes");
      let listData = [];
      if (Object.keys(data).length > 0)
        result?.data?.map((item) => {
          return listData.push({ label: item?.name, value: item?.name });
        });
      else
      result?.data?.data?.map((item) => { 
        return listData.push({ label: item, value: item })
     })
      setLeaveList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getLeaveTypeData();
  }, []);

  return (
    <>
      <div className="formcustom">
        <div>
          <LabelCustom mandatory={true} labelName={"Leave Type Name"} />
          <SelectForm
            placeholder={"Select Leave Type Name"}
            name="name"
            options={leaveList}
            values={
              data
                ? leaveList.filter((options) => options.label === data?.name)
                : opt
            }
          />
        </div>
        <div>
          <LabelCustom mandatory={true} labelName={"Leave Type Code"} />
          <InputCustomFormik
            name="code"
            type={"text"}
            placeholder={"Enter Leave Type Code"}
          />
        </div>

        <div>
          <LabelCustom mandatory={true} labelName={"Leave Types"} />
          <SelectForm
            placeholder={"Select Leave Types "}
            name="type"
            options={leaveOptions}
            values={
              data
                ? leaveOptions.filter((options) => options.label === data?.type)
                : opt
            }
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Leave Type Balance Based On"}
            mandatory={true}
          />
          <RadioFormik
            name="balanceBasedOn"
            options={[
              { label: "Accrual", value: "Accrual" },
              { label: "Fixed", value: "Fixed" },
            ]}
          />
        </div>
        <div>
          <LabelCustom labelName={"Leave Type Valid From"} />
          <DatePickerFormiks name={"validityFrom"} />
        </div>
        <div>
          <LabelCustom labelName={"Leave Type Valid Till"} />
          <DatePickerFormiks
            minDate={formValues?.validityFrom}
            name={"validityTo"}
          />
        </div>
      </div>
    </>
  );
};

export { GeneralDetails };
