import { Form, Formik } from "formik";
import * as Yup from "yup";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";
const opt=[
    {label:"Requester",value:"Requester"}
]
const optHod=[
    {label:"Requester",value:"Requester"},
    {label:"Change Owner",value:"Change Owner"},
    {label:"HOD",value:"HOD"}
]
 const ReferBack = ({ id,handleClose,ChangeType,Reason,Priority,DescriptionOfchange,getDetails,type}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    Remarks: Yup.string().max(200,"Remarks cannot be more than 500 characters").required("Please Enter Remarks"),
  });
  const createPageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(`ITsmChangeRequest/Referback`, values);
      dispatch(showToast({ text: "Successfully Created", severity: "success" }));
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      setLoading(false);
      handleClose()
      getDetails()
    }
  }
  const onSubmit = async (values) => {
      const Create={
          ...values,
        DescriptionOfchange:DescriptionOfchange,
        ChangeType:ChangeType,
        Reason:Reason,
        Priority:Priority,
        Id:id
    }
    createPageData(Create);
  };
  useEffect(() => {
    document.title = `PeopleSol - Refer Backs`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  if(loading) return <Loader/>
 else return (
    <Formik
      initialValues={{ Remarks: "",ReferredBackTo:"" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
      <div>
            <LabelCustom labelName={"Referred Back To "} mandatory={true}/>
            <SelectForm name="ReferredBackTo" options={type==="HOD"?optHod:opt} />
          </div>
        <LabelCustom labelName={"Remark"} mandatory={true} />
        <TextAreaFormik maxLength={500} placeholder="Please Enter Remarks" style={{ height: "80px" }} name={"Remarks"} />
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ background: "var(--primary)", color: "white" }}
          >
            {"Refer Back"}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
export { ReferBack };
