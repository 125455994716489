const profileImages = "/Core/Uploads/ProfileImageUploads/";
const socialPostImages = "/Core/Uploads/SocialPostImageUploads/";
const albumPostImages = "/Core/Uploads/Albums/AlbumPhotos/";
const bannerPostImages = "/Core/Uploads/Banners/";
const communityProfileImages = "/Core/Uploads/CommunityImage/";
const surveyPostImages = "/Core/Uploads/Surveys/";

export {
  profileImages,
  socialPostImages,
  albumPostImages,
  bannerPostImages,
  communityProfileImages,
  surveyPostImages,
};
