import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const Notifications = ({ id, module }) => {
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const getEmailNotification = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`GetEmailHistory/${id}/${module}`);
      setData(result.data?.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmailNotification();
  }, []);
  // useEffect(() => {
  //   document.title = `PeopleSol - Notifications`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // }, []);
  if(loading)return <Loader/>
  return (
    <>
      {data?.length > 0 ? (
        <div style={{ width: "100%" }}>
          <table className="tableNoti tabless ">
            <thead
              className="headNoti"
              style={{ borderBottom: "1px solid #1296b0 !important" }}
            >
              <tr>
                <td>S.no</td>
                <td>Mode</td>
                <td>Title</td>

                <td>Time</td>
                <td>Recipient</td>
                <td>CC</td>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr className="trStyle">
                    <td>{index + 1}</td>
                    <td>{item.notificationType}</td>
                    <td>{item?.title}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                    {format(new Date(item?.createdOn), "dd/MM/yyyy hh:mm a")}
                    </td>
                    <td>{item.emailId}</td>
                    <td>{item.cc}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </>
  );
};
export { Notifications };
