import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";

const opt = [{ label: "select", value: null }];
const WorkFlowRolesForm = ({ data, editID }) => {
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");
  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };

  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    if (editID) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (editID) {
      setPage(0);
      const idArray = [];
      data?.associatedEmployeeId && idArray?.push(data?.associatedEmployeeId);
      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data?.associatedEmployeeId]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);
  useEffect(() => {
    document.title = `PeopleSol - Workflow Role Form`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={51}
          type={"text"}
          name="name"
          placeholder={"Enter Name "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Associated Employee"} mandatory={true} />
        <SelectForm
          values={
            data
              ? option.filter(
                  (options) => options.label === data?.AssociatedEmployee
                )
              : opt
          }
          name="associatedEmployeeId"
          placeholder={" Select Associated Employee"}
          onMenuScrollToBottom={handleScrollToBottom}
          options={searchParamemployee ? option : listData}
          onInputChange={(e) => setSearchParamemployee(e)}
        />
      </div>
    </div>
  );
};
export { WorkFlowRolesForm };
