import React from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";
const actionList = [
    { label: "Hold", value: "Hold" },
    { label: "Rejected", value: "Rejected" },
    { label: "Move to Next Stage", value: "Move to Next Stage" },
    { label: "Selected", value: "Selected" }
]
const opt = [{ label: "Select", value: null }]
export const CreateStatusComp = ({ data }) => {

    useEffect(()=>{
        document.title = `PeopleSol - Create Status`;
        return () => {
          document.title = 'PeopleSol';
        };
      })

    return (
        <div>
            <div>
                <LabelCustom labelName={"Display Name"} mandatory={true} />
                <InputCustomFormik
                    name="displayName"
                    type={"text"}
                    placeholder={"Enter Display Name"}
                />
            </div>
            <div>
                <LabelCustom labelName={"Action"} mandatory={true} />
                <SelectForm
                 
                    name="action"
                    options={actionList}
                    values={
                        data
                            ? actionList.filter(
                                (options) => options.value === data?.Action
                            )
                            : opt
                    } />
            </div>
        </div>
    );
};