import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function UploadDetailMulti({ filepath }) {
  const [fileName, setFileName] = useState(filepath);
  const [beforeSlashText, setBeforeSlashText] = useState("");
  const [attachTexts, setAttachTexts] = useState([]);
  useEffect(() => {
    if (filepath)
      if (filepath.includes("/")) {
        const lastSlashIndex = filepath?.lastIndexOf("/");

        const beforeSlashText = filepath?.substring(0, lastSlashIndex);
        setBeforeSlashText(beforeSlashText);
        setAttachTexts(
          filepath?.substring(filepath?.lastIndexOf("/") + 1)?.split(",")
        );
      }
  }, [filepath]);

  return (
    <>
      {attachTexts?.map((text, index) => (
        <a
          style={{ marginRight: "7px", whiteSpace: "nowrap" }}
          href={
            beforeSlashText?.length > 0
              ? `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/${beforeSlashText}/${text}`
              : `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/${text}`
          }
          target="blank"
        >
          {text}
        </a>
      ))}
    </>
  );
}

export default UploadDetailMulti;
