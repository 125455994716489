import React from "react";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

const InfoTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    maxWidth: 420,
    fontSize: "14px",
    border: "1px solid #dadde9",
    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    padding: 0,
    contain: "content",
  },
}));
export default InfoTooltip;
