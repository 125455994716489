import React from "react";
import Nodata from "../../../../../Assets/Nodata.svg";
import DonE from "../../../../../Assets/DonE.svg";
import DownloadExit from "../../../../../Assets/DownloadExit.svg";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import UploadDetail from "../../../../../Components/UploadDetail/UploadDetail";
import { Details } from "../../DetailsComp/Details";
import { useEffect } from "react";
export const ExitLetters = ({ data }) => {
  useEffect(() => {
    document.title = `PeopleSol - Exit Letters`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  if (data?.request?.experienceLetter == null) {
    return (
      <div className="noDatastyle">
        <img
          src={Nodata}
          alt="No data"
          style={{ height: "150px", width: "250px" }}
        />
        <h4>{"No Data Found"}</h4>
      </div>
    );
  }
  
  return (
    <>
      {/* <div
        style={{ position: "absolute", height: "100%", top: "0%", left: "2%" }}
      >
        <img
          src={ExitBg}
          alt="exit"
          style={{ height: "1000px", width: "1000px" }}
        />
      </div> */}
      <div
        style={{
          padding: "24px",
          marginTop: "55px",
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
      
        }}
      >
        <a style={{ textDecoration:'none',color:'var(--primary)'}}
          href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ResignationLetters/${data?.request?.experienceLetter}`}
          target="blank"
        >
          <div className="desExit">
            <img src={DownloadExit} alt="" />
            <p>{data?.request?.experienceLetter}</p>
            <img src={DonE} alt="" />
            <p>Click here to download the document </p>
          </div>
        </a>
        <a style={{ textDecoration:'none',color:'var(--primary)'}}
          href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ResignationLetters/${data?.request?.relievingLetter}`}
          target="blank"
        >
          <div className="desExit">
            <img src={DownloadExit} alt="" />
            <p>{data?.request?.relievingLetter}</p>
            <img src={DonE} alt="" />
            <p>Click here to download the document </p>
          </div>
        </a>
      </div>
    </>
  );
};
