import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { CreateStatusComp } from "../FormComp/CreateStatusComp";
import { useEffect } from "react";





const CreateStatus = (props) => {


  useEffect(()=>{
    document.title = `PeopleSol - Create Status`;
    return () => {
      document.title = 'PeopleSol';
    };
  })


  const { state } = useLocation();

  const data = state?.dataEdit;
  

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest('PipelineStatus/Create', values);
      dispatch(showToast({ text: "Successfully Created", severity: "success" }));
      
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }
  const Edit = async (values) => {
    try {
        dispatch(isLoading(true));
        setLoading(true);
        const result = await postRequest('PipelineStatus/Edit', values);
        dispatch(showToast({ text: "Successfully Updated", severity: "success" }));
        
      } catch (error) {
        dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      } finally {
        dispatch(isLoading(false));
        setLoading(false);
      }
    }


  const initialvalue = {
    action: "",
    displayName: ""
  };
  const validationSchema = Yup.object({
    displayName: Yup.string().max(25).required("Please enter the display name"),
    action: Yup.string().required("Please select action")
  });

  const onSubmit = (values) => {

    
    const edit = {
      ...values,
      Id: data?.Id
    };

    const create = {
      ...values,
      Id: 0
    }
    
    data ? Edit(edit) : createPageData(create);
    returnPage()
  }


  const navigate = useNavigate();

  const returnPage = () => {
    navigate("atsmaster/status");
  };
  return (
    <FormCustomFormik FormHeading={props.title} ButtonLabel={data ? 'Update' : 'Create'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
      data
        ? {
          ...initialvalue,
          action: data?.Action,
          displayName: data?.Name
        }
        : initialvalue
    }
      returnPage={returnPage} propsDiv={<CreateStatusComp data={data} />} />
  );
};

export { CreateStatus };