import React from "react";
import CourseIcon from "../../../Assets/CourseIcon.svg";
import CourseSmallIcon from "../../../Assets/CourseSmallIcon.svg";
import ChapterSmallIcon from "../../../Assets/ChapterSmallIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import VideoIcon from "../../../Assets/VideoIcon.svg";
import TeamLearningProfileBackground from "../../../Assets/TeamLearningProfileBackground.svg";

const LearnerCardComponent = ({
  backgroundStyles,
  styles,
  name,
  time,
  index,
  chapters,
  courses,
  photo,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`learnerCard ${index === 0 && "backgroundPurple"} ${
        index === 1 && "backgroundOrange"
      } ${index === 2 && "backgroundGreen"}`}
      style={backgroundStyles}
    >
      <div className="learnerdiv">
        <div className="learnerCardCover">
          <img
            src={TeamLearningProfileBackground}
            alt="Profile"
            className="CandidateProfileTeamLearning "
          />
          <div className="learnerCardContent">
            <img
              src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${photo}`}
              alt="Profile"
              className="learnerCardCoverimg"
            />
          </div>
        </div>
        <div className="learnerCardColorCover" style={{ fontWeight: "400" }}>
          <div className="learnerCardColor">{name}</div>
        </div>
      </div>
      <div
        className="countLabel "
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          marginBottom: "10px",
        }}
      >
        <svg
          style={{
            marginTop: "-2px",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 10 11"
          fill="none"
        >
          <path
            d="M9.44696 5.61392C9.50221 6.80612 9.08161 7.97143 8.27767 8.85351C7.47373 9.73559 6.35232 10.2622 5.16012 10.3174C3.96793 10.3727 2.80261 9.95209 1.92053 9.14815C1.03845 8.34422 0.511863 7.2228 0.456608 6.03061C0.401352 4.83841 0.821957 3.6731 1.62589 2.79102C2.42983 1.90894 3.55125 1.38235 4.74344 1.32709C5.93563 1.27184 7.10095 1.69244 7.98303 2.49638C8.86511 3.30031 9.3917 4.42173 9.44696 5.61392L9.44696 5.61392Z"
            stroke="white"
            stroke-width="0.75"
            stroke-linecap="round"
          />
          <path
            d="M4.58105 3.4043L4.58105 6.63246H7.03897"
            stroke="white"
            stroke-width="0.75"
            stroke-linecap="round"
          />
        </svg>
        <span className="learnerCardColortime " style={{ marginLeft: "4px" }}>
          {time}
        </span>
      </div>
    </div>
  );
};

export { LearnerCardComponent };
