import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import "./Connect.css";

const PostActivityContainer = forwardRef((props, ref) => {
  const [isActive, setIsActive] = useState(false);

  const updateState = () => setIsActive((prev) => !prev);
  const closeState = () => setIsActive(false);
  useImperativeHandle(ref, () => ({
    updateState,
    closeState,
  }));
  const moreActivityHandler = useCallback((e) => {
    let moreActivity = document.querySelector(
      `.socialPostActivityContainer${props.editDeleteSocialPostId}`
    );
    if (moreActivity && !moreActivity?.contains(e.target)) {
      setIsActive(false);
    }
  }, [props.editDeleteSocialPostId]);

  useEffect(() => {
    document.addEventListener("mouseup", moreActivityHandler);
    return () => {
      document.removeEventListener("mouseup", moreActivityHandler);
    };
  }, [moreActivityHandler]);
  
  return (
    <div
      className={`socialPostActivityContainer socialPostActivityContainer${props.editDeleteSocialPostId}`}
    >
      <div
        className="rightSocialPostHeaderContainer"
        onClick={() => {
          setIsActive(true);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="17"
          viewBox="0 0 3 17"
          fill="none"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="#D9D9D9" />
          <circle cx="1.5" cy="8.25" r="1.5" fill="#D9D9D9" />
          <circle cx="1.5" cy="15" r="1.5" fill="#D9D9D9" />
        </svg>
      </div>
      {isActive && (
        <div className="socialPostActivityHolder">
          <div
            className="headingFilterHeader"
            onClick={() => {
              props.setEditDeleteSocialPostData(props.editDeleteSocialPostData);
              props.setEditDeleteSocialPostId(props?.editDeleteSocialPostId);
              props.setIsSharePosEditActive(props.isSharePosEditActive) ;
              props.setIsMyPosEditActive(props.isMyPosEditActive) ;
              setIsActive(false);
            }}
          >
            <h5>Edit Post</h5>
          </div>
          <div
            className="headingFilterHeader"
            onClick={() => {
              props.setEditDeleteSocialPostId(props?.editDeleteSocialPostId);
              props.setIsSocialPostDeletePopupActive(true);
              setIsActive(false);
            }}
          >
            <h5>Delete Post</h5>
          </div>
        </div>
      )}
    </div>
  );
});

export default PostActivityContainer;
