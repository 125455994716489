import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import { useState } from "react";
const opt = [{ label: "select", value: null }];
const RequestTypeFormIt = ({ data }) => {
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [option, setOption] = useState([]);
  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        `ItsmRequestCategories/GetItsmRequestCategories?&q=&i=${page}`
      );
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
     if(result.data.results?.length>500) setTotalPages(totalPages+1);
     if(result.data.results?.length>500)   setPage(page + 1);
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Request Type`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const loadMore = async (url, setState) => {
    if (page < totalPages) {
      try {
        const result = await getRequest(url);
        // Update the options and page
        setState((prevOptions) => [
          ...prevOptions,
          ...transformData(result.data.results),
        ]);
        setPage(page + 1);
      } catch (error) {
        // Handle error
      }
    }
  };
  const transformData = (data) => {
    // Transform the data into the desired format
    return data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  };

  useEffect(() => {
    getOptionReq();
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Request Category  "} mandatory={true} />
        <SelectForm
          onMenuScrollToBottom={() =>
            loadMore(
              `ItsmRequestCategories/GetItsmRequestCategories?&q=&i=${page}`,
              setOption
            )
          }
          name={"requestCategoryId"}
          options={option}
          placeholder={" Select Request Category "}
        />
      </div>
    </div>
  );
};
export { RequestTypeFormIt };
