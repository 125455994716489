/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import "react-circular-progressbar/dist/styles.css";
import { getRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import SelectCustomEmployeeCreateAttendance from "../../../../HRMS/Components/Time & Attendance/SelectCustomEmployeeCreateAttendance";

const TimePunchualityCard = ({ isManager, navigate, teamList }) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
const currentYear = currentDate.getFullYear();
const currentMonthFormatted = String(currentMonth).padStart(2, '0'); // Add leading zero if needed

const initialMonth = {
  label: new Intl.DateTimeFormat('en-US', { month: 'long' }).format(currentDate),
  value: `${currentMonthFormatted}-${currentYear}`
};
const [selectedMonth, setSelectedMonth] = useState([initialMonth]);

  const [selectedEmployee, setSelectedEmployee] = useState([
    { label: "Myself", value: LoggedInUser?.id },
  ]);
  const [idActive, setIdActive] = useState(LoggedInUser?.id);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const getTeamLeaveBalance = async (id, startDate, endDate) => {
    if (!id) return;
    try {
      setLoading(true);
      let firstDate = new Date();
      firstDate.setDate(1);
      const result = await getRequest(
        `Attendance/GetPunctuality/${id}/${startDate}/${endDate}`
      );
      setAttendanceData(result?.data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (LoggedInUser) {
      setIdActive(LoggedInUser?.id);
    }
  }, [LoggedInUser]);
  const colors = [
    "#77A2C7",
    "#DA5DB6",
    "#5BC4BF",
    "#8A88ED",
    "#A0A0CB",
    "#FB7158",
  ];
  const getHolidayWeekoffStatus = (holidayCount, weekoffCount) => {
    if (holidayCount && weekoffCount) {
      return holidayCount + weekoffCount;
    } else {
      if (holidayCount === 0 || weekoffCount === 0) {
        if (holidayCount === 0 && weekoffCount === 0) return 0;
        else if (holidayCount === 0) return weekoffCount;
        else if (weekoffCount === 0) return holidayCount;
      } else return 0;
    }
  };
  const handleMonthChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedMonthValue = selectedOptions[0].value; // Assuming value is in "MM-yyyy" format
      setSelectedMonth(selectedMonthValue);
    }
  };

  useEffect(() => {
    if (typeof selectedMonth =="string") {
      const [month, year] = selectedMonth.split("-"); // Splitting the "MM-yyyy" string into month and year
      const startDate = format(new Date(year, month - 1, 1), "MM-dd-yyyy"); // Month in JavaScript starts from 0
      const endDate = format(new Date(year, month, 0), "MM-dd-yyyy"); // Last day of the selected month
      getTeamLeaveBalance(idActive, startDate, endDate);
    }
    else {
      const [month, year] = selectedMonth[0]?.value?.split("-"); // Splitting the "MM-yyyy" string into month and year
      const startDate = format(new Date(year, month - 1, 1), "MM-dd-yyyy"); // Month in JavaScript starts from 0
      const endDate = format(new Date(year, month, 0), "MM-dd-yyyy"); // Last day of the selected month
      getTeamLeaveBalance(idActive, startDate, endDate);
    }
  }, [selectedMonth,idActive]);

  if (!attendanceData) return <Loader />;
  else
    return (
      <>
        <div className="headingContainer cursor headingWithDropdown">
          <h3 style={{flex:'2'}}
            className="cursor"
            onClick={() => navigate("/hrms/timeattendance/myattendance")}
          >
            Time & Punctuality
          </h3>
          <div style={{flex:'2'}}>
            <SelectCustom
              options={[
                { label: "Jan", value: "01-2024" },
                { label: "Feb", value: "02-2024" },
                { label: "Mar", value: "03-2024" },
                { label: "Apr", value: "04-2024" },
                { label: "May", value: "05-2024" },
                { label: "Jun", value: "06-2024" },
                { label: "Jul", value: "07-2024" },
                { label: "Aug", value: "08-2024" },
                { label: "Sep", value: "09-2024" },
                { label: "Oct", value: "10-2024" },
                { label: "Nov", value: "11-2024" },
                { label: "Dec", value: "12-2024" }
              ]}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
              deepStyle={{ backgroundColor: "var(--primary-hover)" }}
              onChange={handleMonthChange}
              values={[initialMonth]}
            />
          </div>
          {isManager && (
            <div style={{ width: "48%",flex:'1' }}>
              <SelectCustomEmployeeCreateAttendance
                styles={{
                  minWidth: "100%",
                  width: "100%",
                  maxWidth: "100%",
                }}
                selectedEmployeeList={selectedEmployee}
                setSelectedEmployeeList={setSelectedEmployee}
                // values={selectedEmployee}
                setIdActive={setIdActive}
                urlProp={"Employees/GetEmployeesForAttendance"}
                lgid={LoggedInUser?.id}
                styl={{
                  backgroundColor: "var(--primary-hover)",
                  margin: 0,
                  minHeight: "40px",
                  maxHeight: "40px",
                  padding: "5px 10px",
                }}
                deepStyle={{ backgroundColor: "var(--primary-hover)" }}
              />
              {/* <SelectCustom
                dropdownHeight="236px"
                styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
                values={selectedEmployee}
                options={teamList}
                onChange={(value) => {
                  setIdActive(value?.[0]?.value);
                  setSelectedEmployee(value);
                }}
                fullWidth={true}
                styl={{
                  backgroundColor: "var(--primary-hover)",
                  margin: 0,
                  minHeight: "40px",
                  maxHeight: "40px",
                  padding: "5px 10px",
                }}
              /> */}
            </div>
          )}
        </div>
        <div className="dashboardCardContentHolder">
          {loading ? (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 10,
              }}
            >
              <Skeleton
                width="100%"
                height="100%"
                style={{ position: "absolute" }}
              />
            </div>
          ) : (
            <>
              <div className="tabContainer">
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{ style: { background: "#F7941D" } }}
                >
                  <Tab
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex === 0 && "tabActive tabActiveSecondary"
                        }`}
                      >
                        Attendance
                      </span>
                    }
                  />
                  <Tab
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex === 1 && "tabActive tabActiveSecondary"
                        }`}
                      >
                        Punctuality
                      </span>
                    }
                  />
                </Tabs>
                <div className="tabContainerLine"></div>
              </div>
              {tabIndex === 0 && (
                <div className="ProgressBars">
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[0 % colors.length] }}
                    >
                      {attendanceData.totalWorkingDaysCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[0 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">
                      Total Working Days
                    </label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[1 % colors.length] }}
                    >
                      {attendanceData.presentCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[1 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">Present Days</label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[2 % colors.length] }}
                    >
                      {getHolidayWeekoffStatus(
                        attendanceData.holidayCount,
                        attendanceData.weekoffCount
                      )}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[2 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">
                      Holiday & Weekly Off
                    </label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[3 % colors.length] }}
                    >
                      {attendanceData.leaveCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[3 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">Leave Days</label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[4 % colors.length] }}
                    >
                      {attendanceData.halfDayCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[4 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">Half Days</label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[5 % colors.length] }}
                    >
                      {attendanceData.absentCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[5 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">Absent Days</label>
                  </div>
                </div>
              )}
              {tabIndex === 1 && (
                <div className="ProgressBars">
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[0 % colors.length] }}
                    >
                      {attendanceData.totalWorkingDaysCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[0 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">
                      Total Working Days
                    </label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[1 % colors.length] }}
                    >
                      {attendanceData.lateComingCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[1 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">Late Days</label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[2 % colors.length] }}
                    >
                      {`${attendanceData.lateComingPercentage?.toFixed(0)}%` ??
                        "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[2 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">Late Days %</label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[3 % colors.length] }}
                    >
                      {attendanceData.avgLateComingMinutes?.toFixed(0) ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[3 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">
                      Avg. Late Time (in Mins)
                    </label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[4 % colors.length] }}
                    >
                      {attendanceData.regularizedCount ?? "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[4 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">
                      Regularized Attendance
                    </label>
                  </div>
                  <div className="leaveBalanceInfoHolder">
                    <label
                      className="leaveBalanceCount"
                      style={{ color: colors[5 % colors.length] }}
                    >
                      {`${attendanceData.regularizedPercentage?.toFixed(0)}%` ??
                        "--"}
                    </label>
                    <div
                      className="horizontalLinePrimary noMarginTop"
                      style={{ backgroundColor: colors[5 % colors.length] }}
                    ></div>
                    <label className="leaveNameHolder">
                      Regularized Attendance %
                    </label>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
};

export { TimePunchualityCard };
