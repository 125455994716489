import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { MyLearning } from "./MyLearning";
import { TeamLearning } from "./TeamLearning";

const Learning = () => {
  const [tabIndex, setTabIndex] = useState("mylearning");
  const handleTabChange = (event, newTabIndex) => {
    navigate(`learning/${newTabIndex}`);
  };
  const location = useLocation();
  const navigate = useNavigate();
  const [featureMenu, setFeatureMenu] = useState(null);
  const { featureMenuList } = useSelector((state) => state.menu);
  useEffect(() => {
    if (featureMenuList) {
      featureMenuList.map((menu) => {
        if (
          menu[0]?.subMenuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "learning" &&
          menu[0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "ld"
        )
          setFeatureMenu(menu);
        return null;
      });
    }
  }, [featureMenuList]);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("ld/learning")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "learning");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `learning/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `learning/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, featureMenu]);
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex ===
                            item?.featureName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "tabActive"
                        }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "mylearning" && <MyLearning />}
      {tabIndex === "teamlearning" && <TeamLearning />}
    </div>
  );
};

export { Learning };
