import React, { useState } from "react";
import "./CreateDepartment.css";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateDepartmentComp } from "./FormComp/CreateDepartmentComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CreateDepartment = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getDetails = async () => {
    try {
      const result = await getRequest(`Department/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequest("Department/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      returnPage();
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Department `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("Department/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      returnPage();
    }
  };

  const initialvalue = {
    name: data ? detail.name : "",
    parentDepartmentId: data ? detail.parentDepartmentId : null,
    hodemployeeId: data ? detail.hodemployeeId : null,
    directorEmployeeId: data ? detail.directorEmployeeId : null,
    businessPerson: data ? detail.businessPerson : null,
    spoc: data ? detail.spoc : null,
    requireNoDuesClearance: data
      ? detail.requireNoDuesClearance
        ? "Yes"
        : "No"
      : "Yes",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(49, "Department Name must be less than 50 characters")
      .required("Please Enter Department Name"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,
      requireNoDuesClearance:
        values.requireNoDuesClearance == "Yes" ? true : false,
    };

    const create = {
      ...values,
      id: 0,

      isDataActive: true,
      requireNoDuesClearance:
        values.requireNoDuesClearance == "Yes" ? true : false,
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Department" : "Create Department"}
      ButtonLabel={data ? "Update Department" : "Create Department"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateDepartmentComp data={detail} editID={data} />}
    />
  );
};

export { CreateDepartment };
