import { Form, Formik } from "formik";
import React from "react";
import { useLocation } from "react-router";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import dateFormat from "dateformat";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import { useState } from "react";
import { postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { ReferBack } from "./ReferBack";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";
const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const change = [{ label: "Normal", value: "Normal" }];
const HodApproval = ({ data, getDetails }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selId, setSelId] = useState(0);

  const togglePopup = (id) => {
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  //console.log("ds",data?.itsmChangeRequest?.id,)
  const onSubmit = (values, status) => {
    const create = {
      ...values,
      RequstId: data?.itsmChangeRequest?.id,
      ApprovalStatus: status === "reject" ? false : true,
      DowntimeHours: dateFormat(values?.DowntimeHours, "h:MM TT"),
      EstimatedTimetoComplete: dateFormat(
        values?.EstimatedTimetoComplete,
        "yyyy-mm-dd"
      ),
      DescriptionOfchange: data?.itsmChangeRequest?.descriptionOfchange,
    };
    createPage(create);
  };
  const createPage = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("ITsmChangeRequest/Approvals", values);
      if (result) {
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(showToast({ text: "error", severity: "error" }));
    } finally {
      getDetails();
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Hod Approval`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  if (loading) return <Loader />;
  else
    return (
      <>
        <Formik
          initialValues={{
            Priority: data?.itsmChangeRequest?.priority,
            ChangeType: data?.itsmChangeRequest?.changeType,
            EstimatedTimetoComplete:
              data?.itsmChangeRequest?.estimatedTimetoComplete !== null
                ? new Date(data?.itsmChangeRequest?.estimatedTimetoComplete)
                : "",
            DowntimeHours:
              data?.itsmChangeRequest?.downtimeHours !== null
                ? new Date(`12/12/12 ${data?.itsmChangeRequest?.downtimeHours}`)
                : "",
            Remarks: "",
          }}
          onSubmit={onSubmit}
        >
          {({ values }) => {
            return (
             <div style={{backgroundColor:'var(--form-background)',padding:'12px'}}>
               <Form>
                <div className="formgrid">
                  <div>
                    <LabelCustom
                      labelName={"Estimated Completion Time"}
                      mandatory={true}
                    />
                    <DatePickerFormiks name="EstimatedTimetoComplete" />
                  </div>
                  <div>
                    <LabelCustom
                      labelName={"Downtime Required For Implementation"}
                      mandatory={true}
                    />
                    <TimeCustomFormik 
                    ampm={false}  name="DowntimeHours" />
                  </div>
                  <div>
                    <LabelCustom
                      labelName={"Change Request Priority"}
                      mandatory={true}
                    />
                    <SelectForm
                      name="Priority"
                      options={option}
                      placeholder="Select Priority"
                    />
                  </div>
                  <div>
                    <LabelCustom
                      labelName={"Notes"}
                      mandatory={true}
                    />
                    <TextAreaFormik
                      style={{ height: "100px" }}
                      name="Remarks"
                      maxLength="500"
                      placeholder="Please Enter Description"
                    />
                  </div>
                </div>
                <div className="createFootCust" style={{ position: "inherit" }}>
                  <div>
                    <button
                      className="button secondaryButton"
                      style={{ background: "var(--secondary)", color: "white" }}
                      type="button"
                      onClick={() => togglePopup(data?.itsmChangeRequest?.id)}
                    >
                      Refer Back
                    </button>
                  </div>
                  <div>
                    <button className="button primaryButton" type="submit">
                      Approve
                    </button>
                  </div>
                  <div>
                    <button
                      className="deletebtn"
                      type="reset"
                      onClick={() => onSubmit(values, "reject")}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </Form>
             </div>
            );
          }}
        </Formik>
        {isOpen && (
          <Popup
            popupHeading={"Refer Back"}
            content={
              <>
                <ReferBack
                  ChangeType={data?.itsmChangeRequest?.changeType}
                  Reason={data?.itsmChangeRequest?.reason}
                  Priority={data?.itsmChangeRequest?.priority}
                  DescriptionOfchange={
                    data?.itsmChangeRequest?.descriptionOfchange
                  }
                  getDetails={getDetails}
                  type={"HOD"}
                  id={selId}
                  handleClose={togglePopup}
                />
              </>
            }
            handleClose={togglePopup}
          />
        )}
      </>
    );
};

export { HodApproval };
