import React, { useState, useEffect } from "react";
import Goalsettingpic from "../../Assets/Goalsettingpic.svg";
import PerformanceCyclepic from "../../Assets/PerformanceCyclepic.svg";
import Rewardspic from "../../Assets/Rewardspic.svg";
import "./PMSMaster.css";
import { useLocation, useNavigate } from "react-router-dom";
import { GoalSetting } from "./Components/GoalSetting";
import { PerformanceCycle } from "./Components/PerformanceCycle";
import { Rewards } from "./Components/Rewards";
import { ModuleMasterDetailsPage } from "../HRMSMaster/Components";
import { CreateGoalSetting } from "./Components/CreateGoalSetting";
import { CreateReward } from "./Components/CreateReward";
import { CreatePerformanceCycle } from "./Components/CreatePerformanceCycle";
import { DetailsRewards } from "./Components/DetailsRewards";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../Services/axios";
import { loggedOut } from "../../Features";
import { GroupBy } from "../../Utilities";
import Skeleton from "react-loading-skeleton";

const PMSMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("goalsetting");
  const [activeFeature, setActiveFeature] = useState("");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "pms"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {
             
          }
        });
      }
    } catch (err) {
       
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("pmsmaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "pmsmaster");
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
  }, [location.pathname, subMenuList]);
  // const getFeatureMenu = (submenu) => {
  //   if (!subMenuList) return null;
  //   return subMenuList?.find(
  //     (obj) =>
  //       obj?.[0]?.subMenuName
  //         ?.replaceAll(" ", "")
  //         ?.replaceAll("&", "")
  //         ?.toLowerCase() === submenu
  //   );
  // };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "onboarding") return Goalsettingpic;
    if (subMenuName === "performancecycle") return PerformanceCyclepic;
    if (subMenuName === "rewards") return Rewardspic;
    return Goalsettingpic;
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "goalsetting" && (
        <>
          {createActive && <CreateGoalSetting title="Create Goal Setting" />}
          {detailsActive && <ModuleMasterDetailsPage />}
        </>
      )}
      {activeSubmodule === "performancecycle" && (
        <>
          {createActive && (
            <CreatePerformanceCycle title="Create Performance Cycle" />
          )}
          {detailsActive && <ModuleMasterDetailsPage />}
        </>
      )}
      {activeSubmodule === "rewards" && (
        <>
          {createActive && <CreateReward title="Create Reward" />}
          {detailsActive && <DetailsRewards />}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/pmsmaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "goalsetting" ? <GoalSetting /> : null}
            {activeSubmodule === "performancecycle" ? (
              <PerformanceCycle />
            ) : null}
            {activeSubmodule === "rewards" ? <Rewards /> : null}
          </>
        )}
      </div>
    </div>
  );
};

export { PMSMaster };
