import React from 'react';

import { HotelsTab } from './HotelsTab';
import { useEffect } from 'react';

const Hotels = () => {
    useEffect(() => {
        document.title = `PeopleSol - Hotels `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
          
            <HotelsTab title={'Hotels'}/>
            
        </div>
    )
}

export { Hotels }