/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
} from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import MoreActivityContainer from "../../../../Components/Table/MoreActivityContainer";
import ApproveIcon from "../../../../Assets/ApproveIcon.svg";
import RejectIcon from "../../../../Assets/RejectIcon.svg";
import HoldIcon from "../../../../Assets/HoldIcon.svg";
import CancelIcon from "../../../../Assets/CancelIcon.svg";
import { TransactionTable } from "../../../../Components/Table/TransactionTable";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

import { useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { ApprovePopup } from "../../../../Components/FormComponent/PopupComponent/ApprovePopup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { Allocation } from "./Form/Allocation";
import { AdminCreate } from "./Form/AdminCreate";
import { AdminUpdate } from "./Form/AdminUpdate";
import { Surrender } from "./Form/Surrender";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../Features/badgeUtils";
const Facilities = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = [
    { label: "Deallocated", value: "Deallocated" },
    { label: "Allocated", value: "Allocated" },
    { label: "Completed", value: "Completed" },
    { label: "Pending", value: "Pending" },
    { label: "Accepted", value: "Accepted" },
    { label: "Rejected", value: "Rejected" },
    { label: "Maintenance", value: "Maintenance" },
    { label: "Surrender Initiated", value: "Surrender Initiated" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedTransferTypeList, setSelectedTransferTypeList] = useState([]);
  const [selectedfacilityTypeList, setSelectedfacilityTypeList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [optionEmployees, setOptionEmployees] = React.useState([]);
  const [optionEmployee, setOptionEmployee] = React.useState([]);
  const [optionfacilityType, setOptionFacilityType] = React.useState([]);
  const [typeAll, setTypeAll] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [selId, setSelId] = useState(false);
  const [dateSelect, setDateSelect] = useState(false);
  const [extra, setExtra] = useState("");
  const togglePopup = (type, color, id, extra) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setExtra(extra);
    setIsOpen((prev) => !prev);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const togglePopupapprove = (type) => {
    setTypeAll(type);
    setIsApprovePopup((prev) => !prev);
  };
  useEffect(() => {
    document.title = `PeopleSol - Facilitiest`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const clickHandlerFunction = (id, type, color, extra) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Accept" &&
      type !== "Complete" &&
      type !== "Maintenance"
    ) {
      togglePopup(type, color, id, extra);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    // //console.log("type", type);
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Confirm"
      ) {
        result = await postRequest(`FacilityRequests/${type}?Id=${id}`);
        if (result) {
          dispatch(
            showToast({ text: result?.data?.message, severity: "success" })
          );
        }
      } else if (type === "Maintenance") {
        result = await postRequest(
          `FacilityRequests/ModificationRequests?ID=${id}&&type=${type}`
        );
        if (result) {
          dispatch(
            showToast({ text: result?.data?.message, severity: "success" })
          );
        }
      } else if (type === "Accept") {
        const result = await postRequest(`FacilityRequests/Complete?Id=${id}`);
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      } else if (type === "Complete") {
        const result = await postRequest(
          `FacilityRequests/ModificationApprove?ID=${id}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
        getMaiintenenceListData();
      } else {
        const result = await postRequest(
          `FacilityRequests/${type}?Id=${id}&Remarks=${Remark}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      if (activeList === "1") getListData();
      else if (activeList === "2") getAllListData();
      else if (activeList === "3") getAllocateList();
      else if (activeList === "4") getSurrenderListData();
      else if (activeList === "10") getEmployeeAdminFacility();
      else if (activeList === "11") getMyFacilitiesListData();
    }
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  // //console.log("LoggedInUser", LoggedInUser);
  const defaultList = "FacilitidesReqDL";
  // const ActivityList = [
  //   {
  //     id: 1,
  //     actionTitle: "My Requests",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "1",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 6,
  //     actionTitle: "Team Requests",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "6",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 2,
  //     actionTitle: "All Requests",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "2",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 11,
  //     actionTitle: "My Facilities",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "11",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 5,
  //     actionTitle: "Map Facilities",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "5",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 3,
  //     actionTitle: "Allocate Requests",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "3",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 4,
  //     actionTitle: "Surrender Requests",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "4",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 7,
  //     actionTitle: "Maintenance Requests ",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "7",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 8,
  //     actionTitle: "Facility Type Summary ",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "8",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 9,
  //     actionTitle: "Facility Category Summary  ",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "9",
  //     defaultList: defaultList,
  //   },
  //   {
  //     id: 10,
  //     actionTitle: "Employee Facility Request",
  //     badge: 0,
  //     favourite: (localStorage.getItem(defaultList) ?? "1") === "10",
  //     defaultList: defaultList,
  //   },
  // ];
// console.log("LoggedInUser?.isHRHead",LoggedInUser?.isHRHead)
  const [ActivityList, setActivityList] = useState([
    {
      id: 1,
      actionTitle: "My Requests",
      badge: 0,
      favourite: (localStorage.getItem(defaultList) ?? "1") === "1",
      defaultList: defaultList,
    },
   
  ]);
  const getPermissions = async () => {
    setLoading(true);
    if (LoggedInUser?.isReportingManager) {
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 2)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 2,
              actionTitle: "Team Requests",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "2",
              defaultList: defaultList,
            },
          ];
        }
      });
    }
  
     if (
      LoggedInUser?.isHRHead ||
      LoggedInUser?.isHRDirector ||
      LoggedInUser?.teamnames?.includes("HR Team") ||
      LoggedInUser?.hrbpLocationIds?.length ||
      LoggedInUser?.taAreaIds?.length ||
      LoggedInUser?.hrbpDepartmentIds?.length
    ) {
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 6)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 6,
              actionTitle: "All Requests",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "6",
              defaultList: defaultList,
            },
          ];
        }
      });
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 5)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 5,
              actionTitle: "Map Facilities",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "5",
              defaultList: defaultList,
            },
          ];
        }
      });
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 7)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 7,
              actionTitle: "Maintenance Requests ",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "7",
              defaultList: defaultList,
            },
          ];
        }
      });
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 11)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 11,
              actionTitle: "My Facilities",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "11",
              defaultList: defaultList,
            },
          ];
        }
      });
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 8)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 8,
              actionTitle: "Facility Type Summary ",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "8",
              defaultList: defaultList,
            },
          ];
        }
      });
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 3)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 3,
              actionTitle: "Allocate Requests ",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "3",
              defaultList: defaultList,
            },
          ];
        }
      });
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 9)) {
          return prev;
        } else {
          return [
            ...prev,
            {
              id: 9,
              actionTitle: "Facility Category Summary  ",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "9",
              defaultList: defaultList,
            },
          ];
        }
      });

      setActivityList((prev) => {
        if (prev.some((e) => e.id === 4)) {
          let index = prev.findIndex((e) => e.id === 4);
          let newArr = [...prev]; // copying the old datas array
          newArr[index] = {
            id: 4,
            actionTitle: "Surrender Requests",
            badge: 0,
            favourite: (localStorage.getItem(defaultList) ?? "1") === "4",
            defaultList: defaultList,
          };
          return newArr;
        } else {
          return [
            ...prev,
            {
              id: 4,
              actionTitle: "Surrender Requests",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "4",
              defaultList: defaultList,
            },
          ];
        }
      });
      setActivityList((prev) => {
        if (prev.some((e) => e.id === 10)) {
          let index = prev.findIndex((e) => e.id === 10);
          let newArr = [...prev]; // copying the old datas array
          newArr[index] =  {
            id: 10,
            actionTitle: "Employee Facility Request",
            badge: 0,
            favourite: (localStorage.getItem(defaultList) ?? "1") === "10",
            defaultList: defaultList,
          };
          return newArr;
        } else {
          return [
            ...prev,
            {
              id: 10,
              actionTitle: "Employee Facility Request",
              badge: 0,
              favourite: (localStorage.getItem(defaultList) ?? "1") === "10",
              defaultList: defaultList,
            },
          ];
        }
      });
    }
    setLoading(false);
  };
  const [activeList, setActiveList] = useState(
    localStorage.getItem(defaultList) ?? "1"
  );
  function handleActiveListChange(newValue) {
    setActiveList(newValue.toString());
    setSelectedRows([]);
    setRecordsSelected(noOfRecords[0]);
    setSearchParam(null);
    setSortParam("Id");
    setSortType("descending");
    setReqData({
      createdOn: null,
      lastUpdatedOn: null,
      dataTableRequest: {
        iDisplayLength: noOfRecords[0].value,
        iDisplayStart: 0,
        sSearch: null,
        sorting: "Id",
        sSortDir_0: "descending",
        isExport: false,
      },
    });
  }
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      var result;
      var element = document.createElement("a");
      if (activeList === "1") {
        result = await postRequest(
          "FacilityRequests/MyRequestsList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "2") {
        result = await postRequest(
          "FacilityRequests/AllRequestsList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "3") {
        result = await postRequest(
          "FacilityRequests/AllocateRequestsList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "4") {
        result = await postRequest(
          "FacilityRequests/surrenderRequestsList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "5") {
        result = await postRequest(
          "FacilityRequests/dispatchedFacilityRequestsList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "6") {
        result = await postRequest(
          "FacilityRequests/MyTeamList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "7") {
        result = await postRequest(
          "FacilityRequests/ModificationFacilitiyRequestsList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "8") {
        result = await postRequest(
          "FacilityRequests/FacilitiesSummaryList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "9") {
        result = await postRequest(
          "FacilityRequests/FacilityCategorySummaryList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else if (activeList === "10") {
        result = await postRequest(
          "FacilityRequests/EmployeeFacilitiesList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      } else {
        result = await postRequest(
          "FacilityRequests/TeamList",
          downloadReqData
        );
        element.setAttribute(
          "href",
          `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
        );
      }

      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in donwloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getStaticActions = (data) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    if (
      data.status.replace(/<[^>]+>/g, "") !== "Cancelled" &&
      data.status.replace(/<[^>]+>/g, "") !== "Rejected" &&
      data.status.replace(/<[^>]+>/g, "") !== "Closed" &&
      data.status.replace(/<[^>]+>/g, "") !== "Released" &&
      data.status.replace(/<[^>]+>/g, "") !== "Amount Received"
    ) {
      returnActionArray.push({
        img: <img src={CancelIcon} alt="Cancel" />,
        action: "Cancel Request",
        clickAction: (id) => {
          cancelRequest(id);
        },
      });
      returnActionArray.push({
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z"
              fill="#FB7158"
            />
            <path
              d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z"
              stroke="#FB7158"
              strokeWidth="0.698594"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
        action: "Edit Request",
        clickAction: (id) => handleEdit(id),
      });
    }
    return returnActionArray;
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/MyRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: false,
              needFor: item?.item?.type,
              requestNumber: item?.item?.referenceNo,
              statusColor: item.statusColor,
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              actions: getStaticActions(item),
              moreActions: getMoreActions(item?.actions),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ projectDuration, moreActions, projectDelayed, ...rest }) => ({
              ...rest,
            })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              facility,
              status,
              needFor,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              facility,
              status,
              needFor,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getAllListCheckStatus = (data) => {
    let returnValue = false;
    data?.actions?.map((item) => {
      if (item.type === "Approve" || item.type === "Reject") returnValue = true;
      return null;
    });
    return returnValue;
  };
  const handleEdit = (id) => {
    navigate("/hrms/benefits/facilities/create", {
      state: { id },
    });
  };
  const getActions = (actionList, item) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) =>
          activeList === "2" || activeList === "6"
            ? getFormDetails(id)
            : activeList === "7"
            ? getFormDetails(item?.item?.facility.id)
            : getFormDetails(item?.item?.facilityRequestId),
      },
    ];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (action.type === "Approve") {
          returnActionArray.push({
            img: <img src={ApproveIcon} alt="Approve" />,
            action: "Approve Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Approve", "rgb(255, 128, 0)"),
          });
        }
        if (action.type === "Reject") {
          returnActionArray.push({
            img: <img src={RejectIcon} alt="Reject" />,
            action: "Reject Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Reject", "red"),
          });
        }
        if (action.type === "Hold") {
          returnActionArray.push({
            img: <img src={HoldIcon} alt="Hold" />,
            action: "Hold Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Hold", "rgb(255, 128, 0)"),
          });
        }
        return null;
      });
    }
    return returnActionArray;
  };
  const getMoreActions = (actionList, item) => {
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              action.type === "Readiness"
                ? clickHandlerFunction(
                    item?.item?.id,
                    action?.type,
                    action?.color
                  )
                : action.type === "Accept" || action.type === "Complete"
                ? clickHandlerFunction(action?.id, action?.type, action?.color)
                : clickHandlerFunction(
                    item?.item?.assetcategoryId,
                    action?.type,
                    item?.item?.employeeId
                  ),
          });
        }
        return null;
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getAllListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/AllRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              needFor: item?.item?.type,
              requestNumber: item?.item?.referenceNo,
              checkActive: getAllListCheckStatus(item),
              statusColor: item.statusColor,
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions, item),
              moreActions: getMoreActions(item?.actions),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              status,
              employee,
              facility,
              needFor,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              status,
              employee,
              facility,
              needFor,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getEmployeeAdminFacility = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/EmployeeFacilitiesList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            return tempData.push({
              ...item.item,
              facilityMaterial: item?.item?.facilitymaterialname,
              facilityCategory: item?.item?.categoryName,
              facilityName: item?.item?.facilityname,
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(25, 28) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(25, 28) === "new"
                  ? "#755681"
                  : item.status?.slice(25, 28) === "dan"
                  ? "red"
                  : item.status?.slice(25, 28) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(25, 28) === "suc"
                  ? "#99131D"
                  : item.status?.slice(25, 28) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(25, 28) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              moreActions: getMoreAction(item?.actions, item),
            });
          });
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              employeeName,
              status,
              facilityName,
              facilityCategory,
              facilityMaterial,
              allocatedOn,
              createdOn,
            }) => ({
              id,
              employeeName,
              status,
              facilityName,
              facilityCategory,
              facilityMaterial,
              allocatedOn,
              createdOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getMaiintenenceListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/ModificationFacilitiyRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result?.data?.data?.map((item) => {
            return tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              facilityMaterial: item?.item?.facility?.facilityMaterial,
              facilityCategory: item?.item?.facility?.category,
              facilityReferenceNumber: item?.item?.assetId,
              facilityType: item?.item?.assetType,
              statusColor: item.statusColor,
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getAction(item?.actions, item),
              moreActions: getMoreActions(item?.actions),
            });
          });
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, facility, moreActions, ...rest }) => ({
              ...rest,
            })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              status,
              facilityReferenceNumber,
              facilityType,
              employeeName,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              status,
              facilityReferenceNumber,
              facilityType,
              employeeName,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getTeamListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("FacilityRequests/MyTeamList", reqData);
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              needFor: item?.item?.type,
              requestNumber: item?.item?.referenceNo,
              checkActive: getAllListCheckStatus(item),
              statusColor: item.statusColor,
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions, item),
              moreActions: getMoreActions(item?.actions),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              status,
              employee,
              facility,
              needFor,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              status,
              employee,
              facility,
              needFor,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getdispatchAction = (actionList, item) => {
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (action.type === "Readiness") {
          returnActionArray.push({
            img: <img src={ApproveIcon} alt="Approve" />,
            action: "Readiness",
            clickAction: () =>
              clickHandlerFunction(item?.item?.id, action?.type, action?.color),
            id: action?.id,
          });
        }
        return null;
      });
    }
    return returnActionArray;
  };
  const getActionMy = (actionList, item) => {
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              clickHandlerFunction(
                action?.id,
                action?.type,
                action?.color,
                item?.item?.status
              ),
          });
        }
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getAction = (actionList) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (action.type === "Approve") {
          returnActionArray.push({
            img: <img src={ApproveIcon} alt="Approve" />,
            action: "Approve Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Approve", "rgb(255, 128, 0)"),
          });
        }
        if (action.type === "Reject") {
          returnActionArray.push({
            img: <img src={RejectIcon} alt="Reject" />,
            action: "Reject Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Reject", "red"),
          });
        }
        if (action.type === "Hold") {
          returnActionArray.push({
            img: <img src={HoldIcon} alt="Hold" />,
            action: "Hold Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Hold", "rgb(255, 128, 0)"),
          });
        }
        return null;
      });
    }
    return returnActionArray;
  };
  const getMoreAction = (actionList, item) => {
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              clickHandlerFunction(
                action?.id,
                action?.type,
                action?.color,
                item?.item?.status
              ),
          });
        }
        return null;
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getSurrenderListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/surrenderRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              facilityReferenceNumber: item?.item?.facilityID,
              statusColor: item.statusColor,
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getAction(item?.actions),
              moreActions: getMoreActions(item?.actions),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, facility, ...rest }) => ({
              ...rest,
            })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              status,
              employee,
              facilityReferenceNumber,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              status,
              employee,
              facilityReferenceNumber,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const dispatchAdminListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/dispatchedFacilityRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            return tempData.push({
              ...item.item,
              facilityType: item.item.name,
              facilityCategory: item.item.category,
              facilityReferenceNumber: item?.item?.facilityId,
              checkActive: getAllListCheckStatus(item),
              statusColor: item.statusColor,
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getdispatchAction(item?.actions, item),
            });
          });
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              facilityReferenceNumber,
              status,
              facilityType,
              facilityCategory,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              facilityReferenceNumber,
              status,
              facilityType,
              facilityCategory,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const facilitiesSummary = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/FacilitiesSummaryList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            return tempData.push({
              ...item.item,
              facilityType: item.item.name,
              allocated: item.item._allocated,
              maintenance: item.item._maintenance,
              checkActive: false,
              actions: getAction(item?.actions),
            });
          });
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({ id, facilityType, stockInHand, maintenance, allocated }) => ({
              id,
              facilityType,
              stockInHand,
              maintenance,
              allocated,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const facilitiesCategory = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/FacilityCategorySummaryList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            return tempData.push({
              ...item.item,
              facilityCategory: item.item.name,
              allocated: item.item._allocated,
              maintenance: item.item._maintenance,
              checkActive: false,

              // tatColor: item?.tat[0]?.color,
              actions: getAction(item?.actions),
              moreActions: getMoreActions(item?.actions, item),
            });
          });
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              facilityCategory,
              stockInHand,
              maintenance,
              allocated,
            }) => ({
              id,
              facilityCategory,
              stockInHand,
              maintenance,
              allocated,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getMyFacilitiesListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/MyFacilitiesList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              facilityCategory: item?.item?.categoryName,
              facilityReferenceNumber: item?.item?.facilitynumber,
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getAction(item?.actions),
              moreActions: getActionMy(item?.actions, item),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              facilityReferenceNumber,
              status,
              facilityCategory,
              facilityType,
              allocatedOn,
              deallocatedOn,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              facilityReferenceNumber,
              status,
              facilityCategory,
              facilityType,
              allocatedOn,
              deallocatedOn,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getAllocateList = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/AllocateRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              facilityCategory: item?.item?.assetcategory,
              statusColor:
                item.status?.slice(25, 28) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(25, 28) === "new"
                  ? "#755681"
                  : item.status?.slice(25, 28) === "dan"
                  ? "red"
                  : item.status?.slice(25, 28) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(25, 28) === "suc"
                  ? "#99131D"
                  : item.status?.slice(25, 28) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(25, 28) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions, item),
              moreActions: getMoreActions(item?.actions, item),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              ticketNumber,
              status,
              facilityCategory,
              employee,
              stockInHand,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              ticketNumber,
              status,
              facilityCategory,
              employee,
              stockInHand,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getFacility = async () => {
    try {
      const result = await getRequest(
        "FacilityCategories/GetFacilityCategories"
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionEmployee(listData);
    } catch (error) {
    } finally {
    }
  };
  const getEmployee = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetEmployees");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionEmployees(listData);
    } catch (error) {
    } finally {
    }
  };
  const getFacilityType = async () => {
    try {
      const result = await getRequest("FacilityTypes/GetFacilityType");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionFacilityType(listData);
    } catch (error) {
    } finally {
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("facilities")){
      getPermissions()
    }
      if (activeList === "1") getListData();
      else if (activeList === "6") getAllListData();
      else if (activeList === "3") getAllocateList();
      else if (activeList === "4") getSurrenderListData();
      else if (activeList === "5") dispatchAdminListData();
      else if (activeList === "2") getTeamListData();
      else if (activeList === "7") getMaiintenenceListData();
      else if (activeList === "8") facilitiesSummary();
      else if (activeList === "9") facilitiesCategory();
      else if (activeList === "10") getEmployeeAdminFacility();
      else if (activeList === "11") getMyFacilitiesListData();
    
  }, [location.pathname,reqData]);



  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const getFormDetails = (id) => {
    activeList === "8" || activeList === "9"
      ? navigate(`/hrmsmaster/benefits/category/details/${id}`)
      : activeList === "4"
      ? navigate(`/hrms/benefits/facilities/surrender/${id}`)
      : activeList === "11"
      ? navigate(`/hrms/benefits/facilities/myallocated/${id}`)
      : activeList === "7"
      ? navigate(`/hrms/benefits/facilities/maintenence/${id}`)
      : navigate(`/hrms/benefits/facilities/details/${id}`);
  };
  const cancelRequest = (id) => {
    clickHandlerFunction(id, "Cancel", "red");
  };

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);

      if (activeList === "3"|| activeList === "8") {
        if (heading === "facilityReferenceNumber") heading = "facilityId";
        else if (heading === "facilityType") heading = "name";
        else if (heading === "facilityCategory") heading = "assetcategory";
      } else if (activeList === "5" || activeList === "4") {
        if (heading === "facilityReferenceNumber") heading = "facilityId";
        else if (heading === "facilityType") heading = "name";
        else if (heading === "facilityCategory") heading = "category";
      } else if (activeList === "7") {
        if (heading === "facilityReferenceNumber") {
          heading = "assetId";
        } else heading = "facilityID";
      } else if (activeList === "11" || activeList === "10") {
        if (heading === "facilityCategory") heading = "categoryName";
        if (heading === "facilityMaterial") heading = "facilitymaterialname";
      } else if (activeList === "9") {
        if (heading === "facilityCategory") heading = "name";
      } else if (activeList !== "4") {
        if (heading === "requestNumber") heading = "referenceNo";
      } else if (
        activeList !== "9" &&
        activeList !== "10" &&
        activeList !== "11"
      ) {
        if (heading === "facilityType") heading = "name";
        else if (heading === "facilityCategory") heading = "category";
        else if (heading === "needFor") heading = "type";
      }

      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      status: selectedStatusList[0]?.value,
      FacilityTypeID: selectedfacilityTypeList[0]?.value,
      FacilityCategory: selectedTransferTypeList[0]?.value,
      employeeId: selectedEmployeeList[0]?.value,
      date: dateSelect ? `${format(dateSelect, "yyyy/MM/dd")}` : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setSelectedStatusList([]);
    setDateSelect(false);
    setSelectedTransferTypeList([]);
    setSelectedfacilityTypeList([]);
    setSelectedEmployeeList([]);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      status: null,
      date: null,
      FacilityCategory: null,
      FacilityTypeID: null,
      employeeId: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
    } else {
      let arr = [];
      tbodyData?.map((item) => {
        if (
          originalData.find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
            .actions?.length > 1
        )
          arr.push(item.Id ?? item.id);
        return null;
      });
      setSelectedRows(arr);
    }
  };
  const handleDelete = async (selectedRows) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      await deleteWithPayloadRequest(
        `FacilityRequests/CancelAll`,
        selectedRows
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      getListData();
    }
  };
  const deleteSelected = () => {
    if (selectedRows.length === 1) {
      confirmAlert({
        title: "Confirm cancel",
        message: `Are you sure you want to cancel this request?`,
        buttons: [
          {
            label: "Yes, confirm cancel",
            onClick: () => handleDelete(selectedRows),
          },
          {
            label: "No, cancel cancel",
            onClick: () => setSelectedRows([]),
          },
        ],
      });
    } else {
      confirmAlert({
        title: "Confirm cancel",
        message: `Are you sure you want to cancel these ${selectedRows.length} requests?`,
        buttons: [
          {
            label: "Yes, confirm cancel",
            onClick: () => handleDelete(selectedRows),
          },
          {
            label: "No, cancel cancel",
            onClick: () => setSelectedRows([]),
          },
        ],
      });
    }
  };
  const handleApprove = async (selectedRows, typeAll) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
        return tempIds.push(
          originalData.find((x) => x.Id ?? x.id === item)?.nextRequestId
        );
      });
      await deleteWithPayloadRequest(`FacilityRequests/${typeAll}`, tempIds);
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      if (isApprovePopup) togglePopupapprove();
      getAllListData();
    }
  };
  const rejectAll = async (typeAll, selectedRows, remarks) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
        return tempIds.push(
          originalData.find((x) => x.Id ?? x.id === item)?.nextRequestId
        );
      });
      await deleteWithPayloadRequest(
        `FacilityRequests/${typeAll}?Remarks=${remarks}`,
        tempIds
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      if (isApprovePopup) togglePopupapprove();
      getAllListData();
    }
  };

  const childRef = useRef(null);
  function handleActivityClick() {
    childRef.current.updateState();
  }
  function handleActivityClose() {
    childRef.current.closeState();
  }
  return (
    <div className="fullTableContainer">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              onClick={() => setFilterSearchToogle((prev) => !prev)}
              title="close"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          {activeList === "9" ? (
            <div className="filterListContainer">
              <div className="filterContainer">
                <p> Facility Category </p>
                <SelectCustom
                  onDropdownOpen={() => {
                    getFacility();
                  }}
                  options={optionEmployee}
                  values={selectedTransferTypeList}
                  onChange={(value) => {
                    setSelectedTransferTypeList(value);
                  }}
                  placeholder="Select Facility Category"
                />
              </div>
            </div>
          ) : activeList === "8" || activeList === "5" ? (
            <div className="filterListContainer">
              <div className="filterContainer">
                <p> Facility Type </p>
                <SelectCustom
                  onDropdownOpen={() => {
                    getFacilityType();
                  }}
                  placeholder="Select Facility Type"
                  options={optionfacilityType}
                  values={selectedfacilityTypeList}
                  onChange={(value) => {
                    setSelectedfacilityTypeList(value);
                  }}
                />
              </div>
              {activeList === "5" && (
                <>
                  <div className="filterContainer">
                    <p>Created On</p>
                    <DateRangePicker
                      selectedEnd={createdOnEndDate}
                      selectedStart={createdOnStartDate}
                      onChangeStart={(date) => setCreatedOnStartDate(date)}
                      onChangeEnd={(date) => setCreatedOnEndDate(date)}
                    />
                  </div>
                  <div className="filterContainer">
                    <p>Last Updated On</p>
                    <DateRangePicker
                      selectedEnd={lastUpdatedOnEndDate}
                      selectedStart={lastUpdatedOnStartDate}
                      onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                      onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
                    />
                  </div>
                </>
              )}
            </div>
          ) : activeList === "4" ? (
            <div className="filterListContainer">
              <div className="filterContainer">
                <p> Status </p>
                <SelectCustom
                  placeholder="Select Status"
                  options={status}
                  values={selectedStatusList}
                  onChange={(value) => {
                    setSelectedStatusList(value);
                  }}
                />
              </div>
              <div className="filterContainer">
                <p> Employee </p>
                <SelectCustom
                  onDropdownOpen={() => {
                    getEmployee();
                  }}
                  options={optionEmployees}
                  values={selectedEmployeeList}
                  onChange={(value) => {
                    setSelectedEmployeeList(value);
                  }}
                />
              </div>
              <div className="filterContainer">
                <p>Created On</p>
                <DateRangePicker
                  selectedEnd={createdOnEndDate}
                  selectedStart={createdOnStartDate}
                  onChangeStart={(date) => setCreatedOnStartDate(date)}
                  onChangeEnd={(date) => setCreatedOnEndDate(date)}
                />
              </div>
              <div className="filterContainer">
                <p>Last Updated On</p>
                <DateRangePicker
                  selectedEnd={lastUpdatedOnEndDate}
                  selectedStart={lastUpdatedOnStartDate}
                  onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                  onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="filterListContainer">
                {(activeList === "3" || activeList === "2") && (
                  <div className="filterContainer">
                    <p> Employee </p>
                    <SelectCustom
                      onDropdownOpen={() => {
                        getEmployee();
                      }}
                      options={optionEmployees}
                      values={selectedEmployeeList}
                      onChange={(value) => {
                        setSelectedEmployeeList(value);
                      }}
                    />
                  </div>
                )}
                <div className="filterContainer">
                  <p> Status </p>
                  <SelectCustom
                    placeholder="Select Status"
                    options={status}
                    values={selectedStatusList}
                    onChange={(value) => {
                      setSelectedStatusList(value);
                    }}
                  />
                </div>
                <div className="filterContainer">
                  <p> Facility Category </p>
                  <SelectCustom
                    placeholder="Select Date"
                    onDropdownOpen={() => {
                      getFacility();
                    }}
                    options={optionEmployee}
                    values={selectedTransferTypeList}
                    onChange={(value) => {
                      setSelectedTransferTypeList(value);
                    }}
                  />
                </div>

                <div className="filterContainer">
                  <p>Created On</p>
                  <DateRangePicker
                    selectedEnd={createdOnEndDate}
                    selectedStart={createdOnStartDate}
                    onChangeStart={(date) => setCreatedOnStartDate(date)}
                    onChangeEnd={(date) => setCreatedOnEndDate(date)}
                  />
                </div>
                <div className="filterContainer">
                  <p>Last Updated On</p>
                  <DateRangePicker
                    selectedEnd={lastUpdatedOnEndDate}
                    selectedStart={lastUpdatedOnStartDate}
                    onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                    onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
                  />
                </div>
              </div>
            </>
          )}
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer">
        {selectedRows.length > 0 ? (
          <>
            {activeList === "1" && (
              <button className="deletebtn" onClick={deleteSelected}>
                <span className="deletebtnflex">
                  <p>Cancel</p>
                  <span>{`(${selectedRows.length})`}</span>
                </span>
              </button>
            )}
            {activeList === "2" && (
              <>
                <button
                  className="button primaryButton"
                  onClick={() => togglePopupapprove("ApproveAll")}
                >
                  <span className="deletebtnflex">
                    <p>Approve</p>
                    <span>{`(${selectedRows.length})`}</span>
                  </span>
                </button>
                <button
                  className="deletebtn"
                  onClick={() => togglePopupapprove("RejectAll")}
                >
                  <span className="deletebtnflex">
                    <p>Reject</p>
                    <span>{`(${selectedRows.length})`}</span>
                  </span>
                </button>
              </>
            )}
          </>
        ) : activeList === "9" || activeList === "8" || activeList === "10" ? (
          ""
        ) : (
          <button
            onClick={() => {
              activeList === "5"
                ? togglePopup("Admin Facilities", "red", 0)
                : navigate("/hrms/benefits/facilities/create");
            }}
            className="button primaryButton"
            type="submit"
          >
            + Create {activeList === "5" ? "New Facility" : "Facility Request"}
          </button>
        )}
        <OutsideClickHandler onOutsideClick={handleActivityClose}>
          <div className="moreActivityLabelContainer cursor not-selectable">
            <MoreActivityContainer
              ref={childRef}
              ActivityList={ActivityList}
              onChange={handleActiveListChange}
              activeList={activeList}
            />
            <div
              className="moreActivityLabelHolder"
              onClick={handleActivityClick}
            >
              <label style={{ color: "var(--primary)", fontWeight: 500 }}>
                {
                  ActivityList.find((x) => x.id.toString() === activeList)
                    ?.actionTitle
                }
              </label>
            </div>
          </div>
        </OutsideClickHandler>

        <div className="search-box">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">
          <IconButton
            color="primary"
            onClick={() => {
              setFilterSearchToogle((prev) => !prev);
            }}
          >
            {!filterSearchToogle ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Download" placement="top">
          <IconButton color="primary" onClick={exportTable}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </Tooltip>
      </div>
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            {activeList === "1" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
              />
            )}
            {activeList === "2" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesAllListFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "3" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesAllocateListFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "4" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesSurrenderListFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "5" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesdispatchListFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "6" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesTeamListFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "7" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesMaintenanceFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "8" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesTypesFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "9" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesCategoryFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "10" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesEmployeeadminFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
                containTat={true}
              />
            )}
            {activeList === "11" && (
              <TransactionTable
                selectAll={selectAll}
                tableFilterName="facilitiesMyFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
              />
            )}
          </>
        )}
        <div className="horizontalLine"></div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords}
            dropdownPosition="top"
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </>

      {isOpen &&
        (loading ? (
          ""
        ) : (
          <Popup
            popupHeading={
              typeAction === "Admin Facilities"
                ? "Create New Facility"
                : typeAction
            }
            content={
              <>
                {typeAction === "Allocate" ? (
                  <Allocation
                    data={getAllocateList}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                ) : typeAction === "Admin Facilities" ? (
                  <AdminCreate
                    data={dispatchAdminListData}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                ) : typeAction === "Readiness" ? (
                  <AdminUpdate
                    data={dispatchAdminListData}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                ) : typeAction === "Initiate Surrender" ? (
                  <Surrender
                    data={getEmployeeAdminFacility}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={togglePopup}
                    eid={extra}
                  />
                ) : typeAction === "Surrender" ? (
                  <Surrender
                    data={getAllListData}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={togglePopup}
                    eid={extra}
                  />
                ) : (
                  <Remark
                    data={PostApRejHol}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                )}
              </>
            }
            handleClose={handleClose}
          />
        ))}
      {isApprovePopup && (
        <ApprovePopup
          popupHeading={typeAll}
          selectedRows={selectedRows}
          type={typeAll}
          content={
            <div style={{ display: "flex" }}>
              {typeAll === "ApproveAll" ? (
                <>
                  <button
                    className="button"
                    onClick={() => {
                      handleApprove(selectedRows, typeAll);
                    }}
                  >
                    Confirm
                  </button>
                  <button className="button">Cancel</button>
                </>
              ) : (
                <div style={{ width: "100%" }}>
                  <Remark
                    data={rejectAll}
                    type={typeAll}
                    id={selectedRows}
                    color={"red"}
                    handleClose={togglePopupapprove}
                  />
                </div>
              )}
            </div>
          }
          handleClose={togglePopupapprove}
        />
      )}
    </div>
  );
};

export { Facilities };
