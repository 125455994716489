import React, { useState, useEffect } from "react";
import onboardingpic from "../../Assets/Onboardingpic.svg";
import CompanyPoliciespic from "../../Assets/CompanyPoliciespic.svg";
import TimeAttendancepic from "../../Assets/TimeAttendancepic.svg";
import ProjectsTaskspic from "../../Assets/ProjectsTaskspic.svg";
import HRDeskpic from "../../Assets/HRDeskpic.svg";
import Benefitspic from "../../Assets/Benefitspic.svg";
import Transferpic from "../../Assets/Transferpic.svg";
import Separationpic from "../../Assets/Separationpic.svg";
import ReportsKPIpic from "../../Assets/ReportsKPI.svg";
import OrgStructurepic from "../../Assets/OrgStructurepic.svg";
import "./HRMS.css";
import { Onboarding, ProjectTask } from "./Components/index";
import { useLocation, useNavigate } from "react-router-dom";
import HRMSIcon from "../../Assets/HRMSIcon.svg";
import { IconButton } from "@mui/material";
import { Policies } from "./Components/Policies";
import { TimeAttendance } from "./Components/TimeAttendance";
import { HRDesk } from "./Components/HRDesk";
import { Benefits } from "./Components/Benefits";
import { Transfer } from "./Components/Transfer";
import { Separation } from "./Components/Separation";
import { ReportsandKPI } from "./Components/ReportsandKPI";
import { DetailsPage } from "./Components/DetailsComp/DetailsPage";
import { CreateLateComing } from "./Components/Time & Attendance/CreateLateComing";
import { CreateEarlyGoing } from "./Components/Time & Attendance/CreateEarlyGoing";
import { CreateCompOff } from "./Components/Time & Attendance/CreateCompOff";
import { CreateLeave } from "./Components/Time & Attendance/CreateLeave";
import { CreateRequisition } from "./Components/OnboardingComponents/CreateRequisition";
import { Orgstructure } from "./Components/Orgstructure";
import { CreateHrdesk } from "./Components/HRDeskComponents/CreateHrdesk";
import { DetailsPageHrDesk } from "./Components/HRDeskComponents/DetailsPageHrDesk";
import { CreateRegularisation } from "./Components/Time & Attendance/CreateRegularisation";
import { CreateCandidate } from "./Components/OnboardingComponents/CreateCandidate";
import { CreateRelocation } from "./Components/TransferComponents/CreateRelocation";
import { DetailsPageRelocation } from "./Components/TransferComponents/DetailsComp/DetailsPageRelocation";
import { DetailsPageDeputation } from "./Components/TransferComponents/DetailsComp/DetailsPageDeputation";
import { CreateDeputation } from "./Components/TransferComponents/CreateDeputation";
import { CreateOutdoor } from "./Components/Time & Attendance/CreateOutdoor";
import { CreateInduction } from "./Components/OnboardingComponents/CreateInduction";
import { DetailsPageResignation } from "./Components/SeparationComponents/Details/DetailsPageResignation";
import { CreateLoanAdvance } from "./Components/BenefitsComponents/CreateLoanAdvance";
import { DetailsPageLoan } from "./Components/BenefitsComponents/DetailsComp/DetailsPageLoan";
import { DetailsPageRegularisation } from "./Components/Time & Attendance/DetailsComp/DetailsPageRegularisation";
import { DetailsPageLateComing } from "./Components/Time & Attendance/DetailsComp/DetailsPageLateComing";
import { DetailsPageEarlyGoing } from "./Components/Time & Attendance/DetailsComp/DetailsPageEarlyGoing";
import { DetailsPageLeave } from "./Components/Time & Attendance/DetailsComp/DetailsPageLeave";
import { DetailsPageCompOff } from "./Components/Time & Attendance/DetailsComp/DetailsPageCompOff";
import { DetailsPageOutdoor } from "./Components/Time & Attendance/DetailsComp/DetailsPageOutdoor";
import { DetailsPageInduction } from "./Components/OnboardingComponents/DetailsComp/DetailsPageInduction";
import { DetailsPageHoliday } from "./Components/Time & Attendance/DetailsComp/DetailsPageHoliday";
import { DetailsPageProbation } from "./Components/OnboardingComponents/DetailsComp/DetailsPageProbation";
import { DetailsPageExitFeedback } from "./Components/SeparationComponents/Details/DetailsPageExitFeedback";
import { CreateFacilities } from "./Components/BenefitsComponents/CreateFacilities";
import { DetailsPageFacilities } from "./Components/BenefitsComponents/DetailsComp/DetailsPageFacilities";
import { DetailsCandidate } from "./Components/OnboardingComponents/CandidateComp/DetailsCandidate";
import { useSelector } from "react-redux";
import { DetailsEmployee } from "./Components/OnboardingComponents/EmployeeComp/DetailsEmployee";
import { CreateProjects } from "./Components/ProjectTaskComponents/CreateProjects";
import { ModuleMasterDetailsPage } from "../HRMSMaster/Components";
import { DetailsPagemaintenance } from "./Components/BenefitsComponents/DetailsComp/DetailsPagemaintenance";
import { DetailsPageFacilitiesSurrender } from "./Components/BenefitsComponents/DetailsComp/DetailsPageFacilitiesSurrender";
import { DetailsPageFacilitiesAllocatedMine } from "./Components/BenefitsComponents/DetailsComp/DetailsPageFacilitiesAllocatedMine";
import { Day1FeedbackDetails } from "./Components/OnboardingComponents/DetailsComp/Day1FeedbackDetails";
import { Day7FeedbackDetails } from "./Components/OnboardingComponents/DetailsComp/Day7FeedbackDetails";
import { Day30FeedbackDetails } from "./Components/OnboardingComponents/DetailsComp/Day30FeedbackDetails";
import { OnboardingFeedbackDetails } from "./Components/OnboardingComponents/DetailsComp/OnboardingFeedbackDetails";
import { DetailsPageProject } from "./Components/ProjectTaskComponents/DetailsPageProject";
import { CreateTasks } from "./Components/ProjectTaskComponents/TasksComp/CreateTasks";
import { DetailPageTask } from "./Components/ProjectTaskComponents/TasksComp/DetailPageTask";
import { DetailPageSubTask } from "./Components/ProjectTaskComponents/TasksComp/DetailPageSubTask";
import { CreateOvertime } from "./Components/Time & Attendance/CreateOvertime";
import { DetailsPageOvertime } from "./Components/DetailsComp/DetailsPageOvertime";
import { DetailsPolicies } from "../HRMSMaster/Components/DetailsPolicies";
const HRMS = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "hrms"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    setMainActive(false);
    setSummaryActive(false);
    setSurrenderActive(false);
    setAlloMyActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrms")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "hrms");
      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
          setCreateActive(false);
          setMainActive(false);
          setSummaryActive(false);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setDetailsActive(false);
          setCreateActive(true);
          setMainActive(false);
          setSummaryActive(false);
        } else if (splitArray[submoduleIndex + 2] === "maintenence") {
          setDetailsActive(false);
          setCreateActive(false);
          setMainActive(true);
          setSummaryActive(false);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (
          splitArray[submoduleIndex + 3] === "details" ||
          splitArray[submoduleIndex + 3] === "feedback"
        ) {
          setDetailsActive(true);
          setCreateActive(false);
          setMainActive(false);
          setSurrenderActive(false);
          setAlloMyActive(false);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setDetailsActive(false);
          setCreateActive(true);
          setMainActive(false);
          setAlloMyActive(false);
          setSurrenderActive(false);
        } else if (splitArray[submoduleIndex + 3] === "maintenence") {
          setDetailsActive(false);
          setCreateActive(false);
          setMainActive(true);
          setSurrenderActive(false);
          setAlloMyActive(false);
        } else if (splitArray[submoduleIndex + 3] === "summary") {
          setDetailsActive(false);
          setCreateActive(false);
          setMainActive(false);
          setSurrenderActive(false);
          setSummaryActive(true);
          setAlloMyActive(false);
        } else if (splitArray[submoduleIndex + 3] === "surrender") {
          setDetailsActive(false);
          setCreateActive(false);
          setMainActive(false);
          setAlloMyActive(false);
          setSurrenderActive(true);
        } else if (splitArray[submoduleIndex + 3] === "myallocated") {
          setDetailsActive(false);
          setCreateActive(false);
          setMainActive(false);
          setSurrenderActive(false);
          setAlloMyActive(true);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);
  const [activeSubmodule, setActiveSubmodule] = useState("onboarding");
  const [activeFeature, setActiveFeature] = useState("manpowerplanning");
  const [detailsActive, setDetailsActive] = useState(false);
  const [mainActive, setMainActive] = useState(false);
  const [summaryActive, setSummaryActive] = useState(false);
  const [surrenderActive, setSurrenderActive] = useState(false);
  const [alloMyActive, setAlloMyActive] = useState(false);
  const [createActive, setCreateActive] = useState(false);
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "onboarding") return onboardingpic;
    else if (subMenuName === "orgstructure") return OrgStructurepic;
    else if (subMenuName === "companypolicies") return CompanyPoliciespic;
    else if (subMenuName === "timeattendance") return TimeAttendancepic;
    else if (subMenuName === "projecttask") return ProjectsTaskspic;
    else if (subMenuName === "hrdesk") return HRDeskpic;
    else if (subMenuName === "benefits") return Benefitspic;
    else if (subMenuName === "transfer") return Transferpic;
    else if (subMenuName === "separation") return Separationpic;
    else if (subMenuName === "reportskpi") return ReportsKPIpic;
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "onboarding" && (
        <>
          {activeFeature === "requisition" && (
            <>
              {createActive && (
                <CreateRequisition title={"Create Requisition"} />
              )}
              {detailsActive && <DetailsPage />}
            </>
          )}
          {activeFeature === "candidate" && (
            <>
              {createActive && <CreateCandidate title={"Create Candidate"} />}
              {detailsActive && <DetailsCandidate />}
            </>
          )}
          {activeFeature === "employee" && (
            <>{detailsActive && <DetailsEmployee />}</>
          )}
          {activeFeature === "induction" && (
            <>
              {createActive && <CreateInduction title={"Create Induction"} />}
              {detailsActive && <DetailsPageInduction />}
            </>
          )}
          {activeFeature === "probation" && (
            <>{detailsActive && <DetailsPageProbation />}</>
          )}

          {activeFeature === "feedback" && (
            <>
              {location.pathname.includes("onboarding") && detailsActive && (
                <OnboardingFeedbackDetails />
              )}
              {location.pathname.includes("day1") && detailsActive && (
                <Day1FeedbackDetails />
              )}
              {location.pathname.includes("day7") && detailsActive && (
                <Day7FeedbackDetails />
              )}
              {location.pathname.includes("day30") && detailsActive && (
                <Day30FeedbackDetails />
              )}
            </>
          )}
        </>
      )}
      {activeSubmodule === "companypolicies" && (
        <>{detailsActive && <DetailsPolicies />}</>
      )}
      {activeSubmodule === "timeattendance" && (
        <>
          {activeFeature === "regularisation" && (
            <>
              {createActive && (
                <CreateRegularisation title={"Create Regularisation Request"} />
              )}
              {detailsActive && <DetailsPageRegularisation />}
            </>
          )}
          {activeFeature === "latecoming" && (
            <>
              {createActive && (
                <CreateLateComing title={"Create Late Coming Request"} />
              )}
              {detailsActive && <DetailsPageLateComing />}
            </>
          )}
          {activeFeature === "earlygoing" && (
            <>
              {createActive && (
                <CreateEarlyGoing title={"Create Early Going Request"} />
              )}
              {detailsActive && <DetailsPageEarlyGoing />}
            </>
          )}
          {activeFeature === "leave" && (
            <>
              {createActive && <CreateLeave title={"Create Leave Request"} />}
              {detailsActive && <DetailsPageLeave />}
            </>
          )}
          {activeFeature === "compoff" && (
            <>
              {createActive && (
                <CreateCompOff title={"Create Comp Off Request"} />
              )}
              {detailsActive && <DetailsPageCompOff />}
            </>
          )}
          {activeFeature === "overtime" && (
            <>
              {createActive && (
                <CreateOvertime title={"Create Overtime Request"} />
              )}
              {detailsActive && <DetailsPageOvertime />}
            </>
          )}
          {activeFeature === "outdoor" && (
            <>
              {createActive && (
                <CreateOutdoor title={"Create Outdoor Request"} />
              )}
              {detailsActive && <DetailsPageOutdoor />}
            </>
          )}
          {activeFeature === "holiday" && (
            <>{detailsActive && <DetailsPageHoliday />}</>
          )}
        </>
      )}
      {activeSubmodule === "hrdesk" && (
        <>
          {createActive && <CreateHrdesk title={"Create HR Desk"} />}
          {detailsActive && <DetailsPageHrDesk />}
        </>
      )}
      {activeSubmodule === "projecttask" && (
        <>
          {activeFeature === "project" && (
            <>
              {createActive && <CreateProjects title={"Create Projects"} />}
              {detailsActive && <DetailsPageProject />}
            </>
          )}
          {activeFeature === "task" && (
            <>
              {createActive && <CreateTasks title={"Create Tasks"} />}
              {detailsActive && <DetailPageTask />}
              {summaryActive && <DetailPageSubTask />}
            </>
          )}
          {activeFeature === "customer" && (
            <>{detailsActive && <ModuleMasterDetailsPage />}</>
          )}
        </>
      )}

      {activeSubmodule === "benefits" && (
        <>
          {activeFeature === "facilities" && (
            <>
              {createActive && <CreateFacilities title={"Create Facilities"} />}
              {detailsActive && <DetailsPageFacilities />}
              {mainActive && <DetailsPagemaintenance />}
              {surrenderActive && <DetailsPageFacilitiesSurrender />}
              {alloMyActive && <DetailsPageFacilitiesAllocatedMine />}
            </>
          )}
          {activeFeature === "loanadvance" && (
            <>
              {createActive && (
                <CreateLoanAdvance title={"Create Loan or Advance"} />
              )}
              {detailsActive && <DetailsPageLoan />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "transfer" && (
        <>
          {activeFeature === "relocation" && (
            <>
              {createActive && <CreateRelocation title={"Create Relocation"} />}
              {detailsActive && <DetailsPageRelocation />}
            </>
          )}
          {activeFeature === "deputation" && (
            <>
              {createActive && <CreateDeputation title={"Create Deputation"} />}
              {detailsActive && <DetailsPageDeputation />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "separation" && (
        <>
          {activeFeature === "resignation" && (
            <>{detailsActive && <DetailsPageResignation />}</>
          )}
          {activeFeature === "exitletters" && (
            <>{detailsActive && <DetailsPageExitFeedback />}</>
          )}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/hrms/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {activeSubmodule === "orgstructure" ? <Orgstructure /> : null}
        {activeSubmodule === "onboarding" ? <Onboarding /> : null}
        {activeSubmodule === "projecttask" ? <ProjectTask /> : null}
        {activeSubmodule === "companypolicies" ? <Policies /> : null}
        {activeSubmodule === "timeattendance" ? <TimeAttendance /> : null}
        {activeSubmodule === "hrdesk" ? <HRDesk /> : null}
        {activeSubmodule === "benefits" ? <Benefits /> : null}
        {activeSubmodule === "transfer" ? <Transfer /> : null}
        {activeSubmodule === "separation" ? <Separation /> : null}
        {activeSubmodule === "reportskpi" ? <ReportsandKPI /> : null}
      </div>
    </div>
  );
};

export { HRMS };
