import React, { useState } from "react";
import "./Completed.css";
import TableContent from "./TableContent";
import { ProgressBarComponent } from "./ProgressBarComponent";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getRequest, postRequest } from "../../../Services/axios";
import { useEffect } from "react";
import { InstallMobile } from "@mui/icons-material";
import Nodata from "../../../Assets/Nodata.svg";

const InProgress = () => {
  const [loading, setLoading] = useState(false);
  const [inprogress, setInprogress] = useState([]);
  const inprogressStyle = {
    borderBottom: "1px solid #E6E6E6 ",
    // paddingBottom: "10px",
  };
  const dispatch = useDispatch();
  const payload = { Status: "In Progress" };
  const getInProgressDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        `LMSController/MyTeamLearningProgress`,
        payload
      );
      // console.log("MyTeamLearningProgress", result);
      setInprogress(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getInProgressDetails();
  }, []);

  return (
    <>
      {inprogress?.length !== 0 ? (
        <div
          style={{
            // minWidth: "1172px",
            overflowY: "hidden",
            overflowX: "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            className="completedHeadingldteam"
            style={{
              borderRadius: "6px",
              backgroundColor: "#FBFBFB",
              padding: " 6px 0px 7px 0px",
            }}
          >
            <div style={{ marginLeft: "16px" }} className="columnwidth ">
              Name
            </div>
            <div className="columnwidth ">Course</div>
            <div className="columnwidth ">Chapter</div>
            <div className="columnwidth ">Lesson</div>
            <div className="columnwidth ">Status</div>
            <div className="columnwidth ">%Percentage</div>
          </div>
          <div className="tablecontentldteam">
            {inprogress
              ? inprogress?.map((item) => (
                  <TableContent
                    Name={item?.employeeName}
                    Category={item?.name}
                    Course={item?.title}
                    Chapter={item?.chapterName}
                    Status={item?.status}
                    componentProp={
                      <ProgressBarComponent
                        percentage={item?.percentage}
                        color={"#1296B0"}
                        widthTotal={"53%"}
                      />
                    }
                    completedStyle={inprogressStyle}
                  />
                ))
              : ""}
          </div>
        </div>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </>
  );
};

export { InProgress };
