import React from 'react'
import { MyAttendance } from '../../HRMS/Components/Time & Attendance/MyAttendance';
import { useEffect } from 'react';

const ServiceAttendance = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Attendance Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      <MyAttendance styles={newHeight}/>
        
    </div>
  )
}

export {ServiceAttendance};
