import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LD.css";
import { LearningVideos } from "./Components/LearningVideos";
import { CertificateViewer } from "./Components/CertificateViewer";
import { useSelector } from "react-redux";
import { Learning } from "./Components/Learning";
import { CertificateTempViewer } from "./Components/CertificateTempViewer";
import learningpic from "../../Assets/learningpic.svg";
import OrgStructurepic from "../../Assets/OrgStructurepic.svg";
import { InProgress } from "./Components/InProgress";
import { Completed } from "./Components/Completed";
import { MyListLDResults } from "./Components/MyListLDResults";
import { MyTeamListLDResults } from "./Components/MyTeamListLDResults";
import { Skills } from "./Components/Skills";
import { Competency } from "./Components/Competency";
import { OrganisationSkillSet } from "./Components/OrganisationSkillSet";
import { OrganisationSkillSetEmployees } from "./Components/OrganisationSkillSetEmployees";
import { OrganisationCompetencySet } from "./Components/OrganisationCompetencySet";
import { OrganisationCompetencySetEmployees } from "./Components/OrganisationCompetencySetEmployees";
import { MyListLDResultsDetails } from "./Components/MyListLDResultsDetails";
import { MyTeamListLDResultsDetails } from "./Components/MyTeamListLDResultsDetails";
import CompetenciesIcon from "../../Assets/CompetenciesIcon.svg";
import Skillspic from "../../Assets/Skillspic.svg";
import trainingpic from "../../Assets/trainingpic.svg";
const LD = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  const [activeSubmodule, setActiveSubmodule] = useState("learning");
  const [activeFeature, setActiveFeature] = useState("mylearning");
  const [learningVideosActive, setlearningVideosActive] = useState(false);
  const [certificateActive, setCertificateActive] = useState(false);
  const [certicompActive, setCerticompActive] = useState(false);
  const [completedActive, setCompletedActive] = useState(false);
  const [inprogressActive, setInprogressActive] = useState(false);
  const [myListActive, setMyListActive] = useState(false);
  const [myTeamListActive, setMyTeamListActive] = useState(false);

  const [mySkillsActive, setMySkillsActive] = useState(false);
  const [myTeamSkillsActive, setMyTeamSkillsActive] = useState(false);
  const [myOrganisationSkillsActive, setMyOrganisationSkillsActive] =
    useState(false);
  const [myCompetencyActive, setMyCompetencyActive] = useState(false);
  const [myTeamCompetencyActive, setMyTeamCompetencyActive] = useState(false);
  const [myOrganisationCompetencyActive, setMyOrganisationCompetencyActive] =
    useState(false);
  const [skillSetActive, setSkillSetActive] = useState(false);
  const [competencySetActive, setCompetencySetActive] = useState(false);
  const [appersubmodule, setappersubmodule] = useState(true);
  const [myOrganisationEmployeesActive, setMyOrganisationEmployeesActive] =
    useState(false);

  //console.log("feat", submoduleMenu);
  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "ld"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);
  //console.log("subMenuList", subMenuList);
  //console.log("submoduleMenuowusdh", submoduleMenu);
  useEffect(() => {
    setlearningVideosActive(false);
    setCertificateActive(false);
    setCerticompActive(false);
    setInprogressActive(false);
    setCompletedActive(false);
    setMyListActive(false);
    setMyTeamListActive(false);
    setMySkillsActive(false);
    setMyTeamSkillsActive(false);
    setMyOrganisationSkillsActive(false);
    setMyCompetencyActive(false);
    setMyTeamCompetencyActive(false);
    setMyOrganisationCompetencyActive(false);
    setSkillSetActive(false);
    setCompetencySetActive(false);
    setMyOrganisationEmployeesActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("ld")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "ld");

      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 1) {
        if (splitArray[submoduleIndex + 1] === "skills") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 1] === "competency") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        }
        // else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "mylearning") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 2] === "teamlearning") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 2] === "myskills") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(true);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 2] === "myteamskills") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(true);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 2] === "organisationskills") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(true);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 2] === "mycompetency") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(true);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 2] === "myteamcompetency") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(true);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (
          splitArray[submoduleIndex + 2] === "organisationcompetency"
        ) {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(true);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "certicomp") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(true);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 3] === "learningvideos") {
          setlearningVideosActive(true);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 3] === "inprogress") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(true);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 3] === "completed") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(true);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 3] === "mylist") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(true);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 3] === "myteamlist") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(true);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        } else if (splitArray[submoduleIndex + 3] === "skillset") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(true);
          setappersubmodule(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
        } else if (splitArray[submoduleIndex + 3] === "competencyset") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(true);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(false);
        }
      }
      if (splitArray?.length > submoduleIndex + 4) {
        if (splitArray[submoduleIndex + 4] === "certificate") {
          setlearningVideosActive(false);
          setCertificateActive(true);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(false);
          setappersubmodule(true);
        }
        if (splitArray[submoduleIndex + 4] === "employees") {
          setlearningVideosActive(false);
          setCertificateActive(false);
          setCerticompActive(false);
          setInprogressActive(false);
          setCompletedActive(false);
          setMyListActive(false);
          setMyTeamListActive(false);
          setMySkillsActive(false);
          setMyTeamSkillsActive(false);
          setMyOrganisationSkillsActive(false);
          setMyCompetencyActive(false);
          setMyTeamCompetencyActive(false);
          setMyOrganisationCompetencyActive(false);
          setSkillSetActive(false);
          setCompetencySetActive(false);
          setMyOrganisationEmployeesActive(true);
          setappersubmodule(false);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);

  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "learning") return learningpic;
    else if (subMenuName === "skills") return Skillspic;
    else if (subMenuName === "competency") return CompetenciesIcon;
    else if (subMenuName === "performance") return OrgStructurepic;
    else if (subMenuName === "training") return trainingpic;
    // else if (subMenuName === "projecttask") return ProjectsTaskspic;
    // else if (subMenuName === "hrdesk") return HRDeskpic;
    // else if (subMenuName === "benefits") return Benefitspic;
    // else if (subMenuName === "transfer") return Transferpic;
    // else if (subMenuName === "separation") return Separationpic;
    // else if (subMenuName === "reportskpi") return ReportsKPIpic;
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "learning" && (
        <>
          {activeFeature === "mylearning" && (
            <>
              {location.pathname.includes("learningvideos") &&
                learningVideosActive && <LearningVideos />}
              {/* {location.pathname.includes(
                "ld/learning/mylearning/learningvideos"
              ) && <LearningVideos />} */}
              {location.pathname.includes("certificate") &&
                certificateActive && <CertificateViewer />}

              {/* {location.pathname.includes("certicomp") && certicompActive && (
                <CertificateTempViewer />
              )} */}
            </>
          )}
          {activeFeature === "teamlearning"}
        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/ld/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>

      <div className="homeEngageMainContainer">
        {activeSubmodule === "learning" ? <Learning /> : null}
        {location.pathname.includes("mylist") && myListActive && (
          <MyListLDResults />
        )}
        {location.pathname.includes("mylearning/mylist/details") &&
          myListActive && <MyListLDResultsDetails />}
        {location.pathname.includes("myteamlist") && myTeamListActive && (
          <MyTeamListLDResults />
        )}
        {location.pathname.includes("mylearning/myteamlist/details") &&
          myTeamListActive && <MyTeamListLDResultsDetails />}

        {skillSetActive ? <OrganisationSkillSet /> : null}
        {activeSubmodule === "skills" && myOrganisationEmployeesActive ? (
          <OrganisationSkillSetEmployees />
        ) : null}

        {activeSubmodule === "skills" && appersubmodule ? <Skills /> : null}

        {activeSubmodule === "competency" && appersubmodule ? (
          <Competency />
        ) : null}
        {competencySetActive ? <OrganisationCompetencySet /> : null}
        {activeSubmodule === "competency" && myOrganisationEmployeesActive ? (
          <OrganisationCompetencySetEmployees />
        ) : null}
      </div>
    </div>
  );
};

export { LD };
