import React from "react";
import { getAttachments } from "../../../../../../../Utilities/socialAllFunctions";
import ShowCarouselSurveyImages from "./ShowCarouselSurveyImages";

function SurveyAndPollImages({ imgData }) {
  return (
    <div>
      {imgData?.attachment !== null && (
        <ShowCarouselSurveyImages
          photo={getAttachments(imgData?.attachment) || []}
          showModal={() => {}}
        />
      )}
    </div>
  );
}

export default SurveyAndPollImages;
