import React from "react";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";

import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";

export const CreateNotiFlowComp = () => {
  useEffect(()=>{
    document.title = `PeopleSol - Create Notification Flow`;
    return () => {
      document.title = 'PeopleSol';
    };
  })
  return (
    <>
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      <div className="repeatedSectionContainer">
        <FieldArray name="items">
          {(fieldArrayProps) => {
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Priority"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Reminder 1 (In Hrs)"} mandatory={true} />
                      
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Reminder 2 (In Hrs)"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Reminder 3 (In Hrs)"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Escalation 1 (In Hrs)"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Escalation 2 (In Hrs)"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Escalation 3 (In Hrs)"} mandatory={true} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {/* {items?.map((items, index) => ( */}
                  <TableBody
                  // key={index}
                  >
                    <TableRow>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {/* {index == 0} */}
                        <LabelCustom labelName={"High"} mandatory={true} />
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`areminder1`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`areminder2`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 4th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`areminder3`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 5th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`aescalation1`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 6th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`aescalation2`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 7th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`aescalation3`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>
                      {/* 8th Column */}
          
                    </TableRow>

                    {/* Medium Row */}

                    <TableRow>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {/* {index == 1} */}
                        <LabelCustom labelName={"Medium"} mandatory={true} />
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`breminder1`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`breminder2`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 4th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`breminder3`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 5th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`bescalation1`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 6th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`bescalation2`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 7th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`bescalation3`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>
                      {/* 8th Column */}
                  
                    </TableRow>

                    {/* Low Row */}

                    <TableRow>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {/* {index == 2} */}
                        <LabelCustom labelName={"Low"} mandatory={true} />
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`creminder1`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`creminder2`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 4th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`creminder3`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 5th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`cescalation1`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 6th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`cescalation2`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>

                      {/* 7th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                           step="0.01"
                          name={`cescalation3`}
                          placeholder={" Enter Value  "}
                        />
                      </StyledTableCell>
                      {/* 8th Column */}
                     
                    </TableRow>
                  </TableBody>
                  {/*} ))}  */}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
};
