import React, { useState, useEffect } from "react";
import { Calendar } from "antd";
import "./MeetingRoom.css";
import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { TimeCustomFormik } from "../../../../Components/FormComponent/TimeCustomFormik";
import { DateRangePicker } from "@mui/lab";
import { RadioFormik } from "../../../../Components/FormComponent/RadioFormik";
import CalendarComponent from "../../Components/CalendarComponent";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { CalendarPopup } from "../../Components/CalendarPopup";
import { format } from "date-fns";

const opt = [{ label: "select", value: null }];
export const CreateMeetingRoomComp = ({
  loading,
  formValues,
  data,
  dataVisitProp,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredDatapopup, setfilteredDatapopup] = useState();
  const togglePopupDate = () => {
    setIsOpen((prev) => !prev);
  };
  // console.log("formValues", formValues);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 1000,
      iDisplayStart: 0,
      sSearch: "",
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });

  const timeOptions = [
    { label: "12:00 AM", value: "2023-12-08T00:00:00" },
    { label: "12:30 AM", value: "2023-12-08T00:30:00" },
    { label: "1:00 AM", value: "2023-12-08T01:00:00" },
    { label: "1:30 AM", value: "2023-12-08T01:30:00" },
    { label: "2:00 AM", value: "2023-12-08T02:00:00" },
    { label: "2:30 AM", value: "2023-12-08T02:30:00" },
    { label: "3:00 AM", value: "2023-12-08T03:00:00" },
    { label: "3:30 AM", value: "2023-12-08T03:30:00" },
    { label: "4:00 AM", value: "2023-12-08T04:00:00" },
    { label: "4:30 AM", value: "2023-12-08T04:30:00" },
    { label: "5:00 AM", value: "2023-12-08T05:00:00" },
    { label: "5:30 AM", value: "2023-12-08T05:30:00" },
    { label: "6:00 AM", value: "2023-12-08T06:00:00" },
    { label: "6:30 AM", value: "2023-12-08T06:30:00" },
    { label: "7:00 AM", value: "2023-12-08T07:00:00" },
    { label: "7:30 AM", value: "2023-12-08T07:30:00" },
    { label: "8:00 AM", value: "2023-12-08T08:00:00" },
    { label: "8:30 AM", value: "2023-12-08T08:30:00" },
    { label: "9:00 AM", value: "2023-12-08T09:00:00" },
    { label: "9:30 AM", value: "2023-12-08T09:30:00" },
    { label: "10:00 AM", value: "2023-12-08T10:00:00" },
    { label: "10:30 AM", value: "2023-12-08T10:30:00" },
    { label: "11:00 AM", value: "2023-12-08T11:00:00" },
    { label: "11:30 AM", value: "2023-12-08T11:30:00" },
    { label: "12:00 PM", value: "2023-12-08T12:00:00" },
    { label: "12:30 PM", value: "2023-12-08T12:30:00" },
    { label: "1:00 PM", value: "2023-12-08T13:00:00" },
    { label: "1:30 PM", value: "2023-12-08T13:30:00" },
    { label: "2:00 PM", value: "2023-12-08T14:00:00" },
    { label: "2:30 PM", value: "2023-12-08T14:30:00" },
    { label: "3:00 PM", value: "2023-12-08T15:00:00" },
    { label: "3:30 PM", value: "2023-12-08T15:30:00" },
    { label: "4:00 PM", value: "2023-12-08T16:00:00" },
    { label: "4:30 PM", value: "2023-12-08T16:30:00" },
    { label: "5:00 PM", value: "2023-12-08T17:00:00" },
    { label: "5:30 PM", value: "2023-12-08T17:30:00" },
    { label: "6:00 PM", value: "2023-12-08T18:00:00" },
    { label: "6:30 PM", value: "2023-12-08T18:30:00" },
    { label: "7:00 PM", value: "2023-12-08T19:00:00" },
    { label: "7:30 PM", value: "2023-12-08T19:30:00" },
    { label: "8:00 PM", value: "2023-12-08T20:00:00" },
    { label: "8:30 PM", value: "2023-12-08T20:30:00" },
    { label: "9:00 PM", value: "2023-12-08T21:00:00" },
    { label: "9:30 PM", value: "2023-12-08T21:30:00" },
    { label: "10:00 PM", value: "2023-12-08T22:00:00" },
    { label: "10:30 PM", value: "2023-12-08T22:30:00" },
    { label: "11:00 PM", value: "2023-12-08T23:00:00" },
    { label: "11:30 PM", value: "2023-12-08T23:30:00" },
    { label: "12:00 PM", value: "2023-12-08T24:00:00" },
    { label: "12:30 PM", value: "2023-12-08T24:30:00" },
  ];

  useEffect(() => {
    document.title = `PeopleSol - Create Meeting Room`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();
  const [roomAvailable, setRoomAvailable] = useState([]);

  const roomidOptions = async () => {
    try {
      const result = await postRequest(
        "MeetingRoomBookings/GetMeetingRooms",
        reqData
      );
      // console.log("MeetingRoomBookings", result);
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      setRoomAvailable(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    roomidOptions();
  }, []);
  useEffect(() => {
    if (formValues?.Date) {
      formValues.EndDate = formValues?.Date;
    }
  }, [formValues?.Date]);

  // console.log("first formValues?.Date", formValues?.Date);
  // console.log("first formValues?.EndDate", formValues?.EndDate);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Meeting Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={250}
          name="Title"
          type={"text"}
          placeholder={"Please Enter the meeting title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Show Booking For"} mandatory={false} />
        <SelectForm name="showbookingfor" options={roomAvailable} />
      </div>
      <div>
        <LabelCustom labelName={"Number of Persons"} mandatory={true} />
        <InputCustomFormik
          name="NumberofPersons"
          type={"number"}
          placeholder={"Please Enter number of persons"}
        />
      </div>
      <div className="meetingroomformcalander calendarDesign">
        <div>
          <div style={{ width: "100%" }}>
            <CalendarComponent
              formValues={formValues}
              calendarRoomId={formValues?.showbookingfor}
              togglePopupDate={togglePopupDate}
              setfilteredDatapopup={setfilteredDatapopup}
            />
          </div>
        </div>
      </div>
      <div>
        <LabelCustom labelName={"Additional Request"} mandatory={false} />
        <TextAreaFormik
          maxLength={1000}
          name="AdditionalRequests"
          type={"text"}
          placeholder={"Please Enter the additional request"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Date"} mandatory={true} />
        <div
          className="meetingroomformdate"
          style={{ width: "100%", maxWidth: "551px" }}
        >
          <div style={{ width: "50%", maxWidth: "213px" }}>
            <DatePickerFormiks
              name={"Date"}
              placeholder={" Select Date "}
              minDate={new Date()}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "start",
              justifyContent: "center",
              paddingBottom: "10px",
              marginTop: " 23px",
            }}
          >
            To
          </div>
          <div style={{ width: "50%", maxWidth: "213px" }}>
            <DatePickerFormiks
              name={"EndDate"}
              placeholder={" Select Date "}
              minDate={new Date()}
            />
          </div>
        </div>

        {/* name="meetingtitle"
            type={"text"}
            placeholder={"Please Enter number of persons"}
            /> */}
      </div>
      <div>
        <LabelCustom labelName={"Timing"} mandatory={true} />
        <div
          className="meetingroomformdate"
          style={{ width: "100%", maxWidth: "551px" }}
        >
          <div style={{ width: "50%", maxWidth: "213px" }}>
            {/* <TimeCustomFormik
              name={"StartTime"}
              placeholder={" Select Date "}
            /> */}
            <SelectForm
              formikWidth={"100%"}
              name={"StartTime"}
              options={timeOptions}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "start",
              justifyContent: "center",
              paddingBottom: "10px",
              marginTop: " 23px",
              // marginRight: "29px",
            }}
          >
            To
          </div>
          <div style={{ width: "50%", maxWidth: "213px" }}>
            {/* <TimeCustomFormik name={"EndTime"} placeholder={" Select Date "} /> */}
            <SelectForm
              formikWidth={"100%"}
              name={"EndTime"}
              options={timeOptions}
            />
          </div>
        </div>
      </div>

      <div
        style={{ flexDirection: "column" }}
        className="meetingroomformdate radioformikstyle"
      >
        <LabelCustom labelName={"Rooms Availabe"} mandatory={true} />
        <RadioFormik name={"RoomId"} options={roomAvailable} />
      </div>
      <div style={{ width: "100%" }}>
        {isOpen && (
          <Popup
            popupHeading={
              <section
                className="calendarPopupHeading"
                style={{
                  boxshadow: " 0px 0px 0px 0px rgba(0, 0, 0, 0.05)",
                  padding: "0px",
                }}
              >
                <div className="calendarPopupDate">
                  {filteredDatapopup?.date
                    ? format(new Date(filteredDatapopup?.date), "dd MMMM, yyyy")
                    : ""}
                </div>
                <div className="calendarPopupDay">
                  {filteredDatapopup?.date
                    ? format(new Date(filteredDatapopup?.date), "EEEE")
                    : ""}
                </div>
              </section>
            }
            secondClassName={"popupwidth"}
            contentClassName={"cancelcsspopup"}
            content={
              <CalendarPopup
                loading={loading}
                Loader={Loader}
                currentDate={
                  filteredDatapopup?.date ? filteredDatapopup?.date : ""
                }
                filteredData={filteredDatapopup ? filteredDatapopup : ""}
              />
            }
            handleClose={togglePopupDate}
          />
        )}
      </div>
    </div>
  );
};
