import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "./Details.css";
import { TimelineItem } from "./TimelineItem";
import { Form, Formik } from "formik";
import React from "react";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import * as Yup from "yup";
export const Timeline = ({ id, module, requestNumber }) => {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const getTimeline = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `GetTimeline?ID=${id}&Type=${module}&RequestNumber=${requestNumber}`
      );
      // //console.log("result", result.data);
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Time Line`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getTimeline();
  }, []);
  const initial = { Comment: "" };
  const PostApRejHol = async (values) => {
    try {
      setLoading(true);
      await postRequest("ManpowerIndents/UpdateDetails", values);
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      getTimeline();
      setLoading(false);
    }
  };
  const onSubmit = async (values) => {
    const post = { ...values, RequestId: id, Module: module };
    PostApRejHol(post);
  };
  // //console.log("data?.length", data);
  return (
    <>
      {loading ? (
        <div className="noDataFound">
          <Loader />
        </div>
      ) : data?.length > 0 ? (
        <>
          <div className="timeline-container">
            {data?.map((list, index) => {
              return <TimelineItem data={list} index={index} />;
            })}
          </div>
          <div className="comentCont">
            <p className="labelCom">Comments</p>
            <Formik
              initialValues={initial}
              onSubmit={onSubmit}
              validationSchema={Yup.object({
                Comment: Yup.string()
                  .max(299, "Name must be less than 150 characters")
                  .required("Please enter comments"),
              })}
            >
              {(formik) => (
                <Form>
                  <div style={{ padding: "0px 37px 0 13px" }}>
                    <TextAreaFormik
                      name="Comment"
                      style={{ height: "200px" }}
                      maxLength={300}
                      placeholder="Enter comment..."
                    />
                  </div>
                  <div className="createBtnTime" style={{ position: "unset" }}>
                    <div>
                      <button
                        className="button secondaryButton"
                        onClick={() =>
                          formik.resetForm({ values: { Comment: "" } })
                        }
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                    <div>
                      <button className="button primaryButton" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </>
  );
};
