import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { ApprovedSeparation } from "../Components/SeparationApprovals/ApprovedSeparation";
import { HoldRequestSeparation } from "../Components/SeparationApprovals/HoldRequestSeparation";
import { RejectedSeparation } from "../Components/SeparationApprovals/RejectedSeparation";
import { AllRequestSeparation } from "../Components/SeparationApprovals/AllRequestSeparation";
import { PendingApprovalSeparation } from "../Components/SeparationApprovals/PendingApprovalSeparation";
import { useEffect } from "react";

const ApprovalResignation = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Resignation`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Discuss
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalSeparation
            title={"Separation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <ApprovedSeparation
          title={"Separation"}
          getApprovalBadge={getApprovalBadge}
        />
      )}
      {tabIndex === 2 && (
        <>
          <HoldRequestSeparation
            title={"Separation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <RejectedSeparation
            title={"Separation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 4 && (
        <>
          <AllRequestSeparation
            title={"Separation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalResignation };
