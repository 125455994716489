import React, { useState, useEffect } from 'react';
import { Tabs, Tab, IconButton } from '@mui/material';
import "./JobOpening.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "../../../Components/index";
import Select from "react-dropdown-select";
// import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';
import "./OfferLetter.css";
import { width } from '@mui/system';
import PdfPositionTable from './PdfPositionTable';
import SalaryStructureTable from './SalaryStructureTable';

const OfferLetter = (props) => {
    // const styles = StyleSheet.create({
    //     body: {
    //         border: 5,
    //         borderColor: "black",
    //         position: "relative",
    //         display: "flex",
    //         paddingTop: 90,
    //         paddingBottom: 50,
    //         fontFamily: "Helvetica"
    //     },
    //     title: {
    //         fontSize: 20,
    //         textAlign: 'center',
    //         textDecoration: "underline",
    //         marginBottom: 5,
    //         fontWeight: "bold",
    //         fontFamily: "Helvetica-Bold"
    //     },
    //     author: {
    //         fontSize: 10,
    //         textAlign: 'center',
    //         marginBottom: 20,
    //         fontWeight: "bold",
    //         fontFamily: "Helvetica-Bold"
    //     },
    //     subtitle: {
    //         fontSize: 12,
    //         marginHorizontal: 24,
    //     },
    //     text: {
    //         marginHorizontal: 24,
    //         fontSize: 12,
    //         marginBottom: 10,
    //         textAlign: 'justify',
    //     },
    //     subText: {
    //         marginHorizontal: 24,
    //         fontSize: 12,
    //         textAlign: 'justify',
    //     },
    //     sectionHeading: {
    //         fontSize: 12,
    //         marginHorizontal: 24,
    //         marginBottom: 10,
    //         fontWeight: "bold",
    //         fontFamily: "Helvetica-Bold"
    //     },
    //     sectionSubHeading: {
    //         fontSize: 12,
    //         marginHorizontal: 24,
    //         fontWeight: "bold",
    //         fontWeight: "bold",
    //         fontFamily: "Helvetica-Bold"
    //     },
    //     pageNumber: {
    //         position: 'absolute',
    //         fontSize: 12,
    //         bottom: 30,
    //         left: 0,
    //         right: 0,
    //         textAlign: 'right',
    //         color: 'grey',
    //         marginRight: 35,
    //     },
    //     footer: {
    //         position: 'absolute',
    //         fontSize: 12,
    //         bottom: 30,
    //         left: 0,
    //         right: 0,
    //         textAlign: 'center',
    //         color: 'black',
    //     },
    //     watermark: {
    //         position: 'absolute',
    //         opacity: 0.2,
    //         zIndex: -1,
    //         alignSelf: "center",
    //         height: 400,
    //         width: 400,
    //         top: 200
    //     },
    //     header: {
    //         position: "absolute",
    //         top: 0,
    //         left: 0,
    //         alignItems: "center",
    //         display: "flex",
    //         flexDirection: "row",
    //         width: "100%",
    //         height: 80,
    //     },
    //     headerImage: {
    //         margin: 5,
    //         width: 80
    //     },
    //     headerCompanyName: {
    //         fontSize: 40,
    //         marginRight: 10,
    //         fontWeight: "bold",
    //         fontFamily: "Helvetica-Bold"
    //     },
    //     gap: {
    //         marginVertical: 40,
    //         color: "transparent"
    //     },
    //     bold: {
    //         fontWeight: "bold",
    //         fontFamily: "Helvetica-Bold"
    //     }
    // });
    const companyLogo = "https://cdn.pixabay.com/photo/2016/08/09/17/52/instagram-1581266__340.jpg";
    const dateTimeNow = new Date();
    const titleOfApplicant = "Mr";
    const nameOfApplicant = "Anuj Yadav";
    const roleOfApplicant = "Jr. Tester";
    const phoneOfApplicant = "+91-8107829401";
    const addressLine1 = "Near Reliance Fresh";
    const addressLine2 = "Ratu Road";
    const cityPincode = "Bihar - 123456";
    const dateOfJoining = new Date();
    const positionData = {
        "Role": "Jr. Tester",
        "Designation": "Abc Xyz",
        "Grade": "Abc Xyz",
        "Band": "Abc Xyz",
        "Department": "Abc Xyz",
        "Sub Department": "Abc Xyz",
        "Date of joining": "Abc Xyz",
        "Reporting Manager": "Abc Xyz",
        "Location": "Abc Xyz"
    };
    const SalaryData = {
        "Fixed Components": [{
            "SalaryComponent": "Basic",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        },
        {
            "SalaryComponent": "HRA",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        },
        {
            "SalaryComponent": "LTA",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        },
        {
            "SalaryComponent": "Special Allowance",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        },
        {
            "SalaryComponent": "Basic",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        },
        {
            "SalaryComponent": "Basic",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        },
        {
            "SalaryComponent": "Basic",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        },
        {
            "SalaryComponent": "Basic",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        }, {
            "SalaryComponent": "Basic",
            "INRPerMonth": "5000",
            "INRPerYear": "60000",
        }
        ],
        "Benefits": [
            {
                "SalaryComponent": "Annual Bonus",
                "INRPerMonth": "200",
                "INRPerYear": "2400",
            }
        ],
        "Total Benefits": [
            {
                "SalaryComponent": "Total Benefits",
                "INRPerMonth": "200",
                "INRPerYear": "2400",
            }
        ],
        "Gross CTC Salary": [
            {
                "SalaryComponent": "Gross CTC Salary",
                "INRPerMonth": "12500",
                "INRPerYear": "150000 ",
            }
        ]
    };


    useEffect(()=>{
        document.title = `PeopleSol - Offer Letter`;
        return () => {
          document.title = 'PeopleSol';
        };
      })

    const associateCandidate = () => {
    };
    const scheduleInterview = () => {
    };
    const location = useLocation();
    const navigate = useNavigate();
    const [maxRecords, setMaxRecords] = useState([]);
    const Countries = [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
    ];
    const actionData = [{
        img: <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0375 13.2779C12.8689 13.2779 14.3535 11.8007 14.3535 9.97846C14.3535 8.15624 12.8689 6.67905 11.0375 6.67905C9.20612 6.67905 7.7215 8.15624 7.7215 9.97846C7.7215 11.8007 9.20612 13.2779 11.0375 13.2779Z" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M5.00836 20.0767V19.1769C5.00836 15.8774 7.72146 13.1779 11.0375 13.1779C14.3535 13.1779 17.0666 15.8774 17.0666 19.1769V20.0767" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M18.0715 20.0766H4.00356C2.29532 20.0766 0.989014 18.7768 0.989014 17.0771V5.47918C0.989014 3.77948 2.29532 2.47971 4.00356 2.47971H9.93217L11.0375 1.07996L12.1428 2.47971H18.0715C19.7797 2.47971 21.086 3.77948 21.086 5.47918V17.0771C21.086 18.7768 19.7797 20.0766 18.0715 20.0766Z" stroke="#1296B0" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        action: "Associate Candidate",
        clickAction: () => associateCandidate
    }, {
        img: <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0375 13.2779C12.8689 13.2779 14.3535 11.8007 14.3535 9.97846C14.3535 8.15624 12.8689 6.67905 11.0375 6.67905C9.20612 6.67905 7.7215 8.15624 7.7215 9.97846C7.7215 11.8007 9.20612 13.2779 11.0375 13.2779Z" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M5.00836 20.0767V19.1769C5.00836 15.8774 7.72146 13.1779 11.0375 13.1779C14.3535 13.1779 17.0666 15.8774 17.0666 19.1769V20.0767" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M18.0715 20.0766H4.00356C2.29532 20.0766 0.989014 18.7768 0.989014 17.0771V5.47918C0.989014 3.77948 2.29532 2.47971 4.00356 2.47971H9.93217L11.0375 1.07996L12.1428 2.47971H18.0715C19.7797 2.47971 21.086 3.77948 21.086 5.47918V17.0771C21.086 18.7768 19.7797 20.0766 18.0715 20.0766Z" stroke="#1296B0" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>, action: "Schedule Interview",
        clickAction: () => scheduleInterview
    }]
    const theadData = ["Id",
        "Initial Name",
        "First Name",
        "Middle Name",
        "LastName",
        "Email",
        "Phone Number",
        "Photo",
        "Gender",
        "Nationality",
        "Language",
        "Marital Status",
        "Current Employer",
        "Current Job title",
        "Current Salary",
        "Current Experience",
        "Expected Salary",
        "Source Of Higher",
        "Highest Qualification",
        "Current Address",
        "Current AddressLine2",
        "Current City",
        "Current PinCode",
        "Current District",
        "Current State",
        "Current Address Country",
        "Additional Info",
        "Joining DOJ",
        "Resume Id",
        "Resume FilePath",
        "LinkedIn",
        "Facebook",
        "Twitter",
        "Website",
        "Man Power Indent Id",
        "JobOpeningId",
        "IsActive",
        "Status",
        "CreatedBy",
        "UpdatedBy",
        "DeletedBy",
        "CreatedOn",
        "LastUpdatedOn",
        "DeletedOn"]
    const tbodyData = [
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
                "Mr",
                "Anuj",
                "Kumar",
                "Yadav",
                "anuj.yadav@aesssolutions.com",
                "8107829401",
                "profile.png",
                "Male",
                "Indian",
                "English, Hindi",
                "Single",
                "Infosys",
                "Developer",
                "5LPA",
                "1+ years",
                "12LPA",
                "Naukri",
                "BTech",
                "Delhi",
                "Delhi",
                "Delhi",
                "110007",
                "Delhi",
                "Delhi",
                "India",
                "-",
                "29/11/2022",
                "01",
                "sfgf/asfgfa/sdfgs.pdf",
                "www.LinkedIn.com",
                "www.Facebook.com",
                "www.Twitte.com",
                "www.Website.com",
                "A101",
                "10001",
                "true",
                "Associated",
                "Anuj Yadav",
                "Anuj Yadav",
                "Anuj Yadav",
                "29/11/2022",
                "29/11/2022",
                "29/11/2022"
            ],
        },
    ];




    return (
        <div className="moduleMainContainer">
            <div className="tableHeaderContainer">
                <button onClick={() => { navigate("ats/offerletter/create"); }} className='createFooterButton2 addProjectButton buttonClass'>+ {props.title}</button>
                <div className="chatListSearchContainer navBarSearch">
                    <input type="text" className="chatListSearch" placeholder="Search" />
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0"></circle><line x1="8.70711" y1="9" x2="12" y2="12.2929" stroke="#1296B0" strokeLinecap="round"></line>
                    </svg>
                </div>
                <IconButton color='primary'>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z" stroke="#1296B0" strokeWidth="1.5" />
                    </svg>
                </IconButton>
                <IconButton color='primary'>
                    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961" stroke="#1296B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                </IconButton>
                <IconButton color='primary'>
                    <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

                </IconButton>
            </div>
            <Table theadData={theadData} tbodyData={tbodyData} actionData={actionData} />
            <div className="horizontalLine"></div>
            <div className="tableFooterContainer">
                <label>Total Record Count: </label><span> 01</span>
                <Select className='recordDropdown' options={Countries}   placeholder='10' />
                <label>Records Per Page</label>
            </div>
        </div>
    )
}

export { OfferLetter }


{/* <PDFViewer className="pdfViewer">
                <Document>
                    <Page style={styles.body}>
                        <Image
                            style={styles.watermark}
                            src={companyLogo}
                            fixed />
                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                        <Text style={styles.footer} render={() => (
                            "CIN: 1213 | hdfgb@bdfh.com | +91 8107829401"
                        )} fixed />
                        <View style={styles.header} fixed>
                            <Image
                                style={styles.headerImage}
                                src={companyLogo}
                            />
                            <Text style={styles.headerCompanyName}>AESS Solutions Pvt. Ltd.</Text>
                        </View>
                        <Text style={styles.title}>Employment Offer Letter</Text>
                        <Text style={styles.author}>(Personal & Confidential Employment Agreement)</Text>
                        <Text style={[styles.subtitle, styles.bold, { marginBottom: 10 }]}>{dateTimeNow.toDateString()}</Text>
                        <Text style={[styles.subtitle, styles.bold]}>{`${titleOfApplicant} ${nameOfApplicant}`}</Text>
                        <Text style={[styles.subtitle, { marginBottom: 10 }]}>{phoneOfApplicant}</Text>
                        <Text style={styles.subtitle}>{addressLine1}</Text>
                        <Text style={styles.subtitle}>{addressLine2}</Text>
                        <Text style={[styles.subtitle, { marginBottom: 10 }]}>{cityPincode}</Text>
                        <Text style={styles.text}>On behalf of AESS Solutions Pvt. Ltd, we are excited to make this Offer of Employment with the Company.
                        </Text>


                        <PdfPositionTable data={positionData} />


                        <Text style={styles.text}>{`We really hope that you decide to accept this offer and look forward to working with you in the future.To confirm your acceptance of this post, please sign both copies of this letter as indicated overleaf and return one  copy  on  mail  to  us  by ${dateOfJoining.toDateString()}. Please  confirm  the  date  of  joining  so  that  necessary arrangements can be made for an effective induction. Your employment agreement will be effective from the actual date of joining.`}
                        </Text>
                        <Text style={styles.text}>Your appointment will commence with effect from your date of joining the Company, which should not in any  case  be  later  than  the  date  of  joining  mentioned  above.  In  the  event  of  you  failing  to  join  by  the aforementioned date, this employment agreement (‘Agreement’) shall stand terminated.
                        </Text>
                        <Text style={styles.text}>You can be transferred to any other office of the Company in any other location in India or outside India, as decided by the Company from time to time.
                        </Text>
                        <Text style={styles.text}>If  the  terms  and conditions  of  this  offer  letter  are  acceptable  to  you,  please  confirm  your  acceptance  by signing andreturning a copy of this letter to the Company within the timelines specified by the HR at the time of joining, failing which, this offer stands automatically withdrawn by the Company without any further notice to you.
                        </Text>
                        <Text style={styles.sectionHeading}>Terms & Conditions</Text>
                        <Text style={styles.sectionSubHeading}>A. Compensation</Text>
                        <Text style={styles.text}>Your annual fixed compensation will be INR 1,50,00 (One LakhFiftyThousandOnly). The detailed break-up   of   the   salary   components,   allowance   and   reimbursements   which   are   offered   as   part   of   your compensation  is  mentioned  in Annexure  1. The  Company  follows  a  system  of  annual  appraisal  of performance in the job to revise your compensation. The revision in your compensation post performance evaluation is subject to the discretion of the Company. The changes in your compensation (if any) will be communicated to you separately.
                        </Text>
                        <Text style={styles.sectionSubHeading}>B. Background Verification</Text>
                        <Text style={styles.text}>The Company may, at any time, (or as part of the joining formalities) conduct reference / background checks (including  but  not  limited  to  previous  employers,  qualifications,  etc.).  In  the  event  the  statements  / particulars furnished by you at the time of joining is found to be false or misleading or any information was suppressed, or if the Company, during the course of the check receives any adverse report against you that may be detrimental to the interests of the Company, the Company shall have the right to terminate your services  forthwith  without  giving  any  notice,  notwithstanding  any  other  terms  and conditions  stipulated herein. In the event there are any pending / closed legal cases against you in your professional and personal capacity in the courts of law, you shall disclose the same to the Company immediately.
                        </Text>
                        <Text style={styles.sectionSubHeading}>C. Probation</Text>
                        <Text style={styles.text}>You will be on probation for a period of 6 months, which may be extended by a maximum of another six months,  at  the  sole  discretion of  the  company.  You shall be  deemed to  be  on probation until  a letter  of confirmation in writing is issued to you. If during the probation period, your performance is not up to the mark, you may not be considered for confirmation and your service shall be terminated as per the decision of the management.
                        </Text>
                        <Text style={styles.sectionSubHeading}>D. Miscellaneous</Text>
                        <Text style={styles.sectionSubHeading}>1. Working Hours</Text>
                        <Text style={styles.text}>You will be required to work as per the Company’s working hour’s policy applicable to you.The  work timings, schedules and shifts may vary from time to time based on the business requirement. You may be required to work additional hours as appropriate to fulfill the responsibilities of your role.
                        </Text>
                        <Text style={styles.sectionSubHeading}>2. Leaves</Text>
                        <Text style={styles.text}>You shall be entitled to Earned Leave in accordance with the Company policy subject to the applicable law.
                        </Text>
                        <Text style={styles.sectionSubHeading}>3. Gratuity</Text>
                        <Text style={styles.text}>You shall be entitled to payment of gratuity as per the Company policy and in line with the applicable law
                        </Text>
                        <Text style={styles.sectionSubHeading}>4. Taxation</Text>
                        <Text style={styles.text}>Any  amount  payable  by  the  Company  to  you  towards  Compensation,  other  entitlements  and  payments shall be subject to deduction of withholding taxes and, or any other taxes.
                        </Text>
                        <Text style={styles.sectionSubHeading}>5. Confidentiality of Information</Text>
                        <Text style={styles.text}>For the purposes of this Agreement, 'Confidential Information' in relation to the Company means: -
                        </Text>
                        <Text style={styles.subText}>a. Trade secrets.</Text>
                        <Text style={styles.subText}>b. Lists or details of its suppliers, their services, or customers and the services and their terms of business.</Text>
                        <Text style={styles.subText}>c. Prices charged to and terms of business with clients.</Text>
                        <Text style={styles.subText}>d. Marketing plans and revenue forecasts.</Text>
                        <Text style={styles.subText}>e. Any proposals relating to the future of Company or any of its business or any part thereof.</Text>
                        <Text style={styles.subText}>f. Details of its employees and officers and of the remuneration andother benefits paid.</Text>
                        <Text style={styles.subText}>g. Information   relating   to   business   matters,   corporate   plans,   management   systems,investments, finances,  accounts,  marketing  or  sales  of  any  past,  present  or  future  products  or  service,  processes, inventions,  designs,  know  how,  discoveries,  technical/financial  specifications  and  other  technical  or financial  information  relating  to  the  creation,  production  or  supply  of  any  past,  present  or  future products  or  service  of  the  Company,  any  information  given  to  the  Company  in  confidence  by clients/customers, suppliers or other persons and any other information (whether or not recorded in documentary form, or on computer disk or tape) which is confidential or commercially sensitive and is not in the public domain.</Text>
                        <Text style={styles.subText}>h. Any other information which is notified to you as confidential.</Text>
                        <Text style={styles.subText}>i. IP Protection.</Text>
                        <Text style={[styles.subText, { marginBottom: 10 }]}>j. Any items developed by you or anyone in the company shouldn’t be transmitted to another party without consent of the company.</Text>

                        <Text style={styles.text}>You shall  not,  either  during  your  employment  or  at  any  time thereafter,  except  as  required  by  law,  use, divulge or disclose to any person any Confidential Information, which may have come to your knowledge at  any  time  during  the  course  of  your  employment  with  the  Company. This  clause  will cease  to  apply  to information which enters the public domain other than (directly or indirectly) through your act, omission, negligence or fault.
                        </Text>

                        <Text style={styles.sectionSubHeading}>6. Employment Conditions</Text>
                        <Text style={styles.text}>During  the  course  of  your  employment,  you  will  not  be  permitted  to  undertake  any  other  employment (including self-employment), consulting assignments, or engage in any external activities of a commercial nature  without  prior  written  approval  of  the  Company.  You  will  be  required  to  effectively  carry  out  all duties, responsibilities and obligations assigned to you by your manager and/or others  authorized by the Company to assign such duties and responsibilities.
                        </Text>
                        <Text style={styles.sectionSubHeading}>7. Travel</Text>
                        <Text style={styles.text}>You shall travel to such places, within or outside India, as the Company may from time to time require in relation to the Company's business.
                        </Text>
                        <Text style={styles.sectionSubHeading}>8. Intellectual Property Rights</Text>
                        <Text style={styles.text}>You acknowledge that the Company is the absolute, unrestricted and exclusive owner of the Confidential Information  or  other  proprietary  technical,  financial,  marketing,  manufacturing,  distribution  or  other business  related  information  or  trade  secrets  of  the  Company,  including  without  limitation,  concepts, techniques,  processes, designs, methods,  systems,  designs,  clients,  cost  data,  computer  programs, formulae, and other information used by you in course of your employment with the Company. You shall not  in any  manner  whatsoever,  represent  and/or  claim  that  you  have  any  interest  by  way  of  ownership, assignment or otherwise in the same.
                        </Text>
                        <Text style={styles.text}>You acknowledge that the Company shall own all rights, title and interest including copyright in any work created  by  you  in  course  of  your  employment  with  the  Company.  To  the  extent  such  rights  do  not  vest immediately in the Company, you agree to and irrevocably and unconditionally assign to the Company all your rights, title and interest including copyright in such works for adequate consideration, receipt whereof
                        </Text>
                        <Text style={styles.text}>you hereby acknowledge. You agree to execute such other documents, as may be required by the Company, for recording the Company as the owner of such works at the Company's cost and expense.
                        </Text>

                        <Text style={styles.sectionSubHeading}>9. End of Service</Text>
                        <Text style={styles.text}>During the Probation Period, the Company may terminate this Agreement without assigning any reasons upon  thirty  (30)  days  prior written  notice  or  payment  of  salary  in  lieu  thereof,  at  the  discretion  of  the Company. Similarly, during the Probation Period you may also terminate this Agreement without assigning any reasons upon thirty (30) days prior notice in writing or payment by you to the Company of the salary in lieu thereof. In such an event and in addition to the thirty (30) days written notice or salary in lieu thereof, you shall also be liable to reimburse to the Company any joining bonus/ sign-on bonus paid to you by the Company at the time of your joining the employment of the Company.
                        </Text>
                        <Text style={styles.text}>Upon your  confirmation, either  Party  shall be  free  to  terminate  this  Agreement  at  will  and,  at  any  time, with or without cause, upon ninety (90) days prior written notice by the Party desirous of terminating this Agreement or payment of equivalent  salary in lieu thereof or a combination thereof,  at the discretion of the Company. In case you have received or otherwise entitled to any joining bonus/ sign-on bonus and, you exercise the option of terminating this Agreement within the period agreed as per your joining bonus/ sign-on bonus letter, you shall be liable to refund to the Company such joining bonus/ sign-on bonus as paid to you by the Company which shall be in addition to the 60 (Sixty)dayswritten notice or salary in lieu thereof or a combination thereof.
                        </Text>
                        <Text style={styles.sectionSubHeading}>10. Breach or Misconduct</Text>
                        <Text style={styles.text}>Notwithstanding  anything  herein,  the  Company  shall  be  entitled  to  terminate  this  Agreement,  without notice and with immediate effect, in the event you are:
                        </Text>
                        <Text style={styles.subText}>a. Found to have engaged in any act of misconduct or negligence in the discharge of your duties or in the conduct of the Company's business.</Text>
                        <Text style={styles.subText}>b. Found to have engaged in any other act or omission, inconsistent with your duties.</Text>
                        <Text style={styles.subText}>c. Found to have engaged in anybreach of this Agreement, or the Company Policy or lawful orders given to you by the Company.</Text>
                        <Text style={styles.subText}>d. Convicted of any criminal offence.</Text>
                        <Text style={styles.subText}>e. Found to  have engaged in unauthorized absence or absconding from work beyond a period of seven (7) days.</Text>
                        <Text style={styles.subText}>f. Found  to  have  engaged  in  theft  of  any  asset,  information  or  proprietary  documents  and/  or dissemination   of   any   confidential/proprietary   information   to   any   third   party   or   individual   not authorized by the Company for receipt of such information.</Text>
                        <Text style={[styles.subText, { marginBottom: 10 }]}>g. Any infringement to the company’s intellectual property is considered to be the breech in the contract.</Text>

                        <Text style={styles.sectionSubHeading}>11. Return of Property</Text>
                        <Text style={styles.text}>On the termination of this agreement by either party, you will be liable to  return any Company property /assets assigned to you. Failure to  return the same to the Company will be considered as an offence and value of the property will be recovered from your Full and Final settlement. In case the value of the property is greater than the Full and Final settlement, the Company may choose to withhold (wholly  / partly) the payment of the entire Full and Final settlement amount.
                        </Text>
                        <Text style={styles.sectionSubHeading}>12. Governing Law</Text>
                        <Text style={styles.text}>This  Agreement  shall  be  governed  and  construed  in  accordance  with  the  laws  of  India.  The  invalidity  or unenforceability  of  any  part  of  this  Agreement  shall  not  affect  the  binding  effect  of  the  rest  of  the Agreement. This Agreement shall be concluded and will be deemed effective on the submission ofa signed copy  to  us,  provided that  your  Compensation and Other  Entitlements  shall  not  begin to accrue  until  you commence work for the Company.
                        </Text>
                        <Text style={styles.sectionSubHeading}>13. Dispute Resolution</Text>
                        <Text style={styles.text}>Your employment with the Company is subject to Indian laws. All disputes shall be subject to the jurisdiction of Bhopal, Madhya Pradeshonly.
                        </Text>
                        <Text style={styles.gap}>.</Text>

                        <Text style={styles.subText}>With Best Regards</Text>
                        <Text style={styles.subText}>From AESS Solutions Pvt. Ltd.</Text>
                        <Text style={styles.subText}>Mr. Faisal Kahan</Text>
                        <Text style={styles.subText}>CEO</Text>

                        <Text style={styles.gap}>.</Text>

                        <Text style={styles.text}>I accept the above terms and conditions and shall abide by the service rules of the company.</Text>
                        <Text style={styles.text}>Candidate Name & Signature _______________________             Joining Date __________________</Text>

                    </Page>
                    <Page style={styles.body}>
                        <Image
                            style={styles.watermark}
                            src={companyLogo}
                            fixed />
                        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )} fixed />
                        <Text style={styles.footer} render={() => (
                            "CIN: 1213 | hdfgb@bdfh.com | +91 8107829401"
                        )} fixed />
                        <View style={styles.header} fixed>
                            <Image
                                style={styles.headerImage}
                                src={companyLogo}
                            />
                            <Text style={styles.headerCompanyName}>AESS Solutions Pvt. Ltd.</Text>
                        </View>


                        <Text style={styles.sectionSubHeading}>Annexure 1</Text>
                        <Text style={styles.sectionSubHeading}>Compensation Break-up</Text>
                        <Text style={styles.sectionSubHeading}>{`${titleOfApplicant} ${nameOfApplicant} (${roleOfApplicant})`}</Text>
                        <SalaryStructureTable data={SalaryData} />
                    </Page>
                </Document>
            </PDFViewer> */}