import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { onBoardingFormSchema } from "./OnboardingFormsSchemas";

import { CandidateForm } from "./Forms/CandidateForm";
import { postRequest } from "../../../Services/axios";
import { Day1FeedForm } from "./Forms/Day1FeedForm";
import { day1schema } from "./Day1FeedbackSchemas";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

const initialvalue = {
  question: "",
  displayOrder: "",
};
const CreateDay1Feedback = (props) => {
  const { state } = useLocation();
  const data = state?.selectedRecord;
  const dispatch = useDispatch();
  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "OnBoardingFeedbackQuestions/Day1Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "OnBoardingFeedbackQuestions/Day1Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  
  useEffect(() => {
    document.title = `PeopleSol - Day 1 Feedback`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  
  const onSubmit = async (values) => {
    
    const edit = {
      ...values,
      Id: data?.Id,
      isActive:data?.IsActive,
      
     
    };
    
    const create = {
      ...values,
    };
    data?Edit(edit) : postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/onboarding/day1feedback");
  };

  return (
    <FormCustomFormik
      FormHeading={data?'Edit Day 1 Feedback':'Create Day 1 Feedback'}
      validationSchema={day1schema}
      ButtonLabel={data?'Update Day 1 Feedback':'Create Day 1 Feedback'}
      onSubmit={onSubmit}
      initialvalue={data
        ? {
            ...initialvalue,
            question:data?.Question,
            displayOrder: data?.DisplayOrder,
    
          }
        : initialvalue}
      returnPage={returnPage}
      propsDiv={<Day1FeedForm />}
    />
  );
};

export { CreateDay1Feedback };
