/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import AESSCompanyLogo from "../../../Assets/AESSCompanyLogo.png";

import "./DesignPrint.css";
import { format } from "date-fns";
import { TableBody } from "@mui/material";
import { StyledTableCell } from "../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { postRequest } from "../../../Services/axios";
import { TimeCustomFormik } from "../../../Components/FormComponent/TimeCustomFormik";

export const VisitorsRepeatedTable = ({
  formValues,
  locationGroup,
  index,
  remove,
  Visitlocationlist,
  push,
  insert,
  values,
  setLoadingForm,
}) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState([]);
  const [meetWhom, setMeetWhom] = useState([]);

  const getLocation = async (id) => {
    try {
      const result = await postRequest(
        `VisitorsController/GetLocations?LocationGroupId=${id}`
      );
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };
  const getMeetWhom = async (id) => {
    try {
      const result = await postRequest(
        `VisitorsController/GetEmployeesBasedOnLocationS?EmployeeIDs=0&CurrentLocationId=${id}`
      );
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });

      setMeetWhom(listData);
    } catch (error) {
    } finally {
    }
  };
  // useEffect(() => {
  //   if (
  //     formValues.Visitlocationlist[0].locationId !== "" &&
  //     formValues.Visitlocationlist[0].locationGroupId !== ""
  //   )
  //     setLoadingForm(true);
  //   while (index > 0) {
  //     remove(index);
  //     index--;
  //   }
  //   setLoadingForm(false);
  // }, [
  //   formValues?.Visitlocationlist[0]?.locationGroupId,
  //   formValues?.Visitlocationlist[0]?.locationId,
  // ]);

  useEffect(() => {
    if (formValues?.Visitlocationlist[index]?.locationId) {
      for (let i = 0; i < formValues?.Visitlocationlist.length; i++) {
        formValues.Visitlocationlist[i].locationId =
          formValues.Visitlocationlist[0].locationId;
      }
    }
  }, [formValues?.Visitlocationlist[0]?.locationId]);
  useEffect(() => {
    if (formValues?.Visitlocationlist[index]?.date) {
      for (let i = 0; i < formValues?.Visitlocationlist.length; i++) {
        formValues.Visitlocationlist[i].date =
          formValues.Visitlocationlist[0].date;
      }
    }
  }, [formValues?.Visitlocationlist[0]?.date]);

  useEffect(() => {
    if (formValues?.Visitlocationlist[index]?.locationGroupId) {
      // Reset the fields for the current row (index)
      formValues.Visitlocationlist[index].locationGroupId =
        formValues.Visitlocationlist[0].locationGroupId;
      formValues.Visitlocationlist[index].employeeId = "";
      formValues.Visitlocationlist[index].locationId = "";
      formValues.Visitlocationlist[index].visitId = "";
    }
  }, [formValues?.Visitlocationlist[0]?.locationGroupId]);

  useEffect(() => {
    if (formValues?.Visitlocationlist[0]?.locationGroupId)
      getLocation(formValues?.Visitlocationlist[0]?.locationGroupId);
    // getMeetWhom(formValues?.Visitlocationlist[0]?.locationId);
  }, [formValues?.Visitlocationlist[index]?.locationGroupId]);
  useEffect(() => {
    if (formValues?.Visitlocationlist[0]?.locationId)
      getMeetWhom(formValues?.Visitlocationlist[0]?.locationId);
  }, [formValues?.Visitlocationlist[0]?.locationId]);
  return (
    <TableBody key={index}>
      {/* 1st column */}
      <StyledTableCell align="center">{index + 1}</StyledTableCell>
      {/* 2nd column */}
      <StyledTableCell>
        <SelectForm
          name={`Visitlocationlist[${index}].locationGroupId`}
          disabled={index > 0 ? true : false}
          options={locationGroup}
          placeholder={" Select The Location Group"}
        />
      </StyledTableCell>

      <StyledTableCell>
        <SelectForm
          name={`Visitlocationlist[${index}].locationId`}
          disabled={index > 0 ? true : false}
          options={location}
          placeholder={" Select The Location"}
          // value={locationIddata}
        />
      </StyledTableCell>

      <StyledTableCell>
        <SelectForm
          name={`Visitlocationlist[${index}].employeeId`}
          options={meetWhom}
          placeholder={" Select The Employee"}
        />
      </StyledTableCell>

      {/* 3rd column */}
      <StyledTableCell>
        <DatePickerFormiks
          minDate={new Date()}
          // maxDate={index > 0 ? values.Visitlocationlist[0].visitingTime : ""}
          // selected={index > 0 ?values.Visitlocationlist[0].visitingTime : undefined }
          name={`Visitlocationlist[${index}].date`}
          disabled={index > 0 ? true : false}
        />
      </StyledTableCell>
      <StyledTableCell>
        <TimeCustomFormik
          name={`Visitlocationlist[${index}].time`}
          placeholder={" Select Time "}
        />
      </StyledTableCell>
      {/* 4th column */}

      <StyledTableCell
        sx={{
          textAlign: "center",
        }}
      >
        <button
          type="button"
          className="remove-btn"
          onClick={() =>
            Visitlocationlist.length > 1
              ? remove(index)
              : dispatch(
                  showToast({
                    text: "Atleast One Item is Required ",
                    severity: "error",
                  })
                )
          }
        >
          -
        </button>
        <button
          type="button"
          className="add-btn"
          onClick={() => {
            insert(index + 1, {
              locationGroupId: Visitlocationlist[0].locationGroupId,
              locationId: Visitlocationlist[0].locationId,
              date: Visitlocationlist[0].date,
              // employeeId: Visitlocationlist[0].employeeId,
              time: "",
              visitId: 0,
            });
          }}
        >
          +
        </button>
      </StyledTableCell>
    </TableBody>
  );
};
