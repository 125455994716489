import React from 'react'
import { useEffect } from 'react';

const OptionalLeaveCardComp = ({title, date}) => {
 
  return (
    <div className="attendanceCardContentContainer" style={{backgroundColor:`#FFF2F0`, borderLeft: '2px solid #FB7158'}}>
      <div className="cardDateDisplay">{date ? date : ""}</div>
        <div className='attendanceContentStatus' style={{color:"#FB7158", fontSize: '10px', width: 'calc(100% - 3px)', lineHeight: '12px'}} >
            <label>{title ?title: ""}</label>
        </div>
        
        
        </div>
  )
}

export {OptionalLeaveCardComp}
