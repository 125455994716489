
import { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";

const AssetSpecForm = ({data}) => {
  useEffect(() => {
    document.title = `PeopleSol - Asset Spec`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div >
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
        {!data && 
    <div style={{color:"var(--primary)",fontSize:"14px",marginLeft:"13px"}}>For multiple entries, separate Names with ';' .  Eg: Name1;Name2;Name3</div>
        }
    </div>
  );
};

export  {AssetSpecForm}
