import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { getRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { DetailsStep } from "../../../../Components/FormComponent/DetailsStep";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";
import Nodata from "../../../../Assets/Nodata.svg";

const DetailsPayArea = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`PayArea/Details/${selectedId}`);
      setEventtData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Details Asset Material`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Pay Area Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>

        <div className="moduleMasterDetailsPageBody">
          {loading ? <Loader/>          
          : eventtData?.length ?
           eventtData?.map((item) => {
            return (
              <>
                <DetailsStep
                  detailTitle={"Pay Area"}
                  Details={item?.payArea?.name}
                />
                      <DetailsStep
                  detailTitle={"Total Employee"}
                  Details={item?.payAreaMappingDetails?.length}
                />
                <DetailsStep
                  detailTitle={"Employees Mapped"}
                  Details={
                    <div className="payareaemp">
                      {item?.payAreaMappingDetails?.map(
                        (item) => <ul ><li>{item?.employeeName}</li></ul>
                      )}
                    </div>
                  }
                />
                  <DetailsStep
                    detailTitle={"Created On"}
                    Details={item?.payArea?.createdOn && format(
                      new Date(item?.payArea?.createdOn),
                      "dd/MM/yyyy hh:mm a"
                    )}
                  />
                  <DetailsStep
                    detailTitle={"Last Updated On"}
                    Details={item?.payArea?.lastUpdatedOn&&format(
                      new Date(item?.payArea?.lastUpdatedOn),
                      "dd/MM/yyyy hh:mm a"
                    )}
                  />
              </>
            );
          }) :  <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{ "No Data Found"}</h4>
        </div>}
        </div>
      </div>
    </div>
  );
};

export { DetailsPayArea };
