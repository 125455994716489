/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import { Loader } from "../../../../Components/FormComponent/Loader";
export const DetailsAppraiserReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const data = state?.item;
  const [editFormData, seEditFormData] = useState([]);
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSGoalReviews/RoReviews?ID=${selectedId}`
      );
      seEditFormData(result?.data?.data.pmsReviews);
      setText(result?.data?.data._pmsAppraisalCycles);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Appraisal Review Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const selfRatingObtain = (total) => {
    if (total >= 111 && total <= 120) {
      return 5;
    }
    if (total >= 101 && total <= 110) {
      return 4;
    }
    if (total >= 91 && total <= 100) {
      return 3;
    }
    if (total >= 81 && total <= 90) {
      return 2;
    }
    if (total < 80) {
      return 1;
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const count = editFormData?.filter(
    (item) => item?.status === "Appraiser Review Pending"
  )?.length;
  const countA = editFormData?.filter(
    (item) => item?.status === "Review Pending"
  )?.length;

  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal" style={{ padding: "24px" }}>
        <div
            className="headingContainer"
            style={{ marginLeft: "12px", width: "98%",padding:'0' }}
          >
            <div
              onClick={() => navigate(-1)}
              style={{ display: "flex" }}
              className="returnToDashboard cursor"
            >
              <div>
                <svg
                  width="20"
                  height="8"
                  viewBox="0 0 20 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                    fill="#1296B0"
                  />
                </svg>
              </div>
              <label className="returnLabelHolder">{text?.name}</label>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="gridPend twoColumn">
              <div
                style={{
                  display: "block",
                }}
              >
                <div className="button primary goalTeam">
                  <span>Pending</span>
                  <span
                    style={{
                      border: "0.75px solid var(--secondary)",
                      borderRadius: "4px",
                      fontSize: "11px",
                      padding: "0 3px",
                    }}
                  >
                    {count}
                  </span>
                </div>
                <div>
                  {editFormData &&
                    editFormData?.map((item, index) => {
                      if (item?.status === "Appraiser Review Pending") {
                        return (
                          <div
                            className="internalJobCard"
                            key={item?.id}
                            onClick={(e) =>
                              navigate(
                                `/pms/performancereview/teamreview/details/${item?.appraisalCycleId}`,
                                {
                                  state: { item },
                                }
                              )
                            }
                          >
                            <div className="cardContainer cursor">
                              <div className="headingContainer">
                                <h3
                                  style={{
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item?.employee?.nameWithPersonNumber}
                                </h3>
                                {/* <span className="internalJobDateTime" style={{color:'var(--secondary)'}}>
                        Pending
                      </span> */}
                              </div>
                              <div
                                className="jobDetailContainerHolder"
                                style={{ padding: "7px 0px 23px 11px" }}
                              >
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    Submitted Date
                                  </label>
                                  <label>
                                    {" "}
                                    {item?.createdOn &&
                                      format(
                                        new Date(item?.createdOn),
                                        "dd/MM/yyyy "
                                      )}
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    Self Review End Date
                                  </label>
                                  <label>
                                    {item?.appraisalCycle
                                      ?.selfApprisalEndDate &&
                                      format(
                                        new Date(
                                          item?.appraisalCycle?.selfApprisalEndDate
                                        ),
                                        "dd/MM/yyyy "
                                      )}
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    Self Achievement Percent
                                  </label>
                                  <label>
                                    {editFormData[
                                      index
                                    ].pmsReviewFeedbacksUpdates.reduce(
                                      (sum, card) =>
                                        sum + card.employeeAchievement,
                                      0
                                    )}{" "}
                                    %
                                  </label>
                                </div>
                                <div className="jobDetailContainer">
                                  <div className="verticalLine"></div>
                                  <label className="jobDesignation">
                                    Self Rating
                                  </label>
                                  <label>
                                    {selfRatingObtain(
                                      editFormData[
                                        index
                                      ].pmsReviewFeedbacksUpdates.reduce(
                                        (sum, card) =>
                                          sum + card.employeeAchievement,
                                        0
                                      )
                                    )}
                                    /5
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
              <div>
                <div
                  className="button primary goalTeam"
                  style={{
                    background: "#E7F8EC",
                    color: "rgba(13, 181, 60, 1)",
                  }}
                >
                  <span>Reviewed</span>
                  <span
                    style={{
                      border: "0.75px solid green",
                      borderRadius: "4px",
                      fontSize: "11px",
                      padding: "0 3px",
                    }}
                  >
                    {countA}
                  </span>
                </div>

                {editFormData &&
                  editFormData?.map((item, index) => {
                    if (item?.status === "Review Pending") {
                      return (
                        <div
                          className="internalJobCard"
                          key={item?.id}
                          onClick={(e) =>
                            navigate(
                              `/pms/performancereview/teamreview/details/${item?.appraisalCycleId}`,
                              {
                                state: { item },
                              }
                            )
                          }
                        >
                          <div className="cardContainer cursor">
                            <div className="headingContainer">
                              <h3
                                style={{
                                  fontSize: "16px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item?.employee?.nameWithPersonNumber}
                              </h3>
                              {/* <span className="internalJobDateTime" style={{color:'var(--secondary)'}}>
                      Pending
                    </span> */}
                            </div>
                            <div
                              className="jobDetailContainerHolder"
                              style={{ padding: "7px 0px 23px 11px" }}
                            >
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Submitted Date
                                </label>
                                <label>
                                  {" "}
                                  {item?.createdOn &&
                                    format(
                                      new Date(item?.createdOn),
                                      "dd/MM/yyyy "
                                    )}
                                </label>
                              </div>
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Self Review End Date
                                </label>
                                <label>
                                  {item?.appraisalCycle
                                    ?.selfApprisalEndDate &&
                                    format(
                                      new Date(
                                        item?.appraisalCycle?.selfApprisalEndDate
                                      ),
                                      "dd/MM/yyyy "
                                    )}
                                </label>
                              </div>
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Self Achievement Percent
                                </label>
                                <label>
                                  {editFormData[
                                    index
                                  ].pmsReviewFeedbacksUpdates.reduce(
                                    (sum, card) =>
                                      sum + card.employeeAchievement,
                                    0
                                  )}{" "}
                                  %
                                </label>
                              </div>
                              <div className="jobDetailContainer">
                                <div className="verticalLine"></div>
                                <label className="jobDesignation">
                                  Self Rating
                                </label>
                                <label>
                                  {selfRatingObtain(
                                    editFormData[
                                      index
                                    ].pmsReviewFeedbacksUpdates.reduce(
                                      (sum, card) =>
                                        sum + card.employeeAchievement,
                                      0
                                    )
                                  )}
                                  /5
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    else return null;
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
