import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CertificateImage from "../../../Assets/CertificateImage.png";

const CertificateComp = ({
  name,
  description,
  allCertificate,
  index,
  requiredCertificateProp,
}) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Certificate`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [active, setActive] = useState(false);
  const isOpenHandler = () => {
    navigate("/ld/learning/mylearning/learningvideos/certificate", {
      state: { requiredCertificateProp },
    });
  };

  return (
    <>
      {!requiredCertificateProp?.certificatedata[0]?.certificateNeeded ? (
        <div class="certificate-notice">
          Please note: This course does not include a completion certificate.
        </div>
      ) : (
        <div
          onClick={isOpenHandler}
          className={`detailsListComp ${active && "detailsListCompActive"}`}
        >
          <div
            className="learnerImageContainer"
            style={{ width: "6rem", height: "4rem" }}
          >
            <img
              src={CertificateImage}
              alt={CertificateImage}
              className="learnerImageHolder cursor certificateImage"
            />
          </div>
          <div className="commentContentHolder" style={{ width: "19rem" }}>
            <div className="commentOwner">
              {requiredCertificateProp?.categoryName}
            </div>

            {/* <div className="commentText">{description}</div> */}
          </div>
          {/* <div className="completeIconHolder" style={{ width: "4rem" }}>
          {active ? (
            <img
              id="downloadbtn"
              className="cursor"
              onClick={() => clickHandler()}
              src={DownloadComplete}
              alt={DownloadComplete}
            />
          ) : (
            <img
              id="downloadbtn"
              className="cursor"
              onClick={() => clickHandler()}
              src={DownloadInComplete}
              alt={DownloadInComplete}
            />
          )}

          
        </div> */}
        </div>
      )}

      {/* <div
        className="horizontalGrayLine"
        style={{ border: "0.5px solid #BFBFBF" }}
      ></div> */}
    </>
  );
};
export { CertificateComp };
