import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalLeave } from "../Components/LeaveApproval/PendingApprovalLeave";
import { ApprovedApprovalLeave } from "../Components/LeaveApproval/ApprovedApprovalLeave";
import { RejectedApprovalLeave } from "../Components/LeaveApproval/RejectedApprovalLeave";
import { AllApprovalLeave } from "../Components/LeaveApproval/AllApprovalLeave";
import { useEffect } from "react";

const ApprovalLeave = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Leave`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalLeave
            title={"Leave"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <ApprovedApprovalLeave title={"Leave"} />
        </>
      )}
      {tabIndex === 2 && (
        <>
          <RejectedApprovalLeave title={"Leave"} />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <AllApprovalLeave
            title={"Leave"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalLeave };
