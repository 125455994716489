import React, { useState, useEffect } from 'react';
import { Tabs, Tab, IconButton } from '@mui/material';
// import "./ConsultantJobsPage.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "../../Components/index";
import Select from "react-dropdown-select";
import { CandidateJobs } from '../CandidatePage/Components/CandidateJobs';
import { ConsultantJobs } from './Components/ConsultantJobs';
import { Candidate } from './Components/Candidate';

const ConsultantCandidatePage = () => {

    
   
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newTabIndex) => {
        if (newTabIndex === 0) {
            navigate("candidates");
        }
        // else if (newTabIndex === 1) {
        //     navigate("applied");
        // }
    }
    const location = useLocation();
    const navigate = useNavigate();
    // useEffect(() => {
    //     const currentpath = location.pathname.toLowerCase();
    //     if (currentpath.includes("consultant/candidate")) {
    //         if (currentpath.includes("candidate")) {
    //             setTabIndex(0);
    //         }
    //         else if (currentpath.includes("applied")) {
    //             setTabIndex(1);
    //         }
    //         else {
    //             navigate("candidate");
    //         }
    //     }
    // }, [location.pathname]);
    useEffect(()=>{
        document.title = `PeopleSol - Consultant Candidate`;
        return () => {
          document.title = 'PeopleSol';
        };
      })


    useEffect(() => {
        const currentpath = location.pathname.toLowerCase();
        
         if (currentpath.includes("candidate")) {
            
            if(currentpath.includes("create"))
            {
                setCreateCandidateVisible(true);
            }
            else
            {
                setCreateCandidateVisible(false);
            }
        }
    });

    const [createCandidateVisible, setCreateCandidateVisible] = useState(false);
    
    return (
        <>
            {/* {createCandidateVisible ? <CreateCandidate/> : null} */}
        <div className="homeEngageMainContainer candidateJobsContainer">
        <div className="moduleMainContainer">
            <div className="tabContainer">
                <Tabs value={tabIndex}
                
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{ style: { background: "#1296B0" } }}>
                    <Tab label={<span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>Candidate</span>} />
                    
                </Tabs>
                <div className="tabContainerLine"></div>
            </div>
            {tabIndex === 0 && (
                <>
                    <Candidate/>
                </>)}
        
        </div>
        </div>
        </>
    )
}

export { ConsultantCandidatePage };