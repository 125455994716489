import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { format } from "date-fns";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { DateRangePick } from "../../../../Components/FormComponent/DateRangePick";
import SelectCustomEmployeeCreate from "../../../DropdownComponents/SelectCustomEmployeeCreate";

const ItReports = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [dateOfJoning, setDateOfJoining] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedTransferTypeList, setSelectedTransferTypeList] = useState([]);
  const [selectedtypeOfTransferList, setSelectedtypeOfTransferList] = useState(
    []
  );
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [optionEmployee, setOptionEmployee] = React.useState([]);
  const [typeAll, setTypeAll] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [selId, setSelId] = useState(false);
  const [dateStartDate, setDateStartDate] = useState(false);
  const [dateEndDate, setDateEndDate] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  const togglePopupapprove = (type) => {
    setTypeAll(type);
    setIsApprovePopup((prev) => !prev);
  };

  const defaultList = "RelocationReqDL";
  const { LoggedInUser } = useSelector((state) => state.auth);

  //   const getPermissions = async () => {
  //     if (LoggedInUser?.isReportingManager) {
  //       setActivityList((prev) => {
  //         if (prev.some((e) => e.id === 3)) {
  //           return prev;
  //         } else {
  //           return [
  //             ...prev,
  //             {
  //               id: 3,
  //               actionTitle: "Team Requests",
  //               badge: 0,
  //               favourite: (localStorage.getItem(defaultList) ?? "1") === "3",
  //               defaultList: defaultList,
  //             },
  //           ];
  //         }
  //       });
  //     }
  //     if (
  //       LoggedInUser?.isHRHead ||
  //       LoggedInUser?.isHRDirector ||
  //       LoggedInUser?.teamnames?.includes("HR Team") ||
  //       LoggedInUser?.teamnames?.includes("Transfer Management") ||
  //       LoggedInUser?.hrbpLocationIds?.length ||
  //       LoggedInUser?.taAreaIds?.length ||
  //       LoggedInUser?.hrbpDepartmentIds?.length
  //     ) {
  //       setActivityList((prev) => {
  //         if (prev.some((e) => e.id === 2)) {
  //           return prev;
  //         } else {
  //           return [
  //             ...prev,
  //             {
  //               id: 2,
  //               actionTitle: "All Requests",
  //               badge: 0,
  //               favourite: (localStorage.getItem(defaultList) ?? "1") === "2",
  //               defaultList: defaultList,
  //             },
  //           ];
  //         }
  //       });
  //     }
  //     setLoading(false);
  //   };

  const [activeList, setActiveList] = useState(
    localStorage.getItem(defaultList) ?? "1"
  );
  function handleActiveListChange(newValue) {
    setActiveList(newValue.toString());
    setSelectedRows([]);
    setRecordsSelected(noOfRecords[0]);
    setSearchParam(null);
    setSortParam("Id");
    setSortType("descending");
    setReqData({
      createdOn: null,
      lastUpdatedOn: null,
      dataTableRequest: {
        iDisplayLength: noOfRecords[0].value,
        iDisplayStart: 0,
        sSearch: null,
        sorting: "Id",
        sSortDir_0: "descending",
        isExport: false,
      },
    });
  }
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        ...reqData,
        dataTableRequest: {
          isExport: true,
        },
      };
      var result;
      result = await postRequest(
        "EmployeeAttendances/GetAllRequestList",
        downloadReqData
      );
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("PayrollReports/ITSummary", reqData);
      if (result.data) {
        console.log(" result.data.data", result.data.data);

        let tempData = [];
        result.data.data?.map((item) => {
          return tempData.push({
            ...item,
          });
        });
        setOriginalData(tempData);
        const clippedArray = tempData.map(({ createdBy, ...rest }) => ({
          ...rest,
        }));
        const finalArray = clippedArray.map(({ id, ...rest }) => ({
          id,
          ...rest,
        }));
        setTbodyData(finalArray);
      } else {
        setTbodyData(false);
      }
      setCount(result?.data?.summary);
      calculateTotalPages(result?.data?.summary);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  //console.log("originalData", originalData);

  const location = useLocation();
  useEffect(() => {
    document.title = `PeopleSol - Employeee Attendance Report `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("reportskpi")) getListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");

        setReqData({
          ...reqData,

          dataTableRequest: {
            ...reqData.dataTableRequest,

            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");

        setReqData({
          ...reqData,

          dataTableRequest: {
            ...reqData.dataTableRequest,

            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");

      setSortParam(heading);

      if (heading === "transferFor") heading = "transferType";
      setReqData({
        ...reqData,

        dataTableRequest: {
          ...reqData.dataTableRequest,

          sorting: heading,

          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      //   status: selectedStatusList[0]?.value,
      //   typeOfTransfer: selectedtypeOfTransferList[0]?.value,
      //   transferType: selectedTransferTypeList[0]?.value,
      employeeId: selectedEmployeeList[0]?.value,
      date: dateStartDate
        ? `${format(dateStartDate, "dd/MM/yyyy")} ${
            dateEndDate
              ? format(dateEndDate, "dd/MM/yyyy")
              : format(dateStartDate, "dd/MM/yyyy")
          }`
        : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);

    setDateEndDate(false);
    setDateStartDate(false);
    setSelectedStatusList([]);
    setSelectedEmployeeList(null);
    setSelectedtypeOfTransferList([]);
    setSelectedTransferTypeList([]);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      status: null,
      employeeId: null,
      transferType: null,
      typeOfTransfer: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };

  const childRef = useRef(null);
  function handleActivityClick() {
    childRef.current.updateState();
  }
  function handleActivityClose() {
    childRef.current.closeState();
  }
  // //console.log("childRef",childRef)
  return (
    <div className="fullTableContainerta" style={{ height: "100%" }}>
      <div className="contentTableHeight">
        <OutsideClickHandler
          onOutsideClick={() => {
            setFilterSearchToogle((prev) => {
              if (prev) return false;
              else return prev;
            });
          }}
        >
          <div
            className={`${
              filterSearchToogle
                ? "filterSearchContainerActive filterSearchContainer"
                : "filterSearchContainer"
            }`}
          >
            <div className="headingContainer">
              <h3>Filter</h3>
              <IconButton
                onClick={() => setFilterSearchToogle((prev) => !prev)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7579 1.56897L0.757935 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.757935 1.56897L18.7579 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
            <h5>Fields</h5>
            <div className="filterListContainer">
              {/* <div className="filterContainer">
                <p> Status </p>
                <SelectCustom
                  options={status}
                  values={selectedStatusList}
                  onChange={(value) => {
                    setSelectedStatusList(value);
                  }}
                />
              </div>

              <div className="filterContainer">
                <p> Transfer Type </p>
                <SelectCustom
                  options={transfer}
                  values={selectedTransferTypeList}
                  onChange={(value) => {
                    setSelectedTransferTypeList(value);
                  }}
                />
              </div>
              <div className="filterContainer">
                <p> Type of Transfer </p>
                <SelectCustom
                  options={transferType}
                  values={selectedtypeOfTransferList}
                  onChange={(value) => {
                    setSelectedtypeOfTransferList(value);
                  }}
                />
              </div> */}
              <div className="filterContainer">
                <p> Employee </p>
                <SelectCustomEmployeeCreate
                  selectedEmployeeList={selectedEmployeeList}
                  setSelectedEmployeeList={setSelectedEmployeeList}
                  values={selectedEmployeeList}
                />
              </div>
              <div className="filterContainer">
                <p>Date</p>
                <DateRangePick
                  selectedEnd={dateEndDate}
                  selectedStart={dateStartDate}
                  onChangeStart={(date) => setDateStartDate(date)}
                  onChangeEnd={(date) => setDateEndDate(date)}
                />
              </div>
              <div className="filterContainer">
                <p>Created On</p>
                <DateRangePicker
                  selectedEnd={createdOnEndDate}
                  selectedStart={createdOnStartDate}
                  onChangeStart={(date) => setCreatedOnStartDate(date)}
                  onChangeEnd={(date) => setCreatedOnEndDate(date)}
                />
              </div>
              <div className="filterContainer">
                <p>Last Updated On</p>
                <DateRangePicker
                  selectedEnd={lastUpdatedOnEndDate}
                  selectedStart={lastUpdatedOnStartDate}
                  onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                  onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
                />
              </div>
            </div>
            <div className="filterSearchFooterContainer">
              <button className="button secondaryButton" onClick={resetFilters}>
                Reset
              </button>
              <button className="button primaryButton" onClick={applyFilters}>
                Apply
              </button>
            </div>
          </div>
        </OutsideClickHandler>
        <div
          className="tableHeaderContainer"
          style={{
            padding: 0,
            boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
            marginBottom: "5px",
          }}
        >
          <div className="reportsheading" style={{ padding: "24px 0px" }}>
            <div
              className="returnToDashboard cursor"
              onClick={() => {
                navigate(-1);
              }}
            >
              <svg
                width="20"
                height="8"
                viewBox="0 0 20 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.243615 3.74484C0.0483524 3.9401 0.0483524 4.25668 0.243615 4.45194L3.4256 7.63392C3.62086 7.82918 3.93744 7.82918 4.1327 7.63392C4.32796 7.43866 4.32796 7.12208 4.1327 6.92682L1.30427 4.09839L4.1327 1.26996C4.32796 1.0747 4.32796 0.758117 4.1327 0.562855C3.93744 0.367593 3.62086 0.367593 3.4256 0.562855L0.243615 3.74484ZM18.624 4.59839C18.9002 4.59839 19.124 4.37453 19.124 4.09839C19.124 3.82225 18.9002 3.59839 18.624 3.59839V4.59839ZM0.597168 4.59839H18.624V3.59839H0.597168V4.59839Z"
                  fill="#1296B0"
                />
              </svg>

              <label className="returnLabelHolder">{props?.text}</label>
            </div>
          </div>
          <div className="search-box">
            <input
              className="search-text"
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchParam(e.target.value)}
            />
            <a className="search-btn">
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                  fill="#1296B0"
                />
              </svg>
            </a>
          </div>
          <Tooltip arrow title="Filter" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                setFilterSearchToogle((prev) => !prev);
              }}
            >
              {!filterSearchToogle ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                    stroke="#1296B0"
                    strokeWidth="1.5"
                  />
                </svg>
              ) : (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7579 1.56897L0.757935 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.757935 1.56897L18.7579 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Download" placement="top">
            <IconButton color="primary" onClick={exportTable}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 21 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                  stroke="#1296B0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </Tooltip>
        </div>
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div
                className="tableScrollContainerta"
                style={{ height: "calc(100% - 66px)" }}
              >
                <div className="tableContainerta">
                  <table className="">
                    {/* Table Header */}
                    <thead className="headerTableStyle">
                      <tr className="timeAttendenceHeader">
                        <th>Sno</th>
                        <th>Employee Name</th>
                        <th>PT Amount</th>
                        <th>Month</th>
                      </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody className="scrollable-bodyApprovalReport">
                      {tbodyData?.map((item, index) => (
                        <tr
                          className="rowstyle timeAttendenceBody"
                          key={item.id}
                        >
                          <td>{index + 1}</td>
                          <td>{item.employeeName}</td>
                          <td>{item.ptAmount}</td>
                          <td>{item.month}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      </div>
      <div className="horizontalLine"></div>
      <div className="tableFooterContainerta">
        <div>
          <label>Total Record Count: </label>
          <span>{count}</span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SelectCustom
            searchable={false}
            options={noOfRecords}
            dropdownPosition="top"
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { ItReports };
