import React, { useEffect } from 'react'
import { InputCustomFormik } from '../../../../Components/FormComponent/InputCustomFormik';
import { LabelCustom } from '../../../../Components/FormComponent/LabelCustom';
import { SelectForm } from '../../../../Components/FormComponent/SelectForm';
import { TextAreaFormik } from '../../../../Components/FormComponent/TextAreaFormik';
import { getRequest } from '../../../../Services/axios';

const opt = [
    { label: "Select", value: "null" },
    
  ];
 export const WorkflowDetailsFormGenral = (props) => {
    const {data}=props
    const [optionSer, setOptionSer] = React.useState([]);
    const getOptionSer = async () => {
      try {
        const result = await getRequest(
          "NotificationFlows/GetNotificationFlow"
        );
        let listData = [];
        result.data.map((item) => {
          listData.push({ label: item.title, value: item.id });
        });
        setOptionSer(listData);
      } catch (error) {
      } finally {
      }
    };

    useEffect(()=>{
      document.title = `PeopleSol - Workflow Details Form `;
      return () => {
        document.title = 'PeopleSol';
      };
    })

    useEffect(() => {
      getOptionSer()
    }, [])
  return (
    <div style={{ height: "fit-content" }}>
    <div >
      <LabelCustom labelName={"Name"} mandatory={true} />
      <InputCustomFormik
      
      maxLength={150}
        name="name"
        type={"text"}
        placeholder={"Enter Name"}
      />
    </div>
    
    <div>
      <LabelCustom labelName={"Description"}/>
      <TextAreaFormik
      
      maxLength={500}
        name={"description"}
        placeholder={" Enter Description "}
      />
    </div>  
    <div>
      <LabelCustom labelName={"Notification Flow"}  />
      <SelectForm
        name={"notificationId"}
        options={optionSer}
        placeholder={" Select Notification Flow "}
        values={
          data
            ? optionSer.filter(
                (options) => options?.value === data?.notificationId
              )
            : opt
        }
      />
    </div>
    </div>
  )
}
