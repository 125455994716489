import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const PayrollReports = () => {
  // Define your summary reports
  const navigate = useNavigate();
  const summaryReports = [
    // { id: 1, title: "Payroll Summary", link: "/payroll-summary" },
    { id: 2, title: "LOP Summary", link: "/lop-summary" },
    { id: 3, title: "EPF Summary", link: "/epf-summary" },
    { id: 4, title: "ESI Summary", link: "/esi-summary" },
    { id: 5, title: "PT Summary", link: "/pt-summary" },
    { id: 6, title: "LWF Summary", link: "/lwf-summary" },
    { id: 7, title: "IT Summary", link: "/it-summary" },
  ];
  const goToDetails = (link) => {
    navigate(`/payroll/reportskpi/details${link}`);
  };
  return (
    <div style={{ padding: "24px", width: "100%",background:'var(--form-background)' }}>
      <div
        className="tableHeaderContainer"
        style={{ boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.04)" }}
      >
        {" "}
        <h5>Reports</h5>
      </div>
      <ul className="summary-list">
        {summaryReports.map((report) => (
          <li key={report.id}>
            <div
              onClick={() => goToDetails(report.link)}
              className="tableHeaderContainer"
              style={{
                boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.04)",
                color: "var(--primary)",
                cursor: "pointer",
              }}
            >
              {" "}
              {report.title}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
