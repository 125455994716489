import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";
export const Requistion=({data})=>{
  useEffect(() => {
    document.title = `PeopleSol - Requisition`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
    return (
      <>
        <div>
          <LabelCustom labelName={"KEY"}  mandatory={true}/>
          <InputCustomFormik
            disabled={true}
            name="key"
            type={"text"}
         
          />
        </div>
        <div>
          <LabelCustom labelName={"Value"}  />
          <TextAreaFormik style={{height:"80px"}} type="textarea" name="value"
          
          maxLength={300}
          />
        </div>
  
      </>
    )
  }