/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateEarlyGoingComp } from "./FormComp/CreateEarlyGoingComp";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";

const CreateEarlyGoing = (props) => {
  const { state } = useLocation();

  const data = state?.id;

  const [detailsform, setDetailsform] = useState(null);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("EarlyGoingRequests/Create", values);
      if (result) {
        if (result.data.isSuccess === false) {
          dispatch(showToast({ text: result.data.message, severity: "error" }));
        } else {
          dispatch(
            showToast({ text: result.data.message, severity: "success" })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Early Going`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("EarlyGoingRequests/Edit", values);
      if (result.data.isSuccess === false) {
        dispatch(
          showToast({
            text: "Sorry, You are already created the request with the same date!",
            severity: "error",
          })
        );
      } else {
        if (result) {
          dispatch(
            showToast({ text: "Successfully Updated", severity: "success" })
          );
        }
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    }
  };

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`EarlyGoingRequests/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  // //console.log('detailsform',detailsform)

  const initialvalue = {
    date: "",
    time: "",
    reason: "",
  };

  const validationSchema = Yup.object({
    date: Yup.string().required("Please enter Date"),
    time: Yup.string().required("Please enter Time"),
    reason: Yup.string()
      
      .max(499, "Reason must be less than 500 characters")
      .required("Please enter the Reason"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
      date: dateFormat(values?.date, "yyyy-mm-dd"),
      time: dateFormat(values?.time, "h:MM TT"),
    };

    const create = {
      ...values,
      id: 0,
      date: dateFormat(values?.date, "yyyy-mm-dd"),
      time: dateFormat(values?.time, "h:MM TT"),
    };

    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  // // //console.log('time',detailsform?.data?.time)

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Update Early Going Request" : props.title}
      ButtonLabel={data ? "Update Early Going Request" : "Create Early Going Request"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        detailsform
          ? {
              ...initialvalue,

              date: detailsform?.data?.date
                ? new Date(detailsform?.data?.date)
                : "", // ? dateFormat(detailsform?.data?.date,"yyyy-mm-dd") : "",
              time: detailsform?.data?.time
                ? new Date(`12/12/12 ${detailsform?.data?.time}`)
                : "",
              reason: detailsform?.data?.reason,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateEarlyGoingComp />}
    />
  );
};

export { CreateEarlyGoing };
