/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import { currentMonth } from "../../../../Utilities/currentMonth";
import { CreatePayConfigComp } from "./CreatePayConfigComp";
import dateFormat from "dateformat";
const CreatePayConfig = () => {
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState();
  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState({});
  const getFormDetails = async () => {
    try {
      const result = await getRequest(`PayConfig/Details/${data}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Pay Config `;
    if(data)getFormDetails()
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
const initialValue={
  payAreaId:data ? detailsform?.payAreaId:'',
  calculateMonthlySalaryBasedOnValue:data ? detailsform?.calculateMonthlySalaryBasedOnValue:0,
  calculateMonthlySalaryBasedOn:data ? detailsform?.calculateMonthlySalaryBasedOn !=="Actual days in a month" ? "Organisation working days":detailsform?.calculateMonthlySalaryBasedOn :'',
  payYourEmployeesOnValue:data ? detailsform?.payYourEmployeesOnValue:0,
payYourEmployeesOn:data ? detailsform?.payYourEmployeesOn !=="The last working day of every month" ? "day":detailsform?.payYourEmployeesOn :'',
  firstPayrollFrom:data ? detailsform?.firstPayrollFrom:currentMonth(),
  paidOn:detailsform?.paidOn
  ? new Date(detailsform?.paidOn)
  : "",

}


  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
    await postRequest("PayConfig/Create", values);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      returnPage()
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const editPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
     await postRequest("PayConfig/Update", values);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      returnPage()
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Pay Config`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
  
  }, []);
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const submitEditCompany = (values) => {
const payload={
  ...values,
  WorkingDays:'',
  id:data? data :0,
  calculateMonthlySalaryBasedOnValue:values?.calculateMonthlySalaryBasedOn==="Actual days in a month"?0:values?.calculateMonthlySalaryBasedOnValue,
  payYourEmployeesOnValue:values?.payYourEmployeesOn==="The last working day of every month"?0:values?.payYourEmployeesOnValue,
  paidOn:dateFormat(values?.paidOn, "yyyy-mm-dd"),
}
if(data)editPageData(payload)
else createPageData(payload);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
    chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Update Pay Config" : "Create Pay Config"}
      ButtonLabel={data ? "Update Pay Config" : "Create Pay Config"}
      // validationSchema={{}}
      
      initialvalue={initialValue}
      onSubmit={submitEditCompany}
      returnPage={returnPage}
      propsDiv={<CreatePayConfigComp formValues={formValues} data={data}/>}
    />
  );
};

export { CreatePayConfig };
