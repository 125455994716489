import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { StepsHierarchy } from "../../../Components/FormComponent/StepsHierarchy";
import { WorkflowStepsComp } from "../../../Components/FormComponent/WorkflowStepsComp";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { ChromePicker } from "react-color";
import ColorDisplay from "../../../Components/ColorDisplay/ColorDisplay";
import { format } from "date-fns";
import { Loader } from "../../../Components/FormComponent/Loader";
import UploadImageDetail from "../../../Components/UploadDetail/UploadImageDetail";

const DetailsCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState([]);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `TrainingCategories/Details/${selectedId}`
      );
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);

  useEffect(() => {
    document.title = `PeopleSol - Category Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const signArray =
    eventtData?.trainingCategories?.length > 0 &&
    eventtData?.trainingCategories[0]?.sign?.split(",");
  const signatoryArray =
    eventtData?.trainingCategories?.length > 0 &&
    eventtData?.trainingCategories[0]?.signatory?.split(",");
  const roleArray = eventtData?.rolenames?.length > 0 && eventtData?.rolenames;

  // console.log("roleArray", roleArray);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Course Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            {eventtData?.trainingCategories?.map((item) => (
              <>
                <DetailsStep detailTitle={"Name"} Details={item?.name} />
                <DetailsStep
                  detailTitle={"Color"}
                  Details={<ColorDisplay color={item?.color} />}
                />
                <DetailsStep
                  detailTitle={"Is Active?"}
                  Details={item?.isActive === true ? "Yes" : "No"}
                />
                <DetailsStep
                  detailTitle={"Cover Image"}
                  Details={
                    <UploadImageDetail
                      filepath={`TrainingCategories/${item?.attachment}`}
                    />
                  }
                />

                <DetailsStep
                  detailTitle={"Is Display in candidate page "}
                  Details={
                    item?.isDisplayInCandidatePage === true ? "Yes" : "No"
                  }
                />
                <DetailsStep
                  detailTitle={"Is Certificate Needed "}
                  Details={item?.certificateNeeded === true ? "Yes" : "No"}
                />

                <DetailsStep
                  detailTitle={"Created On"}
                  Details={format(
                    new Date(item?.createdOn),
                    "dd/MM/yyyy hh:mm a"
                  )}
                />
                <DetailsStep
                  detailTitle={"Last Updated On"}
                  Details={format(
                    new Date(item?.lastUpdatedOn),
                    "dd/MM/yyyy hh:mm a"
                  )}
                />
                <div>
                  <h4 style={{ margin: "10px 0px" }}>Signature</h4>
                </div>
                <div style={{ width: "100%" }}>
                  <table className="tabless tabless-bordered">
                    <thead
                      style={{
                        backgroundColor: "var(--primary-hover)",
                      }}
                    >
                      <tr>
                        <td>S.no</td>
                        <td>Signatory</td>
                        <td>Signature</td>
                        <td>Role</td>
                      </tr>
                    </thead>
                    <tbody>
                      {signArray?.length > 0
                        ? signArray?.map((item, index) => {
                            return (
                              <tr className="trStyle">
                                <td>{index + 1}</td>
                                <td>{signatoryArray[index]}</td>
                                <td>
                                  <a
                                    href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/TrainingCategories/${item}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item}
                                  </a>
                                </td>
                                <td>{roleArray[index]}</td>
                              </tr>
                            );
                          })
                        : "No Data Found"}
                    </tbody>
                  </table>
                </div>
              </>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsCategory };
