import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LopReports } from "./LopReports";
import { EsiReports } from "./EsiReports";
import { EpfReports } from "./EpfReports";
import { PtReports } from "./PtReports";
import { LwfReports } from "./LwfReports";
import { ItReports } from "./ItReports";

export const DetailsReports = () => {
  const { type } = useParams(); // Get the 'type' parameter from the URL
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  console.log("type", getIdFromDynamicUrl(currentUrl));
  // Conditionally render components based on the 'type' parameter
  const renderSummaryComponent = () => {
    switch (getIdFromDynamicUrl(currentUrl)) {
      case "lop-summary":
        return <LopReports text={getIdFromDynamicUrl(currentUrl)} />;
      case "esi-summary":
        return <EsiReports text={getIdFromDynamicUrl(currentUrl)} />;
      case "epf-summary":
        return <EpfReports text={getIdFromDynamicUrl(currentUrl)} />;
      case "pt-summary":
        return <PtReports text={getIdFromDynamicUrl(currentUrl)} />;
      case "lwf-summary":
        return <LwfReports text={getIdFromDynamicUrl(currentUrl)} />;
      case "it-summary":
        return <ItReports text={getIdFromDynamicUrl(currentUrl)} />;
      // Add cases for other summary types if needed
      default:
        return <LopReports text={getIdFromDynamicUrl(currentUrl)}  />;
    }
  };

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>

      {/* <h6>{getIdFromDynamicUrl(currentUrl)?.toUpperCase()}</h6> */}
      {renderSummaryComponent()}
    </div>
  );
};

export default DetailsReports;
