import React, { useEffect } from "react";
import { useState } from "react";
import DropDownBlueIcon from "../../../../../Assets/DropDownBlueIcon.svg";
import { IconButton } from "@mui/material";

function KnowledgeCenterDropdown({
  item,
  index,
  arrLength,
  activeClickhandler,
  idActive,
}) {
  const [dropdownArrow, setDropdownArrow] = useState(false);
  useEffect(() => {
    if (idActive.toString().split(".")[0] === `${index + 1}`)
      setDropdownArrow(true);
  }, []);
  return (
    <div
      key={index}
      className={
        arrLength - 1 === index
          ? "knowledgeContentholderNewend"
          : "knowledgeContentholderNew"
      }
    >
      <div className="knowledgeTitleCss">
        <div>
          {index + 1}. {item?.title}
        </div>
        <div>
          <IconButton
            style={{
              width: "26px",
              height: "26px",
            }}
            onClick={() => setDropdownArrow((prev) => !prev)}
          >
            <img
              style={
                dropdownArrow
                  ? {
                      transform: "rotate(180deg)",
                    }
                  : {}
              }
              src={DropDownBlueIcon}
              alt={DropDownBlueIcon}
            />
          </IconButton>
        </div>
      </div>
      {dropdownArrow && (
        <div className="knowledgeSubTitleCover">
          {item?.subTitle?.map((SubItem, SubItemindex) => {
            return (
              <div
                key={SubItemindex}
                onClick={() =>
                  activeClickhandler(`${index + 1}.${SubItemindex + 1}`)
                }
                style={{ cursor: "pointer" }}
                className={
                  idActive.toString() === `${index + 1}.${SubItemindex + 1}`
                    ? "knowledgeSubTitleCssActive"
                    : "knowledgeSubTitleCss"
                }
              >
                {index + 1}.{SubItemindex + 1}. {SubItem?.subTitles}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default KnowledgeCenterDropdown;
