import React, { useState, useEffect, useDeferredValue, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { isLoading, showToast } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { IconButton, Tooltip } from "@mui/material";
import { Loader } from "../../../Components/FormComponent/Loader";
import { OrganisationSkillsParent } from "./OrganisationSkillsParent";
import backbuttonpic from "../../../Assets/backbuttonpic.svg";

const OrganisationSkillSet = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [allListsskill, setAllListsskill] = useState();
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    SkillsetId: location?.state?.state,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };
      const result = await postRequest(
        "SkillsEmployeeController/AllListsskill",
        downloadReqData
      );

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getAllListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "SkillsEmployeeController/AllListsskill",
        reqData
      );
      setAllListsskill(result?.data?.summary);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("skills/organisationskills"))
      getAllListData();
  }, [location.pathname, reqData]);

  const getFormDetails = (id, returnUrl) => {
    navigate(`/services/myskill/details/${id}`, { state: { returnUrl } });
  };

  return (
    <div className="moduleMainContainer">
      <div className="fullTableContainer">
        <div className="backButtonHolder">
          {" "}
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="button secondaryButton"
            style={{ margin: "0px", padding: "0px" }}
          >
            <img src={backbuttonpic} alt="Previous button" />
          </button>
        </div>

        <>
          <div className="tableHeaderContainerskillset">
            {!loading && (
              <div className="skillsetheading">
                <div className="skillsetheadingfont">
                  Top {allListsskill?.top5skilss?.length} Skills
                </div>
                <div className="skillsetheadingdiv">
                  {allListsskill &&
                    allListsskill?.top5skilss?.map((item, index) => (
                      <div className="skillsetheadingcontent">
                        {`${item?.skill}`}
                        {index === allListsskill?.top5skilss?.length - 1
                          ? "  "
                          : " | "}
                      </div>
                    ))}
                </div>
              </div>
            )}
            <div className="search-box">
              <input
                className="search-text"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchParam(e.target.value)}
              />
              <a className="search-btn">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                    fill="#1296B0"
                  />
                </svg>
              </a>
            </div>

            <Tooltip arrow title="Download" placement="top">
              <IconButton color="primary" onClick={exportTable}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                    stroke="#1296B0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </div>
          {loading ? (
            <div className="noDataFound">
              <Loader />
            </div>
          ) : (
            <>
              <OrganisationSkillsParent
                Heading={"Skill Name"}
                allskillAllListItem={allListsskill}
              />
            </>
          )}
        </>
      </div>
    </div>
  );
};
export { OrganisationSkillSet };
