import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateCategoriesComp } from "../FormComp/CreateCategoriesComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateCategories = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Create Categories`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("HouseKeepingsCategory/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("HouseKeepingsCategory/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getDetails = async () => {
    try {
      const result = await getRequest(`HouseKeepingsCategory/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);

  const initialvalue = {
    name: data ? detail?.name : "",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, "Name must be less than 150 characters")
      .required("Please Enter Category Name "),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data ? detail.id : "",
    };

    const create = {
      ...values,
      id: 0,

      isDataActive: true,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={
        data ? "Edit House Keeping Category" : "Create  House Keeping Category"
      }
      ButtonLabel={
        data ? "Update House Keeping Category" : "Create House Keeping Category"
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateCategoriesComp />}
    />
  );
};

export { CreateCategories };
