import React from "react";
import "./KnowledgeCenter.css";
import { LoginPeopleSol, LoginPeopleSolComp } from "./LoginPeopleSolComp";
import { ForgotPasswordComp } from "./ForgotPasswordComp";
import { WhatIsRequisitionComp } from "./WhatIsRequisitionComp.jsx";
import { RequisitionListComp } from "./RequisitionListComp";
import { HowToCreateComp } from "./HowToCreateComp";
import { useState } from "react";
import { useEffect } from "react";
import pic1 from "../../../../../Assets/pic1.png";
import pic2 from "../../../../../Assets/pic2.png";
import pic3 from "../../../../../Assets/pic3.png";
import ForgotPassword from "../../../../../Assets/ForgotPassword.jpg";
import Login from "../../../../../Assets/Login.jpg";
import HRMS1 from "../../../../../Assets/HRMS 1.jpg";
import HRMS2 from "../../../../../Assets/HRMS 2.jpg";
import Home from "../../../../../Assets/Home.jpg";
import Travel1 from "../../../../../Assets/Travel1.jpg";
import Travel2 from "../../../../../Assets/Travel2.jpg";

import KnowledgeCenterDropdown from "./KnowledgeCenterDropdown.jsx";
import BodyForKnowledgeCenter from "./BodyForKnowledgeCenter.jsx";

const KnowledgeCenter = () => {
  const [idActive, setIdActive] = useState(1.1);

  const dataArray = [
    {
      title: "PeopleSol Login",
      subTitle: [
        { subTitles: "Login to PeopleSol", pics: [Login] },
        { subTitles: "Forgot Password", pics: [ForgotPassword] },
      ],
    },
    // {
    //   title: "Man Power Requisition",
    //   subTitle: [
    //     { subTitles: "a", pics: [pic1, pic1, pic1] },
    //     { subTitles: "b", pics: [pic2, pic2, pic2] },
    //   ],
    // },
    {
      title: "PeopleSol Home Page",
      subTitle: [{ subTitles: "Home", pics: [Home] }],
    },
    {
      title: "PeopleSol HRMS",
      subTitle: [
        { subTitles: "HRMS", pics: [HRMS1, HRMS2] },
        {
          subTitles: "HRMS - Onboarding (Requisition) ",
          pics: [pic1, pic2, pic3],
        },
      ],
    },
    {
      title: "PeopleSol Travel",
      subTitle: [{ subTitles: "Travel", pics: [Travel1, Travel2] }],
    },
  ];

  const activeClickhandler = (id) => {
    setIdActive(id);
    // setTextActive(text);
  };
  useEffect(() => {
    document.title = `PeopleSol - Knowledge Center`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="container candidateContainer knowledgeBackground">
      <div className="knowledgeMainContainer">
        <div className="knowledgeLeft">
          <div className="knowledgeBottom" style={{ height: "41vw" }}>
            {dataArray?.map((item, index) => (
              <KnowledgeCenterDropdown
                idActive={idActive}
                activeClickhandler={activeClickhandler}
                key={index}
                index={index}
                item={item}
                arrLength={dataArray?.length}
              />
            ))}
          </div>
        </div>
        <div className="knowledgeRight">
          <BodyForKnowledgeCenter idActive={idActive} picArray={dataArray} />
          {/* {textActive === 3 && <LoginPeopleSolComp />}
          {textActive === 4 && <ForgotPasswordComp />}

          {textActive === 6 && <RequisitionListComp />}
          {textActive === 7 && <HowToCreateComp />} */}
        </div>
      </div>
    </div>
  );
};

export { KnowledgeCenter };
