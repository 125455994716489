import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { ServiceMySkillCreateComp } from "./FormComp/ServiceMySkillCreateComp";
import { ServiceMyCompetencyCreateComp } from "./FormComp/ServiceMyCompetencyCreateComp";
 
const ServiceMyCompetencyCreate = (props) => {
  const { state } = useLocation();
 
  const [detailsform, setDetailsform] = useState({});
  const [formValues, setformValues] = useState();
 
  const data = state?.id;
 
  const dispatch = useDispatch();
 
  const [loading, setLoading] = useState(false);
 
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `CompetencyEmployeeController/Details?ID=${data}`
      );
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
 
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  // // //console.log("Banners id",data)
  // // //console.log("Banners Details",detailsform)
 
  const createPageData = async (formData) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "CompetencyEmployeeController/Create",
        formData
      );
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
      // // //console.log(result)
    } catch (error) {
      // // //console.log(error)
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "CompetencyEmployeeController/Edit",
        values
      );
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
      returnPage();
      // // //console.log(result)
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const initialValue = {
    CompetencySetId: data ? detailsform?.requests?.competencySetId : "",
    ProficiencyLevel: data ? detailsform?.requests?.proficiencyLevel : "",
    CompetencyId: data ? detailsform?.requests?.competencyId : "",
    upload: data ? detailsform?.requests?.attachment : "",
    Description: data ? detailsform?.requests?.description : "",
  };
 
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
 
  const validationSchema = Yup.object({
    CompetencyId: Yup.string().required("Please Select Competency"),
    CompetencySetId: Yup.string().required("Please Select Competency Set"),
    ProficiencyLevel: Yup.string().required("Please Select Proficiency Level"),
    upload: Yup.mixed()
      // .nullable()
      .test(
        "Fichier taille",
        "File Size should be less than 1 mb",
        (value) => !value || (value && value.size <= 2048 * 2048)
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .required("Please upload"),
  });
 
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("CompetencySetId", values?.CompetencySetId);
    formData.append("CompetencyId", values?.CompetencyId);
    formData.append("ProficiencyLevel", values?.ProficiencyLevel);
    formData.append("upload", values?.upload);
    formData.append("Description", values?.Description);
    // formData.append("Image", values?.Upload.name);
    // formData.append("CoverImage", values?.upload !== "" ?  values?.upload?.name : detailsform?.coverImage?.toString());
    // formData.append("isActive",true);
    // // //console.log('formData',formData)
    data ? Edit(formData) : createPageData(formData);
  };
 
  const navigate = useNavigate();
 
  const returnPage = () => {
    navigate(-1);
  };
  const chooseFormValues = (values) => {
    setformValues(values);
  };
 
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Competency" : "Create Competency"}
      ButtonLabel={data ? "Edit Competency" : "Create Competency"}
      onSubmit={onSubmit}
      chooseFormValues={chooseFormValues}
      validationSchema={validationSchema}
      initialvalue={initialValue}
      returnPage={returnPage}
      propsDiv={
        <ServiceMyCompetencyCreateComp
          formValues={formValues}
          data={detailsform}
        />
      }
    />
  );
};
 
export { ServiceMyCompetencyCreate };