import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";

export const CreateEmailTemplateComp = () => {
  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Module"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="module"
          type={"text"}
          placeholder={"Enter Module"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Event"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="event"
          type={"text"}
          placeholder={"Enter Event"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Code"} mandatory={true} />
        <InputCustomFormik
          name="code"
          type={"text"}
          placeholder={"Enter Code"}
          maxLength={6}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} mandatory={true} />
        <TextAreaFormik
          maxLength={2000}
          name="content"
          placeholder={"Enter Description"}
        />
      </div>
    </div>
  );
};
