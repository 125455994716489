import { Form, Formik } from "formik";
import React from "react";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { useDispatch } from "react-redux";
import { useState } from "react";
import * as Yup from "yup";
import { showToast } from "../../../../../Features";
import { postRequestForm } from "../../../../../Services/axios";
import DatePicker from "react-datepicker";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { useEffect } from "react";
const option = [
  { label: "Implementing in Test", value: "Implementing in Test" },
  { label: "Implementing in production", value: "Implementing in production" },
  { label: "Change successful", value: "Change successful" },
  { label: "Roll back successful", value: "Roll back successful" },
  { label: "Roll back not successful", value: "Roll back not successful" },
  { label: "Deferred", value: "Deferred" },
];

const ITImplementation = ({ data, getDetails }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState(
    data?.itsmChangeRequest?.timeline != null
      ? data?.itsmChangeRequest?.timeline
        .split(/\s*\-\s*/g)
        ?.map((item) => new Date(item))
      : [new Date(), new Date()]
  );
  const [startDate, endDate] = dateRange;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/doc",
  ];
  useEffect(() => {
    document.title = `PeopleSol - IT Implementation`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const validationSchema = Yup.object({
    AdditionalComments: Yup.string()
      .max(499, "Description cannot be more than 500 characters")
      .required("Please Please Enter Description"),
    ImplementerRemarks: Yup.string().when("ImplementerStatus", {
      is: (val) => val === "Roll back successful",
      then: () =>
        Yup.string()

          .max(499, "Description cannot be more than 500 characters")
          .required("Please Please Enter Description")
          .nullable(),
    }),
    ChangeImplementor: Yup.mixed()
      .nullable()
      .notRequired()
      .test(
        "Fichier taille",
        "File Size should be less than 1 mb",
        (value) => {
          return (
            !value ||
            (value && value.size <= 2048 * 2048) ||
            typeof value !== "object"
          );
        }
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          (value && SUPPORTED_FORMATS.includes(value.type)) ||
          typeof value !== "object"
      ),
  });

  const onSubmit = async (values, val) => {
    const formData = new FormData();
    formData.append(
      "Timeline",
      dateRange.length > 0
        ? `${format(dateRange[0], "MM/dd/yyyy")} - ${format(
          dateRange[1],
          "MM/dd/yyyy"
        )}`
        : []
    );
    formData.append("AdditionalComments", values?.AdditionalComments);
    if (typeof values?.ChangeImplementor == "object") {
      formData.append("ChangeImplementor", values?.ChangeImplementor);
      formData.append("multipleimage1", values?.ChangeImplementor?.name);
    }
    formData.append("ImplementerStatus", values?.ImplementerStatus);
    formData.append("ImplementerRemarks", values?.ImplementerRemarks);
    formData.append("priority", data?.itsmChangeRequest?.priority);
    formData.append("ChangeType", data?.itsmChangeRequest?.changeType);
    formData.append(
      "DescriptionOfchange",
      data?.itsmChangeRequest?.descriptionOfchange
    );
    formData.append("Reason", data?.itsmChangeRequest?.reason);
    formData.append("id", data?.itsmChangeRequest?.id);
    postFormData(formData);
  };
  const postFormData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm(
        "ITsmChangeRequest/ChangeImplementor",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
    } finally {
      getDetails();
      setLoading(false);
    } 
    
  };
  if (loading) return  <Loader />;
  return (
    <Formik
      enableReinitialize
      initialValues={{
        AdditionalComments:
          data?.itsmChangeRequest?.additionalComments != null
            ? data?.itsmChangeRequest?.additionalComments
            : "",
        ChangeImplementor:
          data?.itsmChangeRequest?.implemeterTimelineUpload != null
            ? `ChangeRequest/${data?.itsmChangeRequest?.implemeterTimelineUpload}`
            : "",
        ImplementerStatus:
          data?.itsmChangeRequest?.implementerStatus != null
            ? data?.itsmChangeRequest?.implementerStatus
            : "",
        ImplementerRemarks:
          data?.itsmChangeRequest?.implementerRemarks != null
            ? data?.itsmChangeRequest?.implementerRemarks
            : "",
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, resetForm }) => {
        return (
          <div style={{ backgroundColor: 'var(--form-background)' }}>
            <Form>
              <div className=" formcustom" >
                {data?.itsmChangeRequest?.status === "Rolled back" ? (
                  ""
                ) : (
                  <>
                    <div>
                      <LabelCustom labelName={"Implementation Timeline"} mandatory={true} />
                      <DatePicker
                        minDate={new Date()}
                        dateFormat={"dd/MM/yyyy"}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(date) => setDateRange(date)}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"Implementation Notes"} mandatory={true} />
                      <TextAreaFormik
                        maxLength={500}
                        style={{ height: "80px" }}
                        name="AdditionalComments"
                        type="number"
                        step="0.01"
                      />
                    </div>
                    <div>
                      <LabelCustom labelName={"Implementation Support Document"} />
                      <FileUploadFormik name="ChangeImplementor" />
                      <div
                        style={{
                          color: "var(--primary)",
                          fontSize: "14px",
                          marginLeft: "13px",
                        }}
                      >
                        Supporting document must be in pdf, jpeg , png format and within 2mb
                      </div>
                    </div>
                  </>
                )}

                {(data?.itsmChangeRequest?.status === "Work In Progress" ||
                  data?.itsmChangeRequest?.status === "Rolled back") && (
                    <div>
                      <LabelCustom labelName={"Request Status"} />
                      <SelectForm
                        name="ImplementerStatus"
                        placeholder="please select status"
                        options={option}
                      />
                    </div>
                  )}
                {values?.ImplementerStatus === "Roll back successful" && (
                  <div>
                    <LabelCustom
                      labelName={"Reason for Roll back"}
                      mandatory={true}
                    />
                    <TextAreaFormik
                      maxLength={500}
                      style={{ height: "80px" }}
                      name="ImplementerRemarks"
                      type="number"
                      step="0.01"
                      placeholder="Please Enter Reason"
                    />
                  </div>
                )}
              </div>
              <div className="createFootCust" style={{ paddingRight: '24px' }}>
                <div>
                  <button
                    className="deletebtn"
                    type="button"
                    onClick={() => resetForm({ values: "" })}
                  >
                    {"Reset"}
                  </button>
                </div>
                <div>
                  <button className="button primaryButton" type="submit">
                    {"Submit"}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export { ITImplementation };
