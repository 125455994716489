import React, { useCallback, useEffect } from 'react'
import "./GroupInfo.css";
import { confirmAlert } from 'react-confirm-alert';
import { Switch } from '@mui/material';
import { ProfileGroup } from '../MyProfileDetails/ProfileGroup';

function GroupInfo(props) {


      useEffect(()=>{
        document.title = `PeopleSol - Group Info`;
        return () => {
          document.title = 'PeopleSol';
        };
      })
    

      const groupInfoHandler = useCallback((e) => {
        let moreActivity = document.querySelector(".groupInfoMainContainer");
        if (moreActivity && !moreActivity?.contains(e.target)) {
          props.setGroupInfo(false);
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("mouseup", groupInfoHandler);
        return () => {
          document.removeEventListener("mouseup", groupInfoHandler);
        };
      }, [groupInfoHandler]);

      return (
        <>
          {/* {!activeAttachment && */}
          <div className="groupInfoMainContainer">
            <div className="groupInfoHeaderContainer">
                <div className="groupInfoHeaderWrapper">
                <span>{"Jenny James Info"}</span>
                <svg onClick={() => {
                    props.setGroupInfo(false);
                    }}
                xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 0.707104 -0.70711 -0.707104 20.4531 6.48962)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round"/>
                <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 22.0259 20.6318)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
                </div>
            </div>
            <div className="groupInfoDetailsMainWrapper">
                <div className="groupInfoImageContainer">
                <div className="groupInfoImageWrapper">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="155"
                    height="154"
                    viewBox="0 0 155 154"
                    fill="none"
                    >
                    <g opacity="0.4">
                        <circle
                        cx="77.1895"
                        cy="77.0684"
                        r="76.9316"
                        transform="rotate(-90 77.1895 77.0684)"
                        fill="url(#paint0_radial_60_9411)"
                        />
                    </g>
                    <g opacity="0.4">
                        <circle
                        cx="77.2061"
                        cy="77.0963"
                        r="63.3691"
                        transform="rotate(90 77.2061 77.0963)"
                        fill="url(#paint1_radial_60_9411)"
                        />
                    </g>
                    <g opacity="0.4">
                        <circle
                        cx="77.2051"
                        cy="77.0969"
                        r="46.7725"
                        transform="rotate(90 77.2051 77.0969)"
                        fill="url(#paint2_radial_60_9411)"
                        />
                    </g>
                    <defs>
                        <radialGradient
                        id="paint0_radial_60_9411"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(73.4253 78.6441) rotate(102.659) scale(101.154)"
                        >
                        <stop
                            offset="0.568025"
                            stop-color="white"
                            stop-opacity="0.2"
                        />
                        <stop offset="1" stop-color="#13A9C7" />
                        </radialGradient>
                        <radialGradient
                        id="paint1_radial_60_9411"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(74.1055 78.3943) rotate(102.659) scale(83.3209)"
                        >
                        <stop
                            offset="0.568025"
                            stop-color="white"
                            stop-opacity="0.2"
                        />
                        <stop offset="1" stop-color="#13A9C7" />
                        </radialGradient>
                        <radialGradient
                        id="paint2_radial_60_9411"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(74.9166 78.0549) rotate(102.659) scale(61.4988)"
                        >
                        <stop
                            offset="0.531188"
                            stop-color="white"
                            stop-opacity="0.2"
                        />
                        <stop offset="1" stop-color="#13A9C7" />
                        </radialGradient>
                    </defs>
                    </svg>
                    <div className="groupInfoProfileImage"></div>
                    <div className="groupInfoCameraIcon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30" fill="none">
                        <circle cx="14.4892" cy="14.8879" r="14.2138" fill="#1296B0"/>
                        <circle cx="17.842" cy="13.3865" r="0.412346" fill="white"/>
                        <circle cx="14.4905" cy="16.3486" r="2.99882" stroke="white" stroke-width="1.11962"/>
                        <path d="M10.0234 10.4073V10.9671H10.5832V10.4073H10.0234ZM18.9549 10.4073H18.3951V10.9671H18.9549V10.4073ZM10.5832 10.1871C10.5832 9.39489 11.2254 8.75265 12.0177 8.75265V7.63303C10.6071 7.63303 9.46357 8.77654 9.46357 10.1871H10.5832ZM10.5832 10.4073V10.1871H9.46357V10.4073H10.5832ZM9.24383 10.9671H10.0234V9.84746H9.24383V10.9671ZM7.31079 12.9001C7.31079 11.8325 8.17624 10.9671 9.24383 10.9671V9.84746C7.55789 9.84746 6.19117 11.2142 6.19117 12.9001H7.31079ZM7.31079 19.0909V12.9001H6.19117V19.0909H7.31079ZM9.24383 21.0239C8.17624 21.0239 7.31079 20.1585 7.31079 19.0909H6.19117C6.19117 20.7768 7.55789 22.1435 9.24383 22.1435V21.0239ZM19.7345 21.0239H9.24383V22.1435H19.7345V21.0239ZM21.6675 19.0909C21.6675 20.1585 20.8021 21.0239 19.7345 21.0239V22.1435C21.4204 22.1435 22.7871 20.7768 22.7871 19.0909H21.6675ZM21.6675 12.9001V19.0909H22.7871V12.9001H21.6675ZM19.7345 10.9671C20.8021 10.9671 21.6675 11.8325 21.6675 12.9001H22.7871C22.7871 11.2142 21.4204 9.84746 19.7345 9.84746V10.9671ZM18.9549 10.9671H19.7345V9.84746H18.9549V10.9671ZM18.3951 10.1871V10.4073H19.5147V10.1871H18.3951ZM16.9606 8.75265C17.7528 8.75265 18.3951 9.39489 18.3951 10.1871H19.5147C19.5147 8.77654 18.3712 7.63303 16.9606 7.63303V8.75265ZM12.0177 8.75265H16.9606V7.63303H12.0177V8.75265Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className="imgText ">
                    <h4>AESS Frontend... <span><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                    <line x1="7.0243" y1="12.1146" x2="12.0204" y2="12.1146" stroke="#1296B0" stroke-width="1.00856" stroke-linecap="round"/>
                    <path d="M6.93619 3.13129L0.572876 9.45211V11.9761H3.13693L9.4284 5.64444M6.93619 3.13129L8.56652 1.51185C9.25534 0.82763 10.3679 0.829495 11.0544 1.51602V1.51602C11.741 2.20263 11.7428 3.3153 11.0583 4.0041L9.4284 5.64444M6.93619 3.13129L9.4284 5.64444" stroke="#1296B0" stroke-width="1.00856"/>
                    </svg></span></h4>
                    <hr className='groupInfoHrLine'></hr>
                    <p>{3} Members</p>
                </div>
                </div>
                <div className="groupInfoAttachContainer">
                    <div className="groupInfoAttachWrapper">
                        <div className="media" onClick={()=>{props.setActiveAttachment(true); props.setGroupInfo(false);}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
                            <path d="M9.56459 7.79475C10.0411 7.79475 10.4275 8.18108 10.4275 8.65764C10.4275 9.1342 10.0411 9.52052 9.56459 9.52052C9.08803 9.52052 8.70171 9.1342 8.70171 8.65764C8.70171 8.18108 9.08803 7.79475 9.56459 7.79475Z" stroke="#DE64A1" stroke-width="0.920806"/>
                            <rect x="1.40181" y="4.9059" width="13.8079" height="10.8659" rx="0.897738" stroke="#DE64A1" stroke-width="0.920806"/>
                            <path d="M3.28731 4.98451L3.59287 2.56491C3.68637 1.82457 4.35965 1.29833 5.10064 1.38643L17.0389 2.80569C17.7837 2.89424 18.3158 3.56983 18.2272 4.31467L17.151 13.3676C17.0646 14.0943 16.418 14.6218 15.6888 14.5607L15.4222 14.5383" stroke="#DE64A1" stroke-width="0.920806"/>
                            <path d="M15.3327 10.3379L10.429 13.0134C10.0076 13.1604 9.32388 13.528 8.50064 12.7753C7.4716 11.8345 6.91293 10.4849 5.63021 10.4849C4.66689 10.4849 1.5303 12.7753 1.3833 12.7753" stroke="#DE64A1" stroke-width="0.920806"/>
                            </svg>
                            <span>Media</span>
                        </div>
                        <div className="links" onClick={()=>{props.setActiveAttachment(true); props.setGroupInfo(false);}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                            <path d="M2.10165 7.72816C1.48184 6.98633 1.14223 6.0504 1.14209 5.08372C1.14209 2.80194 3.00831 0.928162 5.29764 0.928162H9.07542C11.3572 0.928162 13.231 2.80194 13.231 5.08372C13.231 7.3655 11.3648 9.23927 9.07542 9.23927H7.18653" stroke="#1296B0" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.2934 7.72815C15.9132 8.46998 16.2528 9.40592 16.2529 10.3726C16.2529 12.6544 14.3867 14.5282 12.0974 14.5282H8.31962C6.03784 14.5282 4.16406 12.6544 4.16406 10.3726C4.16406 8.09082 6.03028 6.21704 8.31962 6.21704H10.2085" stroke="#1296B0" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>Links</span>
                        </div>
                        <div className="docs" onClick={()=>{props.setActiveAttachment(true); props.setGroupInfo(false);}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path d="M15.7078 6.39963V10.0121C15.7078 13.6246 14.2628 15.0696 10.6503 15.0696H6.31531C2.70281 15.0696 1.25781 13.6246 1.25781 10.0121V5.67713C1.25781 2.06463 2.70281 0.619629 6.31531 0.619629H9.92781" stroke="#8A88ED" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M4.87012 8.56713H9.20512M4.87012 11.4571H7.76012M15.7076 6.39963H12.8176C10.6501 6.39963 9.92762 5.67713 9.92762 3.50963V0.619629L15.7076 6.39963Z" stroke="#8A88ED" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span>Docs</span>
                        </div>
                    </div>
                    <div className="groupInfoMuteNotifications">
                        <div className="busyStatus">
                            <div className="busyStatusDot"></div>
                            <span>Mute Notifications</span>
                        </div>
                        <div className="changeStatus">
                            <Switch size="small" className="customMUISwitch" />
                        </div>
                    </div>
                </div>
                <div className="groupInfoMemberContainer">
                <div className="groupInfoMemberWrapper">
                    <div className="creteNewGroup">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                    <path d="M10.775 11.8865C13.8167 11.8865 16.3781 9.36185 16.3781 6.36388C16.3781 3.36591 13.8167 0.841309 10.775 0.841309C7.73335 0.841309 5.17194 3.36591 5.17194 6.36388C5.17194 9.51964 7.73335 11.8865 10.775 11.8865Z" stroke="#1296B0" stroke-width="1.5"/>
                    <path d="M1.49054 21.8487C1.49054 16.7995 5.65282 11.8873 10.7756 11.8873C12.9707 11.8873 14.9893 12.7892 16.5798 14.2172" stroke="#1296B0" stroke-width="1.5" stroke-linecap="round"/>
                    <line x1="20.5407" y1="14.0549" x2="20.5407" y2="22.797" stroke="#F7941D" stroke-width="1.5" stroke-linecap="round"/>
                    <line x1="24.7928" y1="18.6798" x2="15.9014" y2="18.6798" stroke="#F7941D" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    <p>Add Participants</p>
                    </div>
                    <span>{3} Members in this Group</span>
                    <ProfileGroup/>
                </div>
                </div>
                <div className="groupInfoExitContainer">
                <div className="groupInfoExitWrapper">
                <div className="groupInfoExitImg">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                <path d="M0.657593 9.67413V3.31848C0.657593 2.1193 1.73685 1.04004 2.93603 1.04004H21.8831C23.0822 1.04004 24.1615 2.1193 24.1615 3.31848V22.8651C24.1615 24.0643 23.0822 25.1435 21.8831 25.1435H2.93603C1.73685 25.1435 0.657593 24.0643 0.657593 22.8651V16.3895" stroke="#F71D1D" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M0.657593 13.032H17.566" stroke="#F71D1D" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.249 8.59473L17.9258 13.0317L13.249 17.3487L17.4461 13.5114L17.9258 13.0317L17.4461 12.552L13.249 8.59473Z" fill="black"/>
                <path d="M17.9258 13.0317L13.249 8.59473L17.4461 12.552L17.9258 13.0317ZM17.9258 13.0317L13.249 17.3487L17.4461 13.5114L17.9258 13.0317Z" stroke="#F71D1D"/>
                </svg>
                </div>
                <div className="groupInfoExitText"
                onClick={() => {
                    confirmAlert({
                    title: "Exit Group?",
                    message: "Only group admin will be notified that you left the group.",
                    buttons: [
                        {
                        label: "Cancel",
                        onClick: () => {},
                        },
                        {
                        label: "Exit",
                        onClick: () => {},
                        },
                    ],
                    overlayClassName: "customConfirmAlertContainer",
                    });
                }}
                >Exit Group</div>
                </div>
                </div>
            </div>
          </div>
          {/* } */}
          
        </>
        
      );
}

export default GroupInfo;