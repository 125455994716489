import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";

export const ViewDue = ({ id }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(true);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Resignations/DueClearanceForm?ID=${id}`);
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - View Due`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);
  if (loading) return <Loader />;
  return (
    <div className="detailsEReq" style={{ flexDirection: "column" }}>
      <div className="detailInner">
        <table
          className="tabless tabless-bordered tabl"
          style={{ width: "100%" }}
        >
          <tr>
            <th>Name</th>
            <td>{data?.employees?.name}</td>
            <th>Employee ID</th>
            <td>{data?.employees?.personNumber}</td>
          </tr>

          <tr>
            <th>Role</th>
            <td>{data?.employees?.accessLevelName}</td>
            <th>Designation</th>
            <td>{data?.employees?.designation}</td>
          </tr>
          <tr>
            <th>Department</th>
            <td>{data?.employees?.department}</td>
            <th>Sub Department</th>

            <td>{data?.employees?.subDepartment}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>{data?.employees?.locationName}</td>
            <th>Official. Mail ID</th>

            <td>{data?.employees?.email}</td>
          </tr>
          <tr>
            <th>Date of Joining</th>
            <td>{format(new Date(data?.employees?.doj), "dd/MM/yyyy ")}</td>
            <th>Resignation Date</th>
            <td>
              {data?.resignations?.createdOn
                ? format(new Date(data?.resignations?.createdOn), "dd/MM/yyyy ")
                : ""}
            </td>
          </tr>
          <tr>
            <th>Relieving date</th>
            <td>
              {data?.resignations?.dateOfRelieving
                ? format(
                    new Date(data?.resignations?.dateOfRelieving),
                    "dd/MM/yyyy "
                  )
                : ""}
            </td>
            <th></th>
            <td></td>
          </tr>
        </table>
      </div>
      <div className="headingForm">Due Clearance Status</div>
      <div className="detailInner" style={{ gap: "0px" }}>
        {data?.noDueClearanceForms?.length > 0 ? (
          <>
            <table
              className="tabless tabless-bordered"
              style={{ width: "100%" }}
            >
              <thead style={{ backgroundColor: "var(--primary-hover)" }}>
                <tr>
                  <th width="3%">Department</th>
                  <th width="3%">Clearance From</th>
                  <th width="3%">Required By</th>
                  <th width="3%">Payable Amount</th>
                  <th width="3%">Recover Amount</th>
                  <th width="3%">Status</th>
                  <th width="3%">Remarks</th>
                </tr>
              </thead>
              <tbody>
                {data?.noDueClearanceForms?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.department?.name}</td>
                      <td>{item?.employee?.nameWithPersonNumber}</td>
                      <td>{format(new Date(item?.date), "dd/MM/yyyy")}</td>
                      <td>{item?.payableAmount}</td>
                      <td>{item?.recoverAmount}</td>
                      <td>{item?.status}</td>
                      <td>{item?.remarks}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table
              className="tabless tabless-bordered tabl"
              style={{ width: "100%" }}
            >
              <tr>
                <th>Total Payable Amount</th>
                <td>
                  {data?.noDueClearanceForms?.reduce(function (acc, obj) {
                    return acc + obj?.payableAmount;
                  }, 0)}
                </td>
                <th>Total Recovery</th>
                <td>
                  {data?.noDueClearanceForms?.reduce(function (acc, obj) {
                    return acc + obj?.recoverAmount;
                  }, 0)}
                </td>
              </tr>
            </table>
          </>
        ) : (
          <div className="noDatastyle">
            <img
              src={Nodata}
              alt="No data"
              style={{ height: "150px", width: "250px" }}
            />
            <h4>{"Due Clearance Not Initiated"}</h4>
          </div>
        )}
      </div>
    </div>
  );
};
