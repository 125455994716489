import React from "react";
// import CrownIcon from "./crown-icon.png"; // Replace with your own crown icon image
import ChatPremium from "../../Assets/ChatPremium.png";
import office from "../../Assets/office.png";
import payroll from "../../Assets/payroll.png";
import ld from "../../Assets/ld.png";
import "./PremiumContentPageSocial.css"; 
import { useLocation } from "react-router-dom";
const PremiumContentPageChat = () => {
  const location = useLocation();
  const currentpath = location.pathname.toLowerCase();
  const getHeading =()=>{
    if(currentpath.includes("chat")){
      return "Chat"
    }
    else if(currentpath.includes("payroll")){
      return "Payroll"
    }
    else if(currentpath.includes("ld")){
      return "L & D"
    }
    else if(currentpath.includes("office")){
      return "office"
    }
  }
  const getImage =()=>{
    if(currentpath.includes("chat")){
      return ChatPremium
    }
    else if(currentpath.includes("payroll")){
      return payroll
    }
    else if(currentpath.includes("ld")){
      return ld
    }
    else if(currentpath.includes("office")){
      return office
    }
    else return 'Img'
  }
  const bodyData =()=>{
    if(currentpath.includes("chat")){
      return <p> Make your workplace thrive with PeopleSol's Chat Module.  Boost workplace communication with real-time messaging, group chats, and seamless integration. Express yourself with emojis and GIFs, stay updated with notifications, and easily find past conversations. Your data's security is a priority. Enhance collaboration and engagement, creating a more connected, efficient, and motivated workforce. </p>
    }
    else if(currentpath.includes("payroll")){
      return <p>Payroll is a critical function that ensures timely and accurate salary payments to employees.The PeopleSol payroll module automates the process, eliminates errors, reduces manual effort, and increases compliance. It also enables easy access to payment and tax-related data and offers multiple payment options to employers.</p>
    }
    else if(currentpath.includes("ld")){
      return <p>PeopleSol HRMS L&D is a comprehensive platform that helps organizations manage their employee training and development programs. It provides tools for planning, delivering, and tracking training activities, as well as data analytics and reporting features for performance analysis.</p>
    }
    else if(currentpath.includes("office")){
      return <p>PeopleSol effortlessly manages your office and building operations with integrated function modules. Automate administrative tasks, building maintenance, security, and more for enhanced efficiency and productivity.</p>
    }
  }
  return (
    <div
      className="homeContainer"
      style={{ flexDirection: "row", justifyContent: "space-between" }}
    >
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          paddingLeft: "66px",
          gap:'30px',
          marginBottom:'52px'
        }}
      >
        <h5 className="headPre" style={{color:'var(--primary)'}}>{getHeading()}</h5>
        <p className="prPas">
          {bodyData()}
        </p>
        <a
          href={`https://aesssolutions.com/pricing.html`}
          target="_blank"
          className="upgrade-linkSocial"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
          >
            <path
              d="M4.40471 18.9803L4.21373 18.0363H20.3956L20.2029 18.9803H4.40471Z"
              fill="white"
              stroke="white"
              stroke-width="0.904597"
            />
            <path
              d="M6.66202 9.88162L7.01888 9.22728L6.66202 9.88162C7.18552 10.1671 7.84109 9.98675 8.14485 9.47363L12.3165 2.42701L16.3082 9.45469C16.6056 9.97833 17.267 10.1682 17.7969 9.8821L22.732 7.2174L20.6144 15.2093H4.03654L1.8205 7.24121L6.66202 9.88162Z"
              fill="white"
              stroke="white"
              stroke-width="1.49065"
            />
          </svg>
          <div className="horizontalLine" style={{width:'1px',height:'28px'}}></div>
          <span>Upgrade to Premium</span>
        </a>
      </div>
      <div>
        <img style={{width:'900px'}} src={getImage()} alt="img" />
      </div>
    </div>
  );
};

export default PremiumContentPageChat;
