import React from 'react'
import { useEffect } from 'react';

const ServiceLearning = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Learning Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      
        
    </div>
  )
}

export {ServiceLearning};
