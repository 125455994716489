import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import purplearrow from "../../../Assets/purplearrow.svg";

import LearningSummaryIcon from "../../../Assets/LearningSummaryIcon.svg";
import LearningProgressIcon from "../../../Assets/LearningProgressIcon.svg";
import LearningCategoryIcon from "../../../Assets/LearningCategoryIcon.svg";
import { Completed } from "./Completed";
import { LearnerCardComponent } from "./LearnerCardComponent";
import { InProgress } from "./InProgress";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { Loader } from "../../../Components/FormComponent/Loader";
import Nodata from "../../../Assets/Nodata.svg";
import { useSelector } from "react-redux";

const TeamLearning = () => {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [detailsform, setDetailsform] = useState({});
  const [data1, setDataform] = useState({});
  const [loading, setLoading] = useState(false);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    if (newTabIndex === 0) {
      navigate("learning/teamlearning/inprogress");
    } else if (newTabIndex === 1) {
      navigate("learning/teamlearning/completed");
    }
  };
  const getTeamBannerDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LMSController/Teambanner`);
      // console.log("LMSControllerTeambanner", result);
      setDetailsform(result.data.data);
      setDataform(result);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const secstotimeformat = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600); // 1 hour = 3600 seconds
    const remainingSeconds = totalSeconds % 3600;
    let minutes = Math.floor(remainingSeconds / 60);
    let seconds = remainingSeconds % 60;
    if (hours < 10) {
      hours = `0${hours}`;
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${hours}:${minutes}:${seconds}`;
  };
  const secstotimeformathrsmin = (totalSeconds, wantSecs) => {
    const hours = Math.floor(totalSeconds / 3600); // 1 hour = 3600 seconds
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    if (wantSecs) {
      return `${hours}hrs ${minutes}mins ${seconds}Secs`;
    } else {
      return `${hours}hrs ${minutes}mins`;
    }
  };
  // console.log("data1", Object.keys(data1).length === 0);
  const navigate = useNavigate();
  useEffect(() => {
    getTeamBannerDetails();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Team Learning`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <>
      {LoggedInUser?.isReportingManager ? (
        loading ? (
          <Loader />
        ) : Object.keys(data1).length !== 0 ? (
          <div style={{ overflowY: "auto", overflowX: "hidden" }}>
            <div style={{ height: "300px" }}>
              <div className="candidateProfileHeaderSection1team">
                <div className="labelTop">
                  <div
                    className="horizontalLinePrimary"
                    style={{ marginTop: "10px" }}
                  ></div>
                  <h4 className="titleColor">
                    TOP{" "}
                    {detailsform?.topPerformers
                      ? detailsform?.topPerformers?.length
                      : 0}{" "}
                    LEARNERS
                  </h4>
                </div>
                <div className="candidateProfileHeaderld1">
                  <div className="candidateProfileHeaderldcontent">
                    <div className="learnerCardHolder">
                      {detailsform
                        ? detailsform?.topPerformers?.map((item, index) => (
                            <LearnerCardComponent
                              name={`${item.employee} ${item.lstname}`}
                              // name={"Jagat jeeban Maharana "}
                              photo={item.photo}
                              time={secstotimeformathrsmin(
                                item.playbackSeconds,
                                false
                              )}
                              index={index}
                              key={index}
                            />
                          ))
                        : ""}
                    </div>

                    <div className="myteamdetailssection">
                      <div className="mylearningdetailssubsection1">
                        <div className="summaryBox1">
                          <div className="learningLeft">
                            <div className="learningImage">
                              <img
                                src={LearningSummaryIcon}
                                alt={LearningSummaryIcon}
                              />
                            </div>
                            <div
                              className="learningHeading"
                              style={{ color: "#F04E30" }}
                            >
                              <div
                                className="horizontalLine"
                                style={{
                                  backgroundColor: "#FB7158",
                                  width: "2rem",
                                  marginBottom: "7px",
                                }}
                              ></div>
                              <span className="learningfont">
                                TEAM LEARNING SUMMARY
                              </span>
                            </div>
                          </div>
                          <div className="learningCount learningCountdiv">
                            <div className="countHolder">
                              <span className="countLabel">Total</span>
                              <span
                                className="learningCount"
                                style={{ color: "#FB7158" }}
                              >
                                {detailsform ? detailsform?.total : "-"}
                              </span>
                            </div>
                            <div
                              className="timesheetVerticalLineld"
                              style={{ margin: "0 6px" }}
                            ></div>

                            <div className="countHolder">
                              <span className="countLabel">In Progress</span>
                              <span className="learningCount">
                                {detailsform ? detailsform?.started : "-"}
                              </span>
                            </div>

                            <div
                              className="timesheetVerticalLineld"
                              style={{ margin: "0 6px" }}
                            ></div>

                            <div className="countHolder">
                              <span className="countLabel">Completed</span>
                              <span className="learningCount">
                                {detailsform ? detailsform?.completed : "-"}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="summaryBox2">
                          <div className="learningLeft">
                            <div className="learningImage">
                              <img
                                src={LearningCategoryIcon}
                                alt={LearningCategoryIcon}
                              />
                            </div>
                            <div
                              className="learningHeading"
                              style={{ color: "#5BC4BF" }}
                            >
                              <div
                                className="horizontalLine"
                                style={{
                                  backgroundColor: "#5BC4BF",
                                  width: "2rem",
                                  marginBottom: "7px",
                                }}
                              ></div>
                              <span>LEARNING CATEGORY</span>
                            </div>
                          </div>
                          <div
                            className="learningCount"
                            style={{
                              justifyContent: "space-evenly",
                              width: "100%",
                            }}
                          >
                            <div className="countHolder">
                              <span className="countLabel">Mandatory</span>
                              <span
                                className="learningCount"
                                style={{ color: "#5BC4BF" }}
                              >
                                {detailsform ? detailsform?.mandatory : "-"}
                              </span>
                            </div>
                            <div
                              className="timesheetVerticalLine"
                              style={{ margin: "0 6px" }}
                            ></div>

                            <div className="countHolder">
                              <span className="countLabel">Self Learning</span>
                              <span className="learningCount">
                                {detailsform ? detailsform?.selflearning : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mylearningdetailssubsection2">
                        <div className="learningProgressBox">
                          <div className="learningLeft">
                            <div className="learningImage">
                              <img
                                src={LearningProgressIcon}
                                alt={LearningProgressIcon}
                              />
                            </div>

                            <div
                              className="learningHeading"
                              style={{ color: "#8A88ED" }}
                            >
                              <div
                                className="horizontalLine"
                                style={{
                                  backgroundColor: "#8A88ED",
                                  width: "2rem",
                                  marginBottom: "7px",
                                }}
                              ></div>
                              <span>LEARNING PROGRESS</span>
                            </div>
                          </div>

                          <div className="learningCount learningCountdiv">
                            <div className="countHolder">
                              <span className="countLabel">
                                Assigned Hours of Learning
                              </span>
                              <span
                                className="learningCount"
                                style={{ color: "#8A88ED" }}
                              >
                                {detailsform
                                  ? secstotimeformat(detailsform?.totalDuration)
                                  : "-"}
                              </span>
                            </div>
                            <div className="timesheetVerticalLineld1"></div>

                            <div className="countHolder">
                              <span className="countLabel">
                                Completed Hours of Learning
                              </span>
                              <span className="learningCount">
                                {detailsform
                                  ? secstotimeformat(detailsform?.totalprogress)
                                  : "-"}
                              </span>
                            </div>

                            <div className="timesheetVerticalLineld1"></div>

                            <div className="countHolder">
                              <span className="countLabel">Of Completed</span>
                              <span
                                className="learningCount"
                                style={{ color: "#F71D1D" }}
                              >
                                {detailsform
                                  ? `${
                                      detailsform?.percentageCompletion < 0
                                        ? "0"
                                        : detailsform?.percentageCompletion?.toFixed(
                                            1
                                          )
                                    }%`
                                  : "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() =>
                            navigate("/ld/learning/mylearning/myteamlist")
                          }
                          className="learningProgressBox2"
                        >
                          <div className="learningProgressBox2font">
                            Team Learning Assessment Results
                          </div>
                          <div className="learningProgressBox2arrow">
                            <svg
                              className="imgwhitearrow"
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="12"
                              viewBox="0 0 18 12"
                              fill="none"
                            >
                              <path
                                d="M0.742676 5.03223C0.328462 5.03223 -0.00732422 5.36801 -0.00732422 5.78223C-0.00732422 6.19644 0.328462 6.53223 0.742676 6.53223V5.03223ZM17.6973 6.31256C17.9902 6.01966 17.9902 5.54479 17.6973 5.2519L12.9244 0.478926C12.6315 0.186032 12.1566 0.186032 11.8637 0.478926C11.5708 0.771819 11.5708 1.24669 11.8637 1.53959L16.1063 5.78223L11.8637 10.0249C11.5708 10.3178 11.5708 10.7926 11.8637 11.0855C12.1566 11.3784 12.6315 11.3784 12.9244 11.0855L17.6973 6.31256ZM0.742676 6.53223H17.167V5.03223H0.742676V6.53223Z"
                                fill="#ffffff"
                              />
                            </svg>
                            <img
                              className="imgpurplearrow"
                              src={purplearrow}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="candidateProfileHeaderSection2team"
              style={{
                marginTop: "0px",
                marginBottom: "10px",
              }}
            >
              <div className="tabContainer">
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  // variant="scrollable"
                  // scrollButtons="auto"
                  TabIndicatorProps={{ style: { background: "#1296B0" } }}
                >
                  <Tab
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex === 0 && "tabActive"
                        }`}
                      >
                        In Progress
                      </span>
                    }
                  />
                  <Tab
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex === 1 && "tabActive"
                        }`}
                      >
                        Completed
                      </span>
                    }
                  />
                </Tabs>
                <div className="tabContainerLine"></div>
              </div>
              {tabIndex === 0 && (
                <>
                  <InProgress />
                </>
              )}
              {tabIndex === 1 && (
                <>
                  <Completed />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="noDatastyle">
            <img
              src={Nodata}
              alt="No data"
              style={{ height: "150px", width: "250px" }}
            />
            <h4>{"No Data Found"}</h4>
          </div>
        )
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"You don't have required permissions to view the content"}</h4>
        </div>
      )}
    </>
  );
};

export { TeamLearning };
