import React from 'react';

const DeductionsComponent = ({ deductions,employeeSummary }) => {
  // Calculate the total deductions
  const totalDeductions = deductions.reduce((total, deduction) => total + deduction.amount, 0);

  // Filter deductions by type
  const deductionsByType = deductions.reduce((accumulator, deduction) => {
    if (!accumulator[deduction.type]) {
      accumulator[deduction.type] = 0;
    }
    accumulator[deduction.type] += deduction.amount;
    return accumulator;
  }, {});

  return (
    <div className="paySummaryComponentContainer">
      <div className="paySummaryComponentHeader">
        <h6>DEDUCTIONS</h6>
        <h6>AMOUNT</h6>
      </div>
      <div className="paySummaryComponentMain">
        {Object.keys(deductionsByType).map((deductionType, index) => (
          <div className="paySummaryComponentList" key={index}>
            <p className="empheadingSlip">{deductionType}</p>
            <p className="empBodySlip">₹{deductionsByType[deductionType]}</p>
          </div>
        ))}
      </div>
      <div className="paySummaryComponentFooter">
        <p className='empheadingSlip'>Total Deductions</p>
        <p className="empBodySlip">₹{employeeSummary.totalDeduction}</p>
      </div>
    </div>
  );
};

export  {DeductionsComponent};
