import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../../Features";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { reqCategorySchema } from "../../../HRMSMaster/HRDeskComponents/RequestCategorySchemas";
import { ChangeForm } from "./Form/ChangeForm";
import { useState } from "react";
import { useEffect } from "react";

const CreateChangeIt = (props) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const id = useParams();
  let xy = Object?.keys(id);
  const n = id[xy]?.lastIndexOf("/");
  const selectedId = id[xy]?.substring(n + 1);
  const [formValues, setFormValues] = useState(null);
  const { state } = useLocation();
  const data = state?.selectedId;
  //console.log("typeof ", typeof selectedId )
  const postFormData = async (values) => {
    try {
      setLoading(true);
      if (data && typeof selectedId == Number){  await postRequestForm(
          `itsmChangeRequest/create/?Id=${data}&Status=${"SolutionPossible"}&userId=${selectedId}`,
          values
        );
      }  
      else if (data) {
        await postRequestForm(`itsmChangeRequest/Create?crId=${data}`, values);
        await postRequest(
          `ProblemRequests/SolutionStatusUpdate/?Id=${data}&Status=${"SolutionPossible"}`
           )
      } else await postRequestForm("itsmChangeRequest/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );

      returnPage();
    } catch (error) {}
    setLoading(false);
  };
  const EditFormData = async (values) => {
    try {
      setLoading(true);

      await postRequestForm(`itsmChangeRequest/Edit`, values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );

      returnPage();
    } catch (error) {}
    setLoading(false);
  };
  const [details, setDetail] = useState(false);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `ITsmChangeRequest/Details?ID=${selectedId}`
      );
      setDetail(result?.data?.data?.itsmChangeRequest);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Change IT`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (data === "edit") getDetails();
  }, []);
  const initialvalue = {
    TicketCategoryId: details ? details?.ticketCategoryId : "",
    ChangeType: details ? details?.changeType : "",
    Priority: details ? details?.priority : "",
    DescriptionOfchange: details ? details?.descriptionOfchange : "",
    Reason: details ? details?.reason : "",
    upload: details ? `ChangeRequest/${details?.supportingDocument}` : "",
  };

  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("TicketCategoryId", Number(values?.TicketCategoryId));
    formData.append("ChangeType", values?.ChangeType);
    formData.append("Priority", values?.Priority);
    formData.append("DescriptionOfchange", values?.DescriptionOfchange);
    formData.append("Reason", values?.Reason);
    if(typeof values?.upload === "object")  formData.append("upload", values?.upload);
    if (data === "edit") {
      formData.append("Id", selectedId);
      EditFormData(formData);
    } else postFormData(formData);
  };
  const SUPPORTED_FORMATS = ["application/pdf", "application/doc","image/jpg", "image/jpeg", "image/png"];
  const validationSchema = Yup.object({
    TicketCategoryId: Yup.string().required("Please Select Service Category"),
    ChangeType: Yup.string().required("Please Select ChangeType"),
    Priority: Yup.string().required("Please Select Priority"),
    DescriptionOfchange: Yup.string()

      .max(499, "Description cannot be more than 500 characters")
      .required("Please Enter Description"),
    Reason: Yup.string()

      .max(499, "Reason cannot be more than 500 characters")
      .required("Please Enter Reason"),
     upload: Yup.mixed()
      .nullable()
      .test(
        "Fichier taille",
        "File Size should be less than 2 mb",
        (value) => !value || (value && value.size <= 2048 * 2048)
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
        !value ||
        (value && SUPPORTED_FORMATS.includes(value.type)) ||
        typeof value !== "object"
    ),
  });
  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      ButtonLabel={
        data === "edit" ? "Update Change Request" : "Create Change Request"
      }
      FormHeading={
        data === "edit" ? "Update Change Request" : "Create Change Request"
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      returnPage={returnPage}
      data={data}
      propsDiv={<ChangeForm formValues={formValues} />}
    />
  );
};

export { CreateChangeIt };
