import React, {  } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { MaterialForm } from "./Form/MaterialForm";
import { materialSchema } from "./Schema";
import { useEffect } from "react";



const CreateAssetMaterial = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();
  const getDetails = async () => {
    try {
      const result = await getRequest(`Materials/Details/${data}`);
      setDetail(result.data);
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  }, [data]);
  useEffect(() => {
    document.title = `PeopleSol - Create Asset Material`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const postFormData = async (values) => {
    try {
      
      const result = await postRequest(
        "Materials/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {}
  };
  const initialvalue = {
    name: data?detail?.name:"",
    assetCategoryId:data?detail?.assetCategoryId:""
  };
  
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "Materials/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data, severity: "error" })
    ) }
  };

  const onSubmit =  (values) => {

    const edit = {
        ...values,
        id: data,
    };
    
    const create = {
        ...values,
    };
    
    data ? Edit(edit) : postFormData(create);


    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/itsmmaster/asset/assetmaterial");
  };

  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Asset Material':'Create Asset Material'}
      FormHeading={data?'Edit Asset Material':'Create Asset Material'}
      validationSchema={materialSchema}
      onSubmit={onSubmit}
      initialvalue={
   
          initialvalue
      }
      returnPage={returnPage}
      propsDiv={<MaterialForm data={data}/>}
    />
  );
};

export { CreateAssetMaterial };


