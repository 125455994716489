import React from 'react';

import { MeetingRoomTab } from './MeetingRoomTab';
import { useEffect } from 'react';

const MeetingRoom = () => {
    useEffect(() => {
        document.title = `PeopleSol - Meeting Room`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
   
    return (
        <div className="moduleMainContainer">
          
            <MeetingRoomTab title={'Meeting Room'}/>
            
        </div>
    )
}

export { MeetingRoom }