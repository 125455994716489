import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import {  viewSchema } from "./Schema";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";

const CreateInfoView = (props) => {
    const [detail, setDetail] = React.useState([]);
    const { state } = useLocation();
    
    const data = state?.id;
    
    const dispatch = useDispatch();
    
    useEffect(() => {
      document.title = `PeopleSol - Create Info View`;
      return () => {
        document.title = 'PeopleSol';
      };
    }, []);

    const getDetails = async () => {
      try {
        const result = await getRequest(`ViewInfotypes/Details/${data}`);
        setDetail(result.data);
  
      } catch (error) {        dispatch(
        showToast({ text: error.response.message, severity: "error" })
      ) 
      } finally {
      }
    };
    React.useEffect(() => {
      if(data){
        getDetails();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const initialvalue = {
        name: detail?.name,
        value: detail?.value,
        sapId: detail?.sapId,
        infotypeId: detail?.infotypeId
      };
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ViewInfotypes/EditKdsItem",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }

    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  const onSubmit = async (values) => {

    const edit = {
      ...values,
      id: data,
    };

 
Edit(edit)


    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/globalsettingsmaster/system/infotypes/viewinfo");
  };

  return (
    <FormCustomFormik
    ButtonLabel={"Update"}
      FormHeading={props.title}
      validationSchema={viewSchema}
      onSubmit={onSubmit}
      initialvalue={
         initialvalue
      }
      returnPage={returnPage}
      propsDiv={<InfoTypeForm data={detail}/>}
    />
  );
};

export { CreateInfoView };

const InfoTypeForm = ({data}) => {
    const [option, setOption] = React.useState([]);
    const opt=[
      {label:"select",value:null}
    ]
    const getOption = async () => {
      try {
        const result = await getRequest("ViewInfotypes/GetInfotypes");
        let listData = [];
        result.data.map((item) => {
          return listData.push({ label: item.title, value: item.id });
        });
        setOption(listData);
      } catch (error) {
      } finally {
      }
    };
    React.useEffect(() => {
      getOption();
    }, []);
  
  return (
    <>
    <div>
        <LabelCustom labelName={"Key"} mandatory={true} />
        <InputCustomFormik
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
          />
    </div>
    <div>
        <LabelCustom labelName={"Value"} mandatory={true} />
        <InputCustomFormik
          name="value"
          type={"text"}
          placeholder={"Enter Name"}
          />
    </div>
    <div>
        <LabelCustom labelName={"Key"} mandatory={true} />
        <InputCustomFormik
          name="sapId"
          type={"text"}
          placeholder={"Enter Name"}
          />
    </div>
    <div>
        <LabelCustom labelName={"Infotype "} mandatory={true} />
        <SelectForm
          values={
            data
              ? option.filter(
                  (options) => options.value === data?.infotypeId
                )
              : opt
          }
          options={option}
          name="infotypeId"
          placeholder={" Select "}
        />
      </div>
          </>
  );
};


