import React, { useState, useEffect } from "react";
import Reeports from "../../Assets/Reeports.svg";
import ItsmexceptionPic from "../../Assets/ItsmexceptionPic.svg";
import { useLocation, useNavigate } from "react-router-dom";
import HRMSIcon from "../../Assets/HRMSIcon.svg";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { Reports } from "./Components/Reports";
import { MsiKpi } from "./Components/MsiKpi";
const ReportsKpiMsI = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  const [activeSubmodule, setActiveSubmodule] = useState("reports");
  const [detailsActive, setDetailsActive] = useState(false);
  const [createActive, setCreateActive] = useState(false);

  useState(false);
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "reports") return Reeports;
    else if (subMenuName === "kpimsi") return ItsmexceptionPic;
  };
  //console.log("activeSubmodule",activeSubmodule)
  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "reportskpi"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("reportskpi")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "reportskpi");
      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        }
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);
  return (
    <div className="moduleContainer">
      {activeSubmodule === "reports" && (
        <>
          {createActive && <></>}
          {detailsActive && <></>}
        </>
      )}
      {activeSubmodule === "kpimsi" && (
        <>
          {createActive && <></>}
          {detailsActive && <></>}
        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/reportskpi/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {activeSubmodule === "reports" ? (
          <>
            <Reports />
          </>
        ) : null}
        {activeSubmodule === "kpimsi" ? <MsiKpi /> : null}
      </div>
    </div>
  );
};

export { ReportsKpiMsI };
