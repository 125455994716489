/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import React, { useEffect, useState } from "react";
import "./Day30Form.css";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import Addonboard from "../../../../../Assets/Addonboard.svg";
import CanOuter from "../../../../../Assets/CanOuter.svg";
import FeedbackFormIcon from "../../../../../Assets/FeedbackFormIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { isLoading, showToast } from "../../../../../Features";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { getInitialLetter } from "../../../../../Utilities/getInitialLetter";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { setEmployeeData } from "../../../../../Features/AuthSlice";

const Day30Form = ({image}) => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [isSeven, setIsSeven] = useState(false);
  const [isThirty, setIsThirty] = useState(false);
  const [initialValue, setInitialValue] = useState({});
  const { LoggedInUser } = useSelector((state) => state.auth);
  // console.log("LoggedInUser",LoggedInUser)
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const returnPage = () => {
    navigate("/home/dashboard");
  };
  const getFeedbackStatus = () => {
    if (LoggedInUser.isFirstDayfeedbackCreated === false) {
      setIsFirst(true);
      setIsSeven(false);
      setIsThirty(false);
    } else if (LoggedInUser.isFirstSevenDayfeedbackCreated === false) {
      setIsFirst(false);
      setIsSeven(true);
      setIsThirty(false);
    } else if (LoggedInUser.isFirstMonthfeedbackCreated === false) {
      setIsFirst(false);
      setIsSeven(false);
      setIsThirty(true);
    } else navigate("/");
  };
  useEffect(() => {
    document.title = `PeopleSol - Day 30 Form`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: 10,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  const getProfileData = async () => {
    try {
      const result = await getRequest("Employees/GetBannerProfile");
      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const submitFeedback = async (values) => {
    try {
      // dispatch(isLoading(true));
      setLoading(true);
      if (isSeven === true) {
        var result = await postRequest(
          "Inductions/FirstSevenDayFeedBack",
          values
        );
      } else if (isThirty === true) {
        var result = await postRequest("Inductions/FirstMonthFeedBack", values);
      } else if (isFirst === true) {
        var result = await postRequest("Inductions/FirstDayFeedBack", values);
      }
      if (result) {
        const result = await getRequest("Employees/GetEmployeedata");
        if (result) {
          dispatch(setEmployeeData(result?.data?.data));
          dispatch(
            showToast({ text: "Feedback Submitted", severity: "success" })
          );
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      // dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getFeedbackData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (isSeven === true) {
        var result = await postRequest(
          "OnBoardingFeedbackQuestions/Day7List",
          reqData
        );
      } else if (isThirty === true) {
        var result = await postRequest(
          "OnBoardingFeedbackQuestions/Day30List",
          reqData
        );
      } else if (isFirst === true) {
        var result = await postRequest(
          "OnBoardingFeedbackQuestions/Day1List",
          reqData
        );
      }
      let listData = [];
      JSON.parse(result?.data?.data)?.map((item) => {
        return listData.push({
          questionId: item.Id,
          question: item.Question,
          order: item.DisplayOrder,
        });
      });
      setFeedbackList(listData);

      let initialValues = {};
      JSON.parse(result?.data?.data)?.map((item) => {
        return (initialValues[`${item.questionId}`] = "");
      });
      setInitialValue(initialValues);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("form")) {
      getFeedbackStatus();
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    getFeedbackData();
  }, [isFirst || isSeven || isThirty]);

  const onSubmit = (values) => {
    const x = Object.keys(values);
    let empArr = [];
    x.map((items) => {
      return empArr.push({
        questionsID: items,
        rating: values[items],
      });
    });

    submitFeedback(empArr);
  };

  return (
    <div className="outerCont" style={{ position: "relative" }}>
      <div className="canOuter" style={{ opacity: "1" }}>
        <img src={CanOuter} alt="LoginPerson" />
      </div>

      <div className="outerGr" style={{ opacity: "1", zIndex: "1" }}>
        <img src={Addonboard} alt="LoginPerson" />
      </div>

      <div
        className="innerCont"
        style={{
          flexDirection: "row",
          borderRadius: "10px",
          contain: "content",
        }}
      >
        <div className="innerContLeft">
          <div className="feedbackPermissionHolder">
            <label className="feedbackPermissionText">
              How was your <br />
              {isSeven === true ? (
                <>first week?</>
              ) : isThirty === true ? (
                <>first month?</>
              ) : (
                <>first day?</>
              )}
            </label>
          </div>
          <div className="formIconHolder">
            <img src={FeedbackFormIcon} alt={FeedbackFormIcon} />
          </div>
        </div>
        <div
          className="innerContRight"
          style={{ backgroundColor: "var(--form-background)" }}
        >
          <div className="feedbackFormHeader">
            <div
              className="profileDetails"
              style={{
                display: "flex",
                marginTop: "5rem",
                alignItems: "center",
              }}
            >
              <div className="avatarContainer" style={{ marginLeft: "5rem" }}>
                <CircularProgressbarWithChildren
                  value={100}
                  text={`82%`}
                  strokeWidth={5}
                  styles={buildStyles({
                    textColor: "transparent",
                    pathColor: "#1296B0",
                    trailColor: "#D8F0F2",
                    rotation: 0.37,
                    strokeLinecap: "round",
                  })}
                >
                  {(LoggedInUser?.image) ? (
                    <img
                      src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${LoggedInUser?.image}`}
                      alt="Profile"
                      className="imageInsideProgressBar"
                    />
                  ) : (
                    <img
                      src={ProfileAvatar}
                      alt={"Profile"}
                      className="imageInsideProgressBar"
                    />
                  )}
                </CircularProgressbarWithChildren>
              </div>
              <div className="formGreetingMessage">
                <label className="commentText">Welcome, </label>
                <label
                  className="feedbackPermissionText"
                  style={{ fontSize: "2rem" }}
                >
                  {bannerList?.Name ? bannerList?.Name : "--"}
                </label>
              </div>
            </div>

            <div className="profileDetails sideFeedbackDetails">
              <div
                className="questionHolder"
                style={{
                  marginTop: "16px",
                  lineHeight: "26px",
                  marginLeft: "7rem",
                }}
              >
                <div className="horizontalLinePrimary"></div>
                <div className="questionText">
                  <label className="commentText" style={{ fontSize: "14px" }}>
                    Department
                  </label>
                </div>
                <div>
                  <label style={{ color: "var(--primary)", fontWeight: "400" }}>
                    {bannerList?.Department ?? "--"}
                  </label>
                </div>
              </div>

              <div
                className="questionHolder"
                style={{
                  marginTop: "10px",
                  lineHeight: "26px",
                  marginLeft: "7rem",
                }}
              >
                <div className="horizontalLinePrimary"></div>
                <div className="questionText">
                  <label className="commentText" style={{ fontSize: "14px" }}>
                    Designation
                  </label>
                </div>
                <div>
                  <label style={{ color: "var(--primary)", fontWeight: "400" }}>
                    {bannerList?.Designations ?? "--"}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <Formik initialValues={initialValue} onSubmit={onSubmit}>
            {(formik) => {
              return (
                <Form>
                  <div className="feedbackFormFooter">
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        <div className="formHolder">
                          {feedbackList &&
                            feedbackList
                              .slice() // Create a shallow copy of the array before sorting
                              .sort((a, b) => a.questionId - b.questionId) // Sort by questionId
                              .map((item) => {
                                //console.log("item", item);
                                return (
                                  <div
                                    className="questionHolder"
                                    key={item.questionId}
                                  >
                                    <div
                                      style={{ marginLeft: "12px" }}
                                      className="questionText"
                                    >
                                      <label
                                        className="commentText"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {item.question}{" "}
                                        <sup style={{ color: "red" }}>*</sup>{" "}
                                      </label>
                                    </div>
                                    <div>
                                      <TextAreaFormik
                                        maxLength={499}
                                        placeholder="Please Enter Answer"
                                        styles={{
                                          padding: "0",
                                          margin: "0",
                                          marginTop: "1rem",
                                          border: "1px solid aliceblue",
                                          height: "60px",
                                        }}
                                        name={`${item.questionId}`}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                        </div>
                        <div className="buttonHolder">
                          <div
                            className="feedbackButton cursor feedbackSubmitButton"
                            type="submit"
                            onClick={() => onSubmit(formik.values)}
                          >
                            Submit
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export { Day30Form };
