/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../../Services/axios";
function CandidateDetails({
  titleOptions,
  manpowerIndentOptions,
  onboarding,
  employee,
  departmentOptions,
  roleOptions,
  reportingOfficerOptions,
  areaOptions,
  designationOptions,
  formValues,
  shiftOptions,
  systemRoleOptions,
  employeeTypeOptions,
}) {
  // //console.log("employee",employee)
  useEffect(() => {
    if (employee) {
      if (formValues?.DepartmentId)
        if (formValues?.DepartmentId !== "") getSubDepartmentData();
    }
  }, [formValues?.DepartmentId]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const getSubDepartmentData = async () => {
    try {
      const result = await getRequest(
        `Department/GetSubDepartment/${formValues?.DepartmentId}`
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setSubDepartmentList(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Candidate Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div className="headingForm">
        {employee ? "Employee Detailss" : "Candidate Details"}
      </div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Title"} />
          <SelectForm name="InitialName" options={titleOptions} />
        </div>
        <div>
          <LabelCustom labelName={"First Name"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="Name"
            type={"text"}
            placeholder={"Enter First Name"}
          />
        </div>
        {/* <div>
        <LabelCustom labelName={"Middle Name"} />
        <InputCustomFormik
          
          maxLength={150}
          name="MiddleName"
          type={"text"}
          placeholder={"Enter Middle Name"}
        />
      </div> */}
        <div>
          <LabelCustom labelName={"Last Name"} />
          <InputCustomFormik
            maxLength={150}
            name="LastName"
            type={"text"}
            placeholder={"Enter Last Name"}
          />
        </div>
        {onboarding ? null : (
          <div>
            <LabelCustom
              labelName={employee ? "Official Email Id" : "Email Id"}
              mandatory={true}
            />
            <InputCustomFormik
              maxLength={150}
              name="Email"
              type={"email"}
              placeholder={"Enter Email"}
            />
          </div>
        )}

        <div>
          <LabelCustom
            labelName={employee ? "Official Mobile Number" : "Mobile Number"}
            mandatory={employee ? true : false}
          />
          <InputCustomFormik
            minLength={10}
            maxLength={10}
            name={employee ?"OfficePhoneNumber":"PersonalPhoneNumber"}
            step=".001"
            placeholder={"Enter Mobile Number"}
          />
        </div>
        {/* <div>
        <LabelCustom labelName={"Alternate Number"} />
        <InputCustomFormik
          minLength={10}
          maxLength={10}
          name="AlternateNumber"
          step=".001"
          placeholder={"Enter Alternate Number"}
        />
      </div> */}
      </div>
      {!onboarding && (
        <>
          <div className="headingForm">Organisation Details</div>
          <div className="formcustom threeColumn">
            <div>
              <LabelCustom labelName={"Requisition Number"} />
              <SelectForm
                name="ManPowerIndentID"
                options={manpowerIndentOptions}
                placeholder="Select Requisition Number"
                isDisabled={employee ? true : false}
              />
            </div>
            <div>
              <LabelCustom labelName={"Date of Joining"} />
              <DatePickerFormiks
                name="JoiningDoj"
                placeholder={"Select Date of Joining"}
              />
            </div>
            {employee && (
              <>
                <div>
                  <LabelCustom
                    labelName={"Reporting Manager"}
                    mandatory={true}
                  />
                  <SelectForm
                    name="ReportingOfficer"
                    placeholder={"Select Reporting Manager"}
                    options={reportingOfficerOptions}
                  />
                </div>
                <div>
                  <LabelCustom labelName={"Employee Type"} mandatory={true} />
                  <SelectForm
                    name="employeeTypeCode"
                    placeholder={"Select Employee Type"}
                    options={employeeTypeOptions}
                  />
                </div>
                <div>
                  <LabelCustom labelName={"Location"} mandatory={true} />
                  <SelectForm
                    name="AreaId"
                    placeholder={"Select Location"}
                    options={areaOptions}
                    menuPlacement="top"
                  />
                </div>
                <div>
                  <LabelCustom labelName={"Designation"} mandatory={true} />
                  <SelectForm
                    name="DesignationId"
                    options={designationOptions}
                    placeholder={"Select Designation"}
                    menuPlacement="top"
                  />
                </div>
                <div>
                  <LabelCustom labelName={"Department"} mandatory={true} />
                  <SelectForm
                    name="DepartmentId"
                    options={departmentOptions}
                    placeholder={"Select Department"}
                    menuPlacement="top"
                  />
                </div>
                {subDepartmentList.length > 0 && (
                  <div>
                    <LabelCustom labelName={"Sub Department"} />
                    <SelectForm
                      name="SubDepartmentId"
                      placeholder={"Select Sub Department"}
                      options={subDepartmentList}
                      menuPlacement="top"
                    />
                  </div>
                )}
                {/* NOT THERE IN API */}
                <div>
                  <LabelCustom labelName={"Role"} mandatory={true} />
                  <SelectForm
                    name="roleId"
                    placeholder={"Select Role"}
                    options={roleOptions}
                    menuPlacement="top"
                  />
                </div>
                {/* NOT THERE IN API */}
                <div>
                  <LabelCustom labelName={"System Role"} mandatory={true} />
                  <SelectForm
                    name="systemRoleId"
                    placeholder={"Select Role"}
                    options={systemRoleOptions}
                    menuPlacement="top"
                  />
                </div>
                {/* NOT THERE IN API */}
                <div>
                  <LabelCustom labelName={"Shift"} mandatory={true} />
                  <SelectForm
                    name="shiftId"
                    placeholder={"Select Shift"}
                    options={shiftOptions}
                    menuPlacement="top"
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
export { CandidateDetails };
