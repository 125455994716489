import React from "react";
import { useEffect } from "react";
import StarRatings from "react-star-ratings";

export const InstructionSelf = () => {
  useEffect(() => {
    document.title = `PeopleSol -  Instruction Self`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div style={{ background: "white", padding: "12px" }}>
      <p style={{ fontSize: "14px", padding: "0px 0px 12px 1px" }}>Rating</p>
      <div className="ratingCar">
        <div className="starholderpmself">
          <div style={{ }}>
            <StarRatings
              starEmptyColor={"#BFBFBF"}
              starRatedColor={"var(--secondary)"}
              starDimension="17px"
              starSpacing="2px"
              rating={5}
            />
          </div>
          <label
            className="jobDesignation"
            style={{  fontSize: "15px", fontWeight: "500" }}
          >
            <b> Outstanding </b> (111-120 % Achievement)
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ }}>
            <StarRatings
              starEmptyColor={"#BFBFBF"}
              starRatedColor={"var(--secondary)"}
              starDimension="17px"
              starSpacing="2px"
              rating={4}
            />
          </div>
          <label
            className="jobDesignation"
            style={{  fontSize: "15px", fontWeight: "500" }}
          >
            <b> Strong Performer </b> (101-110 % Achievement )
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ }}>
            <StarRatings
              starEmptyColor={"#BFBFBF"}
              starRatedColor={"var(--secondary)"}
              starDimension="17px"
              starSpacing="2px"
              rating={3}
            />
          </div>
          <label
            className="jobDesignation"
            style={{  fontSize: "15px", fontWeight: "500" }}
          >
            <b> Meets Expectations </b> (91-100 % Achievement)
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ }}>
            <StarRatings
              starEmptyColor={"#BFBFBF"}
              starRatedColor={"var(--secondary)"}
              starDimension="17px"
              starSpacing="2px"
              rating={2}
            />
          </div>
          <label
            className="jobDesignation"
            style={{  fontSize: "15px", fontWeight: "500" }}
          >
            <b> Needs Improvement</b> (71-90 % Achievement)
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ }}>
            <StarRatings
              starEmptyColor={"#BFBFBF"}
              starRatedColor={"var(--secondary)"}
              starDimension="17px"
              starSpacing="2px"
              rating={1}
            />
          </div>
          <label
            className="jobDesignation"
            style={{  fontSize: "15px", fontWeight: "500" }}
          >
            <b> Unsatisfactory</b> (0-70 % Achievement)
          </label>
        </div>
      </div>
    </div>
  );
};
