/* eslint-disable react-hooks/exhaustive-deps */
import React ,{useEffect } from "react";
import { useDispatch } from "react-redux";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";

const opt = [{ label: "select", value: null }];
export const WorkflowDetailsFormIt = (props) => {
  const { values, data } = props;
  const [optionSer, setOptionSer] = React.useState([]);
  const [optionReq, setOptionReq] = React.useState([]);
  const [option, setOption] = React.useState([]);
  const getOptionSer = async () => {
    try {
      const result = await getRequest("ItsmWorkflows/GetITServiceCatalog");
      let listData = [];
      result.data.map((item) => {
      return  listData.push({ label: item.categoryName, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionReq = async () => {
    let result;
    try {
        result = await getRequest(
        `ItsmWorkflows/GetItsmRequestTypes?RequestCategoryId=${values?.requestCategoryId}&&TypeId=${values?.ticketTypeId}`
      );

      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOptionReq(listData);
      values.requestTypeId = listData[0]?.value;  
          setOptionReq(listData);
    } catch (error) {
    } finally {
    }
  };  
  useEffect(() => {
    document.title = `PeopleSol - Workflow Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getOption = async () => {
    try {
      const result = await getRequest(
        `ItsmWorkflows/GetRequestCategory/${values.ticketTypeId}`
      );

      let listData = []; 
    listData.push({ label: result?.data?.text, value: result?.data?.id });
      
      values.requestCategoryId = listData[0]?.value;
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    getOptionSer();
  }, []);
  useEffect (() => {
    if (values?.ticketTypeId) {
      getOption();
    }
  }, [values?.ticketTypeId]);
  useEffect(() => {
    if (values?.requestCategoryId) {
      getOptionReq();
    }
  }, [values?.requestCategoryId]);
  const dispatch = useDispatch();
  return (
    <div className="formcustom twoColumn" style={{ height: "fit-content" }}>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={150}
          name="name"
          type={"text"}
          placeholder={" Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Service Type   "} mandatory={true} />
        <SelectForm
          values={
            data
              ? optionSer.filter(
                  (options) => options?.value === data?.ticketTypeId
                )
              : opt
          }
          name={"ticketTypeId"}
          options={optionSer}
          placeholder={" Select Service Type  "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Request Category "} mandatory={true} />
        <SelectForm
          onDropdownOpen={
            values?.ticketTypeId === ""
              ? () =>
                  dispatch(
                    showToast({
                      text: " Select  service type",
                      severity: "error",
                    })
                  )
              : () => {}
          }
          name={"requestCategoryId"}
          options={option}
          placeholder={" Select Request Category   "}
        />
      </div>
      {values?.requestCategoryId !== 3  && (
        <div>
          <LabelCustom labelName={" Select Request Type"} mandatory={true} />
          <SelectForm
            name={"requestTypeId"}
            options={optionReq}
            placeholder={" Select Request Type"}
          />
        </div>
      )}

      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik
        
        maxLength={500}
          style={{ height: "120px" }}
          name={"description"}
          placeholder={" Enter Description "}
        />
      </div>
    </div>
  );
};
