import React from "react";
import { useEffect } from "react";

const AddRepeatedSectionComp = ({text, clickHandler, color}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Add Repeated Section`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div
      className="addTaskCompHolder"
      style={{ paddingLeft: "20px" }}
    >
      <div
        className="addTaskIconHolder cursor"
        style={{ height: "24px", width: "25px", margin: "0" }}
        onClick={clickHandler}
      >
        <label className="addTaskIcon">
          <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="6.50529"
              y1="0.748943"
              x2="6.50529"
              y2="11.991"
              stroke="#1296B0"
              stroke-width="1.27035"
              stroke-linecap="round"
            />
            <line
              x1="12.1173"
              y1="6.22746"
              x2="0.875186"
              y2="6.22746"
              stroke="#1296B0"
              stroke-width="1.27035"
              stroke-linecap="round"
            />
          </svg>
        </label>
      </div>
      <div className="addTaskTextHolder cursor" onClick={clickHandler}>
        <label
          style={{
            color: `${color ? color : "var(primary)" }`,
            fontWeight: "400",
            paddingLeft: "7px",
          }}
        >
          {text}
        </label>
      </div>
    </div>
  );
};

export { AddRepeatedSectionComp };
