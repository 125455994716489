import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import SocialPostTeamIcon from "../../../../../Assets/SocialPostTeamIcon.svg";
import SocialBirthdayTag from "../../../../../Assets/SocialBirthdayTag.svg";
import SocialWorkAnnyTag from "../../../../../Assets/SocialWorkAnnyTag.svg";
import SocialNewJoiningTag from "../../../../../Assets/SocialNewJoiningTag.svg";
import SocialKudosTag from "../../../../../Assets/SocialKudosTag.svg";
import dateFormat from "dateformat";
import {
  docsArray,
  getAttachments,
  getExtension,
  getUserInitials,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import "./ShowFullAttachments.css";
import "./Connect.css";
import {
  albumPostImages,
  communityProfileImages,
  profileImages,
  socialPostImages,
} from "../../../../../Utilities/profileAndUploadImages";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import ProfileAvatar from "../../../../../Assets/ProfileAvatar.svg";
import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import SharePost from "./SharePost";
import CommentPost from "./CommentPost";
import LikeDislike from "./LikeDislike";
import { useNavigate } from "react-router-dom";
import PDFViewer from "./PDFViewer";
import ContentSocialDisplay from "./ContentSocialDisplay";
import { useSelector } from "react-redux";
import { setSocialData } from "../../../../../Features/SocialSlice";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import DetailsPostLikeDislikeComponent from "./DetailsPostLikeDislikeComponent";
import ShareDetails from "./ShareDetails";

function SocialDeepNestetShowFullAttachment({
  postid,
  setShowFullAttachmentActive,
}) {
  const [commentedPost, setCommentedPost] = useState(null);
  const [shareDetailsActive, setShareDetailsActive] = useState(false);
  const [sharePostActive, setSharePostActive] = useState(false);
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [isImages, setIsImages] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [isDocs, setIsDocs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const [attachment, setAttachment] = useState(null);
  const { socialData } = useSelector((state) => state.social);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`SocialPost/Details?PostId=${postid}`);
      const { data, summary } = result?.data;
      const mergedObject = { ...data, ...summary };
      setCommentedPost(mergedObject);
      setAttachment(getAttachments(result?.data?.data?.attachment));
      findAttach(getAttachments(result?.data?.data?.attachment));
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const [items, setItems] = useState(undefined);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("commentid"));
    if (items) {
      setItems(items);
    }
  }, []);
  const backToAnnouncement = () => {
    setAttachment(null);
    setCommentedPost(null);
    setShowFullAttachmentActive(false);
  };
  

  const findAttach = (attachment) => {
    if (attachment) {
      let x = getExtension(attachment[0]);
      if (imgArray.includes(x)) {
        setIsImages(true);
      } else if (videoArray.includes(x)) {
        setIsVideos(true);
      } else if (docsArray.includes(x)) {
        setIsDocs(true);
      }
      if (x !== null) {
        setNumPages(attachment?.length);
      }
    }
  };
  const handleUserProfileClick = (userId) => {
    navigate(`/home/social/userprofile/${userId}`);
  };

  const nextSlide = (e) => {
    // e.stopPropagation();
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  };
  const prevSlide = (e) => {
    // e.stopPropagation();
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  useEffect(() => {
    const keyHandler = (event) => {
      if (event.code === "ArrowRight") {
        nextSlide();
      } else if (event.code === "ArrowLeft") {
        prevSlide();
      }
    };
    document.addEventListener("keydown", keyHandler, false);
    return () => document.removeEventListener(keyHandler);
  }, []);
  useEffect(() => {
    getData();
  }, [postid]);
  useEffect(() => {
    return () => {
      setCommentedPost(null);
    };
  }, [postid]);
  return (
    <div className="showAttachmentsWithCommentContainer">
      {!loading && commentedPost && (
        <Popup
          firstClassName="showAttachmentsWithCommentPopupbox1"
          secondClassName="showAttachmentsWithCommentPopupbox2"
          createMyHead="showAttachmentsWithCommentCreateHead"
          content={
            <>
              <div className="showAttachmentsWithCommentContainer">
                <Popup
                  firstClassName="showAttachmentsWithCommentPopupbox1"
                  secondClassName="showAttachmentsWithCommentPopupbox2"
                  createMyHead="showAttachmentsWithCommentCreateHead"
                  content={
                    <>
                      <div className="showAttachmentsWithCommentWrapper">
                        {isImages && (
                          <div className="showAttachmentsWithCommentImgContainer" style={{padding:"0px", margin:"12px", height:"96%"}}>
                            {commentedPost?.subType &&
                              getExtension(attachment[0]) !== "gif" && (
                                <img
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    albumPostImages +
                                    attachment[index]
                                  }
                                  alt=""
                                />
                              )}
                            {(!commentedPost?.subType ||
                              getExtension(attachment[0]) === "gif" || commentedPost?.subType !== null) && (
                              <img
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  socialPostImages +
                                  attachment[index]
                                }
                                alt=""
                              />
                            )}
                          </div>
                        )}
                        {isVideos && (
                          <div
                            className="showFullAttachmentListBox"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <video
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                socialPostImages +
                                attachment[index]
                              }
                              controls
                              style={{
                                padding: "12px",
                                width: "100%",
                                height: "89%",
                              }}
                            />
                          </div>
                        )}
                        {isDocs && (
                          <div
                            className="showFullAttachmentListBox"
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <PDFViewer
                              pdfUrl={
                                process.env.REACT_APP_.BASE_URL +
                                socialPostImages +
                                attachment[index]
                              }
                            />
                          </div>
                        )}
                        {!isImages && !isVideos && !isDocs && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                              color: "#bfbfbf",
                            }}
                          >
                            No Attachment Found!
                          </div>
                        )}
                        {attachment !== null && attachment?.length > 0 && (
                          <>
                            <div className="leftPdfArrow">
                              {index === 0 || (
                                <img
                                  src={SocialPdfLeftArrow}
                                  alt=""
                                  onClick={(e) => {
                                    prevSlide(e);
                                  }}
                                />
                              )}
                            </div>
                            <div className="rightPdfArrow">
                              {numPages - 1 === index || (
                                <img
                                  src={SocialPdfRightArrow}
                                  alt=""
                                  onClick={(e) => {
                                    nextSlide(e);
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="showAttachmentsWithCommentListContainer">
                        <div className="showAttachmentsWithCommentListWrapper">
                          <div
                            className="showAttachmentsWithCommentListHeaderContainer"
                            style={{ padding: "0" }}
                          >
                            <div
                              className={` social-details-header ${
                                commentedPost?.postType !== "organisation"
                                  ? "team-community-details-Header"
                                  : ""
                              }`}
                            >
                              {commentedPost?.postType === "organisation" && (
                                <div
                                  className="leftPostHeaderContainer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUserProfileClick(
                                      commentedPost?.employeeId
                                    );
                                  }}
                                >
                                  <div
                                    className={`${
                                      commentedPost?.parantPostEmployee?.image
                                        ? "socialProfilePicContainer"
                                        : "socialCustomProfilePic"
                                    }`}
                                  >
                                    {!commentedPost?.parantPostEmployee
                                      ?.image ? (
                                      getUserInitials(
                                        commentedPost?.parantPostEmployee?.name
                                      )
                                    ) : (
                                      <img
                                        className="socialProfilePic"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleUserProfileClick(
                                            commentedPost?.employeeId
                                          );
                                        }}
                                        src={
                                          process.env.REACT_APP_.BASE_URL +
                                          profileImages +
                                          commentedPost?.parantPostEmployee
                                            ?.image
                                        }
                                        alt="Profile"
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                    )}
                                  </div>

                                  <div className="postMainTextContainer">
                                    <label className="postMainName">
                                      {getDisplayName(
                                        commentedPost?.parantPostEmployee
                                          ?.nameWithPersonNumber
                                      )}
                                    </label>
                                    <label>
                                      {
                                        commentedPost?.parantPostEmployee
                                          ?.roleName
                                      }
                                    </label>
                                    <label className="dateAndViewsContainer">
                                      {
                                        commentedPost?.parantPostEmployee
                                          ?.locationName
                                      }
                                      <span className="postHrTime">
                                        &nbsp; •{" "}
                                        {dateFormat(
                                          commentedPost?.createdOn,
                                          "hh:MM TT | dd mmm yyyy"
                                        )}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              )}
                              {commentedPost?.postType === "community" && (
                                <div
                                  className="leftPostHeaderContainer"
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    onClick={(e) => {
                                      navigate(
                                        `/home/social/community/${commentedPost?.community?.id}`
                                      );
                                    }}
                                    className={`${
                                      commentedPost?.community?.communityImage
                                        ? "socialConnectProfilePicContainer"
                                        : "nestedCommunityCustomProfilePic"
                                    }`}
                                  >
                                    {!commentedPost?.community
                                      ?.communityImage &&
                                    commentedPost?.community?.communityName ? (
                                      getUserInitials(
                                        commentedPost?.community?.communityName
                                      )
                                    ) : (
                                      <img
                                        className="socialProfilePic"
                                        src={
                                          process.env.REACT_APP_.BASE_URL +
                                          communityProfileImages +
                                          commentedPost?.community
                                            ?.communityImage
                                        }
                                        style={{ marginBottom: "8px" }}
                                        alt="Profile"
                                        onError={(e) => {
                                          e.target.src =
                                            SocialCommunityGroupIcon;
                                        }}
                                      />
                                    )}
                                  </div>

                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUserProfileClick(
                                        commentedPost?.employeeId
                                      );
                                    }}
                                    className={`${
                                      commentedPost?.parantPostEmployee?.image
                                        ? "socialProfilePicContainer"
                                        : "socialCustomProfilePicCommunity"
                                    }`}
                                  >
                                    {!commentedPost?.parantPostEmployee
                                      ?.image ? (
                                      getUserInitials(
                                        commentedPost?.parantPostEmployee?.name
                                      )
                                    ) : (
                                      <img
                                        className={`socialProfilePic ${
                                          commentedPost?.postType ===
                                            "community" || "team"
                                            ? "prTeamCommunity"
                                            : ""
                                        }`}
                                        src={
                                          process.env.REACT_APP_.BASE_URL +
                                          profileImages +
                                          commentedPost?.parantPostEmployee
                                            ?.image
                                        }
                                        alt="Profile"
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                    )}
                                  </div>

                                  <div
                                    className="postMainTextContainer"
                                    style={{ marginLeft: "15px" }}
                                    onClick={() => {
                                      navigate(
                                        `/home/social/community/${commentedPost?.community?.id}`
                                      );
                                    }}
                                  >
                                    <label className="postMainName">
                                      {commentedPost?.community?.communityName}
                                    </label>
                                    <label className="dateAndViewsContainer">
                                      Post from{" "}
                                      <span className="nameofposter">
                                        &nbsp;
                                        {getDisplayName(
                                          commentedPost?.parantPostEmployee
                                            ?.nameWithPersonNumber
                                        )}
                                        &nbsp;
                                      </span>
                                      <span className="postHrTime">
                                        •{" "}
                                        {dateFormat(
                                          commentedPost?.createdOn,
                                          "hh:MM TT | dd mmm yyyy"
                                        )}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              )}
                              {commentedPost?.postType === "team" && (
                                <div
                                  className="leftPostHeaderContainer"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={SocialPostTeamIcon}
                                    alt=""
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      marginBottom: "8px",
                                    }}
                                  />
                                  <div
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUserProfileClick(
                                        commentedPost?.employeeId
                                      );
                                    }}
                                    className={`${
                                      commentedPost?.parantPostEmployee?.image
                                        ? "socialProfilePicContainer"
                                        : "socialCustomProfilePicCommunity"
                                    }`}
                                  >
                                    {!commentedPost?.parantPostEmployee
                                      ?.image ? (
                                      getUserInitials(
                                        commentedPost?.parantPostEmployee?.name
                                      )
                                    ) : (
                                      <img
                                        className={`socialProfilePic ${
                                          commentedPost?.postType ===
                                            "community" || "team"
                                            ? "prTeamCommunity"
                                            : ""
                                        }`}
                                        src={
                                          process.env.REACT_APP_.BASE_URL +
                                          profileImages +
                                          commentedPost?.parantPostEmployee
                                            ?.image
                                        }
                                        alt="Profile"
                                        onError={(e) => {
                                          e.target.src = ProfileAvatar;
                                        }}
                                      />
                                    )}
                                  </div>

                                  <div
                                    className="postMainTextContainer"
                                    style={{ marginLeft: "15px" }}
                                  >
                                    <label className="postMainName">Team</label>
                                    <label className="dateAndViewsContainer">
                                      Post from &nbsp;
                                      <span className="nameofposter">
                                        {getDisplayName(
                                          commentedPost?.parantPostEmployee
                                            ?.nameWithPersonNumber
                                        )}
                                      </span>
                                      <span className="postHrTime">
                                        &nbsp; •{" "}
                                        {dateFormat(
                                          commentedPost?.createdOn,
                                          "hh:MM TT | dd mmm yyyy"
                                        )}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {commentedPost?.subType === "kudos" && (
                                  <div className="kudosTypeContainer">
                                    <span>Kudos</span>
                                    <article></article>
                                    <img src={SocialKudosTag} alt="" />
                                  </div>
                                )}
                                {commentedPost?.subType === "birthday" && (
                                  <div className="birthdayTypeContainer">
                                    <span>Birthday</span>
                                    <article></article>
                                    <img src={SocialBirthdayTag} alt="" />
                                  </div>
                                )}
                                {commentedPost?.subType ===
                                  "workanniversary" && (
                                  <div className="workAnnyTypeContainer">
                                    <span>Work Anniversary</span>
                                    <article></article>
                                    <img src={SocialWorkAnnyTag} alt="" />
                                  </div>
                                )}
                                {commentedPost?.subType === "welcome" && (
                                  <div className="newJoiningTypeContainer">
                                    <span>New Joining</span>
                                    <article></article>
                                    <img src={SocialNewJoiningTag} alt="" />
                                  </div>
                                )}
                              </div>
                            </div>
                            <IconButton onClick={backToAnnouncement}>
                              <img src={CloseIcon} alt="" />
                            </IconButton>
                          </div>

                          <div className="showAttachmentsWithCommentListBodyContainer">
                            <div className="showAttachmentsWithCommentListBodyWrapper">
                              <div className="">
                                <p>
                                  <ContentSocialDisplay
                                    content={commentedPost?.content}
                                  />
                                </p>
                              </div>
                            </div>
                            <div
                              className="showAttachmentsWithCommentListActivityContainer"
                              style={{ position: "relative" }}
                            >
                              <DetailsPostLikeDislikeComponent
                                commentedPost={commentedPost}
                                setSharePostData={setSharePostData}
                                setSharePostId={setSharePostId}
                                setLikeId={setLikeId}
                                setTotalLikesWatching={setTotalLikesWatching}
                                setDislikeId={setDislikeId}
                                setTotalDisLikesWatching={
                                  setTotalDisLikesWatching
                                }
                                setCommentedPost={setCommentedPost}
                                setShareDetailsActive={setShareDetailsActive}
                                postData={socialData}
                                setPostData={setSocialData}
                                getData={getData}
                                setSharePostActive={setSharePostActive}
                              />
                            </div>
                            <CommentPost
                              commentedPost={commentedPost}
                              setCommentedPost={setCommentedPost}
                              getData={getData}
                              postData={socialData}
                              setPostData={setSocialData}
                              localStorageCommentId={items}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  handleClose={backToAnnouncement}
                />
                {sharePostActive && (
                  <SharePost
                    sharePostData={sharePostData}
                    sharePostId={sharePostId}
                    getData={getData}
                    setSharePostActive={setSharePostActive}
                    getExtension={getExtension}
                    getAttachments={getAttachments}
                  />
                )}

                {shareDetailsActive && (
                  <ShareDetails
                    setShareDetailsActive={setShareDetailsActive}
                    sharePostId={sharePostId}
                    setSharePostId={setSharePostId}
                  />
                )}
                {totalLikesWatching && (
                  <LikeDislike
                    id={likeId}
                    setTotalLikesWatching={setTotalLikesWatching}
                    totalLikesWatching={totalLikesWatching}
                  />
                )}
                {totalDisLikesWatching && (
                  <LikeDislike
                    id={dislikeId}
                    setTotalDisLikesWatching={setTotalDisLikesWatching}
                    totalDisLikesWatching={totalDisLikesWatching}
                  />
                )}
              </div>
            </>
          }
          handleClose={backToAnnouncement}
        />
      )}
    </div>
  );
}

export default SocialDeepNestetShowFullAttachment;
