import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { SelectFormikMultiString } from "../../../../Components/FormComponent/SelectFormikMultiString";
import { getRequest } from "../../../../Services/axios";
const FolderForms = () => {
  const [option, setOption] = React.useState([]);
  const [parent, setParent] = React.useState([]);
  const [department, setDepartment] = React.useState([]);
  const getOption = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result?.data?.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  const getParent = async () => {
    try {
      const result = await getRequest("Documents/Folder/GetparentFolders");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setParent(listData);
    } catch (error) {
    } finally {
    }
  };
  const GetDepartments = async () => {
    try {
      const result = await getRequest("Department/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setDepartment(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOption();
    getParent();
    GetDepartments();
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          type={"text"}
          name="name"
          placeholder={"Enter Name "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Parent Name"} />
        <SelectForm
          options={parent}
          name="ParentId"
          placeholder={" Select parent "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Departments"} />
        <SelectFormikMultiString
          options={department}
          name="DepartmentIds"
          placeholder={" Select Departments "}
        />
      </div>
      <div style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}>
        Or
      </div>
      <div>
        <LabelCustom labelName={"Employees"} />
        <SelectFormikMultiString
          options={option}
          name="EmployeeIds"
          placeholder={" Select Employees"}
        />
      </div>
    </div>
  );
};
export { FolderForms };
