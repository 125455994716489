import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { newemployeefacilities } from "./NewEmployeeFacilitiesSchemas";
import { NewEmployeeForm } from "./Forms/NewEmployeeForm";

const initialvalue = {
  name: "",
  associatedEmployeeId: "",
  type: "",
};
const CreateNewEmployeeFacilities = (props) => {
  const { state } = useLocation();
  const data = state?.id;

  const [detail, setDetail] = React.useState([]);
  const dispatch = useDispatch();

  const getDetails = async () => {
    try {
      const result = await getRequest(`Facilities/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const postFormData = async (values) => {
    try {
      const result = await postRequest("Facilities/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("Facilities/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create New Employee`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // //console.log("data", detail);
  const onSubmit = async (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
    };
    data ? Edit(edit) : postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      ButtonLabel={
        data
          ? "Update  New Employee Facilities"
          : "Create  New Employee Facilities"
      }
      FormHeading={
        data ? "Edit New Employee Facilities" : "Create New Employee Facilities"
      }
      validationSchema={newemployeefacilities}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detail?.name,
              associatedEmployeeId: detail?.associatedEmployeeId,
              type: detail?.type,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<NewEmployeeForm data={detail} editID={data} />}
    />
  );
};

export { CreateNewEmployeeFacilities };
