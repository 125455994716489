/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { postRequest } from "../../../../Services/axios";
import { format } from "date-fns";
import Nodata from "../../../../Assets/Nodata.svg";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import UploadDetail from "../../../../Components/UploadDetail/UploadDetail";
import { IconButton } from "@mui/material";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
export const MyReviews = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState([]);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState(null);
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  });
  useEffect(() => {
    document.title = `PeopleSol -  My Review `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const getAllListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(`PMSGoalReviews/List`, reqData);
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            return tempData.push({
              ...item.item,
              actions: item?.actions,
              status: item.status.replace(/<[^>]+>/g, ""),
            });
          });
          const clippedArray = tempData.map(
            ({
              createdBy,
              department,
              designation,
              email,
              employeeId,
              toLocation,
              toLocationName,
              ...rest
            }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              pmsName,
              status,
              reviewStartDate,
              reviewEndDate,
              actions,
              createdOn,
              lastUpdatedOn,
              ...rest
            }) => ({
              id,
              pmsName,
              status,
              reviewStartDate,
              reviewEndDate,
              actions,
              createdOn,
              lastUpdatedOn,
              ...rest,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const SubmmitAccept = async (id) => {
    try {
      setLoading(true);
      const result = await postRequest(
        `PMSGoalReviews/SubmitAcceptance/?Id=${id}`
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      getAllListData();
    }
  };

  const location = useLocation();
  useEffect(() => {
    if(props.featureMenu==null) getAllListData();
    else if (location.pathname.toLowerCase().includes("myreview")) getAllListData();
  }, [location.pathname, reqData]);
  if (loading) return <Loader />;
  else
    return (
      <div
      className="fullTableContainer"
      style={{ width: `${props.width}`, height: `${props.height}` }}
    >
      <div className="boxViewContainer ">
        <div className="homeCardsHolderContainer">
          <div className="homeDashboardCardHolder" key={1}>
            {tbodyData?.length ?
              tbodyData?.map((item, index) => {
                return (
                  <div className="dashboardCard internalJobCard">
                    <div className="cardContainer cursor">
                      <div className="headingContainer">
                        <h3
                          style={{
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.pmsName}
                        </h3>
                        <span
                          className="internalJobDateTime"
                          style={{ color: "var(--secondary)" }}
                        >
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                item.status
                              )?.split(",")?.[0],
                              color: getStatusColor(item.status)?.split(
                                ","
                              )?.[1],
                              cursor: "default",
                              padding: "3px 6px",
                              width: "fit-content",
                              borderRadius: "6px",
                            }}
                          >
                            {item.status}
                          </div>
                        </span>
                      </div>
                      <div
                        className="jobDetailContainerHolder"
                        style={{ padding: "7px 0px 23px 11px" }}
                      >
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Review Start Date
                          </label>
                          <label>
                            {item?.reviewStartDate &&
                              format(
                                new Date(item?.reviewStartDate),
                                "dd/MM/yyyy "
                              )}
                          </label>
                        </div>
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Review End Date
                          </label>
                          <label>
                            {item?.reviewStartDate &&
                              format(
                                new Date(item?.reviewEndDate),
                                "dd/MM/yyyy "
                              )}
                          </label>
                        </div>
                    
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Support Document
                          </label>
                          <label>
                            {item?.guideLineDocument !== "null" ? (
                              <UploadDetail
                                filepath={`PmsAppraisalCycles/${item?.guideLineDocument}`}
                              />
                            ) : (
                              "-"
                            )}
                          </label>
                        </div>
                        <div className="jobDetailContainer">
                          <div className="verticalLine"></div>
                          <label className="jobDesignation">
                            Goal Setting Name
                          </label>
                          <label>{item.kpiName}</label>
                        </div>
                
                      </div>
                      <div
                        className="applyJobButtonContainer "
                        style={{ flexWrap: "wrap", amrginLeft: "12px" }}
                      >
                        {item?.actions?.map((itemData, index) => {
                          return (
                            (itemData?.type === "Self Review" ||
                              itemData?.type === "View Details" ||
                              itemData?.type === "Update Review" ||
                              itemData?.type === "Update Review" || itemData?.type === "Submit Acceptance" ||
                              itemData?.type === "View Rating" ) && (
                              <button
                                onClick={() =>  
                                  itemData?.type === "View Details"
                                    ? navigate(
                                        `/pms/performancereview/myreview/details/${itemData?.id}`
                                      )
                                    : itemData?.type === "Submit Acceptance"
                                    ? SubmmitAccept(itemData?.id):itemData?.type === "View Rating"?navigate(
                                      `/pms/performancereview/myreview/summary/${itemData?.id}`,{
                                        state:{item}
                                      })
                                    : navigate(
                                        `/pms/performancereview/myreview/create/${itemData?.id}`
                                      )
                                }
                                className="button primaryButton"
                              >
                                {itemData?.type}
                              </button>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              }) :
              <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{ "No Data Found"}</h4>
            </div>
              }
          </div>
        </div>
      </div>
      <div className="tableFooterContainer">
              <label>Total Record Count: </label>
              <span>{count}</span>
              <SelectCustom
                searchable={false}
                options={noOfRecords}
                dropdownPosition="top"
                values={[recordsSelected]}
                onChange={(e) => paginationChanged(e)}
              />
              <label>Records Per Page</label>
              {currentPage > 1 ? (
                <IconButton
                  onClick={() => pageChangedPrev()}
                  color="primary"
                  className="pageChangerIcon pageChangerIconActive"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className="pageChangerIcon"
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
              {currentPage} - {totalPages}
              {totalPages > currentPage ? (
                <IconButton
                  onClick={() => pageChangedNext()}
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              ) : (
                <IconButton
                  disabled
                  color="primary"
                  className={`pageChangerIcon pageChangerIconNext`}
                >
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                      stroke="#BFBFBF"
                      strokeLinecap="round"
                    />
                  </svg>
                </IconButton>
              )}
            </div>
      </div>
    );
};
