import React, {  } from 'react';
// import "./GoalSetting.css";
import { GoalSettingTab } from './GoalSettingTab';
import { useEffect } from 'react';

const GoalSetting = () => {
    useEffect(() => {
        document.title = `PeopleSol -  Goals Setting `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
            <GoalSettingTab title="Goal Setting"/>
        </div>
    )
}

export { GoalSetting }