import React, { useEffect } from "react";
import BlueInfoIcon from "../../../Assets/BlueInfoIcon.svg";
import { AddRepeatedSectionComp } from "./AddRepeatedSectionComp";
import { RemoveRepeatedSectionComp } from "./RemoveRepeatedSectionComp";
import { useState } from "react";
import { NetIncomeComp } from "../SubmissionComp/NetIncomeComp";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";

const ParticularDetailsComp = ({
  id,
  formValues,
  formFields,
  detailsValues,
}) => {
  useEffect(() => {
    calculateSums();
  }, []);

  const calculateSums = () => {
    const sums = {};
    const keysWithSum = [
      "houseRentPaid",
      "DividendIncome",
      "Ppf",
      "Insurance",
      "investment80C",
      "investment80CCD",
      "investment80D",
      "investment80E",
      "investmentOther",
    ];
    keysWithSum.forEach((key) => {
      if (key in formValues) {
        const value = formValues[key];
        if (Array.isArray(value)) {
  //console.log("first",value)
        return  sums[key] = value.reduce(
            (sum, card) => sum +  Number(card.Value),
            0
          );
        }
      }
    });
  };
  useEffect(() => {
    document.title = `PeopleSol -  Particular Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const rentDetails = [
    { label: "House Rent Paid Per Year", value: "houseRentPaid" },
    {
      label: "Landlord Address Of Property",
      value: "LandLordAddressOfProperty",
    },
    { label: "Landlord Name", value: "tenantName" },
    { label: "Landlord Pan", value: "LandlordPan" },
  ];
  const homeLoanDetails = [
    { label: "House Loan Interest", value: "HouseLoanInterest" },
  ];
  const rentalPropertyDetails = [
    { label: "Rent Received", value: "rentReceived" },
    { label: "Tax Paid", value: "taxPaid" },
  ];
  const getValues = (val, obj) => {
    let matchingValue; // Variable to store the matching value
    if (obj === "incomeOtherThanSalary") {
      if (formValues?.incomeOtherThanSalary?.length > 0) {
        formValues?.incomeOtherThanSalary.forEach((item) => {
          if (val === item.Title) {
            matchingValue = item.Value;
          }
        });

        return matchingValue; // Return the matching value outside the loop
      }
    } else if (obj === "reimbursementSalary") {
      if (formValues?.reimbursementSalary?.length > 0) {
        formValues?.reimbursementSalary.forEach((item) => {
          if (val === item.Title) {
            matchingValue = item.Value;
          }
        });

        return matchingValue; // Return the matching value outside the loop
      }
    } else if (obj === "investment80C") {
      if (formValues?.investment80C?.length > 0) {
        formValues?.investment80C.forEach((item) => {
          if (val === item.Title) {
            matchingValue = item.Value;
          }
        });

        return matchingValue; // Return the matching value outside the loop
      }
    } else if (obj === "investment80CCD") {
      if (formValues?.investment80CCD?.length > 0) {
        formValues?.investment80CCD.forEach((item) => {
          if (val === item.Title) {
            matchingValue = item.Value;
          }
        });

        return matchingValue; // Return the matching value outside the loop
      }
    } else if (obj === "investment80D") {
      if (formValues?.investment80D?.length > 0) {
        formValues?.investment80D.forEach((item) => {
          if (val === item.Title) {
            matchingValue = item.Value;
          }
        });

        return matchingValue; // Return the matching value outside the loop
      }
    } else if (obj === "investment80E") {
      if (formValues?.investment80E?.length > 0) {
        formValues?.investment80E.forEach((item) => {
          if (val === item.Title) {
            matchingValue = item.Value;
          }
        });

        return matchingValue; // Return the matching value outside the loop
      }
    } else if (obj === "investmentOther") {
      if (formValues?.investmentOther?.length > 0) {
        formValues?.investmentOther.forEach((item) => {
          if (val === item.Title) {
            matchingValue = item.Value;
          }
        });

        return matchingValue; // Return the matching value outside the loop
      }
    }

    return null; // Return null if no matching value is found
  };

  function compareValues(formFields, details) {
    const matchingValues = {};

    formFields.forEach((field) => {
      if (field.compName && field.body) {
        const compName = field.compName;
        if (!matchingValues[compName]) {
          matchingValues[compName] = [];
        }

        field.body.forEach((item) => {
          const value = details[item.value];
          if (value !== null && value !== undefined) {
            matchingValues[compName].push({
              label: item.label,
              value: value,
            });
          }
        });
      }
    });
    return matchingValues;
  }
  const isRentDetailsEmpty = rentDetails.every(
    (item) => !formValues[item.value]
  );
  return (
    <>
      {!isRentDetailsEmpty && (
        <div className="particularDetailsViewer">
          <div className="detailsHeadingViewer">
            <label style={{ fontWeight: "500" }} className="headingForm">
              House Rent Details
            </label>
          </div>
          {rentDetails?.map((item) => {
            return (
              <>
                {formValues[item.value] && (
                  <div className="detailsBodyViewer">
                    {item?.label === "Amount" ? (
                      <>
                        <label style={{ marginRight: "auto" }}>
                          {item?.label}
                        </label>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            ₹{formValues[item.value] * 12}
                          </label>
                          <label>{`(₹${formValues[item.value]}/month)`}</label>
                        </div>
                      </>
                    ) : (
                      <>
                        <label style={{ marginRight: "auto" }}>
                          {item?.label}
                        </label>
                        <label>{formValues[item.value]}</label>
                      </>
                    )}
                  </div>
                )}
              </>
            );
          })}
        </div>
      )}
      {(formValues?.HouseLoanInterest || formValues?.HouseLoanPrincipal) && (
        <div className="particularDetailsViewer">
          <div className="detailsHeadingViewer">
            <label style={{ fontWeight: "500" }} className="headingForm">
              Home Loan Details
            </label>
          </div>
          {homeLoanDetails?.map((item) => {
            //console.log("item",item)
            return (
              <>
                {formValues[item.value] && (
                  <div className="detailsBodyViewer">
                    <label style={{ marginRight: "auto" }}>{item?.label}</label>
                    <label>₹{formValues[item.value]}</label>
                  </div>
                )}
              </>
            );
          })}
        </div>
      )}
      {(formValues?.rentReceived || formValues?.taxPaid) && (
        <div className="particularDetailsViewer">
          <div className="detailsHeadingViewer">
            <label style={{ fontWeight: "500" }} className="headingForm">
              Rental Income Details
            </label>
          </div>
          {rentalPropertyDetails?.map((item) => {
            return (
              <>
                {formValues?.rentReceived && formValues?.taxPaid && (
                  <div className="detailsBodyViewer">
                    <label style={{ marginRight: "auto" }}>{item?.label}</label>
                    <label>₹{formValues[item.value]}</label>
                  </div>
                )}
              </>
            );
          })}
          {formValues?.rentReceived && formValues?.taxPaid && (
            <>
              <div className="detailsBodyViewer">
                <label style={{ marginRight: "auto" }}>{"Net Value"}</label>
                <label>
                  ₹
                  {parseFloat(formValues?.rentReceived) -
                    parseFloat(formValues?.taxPaid)}
                </label>
              </div>
              <div className="detailsBodyViewer">
                <label style={{ marginRight: "auto" }}>
                  {"Standard Deduction"}
                </label>
                <label>
                  ₹
                  {(parseFloat(formValues?.rentReceived) -
                    parseFloat(formValues?.taxPaid)) *
                    0.3}
                </label>
              </div>
              <div className="detailsBodyViewer">
                <label style={{ marginRight: "auto" }}>
                  {"Income Loss from House Property"}
                </label>
                <label>
                  ₹
                  {parseFloat(formValues?.rentReceived) -
                    parseFloat(formValues?.taxPaid) -
                    (parseFloat(formValues?.rentReceived) -
                      parseFloat(formValues?.taxPaid)) *
                      0.3}
                </label>
              </div>
            </>
          )}
        </div>
      )}
      {detailsValues ? (
        <>
          {formFields?.map((x) => {
            const matchingValues = compareValues(formFields, detailsValues);
            const compName = x.compName;
            const values = matchingValues[compName];

            if (values && values.length > 0) {
              return (
                <div className="particularDetailsViewer">
                  <div className="detailsHeadingViewer">
                    <label style={{ fontWeight: "500" }}>{x?.heading}</label>
                  </div>
                  {x?.body?.map((item) => {
                    const matchingValue = values.find(
                      (value) => value.label === item.label
                    );

                    if (matchingValue) {
                      return (
                        <div className="detailsBodyViewer">
                          <label style={{ marginRight: "auto" }}>
                            {item.label}
                          </label>
                          <label>₹{matchingValue.value}</label>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              );
            } else {
              return null;
            }
          })}
        </>
      ) : (
        <>
          {formFields?.map((x) => {
            const matchingValues = x?.body
              ?.map((item) => getValues(item.value, x?.compName))
              .filter(Boolean);

            if (matchingValues.length === 0) {
              return null; // Hide the div when no matching values are present
            }

            return (
              <div className="particularDetailsViewer">
                <div className="detailsHeadingViewer">
                  <label style={{ fontWeight: "500" }} className="headingForm">
                    {x?.heading}
                  </label>
                </div>
                {x?.body?.map((item) => {
                  return (
                    <>
                      {getValues(item.value, x?.compName) && (
                        <div className="detailsBodyViewer">
                          <label style={{ marginRight: "auto" }}>
                            {item.label}
                          </label>
                          <label>₹{getValues(item.value, x.compName)}</label>
                        </div>
                      )}
                    </>
                  );
                })}
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <LabelCustom clas={'label-tax'} labelName={"Total Amount"} />
                  <LabelCustom clas={'label-tax'}labelName={":"} />
                  <LabelCustom clas={'label-tax'}
                    labelName={
                      <span
                        style={{ color: "var(--primary)", fontWeight: "500" }}
                      >
                        {formValues[x.compName]
                          ?.reduce((sum, card) => sum + Number(card.Value), 0)
                          .toFixed(2)}
                      </span>
                    }
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
      {/* {id === 3 ? (
        <div className="computeTaxContainer">
          <label style={{ fontWeight: "500" }}>Total Investment Declaration Amount</label>
          <div className="computeButton cursor" style={{ display: "flex" }}>
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.51362 9.97249V0.63916M8.51362 9.97249L5.40251 6.86138M8.51362 9.97249L11.6247 6.86138M0.73584 11.528L1.21884 13.4608C1.30296 13.7974 1.49715 14.0961 1.77054 14.3096C2.04394 14.5231 2.38085 14.6391 2.72773 14.6392H14.2995C14.6464 14.6391 14.9833 14.5231 15.2567 14.3096C15.5301 14.0961 15.7243 13.7974 15.8084 13.4608L16.2914 11.528"
                stroke="#1296B0"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            {Object.keys(sums).map((key) => (
              <p key={key}>
                 {sums[key]}
              </p>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </>
  );
};

export { ParticularDetailsComp };
