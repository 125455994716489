import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { AllApprovalException } from "../Components/ExceptionApproval/AllApprovalException";
import { PendingApprovalException } from "../Components/ExceptionApproval/PendingApprovalException";
import { ApprovedException } from "../Components/ExceptionApproval/ApprovedException";
import { HoldApprovalException } from "../Components/ExceptionApproval/HoldApprovalException";
import { RejectedApprovalException } from "../Components/ExceptionApproval/RejectedApprovalException";
import { useEffect } from "react";

const ApprovalException = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Exception`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Hold
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalException
            title="Exception"
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <ApprovedException
            title="Exception"
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 2 && (
        <HoldApprovalException
          title="Exception"
          getApprovalBadge={getApprovalBadge}
        />
      )}
      {tabIndex === 3 && (
        <RejectedApprovalException
          props="Exception"
          getApprovalBadge={getApprovalBadge}
        />
      )}
      {tabIndex === 4 && (
        <>
          <AllApprovalException
            title="Exception"
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalException };
