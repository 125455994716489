import { FieldArray } from "formik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { Table } from "@mui/material";
import * as React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "styled-components";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const StyledTableCell = styled(TableCell)({
  padding: "5px",
  border: "1px solid #bdbdbd5c",
});
export const StyledTableCont = styled(TableCell)({
  overflow: "revert !important",
});
export const TeamsForm = ({ data, editID }) => {
  const [locationValuesArray, setLocationValuesArray] = React.useState({});
  const [listData, setListData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [option, setOption] = React.useState([]);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParam, setSearchParam] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    // debugger;
    if (listData?.length < totalListLength) getOption([]);
  };

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;

  const [location, setLocation] = React.useState([]);
  const getLocation = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };

  React.useEffect(() => {
    if (searchParam) {
      getOption([], searchParam);
    }
  }, [searchParam]);
  React.useEffect(() => {
    if (editID) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);
  React.useEffect(() => {
    if (editID) {
      setPage(0);
      const idArray =
        data?.teamsMembers &&
        data?.teamsMembers?.map((item) => item?.employeeId);
      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data?.teamsMembers]);

  React.useEffect(() => {
    setListData([]);
    getLocation();
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);

  const getLocationValues = (index) => {
    return locationValuesArray[index];
  };

  React.useEffect(() => {
    if (location.length >= 0) {
      data?.teamsMembers?.map((item, index) => {
        let loactionIdArray = [];
        item.hrHelpdeskTeammemberlocations.map((loction) => {
          let tempVal = location?.filter(
            (options) => options?.value === loction.locationId
          );
          return loactionIdArray.push(tempVal[0]);
        });
        setLocationValuesArray((prev) => {
          return { ...prev, [index]: loactionIdArray };
        });
        return null;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    document.title = `PeopleSol - Teams Forms`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const dispatch = useDispatch();
  return (
    <div style={{ paddingBottom: " 65px" }}>
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>

      <div className="repeatedSectionContainer">
        <FieldArray name="teamsMembers">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { teamsMembers } = values;

            return (
              <StyledTableCont className="">
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" S. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Employee"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Location"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={" Level"} mandatory={true} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        Add/Remove
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {teamsMembers.map((teamsMember, index) => (
                    <TableBody key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <SelectForm
                          placeholder={"Select Employee"}
                          name={`teamsMembers[${index}].employeeId`}
                          onMenuScrollToBottom={handleScrollToBottom}
                          // onMenuScrollToBottom={handleScroll}
                          options={searchParam ? option : listData}
                          onInputChange={(e) => setSearchParam(e)}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <SelectFormikMulti
                          values={getLocationValues(index)}
                          name={`teamsMembers[${index}].locationIds`}
                          options={location}
                          placeholder={" Select Locations"}
                        />
                        {/* <div className='form-error'>{errors?.teamsMembers?.length>0?(errors?.teamsMembers[index].locationIds):null} </div> */}
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputCustomFormik
                          type="number"
                          step="0.01"
                          name={`teamsMembers[${index}].level`}
                          placeholder={" Enter Level  "}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            teamsMembers.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              employeeId: "",
                              level: "",
                              locationIds: [],
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
