import React from "react";

import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import { useEffect } from "react";

export const CreateLateComingComp = () => {
  useEffect(() => {
    document.title = `PeopleSol - Create Late Coming`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Late Coming Date"} mandatory={true} />
        <DatePickerFormiks name={"date"} />
      </div>
      <div>
        <LabelCustom labelName={"Late Coming Time"} mandatory={true} />
        <TimeCustomFormik name="time" placeholder="Select Time" />
      </div>
      <div>
        <LabelCustom labelName={"Late Coming Reason"} mandatory={true} />
        <TextAreaFormik
          maxLength={500}
          name="reason"
          placeholder={"Enter the reason"}
        />
      </div>
    </div>
  );
};
