import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { format } from "date-fns";
import dateFormat from "dateformat";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";

export const AllocationAsset = ({ id, data, eid, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const alllocate = async (val) => {
    try {
      setLoading(true);
        const result=await postRequest(`ITAssets/allocate`, val);

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose()
      data()
    }
  };
  const getFacility = async () => {
      try {
        const result = await getRequest(`ITAssets/GetAssetType?Id=${eid}`);
        let listData = [];
        result.data.results.map((item) => {
          listData.push({ label: item.text, value: item.id });
        });
        setEmployeeList(listData);
      } catch (error) {
        
      
      } finally {
      }
    };
    useEffect(()=>{
        getFacility()
    },[])
    const onSubmit = async (values) => {
        
        const res = {
            AssetId:values?.AssetId,
            id: id,
            employeeId:color
        };
      alllocate(res)
  };
  useEffect(() => {
    document.title = `PeopleSol - Allocation Asset`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <Formik initialValues={{ AssetId: "1" }} onSubmit={onSubmit}>
      <Form>
        <LabelCustom labelName={"Asset Reference Number"} mandatory={true} />
        <SelectForm name={"AssetId"} placeholder="Select Asset Reference Number"   options={employeeList} />
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ color: "white" }}
          >
            {"Allocate Asset"}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
