import React from "react";
import { useEffect } from "react";

const DeletePopup = ({ onClose, message, onClick, cancel }) => {
  // useEffect(()=>{
  //   document.title = `PeopleSol - Delete`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // })

  return (
    <div className="alertContainer">
      <h2 style={{ fontFamily: "Poppins" }}>
        {cancel ? "Confirm Cancel" : "Confirm Delete"}
      </h2>
      <p style={{ fontFamily: "Poppins", fontSize: "14px" }}>{message}</p>
      <div className="createFootCust" style={{ backgroundColor: "white" }}>
        <button
          className="button secondaryButton"
          onClick={onClose}
          style={{
            border: "1px solid var(--primary)",
            borderRadius: "4px",
          }}
        >
          Cancel
        </button>
        <button
          className="deletebtn"
          onClick={() => {
            onClick();
            onClose();
          }}
        >
          {cancel ? "Confirm" : "Delete"}
        </button>
      </div>
    </div>
  );
};

export default DeletePopup;
