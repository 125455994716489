import React from 'react'
import { useEffect } from 'react';

const RemoveRepeatedSectionComp = ({clickHandler, id, marginTop}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Remove Repeated Section`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="addTaskIconHolder cursor" style={{height:'24px', width:'25px', margin:'0', marginTop:marginTop}} onClick={() => clickHandler(id)}>
        <label className='addTaskIcon' style={{background:'rgb(253, 210, 210)'}}>
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="12.1173" y1="6.22746" x2="0.875186" y2="6.22746" stroke="red" stroke-width="1.27035" stroke-linecap="round"/>
        </svg>
        </label>
    </div>
  )
}

export {RemoveRepeatedSectionComp}
