
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { StepsHierarchy } from "../../../Components/FormComponent/StepsHierarchy";
import { WorkflowStepsComp } from "../../../Components/FormComponent/WorkflowStepsComp";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailAssetMaterialIt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Materials/Details/${selectedId}`);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Details Asset Material`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Asset Material Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>

        <div className="moduleMasterDetailsPageBody">
          <DetailsStep detailTitle={"Asset Materials"} Details={eventtData?.name} />
          {/* <DetailsStep detailTitle={"Asset Category"} Details={eventtData?.material?.map((item)=>(<p>{item?.name}</p>))} /> */}
          <DetailsStep
            detailTitle={"Created On"}
            Details={eventtData?.createdOn&&format(
              new Date(eventtData?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={eventtData?.createdOn&&format(
              new Date(eventtData?.lastUpdatedOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />


          

          
          
           
          

          
          
          

 
      
        </div>
      </div>
    </div>
  );
};

export { DetailAssetMaterialIt };
