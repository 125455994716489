import React, { useEffect } from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { AllRequestCompanyHospitality } from "../Components/CompanyHospitalityApproval/AllRequestCompanyHospitality";
import { CompanyHospitalityAccepted } from "../Components/CompanyHospitalityApproval/CompanyHospitalityAccepted";
import { RejectedCompanyHospitality } from "../Components/CompanyHospitalityApproval/RejectedCompanyHospitality";
import { PendingApprovalCompanyHospitality } from "../Components/CompanyHospitalityApproval/PendingApprovalCompanyHospitality";

const ApprovalCompanyHospitality = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Accepted
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalCompanyHospitality
            title={"Company Hospitality"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <>
          <CompanyHospitalityAccepted
            title={"Company Hospitality"}
            getApprovalBadge={getApprovalBadge}
          />{" "}
        </>
      )}
      {tabIndex === 2 && (
        <>
          {" "}
          <RejectedCompanyHospitality
            title={"Company Hospitality"}
            getApprovalBadge={getApprovalBadge}
          />{" "}
        </>
      )}

      {tabIndex === 3 && (
        <>
          <AllRequestCompanyHospitality
            title={"Company Hospitality"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalCompanyHospitality };
