import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { postRequestForm } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { CandidateDoc } from "./Forms/CandidateDoc";
import { onBoardingDocSchema } from "./OnboardingDocumentsSchemas";
import { useEffect } from "react";

const initialvalue = {
  name: "",
  upload: "",
  Type: "",
};
const CreateOnboardingDocuments = (props) => {
  const { state } = useLocation();
  const data = state?.selectedRecord;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const postFormData = async (create) => {
    try {
      setLoading(true);
      const result = await postRequestForm(
        "CandidateOnboardingRequiredDocuments/Create",
        create
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequestForm(
        "CandidateOnboardingRequiredDocuments/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Onboarding Documents`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = (values) => {
    const formData = new FormData();
    if (typeof values?.upload === "object")
      formData.append("upload", values?.upload);
    formData.append("Id", data ? data.Id : 0);
    formData.append("name", values.name);
    formData.append("Type", values.Type);
    formData.append("isActive", true);
    data ? Edit(formData) : postFormData(formData);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      FormHeading={
        data ? "Edit Onboarding Document" : "Create Onboarding Document"
      }
      ButtonLabel={
        data ? "Update Onboarding Document" : "Create Onboarding Document"
      }
      validationSchema={onBoardingDocSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              upload: `CandidateOnboardingsDocuments/${data?.Document}`,
              name: data?.Name,
              Type: data?.Type,
            }
          : initialvalue
      }
      returnPage={returnPage}
      loading={loading}
      propsDiv={<CandidateDoc />}
    />
  );
};

export { CreateOnboardingDocuments };
