import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../../Services/axios";
import { showToast } from "../../../../Features";
import { useEffect } from "react";
export const ArrearForm = ({
  handleClose,
  type,
  id,
  month,
  PayrollEmployeeId,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    ArrearDays: Yup.string()
      .max(499, "Days cannot be more than 500 characters")
      .required("Please Enter Days"),
  });
  //alistname is crucial for company Hospitality transaction dont remove
  const AddArrear = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(`PreparePayroll/AddArrear`, values);
      if (result) {
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      handleClose();

      setLoading(false);
    }
  };
  const lopDays = async (values) => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PreparePayroll/GetLopDays?PayrollEmployeeId=${PayrollEmployeeId}`
      );
      if (result) {
        console.log("res", result?.data);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    lopDays();
  }, []);

  const onSubmit = async (values) => {
    const payload = {
      ...values,
      id: id,
      LOPMonth: month,
      PayMonth: month,
      PayrollEmployeeId: PayrollEmployeeId,
    };
    AddArrear(payload);
    console.log("pay", payload);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{ ArrearDays: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <LabelCustom
                labelName={type === "Add Arrear" ? "Add Arrear Days" : ""}
                mandatory={true}
              />
              <InputCustomFormik
                type="number"
                maxLength={500}
                placeholder="Please Enter Days"
                name="ArrearDays"
              />
              <div className="createFootCust">
                <button
                  type="button"
                  className="button secondaryButton"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ background: "var(--primary)", color: "white" }}
                >
                  {type == "RejectAll" ? "Reject All" : type}
                </button>
              </div>
            </Form>
          </Formik>
        </>
      )}
    </>
  );
};
