import React from 'react'
import { useEffect } from 'react';

const OptionalLeaveComp = ({date, title, desc}) => {
  useEffect(() => {
    document.title = `PeopleSol - Optional Leave`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="attendanceContentContainer" style={{backgroundColor:`#FFF2F0`}}>
       <div className="dateDisplay">{date ?date: ""}</div>
     <div className="horizontalLinePrimary calendarLine" style={{backgroundColor:`#FB7158`}}></div>

        <div className='attendanceContentStatus' style={{color:`#FB7158`}} >{title ? title: ""}</div>
        <div style={{display:'flex'}}>
           
        <label className='attendanceIn' style={{color:'black', fontSize:'13px'}}>{desc?desc:""}</label>
            </div>
        
        </div>
  )
}

export {OptionalLeaveComp}
