import React, { useEffect } from "react";
import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../../Services/axios";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";

const transfer = [
  { label: "Organization Requirement", value: "Business" },
    { label: "Self Nomination", value: "Self Nomination" },
];
const CreateDeputationComp = ({ formValues, lastDate }) => {
  const [location, setLocation] = useState([]);

  const getLocation = async () => {
    try {
      const result = await getRequest("RelocationRequests/GetAreas");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getLocation();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Create Deputaion `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Deputation For"} mandatory={true} />
          <SelectForm
            name={"transferType"}
            options={transfer}
            placeholder={" Select  Deputation Type"}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"Employee"} mandatory={true} />
          <SelectForm
          isDisabled
            name={"employeeId"}
            options={employeeList}
            placeholder={" Select  Employee "}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Deputed Location  "} mandatory={true} />
          <SelectForm
            name={"toLocation"}
            options={location}
            placeholder={" Select  Location "}
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Deputation Effective Date "}
            mandatory={true}
          />
          <DatePickerFormiks
            name={"transferEffectiveDate"}
            options={transfer}
            placeholder={" Select Date "}
          />
        </div>
        {lastDate && (
          <div>
            <LabelCustom labelName={"End Date"} mandatory={true} />
            <DatePickerFormiks
            disabled
              selected={lastDate}
              name={"endDate"}
              placeholder={" Select Date "}
            />
          </div>
        )}
        <div>
          <LabelCustom labelName={"Reason for Deputation  "} mandatory={true} />
          <TextAreaFormik
            maxLength={500}
            name={"reason"}
            options={transfer}
            placeholder={" Enter Reason  "}
          />
        </div>
      </div>
    </>
  );
};

export { CreateDeputationComp };
