import React from "react";
import Social from "../../Assets/PeopleSolLogo.svg";
import "./CandidateNavigation.css";
import { useDispatch, useSelector } from "react-redux";
import { closeMenu, loggedOut, OpenMenu } from "../../Features";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const CandidateNavigation = () => {
  const { theme } = useSelector((state) => state.theme);
  const { menu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const getCurrentTitle = () => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("profile")) {
      return "My Profile";
    } else if (currentpath.includes("jobs")) {
      return "My Jobs";
    } 
    
    else {
      return "My Profile";
    } 

  };

  // useEffect(()=>{
  //   document.title = `PeopleSol - Candidate Navigation`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // })
  


  return (
    <div
      className={`navigation ${theme === "light" ? "nav-light" : "nav-dark"}`}
    >
      
      <div className="navi">
        <img
          src={Social}
          alt="logo"
          className="nav-logo cursor"
          onClick={() => {
            navigate("/candidate/profile", { replace: true });
            
          }}
        />
        <div className="navHeading">
          <label>{getCurrentTitle()}</label>
        </div>

       
        

        <div
          className="actionButton buttonWidth navHeadButton"
          onClick={() => {
            navigate("/candidate/jobs", { replace: true });
            
          }}
        >
          <div className="actionIconText">
            {getCurrentTitle() === "My Jobs" ? (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8385 0.962264C13.3317 0.620608 12.6683 0.620608 12.1615 0.962264L10.0426 2.39074C9.95692 2.44851 9.85544 2.47831 9.75212 2.47603L7.19729 2.41989C6.58624 2.40646 6.02815 2.76512 5.78653 3.32653L4.77628 5.67381C4.73543 5.76873 4.66617 5.84867 4.57803 5.90261L2.39841 7.23663C1.8771 7.55569 1.60152 8.15914 1.70177 8.76206L2.12093 11.2829C2.13788 11.3848 2.12283 11.4895 2.07785 11.5826L0.965461 13.8832C0.699406 14.4334 0.793819 15.0901 1.20412 15.5431L2.91961 17.4371C2.98898 17.5137 3.03292 17.6099 3.04537 17.7125L3.35339 20.2494C3.42706 20.8561 3.8615 21.3575 4.45158 21.5167L6.91874 22.1826C7.01851 22.2096 7.10749 22.2667 7.17343 22.3463L8.80405 24.3139C9.19406 24.7845 9.83059 24.9714 10.4131 24.7864L12.8486 24.0127C12.9471 23.9814 13.0529 23.9814 13.1514 24.0127L15.5869 24.7864C16.1694 24.9714 16.8059 24.7845 17.1959 24.3139L18.8266 22.3463C18.8925 22.2667 18.9815 22.2096 19.0813 22.1826L21.5484 21.5167C22.1385 21.3575 22.5729 20.8561 22.6466 20.2494L22.9546 17.7125C22.9671 17.61 23.011 17.5137 23.0804 17.4371L24.7959 15.5431C25.2062 15.0901 25.3006 14.4334 25.0345 13.8832L23.9222 11.5826C23.8772 11.4895 23.8621 11.3848 23.8791 11.2829L24.2982 8.76206C24.3985 8.15914 24.1229 7.55569 23.6016 7.23663L21.422 5.90261C21.3338 5.84867 21.2646 5.76873 21.2237 5.67381L20.2135 3.32653C19.9718 2.76512 19.4138 2.40646 18.8027 2.41989L16.2479 2.47603C16.1446 2.47831 16.0431 2.44851 15.9574 2.39074L13.8385 0.962264Z"
                  stroke="#1296B0"
                />
                <path
                  d="M7.6665 11.6668L11.6665 16.3335L19.6665 10.3335"
                  stroke="#F7941D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            ) : (
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.8385 0.962264C13.3317 0.620608 12.6683 0.620608 12.1615 0.962264L10.0426 2.39074C9.95692 2.44851 9.85544 2.47831 9.75212 2.47603L7.19729 2.41989C6.58624 2.40646 6.02815 2.76512 5.78653 3.32653L4.77628 5.67381C4.73543 5.76873 4.66617 5.84867 4.57803 5.90261L2.39841 7.23663C1.8771 7.55569 1.60152 8.15914 1.70177 8.76206L2.12093 11.2829C2.13788 11.3848 2.12283 11.4895 2.07785 11.5826L0.965461 13.8832C0.699406 14.4334 0.793819 15.0901 1.20412 15.5431L2.91961 17.4371C2.98898 17.5137 3.03292 17.6099 3.04537 17.7125L3.35339 20.2494C3.42706 20.8561 3.8615 21.3575 4.45158 21.5167L6.91874 22.1826C7.01851 22.2096 7.10749 22.2667 7.17343 22.3463L8.80405 24.3139C9.19406 24.7845 9.83059 24.9714 10.4131 24.7864L12.8486 24.0127C12.9471 23.9814 13.0529 23.9814 13.1514 24.0127L15.5869 24.7864C16.1694 24.9714 16.8059 24.7845 17.1959 24.3139L18.8266 22.3463C18.8925 22.2667 18.9815 22.2096 19.0813 22.1826L21.5484 21.5167C22.1385 21.3575 22.5729 20.8561 22.6466 20.2494L22.9546 17.7125C22.9671 17.61 23.011 17.5137 23.0804 17.4371L24.7959 15.5431C25.2062 15.0901 25.3006 14.4334 25.0345 13.8832L23.9222 11.5826C23.8772 11.4895 23.8621 11.3848 23.8791 11.2829L24.2982 8.76206C24.3985 8.15914 24.1229 7.55569 23.6016 7.23663L21.422 5.90261C21.3338 5.84867 21.2646 5.76873 21.2237 5.67381L20.2135 3.32653C19.9718 2.76512 19.4138 2.40646 18.8027 2.41989L16.2479 2.47603C16.1446 2.47831 16.0431 2.44851 15.9574 2.39074L13.8385 0.962264Z"
                  stroke="#1296B0"
                />
                <path
                  d="M7.6665 11.6668L11.6665 16.3335L19.6665 10.3335"
                  stroke="#F7941D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            )}
            <p
              className={`actionText ${
                getCurrentTitle() === "My Jobs" && "actionTextActive"
              }`}
            >
            My Jobs
            </p>
            <div className="badge approvalBadge">
              <label>100</label>
            </div>
          </div>
          {getCurrentTitle() === "My Jobs" && <div className="line"></div>}
        </div>

        <div
          className="actionButton buttonWidth"
          onClick={() => {
            navigate("/candidate/profile", { replace: true });
            
          }}
        >
          <div className="actionIconText">
            {getCurrentTitle() === "My Profile" ? (
              <img
                src="https://picsum.photos/35/35"
                alt="PeopleBack"
                className="actionIcon"
              />
            ) : (
              <img
                src="https://picsum.photos/35/35"
                alt="ProfileBack"
                className="actionIcon"
              />
            )}
            <p
              className={`actionText ${
                getCurrentTitle() === "My Profile" && "actionTextActive"
              }`}
            >
              My Profile
            </p>
          </div>
          {getCurrentTitle() === "My Profile" && <div className="line"></div>}
        </div>

        <div
              className="actionButton buttonWidth cursor"
              onClick={() => dispatch(loggedOut())}
              style={{alignItems: 'center',paddingBottom: '6px'}}
            >
              
              <svg
                className="mainMenuEndTileIcon"
                width="19"
                height="17"
                viewBox="0 0 19 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8063 6V2.17069C11.8063 1.55821 11.3098 1.06171 10.6973 1.06171H2.60276C1.99028 1.06171 1.49377 1.55821 1.49377 2.17069V14.9527C1.49377 15.5652 1.99028 16.0617 2.60276 16.0617H10.6973C11.3098 16.0617 11.8063 15.5652 11.8063 14.9527V11"
                  stroke="#1296B0"
                  strokeLinecap="round"
                />
                <path
                  className="logoutArrow"
                  d="M7.11859 8.06174C6.84245 8.06174 6.61859 8.28559 6.61859 8.56174C6.61859 8.83788 6.84245 9.06174 7.11859 9.06174L7.11859 8.06174ZM18.3415 8.91529C18.5368 8.72003 18.5368 8.40345 18.3415 8.20818L15.1595 5.0262C14.9643 4.83094 14.6477 4.83094 14.4524 5.0262C14.2572 5.22147 14.2572 5.53805 14.4524 5.73331L17.2808 8.56174L14.4524 11.3902C14.2572 11.5854 14.2572 11.902 14.4524 12.0973C14.6477 12.2925 14.9643 12.2925 15.1595 12.0973L18.3415 8.91529ZM7.11859 9.06174L17.988 9.06174L17.988 8.06174L7.11859 8.06174L7.11859 9.06174Z"
                  fill="#F7941D"
                />
              </svg>
              
              <p
              className={`actionText ${
                getCurrentTitle() === "Logout" && "actionTextActive"
              }`}
            >
              Logout
            </p>            
            </div>


      </div>
    </div>
  );
};

export { CandidateNavigation };
