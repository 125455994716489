import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { showToast } from "../../../../Features";
import { useEffect } from "react";

function EducationDetails(props) {
  useEffect(() => {
    document.title = `PeopleSol - Education Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const { educationOptions, dispatch} = props;
  return (
    <>
      <div>
        <FieldArray name="employeeEducations">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { employeeEducations } = values;
            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"S. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Education Level"} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Specialisation"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"College/School"} />
                      </StyledTableCell>
                      {/* <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Year Of Passing (From year)"}
                        />
                      </StyledTableCell> */}
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Year Of Passing"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Grade / %"} />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {employeeEducations?.map((employeeEducationss, index) => (
                    <TableBody key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      {/* 2nd column */}
                      <StyledTableCell>
                        <SelectForm
                          name={`employeeEducations[${index}].employeeEducations`}
                          options={educationOptions}
                        />
                      </StyledTableCell>
                      {/* 3rd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          
                          maxLength={150}
                          type="text"
                          name={`employeeEducations[${index}].degree`} //  Not present in the  list
                          placeholder={"Enter Specialisation"}
                        />
                      </StyledTableCell>
                      {/* 4th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          
                          maxLength={150}
                          type="text"
                          name={`employeeEducations[${index}].college`} //  Not present in the  list
                          placeholder={"Enter College/School Name"}
                        />
                      </StyledTableCell>
                      {/* 5th column */}
                      {/* <StyledTableCell>
                        <InputCustomFormik
                          minLength={4}
                          maxLength={4}
                          type="number"
                           step="0.01"
                          // eslint-disable-next-line no-script-url
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                          name={`employeeEducations[${index}].yearofPassingFrom`} //  Not present in the  list
                          placeholder={" Select  "}
                        />
                      </StyledTableCell> */}
                      {/* 6th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          minLength={4}
                          maxLength={4}
                          type="number"
                           step="0.01"
                          // eslint-disable-next-line no-script-url
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          name={`employeeEducations[${index}].yearofPassingTo`} //  Not present in the  list
                          placeholder={"Enter Passing Year"}
                        />
                      </StyledTableCell>
                      {/* 7th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          minLength={1}
                          maxLength={6}
                          type="text"
                          name={`employeeEducations[${index}].grade`}
                          placeholder={"Grade / %"}
                        />
                      </StyledTableCell>
                      {/* 8th column */}

                      <StyledTableCell sx={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className="remove-btn"
                        onClick={() =>
                          employeeEducations.length > 1
                            ? remove(index)
                            : dispatch(
                                showToast({
                                  text: "Atleast One Item is Required ",
                                  severity: "error",
                                })
                              )
                        }
                      >
                        -
                      </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              employeeEducations: "",
                              degree: "",
                              college: "",
                              yearofPassingFrom: "",
                              yearofPassing: "",
                              grade: "",
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </>
  );
}

export { EducationDetails };
