import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";
import {
  getAttachments,
  getExtension,
  imgArray,
} from "../../../../../Utilities/socialAllFunctions";
import "./ShowFullAttachments.css";
import "./Connect.css";

import dateFormat from "dateformat";
import { bannerPostImages } from "../../../../../Utilities/profileAndUploadImages";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { useNavigate, useParams } from "react-router-dom";
import ContentSocialDisplay from "./ContentSocialDisplay";

function NoticeBoardPostDetails() {
  const [commentedPost, setCommentedPost] = useState(null);
  const [isImages, setIsImages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const [attachment, setAttachment] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postid } = useParams();
  const getData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`Banners/Details/${postid}`);
      setCommentedPost(result?.data);
      setAttachment(getAttachments(result?.data?.image));
      findAttach(getAttachments(result?.data?.image));
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const backToAnnouncement = () => {
    setAttachment(null);
    setCommentedPost(null);
    navigate("/home/social/noticeboard");
  };

  const findAttach = (attachment) => {
    if (attachment) {
      let x = getExtension(attachment[0]);
      if (imgArray.includes(x)) {
        setIsImages(true);
      }
      if (x !== null) {
        setNumPages(attachment?.length);
      }
    }
  };
  const nextSlide = (e) => {
    // e.stopPropagation();
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  };
  const prevSlide = (e) => {
    // e.stopPropagation();
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  useEffect(() => {
    const keyHandler = (event) => {
      if (event.code === "ArrowRight") {
        nextSlide();
      } else if (event.code === "ArrowLeft") {
        prevSlide();
      }
    };
    document.addEventListener("keydown", keyHandler, false);
    return () => document.removeEventListener(keyHandler);
  }, []);
  useEffect(() => {
    getData();
  }, [postid]);
  useEffect(() => {
    return () => {
      setCommentedPost(null);
    };
  }, [postid]);
  return (
    <div className="showAttachmentsWithCommentContainer">
      {!loading && commentedPost && (
        <Popup
          firstClassName="showAttachmentsWithCommentPopupbox1"
          secondClassName="showAttachmentsWithCommentPopupbox2"
          createMyHead="showAttachmentsWithCommentCreateHead"
          content={
            <>
              <div className="showAttachmentsWithCommentContainer">
                <Popup
                  firstClassName="showAttachmentsWithCommentPopupbox1"
                  secondClassName="showAttachmentsWithCommentPopupbox2"
                  createMyHead="showAttachmentsWithCommentCreateHead"
                  content={
                    <>
                      <div className="showAttachmentsWithCommentWrapper">
                        {isImages && (
                          <div className="showAttachmentsWithCommentImgContainer">
                          <div >
                            <img
                              src={
                                process.env.REACT_APP_.BASE_URL +
                                bannerPostImages +
                                attachment[index]
                              }
                              alt=""
                            />

                          </div>
                          </div>
                        )}

                        {!isImages && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                              color: "#bfbfbf",
                            }}
                          >
                            No Attachment Found!
                          </div>
                        )}
                        {attachment !== null && attachment?.length > 0 && (
                          <>
                            <div className="leftPdfArrow">
                              {index === 0 || (
                                <img
                                  src={SocialPdfLeftArrow}
                                  alt=""
                                  onClick={(e) => {
                                    prevSlide(e);
                                  }}
                                />
                              )}
                            </div>
                            <div className="rightPdfArrow">
                              {numPages - 1 === index || (
                                <img
                                  src={SocialPdfRightArrow}
                                  alt=""
                                  onClick={(e) => {
                                    nextSlide(e);
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      <div className="showAttachmentsWithCommentListContainer">
                        <div className="showAttachmentsWithCommentListWrapper">
                          <div className="showAttachmentsWithCommentListHeaderContainer">
                            <div className="leftPostHeaderContainer">
                              <div className="socialPostTitleAndTimeContainer">
                                <label>{commentedPost?.title ?? "--"}</label>
                                <label>
                                  <div className="announcementViewsContainer">
                                    <span className="postHrTime">
                                      {" "}
                                      •{" "}
                                      {dateFormat(
                                        commentedPost?.createdOn,
                                        "hh:MM TT | dd mmm yyyy"
                                      )}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            </div>
                            <IconButton onClick={backToAnnouncement}>
                              <img src={CloseIcon} alt="" />
                            </IconButton>
                          </div>

                          <div className="showAttachmentsWithCommentListBodyContainer">
                            <div className="showAttachmentsWithCommentListBodyWrapper">
                              <div className="">
                                <ContentSocialDisplay
                                  content={commentedPost?.description}
                                />
                              </div>
                            </div>
                            <div
                              className="showAttachmentsWithCommentListActivityContainer"
                              style={{ position: "relative" }}
                            >
                              <div
                                className="totalPostActivity"
                                style={{
                                  display: "flex",
                                  alignItems: "end",
                                  justifyContent: "space-between",
                                  fontSize: "12px",
                                  color: "#bfbfbf",
                                  padding: "10px 20px",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  handleClose={backToAnnouncement}
                />
              </div>
            </>
          }
          handleClose={backToAnnouncement}
        />
      )}
    </div>
  );
}

export default NoticeBoardPostDetails;
