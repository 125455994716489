import React,{useEffect} from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { getRequest } from "../../../Services/axios";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { SelectForm } from "../../../Components/FormComponent/SelectForm";

const opt=[
  {label:"select",value:null}
]

const options = [
  { label: "dropdown 1", value: "dropdown 1" },
  { label: "dropdown 2", value: "dropdown 2" },
  { label: "dropdown 3", value: "dropdown 3" },
];


export const CreateTypesComp = ({data}) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Types `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  const [option,setOption]=React.useState([])

  const getCategoryData = async () => {
    try {
        const result = await getRequest('HouseKeepingsCategory/GetCategory');
        let listData = [];
        result.data.map((item) => {
            listData.push({ label: item.name, value: item.id })
        })
        setOption(listData);
    } catch (error) {
      dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
      
    } finally {
    }
}

useEffect(() => {
  getCategoryData();
}, []);

  return (
    <div >
      <div>
        <LabelCustom labelName={"Select Category"} mandatory={true} />
        <SelectForm 
        placeholder={"Select Category"}
            onDropdownOpen={() => {getCategoryData();}} 
            name="categoryId"
            options={option} 
            values={
              data
                ? option.filter(
                    (options) => options.value === data?.CategoryId
                  )
                : opt
            }
            />
      </div>
    
      <div>
        <LabelCustom labelName={"Name"} mandatory={true}  />
        <InputCustomFormik
        
        maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
        <span style={{paddingLeft:"12px",color:"var(--primary)"}}>For multiple entries seperate Names with ';'.  Eg:- Name1;Name2;Name3</span>
      </div>
      
    </div>
  );
};
