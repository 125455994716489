import OnboardingSidePanelBackground from "../../../Assets/OnboardingSidePanelBackground.svg";
import SeperationSidePanelImg from "../../../Assets/SeperationSidePanelImg.svg";
import SeperationSidePanelImgActive from "../../../Assets/SeperationSidePanelImgActive.svg";
import OnboardingSidePanelLine from "../../../Assets/OnboardingSidePanelLine.svg";
import { Form, Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import * as Yup from "yup";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { useNavigate } from "react-router-dom";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { DragFormik } from "../../../Components/FormComponent/Upload/DragFormik";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { format } from "date-fns";

import { DetailsExitFeedback } from "../../HRMS/Components/SeparationComponents/Details/DetailsExitFeedback";
import { MyDetailsResignation } from "../../HRMS/Components/SeparationComponents/Details/MyDetailsResignation";
import { ViewDue } from "../../HRMS/Components/SeparationComponents/PopupForm/ViewDue";
import { DetailsPageFinalSettlement } from "../../HRMS/Components/SeparationComponents/PopupForm/DetailsPageFinalSettlement";
import { ExitInterview } from "../../HRMS/Components/SeparationComponents/PopupForm/ExitInterview";

import { Load, Loader } from "../../../Components/FormComponent/Loader";
import { ExitLetters } from "../../HRMS/Components/SeparationComponents/PopupForm/ExitLetters";
const Resignation = ({ loading }) => {
  const submitResignationClickHandler = () => {};
  useEffect(() => {
    document.title = `PeopleSol -  Resignation Services`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "100%" }}>
          <div className="headingContainer">
            <h3>Create Resignation</h3>
          </div>
          <div className="resignationInnerContainer">
            <div className="resignationSidePanel">
              <img
                style={{
                  width: "100%",
                  bottom: 0,
                  position: "absolute",
                  right: 0,
                }}
                src={OnboardingSidePanelBackground}
                alt="OnboardingSidePanelBackground"
              />
              <div
                style={{
                  width: "46px",
                  margin: "10% 10% 0 10%",
                }}
                className="horizontalLine"
              ></div>
              <div
                style={{ color: "white", fontSize: "18px", margin: " 5% 10%" }}
              >
                We are sorry that you have decided to resign from PeopleSol.
                <br />
                While we understand the reasons leading to your decision, we
                could have only hoped to keep a dedicated and hardworking
                employee like yourself for many more years.
                <br />
                Now we can only thank you for your time here and wish you
                success in your next challenge.
                <br />
              </div>
              <div
                style={{ color: "white", fontSize: "14px", margin: "0 10%" }}
              >
                You can feel free to upload the Resignation letter if you
                already have a document prepared with you or you can simply type
                your resignation letter in the space provided.
              </div>
            </div>
            <div className="resignationMainContainer">
              <div className="itemsAct">
                <LabelCustom
                  labelName={"Date of Relieving "}
                  mandatory={true}
                  style={{ flex: 3, width: "40%" }}
                />
                <label htmlFor="" style={{ flex: 2 }}>
                  :
                </label>
                <div style={{ width: "51%" }}>
                  {" "}
                  <DatePickerFormiks
                    name="DateOfRelieving"
                    style={{ flex: 5 }}
                  />
                </div>
              </div>
              <div className="itemsAct">
                <LabelCustom
                  labelName={"Upload Resignation Letter"}
                  style={{ flex: 3, width: "40%" }}
                />
                <label htmlFor="" style={{ flex: 3 }}>
                  :
                </label>
                <div style={{ width: "51%" }}>
                  {" "}
                  <DragFormik
                    canRestart={true}
                    canCancel={true}
                    canRemove={true}
                    multiple={true}
                    name="Upload"
                    style={{ flex: 5 }}
                  />
                </div>
              </div>
              <label
                style={{ margin: "5%", fontSize: "15px", fontWeight: 500 }}
              >
                OR
              </label>
              <div className="itemsAct">
                <LabelCustom
                  labelName={"Type Here"}
                  style={{ flex: 3, width: "40%" }}
                />

                <label htmlFor="" style={{ flex: 3 }}>
                  :
                </label>
                <div style={{ width: "51%" }}>
                  {" "}
                  <TextAreaFormik
                    style={{ width: "320", height: " 91px", flex: 5 }}
                    placeholder="Enter Resignation letter"
                    name="Reason"
                  />
                </div>
              </div>
              <div
                className="createFootSep"
                style={{ background: "var(--form-background)!important" }}
              >
                <button
                  onClick={submitResignationClickHandler}
                  className="button primaryButton"
                  style={{ padding: "10px 12px", background: "red" }}
                  type="reset"
                >
                  Reset
                </button>
                <button
                  onClick={submitResignationClickHandler}
                  className="button primaryButton"
                  style={{ padding: "10px 12px" }}
                  type="submit"
                >
                  Submit Resignation
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const ServiceResignation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(false);
  const [exit, setExit] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fData, setFData] = useState(false);

  let getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `Resignations/EmployeeResignationDetails`
      );
      setData(result?.data?.data);
      if (
        result?.data?.data?.finalSettlementId !== undefined &&
        result?.data?.data?.finalSettlementId !== 0
      ) {
        getFDetails(result?.data?.data?.finalSettlementId);
      }
      if (result?.data?.data != null) {
        exitDetails(result?.data?.data?.request?.id);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const exitDetails = async (eid) => {
    try {
      setLoading(true);
      const result = await getRequest(
        `ExitForm/EmployeeExitFormDetails?ID=${eid}`
      );
      setExit(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getFDetails = async (id) => {
    try {
      setLoading(true);
      const result = await getRequest(
        `Resignations/FinalSettlementsDetails?ID=${id}`
      );
      setFData(result?.data?.data);
    } catch (error) {
      // dispatch(
      //   showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      // );
    } finally {
      setLoading(false);
    }
  };

  const childRef = useRef(null);
  const setStep = (step) => {
    childRef?.current?.stepFromParent(step);
  };

  if (data == null || data?.request?.status === "Cancelled") {
    setStep(0);
  } else if (data != null) {
    if (
      data?.request?.status === "Approved" ||
      data?.request?.status === "Pending" ||
      data?.request?.status === "Rejected" ||
      data?.request?.status === "Terminated"
    ) {
      setStep(1);
    } else if (data?.request?.status === "Dues Approved") {
      setStep(2);
    } else if (data?.request?.experienceLetter !== null) {
      setStep(5);
    } else if (data?.finalSettlementId !== 0) {
      setStep(3);
    } else {
      setStep(1);
    }
  } else {
    setStep(1);
  }

  useEffect(() => {
    getDetails();
  }, []);

  const stepControlFunction = () => {};
  return (
    <>
      <FormikStepper
        initialValues={{
          DateOfRelieving: "",
          Upload: "",
          Reason: "",
          Rating: "Excellent",
          ReasonForLeaving: "",
          ThingsDoNotLike1: "",
          ThingsDoNotLike2: "",
          ThingsDoNotLike3: "",
          BestThings1: "",
          BestThings2: "",
          BestThings3: "",
          WithinYourDepartment: "Excellent",
          BetweenDepartments: "Excellent",
          BetweenManagersAndStaff: "Excellent",
          ThroughoutTheCompany: "Excellent",
          suggestionsToDevelopAESS: "",
          jobTrainingWasAdequate: "Strongly Agree",
          opportunityToWorkonLatestTechnologies: "Strongly Agree",
          understandingOfTheTasksandExpectations: "Strongly Agree",
          careerAdvancement: "Strongly Agree",
          workScheduleMetmyProfessionalNeeds: "Strongly Agree",
          providedAllUsefulInformationOnTime: "Strongly Agree",
          mySkillsUsedEffectively: "Strongly Agree",
          MyOrientationToTheJob: "Strongly Agree",
          IsanythingCompanyDoneAnythingtoPreventYouFromLeaving: "",
          OnWhatTerms: "",
          ReJoin: "Yes",
        }}
        setLoading={setLoading}
        getDetails={getDetails}
        ref={childRef}
        data={data}
        exit={exit}
        onSubmit={async (values) => {
          navigate("/onboardingcompleted", { replace: true });
        }}
        stepControlFunction={stepControlFunction}
      >
        <FormikStep
          label="Exit Form"
          validationSchema={Yup.object().shape(
            {
              Upload: Yup.mixed().when("Reason", {
                is: (Reason) => !Reason || Reason.length === 0,
                then: () => Yup.mixed().required("File is required"),
              }),
              Reason: Yup.string().when("Upload", {
                is: (Upload) => !Upload || Upload.length === 0,
                then: () =>
                  Yup.string().trim()
                    .max(500)
                    .required("At least one of the fields is required"),
              }),
            },
            ["Upload", "Reason"]
          )}
        >
          <Resignation data={data} loading={loading} />
        </FormikStep>
        <FormikStep label="Resignation">
          <MyDetailsResignation data={data} loadings={loading} />
        </FormikStep>
        <FormikStep label="No Dues">
          <>
            <div
              className="createHeader"
              style={{ position: "absolute", zIndex: "999" }}
            >
              <label>{"No Due Clearance"}</label>
            </div>
            <div className="horizontalLine createLine"></div>
            <ViewDue id={data?.request?.id} />
          </>
        </FormikStep>
        <FormikStep label="Final settlement" style={{ position: "relative" }}>
          <div
            className="createHeader"
            style={{ position: "absolute", zIndex: "999" }}
          >
            <label>{"Final settlement"}</label>
          </div>
          <div className="horizontalLine createLine"></div>
          <DetailsPageFinalSettlement data={fData} loading={loading} />
        </FormikStep>
        <FormikStep label="Exit Interview">
          {exit?.exitInterviews == null ? (
            <ExitInterview data={data} />
          ) : (
            <DetailsExitFeedback details={data} data={exit} loading={loading} />
          )}
        </FormikStep>
        <FormikStep label="Exit Letter">
          <div
            className="createHeader"
            style={{ position: "absolute", zIndex: "999" }}
          >
            <label>{"Final Letter"}</label>
          </div>
          <div className="horizontalLine createLine"></div>
          <ExitLetters data={data} />
        </FormikStep>
      </FormikStepper>
    </>
  );
};
export function FormikStep({ children }) {
  return <>{children}</>;
}
export const FormikStepper = forwardRef((props, ref) => {
  const childrenArray = React.Children.toArray(props.children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const headerClicked = async (clickedStep) => {
    if (clickedStep !== 0) {
      setStep(clickedStep);
    }
  };
  const stepFromParent = (step) => setStep(step);
  useImperativeHandle(ref, () => ({
    stepFromParent,
  }));
  function isLastStep() {
    return step === childrenArray.length - 1;
  }
  function InterViewStep() {
    return step === childrenArray.length - 2;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createPageData = async (values) => {
    try {
      props.setLoading(true);
      await postRequestForm("Resignations/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      props.getDetails();
      props.setLoading(false);
    }
  };
  const cancelPageData = async (values) => {
    try {
      props.setLoading(true);
      await postRequestForm(`Resignations/Cancel?ID=${values}`);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      props.getDetails();
      props.setLoading(false);
    }
  };
  const createPage = async (values) => {
    try {
      await postRequest(`exitform/create`, values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      props.getDetails();
    }
  };
  return (
    <Formik
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props?.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          if (step === 0) {
            const formData = new FormData();
            formData.append(
              "DateOfRelieving",
              `${format(values?.DateOfRelieving, "MM/dd/yyyy")}`
            );
            formData.append("Reason", values?.Reason);

            values?.Upload !== "" && formData.append("Upload", values?.Upload);

            createPageData(formData);
          } else if (step === 4 && props.exit?.exitInterviews == null) {
            const creat = {
              ...values,
              resignationId: props?.data?.request?.id,
              ReJoin: values.ReJoin === "Yes" ? true : false,
            };
            createPage(creat);
          }
          setStep((s) => s + 1);
          helpers?.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form style={{ height: "100%" }}>
          {step === 0 ? (
            <>{currentChild}</>
          ) : (
            <>
              <div className="seperationInnerContainer">
                <div className="seperationSidePanel">
                  <img
                    style={{
                      width: "100%",
                      bottom: 0,
                      position: "absolute",
                      right: 0,
                    }}
                    src={OnboardingSidePanelBackground}
                    alt="OnboardingSidePanelBackground"
                  />
                  <div className="onboardingSidePanelContainer">
                    <img
                      style={{
                        height: "100%",
                        position: "absolute",
                        left: "22px",
                        zIndex: 1,
                      }}
                      src={OnboardingSidePanelLine}
                      alt="OnboardingSidePanelLine"
                    />
                    {childrenArray.map((child, index) => (
                      <div
                        className={`onboardingSidePanelTile ${
                          index === step && "onboardingSidePanelTileActive"
                        } ${index < step && "cursor"}`}
                        onClick={() => headerClicked(index)}
                        key={child.props.label}
                      >
                        {index === step ? (
                          <img
                            src={SeperationSidePanelImgActive}
                            alt="OnboardingSidePanelImg"
                          />
                        ) : (
                          <img
                            src={SeperationSidePanelImg}
                            alt="OnboardingSidePanelImg"
                          />
                        )}
                        <label>{child.props.label}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="onboardingMainContainer" style={{ gap: "0px" }}>
                  <div
                    className="seperationFormContainer"
                    style={{
                      height: "100%",
                      overflow: "auto",
                    }}
                  >
                    {currentChild}
                  </div>
                  <div
                    className="createFootSep"
                    style={{
                      padding: "0px 12px",
                      background: "!important",
                    }}
                  >
                    <div style={{ display: "flex", gap: "12px" }}>
                      {step > 1 && (
                        <button
                          type="button"
                          className="button backButton noMargin"
                          onClick={() => setStep((s) => s - 1)}
                        >
                          {"< Back"}
                        </button>
                      )}
                      {step === 1 &&
                        (props?.data?.request?.status === "Pending" ||
                          props?.data?.request?.status === "Hold") && (
                          <button
                            type="button"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              border: "none !important",
                            }}
                            className="button noMargin"
                            onClick={() =>
                              cancelPageData(props?.data?.request?.id)
                            }
                          >
                            {"Cancel Resignation"}
                          </button>
                        )}
                      {step === 1 &&
                        props?.data?.request?.status === "Rejected" && (
                          <button
                            type="button"
                            style={{
                              backgroundColor: "var(--primary)",
                              color: "white",
                            }}
                            className="button backButton noMargin"
                            onClick={() => setStep(0)}
                          >
                            {"Apply Resignation"}
                          </button>
                        )}
                      {props.data &&
                        props?.data?.request.status !== "Cancelled" &&
                        props?.data?.request?.status !== "Rejected" && (
                          <button
                            className="button primaryButton noMargin"
                            type="submit"
                            style={{
                              background:
                                InterViewStep() &&
                                props.exit?.exitInterviews == null &&
                                "red",
                            }}
                          >
                            {InterViewStep() &&
                            props.exit?.exitInterviews == null
                              ? "Update"
                              : "Next >"}
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
});
export { ServiceResignation };
