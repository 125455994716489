import React,{useState,useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../Services/axios";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast,isLoading } from "../../../Features";
import * as Yup from 'yup'
import { FilesForm } from "./Form/FilesForm";
import { fileSchema } from "./Schema";

const initialvalue = {
  FolderId: "",
  SubFolderId:"",
  Upload:"",

  
};

const CreateFiles = (props) => {
  const [formValues, setFormValues] = useState(null);
  const [detailsform, setDetailsform] = useState({})
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Documents/FileDetails/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }

  useEffect(()=>{
    document.title = `PeopleSol - Create Files`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
  
   const fileSchema=Yup.object(
data?    {
  FolderId: Yup.string().required("Please Select Folder"),
 
  
}:  {
  FolderId: Yup.string().required("Please Select Folder"),
  Upload:Yup.mixed()
          .nullable()
          .test('Fichier taille',
            'File Size should be less than 2 mb', (value) => !value || (value && value.size <= 2 * 2048 * 2048))
          .test('format',
            'File Format is Incorrect', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))).required("Please Upload File"),
}
  );
  const postFormData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "Documents/CreateFile",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage() 
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
    finally{
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(
        "Documents/EditFile",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage()
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
    finally{
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails()
    }
  }, [])
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("FolderId", values?.FolderId);
    formData.append("SubFolderId", values?.SubFolderId);
    if(typeof values?.Upload === "object")
    formData.append("Upload", values?.Upload);
    data ? Edit(formData) : postFormData(formData);
    
  }
  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/globalsettingsmaster/modulesettings/files");
  }
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
    chooseFormValues={chooseFormValues}
    loading={loading}
    ButtonLabel={data?'Update Files':'Create Files'}
    FormHeading={data?'Edit Files':'Create Files'} 
      validationSchema={fileSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              FolderId: detailsform?.folderId,
              SubFolderId: detailsform?.subfolderId,
              Upload: `GeneralFiles/${detailsform?.name}`,
              
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<FilesForm data={detailsform}formValues={formValues}/>}
    />
  );
};

export { CreateFiles };
