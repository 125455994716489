/* eslint-disable no-unused-vars */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../Services/axios";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast } from "../../../Features";
import { FilesForm } from "./Form/FilesForm";
import { FolderForms } from "./Form/FolderForms";
import { folderSchema } from "./Schema";
import { useEffect } from "react";
import { useState } from "react";
import * as Yup from "yup";
const initialvalue = {
  name: "",
  ParentId: null,
  EmployeeIds: "",
  DepartmentIds: "",
};
const CreateFolders = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();

  const [detailsform, setDetailsform] = useState({});
  const getFormDetails = async () => {
    try {
      const result = await getRequest(`Documents/FolderDetails/${data}`);
      setDetailsform(result.data);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - Create Folder`;
    return () => {
      document.title = "PeopleSol";
    };
  });

  const postFormData = async (values) => {
    try {
      const result = await postRequest("Documents/CreateFolder", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("Documents/EditFolder", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values) => {
    const edit = {
      ...values,
      Id: data,
    };
    if (values?.DepartmentIds !== "" && values?.EmployeeIds !== "") {
      data ? Edit(edit) : postFormData(values);
    } else if (values?.DepartmentIds !== "") {
      const dep = {
        name: values?.name,
        ParentId: values?.ParentId,
        DepartmentIds: values?.DepartmentIds,
      };
      const editD = {
        Id: data,
        name: values?.name,
        ParentId: values?.ParentId,
        DepartmentIds: values?.DepartmentIds,
      };
      data ? Edit(editD) : postFormData(dep);
    } else if (values?.EmployeeIds !== "") {
      const emp = {
        name: values?.name,
        ParentId: values?.ParentId,
        EmployeeIds: values?.EmployeeIds,
      };
      const editE = {
        Id: data,
        name: values?.name,
        ParentId: values?.ParentId,
        EmployeeIds: values?.EmployeeIds,
      };
      data ? Edit(editE) : postFormData(emp);
    }
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      ButtonLabel={data ? "Update Folders" : "Create Folders"}
      FormHeading={data ? "Edit Folders" : "Create Folders"}
      validationSchema={Yup.object().shape(
        {
          name: Yup.string()
            .max(149, " Name must be less than 150 characters")
            .required("Please Enter Name"),
          DepartmentIds: Yup.string()
            .nullable()
            .when("EmployeeIds", {
              is: (EmployeeIds) => !EmployeeIds || EmployeeIds.length === 0,
              then: () => Yup.string().nullable(),
            }),
          EmployeeIds: Yup.string().when("DepartmentIds", {
            is: (DepartmentIds) => !DepartmentIds || DepartmentIds.length === 0,
            then: () =>
              Yup.string().required("Please select any Employee or Department"),
          }),
        },
        ["DepartmentIds", "EmployeeIds"]
      )}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?._foldersdata?.name,
              ParentId: detailsform?._foldersdata?.parentId,
              DepartmentIds: detailsform?._foldersdata?.departmentIds,
              EmployeeIds: detailsform?._foldersdata?.employeeIds,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<FolderForms data={detailsform} editID={data} />}
    />
  );
};

export { CreateFolders };
