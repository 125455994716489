import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

const ConsultantRequireAuth = () => {
    const { consultantLogin } = useSelector(state => state.auth);
    const location = useLocation();
    return consultantLogin ? <Outlet /> : <Navigate state={{from: location}} to='/consultant/login' replace />
}

export { ConsultantRequireAuth };
