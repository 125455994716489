import React from 'react'
import "./EmpProfileDetails.css";
import { confirmAlert } from 'react-confirm-alert';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { ProfileGroup } from '../MyProfileDetails/ProfileGroup';

function EmpProfiledetails(props) {
      
      return (
        <>
          {/* {!activeAttachment && */}
          <div className="empProfileDetailsMainContainer">
          <div className="empProfileHeaderContainer">
            <div className="empProfileHeaderWrapper">
              <span>{"Jenny James Info"}</span>
              <svg onClick={() => {
                  props.setEmpProfileDetails(false);
                }}
            xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 0.707104 -0.70711 -0.707104 20.4531 6.48962)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round"/>
            <line x1="0.75" y1="-0.75" x2="19.25" y2="-0.75" transform="matrix(-0.70711 -0.707104 0.70711 -0.707104 22.0259 20.6318)" stroke="#FF0404" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            </div>
          </div>
          <div className="empProfileDetailsMainWrapper">
            <div className="empProfileImageContainer">
              <div className="empProfileImageWrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="155"
                  height="154"
                  viewBox="0 0 155 154"
                  fill="none"
                >
                  <g opacity="0.4">
                    <circle
                      cx="77.1895"
                      cy="77.0684"
                      r="76.9316"
                      transform="rotate(-90 77.1895 77.0684)"
                      fill="url(#paint0_radial_60_9411)"
                    />
                  </g>
                  <g opacity="0.4">
                    <circle
                      cx="77.2061"
                      cy="77.0963"
                      r="63.3691"
                      transform="rotate(90 77.2061 77.0963)"
                      fill="url(#paint1_radial_60_9411)"
                    />
                  </g>
                  <g opacity="0.4">
                    <circle
                      cx="77.2051"
                      cy="77.0969"
                      r="46.7725"
                      transform="rotate(90 77.2051 77.0969)"
                      fill="url(#paint2_radial_60_9411)"
                    />
                  </g>
                  <defs>
                    <radialGradient
                      id="paint0_radial_60_9411"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(73.4253 78.6441) rotate(102.659) scale(101.154)"
                    >
                      <stop
                        offset="0.568025"
                        stop-color="white"
                        stop-opacity="0.2"
                      />
                      <stop offset="1" stop-color="#13A9C7" />
                    </radialGradient>
                    <radialGradient
                      id="paint1_radial_60_9411"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(74.1055 78.3943) rotate(102.659) scale(83.3209)"
                    >
                      <stop
                        offset="0.568025"
                        stop-color="white"
                        stop-opacity="0.2"
                      />
                      <stop offset="1" stop-color="#13A9C7" />
                    </radialGradient>
                    <radialGradient
                      id="paint2_radial_60_9411"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(74.9166 78.0549) rotate(102.659) scale(61.4988)"
                    >
                      <stop
                        offset="0.531188"
                        stop-color="white"
                        stop-opacity="0.2"
                      />
                      <stop offset="1" stop-color="#13A9C7" />
                    </radialGradient>
                  </defs>
                </svg>
                <div className="empProfileImage"></div>
              </div>
              <div className="imgText ">
                <h4>{props?.activeChat?.name}</h4>
                <span>{props?.activeChat?.designation}</span>
              </div>
            </div>
            <div className="EmpProfileAttachContainer">
              <div className="EmpProfileAttachWrapper">
                <div className="media"
                //  onClick={()=>{props.setActiveAttachment(true); props.setEmpProfileDetails(false);}}
                 >
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" viewBox="0 0 19 17" fill="none">
                    <path d="M9.56459 7.79475C10.0411 7.79475 10.4275 8.18108 10.4275 8.65764C10.4275 9.1342 10.0411 9.52052 9.56459 9.52052C9.08803 9.52052 8.70171 9.1342 8.70171 8.65764C8.70171 8.18108 9.08803 7.79475 9.56459 7.79475Z" stroke="#DE64A1" stroke-width="0.920806"/>
                    <rect x="1.40181" y="4.9059" width="13.8079" height="10.8659" rx="0.897738" stroke="#DE64A1" stroke-width="0.920806"/>
                    <path d="M3.28731 4.98451L3.59287 2.56491C3.68637 1.82457 4.35965 1.29833 5.10064 1.38643L17.0389 2.80569C17.7837 2.89424 18.3158 3.56983 18.2272 4.31467L17.151 13.3676C17.0646 14.0943 16.418 14.6218 15.6888 14.5607L15.4222 14.5383" stroke="#DE64A1" stroke-width="0.920806"/>
                    <path d="M15.3327 10.3379L10.429 13.0134C10.0076 13.1604 9.32388 13.528 8.50064 12.7753C7.4716 11.8345 6.91293 10.4849 5.63021 10.4849C4.66689 10.4849 1.5303 12.7753 1.3833 12.7753" stroke="#DE64A1" stroke-width="0.920806"/>
                    </svg>
                    <span>Media</span>
                </div>
                <div className="links"
                //  onClick={()=>{props.setActiveAttachment(true); props.setEmpProfileDetails(false);}}
                 >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                    <path d="M2.10165 7.72816C1.48184 6.98633 1.14223 6.0504 1.14209 5.08372C1.14209 2.80194 3.00831 0.928162 5.29764 0.928162H9.07542C11.3572 0.928162 13.231 2.80194 13.231 5.08372C13.231 7.3655 11.3648 9.23927 9.07542 9.23927H7.18653" stroke="#1296B0" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.2934 7.72815C15.9132 8.46998 16.2528 9.40592 16.2529 10.3726C16.2529 12.6544 14.3867 14.5282 12.0974 14.5282H8.31962C6.03784 14.5282 4.16406 12.6544 4.16406 10.3726C4.16406 8.09082 6.03028 6.21704 8.31962 6.21704H10.2085" stroke="#1296B0" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Links</span>
                </div>
                <div className="docs" onClick={()=>{props.setActiveAttachment(true); props.setEmpProfileDetails(false);}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path d="M15.7078 6.39963V10.0121C15.7078 13.6246 14.2628 15.0696 10.6503 15.0696H6.31531C2.70281 15.0696 1.25781 13.6246 1.25781 10.0121V5.67713C1.25781 2.06463 2.70281 0.619629 6.31531 0.619629H9.92781" stroke="#8A88ED" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4.87012 8.56713H9.20512M4.87012 11.4571H7.76012M15.7076 6.39963H12.8176C10.6501 6.39963 9.92762 5.67713 9.92762 3.50963V0.619629L15.7076 6.39963Z" stroke="#8A88ED" stroke-width="0.920806" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Docs</span>
                </div>
              </div>
            </div>
            <div className="empProfileAboutContainer">
              <div className="empProfileAboutWrapper">
                <h5>About</h5>
                <div className="aboutTextWrapper1">
                  <div className="empId">
                    <hr></hr>
                    <span>Employee ID</span>
                    <p>10000006</p>
                  </div>
                  <div className="department">
                    <hr></hr>
                    <span>Department</span>
                    <p>Technical</p>
                  </div>
                </div>
                <div className="aboutTextWrapper2">
                  <div className="role">
                    <hr></hr>
                    <span>Role</span>
                    <p>UI/UX Designer</p>
                  </div>
                  <div className="mobileno">
                    <hr></hr>
                    <span>Mobile No.</span>
                    <p>+91 9876543210</p>
                  </div>
                </div>
                <div className="aboutTextWrapper3">
                  <div className="email">
                    <hr></hr>
                    <span>Email</span>
                    <p>jennyjames@aesssolutions.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="empProfileGroupContainer">
              <p>{3} Groups in common</p>
          <ProfileGroup/>
            </div>
            <div className="empProfileBlockContainer">
              <div className="empProfileBlockWrapper">
              <div className="blockImg">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                <path d="M2.96777 4.37585L14.9436 14.3173" stroke="#F71D1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <circle cx="9.28223" cy="9.01721" r="8" stroke="#F71D1D" stroke-width="1.5"/>
                </svg>
              </div>
              <div className="blockText"
              onClick={() => {
                confirmAlert({
                  title: "Block User?",
                  message: "Blocked contacts will no longer be able to call you or send you messages.",
                  buttons: [
                    {
                      label: "Cancel",
                      onClick: () => {},
                    },
                    {
                      label: "Block",
                      onClick: () => {},
                    },
                  ],
                  overlayClassName: "customConfirmAlertContainer",
                });
              }}
              >Block {"Jenny James"}</div>
              </div>
            </div>
          </div>
          
          
          </div>
          {/* } */}
          
        </>
        
      );
}

export default EmpProfiledetails