import * as Yup from "yup";

export const reqCategorySchema = Yup.object({

  name:  Yup
  .string()
 
  .max(49," Name must be less than 50 characters")
  .required('Please Enter Name'),
 
});


