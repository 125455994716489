import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateRewardComp } from "./FormComp/CreateRewardComp";
import { useEffect } from "react";

const CreateReward = () => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Awards/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Awards/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Awards/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Reward `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getInitalAnswerValues = () => {
    let returnArray = [];
    data
      ? detailsform?.requestlist?.map((item) => {
          returnArray.push({
            question: item.question,
            isMandatory: item.isMandatory,
          });
        })
      : (returnArray = [{ question: "", isMandatory: "" }]);

    return returnArray;
  };
  const initialvalue = {
    title: "",
    cycleId: "",
    awardFor: "",
    locationId: null,
    noOfWinners: "",
    nominationsCanBeSubmittedBy: "",
    eligibilityCriteria: "",
    requestlist: getInitalAnswerValues(),
    isActive: "Yes",
  };
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(149, "Title must be less than 150 characters")
      .required("Please Enter The Question"),
    cycleId: Yup.string().required("Please Select The Cycle"),
    awardFor: Yup.string()
      .max(149, "Award For must be less than 150 characters")
      .required("Please Enter Award For"),
    noOfWinners: Yup.string().required("Please Enter No. Of Winners"),
    nominationsCanBeSubmittedBy: Yup.string().required(
      "Please Select Nomination Selectors"
    ),
    eligibilityCriteria: Yup.string()
      .max(149, "Eligibility Criteria must be less than 150 characters")
      .required("Please Enter Eligibility Criteria"),
    requestlist: Yup.array().of(
      Yup.object().shape({
        question: Yup.string()
          .max(299, "Name must be less than 300 characters")
          .required("Required"),
        isMandatory: Yup.string().required("Required"),
      })
    ),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      isActive: values.isActive === "Yes" ? true : false,
      Id: detailsform?.id,
    };

    const create = {
      ...values,
      isActive: true,
      Id: 0,
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/pmsmaster/rewards");
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Reward" : "Create Reward"}
      ButtonLabel={data ? "Update Reward" : "Create Reward"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              title: detailsform?.title,
              cycleId: detailsform?.cycleId,
              locationId: detailsform?.locationId,
              eligibilityCriteria: detailsform?.eligibilityCriteria,
              noOfWinners: detailsform?.noOfWinners,
              nominationsCanBeSubmittedBy:
                detailsform?.nominationsCanBeSubmittedBy,
              awardFor: detailsform?.awardFor,
              isActive: detailsform?.isActive ? "Yes" : "No",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<CreateRewardComp edit={data ? true : false} />}
    />
  );
};

export { CreateReward };
