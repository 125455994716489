import React, { useRef, useState, useEffect } from "react";
import "./HolidayCard.css";
import EidBackground from "../../../../../Assets/EidBackground.png";
import holidayTest from "../../../../../Assets/holidayTest.png";
import CalendarIcon from "../../../../../Assets/CalendarIcon.svg";
import { postRequest } from "../../../../../Services/axios";
import dateFormat from "dateformat";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Skeleton from "react-loading-skeleton";
import { format } from "date-fns";

const monthOptions = [
  { id: 0, label: "Jan", value: "Jan" },
  { id: 1, label: "Feb", value: "Feb" },
  { id: 2, label: "Mar", value: "Mar" },
  { id: 3, label: "Apr", value: "Apr" },
  { id: 4, label: "May", value: "May" },
  { id: 5, label: "Jun", value: "Jun" },
  { id: 6, label: "Jul", value: "Jul" },
  { id: 7, label: "Aug", value: "Aug" },
  { id: 8, label: "Sep", value: "Sep" },
  { id: 9, label: "Oct", value: "Oct" },
  { id: 10, label: "Nov", value: "Nov" },
  { id: 11, label: "Dec", value: "Dec" },
];

const HolidayCard = () => {
  const [idActive, setIdActive] = useState(new Date().getMonth());
  const [holidayList, setHolidayList] = useState([]);
  const [holidayActive, setHolidayActive] = useState(null);
  const [selectedDot, setSelectedDot] = useState(0);

  const reqData = {
    createdOn: null,
    lastUpdatedOn: null,
    Date: format(new Date(), "yyyy-MM-dd"),
    // Date: "2023-12-09",
    dataTableRequest: {
      iDisplayLength: 100,
      iDisplayStart: 0,
      sSearch: null,
      sorting: "Id",
      sSortDir_0: "descending",
      isExport: false,
    },
  };
  const getHolidayData = async () => {
    try {
      const result = await postRequest("Holiday/MyHolidayList", reqData);
      setHolidayList(result?.data?.data);
    } catch {
      // Handle the error
    }
  };

  useEffect(() => {
    getHolidayData();
    setSelectedDot(0); // Reset selected dot when the month changes
  }, [idActive]);

  // useEffect(() => {
  //   const currentMonthImages = holidayList
  //     .filter((item) => new Date(item.date)?.getMonth() === idActive)
  //     .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date

  //   setHolidayActive(currentMonthImages);
  //   setSelectedDot(0); // Reset selected dot when holidayActive changes
  //   console.log("second currentMonthImages", currentMonthImages);
  //   console.log("second holidayList", holidayList);
  // }, [idActive, holidayList]);
  useEffect(() => {
    // debugger;
    const currentMonthImages = holidayList
      .filter((item) => new Date(item.date)?.getMonth() === idActive)
      .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
    let index = -1;
    const foundElement = currentMonthImages.find((element, idx) => {
      if (
        new Date(element?.date)?.getMonth() === new Date()?.getMonth() &&
        new Date(element?.date) > new Date()
      ) {
        index = idx;
        return true;
      } else {
        index = 0;
        return false;
      }
    });
    setHolidayActive(currentMonthImages);
    setSelectedDot(index);
  }, [idActive, holidayList]);

  return (
    <div className="dashboardHolidayCardContentHolder">
      <div
        className="HolidayMonthListCover"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="HolidayMonthListUpperDiv"></div>
        <div className="HolidayMonthList">
          {monthOptions.map((item) => (
            <div
              key={item.id}
              className={`HolidayMonth cursor ${
                idActive === item.id ? "HolidayMonthActive" : ""
              }`}
              onClick={() => {
                setIdActive(item.id);
              }}
            >
              {item.value}
            </div>
          ))}
        </div>
        <div className="HolidayMonthListLowerDiv"></div>
      </div>

      <div
        className={`HolidayCardFrameContainer ${
          (holidayActive?.[0]?.title === "No Holiday" ||
            holidayActive?.length < 1) &&
          "invisibleClass"
        }`}
      >
        {holidayList ? (
          <>
            <Carousel
              showIndicators={holidayActive?.length > 1 ? true : false}
              selectedItem={selectedDot}
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              onChange={(index) => setSelectedDot(index)}
              // style={{ height: "100%", width: "100%" }}
            >
              {holidayActive?.map((holiday, index) => {
                return (
                  <div
                    style={{ border: "1px solid #ececec", borderRadius: "6px" }}
                  >
                    <div
                      style={{
                        // height: "100%",
                        display: "flex",
                        position: "relative",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "6px 6px 0px 0px",
                        }}
                        src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Holidays/${holiday?.attachment}`}
                        // src={holidayTest}
                        alt="Holiday Art"
                      />
                    </div>
                    <div className="homeHolidayFrameDataHolder">
                      <div
                        className="horizontalLine"
                        style={{
                          marginTop: "10px",
                          // marginBottom: "5px",
                          height: "2.5px",
                          backgroundColor: " var(--primary)",
                        }}
                      ></div>
                      <div className="homeHolidayFrameDataHolderDiv">
                        <div className="holidayName">
                          <label>
                            {holiday?.title ? (
                              holiday?.title
                            ) : (
                              <label className="dashboardHolidayText">
                                Holiday Title
                              </label>
                            )}
                          </label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "4px",
                            width: "55%",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div style={{ marginRight: "5px" }}>
                              <img src={CalendarIcon} alt="Calendar Icon" />
                            </div>
                            <div
                              className="holidayDate"
                              style={{ textAlign: "left" }}
                            >
                              {dateFormat(holiday?.date, "fullDate")
                                ? dateFormat(holiday?.date, "fullDate")
                                : dateFormat("fullDate")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </>
        ) : (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
          >
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        )}
      </div>
      <div
        className={`noHolidayLabel ${
          holidayActive?.[0]?.title === "No Holiday" ||
          holidayActive?.length < 1
            ? "visibleClass"
            : "invisibleClass"
        }`}
        style={{
          margin:
            holidayActive?.[0]?.title === "No Holiday" ||
            holidayActive?.length < 1
              ? "auto"
              : "",
        }}
      >
        <label>No Holiday This Month &#128542;</label>
      </div>
    </div>
  );
};

export { HolidayCard };
