import React, { useState, useEffect } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { RadioFormik } from "../../../../../Components/FormComponent/RadioFormik";
import { SelectFormikMulti } from "../../../../../Components/FormComponent/SelectFormikMulti";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import { useLocation } from "react-router-dom";

const typeOption = [
  { label: "Internal", value: "Internal" },
  { label: "External", value: "External" },
];
const billOption = [
  { label: "Billable", value: "Billable" },
  { label: "Non-Billable", value: "Non-Billable" },
];

const billScheduleOption = [
  { label: "Monthly", value: "Monthly" },
  { label: "Quaterly", value: "Quaterly" },
  { label: "Half Yearly", value: "Half Yearly" },
  { label: "Yearly", value: "Yearly" },
];

export const CreateProjectsComp = ({ data, formValues, id }) => {
  const dispatch = useDispatch();
  const [departmentList, setDepartmentList] = useState([]);
  const [billTypeOption, setBillTypeOption] = useState([]);
  const [subList, setSubList] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");
  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };
  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    if (data) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  // React.useEffect(() => {
  //   if (data) {
  //     setPage(0);
  //     const idArray =
  //       data?.teamsMembers &&
  //       data?.teamsMembers?.map((item) => item?.employeeId);
  //     idArray?.length > 0 && getOption(idArray);
  //   } else getOption([]);
  // }, [data?.teamsMembers]);

  React.useEffect(() => {
    setListData([]);
    getOption([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);

  useEffect(() => {
    document.title = `PeopleSol - Create Projects`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [roleOptions, setRoleOptions] = useState([]);
  const getRoleOptions = async () => {
    try {
      if (roleOptions.length > 0) return;
      const result = await getRequest("Projects/GetRoleNames");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setRoleOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const getPersonalSubData = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setSubList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const GetBillingTypes = async () => {
    try {
      const result = await getRequest("Projects/GetBillingTypes");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setBillTypeOption(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const GettCustomers = async () => {
    try {
      const result = await getRequest("Projects/GetCustomers");
      let listData = [];
      result.data.results.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setCustomer(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getDepartmentData = async () => {
    try {
      const result = await getRequest("Leavetypes/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getPersonalSubData();
    getDepartmentData();
    GetBillingTypes();
    GettCustomers();
    getRoleOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="headingForm">Project Details</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Project Name"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="name"
            type={"text"}
            placeholder={"Enter Project Name"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Project Code"} />
          <InputCustomFormik
            maxLength={6}
            name="code"
            type={"text"}
            placeholder={"Enter Project Code"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Project Type"} />
          <SelectForm
            name="type"
            options={typeOption}
            placeholder={"Select Type"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Billing"} />
          <RadioFormik name="isBillable" options={billOption} />
        </div>
        <div>
          <LabelCustom labelName={"Customer Name"} />
          <SelectForm
            maxLength={150}
            name="customerId"
            type={"text"}
            options={customer}
            placeholder={"Enter Customer Name"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Department"} />
          <SelectFormikMulti
            name="department"
            options={departmentList}
            placeholder={" Select Department"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Project Delivery Location"} />
          <SelectFormikMulti
            name="location"
            options={subList}
            placeholder={" Select Location"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Project Document"} />
          <FileUploadFormik name="attachments" />
        </div>
        <div>
          <LabelCustom labelName={"Project Tag Name"} />
          <InputCustomFormik
            maxLength={150}
            name="tagName"
            type={"text"}
            placeholder={"Enter Project Tag Name"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Project Description"} />
          <TextAreaFormik
            maxLength={500}
            name="description"
            placeholder="Enter Description"
          />
        </div>
      </div>

      <div className="headingForm">Project Timeline</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Start Date"} mandatory={true} />
          <DatePickerFormiks name="startDate" maxDate={formValues?.endDate} />
        </div>
        <div>
          <LabelCustom labelName={"End Date"} mandatory={true} />
          <DatePickerFormiks name="endDate" minDate={formValues?.startDate} />
        </div>
        <div>
          <LabelCustom labelName={"Project Estimated Hours"} />
          <InputCustomFormik
            name="estimatedHours"
            min={1}
            max={9999999999}
            step=".001"
            type="number"
            placeholder={"Enter Project Estimated Hours"}
          />
        </div>
      </div>

      <div className="headingForm">Project Cost</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Project Budgeted Cost"} />
          <InputCustomFormik
            name="costEstimation"
            min={1}
            max={9999999999}
            type="number"
            placeholder={"Enter Project Budgeted Cost"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Billing Schedule"} />
          <SelectForm
            name="billingSchedule"
            options={billScheduleOption}
            placeholder={" Select Billing Schedule"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Billing Type"} />
          <SelectForm
            name="BillingTypeId"
            options={billTypeOption}
            placeholder={" Select Billing Type"}
          />
        </div>

        <div>
          <LabelCustom labelName={"Billing Rate"} />
          <InputCustomFormik
            min={1}
            max={9999999999}
            name="billingRate"
            type="number"
            placeholder={"Enter Billing Rate"}
          />
        </div>
      </div>
      {id && !data?._project?.projectTeams.length ? (
        ""
      ) : (
        <div>
          <div className="headingForm">Project Team Members</div>
          <div className="repeatedSectionContainer">
            {/* <LabelCustom labelName={"Details"} /> */}
            <FieldArray name="ProjectTeam">
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const { values } = form;
                const { ProjectTeam } = values;
                return (
                  <StyledTableCont>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"S. No."} />
                          </StyledTableCell>
                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Team Members"} />
                          </StyledTableCell>

                          <StyledTableCell
                            padding="0"
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Project Role"} />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Role Description"} />
                          </StyledTableCell>

                          <StyledTableCell
                            style={{
                              fontSize: "12px",
                            }}
                            align="center"
                          >
                            <LabelCustom labelName={"Add/Remove"} />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {ProjectTeam?.map((ProjectTeams, index) => (
                        <TableBody key={index}>
                          {/* 1st column */}
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          {/* 2nd column */}
                          <StyledTableCell>
                            <SelectForm
                              name={`ProjectTeam[${index}].EmployeeId`}
                              placeholder={" Select Team Members"}
                              onMenuScrollToBottom={handleScrollToBottom}
                              options={searchParamemployee ? option : listData}
                              onInputChange={(e) => setSearchParamemployee(e)}
                            />
                          </StyledTableCell>

                          <StyledTableCell>
                            <SelectForm
                              maxLength={300}
                              options={roleOptions}
                              type="text"
                              name={`ProjectTeam[${index}].RoleId`}
                              placeholder={" Select Project Role"}
                            />
                          </StyledTableCell>
                          {/* 3rd column */}
                          <StyledTableCell>
                            <TextAreaFormik
                              maxLength={500}
                              name={`ProjectTeam[${index}].Description`}
                              placeholder="Enter Role Description"
                            />
                          </StyledTableCell>
                          {/* 4th column */}

                          <StyledTableCell
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="remove-btn"
                              onClick={() =>
                                ProjectTeam.length > 1
                                  ? remove(index)
                                  : dispatch(
                                      showToast({
                                        text: "Atleast One Item is Required ",
                                        severity: "error",
                                      })
                                    )
                              }
                            >
                              -
                            </button>
                            <button
                              type="button"
                              className="add-btn"
                              onClick={() =>
                                push({
                                  EmployeeId: "",
                                  RoleId: "",
                                  Description: "",
                                })
                              }
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </TableBody>
                      ))}
                    </Table>
                  </StyledTableCont>
                );
              }}
            </FieldArray>
          </div>
        </div>
      )}
    </>
  );
};
