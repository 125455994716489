/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import {
  EmployeeDetails,
  AddressDetails,
  PersonalDetails,
  Identification,
  BankFinance,
  EducationDetails,
} from "./CreateEmployeeComponents/index";
import { ExperienceDetails } from "./CreateEmployeeComponents/ExperienceDetails";
import {
  FormikMultiStepForm,
  FormikMultiStepFormStep,
} from "../../../Components/FormikMultiStepForm/FormikMultiStepForm";
import * as Yup from "yup";
import { format } from "date-fns";
const titleOptions = [
  { label: "Mr.", value: "Mr." },
  { label: "Mrs.", value: "Mrs." },
  { label: "Ms.", value: "Ms." },
];
const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Others", value: "Others" },
];
const maritalOptions = [
  { label: "Married", value: "Married" },
  { label: "Single", value: "Single" },
  { label: "Divorced", value: "Divorced" },
  { label: "N/A", value: "N/A" },
];
const educationOptions = [
  { label: "Phd", value: "Phd" },
  { label: "Masters", value: "Masters" },
  { label: "Bachelors", value: "Bachelors" },
  { label: "12th", value: "12th" },
  { label: "10th", value: "10th" },
  {
    label: "Special Course",
    value: "Special Course",
  },
];
const yesNoOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
const relationshipOptions = [
  { label: "Husband", value: "Husband" },
  { label: "Wife", value: "Wife" },
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Sibling", value: "Sibling" },
  { label: "Guardian", value: "Guardian" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Others", value: "Others" },
];
const typeofAccountOptions = [
  { label: "Savings", value: "Savings" },
  { label: "Current", value: "Current" },
];
const paymentModeOptions = [
  { label: "Cheque", value: "Cheque" },
  { label: "Cash", value: "Cash" },
  { label: "Bank Transfer", value: "Bank Transfer" },
];
const employeeTypeOptions = [
  { label: "Apprentice", value: "Apprentice" },
  { label: "Support Staff", value: "Support Staff" },
  { label: "Trainee", value: "Trainee" },
  { label: "Regular Employees", value: "Regular Employees" },
  { label: "Vendor", value: "Vendor" },
  { label: "Contractual", value: "Contractual" },
];
const CreateEmployeeN = () => {
  const [detailsform, setDetailsform] = useState(false);
  const { state } = useLocation();
  const data = state?.id;
  const location = useLocation();
  let currentUrl = location?.pathname;
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Create Employee `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Employees/Details/${data}`);
      setDetailsform(result.data.data._employees);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getDepartmentOptions();
    getRoleOptions();
    getAreaOptions();
    getReportingOfficerOptions();
    getDesignationOptions();
    getStateOptions();
    getCostCentreOptions();
    getShiftOptions();
    getSystemRoleOptions();
    if (data) getFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Employees/CreateEmployee", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text:
            error?.response?.data ?? "Some error occured in creating employee",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (currentUrl?.includes("profile"))
        result = await postRequestForm("Employees/EditByEmployee", values);
      else result = await postRequestForm("Employees/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text:
            error?.response?.data ?? "Some error occured in creating employee",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getExperienceListInitialValue = (data) => {
    let returnArray = [
      {
        occupation: null,
        companyName: null,
        summary: null,
        duration: null,
        currentlyWorkHere: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            occupation: item.occupation,
            companyName: item.company,
            summary: item.summary,
            duration: item.duration,
            currentlyWorkHere: item.currentlyWorkingHere,
          });
        });
      }
    return returnArray;
  };
  const getEducationListInitialValue = (data) => {
    let returnArray = [
      {
        employeeEducations: null,
        degree: null,
        college: null,
        yearofPassingFrom: null,
        yearofPassingTo: null,
        grade: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            employeeEducations: item.educationLevel,
            degree: item.degree,
            college: item.college,
            yearofPassingFrom: item.yearofPassingFromYear,
            yearofPassingTo: item.yearofPassingToYear,
            grade: item.grade,
          });
        });
      }
    return returnArray;
  };
  const getDependentListInitialValue = (data) => {
    let returnArray = [
      {
        name: null,
        permanentAddress: null,
        dob: null,
        relationship: null,
        gender: null,
        martialStatus: null,
      },
    ];
    if (data)
      if (data.length > 0) {
        returnArray = [];
        data.map((item) => {
          return returnArray.push({
            name: item.name,
            permanentAddress: item.address,
            dob: item.dob ? new Date(item.dob) : null,
            relationship: item.relationship,
            gender: item.gender,
            martialStatus: item.martialStatus,
          });
        });
      }
    return returnArray;
  };
  const initialValues = {
    currentPermanentAddressSame: true,
    name: data ? detailsform?.name : "",
    email: data ? detailsform?.email : "",
    personalEmail: data ? detailsform?.personalEmail : "",
    initialName: data ? detailsform?.initialName : null,
    personalPhoneNumber: data ? detailsform?.personalPhoneNumber : "",
    middleName: data ? detailsform?.middleName : null,
    OfficePhoneNumber: data ? detailsform?.officePhoneNumber : null,
    lastName: data ? detailsform?.lastName : "",
    dob: data ? (detailsform?.dob ? new Date(detailsform?.dob) : null) : null,
    upload: data
      ? detailsform?.image
        ? detailsform?.image !== ""
          ? `ProfileImageUploads/${detailsform?.image}`
          : null
        : null
      : null,
    gender: data ? detailsform?.gender : null,
    doj: data ? (detailsform?.doj ? new Date(detailsform?.doj) : null) : null,
    departmentId: data ? detailsform?.departmentId : "",
    subDepartmentId: data ? detailsform?.subDepartmentId : null,
    shiftId: data ? detailsform?.shiftId : null,
    systemRoleId: data ? detailsform?.systemRoleId : "",
    roleId: data ? detailsform?.roleId : "",
    reportingOfficer: data ? detailsform?.reportingOfficer : "",
    area: data ? detailsform?.area : "",
    designationId: data ? detailsform?.designationId : "",
    employeeTypeCode: data ? detailsform?.employeeTypeCode : "",
    permanentAddress: data ? detailsform?.permanentAddress : null,
    permanentConame: data ? detailsform?.permanentConame : null,
    permanentAddressLine2: data ? detailsform?.permanentAddressLine2 : null,
    permanentAddressPincode: data ? detailsform?.permanentAddressPincode : null,
    permanentAddressState: data ? detailsform?.permanentAddressState : null,
    permanentAddressCountry: data
      ? detailsform?.permanentAddressCountry
      : "India",
    permanentAddressCity: data ? detailsform?.permanentAddressCity : null,
    currentConame: data ? detailsform?.currentConame : null,
    currentAddress: data ? detailsform?.currentAddress : null,
    currentAddressLine2: data ? detailsform?.currentAddressLine2 : null,
    currentAddressPincode: data ? detailsform?.currentAddressPincode : null,
    currentAddressState: data ? detailsform?.currentAddressState : null,
    currentAddressCountry: data ? detailsform?.currentAddressCountry : "India",
    currentAddressCity: data ? detailsform?.currentAddressCity : null,
    birthCountry: data ? detailsform?.birthCountry : null,
    religion: data ? detailsform?.religion : null,
    state: data ? detailsform?.state : null,
    nationality: data ? detailsform?.nationality : null,
    language: data ? detailsform?.language : null,
    dom: data ? (detailsform?.dom ? new Date(detailsform?.dom) : null) : null,
    martialStatus: data ? detailsform?.martialStatus : null,
    highestQualification: data ? detailsform?.highestQualification : null,
    currentExperience: data ? detailsform?.currentExperience : null,
    cuurentSalary: data ? detailsform?.cuurentSalary : null,
    groupHealthInsurance: data ? detailsform?.groupHealthInsurance : null,
    additionalInformation: data ? detailsform?.additionalInformation : null,
    skillSet: data ? detailsform?.skillSet : null,
    offerletterupload: data
      ? detailsform?.offerLetter
        ? detailsform?.offerLetter !== ""
          ? `OfferLetter/${detailsform?.offerLetter}`
          : null
        : null
      : null,
    spouseName: data ? detailsform?.spouseName : null,
    spouseMobileNumber: data ? detailsform?.spouseMobileNumber : null,
    spouseWorking: data ? detailsform?.spouseWorking : null,
    occupation: data ? detailsform?.occupation : null,
    companyName: data ? detailsform?.companyName : null,
    officeAddress: data ? detailsform?.officeAddress : null,
    numberofChildren: data ? detailsform?.noofChildren : null,
    emergencyContactName: data ? detailsform?.emergencyContactName : null,
    relationShip: data ? detailsform?.relationShip : null,
    emergencyContactNumber: data ? detailsform?.emergencyContactNumber : null,
    houseNumber: data ? detailsform?.houseNumber : null,
    contactPersonOfficePhonenumber: data
      ? detailsform?.contactPersonOfficePhonenumber
      : null,
    contactPersonAddress: data ? detailsform?.contactPersonAddress : null,
    panNumber: data ? detailsform?.panNumber : null,
    uannumber: data ? detailsform?.uannumber : null,
    aaadharNumber: data ? detailsform?.aaadharNumber : "",
    pfnumber: data ? detailsform?.pfnumber : null,
    passportNumber: data ? detailsform?.passportNumber : null,
    passportIsuueDate: data
      ? detailsform?.passportIsuueDate
        ? new Date(detailsform?.passportIsuueDate)
        : null
      : null,
    passportExpiryDate: data
      ? detailsform?.passportExpiryDate
        ? new Date(detailsform?.passportExpiryDate)
        : null
      : null,
    costCentre: data ? detailsform?.costCentre : null,
    isPayroll: data ? (detailsform?.isPayroll ? "Yes" : "No") : null,
    paymentMode: data ? detailsform?.paymentMode : null,
    bankAccountNumber: data ? detailsform?.bankAccountNumber : null,
    ifsccode: data ? detailsform?.ifsccode : null,
    branchName: data ? detailsform?.branchName : null,
    accountHolderName: data ? detailsform?.accountHolderName : null,
    typeofAccount: data ? detailsform?.typeofAccount : null,
    dependentList: getDependentListInitialValue(detailsform?.dependentList),
    employeeEducations: getEducationListInitialValue(
      detailsform?.educationlist
    ),
    employeeExperiences: getExperienceListInitialValue(
      detailsform?.experiencelist
    ),
  };
  const onSubmit = async (values) => {
    const formData = new FormData();
    formData.append("Id", data ? data : 0);
    formData.append("HasPeopleSolOnboardingCompleted", false);
    formData.append("name", values?.name);
    formData.append("email", values?.email);
    if (values?.personalEmail)
      formData.append("personalEmail", values?.personalEmail);
    formData.append("personalPhoneNumber", values?.personalPhoneNumber);
    formData.append("departmentId", values?.departmentId);
    if (values?.subDepartmentId)
      formData.append("subDepartmentId", values?.subDepartmentId);
    formData.append("systemRoleId", values?.systemRoleId);
    formData.append("roleId", values?.roleId);
    formData.append("reportingOfficer", values?.reportingOfficer);
    formData.append("area", values?.area);
    formData.append("designationId", values?.designationId);
    formData.append("employeeTypeCode", values?.employeeTypeCode);
    if (values?.aaadharNumber)
      formData.append("aaadharNumber", values?.aaadharNumber);
    formData.append("shiftId", values?.shiftId);
    if (typeof values?.upload === "object")
      if (values?.upload) formData.append("upload", values?.upload);
    if (values?.offerletterupload)
      formData.append("offerletterupload", values?.offerletterupload);
    if (values?.initialName)
      formData.append("initialName", values?.initialName);
    if (values?.middleName) formData.append("middleName", values?.middleName);
    if (values?.OfficePhoneNumber)
      formData.append("OfficePhoneNumber", values?.OfficePhoneNumber);
    if (values?.lastName) formData.append("lastName", values?.lastName);
    if (values?.dob)
      formData.append("dob", `${format(values?.dob, "MM/dd/yyyy")}`);
    if (values?.gender) formData.append("gender", values?.gender);
    if (values?.doj)
      formData.append("doj", `${format(values?.doj, "MM/dd/yyyy")}`);
    if (values?.permanentConame)
      formData.append("permanentConame", values?.permanentConame);
    if (values?.permanentAddress)
      formData.append("permanentAddress", values?.permanentAddress);
    if (values?.permanentAddressLine2)
      formData.append("permanentAddressLine2", values?.permanentAddressLine2);
    if (values?.permanentAddressPincode)
      formData.append(
        "permanentAddressPincode",
        values?.permanentAddressPincode
      );
    if (values?.permanentAddressState)
      formData.append("permanentAddressState", values?.permanentAddressState);
    if (values?.permanentAddressCountry)
      formData.append(
        "permanentAddressCountry",
        values?.permanentAddressCountry
      );
    if (values?.permanentAddressCity)
      formData.append("permanentAddressCity", values?.permanentAddressCity);
    if (!data && values?.currentPermanentAddressSame) {
      if (values?.permanentConame)
        formData.append("currentConame", values?.permanentConame);
      if (values?.permanentAddressCity)
        formData.append("currentAddressCity", values?.permanentAddressCity);
      if (values?.permanentAddress)
        formData.append("currentAddress", values?.permanentAddress);
      if (values?.permanentAddressLine2)
        formData.append("currentAddressLine2", values?.permanentAddressLine2);
      if (values?.permanentAddressPincode)
        formData.append(
          "currentAddressPincode",
          values?.permanentAddressPincode
        );
      if (values?.permanentAddressState)
        formData.append("currentAddressState", values?.permanentAddressState);
      if (values?.permanentAddressCountry)
        formData.append(
          "currentAddressCountry",
          values?.permanentAddressCountry
        );
    } else {
      if (values?.currentConame)
        formData.append("currentConame", values?.currentConame);
      if (values?.currentAddressCity)
        formData.append("currentAddressCity", values?.currentAddressCity);
      if (values?.currentAddress)
        formData.append("currentAddress", values?.currentAddress);
      if (values?.currentAddressLine2)
        formData.append("currentAddressLine2", values?.currentAddressLine2);
      if (values?.currentAddressPincode)
        formData.append("currentAddressPincode", values?.currentAddressPincode);
      if (values?.currentAddressState)
        formData.append("currentAddressState", values?.currentAddressState);
      if (values?.currentAddressCountry)
        formData.append("currentAddressCountry", values?.currentAddressCountry);
    }
    if (values?.birthCountry)
      formData.append("birthCountry", values?.birthCountry);
    if (values?.religion) formData.append("religion", values?.religion);
    if (values?.state) formData.append("state", values?.state);
    if (values?.nationality)
      formData.append("nationality", values?.nationality);
    if (values?.language) formData.append("language", values?.language);
    if (values?.dom)
      formData.append("dom", `${format(values?.dom, "MM/dd/yyyy")}`);
    if (values?.martialStatus)
      formData.append("martialStatus", values?.martialStatus);
    if (values?.highestQualification)
      formData.append("highestQualification", values?.highestQualification);
    if (values?.currentExperience)
      formData.append("currentExperience", values?.currentExperience);
    if (values?.cuurentSalary)
      formData.append("cuurentSalary", values?.cuurentSalary);
    if (values?.groupHealthInsurance)
      formData.append("groupHealthInsurance", values?.groupHealthInsurance);
    if (values?.additionalInformation)
      formData.append("additionalInformation", values?.additionalInformation);
    if (values?.skillSet) formData.append("skillSet", values?.skillSet);
    if (values?.spouseName) formData.append("spouseName", values?.spouseName);
    if (values?.spouseMobileNumber)
      formData.append("spouseMobileNumber", values?.spouseMobileNumber);
    if (values?.spouseWorking)
      formData.append("spouseWorking", values?.spouseWorking);
    if (values?.occupation) formData.append("occupation", values?.occupation);
    if (values?.companyName)
      formData.append("companyName", values?.companyName);
    if (values?.officeAddress)
      formData.append("officeAddress", values?.officeAddress);
    if (values?.numberofChildren)
      formData.append("noofChildren", values?.numberofChildren);
    if (values?.emergencyContactName)
      formData.append("emergencyContactName", values?.emergencyContactName);
    if (values?.relationShip)
      formData.append("relationShip", values?.relationShip);
    if (values?.emergencyContactNumber)
      formData.append("emergencyContactNumber", values?.emergencyContactNumber);
    if (values?.houseNumber)
      formData.append("houseNumber", values?.houseNumber);
    if (values?.contactPersonOfficePhonenumber)
      formData.append(
        "contactPersonOfficePhonenumber",
        values?.contactPersonOfficePhonenumber
      );
    if (values?.contactPersonAddress)
      formData.append("contactPersonAddress", values?.contactPersonAddress);
    if (values?.panNumber)
      formData.append("panNumber", values?.panNumber.toUpperCase());
    if (values?.uannumber) formData.append("uannumber", values?.uannumber);
    if (values?.pfnumber) formData.append("pfnumber", values?.pfnumber);
    if (values?.passportNumber)
      formData.append("passportNumber", values?.passportNumber);
    if (values?.passportIsuueDate)
      formData.append(
        "passportIsuueDate",
        `${format(values?.passportIsuueDate, "MM/dd/yyyy")}`
      );
    if (values?.passportExpiryDate)
      formData.append(
        "passportExpiryDate",
        `${format(values?.passportExpiryDate, "MM/dd/yyyy")}`
      );
    if (values?.costCentre) formData.append("costCentre", values?.costCentre);
    if (values?.isPayroll)
      formData.append("isPayroll", values?.isPayroll === "Yes" ? true : false);
    if (values?.paymentMode)
      formData.append("paymentMode", values?.paymentMode);
    if (values?.bankAccountNumber)
      formData.append("bankAccountNumber", values?.bankAccountNumber);
    if (values?.ifsccode) formData.append("ifsccode", values?.ifsccode);
    if (values?.branchName) formData.append("branchName", values?.branchName);
    if (values?.accountHolderName)
      formData.append("accountHolderName", values?.accountHolderName);
    if (values?.typeofAccount)
      formData.append("typeofAccount", values?.typeofAccount);

    if (values?.dependentList.length > 0) {
      values?.dependentList.forEach(function (object, index) {
        if (object.name)
          formData.append(`Dependentlist[${index}][Name]`, object.name);
        if (object.permanentAddress)
          formData.append(
            `Dependentlist[${index}][Address]`,
            object.permanentAddress
          );
        if (object.dob)
          formData.append(
            `Dependentlist[${index}][Dob]`,
            object.dob ? `${format(object.dob, "MM/dd/yyyy")}` : ""
          );
        if (object.relationship)
          formData.append(
            `Dependentlist[${index}][Relationship]`,
            object.relationship
          );
        if (object.gender)
          formData.append(`Dependentlist[${index}][Gender]`, object.gender);
        if (object.martialStatus)
          formData.append(
            `Dependentlist[${index}][MartialStatus]`,
            object.martialStatus
          );
      });
    }

    if (values?.employeeEducations.length > 0) {
      values?.employeeEducations.forEach(function (object, index) {
        if (object.employeeEducations)
          formData.append(
            `Educationlist[${index}][EducationLevel]`,
            object.employeeEducations
          );
        if (object.degree)
          formData.append(`Educationlist[${index}][Degree]`, object.degree);
        if (object.college)
          formData.append(`Educationlist[${index}][College]`, object.college);
        if (object.yearofPassingFrom)
          formData.append(
            `Educationlist[${index}][YearofPassingFromYear]`,
            object.yearofPassingFrom
          );
        if (object.yearofPassingTo)
          formData.append(
            `Educationlist[${index}][YearofPassingToYear]`,
            object.yearofPassingTo
          );
        if (object.grade)
          formData.append(`Educationlist[${index}][Grade]`, object.grade);
      });
    }

    if (values?.employeeExperiences.length > 0) {
      let totalExp = 0;
      values?.employeeExperiences.forEach(function (object, index) {
        if (object.occupation)
          formData.append(
            `Experiencelist[${index}][Occupation]`,
            object.occupation
          );
        if (object.companyName)
          formData.append(
            `Experiencelist[${index}][Company]`,
            object.companyName
          );
        if (object.summary)
          formData.append(`Experiencelist[${index}][Summary]`, object.summary);
        if (object.duration) {
          totalExp = totalExp + parseInt(object.duration);
          formData.append(
            `Experiencelist[${index}][Duration]`,
            object.duration
          );
        }
        if (object.currentlyWorkHere)
          formData.append(
            `Experiencelist[${index}][CurrentlyWorkingHere]`,
            object.currentlyWorkHere
          );
      });
      if (totalExp !== 0) formData.append("previousExperience", totalExp);
    }
    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const [departmentOptions, setDepartmentOptions] = useState([]);
  const getDepartmentOptions = async () => {
    try {
      if (departmentOptions.length > 0) return;
      const result = await getRequest("Department/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setDepartmentOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [roleOptions, setRoleOptions] = useState([]);
  const getRoleOptions = async () => {
    try {
      if (roleOptions.length > 0) return;
      const result = await getRequest("Role/GetRole");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setRoleOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [stateOptions, setStateOptions] = useState([]);
  const getStateOptions = async () => {
    try {
      if (stateOptions.length > 0) return;
      const result = await getRequest("Payroll/GetStateLists");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item, value: item });
      });
      setStateOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };

  const [reportingOfficerOptions, setReportingOfficerOptions] = useState([]);
  const getReportingOfficerOptions = async () => {
    try {
      if (reportingOfficerOptions.length > 0) return;
      const result = await getRequest("Employees/GetEmployees");
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setReportingOfficerOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in ",
          severity: "error",
        })
      );
    }
  };
  const [areaOptions, setAreaOptions] = useState([]);
  const getAreaOptions = async () => {
    try {
      if (areaOptions.length > 0) return;
      const result = await getRequest("Area/GetAreas");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.name });
      });
      setAreaOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [designationOptions, setDesignationOptions] = useState([]);
  const getDesignationOptions = async () => {
    try {
      if (designationOptions.length > 0) return;
      const result = await getRequest("Employees/GetDesignation");
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setDesignationOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [costCentreOptions, setCostCentreOptions] = useState([]);
  const getCostCentreOptions = async () => {
    try {
      if (costCentreOptions.length > 0) return;
      const result = await getRequest("Employees/GetCostCenter");
      let listData = [];
      result?.data?.data?.map((item) => {
        return listData.push({ label: item, value: item });
      });
      setCostCentreOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [shiftOptions, setShiftOptions] = useState([]);
  const getShiftOptions = async () => {
    try {
      if (shiftOptions.length > 0) return;
      const result = await getRequest("Shifts/GetShifts");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.title, value: item.id });
      });
      setShiftOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [systemRoleOptions, setSystemRoleOptions] = useState([]);
  const getSystemRoleOptions = async () => {
    try {
      if (systemRoleOptions.length > 0) return;
      const result = await getRequest("Employees/GetSystemRoles");
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setSystemRoleOptions(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    }
  };
  const [formValues, setFormValues] = useState({});
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const SUPPORTED_FORMATS_DOC = ["application/pdf", "application/doc"];
  return (
    <FormikMultiStepForm
      initialValues={initialValues}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      returnPage={returnPage}
      heading={data ? "Edit Employee" : "Create Employee"}
      submitText={data ? "Update Employee" : "Create Employee"}
      chooseFormValues={chooseFormValues}
      loading={loading}
    >
      {!currentUrl?.includes("profile") && (
        <FormikMultiStepFormStep
          label="Employee"
          validationSchema={Yup.object({
            name: Yup.string()
              .max(149, "Name must be less than 150 characters")
              .required("Please Enter Name"),
            middleName: Yup.string()
              .max(149, "Middle Name must be less than 150 characters")
              .nullable()
              .notRequired(),
            lastName: Yup.string()
              .max(149, "Last Name must be less than 150 characters")
              .nullable()
              .notRequired(),
            email: Yup.string()
              .email("Please Enter Valid Email")
              .required("Please Enter Email"),
            OfficePhoneNumber: Yup.string()
              .min(5, "Mobile Number must be equal to 10 characters")
              .max(15, "Mobile Number must be equal to 10 characters")
              .required("Please Enter Mobile Number"),
            departmentId: Yup.string().required("Please Select Department"),
            systemRoleId: Yup.string().required("Please Select System Role"),
            roleId: Yup.string().required("Please Select Role"),
            reportingOfficer: Yup.string().required(
              "Please Select Reporting Officer"
            ),
            area: Yup.string().required("Please Select Area"),
            designationId: Yup.string().required("Please Select Designation"),
            employeeTypeCode: Yup.string().required(
              "Please Select Employee Type"
            ),
          })}
        >
          <EmployeeDetails
            editID={data ? true : false}
            detailsform={detailsform}
            titleOptions={titleOptions}
            genderOptions={genderOptions}
            departmentOptions={departmentOptions}
            roleOptions={roleOptions}
            reportingOfficerOptions={reportingOfficerOptions}
            areaOptions={areaOptions}
            designationOptions={designationOptions}
            formValues={formValues}
            shiftOptions={shiftOptions}
            systemRoleOptions={systemRoleOptions}
            employeeTypeOptions={employeeTypeOptions}
          />
        </FormikMultiStepFormStep>
      )}
      <FormikMultiStepFormStep
        label="Personal"
        validationSchema={Yup.object({
          upload: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              "Fichier taille",
              "File Size must be less than 1 mb",
              (value) =>
                !value ||
                (value && value.size <= 2048 * 2048) ||
                typeof value !== "object"
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value ||
                (value && SUPPORTED_FORMATS.includes(value.type)) ||
                typeof value !== "object"
            ),
          dob: Yup.date().max(new Date()).nullable().notRequired(),
          personalEmail: Yup.string()
            .email("Please Enter Valid Email")
            .notRequired("Please Enter Email")
            .nullable(),
          personalPhoneNumber: Yup.string()
            .min(5, "Mobile Number must be equal to 10 characters")
            .max(15, "Mobile Number must be equal to 10 characters")
            .required("Please Enter Mobile Number"),
          additionalInformation: Yup.string()

            .max(499, "Additional Information must be less than 500 characters")
            .nullable()
            .notRequired(),
          skillSet: Yup.string()

            .max(499, "Skill Set must be less than 500 characters")
            .nullable()
            .notRequired(),
          officeAddress: Yup.string()

            .max(299, "Office Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          spouseName: Yup.string()

            .max(149, "Spouse Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          spouseMobileNumber: Yup.string()

            .max(15, "Spouse Contact Number must be equal to 10 characters")
            .nullable()
            .notRequired(),
          occupation: Yup.string()

            .max(149, "Spouse Occupation must be less than 150 characters")
            .nullable()
            .notRequired(),
          numberofChildren: Yup.string()
            .max(2, "Number of children should less than 2 characters")
            .nullable()
            .notRequired(),
          birthCountry: Yup.string()

            .max(149, "Country must be less than 150 characters")
            .nullable()
            .notRequired(),
          religion: Yup.string()

            .max(149, "Religion must be less than 150 characters")
            .nullable()
            .notRequired(),
          nationality: Yup.string()

            .max(149, "Nationality must be less than 150 characters")
            .nullable()
            .notRequired(),
          language: Yup.string()

            .max(149, "Language must be less than 150 characters")
            .nullable()
            .notRequired(),
          highestQualification: Yup.string()

            .max(149, "Qualification must be less than 150 characters")
            .nullable()
            .notRequired(),
          currentExperience: Yup.string()
            .max(2, "Experience must be less than 3 characters")
            .nullable()
            .notRequired(),
          cuurentSalary: Yup.string()
            .max(4, "Enter correct salary in LPA")
            .nullable()
            .notRequired(),
          offerletterupload: Yup.mixed()
            .nullable()
            .notRequired()
            .test(
              "Fichier taille",
              "File Size must be less than 2 mb",
              (value) =>
                !value ||
                (value && value.size <= 2 * 2048 * 2048) ||
                typeof value !== "object"
            )
            .test(
              "format",
              "File Format is Incorrect",
              (value) =>
                !value ||
                (value && SUPPORTED_FORMATS_DOC.includes(value.type)) ||
                typeof value !== "object"
            ),
          emergencyContactName: Yup.string()

            .max(149, "Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          relationShip: Yup.string()

            .max(149, "Relationship must be less than 150 characters")
            .nullable()
            .notRequired(),
          emergencyContactNumber: Yup.string()
            .min(5, "Mobile Number must be equal to 10 characters")
            .max(15, "Mobile Number must be equal to 10 characters")
            .nullable()
            .notRequired(),
          houseNumber: Yup.string()
            .min(5, "Mobile Number must be equal to 10 characters")
            .max(15, "Mobile Number must be equal to 10 characters")
            .nullable()
            .notRequired(),
          contactPersonAddress: Yup.string()

            .max(299, "Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          dependentList: Yup.array().of(
            Yup.object().shape({
              name: Yup.string()

                .max(149, "Name must be less than 150 characters")
                .nullable()
                .notRequired(),
              permanentAddress: Yup.string()

                .max(299, "Address must be less than 300 characters")
                .nullable()
                .notRequired(),
              dob: Yup.date()
                .max(new Date(), "Birth date cannot be a future date")
                .nullable()
                .notRequired(),
              relationship: Yup.string()

                .max(149, "Relationship must be less than 150 characters")
                .nullable()
                .notRequired(),
            })
          ),
        })}
      >
        <PersonalDetails
          genderOptions={genderOptions}
          maritalOptions={maritalOptions}
          yesNoOptions={yesNoOptions}
          formValues={formValues}
          dispatch={dispatch}
          relationshipOptions={relationshipOptions}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Education"
        validationSchema={Yup.object({
          employeeEducations: Yup.array().of(
            Yup.object().shape({
              degree: Yup.string()

                .max(149, "Degree must be less than 150 characters")
                .nullable()
                .notRequired(),
              college: Yup.string()

                .max(149, "College must be less than 150 characters")
                .nullable()
                .notRequired(),
              yearofPassingTo: Yup.string()
                .min(4)
                .max(4, "Year must be less than 4 characters")
                .nullable()
                .notRequired(),
              grade: Yup.string()
                .min(1)
                .max(6, "Division/Grade/Percent must be less than 6 characters")
                .nullable()
                .notRequired(),
            })
          ),
        })}
      >
        <EducationDetails
          educationOptions={educationOptions}
          dispatch={dispatch}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Experience"
        validationSchema={Yup.object({
          employeeExperiences: Yup.array().of(
            Yup.object().shape({
              occupation: Yup.string()

                .max(149, "Occupation must be less than 150 characters")
                .nullable()
                .notRequired(),
              companyName: Yup.string()

                .max(149, "Company Name must be less than 150 characters")
                .nullable()
                .notRequired(),
              summary: Yup.string()

                .max(499, "Summary must be less than 500 characters")
                .nullable()
                .notRequired(),
              duration: Yup.string()
                .min(1)
                .max(30, "Duration must be correct")
                .nullable()
                .notRequired(),
            })
          ),
        })}
      >
        <ExperienceDetails yesNoOptions={yesNoOptions} dispatch={dispatch} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Address"
        validationSchema={Yup.object({
          permanentAddressPincode: Yup.string()
            .min(6, "ZIP/Pincode must be equal to 6 characters")
            .max(6, "ZIP/Pincode must be equal to 6 characters")
            .nullable()
            .notRequired(),
          currentAddressPincode: Yup.string()
            .min(6, "ZIP/Pincode must be equal to 6 characters")
            .max(6, "ZIP/Pincode must be equal to 6 characters")
            .nullable()
            .notRequired(),
          permanentConame: Yup.string()

            .max(149, "C/o Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          permanentAddressCity: Yup.string()

            .max(149, "City Name must be less than 150 characters")
            .nullable()
            .notRequired(),
          permanentAddress: Yup.string()

            .max(299, "Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          permanentAddressLine2: Yup.string()

            .max(299, "Address Line 2 must be less than 300 characters")
            .nullable()
            .notRequired(),
          currentAddress: Yup.string()

            .max(299, "Address must be less than 300 characters")
            .nullable()
            .notRequired(),
          currentAddressLine2: Yup.string()

            .max(299, "Address Line 2 must be less than 300 characters")
            .nullable()
            .notRequired(),
        })}
      >
        <AddressDetails
          stateOptions={stateOptions}
          edit={data ? true : false}
          formValues={formValues}
        />
      </FormikMultiStepFormStep>

      <FormikMultiStepFormStep
        label="Identification"
        // validationSchema={Yup.object({
        //   aaadharNumber: Yup.string()
        //     .min(12, "Aadhar Number must be equal to 12 characters")
        //     .max(12, "Aadhar Number must be equal to 12 characters")
        //     .nullable()
        //     .notRequired(),
        //   uannumber: Yup.string()
        //     .min(12, "UAN Number must be equal to 12 characters")
        //     .max(12, "UAN Number must be equal to 12 characters")
        //     .nullable()
        //     .notRequired(),
        //   panNumber: Yup.string()
        //     .min(10, "Pan Number must be equal to 10 characters")
        //     .max(10, "Pan Number must be equal to 10 characters")
        //     .matches(/^([A-Z,a-z]){5}([0-9]){4}([A-Z,a-z]){1}$/, {
        //       message: "Please Enter valid PAN",
        //     })
        //     .nullable()
        //     .notRequired(),
        //   passportNumber: Yup.string()
        //     .min(8, "Passport Number must be equal to 8 characters")
        //     .max(8, "Passport Number must be equal to 8 characters")
        //     .matches(/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/, {
        //       message: "Please Enter valid Passport Number",
        //     })
        //     .nullable()
        //     .notRequired(),
        //   pfnumber: Yup.string()
        //     .min(22, "PF Number must be equal to 22 characters")
        //     .max(22, "PF Number must be equal to 22 characters")
        //     .nullable()
        //     .notRequired(),
        // })}
      >
        <Identification />
      </FormikMultiStepFormStep>
      {!currentUrl?.includes("profile") && (
        <FormikMultiStepFormStep
          label="Payment"
          validationSchema={Yup.object({
            ifsccode: Yup.string()
              .min(11, "IFSC must be equal to 11 characters")
              .max(11, "IFSC must be equal to 11 characters")
              .nullable()
              .notRequired(),
            branchName: Yup.string()
              .max(149, "Bank Name must be less than 150 characters")
              .nullable()
              .notRequired(),
            accountHolderName: Yup.string()
              .max(149, "Account Holder Name must be less than 150 characters")
              .nullable()
              .notRequired(),
            bankAccountNumber: Yup.string()
              .max(20, "Account Number must be less than 20 characters")
              .nullable()
              .notRequired(),
          })}
        >
          <BankFinance
            typeofAccountOptions={typeofAccountOptions}
            costCentreOptions={costCentreOptions}
            yesNoOptions={yesNoOptions}
            paymentModeOptions={paymentModeOptions}
          />
        </FormikMultiStepFormStep>
      )}
    </FormikMultiStepForm>
  );
};
export { CreateEmployeeN };
