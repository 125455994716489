/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./TodaysTime.css";
import { Tabs, Tab } from "@mui/material";
import dateFormat from "dateformat";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { getInitialLetter } from "../../../../../Utilities/getInitialLetter";
import { format } from "date-fns";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
const TodaysTime = ({ homeList, isManager }) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const getShiftDuration = () => {
    if (LoggedInUser?.shift?.from && LoggedInUser?.shift?.to) {
      return (
        (new Date(`12/12/12 ${LoggedInUser?.shift?.to.replaceAll(".", ":")}`) -
          new Date(
            `12/12/12 ${LoggedInUser?.shift?.from.replaceAll(".", ":")}`
          )) /
        (1000 * 3600)
      );
    } else {
      return null;
    }
  };

  let totalShiftDuration = getShiftDuration();

  const dispatch = useDispatch();
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [date, setDate] = useState(new Date());
  const [displayDate, setDisplayDate] = useState(
    new Date().toLocaleDateString(undefined, options)
  );
  const [timer, setTimer] = useState("0:0:0 Hrs");
  const [clockedIn, setClockedIn] = useState(false);
  const [timeAttendanceData, setTimeAttendanceData] = useState(false);
  const [active, setActive] = useState(false);
  const [ipAddress, setIpAddress] = useState(false);

  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  const [logInTime, setLoginTime] = useState(null);
  const [logOutTime, setLogOutTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const refreshClock = () => {
    if (clockedIn) {
      // get total seconds between the times
      var delta = Math.abs(new Date() - date) / 1000;
      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;
      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      // what's left is seconds
      var seconds = Math.floor(delta % 60);
      setTimer(`${hours}:${minutes}:${seconds} Hrs`);

      const currentTime = new Date().getTime();
      const loggedInTime = logInTime
        ? new Date(logInTime).getTime()
        : new Date().getTime();
      const elapsed = Math.floor((currentTime - loggedInTime) / (1000 * 60)); // Elapsed time in hours
      setElapsedTime(elapsed);
    }
  };
  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }
  const attendanceClickHandler = () => {
    // if (clockedIn) {
    saveAttendaceData();
    // } else {
    //   saveAttendaceData();
    // }
  };
  const getConfigTime = async () => {
    try {
      const result = getRequest(`TimeAttendanceConfig/Details`);

      if (result) {
        result
          .then((response) => {
            setTimeAttendanceData(response.data);
          })
          .catch((error) => {});
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getAttendanceData = async () => {
    try {
      setActive(true);
      const result = await getRequest(
        `Attendance/GetAttendance/${LoggedInUser?.id}/${format(
          new Date(),
          "yyyy-MM-dd"
        )}/${format(new Date(), "yyyy-MM-dd")}`
      );
      getSignInTime(result?.data);
    } catch (error) {
      setActive(false);
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getSignInTime = (listData) => {
    let reqTime = null;
    if (listData) {
      if (listData.length > 0) {
        reqTime = listData[0];
      }
    }
    if (reqTime) {
      if (reqTime?.timing?.includes("\n")) {
        let splittedTime = reqTime?.timing?.split("\n");
        if (splittedTime[0]?.slice(-1) !== " ") {
          setLoginTime(
            new Date(
              `${format(new Date(), "yyyy-MM-dd")} ${splittedTime[0]?.slice(
                -8
              )}`
            )
          );
          if (splittedTime[1]?.slice(-1) !== " ") {
            setLogOutTime(
              `${format(new Date(), "yyyy-MM-dd")} ${splittedTime[1]?.slice(
                -8
              )}`
            );
            let timeDiff =
              new Date(`12/12/12 ${splittedTime[1]?.slice(-8)}`) -
              new Date(`12/12/12 ${splittedTime[0]?.slice(-8)}`);
            var msec = timeDiff;
            var hh = Math.floor(msec / 1000 / 60 / 60);
            msec -= hh * 1000 * 60 * 60;
            var mm = Math.floor(msec / 1000 / 60);
            msec -= mm * 1000 * 60;
            var ss = Math.floor(msec / 1000);
            msec -= ss * 1000;
            setClockedIn(false);
            setTimer(`${hh}:${mm}:${ss} Hrs`);
            const currentTime = new Date(
              new Date(
                `${format(new Date(), "yyyy-MM-dd")} ${splittedTime[1]?.slice(
                  -8
                )}`
              )
            ).getTime();
            const loggedInTime = new Date(
              new Date(
                `${format(new Date(), "yyyy-MM-dd")} ${splittedTime[0]?.slice(
                  -8
                )}`
              )
            ).getTime();
            const elapsed = Math.floor(
              (currentTime - loggedInTime) / (1000 * 60)
            ); // Elapsed time in hours
            setElapsedTime(elapsed);
          } else {
            setDate(new Date(`12/12/12 ${splittedTime[0]?.slice(-8)}`));
            setClockedIn(true);
          }
        }
      }
    }
    setActive(false);
  };

  useEffect(() => {
    if (!clockedIn) return;
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, [clockedIn]);

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("home")) {
      getConfigTime();
      if (LoggedInUser?.id) getAttendanceData();
    }
  }, [LoggedInUser?.id]);

  const location = useLocation();

  const saveAttendaceData = async () => {
    try {
      if (clockedIn) {
        let reqOut = {
          signInTime: new Date(new Date(logInTime) - tzoffset)
            .toISOString()
            .slice(0, -1),
          signOutTime: new Date(new Date() - tzoffset)
            .toISOString()
            .slice(0, -1),
        };
        setLogOutTime(format(new Date(), "yyyy-MM-dd hh:mm a"));
        const result = await postRequest("Attendance/Save", reqOut);
        setClockedIn(false);
      } else {
        let reqIn = {
          signInTime: new Date(new Date() - tzoffset)
            .toISOString()
            .slice(0, -1),
        };
        const result = await postRequest("Attendance/Save", reqIn);
        setClockedIn(true);
        setDate(new Date());
        setLoginTime(format(new Date(), "yyyy-MM-dd hh:mm a"));
      }
    } catch (error) {
      if (
        error?.response?.status === 403 &&
        error?.response?.statusText === "Forbidden"
      ) {
        dispatch(
          showToast({
            text: error?.response?.data,
            severity: "error",
          })
        );
        setIpAddress(false);
        return;
      }
      // console.log("error", error);
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  function addHours(date, hours) {
    date.setHours(date.getHours() + hours);
    return date;
  }
  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return { hours, minutes };
  }
  const progressWidth = logOutTime
    ? "100%"
    : elapsedTime
    ? (elapsedTime / ((totalShiftDuration ?? 10) * 60)) * 100 + "%"
    : 0;
  const navigate = useNavigate();
  const employeeList = [
    { label: "Myself", value: "Myself" },
    { label: "Team", value: "Team" },
    { label: "Department", value: "Department" },
  ];
  const [selectedEmployee, setSelectedEmployee] = useState([
    { label: "Myself", value: "Myself" },
  ]);
  const departmentIds = LoggedInUser?.timeAttendanceConfig?.departmentIds;
  const subDepartmentID = LoggedInUser?.subDepartmentID;

  function isCheck() {
    if (departmentIds) {
      const departmentIdsArray = departmentIds
        .split(",")
        .map((id) => parseInt(id));
      const isSubDepartmentInList =
        departmentIdsArray.includes(subDepartmentID);
      console.log(isSubDepartmentInList);
      return isSubDepartmentInList;
    } else {
      return false;
    }
  }

  return (
    <>
      <div
        className="headingContainer cursor headingWithDropdown"
        style={{ marginBottom: "-12px", zIndex: "999" }}
      >
        <h3
          className="cursor"
          onClick={() => navigate("/hrms/timeattendance/myattendance")}
        >
          Today’s Time
        </h3>
        {isManager && (
          <div style={{ width: "48%" }}>
            <SelectCustom
              dropdownHeight="236px"
              styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
              values={selectedEmployee}
              options={employeeList}
              onChange={(value) => {
                setSelectedEmployee(value);
              }}
              fullWidth={true}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
            />
          </div>
        )}
      </div>
      <div className="dashboardCardContentHolder">
        <div className="tabContainer">
          <div className="tabContainerLine"></div>
        </div>
        {selectedEmployee?.[0]?.value === "Myself" && (
          <div className="dataContainerDashboardCard">
            <div className="attendanceDateTimeHolder">
              {active ? (
                <Skeleton width={160} height={50} />
              ) : (
                <label className="timerTodayTime">
                  {logOutTime
                    ? `${toHoursAndMinutes(elapsedTime).hours} Hrs ${
                        toHoursAndMinutes(elapsedTime).minutes
                      } Mins`
                    : timer}
                </label>
              )}
              {active ? (
                <Skeleton width={200} height={20} />
              ) : (
                <label className="dateTodayTime">{displayDate}</label>
              )}
            </div>
            {active ? (
              <Skeleton count={5} width={300} height={25} />
            ) : (
              <>
                {!logOutTime && timeAttendanceData?.webLogIn && isCheck() && (
                  <div
                    className={`attendanceButton ${clockedIn && "clockedIn"}`}
                    onClick={attendanceClickHandler}
                  >
                    <span>
                      {clockedIn ? "Attendance Sign Out" : "Attendance Sign In"}
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 13 13"
                      fill="none"
                    >
                      <g id="Group 977">
                        <path
                          id="Rectangle225"
                          d="M3.57632 0.930298H10.9864C11.6368 0.930298 12.1642 1.45761 12.1642 2.10809V10.9167C12.1642 11.5672 11.6368 12.0945 10.9864 12.0945H3.57632"
                          stroke="white"
                          strokeWidth="0.930348"
                          strokeLinecap="round"
                        />
                        <path
                          id="Line113"
                          d="M0.99997 6.04728C0.743061 6.04728 0.534795 6.25554 0.534795 6.51245C0.534795 6.76936 0.743061 6.97763 0.999969 6.97763L0.99997 6.04728ZM9.05794 6.84138C9.23961 6.65972 9.23961 6.36519 9.05794 6.18352L6.09759 3.22317C5.91593 3.04151 5.6214 3.04151 5.43974 3.22317C5.25808 3.40484 5.25808 3.69937 5.43974 3.88103L8.07116 6.51245L5.43974 9.14387C5.25808 9.32554 5.25808 9.62007 5.43974 9.80173C5.6214 9.98339 5.91593 9.98339 6.09759 9.80173L9.05794 6.84138ZM0.999969 6.97763L8.72902 6.97763L8.72902 6.04728L0.99997 6.04728L0.999969 6.97763Z"
                          fill="white"
                        />
                      </g>
                    </svg>
                  </div>
                )}
                <div
                  style={{
                    padding: "12px 36px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "-12px",
                    }}
                  >
                    <p>
                      {LoggedInUser?.shift?.from
                        ? format(
                            new Date(
                              `12/12/12 ${LoggedInUser?.shift?.from.replaceAll(
                                ".",
                                ":"
                              )}`
                            ),
                            "hh:mm a"
                          )
                        : "--:--"}
                    </p>
                    <p>{LoggedInUser?.shift?.title ?? "NA"}</p>
                    <p>
                      {LoggedInUser?.shift?.to
                        ? format(
                            new Date(
                              `12/12/12 ${LoggedInUser?.shift?.to.replaceAll(
                                ".",
                                ":"
                              )}`
                            ),
                            "hh:mm a"
                          )
                        : "--:--"}
                    </p>
                  </div>

                  <div className="shift-progress-line-container">
                    <div className="start-dot" />
                    <div className="end-dot" />
                    <div className="shift-progress-line" />
                  </div>
                  {logInTime && isValidDate(logInTime) && (
                    // Render the date and time components
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "-12px",
                        }}
                      >
                        <p>
                          {logInTime
                            ? format(new Date(logInTime), "hh:mm a")
                            : ""}
                        </p>
                        <p>My Shift</p>
                        <p>
                          {logOutTime
                            ? format(new Date(logOutTime), "hh:mm a")
                            : logInTime
                            ? format(
                                addHours(
                                  new Date(logInTime),
                                  totalShiftDuration ?? 10
                                ),
                                "hh:mm a"
                              )
                            : ""}
                        </p>
                      </div>
                      {/* Rest of your JSX code */}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {selectedEmployee?.[0]?.value === "Team" && (
          <div className="approvalFrameTabData">
            <table className="teamAttendanceCard">
              <tr className="teamAttendanceTableHeader">
                <th></th>
                <th style={{ textAlign: "left" }}>Name</th>
                <th>Sign In</th>
                <th>Sign Out</th>
                <th>Today Hrs</th>
              </tr>
              {homeList?.teamDailyAttendance?.map((item, key) => {
                // let T1 = dateFormat(item?.inTime, "h:MM").split(":");
                // let T2 = dateFormat(item?.outTime, "h:MM").split(":");
                // let TDHours = T2[0] - T1[0];
                // let TDMinutes = T2[1] - T1[1];
                // let Td = T2 - T1;
                const inTimeDate = item.inTime ? new Date(item.inTime) : 0;
                const outTimeDate = item.outTime ? new Date(item.outTime) : 0;

                // Calculate the time difference in milliseconds
                const timeDiffMs = inTimeDate ? outTimeDate - inTimeDate : 0;

                // Convert the time difference into hours and minutes
                const hours = timeDiffMs
                  ? Math.floor(timeDiffMs / 1000 / 60 / 60)
                  : 0;
                const minutes = timeDiffMs
                  ? Math.floor((timeDiffMs / 1000 / 60) % 60)
                  : 0;
                return (
                  <tr key={key} className="teamAttendanceItemContainer">
                    <td className="teamAttendanceProfileItem">
                      {item?.profilePic ? (
                        <img
                          src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${item?.profilePic}`}
                          alt="Profile"
                        />
                      ) : (
                        <label
                          className="childDisplayText"
                          style={{ fontSize: "22px", aspectRatio: "1" }}
                        >
                          {item?.employeeName
                            ? getInitialLetter(item?.employeeName)
                            : "--"}
                        </label>
                      )}
                    </td>
                    <td
                      className="teamAttendanceNameItem"
                      style={{ textAlign: "left" }}
                    >
                      {item?.employeeName
                        ? item?.employeeName.split("(")[0]
                        : item?.personNumber}
                    </td>
                    <td className="teamAttendanceItem">
                      {item?.inTime
                        ? format(new Date(item?.inTime), "hh:mm a")
                        : "--:--"}
                    </td>
                    <td className="teamAttendanceItem">
                      {item?.outTime
                        ? format(new Date(item?.outTime), "hh:mm a")
                        : "--:--"}
                    </td>
                    <td
                      className="teamAttendanceItem"
                      style={{ paddingLeft: "12px" }}
                    >
                      {item?.inTime && item?.outTime
                        ? `${hours}h ${minutes}m`
                        : "-:- Hrs"}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
        {selectedEmployee?.[0]?.value === "Department" && (
          <div className="approvalFrameTabData">
            <table className="teamAttendanceCard">
              <tr className="teamAttendanceTableHeader">
                <th></th>
                <th style={{ textAlign: "left" }}>Name</th>
                <th>Sign In</th>
                <th>Sign Out</th>
                <th>Today Hrs</th>
              </tr>
              {homeList?.departmentWiseDailyAttendance?.map((item, key) => {
                // let T1 = dateFormat(item?.inTime, "h:MM").split(":");
                // let T2 = dateFormat(item?.outTime, "h:MM").split(":");
                // let TDHours = T2[0] - T1[0];
                // let TDMinutes = T2[1] - T1[1];
                // let Td = T2 - T1;
                const inTimeDate = item.inTime ? new Date(item.inTime) : 0;
                const outTimeDate = item.outTime ? new Date(item.outTime) : 0;

                // Calculate the time difference in milliseconds
                const timeDiffMs = inTimeDate ? outTimeDate - inTimeDate : 0;

                // Convert the time difference into hours and minutes
                const hours = timeDiffMs
                  ? Math.floor(timeDiffMs / 1000 / 60 / 60)
                  : 0;
                const minutes = timeDiffMs
                  ? Math.floor((timeDiffMs / 1000 / 60) % 60)
                  : 0;
                return (
                  <tr key={key} className="teamAttendanceItemContainer">
                    <td className="teamAttendanceProfileItem">
                      {item?.profilePic ? (
                        <img
                          src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${item?.profilePic}`}
                          alt="Profile"
                        />
                      ) : (
                        <label
                          className="childDisplayText"
                          style={{ fontSize: "22px", aspectRatio: "1" }}
                        >
                          {item?.employeeName
                            ? getInitialLetter(item?.employeeName)
                            : "--"}
                        </label>
                      )}
                    </td>
                    <td
                      className="teamAttendanceNameItem"
                      style={{ textAlign: "left" }}
                    >
                      {item?.employeeName
                        ? item?.employeeName.split("(")[0]
                        : item?.personNumber}
                    </td>
                    <td className="teamAttendanceItem">
                      {item?.inTime
                        ? format(new Date(item?.inTime), "hh:mm a")
                        : "--:--"}
                    </td>
                    <td className="teamAttendanceItem">
                      {item?.outTime
                        ? format(new Date(item?.outTime), "hh:mm a")
                        : "--:--"}
                    </td>
                    <td
                      className="teamAttendanceItem"
                      style={{ paddingLeft: "12px" }}
                    >
                      {item?.inTime && item?.outTime
                        ? `${hours}h ${minutes}m`
                        : "-:- Hrs"}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export { TodaysTime };
