/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { TimeCustomFormik } from "../../../../../Components/FormComponent/TimeCustomFormik";
import { RadioFormik } from "../../../../../Components/FormComponent/RadioFormik";
import { confirmAlert } from "react-confirm-alert";
import CompPopup from "./CompPopup";

export const CreateCompOffComp = ({ apiValues, formValues }) => {


  const typeOptions = [
    { label: "Weekly Off", value: "Weekly Off" },
    { label: "Holiday", value: "Holiday" },
  ];
  useEffect(() => {
    document.title = `PeopleSol - Create Comp Off`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (formValues?.date){

      if (!apiValues?.starttime || !apiValues?.endtime) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <CompPopup
              heading={"Sorry"}
              onClose={onClose}
              message={"Login information unavailable for the selected date, please choose another date"}
              />
              );
            },
          });
        }
      }
  }, [apiValues]);

  // //console.log('apiValues',apiValues);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Comp Off Date"} mandatory={true} />
        <DatePickerFormiks name={"date"} maxDate={new Date()} />
      </div>
      <div>
        {apiValues ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <LabelCustom labelName={"Sign In Time "} />
              <label style={{ fontWeight: "600", paddingLeft: "14px" }}>
                : &nbsp; {apiValues?.starttime ? apiValues?.starttime : null}
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <LabelCustom labelName={"Sign Out Time"} />
              <label style={{ fontWeight: "600" }}>
                : &nbsp;{apiValues?.endtime ? apiValues?.endtime : null}
              </label>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        <LabelCustom labelName={"Comp Off From Time"} mandatory={true} />
        <TimeCustomFormik name="from" placeholder="Select Time" />
      </div>
      <div>
        <LabelCustom labelName={"Comp Off To Time"} mandatory={true} />
        <TimeCustomFormik name="to" placeholder="Select Time" />
      </div>
      <div>
        <LabelCustom labelName={"Comp Off Day Type"} mandatory={true} />
        <RadioFormik name="type" options={typeOptions} />
      </div>
      <div>
        <LabelCustom labelName={"Work Reason"} mandatory={true} />
        <TextAreaFormik
          name="reason"
          
          maxLength={500}
          placeholder={"Enter the reason"}
        />
      </div>

    </div>
  );
};
