import React, { useState, useEffect } from 'react';
import { Tabs, Tab, IconButton } from '@mui/material';
import "./JobOpening.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "../../../Components/index";
import Select from "react-dropdown-select";

const Candidate = () => {

    useEffect(()=>{
        document.title = `PeopleSol - Candidate`;
        return () => {
          document.title = 'PeopleSol';
        };
      })

    const associateCandidate = () => {
    };
    const scheduleInterview = () => {
    };
    const location = useLocation();
    const navigate = useNavigate();
    const [maxRecords, setMaxRecords] = useState([]);
    const Countries = [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "50", value: 50 },
        { label: "100", value: 100 }
    ];
    const actionData = [{
        img: <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0375 13.2779C12.8689 13.2779 14.3535 11.8007 14.3535 9.97846C14.3535 8.15624 12.8689 6.67905 11.0375 6.67905C9.20612 6.67905 7.7215 8.15624 7.7215 9.97846C7.7215 11.8007 9.20612 13.2779 11.0375 13.2779Z" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M5.00836 20.0767V19.1769C5.00836 15.8774 7.72146 13.1779 11.0375 13.1779C14.3535 13.1779 17.0666 15.8774 17.0666 19.1769V20.0767" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M18.0715 20.0766H4.00356C2.29532 20.0766 0.989014 18.7768 0.989014 17.0771V5.47918C0.989014 3.77948 2.29532 2.47971 4.00356 2.47971H9.93217L11.0375 1.07996L12.1428 2.47971H18.0715C19.7797 2.47971 21.086 3.77948 21.086 5.47918V17.0771C21.086 18.7768 19.7797 20.0766 18.0715 20.0766Z" stroke="#1296B0" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>,
        action: "Associate Candidate",
        clickAction : () => associateCandidate
    }, {
        img: <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0375 13.2779C12.8689 13.2779 14.3535 11.8007 14.3535 9.97846C14.3535 8.15624 12.8689 6.67905 11.0375 6.67905C9.20612 6.67905 7.7215 8.15624 7.7215 9.97846C7.7215 11.8007 9.20612 13.2779 11.0375 13.2779Z" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M5.00836 20.0767V19.1769C5.00836 15.8774 7.72146 13.1779 11.0375 13.1779C14.3535 13.1779 17.0666 15.8774 17.0666 19.1769V20.0767" stroke="#F7941D" strokeMiterlimit="10" />
            <path d="M18.0715 20.0766H4.00356C2.29532 20.0766 0.989014 18.7768 0.989014 17.0771V5.47918C0.989014 3.77948 2.29532 2.47971 4.00356 2.47971H9.93217L11.0375 1.07996L12.1428 2.47971H18.0715C19.7797 2.47971 21.086 3.77948 21.086 5.47918V17.0771C21.086 18.7768 19.7797 20.0766 18.0715 20.0766Z" stroke="#1296B0" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>, action: "Schedule Interview",
        clickAction : () => scheduleInterview
    }]
    const theadData = ["Id",
        "Initial Name",
        "First Name",
        "Middle Name",
        "LastName",
        "Email",
        "Phone Number",
        "Photo",
        "Gender",
        "Nationality",
        "Language",
        "Marital Status",
        "Current Employer",
        "Current Job title",
        "Current Salary",
        "Current Experience",
        "Expected Salary",
        "Source Of Higher",
        "Highest Qualification",
        "Current Address",
        "Current AddressLine2",
        "Current City",
        "Current PinCode",
        "Current District",
        "Current State",
        "Current Address Country",
        "Additional Info",
        "Joining DOJ",
        "Resume Id",
        "Resume FilePath",
        "LinkedIn",
        "Facebook",
        "Twitter",
        "Website",
        "Man Power Indent Id",
        "JobOpeningId",
        "IsActive",
        "Status",
        "CreatedBy",
        "UpdatedBy",
        "DeletedBy",
        "CreatedOn",
        "LastUpdatedOn",
        "DeletedOn"]
    const tbodyData = [
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
        {
            id: "1",
            items: ["100001",
            "Mr",
            "Anuj",
            "Kumar",
            "Yadav",
            "anuj.yadav@aesssolutions.com",
            "8107829401",
            "profile.png",
            "Male",
            "Indian",
            "English, Hindi",
            "Single",
            "Infosys",
            "Developer",
            "5LPA",
            "1+ years",
            "12LPA",
            "Naukri",
            "BTech",
            "Delhi",
            "Delhi",
            "Delhi",
            "110007",
            "Delhi",
            "Delhi",
            "India",
            "-",
            "29/11/2022",
            "01",
            "sfgf/asfgfa/sdfgs.pdf",
            "www.LinkedIn.com",
            "www.Facebook.com",
            "www.Twitte.com",
            "www.Website.com",
            "A101",
            "10001",
            "true",
            "Associated",
            "Anuj Yadav",
            "Anuj Yadav",
            "Anuj Yadav",
            "29/11/2022",
            "29/11/2022",
            "29/11/2022"
            ],
        },
    ];
    return (
        <div className="moduleMainContainer">
                    <div className="tableHeaderContainer">
                        <button onClick={() => {navigate("ats/candidates/create");}} className='createFooterButton2 addProjectButton buttonClass'>+ Candidate</button>
                        <div className="chatListSearchContainer navBarSearch">
                            <input type="text" className="chatListSearch" placeholder="Search" />
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0"></circle><line x1="8.70711" y1="9" x2="12" y2="12.2929" stroke="#1296B0" strokeLinecap="round"></line>
                            </svg>
                        </div>
                        <IconButton color='primary'>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z" stroke="#1296B0" strokeWidth="1.5" />
                            </svg>
                        </IconButton>
                        <IconButton color='primary'>
                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961" stroke="#1296B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </IconButton>
                        <IconButton color='primary'>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.1949 0.95166V11.6183M10.1949 0.95166L6.63936 4.50722M10.1949 0.95166L13.7505 4.50722M1.30603 13.3961L1.85803 15.605C1.95417 15.9896 2.1761 16.331 2.48855 16.575C2.801 16.819 3.18604 16.9516 3.58247 16.9517H16.8074C17.2038 16.9516 17.5888 16.819 17.9013 16.575C18.2137 16.331 18.4357 15.9896 18.5318 15.605L19.0838 13.3961" stroke="#1296B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </IconButton>
                    </div>
                    <Table theadData={theadData} tbodyData={tbodyData} actionData={actionData} />
                    <div className="horizontalLine"></div>
                    <div className="tableFooterContainer">
                        <label>Total Record Count: </label><span> 01</span>
                        <Select className='recordDropdown' options={Countries}   placeholder='10' />
                        <label>Records Per Page</label>
                    </div>
        </div>
    )
}

export { Candidate }