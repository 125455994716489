/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";

import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { TimeLineData } from "./Timeline/TimeLineData";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../../Features/badgeUtils";
import { ConfirmPopup } from "../ConfirmPopup";

const DetailsPageDeputation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");

  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState("");

  const togglePopup = (type, color, id) => {
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol - Details Page Deputaion`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `DeputationRequests/Details?ID=${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result?.data?.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionlist.length
        ) {
          if (
            approve === "reject" ||
            approve === "hold" ||
            approve === "Confirm"
          ) {
            togglePopup(
              approve,
              result.data?.summary?.actionlist[0]?.color,
              result.data?.summary?.actionlist[0]?.id
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const PostApRejHol = async (type, id, Remark, RMId) => {
    try {
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "approve"
      ) {
        await postRequest(`DeputationRequests/${type}?Id=${id}`);
      } else if (type === "Confirm") {
        await postRequest(`DeputationRequests/Confirm?ID=${id}&RMId=${RMId}`);
      } else if (type === "End Deputation") {
        await getRequest(`DeputationRequests/EndDeputation?Id=${id}`);
      } else {
        await postRequest(
          `DeputationRequests/${type}?Id=${id}&Remarks=${Remark}`
        );
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      setIsOpen(false);
      getDetails();
      setLoading(false);
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Deputation Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data != null ? (
                  loading ? (
                    <div className="noDataFound">
                      <Loader />
                    </div>
                  ) : (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm"> Request Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Request Number"}
                          Details={data?.deputationRequests?.requestNumber}
                        />
                        <Details
                          detailTitle={"Status"}
                          Details={
                            <div
                              style={{
                                backgroundColor: getStatusColor(
                                  data?.status
                                )?.split(",")?.[0],
                                color: getStatusColor(data?.status)?.split(
                                  ","
                                )?.[1],
                                cursor: "default",
                                padding: "6px",
                                borderRadius: "6px",
                              }}
                            >
                              {data?.status}
                            </div>
                          }
                        />
                        <Details
                          detailTitle={"Deputation For"}
                          Details={data?.deputationRequests?.transferType}
                        />
                        <Details
                          detailTitle={"Deputation Reason"}
                          Details={data?.deputationRequests?.reason}
                        />
                        <Details
                          detailTitle={"Old Location"}
                          Details={data?.location ? data?.location : "-"}
                        />
                        <Details
                          detailTitle={"New Location"}
                          Details={data?.toLocation ? data?.toLocation : "-"}
                        />
                        <Details
                          detailTitle={"Deputation Effective Date"}
                          Details={format(
                            new Date(
                              data?.deputationRequests?.transferEffectiveDate
                            ),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Deputation End Date"}
                          Details={format(
                            new Date(data?.deputationRequests?.endDate),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />

                        <Details
                          detailTitle={"Created On"}
                          Details={format(
                            new Date(data?.deputationRequests?.createdOn),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                        <Details
                          detailTitle={"Last Updated On"}
                          Details={format(
                            new Date(data?.deputationRequests?.lastUpdatedOn),
                            "dd/MM/yyyy hh:mm a"
                          )}
                        />
                      </div>
                      <div className="headingForm"> Requester's Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?.deputationRequests?.employee?.name} ${data?.deputationRequests?.employee?.lastName}`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?.deputationRequests?.employee?.rO_Name}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={
                            data?.deputationRequests?.employee?.designation
                          }
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={
                            data?.deputationRequests?.employee?.department
                          }
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?.deputationRequests?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={
                            data?.deputationRequests?.employee
                              ?.personalPhoneNumber
                          }
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={
                            data?.deputationRequests?.employee?.locationName
                          }
                        />
                      </div>
                      {data?.actionlist?.length > 0 ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.actionlist?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel" &&
                                  item.type !== "End Deputation"
                                    ? togglePopup(item?.type, item?.color)
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {data?.actionlist?.length > 0 &&
                        data?.actionlist?.map(
                          (item) =>
                            isOpen && (
                              <Popup
                                popupHeading={item?.type}
                                content={
                                  item?.type === "Confirm" ? (
                                    <>
                                      <ConfirmPopup
                                        data={PostApRejHol}
                                        type={item?.type}
                                        id={selId ?? item?.id}
                                        color={colorAction}
                                        handleClose={togglePopup}
                                        locationId={
                                          data?.deputationRequests?.toLocation
                                        }
                                        departmentId={
                                          data?.deputationRequests?.employee
                                            ?.departmentId
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Remark
                                        data={PostApRejHol}
                                        type={typeAction}
                                        id={selId ?? item?.id}
                                        color={colorAction}
                                        handleClose={togglePopup}
                                      />
                                    </>
                                  )
                                }
                                handleClose={togglePopup}
                              />
                            )
                        )}
                    </div>
                  )
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLineData
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={data?.generalWorkflowsApprovals?.module}
                        requestNumber={data?.requestNumber}
                        url={"DeputationRequests"}
                      />
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={getIdFromDynamicUrl(currentUrl)}
                      module={"Deputation Request"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageDeputation };
