import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { onBoardingReqSchema } from "./RequisitonConfigSchemas";
import { Requistion } from "./Forms/Requisition";
import { useEffect } from "react";

const CreateRequisitionConfig = (props) => {
  const { state } = useLocation();
  const data = state?.id;

  const [detail, setDetail] = React.useState([]);
  const getDetails = async () => {
    try {
      const result = await getRequest(`ConfigValues/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, []);
  const dispatch = useDispatch();
  const postFormData = async (values) => {
    try {
      const result = await postRequest("ConfigValues/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "SuccessFully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {}
  };

  const onSubmit = (values) => {
    const form = { ...values, id: detail.id };
    postFormData(form)
  };

  const initialvalue = {
    key: detail?.key,
    value: detail?.value,
  };
  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  useEffect(() => {
    document.title = `PeopleSol - Create Requisition Config`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  return (
    <FormCustomFormik
      ButtonLabel={"Update Config Values"}
      FormHeading={"Edit Config Values"}
      validationSchema={onBoardingReqSchema}
      initialvalue={initialvalue}
      onSubmit={onSubmit}
      returnPage={returnPage}
      propsDiv={<Requistion data={data} />}
    />
  );
};

export { CreateRequisitionConfig };
