import React from 'react'
import { useEffect } from 'react';

const TodayComp = ({date, title, desc}) => {
  useEffect(() => {
    document.title = `PeopleSol - Today`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="attendanceContentContainer" style={{backgroundColor:`aliceblue`, border:'1px groove aqua'}}>
       <div className="dateDisplay" style={{color:'var(--primary)'}}>{date?date:""}</div>
     {/* <div className="horizontalLinePrimary calendarLine" style={{backgroundColor:`var(--primary)`}}></div> */}

        <div className='attendanceContentStatus' style={{color:`black`}} >{title?title:""}</div>
        <div style={{display:'flex'}}>
           
        <label className='attendanceIn' style={{color:'black', fontSize:'13px'}}>{desc?desc:""}</label>
            </div>
        
        </div>
  )
}

export {TodayComp}
