import React, {  useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { getRequest, postRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { ReqTypeForm } from "../../HRMSMaster/HRDeskComponents/Form/ReqTypeForm";
import { requestTypeSchema } from "../../HRMSMaster/HRDeskComponents/RequestTypeSchemas";
import { RequestTypeFormIt } from "./Form/RequestTypeFormIt";
import { useEffect } from "react";


const CreateRequestTypeIt = (props) => {
  const { state } = useLocation();
  const data = state?.id;
  const [detail, setDetail] = React.useState([]);
  const dispatch = useDispatch();
  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "ItsmRequestTypes/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Request Type`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getDetails = async () => {
    try {
      const result = await getRequest(`ItsmRequestTypes/Details?ID=${data}`);
      setDetail(result.data);
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  const initialvalue = {
    name:  data?detail?.name:"",
    requestCategoryId: data?detail?.requestCategoryId:"",
  };
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  }, [data]);

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ItsmRequestTypes/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  
  const onSubmit = async (values) => {
    
    const edit = {
      ...values,
      Id: data
      
      
     
    };
    
    const create = {
      ...values,
    };

    data?Edit(edit) :
     postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
 
  return (
    <FormCustomFormik
      FormHeading={data?'Edit IT Desk Request Type':'Create IT Desk Request Type'}
      validationSchema={requestTypeSchema}
      ButtonLabel={data?'Update IT Desk Request Type':'Create IT Desk Request Type'}
      onSubmit={onSubmit}
      initialvalue={
        initialvalue}
      returnPage={returnPage}
      propsDiv={<RequestTypeFormIt data={data}/>}
    />
  );
};


export { CreateRequestTypeIt}
