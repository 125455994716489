import React, { useEffect, useState } from "react";
import { GiphyFetch } from "@giphy/js-fetch-api";
import "./GridContainer.css";
import SocialPostImageDeleteIcon from "../../../../../Assets/SocialPostImageDeleteIcon.svg";

function GridContainer({ searchQuery, searched, width, height, setGif }) {
  let [fetched, setFetched] = useState(false);
  let [gifData, setGifData] = useState();
  const [gifId, setGifId] = useState(null);

  const giphyFetch = new GiphyFetch("UcIefYLGg8sAVMUtZmrc3pK28yXuUX0H");

  function trendingGifs() {
    return giphyFetch.trending({ limit: 20 });
  }
  function searchGifs() {
    return giphyFetch.search(searchQuery, { limit: 20 });
  }
  useEffect(() => {
    setFetched(false);
    searched
      ? searchGifs().then((res) => {
          let x = res?.data?.map((d, i) => {
            return {
              id: d?.id,
              image: d?.images?.downsized,
              name: d?.slug,
              type: d?.type,
            };
          });
          setGifData(x);
          setFetched(true);
        })
      : trendingGifs().then((res) => {
          let x = res?.data?.map((d, i) => {
            return { id: d?.id, image: d?.images?.downsized, name: d?.slug + ".gif" };
          });
          setGifData(x);
          setFetched(true);
        });
  }, [searched, searchQuery]);

  return fetched ? (
    <div className="gridContainer">
      {!searched ? (
        <div className="gridImgList">
          {gifData?.map((d, index) => (
            <div className="gridImg">
              <img
                src={d?.image?.url}
                alt={""}
                style={{ width: "100%", height: "auto" }}
                onClick={() => {
                  setGif(d);
                  setGifId(d?.id);
                }}
              />
              {gifId === d?.id && (
                <img
                  src={SocialPostImageDeleteIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                  className="imageDeselectIcon "
                  onClick={() => {
                    setGif(null);
                    setGifId(0);
                  }}
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="gridImgList">
          {gifData?.map((d, index) => (
            <div className="gridImg">
              <img
                src={d?.image?.url}
                alt={""}
                style={{ width: "100%", height: "auto" }}
                onClick={() => {
                  setGif(d);
                  setGifId(d?.id);
                }}
              />
              {gifId === d?.id && (
                <img
                  src={SocialPostImageDeleteIcon}
                  alt=""
                  style={{ marginRight: "15px" }}
                  className="imageDeselectIcon "
                  onClick={() => {
                    setGif(null);
                    setGifId(0);
                  }}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div className="loader">Loading...</div>
  );
}

export default GridContainer;
