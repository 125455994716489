import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateTaxComp } from "./CreateTaxComp";
import { format } from "date-fns";
import { useEffect } from "react";

const CreateTaxConfig = (props) => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);
  const data = state?.id;
  const [editFormData, seEditFormData] = useState(null);
  const getDetails = async () => {
    try {
      const result = await getRequest(
        `IncomeTaxDeclarationSettings/Details/${data}`
      );
      seEditFormData(result?.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Tax`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (data) {
      getDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "IncomeTaxDeclarationSettings/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm(
        "IncomeTaxDeclarationSettings/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Edited", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const initialvalue = {
    title: data ? editFormData?.title ?? "" : "",
    description: data ? editFormData?.description : null,
    startDate: data
      ? editFormData?.startDate
        ? new Date(editFormData?.startDate)
        : ""
      : "",
    endDate: data
      ? editFormData?.endDate
        ? new Date(editFormData?.endDate)
        : ""
      : "",
    emailTemplate: data ? editFormData?.emailTemplate ?? "" : "",
    attachment: data
      ? editFormData?.attachment
        ? `tax/${editFormData?.attachment}`
        : null
      : null,
    taxType: data ? editFormData?.taxType : null,
  };

  const SUPPORTED_FORMATS = ['application/pdf', 'image/jpeg', 'image/png'];


  const validationSchema = Yup.object({
    title: Yup.string().max(149," Name must be less than 150 characters").required("Please Enter Title "),
    emailTemplate: Yup.string().required("Please Select Email Template"),
    startDate: Yup.date().required("Please Select Start Date For Initiating"),
    endDate: Yup.date().required("Please Select Deadline For Filing"),
    description: Yup.string().max(499,"Description must be less than 500 characters").nullable(),
    attachment: typeof formValues?.attachment ==="object"? Yup.mixed()
      .nullable()
      .test('Fichier taille',
        'File Size should be less than 1 mb', (value) => !value || (value && value.size <= 2048 * 2048))
      .test('format',
        'File Format is Incorrect', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))):"",
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("Id", data ? data : 0);
    formData.append("description", values?.description);
    formData.append("startDate", `${format(values?.startDate, "MM/dd/yyyy")}`);
    formData.append("endDate", `${format(values?.endDate, "MM/dd/yyyy")}`);
    if (values?.emailTemplate)
      formData.append("emailTemplate", values?.emailTemplate);
      if(typeof values?.attachment === "object")
    if (values?.attachment) formData.append("upload", values?.attachment);
    if (values?.taxType) formData.append("taxType", values?.taxType);
    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Edit Tax Declaration Or Submission" : "Create Tax Declaration Or Submission"}
      ButtonLabel={data ? "Update Tax Declaration Or Submission" : "Create Tax Declaration Or Submission"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateTaxComp formValues={formValues} />}
    />
  );
};

export { CreateTaxConfig };
