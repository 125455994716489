import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { ServiceTypeFormIT } from "./Form/ServiceTypeFormIT";
import { getRequest, postRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { serviceTypeSchemaIt } from "./Schema";

const initialvalue = {
  name: "",
  notificationFlowId: null,
  requestCategoryId: "",
  categoryId: "",
  teamId: "",
  tatHigh: "",
  tatMedium: "",
  tatLow: "",
  assetCategoryID: null,
  requiremoreinformationforAssetApproval: "Yes",
  assetRequestType: "",
  assignTicketsToEmployeeId: null,
};

const CreateServiceTypeIt = (props) => {
  const { state } = useLocation();
  const [detailsform, setDetailsform] = useState({});
  const data = state?.id;

  const dispatch = useDispatch();

  const getFormDetails = async () => {
    try {
      const result = await getRequest(`ServiceTypes/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
    } finally {
    }
  };
  const postFormData = async (values) => {
    try {
      const result = await postRequest("ServiceTypes/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Service`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("ServiceTypes/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const onSubmit = async (values) => {
    const edit = {
      ...values,
      id: detailsform?.id,
      isActive:true,
      requiremoreinformationforAssetApproval:
        values?.requiremoreinformationforAssetApproval === "Yes" ? true : false,
    };

    const create = {
      ...values,
      requiremoreinformationforAssetApproval:
        values?.requiremoreinformationforAssetApproval === "Yes" ? true : false,
    };

    data ? Edit(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  const [formValues, setFormValues] = useState(null);
  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      ButtonLabel={data ? "Update Service Type" : "Create  Service Type"}
      FormHeading={data ? "Edit Service Type" : "Create Service Type"}
      validationSchema={serviceTypeSchemaIt}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              tatHigh: detailsform?.tatHigh,
              tatLow: detailsform?.tatLow,
              tatMedium: detailsform?.tatMedium,
              notificationFlowId: detailsform?.notificationFlowID,
              requestCategoryId: detailsform?.requestCategoryID,
              categoryId: detailsform?.categoryID,
              teamId: detailsform?.teamID,
              assetCategoryID: detailsform?.assetCategoryID,
              assetRequestType: detailsform?.assetRequestType ?? "",
              assignTicketsToEmployeeId: detailsform?.assignTicketsToEmployeeID,
              requiremoreinformationforAssetApproval:
                detailsform?.requiremoreinformationforAssetApproval === true
                  ? "Yes"
                  : "No",
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <ServiceTypeFormIT data={detailsform} formValues={formValues} />
      }
    />
  );
};

export { CreateServiceTypeIt };
