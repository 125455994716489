import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { format } from "date-fns";
import dateFormat from "dateformat";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";

export const Allocation = ({ id, data, type, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  // //console.log("ikd",id,color)
  const alllocate = async (val) => {
    try {
     setLoading(true);
        const result=await postRequest(`FacilityRequests/allocate`, val);

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose()
      data()
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Allocation`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getFacility = async () => {
      try {
        const result = await getRequest(`FacilityRequests/GetAssetType?Id=${id}`);
        let listData = [];
        result.data.results.map((item) => {
          listData.push({ label: item.text, value: item.id });
        });
        setEmployeeList(listData);
      } catch (error) {
        
      
      } finally {
      }
    };
    useEffect(()=>{
        getFacility()
    },[])
    const onSubmit = async (values) => {
        
        const res = {
            FacilityID:values?.FacilityID,
            id: id,
            employeeId:color
        };
      alllocate(res)
  };
  return (
    <Formik initialValues={{ FacilityID: "" }} onSubmit={onSubmit}    validationSchema={Yup.object({
      FacilityID: Yup.string().required("Please Enter Facility Reference Number"),
    })}>
      <Form>
        <LabelCustom labelName={"Facility Reference Number"} mandatory={true} />
        <SelectForm name={"FacilityID"} placeholder="Select Facility Reference Number"   options={employeeList} />
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ color: "white" }}
          >
            {"Allocate Facility"}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
