import React from "react";
import { useEffect } from "react";
const DetailData = ({ detailTitle, detailData }) => {
  // useEffect(() => {
  //   document.title = `PeopleSol - Detail Data`;
  //   return () => {
  //     document.title = 'PeopleSol';
  //   };
  // }, []);
  return (
    <div className="detailData">
      <label>{detailTitle ?? ""}</label>
      <label>:</label>
      <label className="detailsValue">{detailData ?? ""}</label>
    </div>
  );
};
export { DetailData };
