import * as Yup from "yup";
const SUPPORTED_FORMATS = ["application/pdf", "application/msword"];

export const onBoardingDocSchema = Yup.object({
  name: Yup.string().max(49," Name must be less than 50 characters").required("Please Select Name"),
  upload:Yup.mixed()
  .nullable()
  .required()
  .test(
    "Fichier taille",
    "File Size must be less than 1 mb",
    (value) => !value || (value && value.size <= 2048 * 2048) || typeof value !== "object"
  )
  .test(
    "format",
    "File Format is Incorrect",
    (value) =>
      !value || (value && SUPPORTED_FORMATS.includes(value.type)) || typeof value !== "object"
  ),
  Type: Yup.string().max(149,"Type must be less than 150 characters").nullable().required("Please Enter Type"),
});
