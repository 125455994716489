import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
} from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import { TransactionTable } from "../../../../Components/Table/TransactionTable";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

import { DatePickerCustom } from "../../../../Components/FormComponent/DatePickerCustom";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { CreateNoDue } from "./PopupForm/CreateNoDue";
const DueClearance = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = [
    { label: "All", value: "All" },
    { label: "Due not Cleared", value: "Due not Cleared" },
    { label: "Pending", value: "Pending" },
    { label: "No Due", value: "No Due" },
  ];

  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedTransferTypeList, setSelectedTransferTypeList] = useState([]);
  const [dateSelect, setDateSelect] = useState(false);
  const [selectedDesignationList, setSelectedDesignationList] = useState([]);
  const [selectedDepartmentList, setSelectedDepartmentList] = useState([]);
  const [optionDepartment, setOptionDepartment] = React.useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [optionEmployee, setOptionEmployee] = React.useState([]);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState(false);
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  const NodueCreate=async(values)=>{
      try {
      dispatch(isLoading(true));
      setLoading(true);
     
      const result=  await postRequest(`Resignations/DueClearance`, values);
      
      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDueListData();
    }
  }
  useEffect(() => {
    document.title = `PeopleSol - Due Clearence`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const clickHandlerFunction = (id, type, color) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Confirm"
    ) {
      togglePopup(type, color, id);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Confirm"
      ) {
        const result= await postRequest(`Resignations/${type}?Id=${id}`);
        dispatch(showToast({ text: result?.data?.message, severity: "success" }));
      } else if (type === "Mark as Closed") {
        const result= await postRequest(`Resignations/Close?Id=${id}&Remarks=${Remark}`);
        dispatch(showToast({ text: result?.data?.message, severity: "success" }));
      } else {
        const result= await postRequest(`Resignations/${type}?Id=${id}&Remarks=${Remark}`);
        dispatch(showToast({ text: result?.data?.message, severity: "success" }));
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDueListData();
    }
  };


  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const getActions = (actionList) => {
    let returnActionArray = [
      // {
      //   img: (
      //     <svg
      //       width="21"
      //       height="21"
      //       viewBox="0 0 21 21"
      //       fill="none"
      //       xmlns="http://www.w3.org/2000/svg"
      //     >
      //       <path
      //         opacity="0.15"
      //         d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
      //         fill="#0DB53C"
      //       />
      //       <line
      //         x1="14.7295"
      //         y1="7.43701"
      //         x2="7.72949"
      //         y2="7.43701"
      //         stroke="#0DB53C"
      //         stroke-linecap="round"
      //       />
      //       <line
      //         x1="5.72949"
      //         y1="7.43701"
      //         x2="5.61462"
      //         y2="7.43701"
      //         stroke="#0DB53C"
      //         stroke-linecap="round"
      //       />
      //       <line
      //         x1="14.7295"
      //         y1="11.3437"
      //         x2="7.72949"
      //         y2="11.3437"
      //         stroke="#0DB53C"
      //         stroke-linecap="round"
      //       />
      //       <line
      //         x1="5.72949"
      //         y1="11.3437"
      //         x2="5.61462"
      //         y2="11.3438"
      //         stroke="#0DB53C"
      //         stroke-linecap="round"
      //       />
      //       <line
      //         x1="14.7295"
      //         y1="15.2505"
      //         x2="7.72949"
      //         y2="15.2505"
      //         stroke="#0DB53C"
      //         stroke-linecap="round"
      //       />
      //       <line
      //         x1="5.72949"
      //         y1="15.2505"
      //         x2="5.61462"
      //         y2="15.2505"
      //         stroke="#0DB53C"
      //         stroke-linecap="round"
      //       />
      //     </svg>
      //   ),
      //   action: "Request Details",
      //   clickAction: (id) => getFormDetails(id),
      // },
    ];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (action.type === "Create No Due Clearance") {
          returnActionArray.push({
            img: <AddBoxOutlinedIcon sx={{color:action?.color}}/>,
            action: "Create No Due Clearance",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Create No Due Clearance",action.color),
          });
        }
      });
    }
    return returnActionArray;
  };
  const getMoreActions = (actionList) => {
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              clickHandlerFunction(
                action?.id,
                action?.type,
                "rgb(255, 128, 0)"
              ),
          });
        }
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getDueListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "Resignations/NoDueClearance/AllList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            tempData.push({
              ...item.item,
              status: item?.item?.resignation?.status,
              dateOfRelieving: item?.item?.resignation?.dateOfRelieving,
              lastUpdatedOn: item?.item?.lastUpdatedOn,
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              // actions: getActions(item?.actions),
              moreActions: getMoreActions(item?.actions),
            });
          });
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({
              createdBy,
              actions,
              moreActions,
            
              email,
              employeeId,
              fromLocationName,
              isHouseHoldGoodsTransferRequired,
              isInitialAccomodationRequired,
              isOneTimeRelocationRequired,
              isPreTransferTripRequired,
              isTravelAssistanceRequired,
              landPhone,
              locationId,
              nextApprover,
              nextApproverId,
              nextLocationApprover,
              nextLocationApproverId,
              nextLocationRequestId,
              nextRequestId,
              personNumber,
              reason,
              reportingOfficer,
              requester,
              sanctionedAmount,
              statusLabel,
              toDepartment,
              toDepartmentName,
              toLocation,
              toLocationName,
              ...rest
            }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              name,
              department,
              designation,
              status,
              dateOfRelieving,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              name,
              department,
              designation,
              status,
              dateOfRelieving,

              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getDepartment = async () => {
    try {
      const result = await getRequest("LateComingRequests/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
        return null;
      });
      setOptionDepartment(listData);
    } catch (error) {
    } finally {
    }
  };
  const getManagment = async () => {
    try {
      const result = await getRequest("Designation/GetDesignations");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setOptionEmployee(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const location = useLocation();
  useEffect(() => {
    getDueListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading=="dateOfRelieving"?"date":heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      status: selectedStatusList[0]?.value,
      designation: selectedDesignationList[0]?.label,
      exitType: selectedTransferTypeList[0]?.value,
      employeeId: selectedEmployeeList[0]?.value,
      department: selectedDepartmentList[0]?.label,
      date: dateSelect ? `${format(dateSelect,"dd/MM/yyyy")}` : null,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setSelectedStatusList([]);
    setDateSelect(false)
    setSelectedEmployeeList([]);
    setSelectedDesignationList([]);
    setSelectedTransferTypeList([]);
    setSelectedDepartmentList([]);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      status: null,
      employeeId: null,
      exitType: null,
      designation: null,
      department: null,
      date:null
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
    } else {
      let arr = [];
      tbodyData?.map((item) => {
        if (
          originalData.find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
            .actions?.length > 1
        )
          arr.push(item.Id ?? item.id);
          return null
      });
      setSelectedRows(arr);
    }
  };

  return (
    <div className="fullTableContainer noTab">
       <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton onClick={() => setFilterSearchToogle((prev) => !prev)} title="close">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>
          <div className="filterListContainer">
            <div className="filterContainer">
              <p> Status </p>
              <SelectCustom
                options={status}
                values={selectedStatusList}
                onChange={(value) => {
                  setSelectedStatusList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Department </p>
              <SelectCustom
                onDropdownOpen={() => {
                  getDepartment();
                }}
                options={optionDepartment}
                values={selectedDepartmentList}
                onChange={(value) => {
                  setSelectedDepartmentList(value);
                }}
              />
            </div>
            <div className="filterContainer">
            <p>Date</p>
            <DatePickerCustom 
              selected={dateSelect}
              onChange={(date) => setDateSelect(date)}
            />
          </div>
            <div className="filterContainer">
              <p>Designation </p>
              <SelectCustom
                onDropdownOpen={() => {
                  getManagment();
                }}
                options={optionEmployee}
                values={selectedDesignationList}
                onChange={(value) => {
                  setSelectedDesignationList(value);
                }}
              />
            </div>
            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>
          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer">


        <div className="search-box" >
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn" >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <Tooltip arrow title="Filter" placement="top">

        <IconButton
          color="primary"
          onClick={() => {
            setFilterSearchToogle((prev) => !prev);
          }}
        >
          {!filterSearchToogle ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                stroke="#1296B0"
                strokeWidth="1.5"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7579 1.56897L0.757935 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 1.56897L18.7579 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </IconButton>
        </Tooltip>


      </div>
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            <TransactionTable
              selectAll={selectAll}
              tableFilterName="relocationAllListFilter"
              tbodyData={tbodyData}
              headerAction={sortUpdated}
              sortParam={sortParam}
              sortType={sortType}
              selectedRows={selectedRows}
              addToSelectedRows={addToSelectedRows}
              originalData={originalData}
              containStatus={true}
              containTat={true}
            />
          </>
        )}
        <div className="horizontalLine"></div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords} dropdownPosition="top"
             
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </>

      {isOpen && (
        <Popup
          popupHeading={typeAction}
          content={
            <>
              <CreateNoDue
              loading={loading}
              data={NodueCreate}
                type={typeAction}
                id={selId}
                color={colorAction}
                handleClose={togglePopup}
              />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export { DueClearance };
