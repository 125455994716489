import React, { useEffect, useState } from "react";
import "./Team.css";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getRequest } from "../../../Services/axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { useLocation } from "react-router-dom";
import { getInitialLetter } from "../../../Utilities/getInitialLetter";

const Team = () => {
  // //console.log('data',data);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [teamList, setTeamList] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol -  Team`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getTeamData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Settings/Home`);
      setTeamList(result?.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("team")) getTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loader />;
  else
    return (
      <div className="teamContainer">
        <div className="teamContentHolder">
          {/* {//console.log('team data', data?.data?.value)} */}

          {teamList?.teamMembers?.map((item) => {
            // //console.log('item', item)
            return (
              <div
                className="teamMembersCard"
                style={{ backgroundSize: "140px", paddingBottom: "10px" }}
              >
                {item?.profilePic ? (
                  <img
                    className="teamMembersImg"
                    src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${item?.profilePic}`}
                    alt="Profile"
                  />
                ) : (
                  <label
                    className="childDisplayText teamMembersImg"
                    style={{ fontSize: "70px", width: "calc(100% - 67px)" }}
                  >
                    {item?.employeeName
                      ? getInitialLetter(item?.employeeName)
                      : item?.employeeName.slice(0, 1).toUpperCase()}
                  </label>
                )}
                {/* { item?.image ? <img src={item?.image}
        alt="Profile" 
        style={{borderRadius: "100%", height:"8rem", aspectRatio:"1"}}
        /> : } */}
                <label className="teamMemberName">
                  {item.employeeName.split("(")[0]}
                </label>
                <label className="teamMemberName" style={{ color: "black" }}>
                  {item.employeeName.split("(")[1]?.slice(0, -1)}
                </label>
                <div className="teamMemberLine"></div>
                <label className="teamMemberRole">
                  {/* {item.accessLevel.split("(")[0]} */}
                  {item.role}
                </label>
                {/* <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Designation</label>
          <label className="teamMemberDesignation">{item.title}</label>
          </div>
          <div className="teamTileContentHolder">
          <label>Employee Status</label>
          <div className="teamMemberStatus">{item.employmentStatus ? "Employee" : "Employee"}</div>
          </div>
        </div> */}
                {/* <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Email</label>
          <label className="teamMemberEmail">{item.email}</label>
          </div>
        </div> */}
                {/* <div className="badgeHolderTeam">
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
        </div> */}
              </div>
            );
          })}

          {/* <div className="teamCard">
        <img src="/static/media/ProfileDemo.a04ba2eef9874a863ad668e09090bce5.svg" alt="Profile"/>
        <label className="teamMemberName">Jenny James</label>
        <label className="teamMemberRole">UI/UX Designer</label>
        <div className="teamMemberLine"></div>
        <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Designation</label>
          <label className="teamMemberDesignation">Gen. Manager</label>
          </div>
          <div className="teamTileContentHolder">
          <label>Employee Status</label>
          <div className="teamMemberStatus">Intern</div>
          </div>
        </div>
        <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Email</label>
          <label className="teamMemberEmail">md.shariq@aesssolutions.com</label>
          </div>
        </div>
        <div className="badgeHolderTeam">
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
        </div>
        </div> */}

          {/* <div className="teamCard">
        <img src="/static/media/ProfileDemo.a04ba2eef9874a863ad668e09090bce5.svg" alt="Profile"/>
        <label className="teamMemberName">Jenny James</label>
        <label className="teamMemberRole">UI/UX Designer</label>
        <div className="teamMemberLine"></div>
        <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Designation</label>
          <label className="teamMemberDesignation">Gen. Manager</label>
          </div>
          <div className="teamTileContentHolder">
          <label>Employee Status</label>
          <div className="teamMemberStatus">Intern</div>
          </div>
        </div>
        <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Email</label>
          <label className="teamMemberEmail">md.shariq@aesssolutions.com</label>
          </div>
        </div>
        <div className="badgeHolderTeam">
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
        </div>
        </div>

        <div className="teamCard">
        <img src="/static/media/ProfileDemo.a04ba2eef9874a863ad668e09090bce5.svg" alt="Profile"/>
        <label className="teamMemberName">Jenny James</label>
        <label className="teamMemberRole">UI/UX Designer</label>
        <div className="teamMemberLine"></div>
        <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Designation</label>
          <label className="teamMemberDesignation">Gen. Manager</label>
          </div>
          <div className="teamTileContentHolder">
          <label>Employee Status</label>
          <div className="teamMemberStatus">Intern</div>
          </div>
        </div>
        <div className="subTeamContainer">
          <div className="teamTileContentHolder">
          <label>Email</label>
          <label className="teamMemberEmail">md.shariq@aesssolutions.com</label>
          </div>
        </div>
        <div className="badgeHolderTeam">
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
          <div className="teamBadgeContainer">
            <div className="teambadge"/>
            <label>Document</label>
          </div>
        </div>
        </div> */}
        </div>
      </div>
    );
};

export { Team };
