import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { GeneralDetails } from "./GeneralDetails";
import { Entitlement } from "./Entitlement";
import { Applicable } from "./Applicable";
import { Restrictions } from "./Restrictions";
import {
  FormikMultiStepForm,
  FormikMultiStepFormStep,
} from "../../../../Components/FormikMultiStepForm/FormikMultiStepForm";

const initialValues = {
  name: "", //General Details
  code: "",
  type: "",
  balanceBasedOn: "",
  validityFrom: null,
  validityTo: null,
  // entitlement:getEntitlementValues(),
  entitlement: {
    effectiveAfterValue: 0, //Entitlement
    effectiveAfterDuration: null,
    effectiveAfterType: null,
    accrualType: null,
    accrualOnDay: null,
    accrualOnMonth: null,
    accrualDays: 0,
    accrualIn: null,
    resetType: null,
    resetDays: null,
    resetMonth: null,
    resetCarryForwardType: null,
    resetCarryForwardValue: 0,
    resetCarryForwardUnitType: null,
    addOpeningBalance: 0,
  },
  // applicable:getApplicableValues(),
  applicable: {
    gender: null, //Applicable
    maritalStatus: null,
    department: null,
    designation: null,
    locationName: null,
    role: null,
    employmentType: null,
    sourceOfHire: null,
    onboardingStatus: null,
    employee: null,
  },
  //  restrictions:getRestrictionValues(),
  restrictions: {
    weekends: null, //Restrictions
    holidays: null,
    holidayLeaveCountAfterDays: 0,
    weekendLeaveCountAfterDays: 0,
    exceedLeavebalance: null,
    duratiuonAllowed: null,
    pastReqDays: 0,
    nextReqDays: 0,
    advanceReqDays: 0,
    minAppLeave: 0,
    maxAppLeave: 0,
    isZero: null,
  },
};

const CreateLeaveTypeComp = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Leave Type `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();

  // const data = state?.selectedRecord;
  const data = state?.id;
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LeaveTypes/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("LeaveTypes/Create", values);
      if (result.data === "Leave type has been created successfully.")
        dispatch(showToast({ text: result.data, severity: "success" }));

      if (result.data === "An item with the same name already exists!")
        dispatch(showToast({ text: result.data, severity: "error" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      returnPage()
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("LeaveTypes/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
    finally{
      returnPage()
    }
  };

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: detailsform?.leaveTypes?.id,
      name: values?.name,
      color: "red",
      type: values?.type,
      balanceBasedOn: values?.balanceBasedOn,
      validityFrom:
        values?.validityFrom != null ? new Date(values.validityFrom) : null,
      validityTo:
        values?.validityTo != null ? new Date(values.validityTo) : null,
      entitlement: {
        effectiveAfterValue: values?.effectiveAfterValue,
        effectiveAfterDuration: values?.effectiveAfterDuration,
        effectiveAfterType: values?.effectiveAfterType,
        accrualType: values?.accrualType,
        accrualOnDay: values?.accrualOnDay,
        accrualOnMonth: values?.accrualOnMonth,
        accrualDays: values?.accrualDays,
        accrualIn: values?.accrualIn,
        resetType: values?.resetType,
        resetDays: values?.resetDays,
        resetMonth: values?.resetMonth,
        resetCarryForwardType: values?.resetCarryForwardType,
        resetCarryForwardValue: values?.resetCarryForwardValue,
        resetCarryForwardUnitType: values?.resetCarryForwardUnitType,
        addOpeningBalance: values?.addOpeningBalance,
      },

      applicable: {
        gender: values?.gender,
        maritalStatus: values?.maritalStatus,
        department: values?.department,
        designation: values?.designation,
        locationName: values?.locationName,
        role: values?.role,
        employmentType: values?.employmentType,
        sourceOfHire: values?.sourceOfHire,
        onboardingStatus: values?.onboardingStatus,
        employee: values?.employee,
      },

      restrictions: {
        weekends: values?.weekends,
        holidays: values?.holidays,
        exceedLeavebalance: values?.exceedLeavebalance,
        duratiuonAllowed: values?.duratiuonAllowed,
        pastReqDays: values?.pastReqDays,
        nextReqDays: values?.nextReqDays,
        advanceReqDays: values?.advanceReqDays,
        minAppLeave: values?.minAppLeave,
        maxAppLeave: values?.maxAppLeave,
        isZero: values?.isZero === "Yes" ? true : false,
      },
    };

    const create = {
      ...values,
      id: 0,
      name: values.name,
      type: values.type,
      balanceBasedOn: values.balanceBasedOn,
      validityFrom:
        values?.validityFrom != null ? new Date(values.validityFrom) : null,
      validityTo:
        values?.validityTo != null ? new Date(values.validityTo) : null,
      entitlement: {
        effectiveAfterValue: values.effectiveAfterValue,
        effectiveAfterDuration: values.effectiveAfterDuration,
        effectiveAfterType: values.effectiveAfterType,
        accrualType: values.accrualType,
        accrualOnDay: values.accrualOnDay,
        accrualOnMonth: values.accrualOnMonth,
        accrualDays: values.accrualDays,
        accrualIn: values.accrualIn,
        resetType: values.resetType,
        resetDays: values.resetDays,
        resetMonth: values.resetMonth,
        resetCarryForwardType: values.resetCarryForwardType,
        resetCarryForwardValue: values.resetCarryForwardValue,
        resetCarryForwardUnitType: values.resetCarryForwardUnitType,
        addOpeningBalance: values.addOpeningBalance,
      },

      applicable: {
        gender: values.gender,
        maritalStatus: values.maritalStatus,
        department: values.department,
        designation: values.designation,
        locationName: values.locationName,
        role: values.role,
        employmentType: values.employmentType,
        sourceOfHire: values.sourceOfHire,
        onboardingStatus: values.onboardingStatus,
        employee: values.employee,
      },

      restrictions: {
        weekends: values.weekends,
        holidays: values.holidays,
        exceedLeavebalance: values.exceedLeavebalance,
        duratiuonAllowed: values.duratiuonAllowed,
        pastReqDays: values.pastReqDays,
        nextReqDays: values.nextReqDays,
        advanceReqDays: values.advanceReqDays,
        minAppLeave: values.minAppLeave,
        maxAppLeave: values.maxAppLeave,
        isZero: values.isZero === "Yes" ? true : false,
      },
    };
    data ? Edit(edit) : createPageData(create);
    // action.resetForm();
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/timeattendance/leavetype");
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormikMultiStepForm
      chooseFormValues={chooseFormValues}
      initialValues={
        data
          ? {
              ...initialValues,
              id: detailsform?.leaveTypes?.id,
              name: detailsform?.leaveTypes?.name,
              code: detailsform?.leaveTypes?.code,          
              type: detailsform?.leaveTypes?.type,
              balanceBasedOn: detailsform?.leaveTypes?.balanceBasedOn,
              validityFrom: detailsform?.leaveTypes?.validityFrom
                ? new Date(detailsform?.leaveTypes?.validityFrom)
                : "",
              validityTo: detailsform?.leaveTypes?.validityTo
                ? new Date(detailsform?.leaveTypes?.validityTo)
                : "",

              effectiveAfterValue:
                detailsform?.leaveTypes?.entitlement?.effectiveAfterValue,
              effectiveAfterDuration:
                detailsform?.leaveTypes?.entitlement?.effectiveAfterDuration,
              effectiveAfterType:
                detailsform?.leaveTypes?.entitlement?.effectiveAfterType,
              accrualType: detailsform?.leaveTypes?.entitlement?.accrualType,
              accrualOnDay: detailsform?.leaveTypes?.entitlement?.accrualOnDay,
              accrualOnMonth:
                detailsform?.leaveTypes?.entitlement?.accrualOnMonth,
              accrualDays: detailsform?.leaveTypes?.entitlement?.accrualDays,
              accrualIn: detailsform?.leaveTypes?.entitlement?.accrualIn,
              resetType: detailsform?.leaveTypes?.entitlement?.resetType,
              resetDays: detailsform?.leaveTypes?.entitlement?.resetDays,
              resetMonth: detailsform?.leaveTypes?.entitlement?.resetMonth,
              resetCarryForwardType:
                detailsform?.leaveTypes?.entitlement?.resetCarryForwardType,
              resetCarryForwardValue:
                detailsform?.leaveTypes?.entitlement?.resetCarryForwardValue,
              resetCarryForwardUnitType:
                detailsform?.leaveTypes?.entitlement?.resetCarryForwardUnitType,
              addOpeningBalance:
                detailsform?.leaveTypes?.entitlement?.addOpeningBalance,

              gender: detailsform?.leaveTypes?.applicable?.gender,
              maritalStatus: detailsform?.leaveTypes?.applicable?.maritalStatus,
              department: detailsform?.leaveTypes?.applicable?.department,
              designation: detailsform?.leaveTypes?.applicable?.designation,
              locationName: detailsform?.leaveTypes?.applicable?.locationName,
              role: detailsform?.leaveTypes?.applicable?.role,
              sourceOfHire: detailsform?.leaveTypes?.applicable?.sourceOfHire,
              onboardingStatus:
                detailsform?.leaveTypes?.applicable?.onboardingStatus,
              employee: detailsform?.leaveTypes?.applicable?.employee,
              employmentType:
                detailsform?.leaveTypes?.applicable?.employmentType,
              weekends: detailsform?.leaveTypes?.restrictions?.weekends,
              holidays: detailsform?.leaveTypes?.restrictions?.holidays,
              exceedLeavebalance:
                detailsform?.leaveTypes?.restrictions?.exceedLeavebalance,
              duratiuonAllowed:
                detailsform?.leaveTypes?.restrictions?.duratiuonAllowed,
              pastReqDays: detailsform?.leaveTypes?.restrictions?.pastReqDays,
              nextReqDays: detailsform?.leaveTypes?.restrictions?.nextReqDays,
              advanceReqDays:
                detailsform?.leaveTypes?.restrictions?.advanceReqDays,
              minAppLeave: detailsform?.leaveTypes?.restrictions?.minAppLeave,
              maxAppLeave: detailsform?.leaveTypes?.restrictions?.maxAppLeave,
              isZero:
                detailsform?.leaveTypes?.restrictions?.isZero === true
                  ? "Yes"
                  : "No",
            }
          : initialValues
      }
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      returnPage={returnPage}
      heading={data ? "Edit Leave Type" : "Create Leave Type"}
      submitText={data ? "Update Leave Type" : "Create Leave Type"}
      loading={loading}
    >
      <FormikMultiStepFormStep
        label="General Details"
        validationSchema={Yup.object({
          name: Yup.string().required("Please Select Leave Type Name"),
          code: Yup.string().required("Please Enter Leave Type Code"),
          type: Yup.string().required("Please Select Leave Type"),
          balanceBasedOn: Yup.string().required(
            "Please Select What Your Balance Is Based On"
          ),
        })}
      >
        <GeneralDetails data={detailsform} formValues={formValues} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Entitlement"
        validationSchema={Yup.object({
          effectiveAfterValue: Yup.number()
            .max(999, "Effective After must be less than a 999 ")
            .notRequired("Please Enter Effective After ")
            .nullable(),
          accrualDays: Yup.number()
            .max(999, "Accural Days must be less than a 999 ")
            .notRequired("Please Enter Accural Days ")
            .nullable(),
          resetCarryForwardValue: Yup.number()
            .max(999, "Carry Forward Value must be less than a 999 ")
            .notRequired("Please Enter Carry Forward Value ")
            .nullable(),
          addOpeningBalance: Yup.number()
            .min(0)
            .max(999, "Opening Balance must be less than a 999 ")
            .notRequired("Please Enter Opening Balance ")
            .nullable(),
        })}
      >
        <Entitlement formValues={formValues} data={detailsform} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep label="Applicable">
        <Applicable data={detailsform} />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Restrictions"
        validationSchema={Yup.object({
          pastReqDays: Yup.number()
            .max(999, "Past Days must be less than a 999 ")
            .notRequired("Please Enter Past Days ")
            .nullable(),
          nextReqDays: Yup.number()
            .max(999, "Future Days must be less than a 999 ")
            .notRequired("Please Enter Future Days ")
            .nullable(),
          advanceReqDays: Yup.number()
            .max(999, "Advance Days  must be less than a 999 ")
            .notRequired("Please Enter Advance Days  ")
            .nullable(),
          minAppLeave: Yup.number()
            .max(999, "Min Avail Leave Value must be less than a 999 ")
            .notRequired("Please Enter Min Avail Leave  ")
            .nullable(),
          maxAppLeave: Yup.number()
            .max(999, "Max Avail Leave must be less than a 999 ")
            .notRequired("Please Enter Max Avail Leave ")
            .nullable(),
        })}
      >
        <Restrictions data={detailsform} />
      </FormikMultiStepFormStep>
    </FormikMultiStepForm>
  );
};

export { CreateLeaveTypeComp };
