import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { ReqCategoryForm } from "./Form/ReqCategoryForm";
import { reqCategorySchema } from "./RequestCategorySchemas";
import { useEffect } from "react";

const initialvalue = {
  name: "",
};

const CreateRequestCategory = (props) => {
  const { state } = useLocation();
  const data = state?.selectedRecord;
  const dispatch = useDispatch();
  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "HRHelpdeskRequestCategories/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "HRHelpdeskRequestCategories/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  const onSubmit = async (values) => {

    const edit = {
      ...values,
      id:data?.Id
    };

    const create = {
      ...values,
    };
    
    data ? Edit(edit) : postFormData(create);


   
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Request Cotegory`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/hrdesk/requestcategory");
  };

  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Request Category':'Create Request Category'}
      FormHeading={data?'Edit Request Category':'Create Request Category'}
      validationSchema={reqCategorySchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: data?.Name,
            }
          : 
          initialvalue
      }
      returnPage={returnPage}
      propsDiv={<ReqCategoryForm />}
    />
  );
};

export { CreateRequestCategory };
