import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { useSelector } from "react-redux";
import { HospitalityApprove } from "../Components/HospitalityApprove";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { TimelineHospitality } from "../../HRMS/Components/DetailsComp/TimelineHospitality";

const DetailsPageHospitality = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const [isOpen, setIsOpen] = useState(false);
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [listId, setListId] = useState(0);

  const { LoggedInUser } = useSelector((state) => state.auth);
  function extractValuesFromURL(url) {
    //debugger;
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [selId, setSelId] = useState("");
  const togglePopup = (id, type, color) => {
    //debugger;
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };

  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `HospitalityRequests/Details?ID=${getIdFromDynamicUrl(currentUrl)}`
      );
      // console.log("result details kdsfhdifuh", result);
      setData(result.data);
      setListId(result.data.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionList.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.summary?.actionList[0]?.id,
              approve,
              result.data?.summary?.actionList[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionList[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Hospitality Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const PostApRejHol = async (
    type,
    id,
    Remark,
    listname,
    listid1,
    updatedHotelId
  ) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await getRequest(`HospitalityRequests/${type}?ID=${id}`);
      } else if (type === "Mark as Closed") {
        await getRequest(
          `HospitalityRequests/Close?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Accept by manager") {
        await getRequest(`HospitalityRequests/Approve?Id=${id}`);
      } else if (type === "Reject by manager") {
        await getRequest(
          `HospitalityRequests/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Approve by hospitality manager") {
        await postRequest(
          `HospitalityRequests/Hospitalityapprove?Id=${id}&UpdatedHotelId=${updatedHotelId}`
        );
      } else if (type === "Reject by hospitality manager") {
        await getRequest(
          `HospitalityRequests/HospitalityManagerReject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Accept by hotel manager") {
        await getRequest(`HospitalityRequests/Hotelapprove?Id=${id}`);
      } else if (type === "Reject by hotel manager") {
        await getRequest(
          `HospitalityRequests/HotelReject?Id=${id}&Remarks=${Remark}`
        );
      } else {
        await getRequest(
          `HospitalityRequests/${type}?Id=${id}&Remarks=${Remark}`
        );
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  useEffect(() => {
    getDetails();
  }, [LoggedInUser.id]);
  // console.log("data", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Hospitality Request Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {data && (
              <>
                {tabIndex === 0 &&
                  (data != null ? (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm"> Request Details</div>
                      <div className="detailInner">
                        <>
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?.data?.requestNumber}
                          />
                          <Details
                            detailTitle={"Guest Of"}
                            Details={data?.summary?.guest}
                          />
                          <Details
                            detailTitle={"Guest Type"}
                            Details={data?.data?.guestType}
                          />
                          <Details
                            detailTitle={"Purpose"}
                            Details={data?.data?.purpose}
                          />
                          <Details
                            detailTitle={"Preferred Hotel"}
                            Details={data?.data?.hotel?.name}
                          />
                          <Details
                            detailTitle={"Recommended Hotel"}
                            Details={data?.summary?.rechotelname}
                          />
                          <Details
                            detailTitle={"Status"}
                            Details={
                              <div
                                style={{
                                  backgroundColor: getStatusColor(
                                    // data?.data?.status
                                    data?.summary?._statusLabel
                                  )?.split(",")?.[0],
                                  color: getStatusColor(
                                    // data?.data?.status
                                    data?.summary?._statusLabel
                                  )?.split(",")?.[1],
                                  cursor: "default",
                                  padding: "6px",
                                  borderRadius: "6px",
                                }}
                              >
                                {data?.summary?._statusLabel}
                              </div>
                            }
                          />
                          <Details
                            detailTitle={"No of Days"}
                            Details={data?.data?.noofDays}
                          />
                          <Details
                            detailTitle={"Check-In Date and Time"}
                            Details={data?.data?.checkinDateandTime.replace(
                              "T",
                              " "
                            )}
                          />

                          <Details
                            detailTitle={"Created On"}
                            Details={data?.data?.createdOn.replace("T", " ")}
                          />
                          <Details
                            detailTitle={"Check-Out Date and Time"}
                            Details={data?.data?.checkOutDateandTime.replace(
                              "T",
                              " "
                            )}
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={data?.data?.lastUpdatedOn.replace(
                              "T",
                              " "
                            )}
                          />
                        </>
                      </div>

                      <div style={{ width: "100%" }}>
                        <h4 className="headingForm">Guest's Details</h4>
                      </div>
                      <div className="">
                        <table
                          className="tabless tabless-bordered"
                          style={{
                            fontSize: "13px",
                            color: "#4e5e6a",
                            margin: "0px 18px 0 18px",
                          }}
                        >
                          <thead
                            style={{
                              backgroundColor: "var(--primary-hover)",
                            }}
                          >
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Sl. No.{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Full Name{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Phone Number{" "}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Email
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Designation
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                Remarks
                              </td>
                            </tr>
                          </thead>
                          {data?.data?.items?.map((item, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                {index + 1}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                {item.name}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                {item.phoneNumber}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                {item.emailId}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                {item.designation}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  textAlign: "start",
                                  paddingLeft: "10px",
                                }}
                              >
                                {item.remarks}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                      <div className="headingForm"> Requester's Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?.data?.employee?.name} ${data?.data?.employee?.lastName}`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?.data?.employee?.rO_Name}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={data?.data?.employee?.designation}
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={data?.data?.employee?.department}
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?.data?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={data?.data?.employee?.personalPhoneNumber}
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={data?.data?.employee?.locationName}
                        />
                      </div>

                      {data?.summary?.actions ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.summary?.actions?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Accept" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel" &&
                                  item.type !== "Accept by hotel manager"
                                    ? togglePopup(
                                        item?.id,
                                        item?.type,
                                        item?.color
                                      )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {data?.summary?.actions?.map(
                        (item) =>
                          isOpen && (
                            <Popup
                              popupHeading={typeAction}
                              content={
                                typeAction ===
                                "Approve by hospitality manager" ? (
                                  <HospitalityApprove
                                    data={PostApRejHol}
                                    listId={listId}
                                    type={typeAction}
                                    id={selId}
                                    color={colorAction}
                                    handleClose={togglePopup}
                                  />
                                ) : (
                                  <Remark
                                    loading={loading}
                                    data={PostApRejHol}
                                    type={typeAction}
                                    id={selId}
                                    color={colorAction}
                                    handleClose={togglePopup}
                                  />
                                )
                              }
                              handleClose={togglePopup}
                            />
                          )
                      )}
                    </div>
                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <TimelineHospitality
                          id={getIdFromDynamicUrl(currentUrl)}
                          module={"Hospitality Requests"}
                          requestNumber={data?.data?.requestNumber}
                        />
                        {/* <Comments id={getIdFromDynamicUrl(currentUrl)} module={data?.data?.module} requestNumber={
                          data?.data?.manpowerIndent?.requestNumber
                        } /> */}
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={"Hospitality Requests"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsPageHospitality };
