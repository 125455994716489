import React from "react";

import { ChaptersTab } from "./ChaptersTab";
import { useEffect } from "react";

const Chapters = () => {
  useEffect(() => {
    document.title = `PeopleSol - Chapter`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <ChaptersTab title="Learning Lesson" />
    </div>
  );
};

export { Chapters };
