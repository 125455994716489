export const GroupObjectBy = (object) => {
    // Return the end result
    return Object.keys(object).reduce((result, currentKey) => {
      const currentValue = object[currentKey];
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentKey] = result[currentKey] || []).push(currentValue);
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };
  