import React, {  } from "react";
// import "./Policies.css";
import { PoliciesTabN } from "./PoliciesTabN";
import { useEffect } from "react";

const Policies = () => {
  useEffect(() => {
    document.title = `PeopleSol - Company Policies`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <PoliciesTabN title="policies"/>
    </div>
  );
};

export { Policies };
