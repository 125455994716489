import React, { useState, useEffect } from "react";
import "./Approvals.css";
import EditPencilIcon from "../../Assets/EditPencilIcon.svg";
import AttendanceRegIcon from "../../Assets/AttendanceRegIcon.svg";
import EarlyGoingIcon from "../../Assets/EarlyGoingIcon.svg";
import LateComingIcon from "../../Assets/LateComingIcon.svg";
import OutdoorIcon from "../../Assets/OutdoorIcon.svg";
import HrDeskIcon from "../../Assets/HrDeskIcon.svg";
import LoanAdvanceIcon from "../../Assets/LoanAdvanceIcon.svg";
import LeavesIcon from "../../Assets/LeavesIcon.svg";
import DeputationIcon from "../../Assets/DeputationIcon.svg";
import ApprovalsContent from "./Components/ApprovalsContent";
import HomeDashboardBackground from "../../Assets/HomeDashboardBackground.svg";
import RewardIconOrange from "../../Assets/RewardIconOrange.svg";
import InfoIcon from "../../Assets/InfoIcon.svg";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import RelocationIcon from "../../Assets/RelocationIcon.svg";
import ITHelpDeskIcon from "../../Assets/ITHelpDeskIcon.svg";
import ExceptionRequestsIcon from "../../Assets/ExceptionRequestsIcon.svg";
import AdminFacilityReqIcon from "../../Assets/AdminFacilityReqIcon.svg";
import TravelAdvanceIcon from "../../Assets/TravelAdvanceIcon.svg";
import ApprovalTravelIcon from "../../Assets/ApprovalTravelIcon.svg";
import ProfileAvatar from "../../Assets/ProfileAvatar.svg";
import ResignationIcon from "../../Assets/ResignationIcon.svg";
// import DueClearanceIcon from "../../Assets/DueClearanceIcon.svg";
// import SalaryIcon from "../../Assets/SalaryIcon.svg";
// import DeclarationIcon from "../../Assets/DeclarationIcon.svg";
// import SubmissionIcon from "../../Assets/SubmissionIcon.svg";
// import InternalJobsIcon from "../../Assets/InternalJobsIcon.svg";
// import ReferralIcon from "../../Assets/ReferralIcon.svg";
import GoalsIcon from "../../Assets/GoalsIcon.svg";
// import AppraisalsIcon from "../../Assets/AppraisalsIcon.svg";
import RewardsIcon from "../../Assets/RewardsIcon.svg";
import AssetIcon from "../../Assets/AssetIcon.svg";
import SkillsIcon from "../../Assets/SkillsIcon.svg";
import CompetenciesIcon from "../../Assets/CompetenciesIcon.svg";
// import LearningIcon from "../../Assets/LearningIcon.svg";
// import TrainingIcon from "../../Assets/TrainingIcon.svg";
import GatePassIcon from "../../Assets/GatePassIcon.svg";
import MeetingRoomIcon from "../../Assets/MeetingRoomIcon.svg";
import CompanyCarIcon from "../../Assets/CompanyCarIcon.svg";
import CompanyFoodIcon from "../../Assets/CompanyFoodIcon.svg";
import CompanyHospitalityIcon from "../../Assets/CompanyHospitalityIcon.svg";
import VisitorsIcon from "../../Assets/VisitorsIcon.svg";
import SendCourierIcon from "../../Assets/SendCourierIcon.svg";
import ReceiveCourierIcon from "../../Assets/ReceiveCourierIcon.svg";
import ContactsIcon from "../../Assets/ContactsIcon.svg";
import ReimbursementIcon from "../../Assets/ReimbursementIcon.svg";
import HotelBookingIcon from "../../Assets/HotelBookingIcon.svg";
import TravelBookingIcon from "../../Assets/TravelBookingIcon.svg";
import OvertimeIcon from "../../Assets/OvertimeIcon.svg";
import CompoffIcon from "../../Assets/CompoffIcon.svg";

import {
  // AttendanceReg,
  ApprovalEarlyGoing,
  ApprovalLateComing,
  ApprovalOutdoors,
  ApprovalHRDesk,
  ApprovalLoanAdvance,
  ApprovalGatePass,
  ApprovalLeave,
  ApprovalDeputation,
  ApprovalRelocation,
  ApprovalITHelpDesk,
  ApprovalException,
  ApprovalTravelAdvance,
  ApprovalCompanyFood,
  ApprovalCompanyVehicle,
  ApprovalCompanyHospitality,
  ApprovalRequisition,
  ApprovalPlanning,
  ApprovalCompOffs,
  ApprovalTimesheet,
  ApprovalFacilities,
  ApprovalResignation,
  ApprovalGoalSettings,
  ApprovalAppraisals,
  ApprovalRewards,
  ApprovalSkills,
  ApprovalCompetencies,
  ApprovalTraining,
  ApprovalMeetingRoom,
  ApprovalVisitors,
  ApprovalSendCourier,
  ApprovalKeys,
  ApprovalTravelBooking,
  ApprovalHotelBooking,
  ApprovalReimbursement,
} from "./SidebarComponents";
import { getRequest } from "../../Services/axios";
import RequisitionIcon from "../../Assets/RequisitionIcon.svg";
import { showToast } from "../../Features";
import { useDispatch } from "react-redux";
import { ApprovalRegularisation } from "./SidebarComponents/ApprovalRegularisation";
import { useSelector } from "react-redux";
import { ApprovalRecieveCourier } from "./SidebarComponents/ApprovalRecieveCourier";
import { ApprovalOvertime } from "./SidebarComponents/ApprovalOvertime";
const Approvals = () => {
  const [active, setActive] = useState(true);
  const clickHandlerSearch = (item) => {
    setIdActive(item?.id);
    navigate(item?.navigate);
    // setActive(prev => !prev);
  };
  const [idActive, setIdActive] = useState(0);
  const [s, setS] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParam] = useState(["name"]);
  const dispatch = useDispatch();
  const [bannerList, setBannerList] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(s.toLowerCase()) > -1
        );
      });
    });
  }

  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("requisition")) {
      setIdActive(1);
    } else if (currentpath.includes("planning")) {
      setIdActive(2);
    } else if (currentpath.includes("regularisation")) {
      setIdActive(3);
    } else if (currentpath.includes("latecoming")) {
      setIdActive(4);
    } else if (currentpath.includes("earlygoing")) {
      setIdActive(5);
    } else if (currentpath.includes("leave")) {
      setIdActive(6);
    } else if (currentpath.includes("compoffs")) {
      setIdActive(7);
    } else if (currentpath.includes("outdoors")) {
      setIdActive(8);
    } else if (currentpath.includes("timesheet")) {
      setIdActive(9);
    } else if (currentpath.includes("hrdesk")) {
      setIdActive(10);
    } else if (currentpath.includes("facilities")) {
      setIdActive(11);
    } else if (currentpath.includes("loanadvance")) {
      setIdActive(12);
    } else if (currentpath.includes("deputation")) {
      setIdActive(13);
    } else if (currentpath.includes("relocation")) {
      setIdActive(14);
    } else if (currentpath.includes("resignation")) {
      setIdActive(15);
    } else if (currentpath.includes("goalsettings")) {
      setIdActive(16);
    } else if (currentpath.includes("appraisals")) {
      setIdActive(17);
    } else if (currentpath.includes("rewards")) {
      setIdActive(18);
    }
    // else if (currentpath.includes("skills")) {
    //   setIdActive(19);
    // } else if (currentpath.includes("competencies")) {
    //   setIdActive(20);
    // }
    else if (currentpath.includes("training")) {
      setIdActive(21);
    } else if (currentpath.includes("gatepass")) {
      setIdActive(22);
    } else if (currentpath.includes("meetingroom")) {
      setIdActive(23);
    } else if (currentpath.includes("companyvehicle")) {
      setIdActive(24);
    } else if (currentpath.includes("companyfood")) {
      setIdActive(25);
    } else if (currentpath.includes("companyhospitality")) {
      setIdActive(26);
    }
    //  else if (currentpath.includes("visitors")) {
    //   setIdActive(27);
    // }
    else if (currentpath.includes("sendcourier")) {
      setIdActive(28);
    } else if (currentpath.includes("keys")) {
      setIdActive(29);
    } else if (currentpath.includes("ithelpdesk")) {
      setIdActive(30);
    } else if (currentpath.includes("exception")) {
      setIdActive(31);
    } else if (currentpath.includes("travelbooking")) {
      setIdActive(32);
    } else if (currentpath.includes("hotelbooking")) {
      setIdActive(33);
    } else if (currentpath.includes("traveladvance")) {
      setIdActive(34);
    } else if (currentpath.includes("reimbursement")) {
      setIdActive(35);
    }
    // else if (currentpath.includes("receivecourier")) {
    //   setIdActive(36);
    // }
    else if (currentpath.includes("myskill")) {
      setIdActive(39);
    } else if (currentpath.includes("mycompetency")) {
      setIdActive(38);
    } else if (currentpath.includes("overtime")) {
      setIdActive(37);
    } else {
      navigate("/approvals/requisition");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [approvalBadgeList, setApprovalBadgeList] = useState([]);
  const getApprovalBadge = async () => {
    try {
      const result = await getRequest("Settings/ModuleApprovalsCount");
      setApprovalBadgeList(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getApprovalCount = (module) => {
    return approvalBadgeList.find((x) => x.module === module)?.count ?? 0;
  };
  const sidebarData = [
    {
      id: 1,
      icon: RequisitionIcon,
      name: "Requisition",
      navigate: "/approvals/requisition",
      module: "Manpower Indent",
      count: getApprovalCount("Manpower Indent"),
      menu: "HRMS",
    },
    //{id: 2, icon:AttendanceRegIcon, name:'Requisition Planning', navigate: '/approvals/planning'},
    {
      id: 3,
      icon: AttendanceRegIcon,
      name: "Regularisation",
      navigate: "/approvals/regularisation",
      module: "Attendance Requests",
      count: getApprovalCount("Attendance Requests"),
      menu: "HRMS",
    },
    {
      id: 4,
      icon: LateComingIcon,
      name: "Late Coming",
      navigate: "/approvals/latecoming",
      module: "Late Coming Requests",
      count: getApprovalCount("Late Coming Requests"),
      menu: "HRMS",
    },
    {
      id: 5,
      icon: EarlyGoingIcon,
      name: "Early Going",
      navigate: "/approvals/earlygoing",
      module: "Early Going Requests",
      count: getApprovalCount("Early Going Requests"),
      menu: "HRMS",
    },
    {
      id: 6,
      icon: LeavesIcon,
      name: "Leave",
      navigate: "/approvals/leave",
      module: "Leave Requests",
      count: getApprovalCount("Leave Requests"),
      menu: "HRMS",
    },
    {
      id: 7,
      icon: CompoffIcon,
      name: "Comp Off",
      navigate: "/approvals/compoffs",
      module: "Comp off Request",
      count: getApprovalCount("Comp off Request"),
      menu: "HRMS",
    },
    {
      id: 37,
      icon: OvertimeIcon,
      name: "Over Time",
      navigate: "/approvals/overtime",
      module: "Over Time Request",
      count: getApprovalCount("Over Time Request"),
      menu: "HRMS",
    },
    {
      id: 8,
      icon: OutdoorIcon,
      name: "Out Door",
      navigate: "/approvals/outdoors",
      module: "Outdoor Requests",
      count: getApprovalCount("Outdoor Requests"),
      menu: "HRMS",
    },
    // {id: 9, icon:OutdoorIcon, name:'Timesheet', navigate: '/approvals/timesheet'},
    {
      id: 10,
      icon: HrDeskIcon,
      name: "HR Desk",
      navigate: "/approvals/hrdesk",
      module: "HR Helpdesk",
      count: getApprovalCount("HR Helpdesk"),
      menu: "HRMS",
    },
    {
      id: 11,
      icon: AdminFacilityReqIcon,
      name: "Facilities",
      navigate: "/approvals/facilities",
      module: "Admin Facility Requests",
      count: getApprovalCount("Admin Facility Requests"),
      menu: "HRMS",
    },
    {
      id: 12,
      icon: LoanAdvanceIcon,
      name: "Loan & Advance",
      navigate: "/approvals/loanadvance",
      module: "Loan and Advance",
      count: getApprovalCount("Loan and Advance"),
      menu: "HRMS",
    },
    {
      id: 13,
      icon: DeputationIcon,
      name: "Deputation",
      navigate: "/approvals/deputation",
      module: "Deputation Requests",
      count: getApprovalCount("Deputation Requests"),
      menu: "HRMS",
    },
    {
      id: 14,
      icon: RelocationIcon,
      name: "Relocation",
      navigate: "/approvals/relocation",
      module: "Relocation Request",
      count: getApprovalCount("Relocation Request"),
      menu: "HRMS",
    },
    {
      id: 15,
      icon: ResignationIcon,
      name: "Resignation",
      navigate: "/approvals/resignation",
      module: "Exit Requests",
      count: getApprovalCount("Exit Request"),
      menu: "HRMS",
    },
    // {id: 16, icon:RelocationIcon, name:'Goal Settings', navigate: '/approvals/goalsettings'},
    // {id: 17, icon:RelocationIcon, name:'Appraisals', navigate: '/approvals/appraisals'},
    // {id: 18, icon:RelocationIcon, name:'Rewards', navigate: '/approvals/rewards'},
    // {id: 19, icon:RelocationIcon, name:'Skills', navigate: '/approvals/skills'},
    // {id: 20, icon:RelocationIcon, name:'Competencies', navigate: '/approvals/competencies'},
    // {id: 21, icon:RelocationIcon, name:'Training', navigate: '/approvals/training'},
    {
      id: 22,
      icon: GatePassIcon,
      name: "Gate Pass",
      navigate: "/approvals/gatepass",
      module: "Gate Pass Requests",
      count: getApprovalCount("Gate Pass Requests"),
      menu: "Office",
    },
    {
      id: 23,
      icon: MeetingRoomIcon,
      name: "Meeting Room",
      navigate: "/approvals/meetingroom",
      module: "Meeting Room Booking",
      count: getApprovalCount("Meeting Room Booking"),
      menu: "Office",
    },
    {
      id: 24,
      icon: CompanyCarIcon,
      name: "Company Car",
      navigate: "/approvals/companyvehicle",
      module: "Company Car Request",
      count: getApprovalCount("Company Car Request"),
      menu: "Office",
    },
    {
      id: 25,
      icon: CompanyFoodIcon,
      name: "Company Food",
      navigate: "/approvals/companyfood",
      module: "Food Requests",
      count: getApprovalCount("Food Requests"),
      menu: "Office",
    },
    {
      id: 26,
      icon: CompanyHospitalityIcon,
      name: "Company Hospitality",
      navigate: "/approvals/companyhospitality",
      module: "Hospitality Requests",
      count: getApprovalCount("Hospitality Request"),
      menu: "Office",
    },
    // {
    //   id: 27,
    //   icon: ITHelpDeskIcon,
    //   name: "Visitors",
    //   navigate: "/approvals/visitors",
    //   module: "Loan and Advance",
    //   count: getApprovalCount("Loan and Advance"),
    //   menu: "Office",
    // },
    {
      id: 28,
      icon: SendCourierIcon,
      name: "Send Courier",
      navigate: "/approvals/sendcourier",
      module: "Courier Services Requests",
      count: getApprovalCount("Courier Services Requests"),
      menu: "Office",
    },
    // {
    //   id: 36,
    //   icon: ITHelpDeskIcon,
    //   name: "Receive Courier",
    //   navigate: "/approvals/receivecourier",
    //   module: "Loan and Advance",
    //   count: getApprovalCount("Loan and Advance"),
    //   menu: "Office",
    // },
    // {id: 29, icon:ITHelpDeskIcon, name:'Keys', navigate: '/approvals/keys'},
    {
      id: 30,
      icon: ITHelpDeskIcon,
      name: "IT Desk Ticket",
      navigate: "/approvals/ithelpdesk",
      module: "IT Helpdesk",
      count: getApprovalCount("IT Helpdesk"),
      menu: "ITSM",
    },
    {
      id: 31,
      icon: ExceptionRequestsIcon,
      name: "Exception",
      navigate: "/approvals/exception",
      module: "Exception Request",
      count: getApprovalCount("Exception Request"),
      menu: "ITSM",
    },
    {
      id: 32,
      icon: TravelBookingIcon,
      name: "Travel Booking",
      navigate: "/approvals/travelbooking",
      module: "Travel and Hospitality Requests",
      count: getApprovalCount("Travel Booking"),
      menu: "Travel",
    },
    {
      id: 33,
      icon: HotelBookingIcon,
      name: "Hotel Booking",
      navigate: "/approvals/hotelbooking",
      module: "Travel and Hospitality Requests",
      count: getApprovalCount("Hotel Booking"),
      menu: "Travel",
    },
    {
      id: 34,
      icon: TravelAdvanceIcon,
      name: "Travel Advance",
      navigate: "/approvals/traveladvance",
      module: "Travel Advance Requests",
      count: getApprovalCount("Travel Advance Requests"),
      menu: "Travel",
    },
    {
      id: 35,
      icon: ReimbursementIcon,
      name: "Reimbursement",
      navigate: "/approvals/reimbursement",
      module: "Expense Requests",
      count: getApprovalCount("Expense Requests"),
      menu: "Travel",
    },
    {
      id: 39,
      icon: SkillsIcon,
      name: "Skills",
      navigate: "/approvals/myskill",
      module: "Skills",
      count: getApprovalCount("Skills"),
      menu: "L&D",
    },
    {
      id: 38,
      icon: CompetenciesIcon,
      name: "Competency",
      navigate: "/approvals/mycompetency",
      module: "Competency",
      count: getApprovalCount("Competency"),
      menu: "L&D",
    },
  ];
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("approvals")) {
      getApprovalBadge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [featureMenu, setFeatureMenu] = useState(null);
  const { menuModuleGroup } = useSelector((state) => state.menu);
  useEffect(() => {
    if (menuModuleGroup) {
      setFeatureMenu(menuModuleGroup);
    }
  }, [menuModuleGroup]);
  const getActiveStatus = (approvalItem) => {
    let isActive = featureMenu
      ?.find((x) => x[0]?.menuName === approvalItem?.menu)
      ?.find((x) => x.featureName === approvalItem?.name)
      ? featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.featureName === approvalItem?.name)?.isFeatureActive
      : featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.subMenuName === approvalItem?.name)?.isSubMenuActive;
    let displayName = featureMenu
      ?.find((x) => x[0]?.menuName === approvalItem?.menu)
      ?.find((x) => x.featureName === approvalItem?.name)
      ? featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.featureName === approvalItem?.name)
          ?.featureDisplayName
      : featureMenu
          ?.find((x) => x[0]?.menuName === approvalItem?.menu)
          ?.find((x) => x.subMenuName === approvalItem?.name)
          ?.subMenuDisplayName;
    return { isActive, displayName };
  };
  return (
    <div className="pageContainer">
      <div
        className={`homeDashboardContainer  ${
          active === true ? "hideDashboard" : "viewDashboard"
        }`}
      >
        <div className="profileImgHolder">
          <img
            src={HomeDashboardBackground}
            alt="Profile"
            className="profileImgHolderbackground"
          />
          <div className="avatarBadge">
            {bannerList?.CompleteProfilePercentage}%
            <img src={InfoIcon} alt="Info" className="infoIcon" />
          </div>
          <div className="avatarContainer">
            <CircularProgressbarWithChildren
              value={bannerList?.CompleteProfilePercentage}
              text={`${bannerList?.CompleteProfilePercentage}%`}
              strokeWidth={5}
              styles={buildStyles({
                textColor: "transparent",
                pathColor: "#1296B0",
                trailColor: "#D8F0F2",
                rotation: 0.37,
                strokeLinecap: "round",
              })}
            >
              {bannerList?.Image ? (
                <img
                  src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${bannerList?.Image}`}
                  alt={"Profile"}
                  className="imageInsideProgressBar"
                />
              ) : (
                <img
                  src={ProfileAvatar}
                  alt={"Profile"}
                  className="imageInsideProgressBar"
                />
              )}
            </CircularProgressbarWithChildren>
          </div>
        </div>

        <div className="homeGreetingContainer">
          <h3>{bannerList?.Name}</h3>
          <h4>{bannerList?.Role}</h4>
          <div className="horizontalLinePrimary"></div>
          <div className="starContainer">
            <img src={RewardIconOrange} alt="RewardIconOrange" />
            <label>Star Performer of the Month</label>
          </div>
        </div>

        <div className="dashboardApprovalsRight">
          <div className="dashboardApproval">
            <ApprovalsContent
              percent="23%"
              amount="500/1000"
              reason="Approved Within TAT"
            />
            <ApprovalsContent
              percent="80%"
              amount="500/1000"
              reason="Approval"
            />
            <ApprovalsContent
              percent="27%"
              amount="500/1000"
              reason="Approved Reminder"
            />
          </div>
          <div className="dashboardApproval">
            <ApprovalsContent
              redpercent="60%"
              amount="500/1000"
              reason="Approved More Than TAT"
            />
            <ApprovalsContent
              percent="20%"
              amount="500/1000"
              reason="Rejection"
            />
            <ApprovalsContent
              redpercent="10%"
              amount="500/1000"
              reason="Approved Escalation"
            />
          </div>
          <div className="editPencil">
            <img
              src={EditPencilIcon}
              alt="Edit Pencil"
              className="editPencilIcon"
            />
          </div>
        </div>
      </div>

      <div
        className={`servicesContainer ${active === true ? "newHeight" : null}`}
        style={{ position: "relative" }}
      >
        {/* {active === true ? (
          <img
            src={ApprovalViewIcon}
            alt={ApprovalViewIcon}
            className="approvalViewIcon cursor"
            onClick={() => clickHandler()}
          />
        ) : (
          <img
            src={ApprovalHideIcon}
            alt={ApprovalHideIcon}
            className="approvalViewIcon cursor"
            onClick={() => clickHandler()}
          />
        )} */}
        <div className="servicesSidebar">
          <div className="chatListSearchContainer">
            <input
              type="search"
              id="search-form"
              className="chatListSearch"
              placeholder="Search"
              value={s}
              onChange={(e) => setS(e.target.value)}
              // onClick={clickHandler}
            />
          </div>

          {search(sidebarData)
            .sort((a, b) => parseFloat(b.count) - parseFloat(a.count))
            ?.map((item) => {
              let itemDetails = getActiveStatus(item);

              if (itemDetails.isActive)
                return (
                  <>
                    <div
                      className={`menuOptions ${
                        item.id === idActive && "menuOptionsActive"
                      }`}
                      style={{ justifyContent: "space-between" }}
                      onClick={() => {
                        clickHandlerSearch(item);
                      }}
                    >
                      <div className="verticalLine"></div>
                      <div
                        style={{
                          padding: "0 12px",
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                        }}
                      >
                        <img src={item.icon} alt={`${item.icon}`} />
                        <label className="cursor">
                          {itemDetails.displayName ?? item.name}
                        </label>
                      </div>
                      {item.count !== 0 && (
                        <div
                          className="badge badgeCenter"
                          style={{ position: "initial" }}
                        >
                          <label className="cursor">{item.count}</label>
                        </div>
                      )}
                    </div>
                  </>
                );
              else return null;
            })}
        </div>

        <div className="servicesContentHolder">
          {idActive === 1 ? (
            <ApprovalRequisition getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 2 ? (
            <ApprovalPlanning getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 3 ? (
            <ApprovalRegularisation getApprovalBadge={getApprovalBadge} />
          ) : null}
          {/* // {idActive === 4 ? <ApprovalLateComing /> : null}
          // {idActive === 5 ? <ApprovalEarlyGoing /> : null}
          // {idActive === 6 ? <ApprovalLeave /> : null}
          // {idActive === 7 ? <ApprovalCompOffs /> : null}
          // {idActive === 8 ? <ApprovalOutdoors /> : null}
          // {idActive === 9 ? <ApprovalTimesheet /> : null}
          // {idActive === 10 ? <ApprovalHRDesk /> : null}
          // {idActive === 11 ? <ApprovalFacilities /> : null}
          // {idActive === 12 ? <ApprovalLoanAdvance /> : null}
          // {idActive === 13 ? <ApprovalDeputation /> : null}
          // {idActive === 14 ? <ApprovalRelocation /> : null}
          // {idActive === 15 ? <ApprovalResignation /> : null}
          // {idActive === 16 ? <ApprovalGoalSettings /> : null}
          // {idActive === 17 ? <ApprovalAppraisals /> : null}
          // {idActive === 18 ? <ApprovalRewards /> : null}
          //{" "} */}
          {/* {idActive === 19 ? <ApprovalSkills /> : null}
//           {idActive === 20 ? <ApprovalCompetencies /> : null} */}
          {/* // {idActive === 21 ? <ApprovalTraining /> : null}
          // {idActive === 22 ? <ApprovalGatePass /> : null}
          // {idActive === 23 ? <ApprovalMeetingRoom /> : null}
          // {idActive === 24 ? <ApprovalCompanyVehicle /> : null}
          // {idActive === 25 ? <ApprovalCompanyFood /> : null}
          // {idActive === 26 ? <ApprovalCompanyHospitality /> : null}
          // {idActive === 27 ? <ApprovalVisitors /> : null}
          // {idActive === 28 ? <ApprovalSendCourier /> : null}
          // {idActive === 29 ? <ApprovalKeys /> : null}
          // {idActive === 30 ? <ApprovalITHelpDesk /> : null}
          // {idActive === 31 ? <ApprovalException /> : null}
          // {idActive === 32 ? <ApprovalTravelBooking /> : null}
          // {idActive === 33 ? <ApprovalHotelBooking /> : null}
          // {idActive === 34 ? <ApprovalTravelAdvance /> : null}
          // {idActive === 35 ? <ApprovalReimbursement /> : null}
          // {idActive === 36 ? <ApprovalRecieveCourier /> : null}
          // {idActive === 39 ? <ApprovalSkills /> : null}
          // {idActive === 38 ? <ApprovalCompetencies /> : null} */}
          {idActive === 4 ? (
            <ApprovalLateComing getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 5 ? (
            <ApprovalEarlyGoing getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 6 ? (
            <ApprovalLeave getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 7 ? (
            <ApprovalCompOffs getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 8 ? (
            <ApprovalOutdoors getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 9 ? (
            <ApprovalTimesheet getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 10 ? (
            <ApprovalHRDesk getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 11 ? (
            <ApprovalFacilities getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 12 ? (
            <ApprovalLoanAdvance getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 13 ? (
            <ApprovalDeputation getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 14 ? (
            <ApprovalRelocation getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 15 ? (
            <ApprovalResignation getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 16 ? (
            <ApprovalGoalSettings getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 17 ? (
            <ApprovalAppraisals getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 18 ? (
            <ApprovalRewards getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 19 ? (
            <ApprovalSkills getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 20 ? (
            <ApprovalCompetencies getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 21 ? (
            <ApprovalTraining getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 22 ? (
            <ApprovalGatePass getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 23 ? (
            <ApprovalMeetingRoom getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 24 ? (
            <ApprovalCompanyVehicle getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 25 ? (
            <ApprovalCompanyFood getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 26 ? (
            <ApprovalCompanyHospitality getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 27 ? (
            <ApprovalVisitors getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 28 ? (
            <ApprovalSendCourier getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 29 ? (
            <ApprovalKeys getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 30 ? (
            <ApprovalITHelpDesk getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 31 ? (
            <ApprovalException getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 32 ? (
            <ApprovalTravelBooking getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 33 ? (
            <ApprovalHotelBooking getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 34 ? (
            <ApprovalTravelAdvance getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 35 ? (
            <ApprovalReimbursement getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 36 ? (
            <ApprovalRecieveCourier getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 37 ? (
            <ApprovalOvertime getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 39 ? (
            <ApprovalSkills getApprovalBadge={getApprovalBadge} />
          ) : null}
          {idActive === 38 ? (
            <ApprovalCompetencies getApprovalBadge={getApprovalBadge} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { Approvals };
