/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch } from "react-redux";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import { useState } from "react";
import { useEffect } from "react";

const opt = [{ label: "select", value: null }];
export const WorkflowDetailsForm = (props) => {
  const { values, data } = props;
  const [optionSer, setOptionSer] = useState([]);
  const [optionReq, setOptionReq] = useState([]);
  const [option, setOption] = useState([]);
  const getOptionSer = async () => {
    try {
      const result = await getRequest("HrHelpdesks/GetTicketTypes");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        `HRHelpdeskWorkflows/GetHRRequestTypes?RequestCategoryId=${values?.requestCategoryId}&&TypeId=${values?.ticketTypeId}`
      );
      let listData = [];
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setOptionReq(listData);
      values.requestTypeId = listData[0]?.value;
      setOptionReq(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOption = async () => {
    try {
      const result = await getRequest(
        `HRHelpdeskRequestCategories/GetHRRequestCategories/${values.ticketTypeId}`
      );

      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      values.requestCategoryId = listData[0]?.value;
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOptionSer();
  }, []);
  useEffect(() => {
    if (values?.ticketTypeId) {
      getOption();
    }
  }, [values?.ticketTypeId]);
  useEffect(() => {
    if (values?.requestCategoryId) {
      getOptionReq();
    }
  }, [values?.requestCategoryId]);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = `PeopleSol - Workflow Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <div className="formcustom" style={{ height: "fit-content" }}>
        <div>
          <LabelCustom labelName={"Name"} mandatory={true} />
          <InputCustomFormik
            maxLength={150}
            name="name"
            type={"text"}
            placeholder={"Enter Name"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Service Type   "} mandatory={true} />
          <SelectForm
            values={
              data
                ? optionSer.filter(
                    (options) => options?.value === data?.ticketTypeId
                  )
                : opt
            }
            name={"ticketTypeId"}
            options={optionSer}
            placeholder={" Select Service Type "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Request Category "} mandatory={true} />
          <SelectForm
            onDropdownOpen={
              values?.ticketTypeId === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select  service type",
                        severity: "error",
                      })
                    )
                : () => {}
            }
            values={option}
            name={"requestCategoryId"}
            options={option}
            placeholder={" Select Request Category "}
          />
        </div>
        {option[0]?.label === "Asset Request" ? (
          <></>
        ) : (
          <div>
            <LabelCustom labelName={"Request Type"} mandatory={true} />
            <SelectForm
              name={"requestTypeId"}
              onDropdownOpen={
                values?.ticketTypeId === ""
                  ? () =>
                      dispatch(
                        showToast({
                          text: "Please select  service type",
                          severity: "error",
                        })
                      )
                  : () => {}
              }
              options={optionReq}
              placeholder={" Select Request Type"}
            />
          </div>
        )}

        <div>
          <LabelCustom labelName={"Description"} />
          <TextAreaFormik
            maxLength={500}
            style={{ height: "80px" }}
            name={"description"}
            placeholder={" Enter Description "}
          />
        </div>
      </div>
    </>
  );
};
