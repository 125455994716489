/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import "./Orgstructure.css";
import { useEffect } from "react";
import Tree from "react-d3-tree";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { useSelector } from "react-redux";
import HorizontalViewButton from "../../../Assets/HorizontalViewButton.svg";
import VerticalViewButton from "../../../Assets/VerticalViewButton.svg";
import { Label } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { getInitialLetter } from "../../../Utilities/getInitialLetter";

const Orgstructure = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [employeeTree, setEmployeeTree] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isVertical, setIsVertical] = useState(false);
  const treeContainer = useRef(null);
  const getTeamData = async () => {
    try {
      const result = await getRequest(`Employees/Team/${LoggedInUser?.id}`);
      setEmployeeTree(result?.data?.data?.value);
      // //console.log(result?.data?.data?.value);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - Organisation Strucure`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const renderForeignObjectNode = ({
    nodeDatum,
    toggleNode,
    foreignObjectProps,
  }) => (
    <g>
      <circle r={1}></circle>
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        <div
          className={`childrenBlock ${
            nodeDatum?.children && "cursor blockHover"
          } ${isVertical && "verticalChildrenBlock"}`}
          onClick={toggleNode}
        >
          <div className="orgImageHolder">
            {nodeDatum.image ? (
              <img
                className="imageInsideProgressBar"
                style={{ zIndex: "0" }}
                src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ProfileImageUploads/${nodeDatum.image}`}
                alt={"Profile Pic"}
              />
            ) : (
              // : <label className="childDisplayText">{nodeDatum.name.slice(0,1)}</label>}
              <label className="childDisplayText">
                {nodeDatum.name
                  ? getInitialLetter(nodeDatum.name)
                  : nodeDatum.name.slice(0, 1).toUpperCase()}
              </label>
            )}
          </div>
          <div className="orgDataHolder">
            <label style={{ color: "var(--primary)", fontWeight: "500" }}>
              {nodeDatum.name.split("(")[0]}
            </label>
            <label style={{ color: "#BFBFBF", fontWeight: "500" }}>
              {nodeDatum.name.slice(-10)}
            </label>
            <label style={{ color: "#555555", fontWeight: "500" }}>
              {nodeDatum.title.includes("(")
                ? nodeDatum.title.split("(")[0]
                : nodeDatum.title}
            </label>
          </div>
          <div
            className={`childrenCountClass ${
              isVertical && "verticalCountClass"
            }`}
          >
            <label style={nodeDatum?.children && { padding: "2px 4px" }}>
              {nodeDatum?.children ? nodeDatum?.children?.length : null}
            </label>
          </div>
        </div>
      </foreignObject>
    </g>
  );

  useEffect(() => {
    if (LoggedInUser) {
      if (location.pathname.toLowerCase().includes("orgstructure"))
        getTeamData();
    }
  }, [location.pathname]);

  const nodeSize = { x: 450, y: isVertical ? 200 : 150 };
  // const foreignObjectProps = { width: nodeSize.x - 100, height: nodeSize.y, x: 20 };
  const foreignObjectProps = {
    width: 350,
    height: isVertical ? 140 : 100,
    x: -150,
    y: isVertical ? -70 : -50,
  };
  useEffect(() => {
    if (employeeTree && treeContainer.current) {
      // Calculate the translation to center the nodes
      const dimensions = treeContainer.current.getBoundingClientRect();
      const translate = {
        x: dimensions.width / 2,
        y: dimensions.height / 2,
      };
      treeContainer.current
        .querySelector("svg")
        .setAttribute("transform", `translate(${translate.x}, ${translate.y})`);
    }
  }, [employeeTree]);
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {employeeTree && (
        <div style={{ width: "100%", height: "100%" }}>
          <Tree
            translate={
              isVertical
                ? { x: 224, y: 390.5 }
                : { x: window.innerWidth / 2 - 140, y: 130.5 }
            } // Center the tree
            initialDepth={2}
            // svgClassName={"svgorgstructure"} //does the same thing as translate
            draggable={true}
            data={employeeTree}
            nodeSize={nodeSize}
            pathFunc={"step"}
            renderCustomNodeElement={(rd3tProps) =>
              renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
            }
            collapsible={true}
            orientation={isVertical ? "horizontal" : "vertical"}
          />
        </div>
      )}
      <Tooltip
        arrow
        title={isVertical ? "Horizontal View" : "Vertical View"}
        placement="left"
      >
        <div
          className="viewChanger cursor"
          onClick={() => setIsVertical((prev) => !prev)}
        >
          <img
            src={isVertical ? HorizontalViewButton : VerticalViewButton}
            alt={"Change View Button"}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export { Orgstructure };
