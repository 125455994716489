/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { TaxDeclaration } from "./TaxDeclaration";
import { TaxDeclarationAll } from "./TaxDeclarationAll";
import { TaxSubmission } from "./TaxSubmission";
import { TaxSubmissionAll } from "./TaxSubmissionAll";

const TaxDeclarations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const closeDetails = () => {
    returnPage();
  };
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Declaration`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    All Declaration
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    All Submission
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>

          {tabIndex === 0 && <TaxDeclarationAll/>}
          {tabIndex === 1 && <><TaxSubmissionAll /></>}

    </div>
  );
};

export { TaxDeclarations };
