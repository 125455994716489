/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import Nodata from "../../../../Assets/Nodata.svg";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import { getStatusColor } from "../../../../Utilities/getStatusColor";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { Details } from "../../../HRMS/Components/DetailsComp/Details";
import { Notifications } from "../../../HRMS/Components/DetailsComp/Notifications";
import { TimeLineData } from "../../../HRMS/Components/TransferComponents/DetailsComp/Timeline/TimeLineData";
import { TimelineItem } from "@mui/lab";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../Features/badgeUtils";
const DetailPageAsset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setAId] = useState("");
  const [aId, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  useEffect(() => {
    document.title = `PeopleSol - Asset Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve\/\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "205"
      const value2WithApprove = matches[2]; // "approve/16"
      const value2 = value2WithApprove.split("/")[1]; // "16"
      const approve = value2WithApprove.split("/")[0]; // "approve"

      return { value1, value2, approve };
    } else {
      return { value1: null, value2: null, approve: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, id, color) => {
    setTypeAction(type);
    setColorAction(color);
    setAId(id);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `ItAssets/Details?ID=${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data);
      if (approve) {
        if (
          LoggedInUser?.id == value2 &&
          result.data?.data?.actionlist.length
        ) {
          PostApRejHol(approve, result.data?.data?.actionlist[0]?.id, "Remark");
        } else {
          dispatch(
            showToast({
              text: "No Actions Available" ?? "Error in fetching Data",
              severity: "error",
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let result;
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Confirm" ||
        type === "approve"
      ) {
        result = await postRequest(`ITAssets/${type}?Id=${id}`);
        if (result) {
          dispatch(
            showToast({ text: result?.data?.message, severity: "success" })
          );
        }
      } else if (
        type === "Maintenance" ||
        type === "Donated" ||
        type === "Scraped"
      ) {
        result = await postRequest(
          `ITAssets/ModificationRequests?ID=${id}&&type=${type}`
        );
        if (result) {
          dispatch(
            showToast({ text: result?.data?.message, severity: "success" })
          );
        }
      } else if (type === "Accept") {
        const result = await postRequest(`ITAssets/Complete?Id=${id}`);
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      } else if (type === "Complete") {
        const result = await postRequest(
          `ITAssets/ModificationApprove?ID=${id}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      } else {
        const result = await postRequest(
          `ITAssets/${type}?Id=${id}&Remarks=${Remark}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Facilities Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          {data && (
            <>
              {tabIndex === 0 &&
                (data?.data != null ? (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm"> Request Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Asset Id"}
                        Details={data?.data?._assets?.id}
                      />
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.data?._assets?.status
                              )?.split(",")?.[0],
                              color: getStatusColor(
                                data?.data?._assets?.status
                              )?.split(",")?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                            }}
                          >
                            {data?.data?._assets?.status}
                          </div>
                        }
                      />
                      <Details
                        detailTitle={"Asset Category "}
                        Details={data?.data?.assetcategoryname}
                      />
                      <Details
                        detailTitle={"Asset Material"}
                        Details={data?.data?.assettypename}
                      />
                      <Details
                        detailTitle={"Asset Type"}
                        Details={data?.data?._assets?.type}
                      />
                      <Details
                        detailTitle={"Description"}
                        Details={data?.data?._assets?.description}
                      />
                      <Details
                        detailTitle={"Created On"}
                        Details={
                          data?.data?._assets?.lastUpdatedOn &&
                          format(
                            new Date(data?.data?._assets?.createdOn),
                            "dd/MM/yyyy hh:mm a"
                          )
                        }
                      />
                      <Details
                        detailTitle={"Last Updated On"}
                        Details={
                          data?.data?._assets?.lastUpdatedOn &&
                          format(
                            new Date(data?.data?._assets?.lastUpdatedOn),
                            "dd/MM/yyyy hh:mm a"
                          )
                        }
                      />
                    </div>

                    {data?.data?.actionlist?.length > 0 ? (
                      <div
                        className="createFootDetails"
                        style={{ background: "white !important" }}
                      >
                        {data?.data?.actionlist?.map((item) => {
                          return (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel"
                                    ? togglePopup(
                                        item?.type,
                                        item?.color,
                                        item?.id
                                      )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  backgroundColor: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    {isOpen && (
                      <Popup
                        popupHeading={typeAction}
                        content={
                          <>
                            <Remark
                              data={PostApRejHol}
                              type={typeAction}
                              id={aId}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          </>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                  </div>
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailPageAsset };
