import React, { useState, useEffect } from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { getRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { TextAreaFormik } from "../../../Components/FormComponent/TextAreaFormik";
import { ColorPickerFormik } from "../../../Components/FormComponent/ColorPickerFormik";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { YesNoFormik } from "../../../Components/FormComponent/YesNoFormik";

const opt = [{ label: "select", value: null }];

export const CreateMeetingRoomComp = ({ data }) => {
  const dispatch = useDispatch();
  const [option, setOption] = useState([]);
  const [activ, setActiv] = useState(null);

  const getLocationFilter = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Meeting Room `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getLocationFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const numberValidation = () => {
  //   if (this.value.length > this.maxLength)
  //   return this.value = this.value.slice(0, this.maxLength)
  // }

  return (
    <div className="formcustom">
      <div>
        <LabelCustom labelName={"Location"} mandatory={true} />
        <SelectForm
          placeholder={"Select Locations"}
          name="locationId"
          options={option}
          values={
            data
              ? option.filter((options) => options.value === data?.locationId)
              : opt
          }
        />
      </div>

      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter Title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Colour"} mandatory={true} />
        <ColorPickerFormik name="colour" />
      </div>
      <div>
        <LabelCustom labelName={"Seating Capacity"} mandatory={true} />
        <InputCustomFormik
          name="seatingCapacity"
          type="number"
          step="0.01"
          min="0"
          max="9999"
          placeholder={"Enter seating Capacity"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Facilities"} mandatory={true} />
        <TextAreaFormik
          maxLength={300}
          placeholder={"Enter Facilities"}
          name="facilities"
        />
      </div>
      <div>
        <LabelCustom labelName={"Is Enabled?"} mandatory={true} />
        <YesNoFormik
          name="isEnabled"
          // checkedyes={activ === true}
          // checkedNo={activ === false}
          // onChangeyes={(e) => setActiv(e.target.value === "Yes" ? true : false)}
          // onChangeNo={(e) => setActiv(e.target.value === "No" ? false : true)}
        />
      </div>
    </div>
  );
};
