/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import { StepsWorkflows } from "../../../../HRMS/Components/HRDeskComponents/StepsWorkflows";

const Proioroption = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
const ItDeskTicketForm = ({ formValues }) => {
  const [optionSer, setOptionSer] = useState([]);
  const [optionReqType, setOptionReqType] = useState([]);
  const [requestCat, setOptionRequestCat] = useState([]);
  const [location, setLocation] = useState([]);
  const [workflowData, setWorkflowData] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - IT Desk Ticket`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  const getWorkflowData = async () => {
    try {
      const result = await getRequest(
        `ItHelpdesks/Workflow?ID=${formValues?.TypeId}`
      );
      setWorkflowData(result?.data?.data.itsmWorkflowsActions);
    } catch (error) {
    } finally {
    }
  };
  const getLocation = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result?.data?.map((item) => {
        return listData?.push({ label: item?.name, value: item?.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionReq = async () => {
    try {
      const result = await getRequest(
        `ItsmWorkflows/GetRequestCategory/${formValues?.TypeId}`
      );
      let listData = [];
      listData.push({ label: result?.data?.text, value: result?.data?.id });
      formValues.RequestCategoryId = listData[0]?.value;
      setOptionRequestCat(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionReqType = async () => {
    try {
      const result = await getRequest(
        `Ithelpdesks/GetItsmRequestTypes?RequestCategoryId=${formValues?.RequestCategoryId}`
      );

      let listData = [];
      result?.data?.results?.map((item) => {
        listData?.push({ label: item?.text, value: item?.id });
        return (formValues.RequestTypeId = listData[0]?.value);
      });
      setOptionReqType(listData);
    } catch (error) {
    } finally {
    }
  };
  const getOptionService = async () => {
    try {
      const result = await getRequest("ItsmWorkflows/GetITServiceCatalog");
      let listData = [];
      result?.data?.map((item) => {
      return listData?.push({ label: item?.categoryName, value: item?.id });
      });
      setOptionSer(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    if (formValues?.TypeId) {
      getOptionReq();
      getWorkflowData();
    }
  }, [formValues?.TypeId]);
  useEffect(() => {
    if (formValues?.RequestCategoryId) {
      getOptionReqType();
    }
  }, [formValues?.RequestCategoryId, formValues?.TypeId]);
  useEffect(() => {
    getOptionService();
    getLocation();
  }, []);
  return (
    <div
      style={
        formValues?.TypeId === ""
          ? { display: "block" }
          : {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
            }
      }
    >
      <div style={{ flex: 6 }}>
        <div>
          <LabelCustom labelName={"Service Type "} mandatory={true} />
          <SelectForm
            name="TypeId"
            options={optionSer}
            placeholder={" Select  Service type"}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"Request Category "} />
          <SelectForm
            disabled
            onDropdownOpen={
              formValues?.TypeId === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select  service type",
                        severity: "error",
                      })
                    )
                : () => {}
            }
            value={2}
            name={"RequestCategoryId"}
            options={requestCat}
            placeholder={" Select  Request Category "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Request Type"} mandatory={true} />
          <SelectForm
            onDropdownOpen={
              formValues?.TypeId === ""
                ? () =>
                    dispatch(
                      showToast({
                        text: "Please select  service type",
                        severity: "error",
                      })
                    )
                : () => {}
            }
            name="RequestTypeId"
            options={optionReqType}
            placeholder={" Select Request Type"}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Location"} mandatory={true} />
          <SelectForm
            name="AreaId"
            options={location}
            placeholder={" Select Location "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Priority "} mandatory={true} />
          <SelectForm
            name="priority"
            options={Proioroption}
            placeholder={" Select Priority "}
          />
        </div>
        <div>
          <LabelCustom labelName={"Upload File(s)"} />
          <FileUploadFormik name="fileUpload" placeholder={" Select File"} />
        </div>
        <div>
          <LabelCustom labelName={"Description "} mandatory={true} />
          <TextAreaFormik
            maxLength="501"
            style={{ height: "100px" }}
            name="Description"
            placeholder={" Enter Description"}
          />
        </div>
      </div>
      <div
        style={{
          flex: 4,
          marginTop: "50px",
          background: "var(--primary-hover)",
        }}
      >
        {formValues?.TypeId === "" ? (
          ""
        ) : (
          <StepsWorkflows workflowData={workflowData} />
        )}
      </div>
    </div>
  );
};

export { ItDeskTicketForm };
