import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../Components/FormComponent/Repeated";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { showToast } from "../../../../Features";
import { postRequest } from "../../../../Services/axios";
export const CreateQuestionComp = ({ data }) => {
  const dispatch = useDispatch();
  const option = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  const opt = [{ label: "select", value: null }];
  const [questionSetList, setQuestionSetList] = useState([]);
  const getBandListFilter = async () => {
    try {
      const result = await postRequest("TrainingQuestionsSets/GetQuestionSet");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setQuestionSetList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getBandListFilter();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Create Question`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Question Set"} mandatory={true} />
        <SelectForm
          name={"questionSetId"}
          placeholder={"Enter Question Set"}
          values={
            data
              ? questionSetList.filter(
                  (options) => options.value === data?.questionSetId
                )
              : opt
          }
          options={questionSetList}
        />
      </div>
      <div>
        <LabelCustom labelName={"Question"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="questionText"
          type={"text"}
          placeholder={"Enter Question"}
        />
      </div>

      <div style={{ display: "grid" }}>
        <LabelCustom labelName={"Details"} />
        <FieldArray name="answers">
          {(fieldArrayProps) => {
            const { push, remove, form } = fieldArrayProps;
            const { values } = form;
            const { answers } = values;

            return (
              <StyledTableCont>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Sl. No"} />
                      </StyledTableCell>
                      <StyledTableCell
                        padding="0"
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Answer"} mandatory={true} />
                      </StyledTableCell>

                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom
                          labelName={"Is Correct Answer?"}
                          mandatory={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          fontSize: "12px",
                        }}
                        align="center"
                      >
                        <LabelCustom labelName={"Add/Remove"} />
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {answers?.map((answer, index) => (
                    <TableBody key={index}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputCustomFormik
                          maxLength={300}
                          type="text"
                          name={`answers[${index}].answerText`}
                          placeholder={" Select  "}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <SelectForm
                          name={`answers[${index}].isCorrectAnswer`}
                          options={option}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            answers.length > 2
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast Two Items are Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({ answerText: "", isCorrectAnswer: "" })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableBody>
                  ))}
                </Table>
              </StyledTableCont>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
