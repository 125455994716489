import React, { useState, useEffect, useRef } from "react";
import AESSCompanyLogo from "../../../Assets/AESSCompanyLogo.png";
import VisitorPassimg from "../../../Assets/VisitorPassimg.svg";
import noimg from "../../../Assets/noimg.svg";

import "./DesignPrint.css";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getRequest } from "../../../Services/axios";
export const DesignPrint = ({
  data,
  index,
  LoggedInUser,
  personid,
  visitid,
}) => {
  const printContainerRef = useRef(null);
  const [qrCodeState, setQrCode] = useState(false);

  const getQRcode = async () => {
    try {
      const result = await getRequest(
        `VisitorsController/GenerateQrCode?visitId=${visitid}&visitpeopeid=${personid}`
      );
      setQrCode(result?.data?.data?.qr);
    } catch (error) {
    } finally {
    }
  };
  const handlePrint = () => {
    const printContainer = printContainerRef.current;
    if (printContainer) {
      printContainer.classList.add("print-version");
      window.print();
      printContainer.classList.remove("print-version");
    }
  };
  useEffect(() => {
    getQRcode();
    document.title = `PeopleSol - Visitor Pass`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      {data && (
        <div
          ref={printContainerRef}
          style={{
            padding: "30px",
            // border: " 1px solid grey",
          }}
          className="bady"
        >
          <div className="printBorder">
            <div
              style={{
                width: "800px",
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
                flexWrap: "nowrap",
              }}
            >
              <div style={{ width: "73%" }}>
                <div style={{ paddingLeft: "8px" }}>
                  <img
                    style={{ height: "90px", marginBottom: " 30px" }}
                    src={
                      LoggedInUser
                        ? `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Company/${LoggedInUser?.companyCode}/Logo/${LoggedInUser?.companyLogo}`
                        : ""
                    }
                    alt="Company Logo"
                  />
                </div>
                <section
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: "21px",
                    paddingRight: "31px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "4px solid rgb(25, 79, 120)",
                      marginBottom: "-29px",
                    }}
                  ></div>
                  <div>
                    <img
                      style={{
                        height: "50px",
                        position: "relative",
                        zIndex: " 0",
                      }}
                      src={VisitorPassimg}
                      alt=" Visitor Pass Logo"
                    />
                  </div>
                </section>
                <section
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="printdiv">
                      <p className="printFirstp">Contact No.</p>
                      <b style={{ paddingTop: "3px", fontSize: "20px" }}>:</b>
                      <p className="printSecondp">
                        {data?._visits?.visitingPeople[index]?.phoneNumber
                          ? data?._visits?.visitingPeople[index]?.phoneNumber
                          : ""}
                      </p>
                    </div>

                    <div className="printdiv">
                      <p className="printFirstp">Company</p>
                      <b style={{ paddingTop: "3px", fontSize: "20px" }}>:</b>
                      <p className="printSecondp">
                        {data?._visits?.company ? data?._visits?.company : ""}
                      </p>
                    </div>
                    <div className="printdiv">
                      <p className="printFirstp">Location</p>
                      <b style={{ paddingTop: "3px", fontSize: "20px" }}>:</b>
                      <p className="printSecondp">
                        {data?._visits?.location ? data?._visits?.location : ""}
                      </p>
                    </div>
                    <div className="printdiv">
                      <p className="printFirstp">To Meet Whom?</p>
                      <b style={{ paddingTop: "3px", fontSize: "20px" }}>:</b>
                      <p className="printSecondp">
                        {data?.tomeet ? data?.tomeet[index] : ""}
                      </p>
                    </div>
                    <div className="printdiv">
                      <p className="printFirstp">Valid For Date</p>
                      <b style={{ paddingTop: "3px", fontSize: "20px" }}>:</b>
                      <p className="printSecondp">
                        {data?._visits?.date
                          ? ` ${format(
                              new Date(data?._visits?.date),
                              "dd/MM/yyyy"
                            )}`
                          : "-"}
                      </p>
                    </div>
                    <div className="printdiv">
                      <p className="printFirstp">Receptionist/Security Sign</p>
                      <b style={{ paddingTop: "3px", fontSize: "20px" }}>:</b>
                    </div>
                  </div>
                </section>
              </div>
              <div style={{ width: "26%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                  }}
                >
                  {data?._visits?.visitingPeople[index]?.photo ? (
                    <>
                      <div className="printPhotoDiv">
                        <div className="printPhoto">
                          <img
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "9px",
                            }}
                            src={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/VisitorPhotos/${data?._visits?.visitingPeople[index]?.photo}`}
                            alt=""
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <p className="printNamep">
                            {data?._visits?.visitingPeople[index]?.name
                              ? data?._visits?.visitingPeople[index]?.name
                              : ""}
                          </p>
                          <p className="printRequestID">
                            ID: {`${data?._visits?.requestNumber}`}{" "}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="printPhotoDiv">
                        <div className="printPhoto">
                          <img
                            style={{
                              width: "150px",
                              height: " 150px",
                              borderRadius: "9px",
                            }}
                            src={noimg}
                            alt="Not Found"
                          />
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <p className="printNamep">
                            {data?._visits?.visitingPeople[index]?.name
                              ? data?._visits?.visitingPeople[index]?.name
                              : ""}
                          </p>
                          <p className="printRequestID">
                            ID: {`${data?._visits?.requestNumber}`}{" "}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="printQRcodeDiv">
                    {qrCodeState?.length > 0 && (
                      <img
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "9px",
                        }}
                        src={`${process.env.REACT_APP_.BASE_URL}/${qrCodeState}`}
                        alt="Qrcode"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="printBottomlineprint"></div>
          </div>
        </div>
      )}
      <div style={{ backgroundColor: "white" }} className="createFootCust ">
        <button
          className="button primaryButton"
          type="button"
          style={{
            background: "var(--primary)",
            color: "white",
            marginRight: "58px",
          }}
          onClick={handlePrint}
        >
          Print
        </button>
      </div>
      <div className="printBottomline"></div>
    </>
  );
};
