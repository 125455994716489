import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest, postRequest } from "../../../../../Services/axios";
import Nodata from "../../../../../Assets/Nodata.svg";

import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import { TimeLineData } from "../../TransferComponents/DetailsComp/Timeline/TimeLineData";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../../../Features/badgeUtils";
const DetailsPageLoan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [aid, setAId] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = (type, id, color) => {
    setTypeAction(type);
    setAId(id);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  // //console.log("id", data?.summary?.statusColor);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `LoanAndAdvance/Details/${getIdFromDynamicUrl(currentUrl)}`
      );
      setData(result.data);
      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionlist.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              result.data?.summary?.actionlist[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionlist[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    // //console.log("id", id);
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "approve"
      ) {
        await getRequest(`LoanAndAdvance/${type}/${id}`);
      } else {
        await getRequest(`LoanAndAdvance/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Loan`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    getDetails();
  }, []);
  // //console.log("data", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Loan And Advance Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                  >
                    Details
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                  >
                    Timeline
                  </span>
                }
              />
              <Tab
                style={{ minWidth: 130 }}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                  >
                    Notifications
                  </span>
                }
              />
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {data && (
            <>
              {tabIndex === 0 &&
                (data?.data != null ? (
                  <div
                    className="detailsEReq"
                    style={{ flexDirection: "column" }}
                  >
                    <div className="headingForm"> Request Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Request Number"}
                        Details={data?.data?.requestNumber}
                      />
                      <Details
                        detailTitle={"Status"}
                        Details={
                          <div
                            style={{
                              backgroundColor: getStatusColor(
                                data?.summary?.status
                              )?.split(",")?.[0],
                              color: getStatusColor(
                                data?.summary?.status
                              )?.split(",")?.[1],
                              cursor: "default",
                              padding: "6px",
                              borderRadius: "6px",
                            }}
                          >
                            {data?.summary?.status}
                          </div>
                        }
                      />
                      <Details
                        detailTitle={"Loan Or Advance Amount"}
                        Details={data?.data?.amount}
                      />
                      <Details
                        detailTitle={"Loan Or Advance Reason"}
                        Details={data?.data?.reason}
                      />

                      <Details
                        detailTitle={"Total Recovery "}
                        Details={`${data?.data?.totalRecoveryMonth} Month(s)`}
                      />
                      <Details
                        detailTitle={"Per Month Deduction"}
                        Details={`${data?.data?.perMonthDeduction} INR`}
                      />
                      <Details
                        detailTitle={"Required by Date"}
                        Details={format(
                          new Date(data?.data?.requiredBydate),
                          "dd/MM/yyyy hh:mm a"
                        )}
                      />
                      <Details
                        detailTitle={"Created On"}
                        Details={format(
                          new Date(data?.data?.createdOn),
                          "dd/MM/yyyy hh:mm a"
                        )}
                      />
                      <Details
                        detailTitle={"Last Updated On"}
                        Details={format(
                          new Date(data?.data?.lastUpdatedOn),
                          "dd/MM/yyyy hh:mm a"
                        )}
                      />
                    </div>
                    <div className="headingForm"> Requester's Details</div>
                    <div className="detailInner">
                      <Details
                        detailTitle={"Requester Name"}
                        Details={`${data?.summary?.generalWorkflowsApprovals?.requestingEmployee?.name} ${data?.summary?.generalWorkflowsApprovals?.requestingEmployee?.lastName}`}
                      />
                      <Details
                        detailTitle={"Requester's Manager"}
                        Details={
                          data?.summary?.generalWorkflowsApprovals
                            ?.requestingEmployee?.rO_Name
                        }
                      />
                      <Details
                        detailTitle={"Requester Designation"}
                        Details={
                          data?.summary?.generalWorkflowsApprovals
                            ?.requestingEmployee?.designation
                        }
                      />
                      <Details
                        detailTitle={"Requester Department"}
                        Details={
                          data?.summary?.generalWorkflowsApprovals
                            ?.requestingEmployee?.department
                        }
                      />
                      <Details
                        detailTitle={"Requester Email"}
                        Details={
                          data?.summary?.generalWorkflowsApprovals
                            ?.requestingEmployee?.email
                        }
                      />
                      <Details
                        detailTitle={"Contact Number"}
                        Details={
                          data?.summary?.generalWorkflowsApprovals
                            ?.requestingEmployee?.personalPhoneNumber
                        }
                      />

                      <Details
                        detailTitle={"Location"}
                        Details={
                          data?.summary?.generalWorkflowsApprovals
                            ?.requestingEmployee?.locationName
                        }
                      />
                    </div>
                    {data?.summary?.actionlist?.length > 0 ? (
                      <div
                        className="createFootDetails"
                        style={{ background: "white !important" }}
                      >
                        {data?.summary?.actionlist?.map((item) => {
                          // //console.log("item?.color", item);

                          return (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel"
                                    ? togglePopup(
                                        item?.type,
                                        item?.id,
                                        item?.type === "Reject"
                                          ? "red"
                                          : item?.color
                                      )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  backgroundColor:
                                    item?.type === "Reject"
                                      ? "red"
                                      : "rgb(10, 71, 116)",
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                    {isOpen && (
                      <Popup
                        popupHeading={typeAction}
                        content={
                          <>
                            <Remark
                              data={PostApRejHol}
                              type={typeAction}
                              id={aid}
                              color={colorAction}
                              handleClose={togglePopup}
                            />
                          </>
                        }
                        handleClose={togglePopup}
                      />
                    )}
                  </div>
                ) : (
                  <div className="noDatastyle">
                    <img
                      src={Nodata}
                      alt="No data"
                      style={{ height: "150px", width: "250px" }}
                    />
                    <h4>{"No Data Found"}</h4>
                  </div>
                ))}
              {tabIndex === 1 && (
                <>
                  <div className="tabularMasterDetailsPageTabBodyContainer">
                    <div style={{ display: "flex", width: "100%" }}>
                      <TimeLineData
                        id={getIdFromDynamicUrl(currentUrl)}
                        url={"LoanAndAdvance"}
                        module={data?.summary?.module}
                        requestNumber={data?.data?.requestNumber}
                      />
                      {/* <Comments id={getIdFromDynamicUrl(currentUrl)} module={data?.data?.module} requestNumber={
                          data?.data?.manpowerIndent?.requestNumber
                        } /> */}
                    </div>
                  </div>
                </>
              )}

              {tabIndex === 2 && (
                <>
                  <div
                    className="tabularMasterDetailsPageTabBodyContainer"
                    style={{ flexGrow: "initial" }}
                  >
                    <Notifications
                      id={getIdFromDynamicUrl(currentUrl)}
                      module={"Loan and Advance"}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageLoan };
