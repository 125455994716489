import React from 'react';
import { ItDeskTicket } from './ItDeskComponent/ItDeskTicket';
import { useEffect } from 'react';

const ItDeskTicketTab = () => {
    useEffect(() => {
        document.title = `PeopleSol - IT Desk Ticket`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
                    <ItDeskTicket title="IT Desk Ticket"/>

        </div>
    )
}

export { ItDeskTicketTab }