/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";
function EmployeeDetails({
  titleOptions,
  departmentOptions,
  roleOptions,
  reportingOfficerOptions,
  areaOptions,
  designationOptions,
  formValues,
  shiftOptions,
  systemRoleOptions,
  employeeTypeOptions,
  detailsform,
  editID,
}) {
  useEffect(() => {
    if (formValues?.departmentId)
      if (formValues?.departmentId !== "") getSubDepartmentData();
  }, [formValues?.departmentId]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const getSubDepartmentData = async () => {
    try {
      const result = await getRequest(
        `Department/GetSubDepartment/${formValues?.departmentId}`
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setSubDepartmentList(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Employee Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <>
      <div className="headingForm">Employee Profile</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Title"} />
          <SelectForm
            name="initialName"
            options={titleOptions}
            placeholder={"Select Title"}
          />
        </div>
        <div>
          <LabelCustom labelName={"First Name"} mandatory={true} />
          <InputCustomFormik
            name="name"
            type={"text"}
            placeholder={"Enter First Name"}
            maxLength={150}
          />
        </div>
        {/* <div>
          <LabelCustom labelName={"Middle Name"} />
          <InputCustomFormik
            
            maxLength={150}
            name="middleName"
            type={"text"}
            placeholder={"Enter Middle Name"}
          />
        </div> */}
        <div>
          <LabelCustom labelName={"Last Name"} />
          <InputCustomFormik
            maxLength={150}
            name="lastName"
            type={"text"}
            placeholder={"Enter Last Name"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Official Email Id"} mandatory={true} />
          <InputCustomFormik
            name="email"
            type={"email"}
            placeholder={"Enter Official Email"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Official Mobile Number"} mandatory={true} />
          <InputCustomFormik
            maxLength={10}
            name="OfficePhoneNumber"
            step=".001"
            placeholder={"Enter Official Mobile Number"}
          />
        </div>
      </div>
      <div className="headingForm">Organisation Details</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Date of Joining"} />
          <DatePickerFormiks
            name="doj"
            placeholder={"Select Date of Joining"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Reporting Manager"} mandatory={true} />
          <DropdownInfiniteScroll
            name="reportingOfficer"
            placeholder={"Select Reporting Manager"}
            detailsForEdit={[detailsform?.reportingOfficer]}
            editID={editID}
          />
          {/* <SelectForm
            name="reportingOfficer"
            placeholder={"Select Reporting Manager"}
            options={reportingOfficerOptions}
          /> */}
        </div>
        <div>
          <LabelCustom labelName={"Employee Type"} mandatory={true} />
          <SelectForm
            name="employeeTypeCode"
            placeholder={"Select Employee Type"}
            options={employeeTypeOptions}
          />
        </div>

        <div>
          <LabelCustom labelName={"Location"} mandatory={true} />
          <SelectForm
            name="area"
            placeholder={"Select Location"}
            options={areaOptions}
          />
        </div>
        <div>
          <LabelCustom labelName={"Designation"} mandatory={true} />
          <SelectForm
            name="designationId"
            options={designationOptions}
            placeholder={"Select Designation"}
            menuPlacement="top"
          />
        </div>
        <div>
          <LabelCustom labelName={"Department"} mandatory={true} />
          <SelectForm
            name="departmentId"
            options={departmentOptions}
            placeholder={"Select Department"}
            menuPlacement="top"
          />
        </div>
        {subDepartmentList.length > 0 && (
          <div>
            <LabelCustom labelName={"Sub Department"} />
            <SelectForm
              name="subDepartmentId"
              placeholder={"Select Sub Department"}
              options={subDepartmentList}
              menuPlacement="top"
            />
          </div>
        )}
        <div>
          <LabelCustom labelName={"Role"} mandatory={true} />
          <SelectForm
            name="roleId"
            placeholder={"Select Role"}
            options={roleOptions}
            menuPlacement="top"
          />
        </div>
        <div>
          <LabelCustom labelName={"System Role"} mandatory={true} />
          <SelectForm
            name="systemRoleId"
            placeholder={"Select Role"}
            options={systemRoleOptions}
            menuPlacement="top"
          />
        </div>
        <div>
          <LabelCustom labelName={"Shift"} mandatory={true} />
          <SelectForm
            name="shiftId"
            placeholder={"Select Shift"}
            options={shiftOptions}
            menuPlacement="top"
          />
        </div>
      </div>
    </>
  );
}
export { EmployeeDetails };
