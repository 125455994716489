import React from 'react'
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from '../../../../Components/FormComponent/TextAreaFormik';
import { useEffect } from 'react';

export const Day1FeedForm = () => {

  useEffect(() => {
    document.title = `PeopleSol - Day 1 Feedback`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  return (
    <div >
      <div>
        <LabelCustom labelName={"Question"} mandatory={true} />
        <TextAreaFormik
        
        maxLength={499} 
          name="question"
          placeholder={"Enter Question"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Display Order"} mandatory={true} />
        <InputCustomFormik  min={1} max={499} type={'number'} name="displayOrder" placeholder={'Enter Number '}/>
      </div>
      
    </div>
  );
};
