import React from 'react';
import { NewsTab } from './NewsTab';
import { useEffect } from 'react';

const News = () => {
    useEffect(() => {
        document.title = `PeopleSol - News`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
           
            
            <NewsTab title='News'/>
            
            
        </div>
    )
}

export { News }