import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../../Services/axios";
import { SystemForm } from "./Form/SystemForm";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { isLoading, showToast } from "../../../Features";
import { systemRolesSchema } from "./Schemas/Index";

const initialvalue = {
  name: "",
  permissionSetId: "",
};
const CreateSystemRoles = (props) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const data = state?.id;
  const [loading, setLoading] = useState(false);
  const [detailsform, setDetailsform] = useState({});
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`SystemRoles/Details/${data}`);
      //console.log("result.data", result.data);
      setDetailsform(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  useEffect(()=>{
    document.title = `PeopleSol - Create System Roles`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  const postFormData = async (values) => {
    try {
      const result = await postRequest("SystemRoles/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("SystemRoles/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const onSubmit = async (values) => {
    const edit = {
      ...values,
      Id: data,
    };

    const create = {
      ...values,
    };
    data ? Edit(edit) : postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/globalsettingsmaster/roles/systemroles");
  };

  return (
    <FormCustomFormik
      loading={loading}
      ButtonLabel={data ? "Update System Roles" : "Create System Roles"}
      FormHeading={data ? "Edit System Roles" : "Create System Roles"}
      validationSchema={systemRolesSchema}
      onSubmit={onSubmit}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              permissionSetId: detailsform?.permissionId,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={<SystemForm data={data} />}
    />
  );
};

export { CreateSystemRoles };
