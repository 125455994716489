import React, {  } from 'react';
import { RewardsTab } from './RewardsTab';
import { useEffect } from 'react';

const Rewards = () => {
    useEffect(() => {
        document.title = `PeopleSol -  Rewards`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
                    <RewardsTab title="Rewards"/>
        </div>
    )
}

export { Rewards }