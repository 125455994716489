import React from 'react'
import { useEffect } from 'react';

const ServiceFilesFolders = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Files And Folder Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      
        
    </div>
  )
}

export {ServiceFilesFolders};
