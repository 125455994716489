import React from "react";
import { useState } from "react";
import { Table } from "../../../Components";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalSeparation } from "../Components/SeparationApprovals/PendingApprovalSeparation";
import { PendingApprovalFacilities } from "../Components/FacilitiesApprovals/PendingApprovalFacilities";
import { ApprovedFacilities } from "../Components/FacilitiesApprovals/ApprovedFacilities";
import { HoldRequestFacilities } from "../Components/FacilitiesApprovals/HoldRequestFacilities";
import { RejectedFacilities } from "../Components/FacilitiesApprovals/RejectedFacilities";
import { AllRequestFacilities } from "../Components/FacilitiesApprovals/AllRequestFacilities";
import { useEffect } from "react";

const ApprovalFacilities = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Facilities`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalFacilities
            title={"Facilities"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && <ApprovedFacilities title={"Facilities"} />}

      {tabIndex === 2 && (
        <>
          <RejectedFacilities title={"Facilities"} />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <AllRequestFacilities
            title={"Facilities"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalFacilities };
