import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
} from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import MoreActivityContainer from "../../../Components/Table/MoreActivityContainer";
import ApproveIcon from "../../../Assets/ApproveIcon.svg";
import RejectIcon from "../../../Assets/RejectIcon.svg";
import HoldIcon from "../../../Assets/HoldIcon.svg";
import CancelIcon from "../../../Assets/CancelIcon.svg";
import { TransactionTable } from "../../../Components/Table/TransactionTable";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";

import { useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { DatePickerCustom } from "../../../Components/FormComponent/DatePickerCustom";
import { ApprovePopup } from "../../../Components/FormComponent/PopupComponent/ApprovePopup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../Components/FormComponent/Loader";
import { useSelector } from "react-redux";
import { DateRangePick } from "../../../Components/FormComponent/DateRangePick";
import backbuttonpic from "../../../Assets/backbuttonpic.svg";
import ChapterSmallIcon from "../../../Assets/ChapterSmallIcon.svg";

import { AssesmentDetailsLD } from "./AssesmentDetailsLD";

const MyTeamListLDResultsDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { chapterid, categoryId, employeeId } = location.state;
  // console.log("first", location);

  const status = [
    { label: "All", value: "All" },
    { label: "Pending", value: "Pending" },
    { label: "New", value: "New" },
    { label: "Rejected", value: "Rejected" },
    { label: "Approved", value: "Approved" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const guestOptions = [
    { label: "Casual", value: "Casual" },
    { label: "Special", value: "Special" },
  ];
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [detailsResults, setDetailsResults] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [selectedGuestList, setSelectedGuestList] = useState([]);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [dateOfJoning, setDateOfJoining] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [dateStartDate, setDateStartDate] = useState(false);
  const [dateEndDate, setDateEndDate] = useState(false);
  const [typeAll, setTypeAll] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [selId, setSelId] = useState(false);

  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);

      const result = await getRequest(
        // `LMSController/FeedbackAggregatesDetails?categoryId=${categoryId}&chapterid=${chapterid}`
        `LMSController/FeedbackAggregatesDetails?categoryId=${categoryId}&chapterid=${chapterid}&employeeId=${employeeId}`
      );
      setDetailsResults(result?.data);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const secstotimeformathrsmin = (totalSeconds, wantSecs) => {
    const hours = Math.floor(totalSeconds / 3600); // 1 hour = 3600 seconds
    const remainingSeconds = totalSeconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;
    if (wantSecs) {
      return `${hours}hrs ${minutes}mins ${seconds}Secs`;
    } else {
      return `${hours}hrs ${minutes}mins`;
    }
  };

  useEffect(() => {
    if (
      location.pathname.toLowerCase().includes("mylearning") &&
      location.pathname.toLowerCase().includes("myteamlist/details")
    )
      getListData();
    // else getAllListData();
  }, [location.pathname]);

  return (
    <div
      className="timelineContainerld candidateProfileContainer"
      style={{
        top: "12px",
        width: "calc(100% - 265px)",
        height: "calc(100% - 24px)",
        right: "11px",
        position: "absolute",
        zIndex: "4",
        overflow: "hidden",
        paddingBottom: "17px",
        // margin: " 0px 12px",
      }}
    >
      <div className="fullTableContainer noTab">
        <div
          className="LDresultsDetails"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "43px",
          }}
        >
          <div style={{ display: "flex", margin: "11px 10px" }} className="">
            <button
              onClick={() => navigate(-1)}
              className=" "
              style={{
                margin: "0px 10px 0px 20px",
                padding: "0px",
                border: "none",
                backgroundColor: "#FFF",
              }}
            >
              <img src={backbuttonpic} alt="Previous button" />
            </button>
            <div className="LDresultsDetailsFont" style={{}}>
              My Team Learning Assessment Results Details
            </div>
          </div>
        </div>

        <>
          {loading ? (
            <div className="noDataFound">
              <Loader />
            </div>
          ) : (
            <>
              <div
                className="DetailsAssesmentParentDiv"
                style={
                  {
                    //   fill: "#FFF",
                    //   filter:
                    //     "drop-shadow(0px 1.8944525718688965px 10px rgba(0, 0, 0, 0.04))",
                  }
                }
              >
                <div className="DetailsDashboardLD">
                  <div
                    className="DetailsDashboardDiv"
                    style={{
                      margin: " 0px 0px 20px 0px",
                    }}
                  >
                    <div>
                      <div className="DetailsDashboardDivfont1">
                        {detailsResults?.coursename}
                      </div>
                      <div className="DetailsDashboardDivfont2">
                        {detailsResults?.chaptername}
                      </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <div
                        className="courseMoreDetails"
                        style={{ marginBottom: "0px" }}
                      >
                        {/* <div
                          className="courseDetail"
                          style={{
                            width: "109px",
                            padding: "2px 5px",
                            borderRadius: "3.048px",
                            border: "0.5px solid var(--PeopleSol, #BFBFBF)",
                            margin: "0px 10px 0px 0px",
                          }}
                        >
                          <img
                            className="courseDetailImage"
                            src={ChapterSmallIcon}
                            alt={ChapterSmallIcon}
                          />

                          <label className="DetailsAssesmentlabelFont">
                            24 Chapters
                          </label>
                        </div> */}
                        <div
                          className="courseDetail"
                          style={{
                            minWidth: "109px",
                            padding: "2px 5px",
                            borderRadius: "3.048px",
                            border: "0.5px solid var(--PeopleSol, #BFBFBF)",
                          }}
                        >
                          {/* <img
                            className="courseDetailImage"
                            src={ChapterSmallIcon}
                            alt={ChapterSmallIcon}
                          /> */}
                          <svg
                            className="courseDetailImage"
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="12"
                            viewBox="0 0 10 12"
                            fill="none"
                          >
                            <path
                              d="M4.65278 4.0722H5.64801V7.05791H4.65278V4.0722ZM6.64324 0.588867H3.65756V1.58411H6.64324V0.588867ZM8.64862 3.76865C9.28351 4.56055 9.62931 5.54531 9.62892 6.5603C9.62892 9.03346 7.63846 11.0389 5.1504 11.0389C3.96262 11.0389 2.82349 10.567 1.9836 9.72712C1.14372 8.88723 0.671875 7.74809 0.671875 6.5603C0.671875 5.3725 1.14372 4.23336 1.9836 3.39347C2.82349 2.55357 3.96262 2.08172 5.1504 2.08172C6.20534 2.08172 7.17568 2.44996 7.94698 3.07696L8.6536 2.36039C8.90738 2.57934 9.1313 2.80825 9.35523 3.06203L8.64862 3.76865ZM8.63369 6.5603C8.63369 4.63451 7.07616 3.07696 5.1504 3.07696C3.22463 3.07696 1.6671 4.63451 1.6671 6.5603C1.6671 8.48608 3.22463 10.0436 5.1504 10.0436C7.07616 10.0436 8.63369 8.48608 8.63369 6.5603Z"
                              fill="#1296B0"
                            />
                          </svg>

                          <label className="DetailsAssesmentlabelFont">
                            {secstotimeformathrsmin(
                              detailsResults?.duration,
                              true
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="DetailsDashboardDiv"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div className="DashboardQuestionDiv">
                      <div className="DashboardQuestionDivKey">
                        Total Questions
                      </div>
                      <div className="DashboardQuestionDivValue">
                        {detailsResults?.totalQuestions}
                      </div>
                    </div>
                    <div
                      className="DashboardQuestionDiv"
                      style={{ background: " var(--HRMS-Light, #E7F8EC)" }}
                    >
                      <div
                        className="DashboardQuestionDivKey"
                        style={{ color: " var(--8, #0DB53C)" }}
                      >
                        Right Answers
                      </div>
                      <div
                        className="DashboardQuestionDivValue"
                        style={{ color: " var(--8, #0DB53C)" }}
                      >
                        {detailsResults?.correctAnswersCount}
                      </div>
                    </div>
                    <div
                      className="DashboardQuestionDiv"
                      style={{ background: "var(--PMS---Pastel, #FFF2F0)" }}
                    >
                      <div
                        className="DashboardQuestionDivKey"
                        style={{ color: "var(--PMS, #FB7158)" }}
                      >
                        Wrong Answers
                      </div>
                      <div
                        className="DashboardQuestionDivValue"
                        style={{ color: "var(--PMS, #FB7158)" }}
                      >
                        {detailsResults?.incorrectAnswersCount}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: " 96%",
                    margin: "31px 0px 0px 0px",
                    height: "616px",
                    overflow: "auto",

                    // padding: "0px 15px",
                  }}
                >
                  <AssesmentDetailsLD detailsResults={detailsResults} />
                </div>
              </div>
            </>
          )}
          {/* <div className="horizontalLine"></div>
          <div className="tableFooterContainer"></div> */}
        </>

        {/* {isOpen && (
          <Popup
            popupHeading={typeAction}
            content={
              <>
                <Remark
                  data={PostApRejHol}
                  type={typeAction}
                  id={selId}
                  color={colorAction}
                  handleClose={togglePopup}
                />
              </>
            }
            handleClose={togglePopup}
          />
        )}
        {isApprovePopup && (
          <ApprovePopup
            popupHeading={typeAll}
            selectedRows={selectedRows}
            type={typeAll}
            content={
              <div style={{ display: "flex" }}>
                {typeAll === "ApproveAll" ? (
                  <>
                    <button
                      className="button"
                      onClick={() => {
                        handleApprove(selectedRows, typeAll);
                      }}
                    >
                      Confirm
                    </button>
                    <button className="button">Cancel</button>
                  </>
                ) : (
                  <div style={{ width: "100%" }}>
                    <Remark
                      data={rejectAll}
                      type={typeAll}
                      id={selectedRows}
                      color={"red"}
                      handleClose={togglePopupapprove}
                    />
                  </div>
                )}
              </div>
            }
            handleClose={togglePopupapprove}
          />
        )} */}
      </div>
    </div>
  );
};
export { MyTeamListLDResultsDetails };
