import React from 'react';
import "./HeaderCountDetails.css";
import { useEffect } from 'react';

const HeaderCountDetails = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Header Count Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className='countDetails'>
      <label>{props.title}</label>
      <label className='countItem' style={props.styles}>{props.count}</label>
    </div>
  )
}

export {HeaderCountDetails}
