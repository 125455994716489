import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { CreatePerformanceCycleComp } from "./FormComp/CreatePerformanceCycleComp";
import { useEffect } from "react";

const CreatePerformanceCycle = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`AppraisalCycles/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("AppraisalCycles/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Create Performance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("AppraisalCycles/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const initialvalue = {
    name: "",
    type: "",
    goalSettingId: "",
    cycleStartDate: null,
    cycleEndDate: null,
    description: null,
    upload: null,
    processStartDate: "",
    processEndDate: "",
    selfApprisalStartDate: "",
    selfApprisalEndDate: "",
    appraiserReviewStartDate: "",
    appraiserReviewEndDate: "",
    reviewStartDate: "",
    reviewEndDate: "",
    departmentIds: "",
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "application/pdf",
  ];

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please enter name"),
    type: Yup.string().required("Please select type"),
    goalSettingId: Yup.string().required("Please select goal setting"),
    processStartDate: Yup.date().required("Please select process start date"),
    processEndDate: Yup.date().required("Please select process end date"),
    selfApprisalStartDate: Yup.date().required(
      "Please select self apprisal start date"
    ),
    selfApprisalEndDate: Yup.date().required(
      "Please select self apprisal end date"
    ),
    appraiserReviewStartDate: Yup.date().required(
      "Please select appraiser review start date"
    ),
    appraiserReviewEndDate: Yup.date().required(
      "Please select appraiser review end date"
    ),
    reviewStartDate: Yup.date().required("Please select review start date"),
    reviewEndDate: Yup.date().required("Please select review end date"),
    description: Yup.string()
      .max(499, "Description must be less than 500 characters")
      .nullable(),
    upload: Yup.mixed()
      .test(
        "Fichier taille",
        "File Size should be less than 2 mb",
        (value) =>
          !value ||
          (value && value.size <= 2048 * 2048) ||
          typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value ||
          typeof value !== "object" ||
          (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .notRequired("Please Attach File"),
  });

  const onSubmit = (values) => {
    //console.log("vakk", values?.departmentIds);
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("Id", data ? data : 0);
    formData.append("description", values?.description);
    formData.append("type", values?.type);
    formData.append(
      "processStartDate",
      `${format(values?.processStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "processEndDate",
      `${format(values?.processEndDate, "MM/dd/yyyy")}`
    );
    formData.append("departmentIds", values?.departmentIds);
    formData.append("goalSettingId", values?.goalSettingId);
    if (typeof values?.upload === "object")
      formData.append("upload", values?.upload);
    if (typeof values?.upload === "object")
      formData.append(
        "guideLineDocument",
        values?.upload !== null
          ? values?.upload?.name
          : detailsform?.guideLineDocument
          ? detailsform?.guideLineDocument.toString()
          : null
      );
    formData.append(
      "cycleStartDate",
      `${format(values?.cycleStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "cycleEndDate",
      `${format(values?.cycleEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "selfApprisalStartDate",
      `${format(values?.selfApprisalStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "selfApprisalEndDate",
      `${format(values?.selfApprisalEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "appraiserReviewStartDate",
      `${format(values?.appraiserReviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "appraiserReviewEndDate",
      `${format(values?.appraiserReviewEndDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "reviewStartDate",
      `${format(values?.reviewStartDate, "MM/dd/yyyy")}`
    );
    formData.append(
      "reviewEndDate",
      `${format(values?.reviewEndDate, "MM/dd/yyyy")}`
    );
    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate("/pmsmaster/performancecycle");
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={props.title}
      ButtonLabel={data ? "Update" : "Create"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialvalue,
              name: detailsform?.name,
              type: detailsform?.type,
              goalSettingId: detailsform?.goalSettingId,
              cycleStartDate: new Date(detailsform?.cycleStartDate ?? null),
              cycleEndDate: new Date(detailsform?.cycleEndDate ?? null),
              description: detailsform?.description,
              upload: `PmsAppraisalCycles/${detailsform?.upload}`,
              processStartDate: new Date(detailsform?.processStartDate ?? null),
              processEndDate: new Date(detailsform?.processEndDate ?? null),
              selfApprisalStartDate: new Date(
                detailsform?.selfApprisalStartDate ?? null
              ),
              selfApprisalEndDate: new Date(
                detailsform?.selfApprisalEndDate ?? null
              ),
              appraiserReviewStartDate: new Date(
                detailsform?.appraiserReviewStartDate ?? null
              ),
              appraiserReviewEndDate: new Date(
                detailsform?.appraiserReviewEndDate ?? null
              ),
              reviewStartDate: new Date(detailsform?.reviewStartDate ?? null),
              reviewEndDate: new Date(detailsform?.reviewEndDate ?? null),
              departmentIds:
                detailsform?.departmentIds == null
                  ? ""
                  : detailsform?.departmentIds,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreatePerformanceCycleComp
          data={detailsform}
          editID={data ? true : false}
        />
      }
    />
  );
};

export { CreatePerformanceCycle };
