import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../Features";
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
} from "../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import MoreActivityContainer from "../../../Components/Table/MoreActivityContainer";
import ApproveIcon from "../../../Assets/ApproveIcon.svg";
import RejectIcon from "../../../Assets/RejectIcon.svg";
import HoldIcon from "../../../Assets/HoldIcon.svg";
import CancelIcon from "../../../Assets/CancelIcon.svg";
import { TransactionTable } from "../../../Components/Table/TransactionTable";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";

import { useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { DatePickerCustom } from "../../../Components/FormComponent/DatePickerCustom";
import { ApprovePopup } from "../../../Components/FormComponent/PopupComponent/ApprovePopup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import OutsideClickHandler from "../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../Components/FormComponent/Loader";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { DatePicker } from "antd";
import { DateRangePick } from "../../../Components/FormComponent/DateRangePick";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../Features/badgeUtils";
import backbuttonpic from "../../../Assets/backbuttonpic.svg";

import SelectCustomEmployeeCreate from "../../DropdownComponents/SelectCustomEmployeeCreate";

const MyListLDResults = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = [
    { label: "All", value: "All" },
    { label: "Pending", value: "Pending" },
    { label: "Rejected", value: "Rejected" },
    { label: "Approved", value: "Approved" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const guestOptions = [
    { label: "Casual", value: "Casual" },
    { label: "Special", value: "Special" },
  ];
  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [areaList, setAreaList] = useState([]);
  const [selectedGuestList, setSelectedGuestList] = useState([]);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState(null);
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [dateSelect, setDateSelect] = useState(false);
  const [dateOfJoning, setDateOfJoining] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [dateStartDate, setDateStartDate] = useState(false);
  const [dateEndDate, setDateEndDate] = useState(false);
  const [typeAll, setTypeAll] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [selId, setSelId] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [lessonList, setLessonList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);
  const [selectedCourseList, setSelectedCourseList] = useState([]);
  const [selectedLessonList, setSelectedLessonList] = useState([]);
  const togglePopup = (type, color, id) => {
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setIsOpen((prev) => !prev);
  };
  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });

  const getCategoryData = async () => {
    try {
      const result = await getRequest("LMSController/GetCategory");
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setCategoryList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getCourseData = async () => {
    try {
      const result = await getRequest("LMSController/GetCourse");
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setCourseList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getChapterData = async () => {
    try {
      const result = await getRequest("LMSController/GetChapters");
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setLessonList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const togglePopupapprove = (type) => {
    setTypeAll(type);
    setIsApprovePopup((prev) => !prev);
  };
  useEffect(() => {
    document.title = `PeopleSol - My Learning Assessment Results`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const clickHandlerFunction = (id, type, color) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Mark Out-Time " &&
      type !== "Mark In-Time "
    ) {
      togglePopup(type, color, id);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await getRequest(`GatePassRequestsController/${type}?id=${id}`);
      } else if (type === "Mark as Closed") {
        await getRequest(
          `GatePassRequestsController/Close?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Approve") {
        await getRequest(`GatePassRequestsController/Approve?Id=${id}`);
      } else if (type === "Reject") {
        await postRequest(
          `GatePassRequestsController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Mark In-Time ") {
        await getRequest(`GatePassRequestsController/AddOutTime?ID=${id}`);
      } else if (type === "Mark Out-Time ") {
        await getRequest(`GatePassRequestsController/AddInTime?ID=${id}`);
      } else {
        await getRequest(`GatePassRequestsController/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getListData();
    }
  };
  const getAllListCheckStatus = (data) => {
    let returnValue = false;
    data?.actions?.map((item) => {
      if (item.type === "Approve" || item.type === "Reject") returnValue = true;
      return null;
    });
    return returnValue;
  };
  const defaultList = "GatePassDL";
  const { LoggedInUser } = useSelector((state) => state.auth);

  const [activeList, setActiveList] = useState(
    localStorage.getItem(defaultList) ?? "1"
  );
  function handleActiveListChange(newValue) {
    setActiveList(newValue.toString());
    setSelectedRows([]);
    setRecordsSelected(noOfRecords[0]);
    setSearchParam(null);
    setSortParam("Id");
    setSortType("descending");
    setReqData({
      createdOn: null,
      lastUpdatedOn: null,
      dataTableRequest: {
        iDisplayLength: noOfRecords[0].value,
        iDisplayStart: 0,
        sSearch: null,
        sorting: "Id",
        sSortDir_0: "descending",
        isExport: false,
      },
    });
  }

  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };

  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
      };

      var result = await postRequest(
        "LMSController/FeedbackAggregatesListView",
        downloadReqData
      );

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );
      element.setAttribute("download", "My Learning Assessment Results");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      // // console.log(error);
      dispatch(
        showToast({ text: "Error in downloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getStaticActions = (data) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              stroke-linecap="round"
            />
          </svg>
        ),
        action: "details",
        clickAction: () =>
          getFormDetails(data?.chapterid, data?.categoryid, data?.employeeId),
      },
    ];
    return returnActionArray;
  };
  const getListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);

      const result = await postRequest(
        "LMSController/FeedbackAggregatesListView",
        reqData
      );
      // console.log("result.data.data", result);
      if (result.data) {
        let tempData = [];
        result.data.data.map((item) =>
          tempData.push({
            ...item.item,
            checkActive: false,
            statusColor: item.statusColor,
            actions: getStaticActions(item.item),
            status: item?.statusLabel,
            courseName: item?.item?.categoryname,
            chapterName: item?.item?.coursename,
            lessonName: item?.item?.title,
            totalQuestionSet: item?.item?.totalquestionset,
            questionsAnswered: item?.item?.answerquestion,
            id: item.item?.chapterid,
            assessmentDate:
              item.item?.assessmentDate === "0001-01-01T00:00:00"
                ? ""
                : item.item?.assessmentDate,
          })
        );
        setOriginalData(tempData);
        const clippedArray = tempData.map(
          ({
            checkActive,
            comment,
            department,
            employeeId,
            inTime,
            outTime,
            managerId,
            nextApprover,
            nextApproverId,
            nextRequestId,
            personNumber,
            reportingOfficer,
            statusColor,
            actions,
            moreActions,
            ...rest
          }) => ({
            ...rest,
          })
        );
        const finalArray = clippedArray.map(
          ({
            id,
            courseName,
            chapterName,
            lessonName,
            totalQuestionSet,
            totalQuestions,
            correctAnswers,
            incorrectAnswers,
            passPercentage,
            questionsAnswered,
            trainingDate,
            assessmentDate,
          }) => ({
            id,
            courseName,
            chapterName,
            lessonName,
            totalQuestionSet,
            totalQuestions,
            correctAnswers,
            incorrectAnswers,
            passPercentage,
            questionsAnswered,
            trainingDate,
            assessmentDate,
          })
        );
        setTbodyData(finalArray);
      } else {
        setTbodyData(false);
      }
      setCount(result.data.summary.allCount);
      calculateTotalPages(result.data.summary.allCount);
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/ld/learning/mylearning/mylist") getListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const getFormDetails = (chapterid, categoryId, employeeId) => {
    navigate(`/ld/learning/mylearning/mylist/details`, {
      state: { categoryId, chapterid, employeeId },
    });
  };

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);

      if (heading === "courseName") heading = "categoryname";
      else if (heading === "chapterName") heading = "coursename";
      else if (heading === "lessonName") heading = "title";
      else if (heading === "totalQuestionSet") heading = "totalquestionset";
      else if (heading === "questionsAnswered") heading = "answerquestion";
      // else if (heading === "deputationEffectiveDate")
      //   heading = "transferEffectiveDate";

      setReqData({
        ...reqData,

        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };

  const applyFilters = () => {
    setReqData({
      ...reqData,
      // employeeId: selectedEmployeeList[0]?.value,
      CategoryID: selectedCategoryList[0]?.value,
      CourseName: selectedCourseList[0]?.label,
      ChapterName: selectedLessonList[0]?.label,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setSelectedCategoryList([]);
    setSelectedCourseList([]);
    setSelectedLessonList([]);
    setReqData({
      ...reqData,
      CategoryID: null,
      CourseName: null,
      ChapterName: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };

  const childRef = useRef(null);
  function handleActivityClick() {
    childRef.current.updateState();
  }
  function handleActivityClose() {
    childRef.current.closeState();
  }
  // console.log("first reqData", reqData);
  return (
    <div
      className="timelineContainerld candidateProfileContainer"
      style={{
        top: "12px",
        width: "calc(100% - 265px)",
        height: "calc(100% - 24px)",
        right: "11px",

        position: "absolute",
        zIndex: "4",
        overflow: "hidden",
        paddingBottom: "17px",
        // margin: " 0px 12px",
      }}
    >
      <div className="fullTableContainer noTab">
        <OutsideClickHandler
          onOutsideClick={() => {
            setFilterSearchToogle((prev) => {
              if (prev) return false;
              else return prev;
            });
          }}
        >
          <div
            className={`${
              filterSearchToogle
                ? "filterSearchContainerActive filterSearchContainer"
                : "filterSearchContainer"
            }`}
          >
            <div className="headingContainer">
              <h3>Filter</h3>
              <IconButton
                onClick={() => setFilterSearchToogle((prev) => !prev)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7579 1.56897L0.757935 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M0.757935 1.56897L18.7579 19.569"
                    stroke="#F71D1D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </div>
            <h5>Fields</h5>
            <div className="filterListContainer">
              {
                // <div className="filterContainer">
                //   <p> Employee </p>
                //   <SelectCustomEmployeeCreate
                //     selectedEmployeeList={selectedEmployeeList}
                //     setSelectedEmployeeList={setSelectedEmployeeList}
                //     values={selectedEmployeeList}
                //   />
                //   {/* <SelectCustom
                //     onDropdownOpen={() => {
                //       getEmployeeData();
                //     }}
                //     options={employeeList}
                //     values={selectedEmployeeList}
                //     onChange={(value) => {
                //       setSelectedEmployeeList(value);
                //     }}
                //   /> */}
                // </div>
              }
              <div className="filterContainer">
                <p> Course </p>

                <SelectCustom
                  onDropdownOpen={() => {
                    getCategoryData();
                  }}
                  options={categoryList}
                  values={selectedCategoryList}
                  onChange={(value) => {
                    setSelectedCategoryList(value);
                  }}
                />
              </div>
              <div className="filterContainer">
                <p> Chapter </p>

                <SelectCustom
                  onDropdownOpen={() => {
                    getCourseData();
                  }}
                  options={courseList}
                  values={selectedCourseList}
                  onChange={(value) => {
                    setSelectedCourseList(value);
                  }}
                />
              </div>
              <div className="filterContainer">
                <p> Lesson </p>

                <SelectCustom
                  onDropdownOpen={() => {
                    getChapterData();
                  }}
                  options={lessonList}
                  values={selectedLessonList}
                  onChange={(value) => {
                    setSelectedLessonList(value);
                  }}
                />
              </div>
            </div>
            <div className="filterSearchFooterContainer">
              <button className="button secondaryButton" onClick={resetFilters}>
                Reset
              </button>
              <button className="button primaryButton" onClick={applyFilters}>
                Apply
              </button>
            </div>
          </div>
        </OutsideClickHandler>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            zIndex: 5,
            backgroundColor: " var(--white)",
            padding: "0 32px 0 12px",
            marginBottom: "-6px",
            minHeight: "60px",
            height: "60px",
          }}
        >
          <div style={{ display: "flex" }}>
            <button
              onClick={() => navigate("mylearning")}
              className="button secondaryButton"
              style={{ minWidth: "54px", margin: "0px", padding: "0px" }}
            >
              <img src={backbuttonpic} alt="Previous button" />
            </button>
            <div style={{ color: "var(--primary)", fontWeight: "500" }}>
              My Learning Assessment Results
            </div>
          </div>
          <div
            className="tableHeaderContainer"
            style={{ marginBottom: "0px", marginTop: "0px", padding: "0px" }}
          >
            <div className="search-box">
              <input
                className="search-text"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearchParam(e.target.value)}
              />
              <a className="search-btn">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                    fill="#1296B0"
                  />
                </svg>
              </a>
            </div>
            <Tooltip arrow title="Filter" placement="top">
              <IconButton
                color="primary"
                onClick={() => {
                  setFilterSearchToogle((prev) => !prev);
                }}
              >
                {!filterSearchToogle ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                      stroke="#1296B0"
                      strokeWidth="1.5"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.7579 1.56897L0.757935 19.569"
                      stroke="#F71D1D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M0.757935 1.56897L18.7579 19.569"
                      stroke="#F71D1D"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </IconButton>
            </Tooltip>
            <Tooltip arrow title="Download" placement="top">
              <IconButton color="primary" onClick={exportTable}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 21 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                    stroke="#1296B0"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <>
          {loading ? (
            <div className="noDataFound">
              <Loader />
            </div>
          ) : (
            <>
              <TransactionTable
                tableFilterName="outdoorFilter"
                tbodyData={tbodyData}
                headerAction={sortUpdated}
                sortParam={sortParam}
                sortType={sortType}
                selectedRows={selectedRows}
                addToSelectedRows={addToSelectedRows}
                originalData={originalData}
                containStatus={true}
              />
            </>
          )}
          <div className="horizontalLine"></div>
          <div className="tableFooterContainer">
            <label>Total Record Count: </label>
            <span>{count}</span>
            <SelectCustom
              searchable={false}
              options={noOfRecords}
              dropdownPosition="top"
              values={[recordsSelected]}
              onChange={(e) => paginationChanged(e)}
            />
            <label>Records Per Page</label>
            {currentPage > 1 ? (
              <IconButton
                onClick={() => pageChangedPrev()}
                color="primary"
                className="pageChangerIcon pageChangerIconActive"
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton disabled color="primary" className="pageChangerIcon">
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
            {currentPage} - {totalPages}
            {totalPages > currentPage ? (
              <IconButton
                onClick={() => pageChangedNext()}
                color="primary"
                className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            ) : (
              <IconButton
                disabled
                color="primary"
                className={`pageChangerIcon pageChangerIconNext`}
              >
                <svg
                  width="8"
                  height="12"
                  viewBox="0 0 8 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                    stroke="#BFBFBF"
                    strokeLinecap="round"
                  />
                </svg>
              </IconButton>
            )}
          </div>
        </>
      </div>
    </div>
  );
};
export { MyListLDResults };
