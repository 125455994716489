import React, { useEffect, useState } from "react";
import "./Community.css";
import SocialCommunityDeactivateGroupIcon from "../../../../../Assets/SocialCommunityDeactivateGroupIcon.svg";
import SocialCommunityLeavrGroupIcon from "../../../../../Assets/SocialCommunityLeavrGroupIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import SearchMagnifierIcon from "../../../../../Assets/SearchMagnifierIcon.svg";
import ArrowBackButton from "../../../../../Assets/ArrowBackButton.svg";
import PlusYellowIcon from "../../../../../Assets/PlusYellowIcon.svg";
import { useNavigate } from "react-router";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { useSelector } from "react-redux";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { IconButton } from "@mui/material";
import CreateCommunity from "./CreateCommunity";
import {
  profileImages,
  communityProfileImages,
} from "../../../../../Utilities/profileAndUploadImages";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { setCommunityData } from "../../../../../Features/SocialSlice";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import { getPersonNumber, getUserInitials } from "../../../../../Utilities/socialAllFunctions";
function CommunityList() {
  const [searchAllCommunityList, setSearchAllCommunityList] = useState([]);
  const [communityId, setCommunityId] = useState(0);
  const [leaveCommunityActiveByAdmin, setLeaveCommunityActiveByAdmin] =
    useState(false);
  const [leaveCommunityActive, setLeaveCommunityActive] = useState(false);
  const [isCreateCommunityActive, setIsCreateCommunityActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deactivateCommunityActive, setDeactivateCommunityActive] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { communityData } = useSelector((state) => state.social);
  const [commData, setCommData] = useState();
  const [searchCommData, setSearchCommData] = useState();
  const [selectedRecipient, setSelectedRecipient] = useState(0);
  const [communityAdminAndMemberList, setCommunityAdminAndMemberList] =
    useState([]);
  const [employeeSelectionActive, setEmployeeSelectionActive] = useState(false);
  function searchCommunity(e) {
    if (e.target.value !== "") {
      let tempChatters = JSON.parse(JSON.stringify(searchAllCommunityList));
      tempChatters = tempChatters.filter((d, i) =>
        d?.item?.communityName
          ?.toLowerCase()
          ?.includes(e.currentTarget.value?.toLowerCase())
      );
      dispatch(setCommunityData(tempChatters));
    } else {
      dispatch(setCommunityData(searchAllCommunityList));
    }
  }
  const getCommunityMemberDataForNewAdmin = async () => {
    setLoading(true);
    try {
      const result = await postRequest(`Community/AllList`, {
        createdOn: null,
        communityId: communityId,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 10,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      let listData = [];
      result?.data?.data?.map((item) => {
        listData.push(item?.item);
      });
      if (listData?.length > 0) {
        setCommData(listData[0]);
        setSearchCommData(listData[0]);
      }
      let updatedList = [];
      for (let i = 0; i < listData[0]?.groupMembers?.length; i++) {
        let isMemberAdmin = false;
        for (let j = 0; j < listData[0]?.groupAdmin?.length; j++) {
          if (
            listData[0]?.groupMembers[i]?.personId ===
            listData[0]?.groupAdmin[j]?.personId
          ) {
            isMemberAdmin = true;
            break;
          }
        }
        updatedList.push({ ...listData[0]?.groupMembers[i], isMemberAdmin });
      }
      updatedList.sort((a, b) => (b?.isMemberAdmin ? 1 : -1));
      setCommunityAdminAndMemberList(updatedList);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const deactivateCommunityById = async () => {
    try {
      const result = await getRequest(
        `Community/DeleteCommunity?Id=${communityId}`
      );
      getCommunityData();
      setDeactivateCommunityActive(false);
      dispatch(
        showToast({
          text: "Community deactivated successfully!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
    }
  };
  const leaveCommunityByAdmin = async () => {
    setLoading(true);
    try {
      const result = await postRequest(
        `Community/LeftCommunity?CommunityId=${communityId}&CreatedBy=${selectedRecipient}`
      );
      getCommunityData();
      setLeaveCommunityActive(false);
      setSelectedRecipient(0);
      dispatch(
        showToast({
          text: "Community left successfully!",
          severity: "success",
        })
      );
      setEmployeeSelectionActive(false);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
      setLoading(false);
    }
  };
  const leaveCommunityById = async () => {
    try {
      const result = await postRequest(
        `Community/LeftCommunity?CommunityId=${communityId}`
      );
      getCommunityData();
      setLeaveCommunityActive(false);
      dispatch(
        showToast({
          text: "Community left successfully!",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in creating community",
          severity: "error",
        })
      );
      return;
    } finally {
    }
  };
  const getCommunityData = async () => {
    setLoading(true);
    try {
      const result = await postRequest("Community/AllList", {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength: 100,
          iDisplayStart: 0,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      dispatch(setCommunityData(result?.data?.data));
      setSearchAllCommunityList(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getCommunityData();
  }, []);
  return (
    <div className="myCommunityListContainer">
      <div className="myCommunitySearchContainer">
        <input
          type="text"
          className="communityListSearch"
          placeholder="Search"
          onChange={searchCommunity}
        />
        <img src={SearchMagnifierIcon} alt="" />
      </div>
      <div className="myCommunityListWrapper">
        <div className="myCommunityListHeaderContainer">
          <div className="showCommunityLeftHeaderContainer">
            <IconButton  onClick={() => {
                  navigate("/home/social/social");
                }}>
              <img
                src={ArrowBackButton}
                alt=""
                style={{ margin: "5px 3px", cursor: "pointer" }}
               
              />
            </IconButton>
            <span style={{ marginLeft: "7px" }}>Your Community</span>
          </div>
          <div
            className="showCommunityRightHeaderContainer"
            onClick={() => {
              setIsCreateCommunityActive(true);
            }}
          >
            <IconButton>
              <img
                src={PlusYellowIcon}
                alt=""
                style={{ marginRight: "7px", cursor: "pointer" }}
              />
              <span>Create Community</span>
            </IconButton>
          </div>
        </div>
        {loading && <Loader />}
        {!loading && (
          <div className="myCommunityListBodyContainer newScrollbar">
            {communityData?.length > 0 &&
              communityData.map((d, i) => {
                return (
                  <div className="myCommunityListBodyWrapper">
                    <div className="myCommunityListGroupContainer">
                      <div
                        className="myCommunityListGroupWrapper"
                        onClick={() => {
                          navigate(
                            window.location.pathname + "/" + d?.item?.id
                          );
                        }}
                      >
                        <div className="myCommunityListGroupImgContainer">
                          <div
                            onClick={(e) => {
                              // handleUserProfileClick(LoggedInUser?.id);
                            }}
                            className={`${
                              d?.item?.communityImage
                                ? "socialCommunityProfilePicContainer"
                                : "socialCommunityListCustomProfilePic"
                            }`}
                          >
                            {!d?.item?.communityImage &&
                            d?.item?.communityName ? (
                              getUserInitials(d?.item?.communityName)
                            ) : (
                              <img
                                className="socialProfilePic"
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  communityProfileImages +
                                  d?.item?.communityImage
                                }
                                alt="Profile"
                                onError={(e) => {
                                  e.target.src = SocialCommunityGroupIcon;
                                }}
                              />
                            )}
                          </div>
                          
                        </div>
                        <div className="myCommunityListTextContainer">
                          <h5>{d?.item?.communityName}</h5>
                          <p>
                            {d?.item?.groupMembers?.length +
                              d?.item?.groupAdmin?.length}{" "}
                            Members
                          </p>
                        </div>
                      </div>

                      <div className="myCommunityListLeaveExitGroupContainer">
                        {d?.item?.groupAdminId?.includes(LoggedInUser?.id) && (
                          <div className="myCommunityListLeaveExitGroupWrapper">
                            <div
                              className="deactivateGroupWrapper"
                              onClick={() => {
                                setCommunityId(d?.item?.id);
                                setDeactivateCommunityActive(true);
                              }}
                            >
                              <span>Deactive</span>
                              <img
                                src={SocialCommunityDeactivateGroupIcon}
                                alt=""
                              />
                            </div>
                            <div
                              className="leaveGroupWrapper"
                              onClick={() => {
                                setCommunityId(d?.item?.id);
                                setLeaveCommunityActiveByAdmin(true);
                              }}
                            >
                              <span>Leave</span>
                              <img src={SocialCommunityLeavrGroupIcon} alt="" />
                            </div>
                          </div>
                        )}
                        {!d?.item?.groupAdminId?.includes(LoggedInUser?.id) && (
                          <div
                            className="leaveGroupWrapper"
                            onClick={() => {
                              setCommunityId(d?.item?.id);
                              setLeaveCommunityActive(true);
                            }}
                          >
                            <span>Leave</span>
                            <img src={SocialCommunityLeavrGroupIcon} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {leaveCommunityActive && (
        <Popup
          firstClassName="communityLeaveDeactivePopupBox1"
          secondClassName="communityLeaveDeactivePopupBox2"
          popupHeading={`Leave this community`}
          content={
            <>
              <div className="communityLeaveDeactiveBody">
                <p>
                  You will no longer receive updates from this community. Would
                  you still like to leave this community?
                </p>
              </div>
              <div className="socialPostDeleteBtn">
                <button onClick={leaveCommunityById}>Leave</button>
                <button
                  onClick={() => {
                    setLeaveCommunityActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setLeaveCommunityActive(false);
          }}
        />
      )}
      {deactivateCommunityActive && (
        <Popup
          firstClassName="communityLeaveDeactivePopupBox1"
          secondClassName="communityLeaveDeactivePopupBox2"
          popupHeading={`Leave this community`}
          content={
            <>
              <div className="communityLeaveDeactiveBody">
                <p>Are you sure want to deactive your community?</p>
              </div>
              <div className="socialPostDeleteBtn">
                <button onClick={deactivateCommunityById}>Deactive</button>
                <button
                  onClick={() => {
                    setDeactivateCommunityActive(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setDeactivateCommunityActive(false);
          }}
        />
      )}
      {leaveCommunityActiveByAdmin && (
        <Popup
          firstClassName="communityLeaveDeactivePopupBox1"
          secondClassName="communityLeaveDeactivePopupBox2"
          popupHeading={`Leave this community`}
          content={
            <>
              <div className="communityLeaveDeactiveBody">
                <p>
                  Your are the creator of the community, If you want to leave
                  please select creator for this Community
                </p>
              </div>
              <div className="socialPostDeleteBtn">
                <button
                  onClick={() => {
                    getCommunityMemberDataForNewAdmin();
                    setEmployeeSelectionActive(true);
                    setLeaveCommunityActiveByAdmin(false);
                  }}
                >
                  Next
                </button>
                <button
                  onClick={() => {
                    setLeaveCommunityActiveByAdmin(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          }
          handleClose={() => {
            setLeaveCommunityActiveByAdmin(false);
          }}
        />
      )}
      {employeeSelectionActive && (
        <Popup
          firstClassName="newPostForEmployeePopup1"
          secondClassName="newPostForEmployeePopup2"
          contentClassName="newPostForEmployeePopupContent"
          bodyClassName="hideScrollBarContainer"
          popupHeading={
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton  onClick={() => {
                    setEmployeeSelectionActive(false);
                  }}>
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "5px 3px", cursor: "pointer" }}
                 
                />
              </IconButton>
              <h5>Select New Creator</h5>
            </div>
          }
          content={
            <div className="selectEmployeeContainer">
              <div className="selectEmployeeWrapper">
                <div className="commonSearchListContainer">
                  <input
                    type="text"
                    className="commonSearchList"
                    placeholder="Search"
                    // onChange={searchChatters}
                  />

                  <img src={SearchMagnifierIcon} alt="" />
                </div>
                <div className="totalEmployeeSelectedContainer">
                  {communityAdminAndMemberList?.map((d, i) => {
                    if (d?.personId !== LoggedInUser?.id)
                      return (
                        <div className="selectedEmployeeList">
                          <div className="employeeList">
                            <div className="totalLikePopupImg">
                              <img
                                src={
                                  process.env.REACT_APP_.BASE_URL +
                                  profileImages +
                                  d?.profilePicture
                                }
                                alt=""
                              />
                            </div>
                            <div className="totalLikePopupText">
                              <h5>
                                {getDisplayName(d?.name)}{" "}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {getPersonNumber(d?.name)}
                                </span>
                              </h5>
                              <p
                                style={{ fontSize: "14px", fontWeight: "500" }}
                              >
                                {d?.jobRoal}
                              </p>
                            </div>
                          </div>
                          <div
                            className="socialCommonChkbox"
                            onClick={() => {
                              setSelectedRecipient(d?.personId);
                            }}
                          >
                            <input
                              type="checkbox"
                              class="myinput large"
                              name="chjsah"
                              checked={selectedRecipient === d?.personId}
                            />
                          </div>
                        </div>
                      );
                  })}
                </div>
              </div>
              <div className="welcomeOptionBottomContainer">
                <div
                  className={`welcomeOptionBottomWrapper ${
                    selectedRecipient !== null ? "active" : ""
                  }`}
                >
                  {!loading && selectedRecipient !== 0 && (
                    <span
                      className="active"
                      onClick={() => {
                        leaveCommunityByAdmin();
                      }}
                    >
                      Leave
                    </span>
                  )}
                  {loading && <span>Leaving...</span>}
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setEmployeeSelectionActive(false);
            setSelectedRecipient(0);
          }}
        />
      )}
      {isCreateCommunityActive && (
        <CreateCommunity
          getCommunityData={getCommunityData}
          setIsCreateCommunityActive={setIsCreateCommunityActive}
        />
      )}
    </div>
  );
}

export default CommunityList;
