import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import Nodata from "../../../../../Assets/Nodata.svg";
import MyImageComponent from "./MyImageComponent";

function BodyForKnowledgeCenter({ picArray, idActive }) {
  const [autoPlay, setAutoPlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadedCount, setLoadedCount] = useState(0);

  const parts = idActive.toString().split(".");
  const currentPics = picArray[parts[0] - 1].subTitle[parts[1] - 1].pics;
  const totalImages = currentPics.length;

  useEffect(() => {
    if (loadedCount === totalImages) {
      setAutoPlay(true);
    }
  }, [loadedCount, totalImages]);
  return (
    <div className="CarouselCover">
      {
        <Carousel
          autoPlay={autoPlay}
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          showIndicators={
            picArray[parts[0] - 1]?.subTitle[parts[1] - 1]?.pics?.length > 1
              ? true
              : false
          }
        >
          {currentPics.map((pic, index) => (
            <div key={index} style={{ position: "relative" }}>
              <MyImageComponent
                loading={loading}
                setLoading={setLoading}
                src={pic}
                alt={`pic ${index}`}
                setLoadedCount={setLoadedCount}
              />
            </div>
          ))}
        </Carousel>
      }
    </div>
  );
}

export default BodyForKnowledgeCenter;
