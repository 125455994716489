import React from "react";
import { useDispatch } from "react-redux";
import { closeService, loggedOut } from "../../Features";
import OutsideClickHandler from "../../Components/OutsideClickHandler/OutsideClickHandler";
import ServiceFloatBg from "../../Assets/ServiceFloatBg.svg";
import image49 from "../../Assets/image49.png";
import image50 from "../../Assets/image50.png";
import ProfileAvatar from "../../Assets/ProfileAvatar.svg";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
const ServiceFloat = ({ navigate }) => {
  const dispatch = useDispatch();
  const closeNotifications = () => dispatch(closeService());
  const [bannerList, setBannerList] = useState([]);
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [loggedInUserData, setLoggedInUserData] = useState(null);
  useEffect(() => {
    setLoggedInUserData(LoggedInUser);
  }, [LoggedInUser]);
  const logout = async () => {
    try {
      // await postRequest("Settings/Logout");
      dispatch(loggedOut());
    } catch (error) {
      dispatch(loggedOut());
    } finally {
      navigate("/login");
    }
  };
  // const getProfileData = async () => {
  //   try {
  //     const result = await getRequest("Employees/GetBannerProfile");
  //     setBannerList(result.data);
  //   } catch (error) {
  //     dispatch(
  //       showToast({
  //         text: "some error occured in fetching data",
  //         severity: "error",
  //       })
  //     );
  //   } finally {
  //   }
  // };
  // useEffect(()=>{
  //   getProfileData()
  // },[])
  return (
    <OutsideClickHandler
      onOutsideClick={closeNotifications}
      style={{ height: "100%", width: "300px" }}
    >
      <div
        className="notificationsMainContainer"
        style={{
          width: "100%",
          boxShadow: "-3px 0px 20px rgba(0, 0, 0, 0.05)",
          position: "relative",
        }}
      >
        <div
          className="notifications-content"
          style={{ padding: "0 0 12px 0", overflowX: "hidden" }}
        >
          <div
            style={{
              height: "144px",
              backgroundColor: "#D8F0F2",
              boxShadow: "-3px 0px 20px rgba(0, 0, 0, 0.05)",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
            className="cursor"
            onClick={() => {
              navigate("/services");
              closeNotifications();
            }}
          >
            <img
              src={ServiceFloatBg}
              alt="Service Bg"
              style={{
                position: "absolute",
                height: "140%",
                right: "-24px",
                top: "-29px",
              }}
            />
            {loggedInUserData?.image ? (
              <img
                src={`${
                  process.env.REACT_APP_.BASE_URL
                }/Core/Uploads/ProfileImageUploads/${
                  bannerList?.Image ?? loggedInUserData?.image
                }`}
                alt="Profile"
                style={{
                  position: "absolute",
                  right: "4px",
                  height: "70%",
                  border: "1px solid var(--primary)",
                  borderRadius: "100%",
                  padding: "2px",
                  aspectRatio: 1,
                }}
              />
            ) : (
              <img
                src={ProfileAvatar}
                alt={"Profile"}
                style={{
                  position: "absolute",
                  right: "4px",
                  height: "70%",
                  border: "1px solid var(--primary)",
                  borderRadius: "100%",
                  padding: "2px",
                  aspectRatio: 1,
                }}
              />
            )}
            <div style={{ padding: "12px", width: "60%" }}>
              <h5 style={{ color: "var(--primary)" }}>
                {loggedInUserData?.name}
              </h5>
              <h6 style={{ padding: "0 2px" }}>
                {loggedInUserData?.designation}
              </h6>
              <div
                style={{ margin: "3px" }}
                className="horizontalLinePrimary"
              ></div>
            </div>
          </div>
          <div
            className={`menuOptions`}
            style={{ marginTop: "12px", padding: "16px", gap: "12px" }}
            onClick={() => {
              navigate("/services");
              closeNotifications();
            }}
          >
            <svg
              width="19"
              height="24"
              viewBox="0 0 19 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.47461 19.4377V19.4377C7.95583 19.4377 6.72461 18.2065 6.72461 16.6877V7.0625C6.72461 6.30311 7.34022 5.6875 8.09961 5.6875V5.6875V5.6875C8.859 5.6875 9.47461 6.30311 9.47461 7.0625V11.7603C9.47461 11.9601 9.74399 12.0237 9.83335 11.845V11.845C10.2521 11.0076 11.4471 11.0076 11.8659 11.845L12.0282 12.1696C12.1091 12.3315 12.3401 12.3315 12.421 12.1696V12.1696C12.9066 11.1984 14.2926 11.1985 14.7782 12.1697V12.1697C14.8591 12.3315 15.0901 12.3315 15.171 12.1697V12.1697C15.6566 11.1985 17.0426 11.1985 17.5281 12.1697L17.648 12.4094C17.6984 12.5102 17.7246 12.6213 17.7246 12.734V16.6877C17.7246 18.2065 16.4934 19.4377 14.9746 19.4377V19.4377M9.47461 19.4377V22.1875V22.875M9.47461 19.4377H14.9746M14.9746 19.4377V22.875"
                stroke="#1296B0"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.5961 9.46875C12.8003 8.93498 12.9121 8.35555 12.9121 7.75C12.9121 5.09213 10.7575 2.9375 8.09961 2.9375C5.44174 2.9375 3.28711 5.09213 3.28711 7.75C3.28711 9.30701 4.02652 10.6913 5.17336 11.5709"
                stroke="#F7941D"
                stroke-linecap="round"
              />
              <path
                d="M14.8371 9.125C14.9273 8.68072 14.9746 8.22089 14.9746 7.75C14.9746 3.95304 11.8966 0.875 8.09961 0.875C4.30265 0.875 1.22461 3.95304 1.22461 7.75C1.22461 9.99925 2.30475 11.9962 3.97461 13.2505"
                stroke="#F7941D"
                stroke-linecap="round"
              />
            </svg>

            <label>My Services</label>
          </div>
          {/* <div
            className={`menuOptions`}
            onClick={() => {
              navigate("/services");
              closeNotifications();
            }}
          >
            <img src={ReportsKPIpic} alt="ReportsKPI" />
            <label>My Account</label>
          </div> */}
          <div
            className={`menuOptions`}
            style={{ padding: "16px", gap: "12px" }}
            onClick={() => {
              navigate("/contactdirectory");
              closeNotifications();
            }}
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.6358"
                y="1"
                width="16.3642"
                height="17.6402"
                rx="2"
                stroke="#1296B0"
              />
              <line
                x1="4.70642"
                y1="1"
                x2="4.70642"
                y2="19.0492"
                stroke="#1296B0"
              />
              <line
                x1="0.5"
                y1="4.91003"
                x2="2.61588"
                y2="4.91003"
                stroke="#1296B0"
                stroke-linecap="round"
              />
              <line
                x1="0.5"
                y1="7.85016"
                x2="2.61588"
                y2="7.85016"
                stroke="#1296B0"
                stroke-linecap="round"
              />
              <line
                x1="0.5"
                y1="10.7904"
                x2="2.61588"
                y2="10.7904"
                stroke="#1296B0"
                stroke-linecap="round"
              />
              <line
                x1="0.5"
                y1="13.7299"
                x2="2.61588"
                y2="13.7299"
                stroke="#1296B0"
                stroke-linecap="round"
              />
              <path
                d="M7.31147 7.20282C7.3901 5.94209 8.43118 5.72348 9.1289 5.77812C9.26127 5.78849 9.36847 5.88397 9.40902 6.0104L9.96759 7.75165C9.98599 7.80901 9.96638 7.87175 9.91858 7.90842L9.28113 8.39742C9.23307 8.43428 9.21343 8.49785 9.23352 8.55499C9.9109 10.4817 11.706 11.6086 12.6152 11.9734C12.6703 11.9955 12.7328 11.9791 12.7717 11.9343L13.3528 11.2637C13.3915 11.2191 13.4537 11.2029 13.5093 11.2231L15.3247 11.8814C15.3741 11.8993 15.41 11.9427 15.4157 11.9949C15.4759 12.5488 15.4059 13.5626 14.6841 13.7783C13.7338 14.0623 11.2327 13.7237 9.58335 12.1727C7.93402 10.6216 7.21764 8.70727 7.31147 7.20282Z"
                stroke="#F7941D"
                stroke-linecap="round"
              />
            </svg>

            <label>Contact Directory</label>
          </div>
          <div
            className={`menuOptions`}
            style={{ padding: "16px", gap: "12px" }}
            onClick={() => {
              navigate("/whatsnew");
              closeNotifications();
            }}
          >
            <svg
              width="19"
              height="24"
              viewBox="0 0 19 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7518 3.94819L17.9299 0.781082"
                stroke="#F7941D"
                stroke-width="0.9"
                stroke-linecap="round"
              />
              <path
                d="M18.6085 8.51779L22.6318 8.48833"
                stroke="#F7941D"
                stroke-width="0.9"
                stroke-linecap="round"
              />
              <path
                d="M17.4659 6.23317L20.7092 4.3737"
                stroke="#F7941D"
                stroke-width="0.9"
                stroke-linecap="round"
              />
              <path
                d="M15.9284 14.6055L7.84142 15.5437L4.88303 11.1053L8.50814 4.15419"
                stroke="#1296B0"
                stroke-width="0.9"
              />
              <ellipse
                cx="12.7049"
                cy="8.99443"
                rx="2.84966"
                ry="6.57031"
                transform="rotate(-34.5916 12.7049 8.99443)"
                stroke="#1296B0"
                stroke-width="0.9"
              />
              <path
                d="M4.99668 11.358L2.81571 12.8216C1.7126 13.5619 1.41848 15.0563 2.15877 16.1594V16.1594C2.89906 17.2625 4.39343 17.5566 5.49653 16.8163L7.6775 15.3527"
                stroke="#1296B0"
                stroke-width="0.9"
              />
              <path
                d="M5.90646 16.6819L7.73519 19.556C8.16573 20.2327 9.0633 20.4322 9.73997 20.0017V20.0017C10.4166 19.5711 10.6162 18.6736 10.1856 17.9969L8.35688 15.1228"
                stroke="#1296B0"
                stroke-width="0.9"
              />
              <path
                d="M13.2243 10.534C13.5237 10.2251 13.7014 9.8125 13.7231 9.37614C13.7447 8.93979 13.6087 8.51066 13.3414 8.17179C13.0742 7.83291 12.6945 7.60837 12.2761 7.54161C11.8576 7.47486 11.43 7.57062 11.076 7.81039"
                stroke="#1296B0"
                stroke-width="0.9"
                stroke-linecap="round"
              />
            </svg>

            <label>What’s New</label>
          </div>
          <div
            className={`menuOptions`}
            style={{ padding: "16px", gap: "12px" }}
            onClick={() => {
              navigate("/knowledgecenter");
              closeNotifications();
            }}
          >
            <svg
              width="19"
              height="24"
              viewBox="0 0 19 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.6189 10.482L8.75042 11.9917C8.55563 12.3245 8.71796 12.6005 9.09944 12.6005H10.1302C10.5198 12.6005 10.6741 12.8764 10.4793 13.2092L9.6189 14.7189"
                stroke="#F7941D"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6.7784 21.9345C8.63711 21.4069 10.6013 21.4069 12.46 21.9345M6.61607 18.7203V17.7788C4.74925 16.6506 3.21521 14.451 3.21521 12.1134C3.21521 8.09568 6.90827 4.94644 11.0802 5.8555C12.9146 6.26133 14.5216 7.47882 15.3577 9.15896C17.054 12.5679 15.2684 16.1879 12.6467 17.7707V18.7122C12.6467 18.9476 12.736 19.4914 11.8675 19.4914H7.39526C6.50244 19.4995 6.61607 19.1505 6.61607 18.7203Z"
                stroke="#1296B0"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.61853 1.4458L9.61853 3.07399"
                stroke="#F7941D"
                stroke-linecap="round"
              />
              <path
                d="M4.10093 3.13647L5.26421 4.39244"
                stroke="#F7941D"
                stroke-linecap="round"
              />
              <path
                d="M15.1371 3.13647L13.9738 4.39244"
                stroke="#F7941D"
                stroke-linecap="round"
              />
              <path
                d="M18.8109 8.06934L17.1152 8.06934"
                stroke="#F7941D"
                stroke-linecap="round"
              />
              <path
                d="M2.33628 8.06934L0.640623 8.06934"
                stroke="#F7941D"
                stroke-linecap="round"
              />
            </svg>

            <label>Knowledge Center</label>
          </div>

          <div
            className={`menuOptions`}
            style={{ padding: "16px", gap: "12px" }}
            onClick={() => {
              navigate("/changepassword");
              closeNotifications();
            }}
          >
            <svg
              width="19"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.86955"
                y="8.12004"
                width="15.7106"
                height="12.5363"
                rx="1.72458"
                stroke="#1296B0"
              />
              <path
                d="M16.7488 5.33502C16.7488 5.61116 16.525 5.83502 16.2488 5.83502C15.9727 5.83502 15.7488 5.61116 15.7488 5.33502H16.7488ZM7.70089 5.18049V8.24962H6.70089V5.18049H7.70089ZM15.7488 5.33502V5.18049H16.7488V5.33502H15.7488ZM11.7249 1.15651C9.50248 1.15651 7.70089 2.95811 7.70089 5.18049H6.70089C6.70089 2.40583 8.9502 0.156514 11.7249 0.156514V1.15651ZM11.7249 0.156514C14.4995 0.156514 16.7488 2.40583 16.7488 5.18049H15.7488C15.7488 2.95811 13.9472 1.15651 11.7249 1.15651V0.156514Z"
                fill="#1296B0"
              />
              <rect
                x="1.43555"
                y="12.2408"
                width="20.5796"
                height="5.0093"
                rx="0.862291"
                fill="white"
                stroke="#F7941D"
              />
              <path
                d="M5.75891 13.96L5.98873 14.375L5.10136 14.7452L5.99511 15.1091L5.75252 15.5369L4.98007 14.9751L5.08221 15.939H4.60342L4.69918 14.9751L3.92672 15.5432L3.67775 15.1091L4.56511 14.7389L3.67775 14.3814L3.91395 13.9536L4.70556 14.5218L4.60342 13.5515H5.0886L4.98007 14.5218L5.75891 13.96Z"
                fill="#F7941D"
              />
              <path
                d="M10.3518 13.96L10.5816 14.3749L9.69424 14.7452L10.588 15.1091L10.3454 15.5368L9.57295 14.975L9.67509 15.939H9.1963L9.29206 14.975L8.5196 15.5432L8.27063 15.1091L9.15799 14.7388L8.27063 14.3813L8.50683 13.9536L9.29844 14.5217L9.1963 13.5514H9.68148L9.57295 14.5217L10.3518 13.96Z"
                fill="#F7941D"
              />
              <path
                d="M14.9435 13.96L15.1733 14.3749L14.286 14.7452L15.1797 15.1091L14.9371 15.5368L14.1647 14.975L14.2668 15.939H13.788L13.8838 14.975L13.1113 15.5432L12.8624 15.1091L13.7497 14.7388L12.8624 14.3813L13.0986 13.9536L13.8902 14.5217L13.788 13.5514H14.2732L14.1647 14.5217L14.9435 13.96Z"
                fill="#F7941D"
              />
              <path
                d="M19.5364 13.96L19.7662 14.3749L18.8788 14.7452L19.7726 15.1091L19.53 15.5368L18.7575 14.975L18.8597 15.939H18.3809L18.4766 14.975L17.7042 15.5432L17.4552 15.1091L18.3426 14.7388L17.4552 14.3813L17.6914 13.9536L18.483 14.5217L18.3809 13.5514H18.8661L18.7575 14.5217L19.5364 13.96Z"
                fill="#F7941D"
              />
            </svg>

            <label>Change Password</label>
          </div>
          <div
            className="menuOptions"
            style={{ padding: "16px", gap: "12px" }}
            onClick={() => {
              logout();
              closeNotifications();
            }}
          >
            <svg
              width="19"
              height="24"
              viewBox="0 0 19 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.81525 5.75049C7.09077 2.72528 8.735 1.48999 12.3345 1.48999H12.4501C16.4229 1.48999 18.0138 2.99419 18.0138 6.75049V12.2295C18.0138 15.9858 16.4229 17.49 12.4501 17.49H12.3345C8.76167 17.49 7.11744 16.2715 6.82414 13.2967"
                stroke="#1296B0"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.9417 9.48159H7.16019H2.37868M4.25263 6.66646L1.4375 9.48159L4.25263 12.2967"
                stroke="#F7941D"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <label className="cursor sideLabel endMenuLabel">Logout</label>
          </div>
          <div
            className="horizontalLine"
            style={{ height: "1px", margin: "24px 0" }}
          />
          <div style={{ padding: "12px 21px" }}>
            <p>PeopleSol Mobile App</p>
            <div
              style={{
                display: "flex",
                padding: "12px 0",
                alignItems: "flex-start",
                flexDirection:'column',
                gap: "6px",
              }}
            >
              
             <a href="https://play.google.com/store/apps/details?id=com.peoplesol" target="blank"> <img src={image49} alt='play'/></a>
             <a href="https://apps.apple.com/in/app/peoplesol/id6445863125" target="blank"> <img src={image50} alt='play'/></a>
              
            </div>
          </div>
          <p
            style={{
              fontSize: "11px",
              textAlign: "end",
              paddingRight: "12px",
              marginTop: "12px",
            }}
          >
           Version : 1.0.1
          </p>
        </div>
      </div>
    </OutsideClickHandler>
  );
};
export { ServiceFloat };
