import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import "./Final.css";
import { useEffect, useState } from "react";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { Loader } from "../../../../../Components/FormComponent/Loader";

export const FinalForm = ({ id, data, type, empId, handleClose }) => {
  const dispatch = useDispatch();
  const [Total, setTotal] = useState(0);
  const [normalEarning, setNormalEarning] = useState(0);
  const [earning, setEarningEarning] = useState(0);
  const [deduction, setDeduction] = useState(0);
  const [netTotal, setNetTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  var converter = require("number-to-words");
  const CreatePageData = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest(`Resignations/FinalSettlements`, values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      data();
      handleClose();
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Final Form`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const currentMonth = () => {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  };

  const initialvalue = {
    DayofWork: "",
    Weekoff: "",
    Leave: "",
    Ph: "",
    Absent: "",
    NormalBasic: "",
    NormalHra: "",

    NormalMed: "",

    NormalUni: "",

    NormalLta: "",

    NormalCar: "",

    NormalConv: "",

    NormalBooksAndPeriodicals: "",

    NormalGiftVouchers: "",
    NormalMealAll: "",

    NormalGadgetReimb: "",
    EarningBasic: "",
    EarningHra: "",

    EarningMed: "",

    EarningUni: "",

    EarningLta: "",

    EarningCar: "",

    EarningBooksAndPeriodicals: "",
    EarningGiftVouchers: "",
    EarningGadgetReimb: "",
    EarningMealAll: "",
    EarningConv: "",
    JoiningBonus: "",
    DeductionPf: "",
    ShiftingCharges: "",
    DebitBalance: "",
    ArrearDeduction: "",
    NoticepayRecovery: "",
    Escideduction: "",
    AzizTrust: "",
    TotalEarning: 0,
    CreditBalance: "",
    CreditBalanceRemarks: "",
    Bonus: "",
    BonusRemarks: "",
    AdditionalPayment: "",
    AddirionalPaymentRemarks: "",
    LeaveAmount: "",
    LeaveRemarks: "",
    HoldSalary: "",
    SalaryPayableRemarks: "",
    TotalDeductionAmount: 0,
    NetEarningPayable: "",
    PayableDays: "",
    TotalDays: currentMonth(),
  };

  const onSubmit = async (values) => {
    const create = {
      ...values,
      resignationId: id,
      RequestNumber: `RS/${id}`,
      DayofWork: values?.DayofWork !== "" ? values?.DayofWork : null,
      Weekoff: values?.Weekoff !== "" ? values?.Weekoff : null,
      Leave: values?.Leave !== "" ? values?.Leave : null,
      Ph: values?.Ph !== "" ? values?.Ph : null,
      Absent: values?.Absent !== "" ? values?.Absent : null,
      NormalBasic: values?.NormalBasic !== "" ? values?.NormalBasic : null,
      NormalHra: values?.NormalHra !== "" ? values?.NormalHra : null,

      NormalMed: values?.NormalMed !== "" ? values?.NormalMed : null,

      NormalUni: values?.NormalUni !== "" ? values?.NormalUni : null,

      NormalLta: values?.NormalLta !== "" ? values?.NormalLta : null,

      NormalCar: values?.NormalCar !== "" ? values?.NormalCar : null,

      NormalConv: values?.NormalConv !== "" ? values?.NormalConv : null,

      NormalBooksAndPeriodicals:
        values?.NormalBooksAndPeriodicals !== ""
          ? values?.NormalBooksAndPeriodicals
          : null,

      NormalGiftVouchers:
        values?.NormalGiftVouchers !== "" ? values?.NormalGiftVouchers : null,
      NormalMealAll:
        values?.NormalMealAll !== "" ? values?.NormalMealAll : null,

      NormalGadgetReimb:
        values?.NormalGadgetReimb !== "" ? values?.NormalGadgetReimb : null,
      EarningBasic: values?.EarningBasic !== "" ? values?.EarningBasic : null,
      EarningHra: values?.EarningHra !== "" ? values?.EarningHra : null,

      EarningMed: values?.EarningMed !== "" ? values?.EarningMed : null,

      EarningUni: values?.EarningUni !== "" ? values?.EarningUni : null,

      EarningLta: values?.EarningLta !== "" ? values?.EarningLta : null,

      EarningCar: values?.EarningCar !== "" ? values?.EarningCar : null,

      EarningBooksAndPeriodicals:
        values?.EarningBooksAndPeriodicals !== ""
          ? values?.EarningBooksAndPeriodicals
          : null,
      EarningGiftVouchers:
        values?.EarningGiftVouchers !== "" ? values?.EarningGiftVouchers : null,
      EarningGadgetReimb:
        values?.EarningGadgetReimb !== "" ? values?.EarningGadgetReimb : null,
      EarningMealAll:
        values?.EarningMealAll !== "" ? values?.EarningMealAll : null,
      EarningConv: values?.EarningConv !== "" ? values?.EarningConv : null,
      JoiningBonus: values?.JoiningBonus !== "" ? values?.JoiningBonus : null,
      DeductionPf: values?.DeductionPf !== "" ? values?.DeductionPf : null,
      ShiftingCharges:
        values?.ShiftingCharges !== "" ? values?.ShiftingCharges : null,
      DebitBalance: values?.DebitBalance !== "" ? values?.DebitBalance : null,
      ArrearDeduction:
        values?.ArrearDeduction !== "" ? values?.ArrearDeduction : null,
      NoticepayRecovery:
        values?.NoticepayRecovery !== "" ? values?.NoticepayRecovery : null,
      Escideduction:
        values?.Escideduction !== "" ? values?.Escideduction : null,
      AzizTrust: values?.AzizTrust !== "" ? values?.AzizTrust : null,
      TotalEarning: values?.TotalEarning !== "" ? values?.TotalEarning : 0,
      CreditBalance:
        values?.CreditBalance !== "" ? values?.CreditBalance : null,
      CreditBalanceRemarks:
        values?.CreditBalanceRemarks !== "" ? values?.CreditBalanceRemarks : "",
      Bonus: values?.Bonus !== "" ? values?.Bonus : null,
      BonusRemarks: values?.BonusRemarks !== "" ? values?.BonusRemarks : "",
      AdditionalPayment:
        values?.AdditionalPayment !== "" ? values?.AdditionalPayment : null,
      AddirionalPaymentRemarks:
        values?.AddirionalPaymentRemarks !== ""
          ? values?.AddirionalPaymentRemarks
          : "",
      LeaveAmount: values?.LeaveAmount !== "" ? values?.LeaveAmount : null,
      LeaveRemarks: values?.LeaveRemarks !== "" ? values?.LeaveRemarks : "",
      HoldSalary: values?.HoldSalary !== "" ? values?.HoldSalary : null,
      SalaryPayableRemarks:
        values?.SalaryPayableRemarks !== "" ? values?.SalaryPayableRemarks : "",
      TotalDeductionAmount:
        values?.TotalDeductionAmount !== "" ? values?.TotalDeductionAmount : 0,
      NetEarningPayable:
        values?.NetEarningPayable !== "" ? values?.NetEarningPayable : null,
      normalTotal: normalEarning,
      PayableDays: values?.PayableDays !== "" ? values?.PayableDays : null,
      EmployeeId: empId,
    };
    CreatePageData(create);
  };
  function toTitleCase(str) {
    return str
      .toLowerCase()
      .replace(/(?:^|\s)\w/g, (match) => match.toUpperCase());
  }
  return (
    <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialvalue}>
      {(formik) => {
        const { values } = formik;

        const calculateTotalEarnings = async () => {
          let total = 0;
          let _EarningBasic = formik?.values?.EarningBasic;
          let _EarningHra = formik?.values?.EarningHra;
          let _EarningMed = formik?.values?.EarningMed;
          let _EarningUni = formik?.values?.EarningUni;
          let _EarningLta = formik?.values?.EarningLta;
          let _EarningCar = formik?.values?.EarningCar;
          let _EarningSpecial = formik?.values?.EarningConv;
          let _EarningBooks = formik?.values?.EarningBooksAndPeriodicals;
          let _EarningGift = formik?.values?.EarningGiftVouchers;
          let _EarningMeal = formik?.values?.EarningMealAll;
          let _EarningGadget = formik?.values?.EarningGadgetReimb;

          if (_EarningBasic !== "") {
            total = total + parseFloat(_EarningBasic);
          }

          if (_EarningHra !== "") {
            total = total + parseFloat(_EarningHra);
          }
          if (_EarningMed !== "") {
            total = total + parseFloat(_EarningMed);
          }

          if (_EarningUni !== "") {
            total = total + parseFloat(_EarningUni);
          }

          if (_EarningLta !== "") {
            total = total + parseFloat(_EarningLta);
          }

          if (_EarningCar !== "") {
            total = total + parseFloat(_EarningCar);
          }

          if (_EarningSpecial !== "") {
            total = total + parseFloat(_EarningSpecial);
          }

          if (_EarningBooks !== "") {
            total = total + parseFloat(_EarningBooks);
          }

          if (_EarningGift !== "") {
            total = total + parseFloat(_EarningGift);
          }

          if (_EarningMeal !== "") {
            total = total + parseFloat(_EarningMeal);
          }

          if (_EarningGadget !== "") {
            total = total + parseFloat(_EarningGadget);
          }
          values.TotalEarning = total.toFixed(2);
          return setEarningEarning(total.toFixed(2));
        };

        const calculateEarnings = async () => {
          let payableDays = Total;
          let totalDays = currentMonth();
          let _NormalBasic =
            formik?.values?.NormalBasic == "" ? 0 : formik?.values?.NormalBasic;
          let _NormalHra =
            formik?.values?.NormalHra == "" ? 0 : formik?.values?.NormalHra;
          let _NormalMed =
            formik?.values?.NormalMed == "" ? 0 : formik?.values?.NormalMed;
          let _NormalUni =
            formik?.values?.NormalUni == "" ? 0 : formik?.values?.NormalUni;
          let _NormalLta =
            formik?.values?.NormalLta == "" ? 0 : formik?.values?.NormalLta;
          let _NormalCar =
            formik?.values?.NormalCar == "" ? 0 : formik?.values?.NormalCar;
          let _NormalSpecial =
            formik?.values?.NormalConv == "" ? 0 : formik?.values?.NormalConv;
          let _NormalBooks =
            formik?.values?.NormalBooksAndPeriodicals == ""
              ? 0
              : formik?.values?.NormalBooksAndPeriodicals;
          let _NormalGift =
            formik?.values?.NormalGiftVouchers == ""
              ? 0
              : formik?.values?.NormalGiftVouchers;
          let _NormalMeal =
            formik?.values?.NormalMealAll == ""
              ? 0
              : formik?.values?.NormalMealAll;
          let _NormalGadget =
            formik?.values?.NormalGadgetReimb == ""
              ? 0
              : formik?.values?.NormalGadgetReimb;

          if (_NormalBasic !== "") {
            let result = (_NormalBasic / totalDays) * payableDays;
            values.EarningBasic = result.toFixed(2);
          }

          if (_NormalHra !== "") {
            let result = (_NormalHra / totalDays) * payableDays;
            values.EarningHra = result.toFixed(2);
          }

          if (_NormalMed !== "") {
            let result = (_NormalMed / totalDays) * payableDays;
            values.EarningMed = result.toFixed(2);
          }

          if (_NormalUni !== "") {
            let result = (_NormalUni / totalDays) * payableDays;
            values.EarningUni = result.toFixed(2);
          }

          if (_NormalLta !== "") {
            let result = (_NormalLta / totalDays) * payableDays;
            values.EarningLta = result.toFixed(2);
          }

          if (_NormalCar !== "") {
            let result = (_NormalCar / totalDays) * payableDays;
            values.EarningCar = result.toFixed(2);
          }

          if (_NormalSpecial !== "") {
            let result = (_NormalSpecial / totalDays) * payableDays;
            values.EarningConv = result.toFixed(2);
          }

          if (_NormalBooks !== "") {
            let result = (_NormalBooks / totalDays) * payableDays;
            values.EarningBooksAndPeriodicals = result.toFixed(2);
          }

          if (_NormalGift !== "") {
            let result = (_NormalGift / totalDays) * payableDays;
            values.EarningGiftVouchers = result.toFixed(2);
          }

          if (_NormalMeal !== "") {
            let result = (_NormalMeal / totalDays) * payableDays;
            values.EarningMealAll = result.toFixed(2);
          }

          if (_NormalGadget !== "") {
            let result = (_NormalGadget / totalDays) * payableDays;
            values.EarningGadgetReimb = result.toFixed(2);
          }
        };
        const CalculateTotalDeduction = async () => {
          let total = 0;
          let _DebitBalance = formik?.values?.DebitBalance;
          let _Arrear = formik?.values?.ArrearDeduction;
          let _NoticepayRecovery = formik?.values?.NoticepayRecovery;
          let _Escideduction = formik?.values?.Escideduction;
          let _AzizTrust = formik?.values?.AzizTrust;
          let _DeductionPf = formik?.values?.DeductionPf;
          let _ShiftingCharges = formik?.values?.ShiftingCharges;
          let _JoiningBonus = formik?.values?.JoiningBonus;
          // //console.log("ts", values);
          if (_DebitBalance !== "") {
            total = total + parseFloat(_DebitBalance);
            // //console.log("t", _DebitBalance);
          }

          if (_Arrear !== "") {
            total = total + parseFloat(_Arrear);
          }

          if (_NoticepayRecovery !== "") {
            total = total + parseFloat(_NoticepayRecovery);
          }

          if (_Escideduction !== "") {
            total = total + parseFloat(_Escideduction);
          }

          if (_AzizTrust !== "") {
            total = total + parseFloat(_AzizTrust);
          }

          if (_DeductionPf !== "") {
            total = total + parseFloat(_DeductionPf);
          }

          if (_ShiftingCharges !== "") {
            total = total + parseFloat(_ShiftingCharges);
          }

          if (_JoiningBonus !== "") {
            total = total + parseFloat(_JoiningBonus);
          }
          values.TotalDeductionAmount = total.toFixed(2);

          return setDeduction(total.toFixed(2));
        };
        const calculate = async () => {
          let total =
            parseFloat(values?.TotalEarning) -
            parseFloat(values?.TotalDeductionAmount);
          // //console.log("earning",values?.TotalEarning)
          // //console.log("deduction",values?.TotalDeductionAmount)
          let leaveamount = values?.LeaveAmount;
          let additionalpayment = values?.AdditionalPayment;
          let creditamount = values?.CreditBalance;
          let holdsalary = values?.HoldSalary;
          let bonus = values?.Bonus;
          if (leaveamount !== "") {
            total = total + parseFloat(leaveamount);
            // //console.log("tota", total);
          }

          if (additionalpayment !== "") {
            total = total + parseFloat(additionalpayment);
            // //console.log("tota", total);
          }

          if (creditamount !== "") {
            total = total + parseFloat(creditamount);
            // //console.log("tota", total);
          }

          if (holdsalary !== "") {
            total = total + parseFloat(holdsalary);
            // //console.log("tota", total);
          }

          if (bonus !== "") {
            total = total + parseFloat(bonus);
          }
          values.NetEarningPayable = total.toFixed(2);
          return setNetTotal(total.toFixed(2));
        };
        const calculateTotalNormalEarnings = async () => {
          let total = 0;
          let _NormalBasic = formik?.values?.NormalBasic;
          let _NormalHra = formik?.values?.NormalHra;
          let _NormalMed = formik?.values?.NormalMed;
          let _NormalUni = formik?.values?.NormalUni;
          let _NormalLta = formik?.values?.NormalLta;
          let _NormalCar = formik?.values?.NormalCar;
          let _NormalSpecial = formik?.values?.NormalConv;
          let _NormalBooks = formik?.values?.NormalBooksAndPeriodicals;
          let _NormalGift = formik?.values?.NormalGiftVouchers;
          let _NormalMeal = formik?.values?.NormalMealAll;
          let _NormalGadget = formik?.values?.NormalGadgetReimb;

          if (_NormalBasic !== "") {
            total = total + parseFloat(_NormalBasic);
          }

          if (_NormalHra !== "") {
            total = total + parseFloat(_NormalHra);
          }
          if (_NormalMed !== "") {
            total = total + parseFloat(_NormalMed);
          }

          if (_NormalUni !== "") {
            total = total + parseFloat(_NormalUni);
          }

          if (_NormalLta !== "") {
            total = total + parseFloat(_NormalLta);
          }

          if (_NormalCar !== "") {
            total = total + parseFloat(_NormalCar);
          }

          if (_NormalSpecial !== "") {
            total = total + parseFloat(_NormalSpecial);
          }

          if (_NormalBooks !== "") {
            total = total + parseFloat(_NormalBooks);
          }

          if (_NormalGift !== "") {
            total = total + parseFloat(_NormalGift);
          }

          if (_NormalMeal !== "") {
            total = total + parseFloat(_NormalMeal);
          }

          if (_NormalGadget !== "") {
            total = total + parseFloat(_NormalGadget);
          }
          return setNormalEarning(total.toFixed(2));
        };
        const calculateTotalPayableDays = async () => {
          let total = 0;
          let _Absent = formik?.values?.Absent;
          let _Ph = formik?.values?.Ph;
          let _Leave = formik?.values?.Leave;
          let _Weekoff = formik?.values?.Weekoff;
          let _DayofWork = formik?.values?.DayofWork;

          if (_Ph !== "") {
            total = total + parseInt(_Ph);
          }

          if (_Leave !== "") {
            total = total + parseInt(_Leave);
          }

          if (_Weekoff !== "") {
            total = total + parseInt(_Weekoff);
          }

          if (_DayofWork !== "") {
            total = total + parseInt(_DayofWork);
          }

          if (_Absent !== "") {
            total = total - parseInt(_Absent);
          }

          if (total !== 0) {
            let _total = parseFloat(total);
            _total.toFixed(1);
          }
          values.PayableDays = total.toFixed(1);
          return setTotal(total.toFixed(1));
        };
        if (loading) return <Loader />;
        else
          return (
            <Form>
              <div
                className="repeatedSectionContainer"
                style={{ padding: "12px" }}
              >
                <table
                  className="tabless-bordered tabless"
                  style={{ overflow: "auto" }}
                >
                  <thead style={{ backgroundColor: "var(--primary-hover)" }}>
                    <tr>
                      <th colspan="2" style={{ textAlign: "center" }}>
                        ATTENDANCE
                      </th>
                      <th colspan="2" style={{ textAlign: "center" }}>
                        PAY&nbsp;STRUCTURE
                      </th>
                      <th colspan="2" style={{ textAlign: "center" }}>
                        EARNINGS
                      </th>
                      <th colspan="2" style={{ textAlign: "center" }}>
                        DEDUCTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>DAYS OF WORK</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalPayableDays();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="DayofWork"
                          placeholder="D/W"
                          title="Numbers only"
                        />
                      </td>
                      <td>BASIC</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalBasic"
                          placeholder="BASIC"
                          title="Numbers only"
                        />
                      </td>
                      <td>BASIC</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningBasic"
                          placeholder="BASIC"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td>PF</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await CalculateTotalDeduction();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="DeductionPf"
                          placeholder="PF"
                          title="Numbers only"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>WEEKLY OFF</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculate();
                            await calculateEarnings();
                            calculateTotalPayableDays();
                            await calculateTotalEarnings();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="Weekoff"
                          placeholder="W/O"
                          title="Numbers only"
                        />
                      </td>
                      <td>HRA</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalHra"
                          placeholder="HRA"
                          title="Numbers only"
                        />
                      </td>
                      <td>HRA</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningHra"
                          placeholder="HRA"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td>ESIC DEDUCTION</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="Escideduction"
                          onKeyUp={async () => {
                            await CalculateTotalDeduction();
                            await calculate();
                          }}
                          placeholder="ESIC DED."
                          title="Numbers only"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>LEAVE</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculate();
                            await calculateEarnings();
                            calculateTotalPayableDays();
                            await calculateTotalEarnings();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="Leave"
                          placeholder="LEAVE"
                          title="Numbers only"
                        />
                      </td>
                      <td>MEDICAL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalMed"
                          placeholder="MED."
                          title="Numbers only"
                        />
                      </td>
                      <td>MEDICAL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningMed"
                          placeholder="MED."
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td> TRUST</td>
                    </tr>
                    <tr>
                      <td>PUBLIC HOLIDAYS</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculate();
                            await calculateEarnings();
                            calculateTotalPayableDays();
                            await calculateTotalEarnings();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="Ph"
                          placeholder="P.H."
                          title="Numbers only"
                        />
                      </td>
                      <td>SPECIAL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalConv"
                          placeholder="SPECIAL ALL."
                          title="Numbers only"
                        />
                      </td>
                      <td>SPECIAL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningConv"
                          placeholder="SPECIAL ALL."
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td>NOTICE PAY RECOVERY</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NoticepayRecovery"
                          onKeyUp={async () => {
                            await CalculateTotalDeduction();
                            await calculate();
                          }}
                          placeholder="NPR."
                          title="Numbers only"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>ABSENT</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculate();
                            await calculateEarnings();
                            calculateTotalPayableDays();
                            await calculateTotalEarnings();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="Absent"
                          placeholder="ABSENT"
                          title="Numbers only"
                        />
                      </td>
                      <td>UNIFORM ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalUni"
                          placeholder="UNI."
                          title="Numbers only"
                        />
                      </td>
                      <td>UNIFORM ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningUni"
                          placeholder="UNI."
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td>DEBIT BALANCE</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await CalculateTotalDeduction();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="DebitBalance"
                          placeholder="DEBIT BALANCE"
                          title="Numbers only"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>LEAVE & TRAVEL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalLta"
                          placeholder="LTA"
                          title="Numbers only"
                        />
                      </td>
                      <td>LEAVE & TRAVEL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningLta"
                          placeholder="LTA"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td>ARREAR DEDUCTION</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="ArrearDeduction"
                          onKeyUp={async () => {
                            await CalculateTotalDeduction();
                            await calculate();
                          }}
                          placeholder="ARREAR DED."
                          title="Numbers only"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>TRANSPORT ALLOWNACE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalCar"
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          placeholder="CAR"
                          title="Numbers only"
                        />
                      </td>
                      <td>TRANSPORT ALLOWNACE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningCar"
                          placeholder="CAR"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td>SHIFTING CHARGES</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="ShiftingCharges"
                          onKeyUp={async () => {
                            await CalculateTotalDeduction();
                            await calculate();
                          }}
                          placeholder="S.C"
                          title="Numbers only"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>BOOKS & PERIODICALS</td>
                      <td>
                        <InputCustomFormik
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalBooksAndPeriodicals"
                          placeholder="B & P"
                          title="Numbers only"
                        />
                      </td>
                      <td>BOOKS & PERIODICALS</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningBooksAndPeriodicals"
                          placeholder="B & P"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td>JOINING BONUS</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="JoiningBonus"
                          onKeyUp={async () => {
                            await CalculateTotalDeduction();
                            await calculate();
                          }}
                          placeholder="J.B"
                          title="Numbers only"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>GIFT VOUCHERS</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalGiftVouchers"
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          placeholder="G.V"
                          title="Numbers only"
                        />
                      </td>
                      <td>GIFT VOUCHERS</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningGiftVouchers"
                          placeholder="G.V"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>MEAL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalMealAll"
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          placeholder="MEAL ALL"
                          title="Numbers only"
                        />
                      </td>
                      <td>MEAL ALLOWANCE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningMealAll"
                          placeholder="MEAL ALL"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>GADGET REIMBURSMENT</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="NormalGadgetReimb"
                          onKeyUp={async () => {
                            await calculateEarnings();
                            await calculateTotalNormalEarnings();
                            await calculateTotalEarnings();
                            await calculate();
                          }}
                          placeholder="GADGET"
                          title="Numbers only"
                        />
                      </td>
                      <td> GADGET REIMBURSMENT</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="EarningGadgetReimb"
                          placeholder="GADGET"
                          title="Numbers only"
                          readonly
                        />
                      </td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <b>TOTAL SALARY </b>
                      </td>
                      <td>{normalEarning}</td>
                      <td>
                        <b>TOTAL EARNING</b>
                      </td>
                      <td>{earning}</td>
                      <td>
                        <b>TOTAL DEDUCTION</b>
                      </td>
                      <td>{deduction}</td>
                    </tr>

                    <tr>
                      <td style={{ width: "11%" }}>
                        <b>PAYABLE DAYS</b>
                      </td>
                      <td>{Total}</td>
                      <td>LEAVE BALANCE</td>
                      <td>
                        <InputCustomFormik
                          style={{ height: "30px" }}
                          type="number"
                          step="0.01"
                          name="LeaveBalance"
                          placeholder="LEAVE BALANCE"
                          title="Numbers only"
                        />
                      </td>
                      <td>
                        <b>AMOUNT OF LEAVE</b>
                      </td>
                      <td>0</td>
                      <td></td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>
                        <b>TOTAL DAYS</b>
                      </td>
                      <td>{currentMonth()}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <table
                  className="tabless"
                  style={{ width: "60%", border: "none !important" }}
                >
                  <tr>
                    <th className="no_border">TOTAL EARNINGS</th>
                    <td>
                      <InputCustomFormik
                        className=" totalearnings"
                        type="text"
                        placeholder="TOTAL EARNINGS"
                        name="TotalEarning"
                        disabled
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <th>ADDITIONAL PAYMENTS</th>
                    <td>
                      <InputCustomFormik
                        onKeyUp={calculate}
                        className=" additional_payment"
                        type="text"
                        placeholder="ENTER ADDITIONAL PAYMENTS"
                        name="AdditionalPayment"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        title="Numbers only"
                      />
                    </td>
                    <td>
                      <TextAreaFormik
                        className=""
                        type="text"
                        placeholder="ENTER COMMENTS"
                        name="AddirionalPaymentRemarks"
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <th>CREDIT AMOUNT</th>
                    <td>
                      <InputCustomFormik
                        onKeyUp={calculate}
                        className=" credit_amount"
                        type="text"
                        placeholder="ENTER CREDIT AMOUNT"
                        name="CreditBalance"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        title="Numbers only"
                      />
                    </td>
                    <td>
                      <TextAreaFormik
                        className=""
                        type="text"
                        placeholder="ENTER COMMENTS"
                        name="CreditBalanceRemarks"
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <th>BONUS</th>
                    <td>
                      <InputCustomFormik
                        onKeyUp={calculate}
                        className=" bonus"
                        type="text"
                        placeholder="ENTER BONUS"
                        name="Bonus"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        title="Numbers only"
                      />
                    </td>
                    <td>
                      <TextAreaFormik
                        className=""
                        type="text"
                        placeholder="ENTER COMMENTS"
                        name="BonusRemarks"
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <th>LEAVE</th>
                    <td>
                      {" "}
                      <InputCustomFormik
                        disabled
                        className="leaveamount"
                        type="text"
                        placeholder="LEAVE"
                        name="LeaveAmount"
                        readonly
                      />
                    </td>
                    <td>
                      <TextAreaFormik
                        className=""
                        type="text"
                        placeholder="ENTER COMMENTS"
                        name="LeaveRemarks"
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <th>SALARY PAYABLE</th>
                    <td>
                      <InputCustomFormik
                        onKeyUp={calculate}
                        className=" holdsalary"
                        type="text"
                        placeholder="ENTER SALARY PAYABLE"
                        name="HoldSalary"
                        onkeypress="return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)"
                        title="Numbers only"
                      />
                    </td>
                    <td>
                      <TextAreaFormik
                        className=""
                        type="text"
                        placeholder="ENTER COMMENTS"
                        name="SalaryPayableRemarks"
                      />
                    </td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <th>TOTAL DEDUCTION</th>
                    <td>
                      {" "}
                      <InputCustomFormik
                        disabled
                        className=" totaldeduction"
                        type="text"
                        placeholder="TOTAL DEDUCTION"
                        name="TotalDeductionAmount"
                      />
                    </td>
                    <td></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <th>NET EARNING PAYABLE</th>
                    <td>
                      <InputCustomFormik
                        className=" tot"
                        type="text"
                        placeholder="NET EARNING PAYABLE"
                        name="NetEarningPayable"
                        readonly
                      />
                    </td>
                    <td>
                      <TextAreaFormik
                        disabled
                        value={toTitleCase(
                          converter?.toWords(isNaN(netTotal) ? 0 : netTotal)
                        )}
                        className=""
                        type="text"
                        placeholder="ENTER COMMENTS"
                        name="SalaryPayableRemarks"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div className="createFootCust">
                <button
                  type="button"
                  className="button secondaryButton"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  className="button primaryButton"
                  type="submit"
                  style={{ background: "rgb(255, 128, 0)", color: "white" }}
                >
                  {type}
                </button>
              </div>
            </Form>
          );
      }}
    </Formik>
  );
};
