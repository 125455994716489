import React from 'react'
import { TTTimesheet } from '../../HRMS/Components/TTTimesheet';
import { useEffect } from 'react';

const ServiceTimesheet = () => {
  useEffect(() => {
    document.title = `PeopleSol -  Timesheet Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      <TTTimesheet/>
        
    </div>
  )
}

export {ServiceTimesheet};
