import React from "react";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { useEffect } from "react";
export const CreateStageComp = ({ data }) => {

    
    useEffect(()=>{
        document.title = `PeopleSol - Create Stage`;
        return () => {
          document.title = 'PeopleSol';
        };
      })

    return (
        <div>
            <div>
                <LabelCustom labelName={"Title"} mandatory={true} />
                <InputCustomFormik
                    name="title"
                    type={"text"}
                    placeholder={"Enter Title"}
                />
            </div>
            <div>
                <LabelCustom labelName={"TAT ( in Hrs)"} mandatory={true} />
                <InputCustomFormik
                    name="tatInHrs"
                    step=".001"
                    placeholder={"Enter TAT ( in Hrs)"}
                />
            </div>
        </div>
    );
};