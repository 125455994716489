/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import "../../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import { Notifications } from "../../DetailsComp/Notifications";
import { Details } from "../../DetailsComp/Details";
import Nodata from "../../../../../Assets/Nodata.svg";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { UpdateTaskStatus } from "../../../../../Components/FormComponent/PopupComponent/UpdateTaskStatus";
import { confirmAlert } from "react-confirm-alert";
import { TimeLineData } from "../../TransferComponents/DetailsComp/Timeline/TimeLineData";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
import UploadDetail from "../../../../../Components/UploadDetail/UploadDetail";
export const DetailSubTask = ({ details, loading,handleClose, getTasksData }) => {
  useEffect(() => {
    document.title = `PeopleSol - Details Sub Task`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const dispatch = useDispatch();
  return (
    <div style={{ minHeight: "500px" }}>
      {details != null ? (
        loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            <div
              className="detailInner"
              style={{
                padding: "12px",
                margin: "12px",
                boxShadow: "none",
              }}
            >
              <Details
                detailTitle="Request Number"
                Details={details?._projecttasks?.requestNumber}
              />
              <Details detailTitle="Task Name" Details={details?._projecttasks?.title} />
              <Details
                detailTitle="Task Description"
                Details={details?._projecttasks?.description}
              />
              <Details
                detailTitle={"Status"}
                Details={
                  <div
                    style={{
                      backgroundColor: getStatusColor(details?._projecttasks?.status)?.split(
                        ","
                      )?.[0],
                      color: getStatusColor(details?._projecttasks?.status)?.split(",")?.[1],
                      cursor: "default",
                      padding: "6px",
                      borderRadius: "6px",
                    }}
                  >
                    {details?._projecttasks?.status}
                  </div>
                }
              />
              {details?._projecttasks?.percentage && (
                <Details
                  detailTitle="Progress Percentage"
                  Details={details?._projecttasks?.percentage}
                />
              )}
              {details?._projecttasks?.remarks && (
                <Details
                  detailTitle="Progress Remarks"
                  Details={details?._projecttasks?.remarks}
                />
              )}
              <Details
                detailTitle="Start Date"
                Details={
                  details?._projecttasks?.createdOn &&
                  format(new Date(details?._projecttasks?.startsOn), "dd/MM/yyyy hh:mm a")
                }
              />
              <Details
                detailTitle="End Date"
                Details={
                  details?._projecttasks?.createdOn &&
                  format(new Date(details?._projecttasks?.endsOn), "dd/MM/yyyy hh:mm a")
                }
              />
              <Details
                detailTitle="Estimated Hours"
                Details={details?._projecttasks?.estimatedHours}
              />
              <Details detailTitle="Priority" Details={details?._projecttasks?.priority} />
              <Details
                          detailTitle="Attachment"
                          Details={
                            <UploadDetail
                              filepath={`ProjectsTasks/${details?._projecttasks?.attachments}`}
                            />
                          }
                        />
            </div>
            <div
              className="primaryButton updateStatusHolder cursor"
              style={{ borderRadius: "4px", marginLeft: "20px" }}
              onClick={() =>
                confirmAlert({
                  customUI: ({ onClose }) => {
                    return (
                      <div className="homeScreenPopup">
                        <UpdateTaskStatus
                        status={details?._projecttasks?.status}
                          title={details?._projecttasks?.title}
                          requestNumber={details?._projecttasks?.requestNumber}
                          id={details.id}
                          onClose={onClose}
                          handleClose={handleClose}
                          dispatch={dispatch}
                          getTasksData={getTasksData}
                        />
                      </div>
                    );
                  },
                })
              }
            >
              <label>Update Status</label>
            </div>
          </>
        )
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"No Data Found"}</h4>
        </div>
      )}
    </div>
  );
};
