import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateTypesComp } from "./FormComp/CreateTypesComp";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { getRequest, postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { useEffect } from "react";


const CreateTypes = (props) => {

  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();
  const [detail, setDetail] = React.useState([]);
  const [loading, setLoading] = useState(true);

    const createPageData = async (values) => {
      try {
          
          dispatch(isLoading(true));
          setLoading(true);
          const result = await postRequest('FacilityTypes/Create', values);
          dispatch(showToast({ text: "Successfully Created", severity: "success" }));
          
      } catch (error) {

          dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
      } finally {
          dispatch(isLoading(false));
          setLoading(false);
      }
  }
  const getDetails = async () => {
    try {
      const result = await getRequest(`FacilityTypes/Details/${data}`);
      setDetail(result.data);
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  }, [data]);
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "FacilityTypes/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Types`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const initialvalue = {

    facilityCategoryId:data?detail.facilityCategoryId:"",
    name: data?detail.name:"",
    
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(149," Name must be less than 150 characters").required("Please Enter  Facility Category"),
    facilityCategoryId: Yup.string().required("Please Select  Facility Type"),
    
  });

  const onSubmit = (values) => {
 
 const edit = {
  ...values,
  Id:  data
};

const create={
 ...values,
 id:0,

 isDataActive: true,
}
data ? Edit(edit) : createPageData(create);
returnPage()
 }
 

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
   <FormCustomFormik FormHeading={data?"Edit Facility Types":'Create Facility Types'} ButtonLabel={data?'Update Facility Types':' Create Facility Types'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
   
       initialvalue
  } returnPage={returnPage} propsDiv={<CreateTypesComp data={data} />} />
  );
};

export { CreateTypes };