import React, {useState, useEffect} from "react";
import { getRequest } from '../../../../Services/axios';
import { showToast, isLoading } from '../../../../Features';
import { useDispatch } from 'react-redux';
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";

const opt=[
  {label:"select",value:null}
]


export const CreateCategoryComp = ({data}) => {
    const [categoryList, setCategoryList] = useState([]);
    const [categoryValuesArray, setCategoryValuesArray] = useState([]);
    const [typeList, setTypeList] = useState([]);
    // const [typeValuesArray, setTypeValuesArray] = useState([]);
    const dispatch = useDispatch();

   
      const getCategoryData = async () => {
        try {
            
            const result = await getRequest('FacilitySpecificationItems/GetSpecificationItem');
            let listData = [];
            result.data.map((item) => { 
                listData.push({ label: item.name, value: item.id })
            })
            setCategoryList(listData);
            
        } catch (error) {
            dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
        } finally {
        }
      }
      const getFacilityTypeData = async () => {
        try {
            
            const result = await getRequest('FacilityTypes/GetFacilityType');
            let listData = [];
            result.data.map((item) => { 
                listData.push({ label: item.name, value: item.id })
            })
            setTypeList(listData);
            
        } catch (error) {
            dispatch(showToast({ text: "some error occured in fetching data", severity: "error" }));
        } finally {
        }
      }
      useEffect(() => {
        getCategoryData();
        getFacilityTypeData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      useEffect(() => {
        
        if (categoryList.length >= 0) {
          data?.specificationsItemId?.map((item) => {
            
            let tempVal = categoryList?.filter((options) => options?.value == item)
            if (tempVal.length > 0) {
              setCategoryValuesArray(prev => {
                var contains = prev.some(elem => {
                  return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
                });
                if (!contains) {
                  return [...prev, tempVal[0]]
                }
                else {
                  return prev
                }
              })
            }
          })
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[categoryList, typeList])
      useEffect(() => {
        document.title = `PeopleSol - Create Category `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Facility Category"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={50}
          name="name"
          type={"text"}
          placeholder={"Enter Facility Category"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Facility Code"} mandatory={true} />
        <InputCustomFormik
        
        maxLength={5}
          name="code"
          type={"text"}
          placeholder={"Enter Facility Code"}
        />
      </div>
      <div>
      <LabelCustom labelName={"Category Specifications"} mandatory={true} />
        <SelectFormikMulti
        multi 
            name="_facilityTypeSpecificationIds"
            options={categoryList}
            values={categoryValuesArray}
             
            placeholder={"Select Category Specifications"}

            />
      </div>
     
      
    </div>
  );
};
