import React, { useState } from "react";

import { useEffect } from "react";
import LanguageSelectionPage from "../MyProfileDetails/LanguageSelectionPage";
import StarredMessages from "../StarredMessages/StarredMessages";

function MySettingDetails(props) {
  const [languageActive, setLanguageActive] = useState(false);
  const [starredMessage, setStarredMessage] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol - My Profile Detail`;
    return () => {
      document.title = "PeopleSol";
    };
  });
console.log('languageActive',languageActive)
  return (
    <div
      className="chatContactListContainer"
      style={{ alignItems: "flex-start" }}
    >
      {languageActive ? (
      <div className="myProfileHeaderContainer">
            <div className="myProfileHeaderWrapper">
              <svg
                onClick={() => {
               setLanguageActive(false)
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="12"
                viewBox="0 0 19 12"
                fill="none"
              >
                <path
                  d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                  fill="#F7941D"
                />
              </svg>
              <span>App Language</span>
            </div>
        <LanguageSelectionPage/>
      </div>
      ) : (
        <>
          <div className="myProfileHeaderContainer">
            <div className="myProfileHeaderWrapper">
              <svg
                onClick={() => {
                  props.setSettingsActive(false);
                  props.setAnchorEl(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="12"
                viewBox="0 0 19 12"
                fill="none"
              >
                <path
                  d="M18.0986 6.60446C18.5128 6.60446 18.8486 6.26868 18.8486 5.85446C18.8486 5.44025 18.5128 5.10446 18.0986 5.10446V6.60446ZM0.495584 5.32413C0.202692 5.61702 0.202692 6.0919 0.495584 6.38479L5.26856 11.1578C5.56145 11.4507 6.03632 11.4507 6.32922 11.1578C6.62211 10.8649 6.62211 10.39 6.32922 10.0971L2.08657 5.85446L6.32922 1.61182C6.62211 1.31893 6.62211 0.844054 6.32922 0.551161C6.03632 0.258267 5.56145 0.258267 5.26856 0.551161L0.495584 5.32413ZM18.0986 5.10446L1.02592 5.10446V6.60446L18.0986 6.60446V5.10446Z"
                  fill="#F7941D"
                />
              </svg>
              <span>Settings</span>
            </div>
          </div>
          <div
            className="myProfileDetailsMainWrapper"
            style={{ width: "100%" }}
          >
            <div className="settingsHeader">
              <div className="activeImageSetting">
                <img
                  style={{ borderRadius: "50%" }}
                  src={`https://picsum.photos/73/73`}
                  alt="Profile"
                />
              </div>
              <div className="imgText " style={{ alignItems: "self-start" }}>
                <h4>Marry Joseph</h4>
                <span>Deputy General Mangaer</span>
              </div>
            </div>
            <div className="settingsBody">
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="14" r="14" fill="#D8F0F2" />
                <path
                  d="M19.3261 20.5002C20.0039 20.5002 20.4818 19.8361 20.1444 19.2483C19.6922 18.4602 19.0774 17.7711 18.3353 17.2297C17.2215 16.4173 15.8785 15.9795 14.4998 15.9795C13.1212 15.9795 11.7782 16.4173 10.6645 17.2297C9.92228 17.7711 9.30743 18.4603 8.85522 19.2483C8.51789 19.8362 8.99581 20.5002 9.67356 20.5002H19.3261Z"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.4996 15.9875C16.8434 15.9875 18.7435 14.0875 18.7435 11.7438C18.7435 9.4 16.8434 7.5 14.4996 7.5C12.1559 7.5 10.2559 9.4 10.2559 11.7438C10.2559 14.0875 12.1559 15.9875 14.4996 15.9875Z"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.8867 13.3867C15.8867 13.3867 15.5346 14.0796 14.501 14.0796C13.4674 14.0796 13.1152 13.3867 13.1152 13.3867"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.6676 10.9358C18.658 10.9358 18.6486 10.9359 18.6392 10.9359C17.4056 10.9359 16.2981 10.3962 15.5399 9.54007C14.7817 10.3962 13.6742 10.9359 12.4406 10.9359C11.7243 10.9359 11.0505 10.7539 10.4629 10.4336C11.015 8.73104 12.614 7.5 14.5006 7.5C16.5681 7.5 18.2902 8.97845 18.6676 10.9358Z"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <div style={{ cursor: "pointer" }} onClick={() => {}}>
                  Profile Photo
                </div>
              </div>
            </div>
            <div className="settingsBody">
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="14" r="14" fill="#D8F0F2" />
                <path
                  d="M21.1836 13.8984C21.1836 15.2829 20.7731 16.6363 20.0039 17.7874C19.2347 18.9386 18.1415 19.8358 16.8624 20.3656C15.5833 20.8954 14.1758 21.034 12.818 20.7639C11.4601 20.4938 10.2128 19.8272 9.23385 18.8482C8.25488 17.8692 7.58819 16.6219 7.3181 15.2641C7.048 13.9062 7.18662 12.4987 7.71644 11.2197C8.24625 9.94057 9.14346 8.84732 10.2946 8.07815C11.4457 7.30898 12.7991 6.89844 14.1836 6.89844"
                  stroke="#1296B0"
                  stroke-width="1.05"
                  stroke-linecap="round"
                />
                <path
                  d="M16.284 13.8988L14.184 13.8988M14.184 13.8988L12.084 13.8988M14.184 13.8988L14.184 11.7988M14.184 13.8988L14.184 15.9988"
                  stroke="#1296B0"
                  stroke-width="1.05"
                  stroke-linecap="round"
                />
                <path
                  d="M15.9336 7.11914C18.3934 7.75225 20.33 9.6888 20.9631 12.1486"
                  stroke="#1296B0"
                  stroke-width="1.05"
                  stroke-linecap="round"
                />
              </svg>

              <div>
                <div style={{ cursor: "pointer" }} onClick={() => {}}>
                  Status
                </div>
              </div>
            </div>
            <div className="settingsBody">
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="14" r="14" fill="#D8F0F2" />
                <path
                  d="M21.5 14C21.5 14.9193 21.3189 15.8295 20.9672 16.6788C20.6154 17.5281 20.0998 18.2997 19.4497 18.9497C18.7997 19.5998 18.0281 20.1154 17.1788 20.4672C16.3295 20.8189 15.4193 21 14.5 21C13.5807 21 12.6705 20.8189 11.8212 20.4672C10.9719 20.1154 10.2003 19.5998 9.55025 18.9497C8.90024 18.2997 8.38463 17.5281 8.03284 16.6788C7.68106 15.8295 7.5 14.9193 7.5 14C7.5 13.0807 7.68106 12.1705 8.03284 11.3212C8.38463 10.4719 8.90024 9.70026 9.55025 9.05025C10.2003 8.40024 10.9719 7.88463 11.8212 7.53284C12.6705 7.18106 13.5807 7 14.5 7C15.4193 7 16.3295 7.18106 17.1788 7.53284C18.0281 7.88463 18.7997 8.40024 19.4497 9.05025C20.0998 9.70026 20.6154 10.4719 20.9672 11.3212C21.3189 12.1705 21.5 13.0807 21.5 14L21.5 14Z"
                  stroke="#1296B0"
                />
                <path
                  d="M17.0871 16.6788C17.2278 15.8295 17.3002 14.9193 17.3002 14C17.3002 13.0807 17.2278 12.1705 17.0871 11.3212C16.9463 10.4719 16.7401 9.70026 16.4801 9.05025C16.2201 8.40024 15.9114 7.88463 15.5717 7.53284C15.232 7.18106 14.8679 7 14.5002 7C14.1325 7 13.7684 7.18106 13.4287 7.53284C13.089 7.88463 12.7803 8.40024 12.5203 9.05025C12.2603 9.70026 12.054 10.4719 11.9133 11.3212C11.7726 12.1705 11.7002 13.0807 11.7002 14C11.7002 14.9193 11.7726 15.8295 11.9133 16.6788C12.054 17.5281 12.2603 18.2997 12.5203 18.9497C12.7803 19.5998 13.089 20.1154 13.4287 20.4672C13.7684 20.8189 14.1325 21 14.5002 21C14.8679 21 15.232 20.8189 15.5717 20.4672C15.9114 20.1154 16.2201 19.5998 16.4801 18.9497C16.7401 18.2997 16.9463 17.5281 17.0871 16.6788Z"
                  stroke="#1296B0"
                />
                <path
                  d="M7.5 14H21.5"
                  stroke="#1296B0"
                  stroke-linecap="round"
                />
              </svg>

              <div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setLanguageActive(true);
                  }}
                >
                  App Language
                </div>
              </div>
            </div>
            <div className="settingsBody">
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="14.5" cy="14" r="14" fill="#D8F0F2" />
                <path
                  d="M14.5004 20.9647C18.3467 20.9647 21.4647 17.8467 21.4647 14.0004C21.4647 10.1542 18.3467 7.03613 14.5004 7.03613C10.6542 7.03613 7.53613 10.1542 7.53613 14.0004C7.53613 17.8467 10.6542 20.9647 14.5004 20.9647Z"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.8311 12.0547C12.8311 11.7248 12.9289 11.4023 13.1122 11.128C13.2954 10.8537 13.5559 10.6399 13.8607 10.5137C14.1655 10.3874 14.5009 10.3544 14.8244 10.4188C15.148 10.4831 15.4452 10.642 15.6785 10.8753C15.9117 11.1085 16.0706 11.4057 16.1349 11.7293C16.1993 12.0528 16.1663 12.3882 16.04 12.693C15.9138 12.9978 15.7 13.2583 15.4257 13.4415C15.1514 13.6248 14.8289 13.7227 14.499 13.7227V14.8346"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.4982 17.6146C14.3447 17.6146 14.2202 17.4901 14.2202 17.3366C14.2202 17.1831 14.3447 17.0586 14.4982 17.0586"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.498 17.6146C14.6516 17.6146 14.776 17.4901 14.776 17.3366C14.776 17.1831 14.6516 17.0586 14.498 17.0586"
                  stroke="#1296B0"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <div style={{ cursor: "pointer" }} onClick={() => {}}>
                  Help
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MySettingDetails;
