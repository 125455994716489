import { useEffect } from "react";

export const SlabNewTax = () => {
  useEffect(() => {
    document.title = `PeopleSol -  New Slab Tax`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div style={{ background: "white",}}>
      <div className="ratingCar" style={{ fontSize: "14px"}}>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>₹0 - ₹3,00,000</div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b> NIL </b>
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>₹3,00,000 - ₹6,00,000</div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b> 5% </b>
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>₹6,00,000 - ₹9,00,000</div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b> 10%</b>
          </label>
        </div>
        <div className="starholderpmself">
          <div style={{ flex: "1" }}>₹9,00,000 - ₹12,00,000</div>
          <label
            className="jobDesignation"
            style={{ fontSize: "15px", fontWeight: "500" }}
          >
            <b> 15%</b>
          </label>
        </div>
     
      <div className="starholderpmself">
        <div style={{ flex: "1" }}>₹12,00,000 -₹15,00,000</div>
        <label
          className="jobDesignation"
          style={{ fontSize: "15px", fontWeight: "500" }}
        >
          <b> 20%</b>
        </label>
      </div>
      <div className="starholderpmself">
        <div style={{ flex: "1" }}>Above ₹15,00,000</div>
        <label
          className="jobDesignation"
          style={{ fontSize: "15px", fontWeight: "500" }}
        >
          <b> 30%</b>
        </label>
      </div>
      </div>
    </div>
  );
};
