import React from 'react';
import { useEffect } from 'react';
import { TaxTDSTab } from './TaxTDSTab';

const TaxTDS = () => {
    useEffect(() => {
        document.title = `PeopleSol -  Tax TDS`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
           
            
            <TaxTDSTab title="Tax TDS"/>
        </div>
    )
}

export { TaxTDS }