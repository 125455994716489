import React from "react";
import Addonboard from "../../Assets/Addonboard.svg";
import CanOuter from "../../Assets/CanOuter.svg";
import OnboardingCompleteBG from "../../Assets/OnboardingCompleteBG.svg";
import { closeTab } from "../../Utilities";
import { useLocation } from "react-router";
import UploadImageDetail from "../../Components/UploadDetail/UploadImageDetail";
import { useEffect } from "react";

function OnboardingCompleted() {
  function extractValueFromURL(url) {
    const parts = url.split('/');
    const value = parts[parts.length - 1];
    return value;
  }
  const currentURL = window.location.href;
  const extractedValue = extractValueFromURL(currentURL);
  const { state } = useLocation();
  const companyLogo = state?.logo;
  // //console.log("companyLogo",companyLogo)
  useEffect(() => {
    document.title = `PeopleSol - Onboarding Completed`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="outerCont">
      <div className="canOuter">
        <img src={CanOuter} alt="CanOuter" />
      </div>
      <div className="outerGr">
        <img src={Addonboard} alt="Addonboard" />
      </div>
      <div
        className="innerCont"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          justifyContent: "flex-end",
        }}
      >
        <img
          style={{
            position: "absolute",
            width: "95%",
            top: "24px",
            height: "70%",
            objectFit: "cover",
          }}
          src={OnboardingCompleteBG}
          alt="OnboardingCompleteBG"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "106px",
            marginBottom: "48px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <h2 style={{ color: "var(--primary)" }}>Welcome on board!</h2>
            <h3>We are delighted to have you as part of our team</h3>
          </div>
          <button
            style={{ width: "120px", fontSize: "16px", height: "40px" }}
            type="submit"
            className="btnCand"
            onClick={() => closeTab()}
          >
            Let's Go
          </button>
          <UploadImageDetail
              filepath={`Company/${extractedValue}/Logo/${companyLogo}`}
            />
        </div>
      </div>
    </div>
  );
}

export default OnboardingCompleted;
