import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateQuestionComp } from "./FormComp/CreateQuestionComp";
import { useEffect } from "react";
import { CreateSkillComp } from "./FormComp/CreateSkillComp";

const CreateSkill = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`SkillController/Details?ID=${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("SkillController/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest("SkillController/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {}
  };

  const initialvalue = {
    Name: data ? detailsform?.skills?.name : "",
    SkillSetId: data ? detailsform?.skills?.skillSetId : "",
  };
  const validationSchema = Yup.object({
    Name: Yup.string()
      .max(149, "Name must be less than 150 characters")
      .required("Please Enter Name"),
    SkillSetId: Yup.string().required("Please Select Skill Set"),
  });

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: detailsform?.skills?.id,
    };

    const create = {
      ...values,
      Id: 0,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Skill" : "Create Skill"}
      ButtonLabel={data ? "Edit Skill" : "Create Skill"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateSkillComp data={detailsform} />}
    />
  );
};

export { CreateSkill };
