
import React, {  useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { getRequest, postRequest } from "../../../Services/axios";
import { showToast } from "../../../Features";
import { useDispatch } from "react-redux";
import { TeamMemberFormIt } from "./Form/TeamMemberFormIt";
import { teammemberSchemaIT } from "./Schema";

const initialvalue = {
  ticketTypeId:"",
  teamId:""
};
const CreateTeamMembersIt = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create IT Team Member`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const [detailsform,setDetailsform]=useState({})
  const { state } = useLocation();
  const data = state?.id;
  const dispatch = useDispatch();
  const getFormDetails = async () => {
    try {
      const result = await getRequest(`ItsmTeamMappings/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
    } finally {
    }
  }
  const postFormData = async (values) => {
    try {
      const result = await postRequest(
        "ItsmTeamMappings/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {       dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) }
  };
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ItsmTeamMappings/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {  dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    )}
  };
  useEffect(()=>{
    if(data){
      getFormDetails()
    }
  },[])
  const onSubmit = async (values) => {
    
    const edit = {
      ...values,
      id: detailsform?.id,
 
    };
    
    const create = {
      ...values,
    };

    data?Edit(edit) :
     postFormData(create);
    returnPage();
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/itsmmaster/itdesk/teammembers");
  };
  return (
    <FormCustomFormik
    FormHeading={data?'Edit IT Desk Team Members':'Create IT Desk Team Members'}
      validationSchema={teammemberSchemaIT}
      ButtonLabel={data?'Update IT Desk Team Members':'Create IT Desk Team Members'}
      onSubmit={onSubmit}
      initialvalue={
        data
        ? {
            ...initialvalue,
            name:detailsform?.name,
            ticketTypeId:detailsform?.ticketTypeId,
            teamId: detailsform?.teamId,
    
          }
        :
         initialvalue}
      returnPage={returnPage}
      propsDiv={<TeamMemberFormIt data={detailsform}/>}
    />
  );
};


export { CreateTeamMembersIt };
