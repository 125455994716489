import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DatePickerFormiks } from "../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { SelectFormikMultiString } from "../../../../Components/FormComponent/SelectFormikMultiString";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import DropdownInfiniteScroll from "../../../../Components/FormComponent/PopupComponent/DropdownInfiniteScroll";
const typeList = [
  { label: "Quarterly", value: "Quarterly" },
  { label: "Half yearly", value: "Half yearly" },
  { label: "Yearly", value: "Yearly" },
];
const opt = [{ label: "select", value: null }];
export const CreatePerformanceCycleComp = ({ data, editID }) => {
  const dispatch = useDispatch();
  const [goalSettingsList, setGoalSettingsList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentValuesArray, setDepartmentValuesArray] = useState([]);
  const getDepartments = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetDepartments");
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
      });
      setDepartmentList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const getGoalSettings = async () => {
    try {
      const result = await getRequest("GoalSettings/GetGoalSettings");
      let listData = [];
      //console.log("res",result?.data)
      result?.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setGoalSettingsList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    getDepartments();
    getGoalSettings();
  }, []);
  useEffect(() => {
    if (departmentList.length >= 0) {
      data?.departmentIds?.split(",").map((item) => {
        let tempVal = departmentList?.filter(
          (options) => options?.value == item
        );
        if (tempVal.length > 0) {
          setDepartmentValuesArray((prev) => {
            var contains = prev.some((elem) => {
              return JSON.stringify(tempVal[0]) === JSON.stringify(elem);
            });
            if (!contains) {
              return [...prev, tempVal[0]];
            } else {
              return prev;
            }
          });
        }
      });
    }
  }, [departmentList, data]);
  useEffect(() => {
    document.title = `PeopleSol -  Create Performance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Name"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Type"} mandatory={true} />
        <SelectForm
          name="type"
          options={typeList}
          values={
            data
              ? typeList.filter((options) => options.value === data?.type)
              : opt
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Goal Settings"} mandatory={true} />
        <DropdownInfiniteScroll
          name="goalSettingId"
          details={data}
          editID={editID}
          detailsForEdit={[data?.goalSettingId]}
          urlProp={"GoalSettings/GetGoalSettings"}
        />
        {/* <SelectForm
          name="goalSettingId"
          options={goalSettingsList}
          values={
            data
              ? goalSettingsList.filter(
                (options) => options.value === data?.goalSettingId
              )
              : opt}
        /> */}
      </div>
      <div>
        <LabelCustom labelName={"Appraisal Cycle Start Date"} />
        <DatePickerFormiks name="cycleStartDate" />
      </div>
      <div>
        <LabelCustom labelName={"Appraisal Cycle End Date"} />
        <DatePickerFormiks name="cycleEndDate" />
      </div>
      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik
          maxLength={500}
          name="description"
          type={"text"}
          placeholder={"Enter Description"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Guide Line Document"} />
        <FileUploadFormik
          name="upload"
          attachTextLabel={data?.guideLineDocument?.toString()}
        />
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Size: 2 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--primary)" }}>
          Format: JPEG, PNG, PDF
        </span>
      </div>
      <div>
        <LabelCustom labelName={"Process Start Date"} mandatory={true} />
        <DatePickerFormiks name="processStartDate" />
      </div>
      <div>
        <LabelCustom labelName={"Process End Date"} mandatory={true} />
        <DatePickerFormiks name="processEndDate" />
      </div>
      <div>
        <LabelCustom labelName={"Self Appraisal Start Date"} mandatory={true} />
        <DatePickerFormiks name="selfApprisalStartDate" />
      </div>
      <div>
        <LabelCustom labelName={"Self Appraisal End Date"} mandatory={true} />
        <DatePickerFormiks name="selfApprisalEndDate" />
      </div>
      <div>
        <LabelCustom
          labelName={"Appraiser Review Start Date"}
          mandatory={true}
        />
        <DatePickerFormiks name="appraiserReviewStartDate" />
      </div>
      <div>
        <LabelCustom labelName={"Appraiser Review End Date"} mandatory={true} />
        <DatePickerFormiks name="appraiserReviewEndDate" />
      </div>
      <div>
        <LabelCustom
          labelName={"Reviewer Review Start Date"}
          mandatory={true}
        />
        <DatePickerFormiks name="reviewStartDate" />
      </div>
      <div>
        <LabelCustom labelName={"Reviewer Review End Date"} mandatory={true} />
        <DatePickerFormiks name="reviewEndDate" />
      </div>
      <div>
        <LabelCustom labelName={"Excluded Department(s)"} />
        <SelectFormikMultiString
          name="departmentIds"
          multi
          options={departmentList}
          values={departmentValuesArray}
        />
      </div>
    </div>
  );
};
