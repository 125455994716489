import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCompanyFoodComp } from "./FormComp/CreateCompanyFoodComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { format } from "date-fns";
import { CreateReceiveCourierComp } from "./FormComp/CreateReceiveCourierComp";

const CreateReceiveCourier = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Receive Courier Car`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);

  const [detailsform, setDetailsform] = useState({});

  const data1 = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`CourierInRequests/Details?ID=${data1}`);
      // console.log("CourierServicesRequestsdetails", result);
      setDetailsform(result.data.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data1) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("CourierInRequests/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("CourierInRequests/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const initialvalue = {
    employeeID: detailsform?._courierinrequest?.employeeId
      ? detailsform?._courierinrequest?.employeeId
      : "",
    date: detailsform?._courierinrequest?.date
      ? new Date(detailsform?._courierinrequest?.date)
      : "",
    description: detailsform?._courierinrequest?.description
      ? detailsform?._courierinrequest?.description
      : "",
    status: detailsform?._courierinrequest?.status,
  };

  // console.log("initialvalue", initialvalue);
  const validationSchema = Yup.object({
    employeeID: Yup.string().required("Please select the Employee "),
    description: Yup.string()
      // .matches(/^\S.*$/, "Comments should not start with spaces")
      .max(250, "Comments must be less than 250 characters")
      .required("Please Enter the Comments"),
    date: Yup.date().required("Please select the Date and Time "),
  });
  const validationSchema2 = Yup.object({
    employeeID: Yup.string().required("Please select the Employee "),
    description: Yup.string()
      // .matches(/^\S.*$/, "Comments should not start with spaces")
      .max(250, "Comments must be less than 250 characters")
      .required("Please Enter the Comments"),
    status: Yup.string().required("Please select the Status "),
    date: Yup.date().required("Please select the Date and Time "),
  });
  // console.log("formVlaues", formValues);
  const onSubmit = (values) => {
    // console.log("values ohf  hospitakalao", values);
    const edit = {
      ...values,
      date: format(values?.date, "yyyy-MM-dd'T'HH:mm:ss"),
      id: data1,
    };
    // console.log("initialvalue", initialvalue);

    const create = {
      ...values,
      date: format(values?.date, "yyyy-MM-dd'T'HH:mm:ss"),
      // employeeID: detailsform?._CourierServiceRequests?.employeeId,
    };
    data1 ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={
        data1
          ? "Edit Receive Courier Request"
          : "Create Receive Courier Request"
      }
      ButtonLabel={
        data1
          ? "Edit Receive Courier Request"
          : "Create Receive Courier Request"
      }
      onSubmit={onSubmit}
      chooseFormValues={chooseFormValues}
      styles={{ maxWidth: "60%", width: "60%" }}
      validationSchema={data1 ? validationSchema2 : validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateReceiveCourierComp
          data={detailsform}
          edit={data1}
          formValues={formValues}
          editID={data1 ? true : false}
        />
      }
    />
  );
};

export { CreateReceiveCourier };
