import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menu: false,
  menuModuleGroup: [],
  subMenuList: [],
  featureMenuList: [],
};

const MenuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    closeMenu: (state) => {
      state.menu = false;
    },
    OpenMenu: (state) => {
      state.menu = true;
    },
    setMenuModuleList: (state, action) => {
      state.menuModuleGroup = action.payload;
    },
    setSubMenuModuleList: (state, action) => {
      state.subMenuList = action.payload;
    },
    setFeatureMenuModuleList: (state, action) => {
      state.featureMenuList = action.payload;
    },
  },
});
export const {
  closeMenu,
  OpenMenu,
  setFeatureMenuModuleList,
  setSubMenuModuleList,
  setMenuModuleList,
} = MenuSlice.actions;
export default MenuSlice.reducer;
