import React, { useEffect, useState } from 'react'
import "./UserOnboarding.css"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { closePopup } from '../../../../../Features';
import CanOuter from "../../../../../Assets/CanOuter.svg";
import { WelcomeOnboard } from './WelcomeOnboard';
import { PeopleSolFeatures } from './PeopleSolFeatures';
import { ManagerSelfService } from './ManagerSelfService';
import { EmployeeSelfService } from './EmployeeSelfService';
import { MobileAppDownload } from './MobileAppDownload';
import { IconButton } from '@mui/material';


const UserOnboarding = () => {
  useEffect(() => {
    document.title = `PeopleSol - User Onboarding`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();

    const [welcomeOnboardVisible, setWelcomeOnboardVisible] = useState(true);
    const [featuresVisible, setFeaturesVisible] = useState(false);
    const [managerVisible, setManagerVisible] = useState(false);
    const [employeeVisible, setEmployeeVisible] = useState(false);
    const [mobileVisible, setMobileVisible] = useState(false);

  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
     if (currentpath.includes("useronboarding")) {
      if (currentpath.includes("welcome")) {
        setWelcomeOnboardVisible(true);
      } else {
        setWelcomeOnboardVisible(false);
      }

      if (currentpath.includes("features")) {
        setFeaturesVisible(true);
      } else {
        setFeaturesVisible(false);
      }

      if (currentpath.includes("manager")) {
        setManagerVisible(true);
      } else {
        setManagerVisible(false);
      }
       
      if (currentpath.includes("employee")) {
        setEmployeeVisible(true);
      } else {
        setEmployeeVisible(false);
      }
       
      if (currentpath.includes("mobile")) {
        setMobileVisible(true);
      } else {
        setMobileVisible(false);
      }
       
      } 
      
    }
       , [location.pathname]);

    const returnPage = () => {
        navigate('/home/dashboard')
        dispatch(closePopup());
    }

  return (
    <>
      
     <div className={`createPageContainer `} style={{gap: '0', overflow: 'hidden'}}>
         <div className="createProjectContainer userOnboardPopup">

        
        <div className="onboardReturnIconHolder" onClick={() => returnPage()}>
        <IconButton>
        <svg class="cursor" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7579 0.56897L0.757935 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M0.757935 0.56897L12.7579 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </IconButton>
        </div>
        <div className="onboardingImageInfoContainer">

        
        <div className="canOuter" style={{ opacity: "1", width:'50%' }}>
        <img src={CanOuter} alt="backgroundImage" style={{width:'100%'}} />
      </div>

        <div className="onboardContentHolder">
      
        {welcomeOnboardVisible ? <WelcomeOnboard/> : null}
      {featuresVisible ? <PeopleSolFeatures/> : null}
      {managerVisible ? <ManagerSelfService/> : null}
      {employeeVisible ? <EmployeeSelfService/> : null}
      {mobileVisible ? <MobileAppDownload/> : null}

         

        </div>
        </div>

         </div>
      
     </div>

    

</>
  )
}

export {UserOnboarding}
