import React, { useState } from "react";
import "./RequestsCard.css";
import { Tabs, Tab } from "@mui/material";
import { useEffect } from "react";

const RequestsCard = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  const data = [
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
    {
      name: "Digvijaya Swain (10000002)",
      type: "Early Going EG/01",
      reason: "On Hold",
      days: "26 Days",
    },
  ];
 
  return (
    <div className="dashboardCardContentHolder">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#F7941D" } }}
        >
          <Tab
            label={
              <span
                className={`tabHeading ${
                  tabIndex === 0 && "tabActive tabActiveSecondary"
                }`}
              >
                Pending (100)
              </span>
            }
          />
          <Tab
            label={
              <span
                className={`tabHeading ${
                  tabIndex === 1 && "tabActive tabActiveSecondary"
                }`}
              >
                On Hold (100)
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <div className="approvalFrameTabData">
          <table className="teamAttendanceCard">
            <tr className="teamAttendanceTableHeader">
              <th></th>
              <th>Held by</th>
              <th>Type</th>
              <th>Reason</th>
              <th>Holding Days</th>
            </tr>
            {data.map((val, key) => {
              return (
                <tr key={key} className="teamAttendanceItemContainer">
                  <td></td>
                  <td className="teamAttendanceNameItem">{val.name}</td>
                  <td className="teamAttendanceItem">{val.type}</td>
                  <td className="teamAttendanceItem">{val.reason}</td>
                  <td className="teamAttendanceItem">{val.days}</td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
      {tabIndex === 1 && (
        <div className="approvalFrameTabData">
          <table className="teamAttendanceCard">
            <tr className="teamAttendanceTableHeader">
              <th></th>
              <th>Held by</th>
              <th>Type</th>
              <th>Reason</th>
              <th>Holding Days</th>
            </tr>
            {data.map((val, key) => {
              return (
                <tr key={key} className="teamAttendanceItemContainer">
                  <td></td>
                  <td className="teamAttendanceNameItem">{val.name}</td>
                  <td className="teamAttendanceItem">{val.type}</td>
                  <td className="teamAttendanceItem">{val.reason}</td>
                  <td className="teamAttendanceItem">{val.days}</td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
};

export { RequestsCard };
