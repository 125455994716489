import React from "react";
import { useState } from "react";
import { Table } from "../../../Components";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalRelocation } from "../Components/RelocationApproval/PendingApprovalRelocation";
import { RelocationApproved } from "../Components/RelocationApproval/RelocationApproved";
import { HoldRequestRelocation } from "../Components/RelocationApproval/HoldRequestRelocation";
import { RejectedRelocation } from "../Components/RelocationApproval/RejectedRelocation";
import { AllRequestRelocation } from "../Components/RelocationApproval/AllRequestRelocation";
import { useEffect } from "react";

const ApprovalRelocation = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Relocation`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalRelocation
            title={"Relocation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <RelocationApproved
          title={"Relocation"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <RejectedRelocation
            title={"Relocation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <AllRequestRelocation
            title={"Relocation"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalRelocation };
