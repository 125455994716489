import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { HoldRequestRequisition } from "../Components/RequisitionApprovals/HoldRequestRequisition";
import { RejectedRequisition } from "../Components/RequisitionApprovals/RejectedRequisition";
import { AllRequestRequisition } from "../Components/RequisitionApprovals/AllRequestRequisition";
import { PendingApprovalRequisition } from "../Components/RequisitionApprovals/PendingApprovalRequisition";
import { ApprovedRequisition } from "../Components/RequisitionApprovals/ApprovedRequisition";
import { useEffect } from "react";

const ApprovalRequisition = ({getApprovalBadge}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(()=>{
    document.title = `PeopleSol - Requisition`;
    return () => {
      document.title = 'PeopleSol';
    };
  })
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Approved
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Hold
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Rejected
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 4 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalRequisition title={"Requisition"} getApprovalBadge={getApprovalBadge}/>
        </>
      )}
      {tabIndex === 1 && <ApprovedRequisition title={"Requisition"} />}
      {tabIndex === 2 && (
        <>
          <HoldRequestRequisition title={"Requisition"} />
        </>
      )}
      {tabIndex === 3 && (
        <>
          <RejectedRequisition title={"Requisition"} />
        </>
      )}
      {tabIndex === 4 && (
        <>
          <AllRequestRequisition title={"Requisition"} getApprovalBadge={getApprovalBadge}/>
        </>
      )}
    </div>
  );
};

export { ApprovalRequisition };
