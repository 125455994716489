import React from 'react'
import { useEffect } from 'react';

const HolidayComp = ({date, title, desc}) => {
  useEffect(() => {
    document.title = `PeopleSol - Holiday`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="attendanceContentContainer" style={{backgroundColor:`#F0F0FF`}}>
       <div className="dateDisplay">{date?date:""}</div>
     <div className="horizontalLinePrimary calendarLine" style={{backgroundColor:`#8A88ED`}}></div>

        <div className='attendanceContentStatus' style={{color:`#8A88ED`}} >{title?title:""}</div>
        <div style={{display:'flex'}}>
           
        <label className='attendanceIn' style={{color:'black', fontSize:'13px'}}>{desc?desc:""}</label>
            </div>
        
        </div>
  )
}

export {HolidayComp}
