import React from "react";
import { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { PendingApprovalVisitors } from "../Components/VisitorsApproval/PendingApprovalVisitors";
import { AllRequestVisitors } from "../Components/VisitorsApproval/AllRequestVisitors";
import { RejectedVisitors } from "../Components/VisitorsApproval/RejectedVisitors";
import { VisitorsApproved } from "../Components/VisitorsApproval/VisitorsApproved";
import { CancelledVisitors } from "../Components/VisitorsApproval/CancelledVisitors";
import { useEffect } from "react";

const ApprovalVisitors = ({ getApprovalBadge }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  useEffect(() => {
    document.title = `PeopleSol - Visitors`;
    return () => {
      document.title = "PeopleSol";
    };
  });
  return (
    <div className="moduleMainContainer">
      <div className="tabContainer" style={{ width: "100%" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>
                Pending
              </span>
            }
          />
          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>
                Arrived
              </span>
            }
          />

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 2 && "tabActive"}`}>
                Exited
              </span>
            }
          />
          {/* <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                Cancelled
              </span>
            }
          /> */}

          <Tab
            label={
              <span className={`tabHeading ${tabIndex === 3 && "tabActive"}`}>
                All
              </span>
            }
          />
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === 0 && (
        <>
          <PendingApprovalVisitors
            title={"Visitors"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {tabIndex === 1 && (
        <VisitorsApproved
          title={"Visitors"}
          getApprovalBadge={getApprovalBadge}
        />
      )}

      {tabIndex === 2 && (
        <>
          <RejectedVisitors
            title={"Visitors"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
      {/* {tabIndex === 3 && (
        <>
          <CancelledVisitors title={"Visitors"} />
        </>
      )} */}

      {tabIndex === 3 && (
        <>
          <AllRequestVisitors
            title={"Visitors"}
            getApprovalBadge={getApprovalBadge}
          />
        </>
      )}
    </div>
  );
};

export { ApprovalVisitors };
