import { Form, Formik } from "formik";
import * as Yup from 'yup'
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";

const option=[
    {label:"Needs Improvement",value:"1"},
    {label:"Meets Expectation",value:"2"},
    {label:"Satisfactory",value:"3"},
    {label:"Above Expectations",value:"4"},
  ]
  const initialvalue={
    remarks: "",
    experienceUsingTool: "",
    interactionExperienceWithEngineer: "",
    satisfiedWithResult: "",
    satisfiedWithResponse: "",
    queryResolvedOnTime: "",
  }
  const validationSchema = Yup.object({
    remarks: Yup.string().required("Required"),
    experienceUsingTool: Yup.string().required("Required"),
    interactionExperienceWithEngineer: Yup.string().required("Required"),
    satisfiedWithResult: Yup.string().required("Required"),
    satisfiedWithResponse: Yup.string().required("Required"),
    queryResolvedOnTime: Yup.string().required("Required"),
  });
export const FeedBack = ({ data, id, type,  handleClose }) => {
    const onSubmit = async (values) => {
        const value={...values,ticketId:id}
        // //console.log("val",value)
      data(value);
    };
    useEffect(() => {
      document.title = `PeopleSol - Feedback`;
      return () => {
        document.title = 'PeopleSol';
      };
    }, []);
    return (
      <Formik 
        initialValues={initialvalue}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
        <div  className="formgrid">
        <div>
            <LabelCustom labelName={"Was your query resolved on time? "} mandatory={true}/>
            <SelectForm name="queryResolvedOnTime" options={option} />
          </div>
          <div>
        <LabelCustom labelName={"Are you satisfied with the HR Team response? "} mandatory={true} />
        <SelectForm name="satisfiedWithResponse"  options={option}/>
      </div>
          <div>
        <LabelCustom labelName={"Are you satisfied with the service? "} mandatory={true} />
        <SelectForm   name="satisfiedWithResult"  options={option}/>
      </div>
          <div>
        <LabelCustom labelName={"How was your interaction experience with HR Team?"} mandatory={true} />
        <SelectForm   name="interactionExperienceWithEngineer"  options={option}/>
      </div>
          <div>
        <LabelCustom labelName={"How was your experience using the HR Desk?"} mandatory={true} />
        <SelectForm   name="experienceUsingTool"  options={option}/>
      </div>
      <div>
      <LabelCustom labelName={"Closure Remark"} mandatory={true} />
          <TextAreaFormik style={{height:"80px"}} name={"remarks"} maxLength={500}/>
      </div>
      </div>
          
          <div className="createFootCust">
              <button type={'button'}className="button secondaryButton" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="button primaryButton"
                type="submit"
              
              >
                Submit
              </button>
          </div>
        </Form>
      </Formik>
    );
  };