import React, {useState} from 'react';
// import "./ReleaseNotes.css";
import { Navigate, useNavigate } from "react-router-dom";
import { Tabs, Tab, IconButton } from "@mui/material";
import { Table } from "../../../Components/index";
import Select from "react-dropdown-select";
import { useEffect } from 'react';

const ReleaseNotes = (props) => {
  

    const navigate = useNavigate();
  
    useEffect(()=>{
        document.title = `PeopleSol - Release Notes`;
        return () => {
          document.title = 'PeopleSol';
        };
      })

    const Countries = [
      { label: "10", value: 10 },
      { label: "20", value: 20 },
      { label: "50", value: 50 },
      { label: "100", value: 100 }
  ];

    const actionData = [{
      img: <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z" fill="#0DB53C"/>
      <path d="M6.93986 15.4946C6.99241 15.4421 7.7807 14.4962 8.9894 13.3926C9.09451 13.2874 9.19961 13.2874 9.35727 13.2874C11.1441 13.2874 13.7717 13.2874 15.5585 13.2874C16.0314 13.2874 16.3467 12.9721 16.3467 12.4992C16.3467 10.292 16.3467 8.0322 16.3467 5.77245C16.3467 5.35203 16.0314 4.98416 15.5585 4.98416C12.3528 4.98416 9.14706 4.98416 5.94136 4.98416C5.46839 4.98416 5.15308 5.29948 5.15308 5.77245C5.15308 7.97965 5.15308 10.2394 5.15308 12.4992C5.15308 12.9196 5.46839 13.2874 5.94136 13.2874C6.09902 13.2874 6.30923 13.2874 6.46689 13.2874C6.7822 13.2874 6.93986 13.4977 6.93986 13.7604C6.93986 14.2859 6.93986 14.7589 6.93986 15.2844C6.93986 15.337 6.93986 15.3895 6.93986 15.4946Z" stroke="#0DB53C" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <circle cx="7.78991" cy="9.33009" r="0.729975" fill="#0DB53C"/>
      <circle cx="10.7499" cy="9.33009" r="0.729975" fill="#0DB53C"/>
      <circle cx="13.7098" cy="9.33009" r="0.729975" fill="#0DB53C"/>
      </svg>
      , action: "Project Status Update"
  }, {
      img: <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.15" d="M0.75 4.23941C0.75 2.03027 2.54086 0.23941 4.75 0.23941H16.75C18.9591 0.23941 20.75 2.03027 20.75 4.23941V16.2394C20.75 18.4485 18.9591 20.2394 16.75 20.2394H4.75C2.54086 20.2394 0.75 18.4485 0.75 16.2394V4.23941Z" fill="#FB7158"/>
      <path d="M15.75 7.09126L13.8981 5.23941L6.49074 12.6468L5.75 15.2394L8.34259 14.4987L15.75 7.09126ZM12.4167 6.72089L14.2685 8.57274L12.4167 6.72089ZM6.49074 12.6468L8.34259 14.4987L6.49074 12.6468Z" stroke="#FB7158" strokeWidth="0.698594" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      
      , action: "Update Categories"
  }]

    const theadData = ["Sl. No.",
        "title",
        "Version",
        "Status",
        "Created On",
       
        ];
    const tbodyData = [
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        {
            id: "1",
            items: ["Solutions",
            "SLU/01",
            "Digvijaya Swain",
            "Per Man Hour Cost",
            "1000000",
           
              ],
        },
        
        
        
        
    ];

  return (
    
                <>
                    <div className="tableHeaderContainer">
                    <button onClick={() => { navigate("/hrms/projecttasks/projects/create") }} className='createFooterButton2 addProjectButton buttonClass' type="submit">+ Create {props.title}</button>
                        <div className="chatListSearchContainer navBarSearch">
                            <input type="text" className="chatListSearch" placeholder="Search" />
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="5.5" cy="5.5" r="4.5" stroke="#1296B0"></circle><line x1="8.70711" y1="9" x2="12" y2="12.2929" stroke="#1296B0" strokeLinecap="round"></line>
                            </svg>
                        </div>
                        <IconButton color='primary'>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z" stroke="#1296B0" strokeWidth="1.5" />
                            </svg>
                        </IconButton>
                        <IconButton color='primary'>
                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961" stroke="#1296B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </IconButton>
                        <IconButton color='primary'>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.1949 0.95166V11.6183M10.1949 0.95166L6.63936 4.50722M10.1949 0.95166L13.7505 4.50722M1.30603 13.3961L1.85803 15.605C1.95417 15.9896 2.1761 16.331 2.48855 16.575C2.801 16.819 3.18604 16.9516 3.58247 16.9517H16.8074C17.2038 16.9516 17.5888 16.819 17.9013 16.575C18.2137 16.331 18.4357 15.9896 18.5318 15.605L19.0838 13.3961" stroke="#1296B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </IconButton>
                    </div>
                    <Table theadData={theadData} tbodyData={tbodyData} actionData={actionData} />
                    <div className="horizontalLine"></div>
                    <div className="tableFooterContainer">
                        <label>Total Record Count: </label><span> 01</span>
                        <Select className='recordDropdown' options={Countries}   placeholder='10' />
                        <label>Records Per Page</label>
                    </div>
                </>
    
  )
}

export  {ReleaseNotes};
