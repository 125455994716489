import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailsQuestionSets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(false);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `TrainingQuestionsSets/Details/${selectedId}`
      );
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
  }, []);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            <DetailsStep detailTitle={"Name"} Details={eventtData?.name} />

            
             <DetailsStep
            detailTitle={"Created On"}
            Details={eventtData?.lastUpdatedOn && format(
              new Date(eventtData?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={eventtData?.lastUpdatedOn && format(
              new Date(eventtData?.lastUpdatedOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />




            <div>
              <h4 style={{ margin: "10px 0px" }}>Questions</h4>
            </div>
            <div style={{ width: "100%" }}>
              <table className="tabless tabless-bordered">
                <thead
                  style={{
                    backgroundColor: "var(--primary-hover)",
                  }}
                >
                  <tr>
                    <td>S.no</td>
                    <td>Question </td>
                    <td>Is Active</td>
                    <td>Created On</td>
                    <td>Last Updated On</td>
                  </tr>
                </thead>
                <tbody>
                  {eventtData?.trainingQuestions?.length > 0
                    ? eventtData?.trainingQuestions?.map((item, index) => {
                        return (
                          <tr className="trStyle">
                            <td>{index + 1}</td>
                            <td>{item.questionText}</td>
                            <td>{item.isActive ? "Yes" : "No"}</td>
                            <td>{item.createdOn}</td>
                            <td>{item.lastUpdatedOn}</td>
                          </tr>
                        );
                      })
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsQuestionSets };
