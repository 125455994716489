/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SideNavigation.css";
import HRMSIcon from "../../Assets/HRMSIcon.svg";
import PayrollIcon from "../../Assets/PayrollIcon.svg";
import ATSIcon from "../../Assets/ATSIcon.svg";
import PMSIcon from "../../Assets/PMSIcon.svg";
import LDIcon from "../../Assets/LDIcon.svg";
import OfficeAdminIcon from "../../Assets/OfficeAdminIcon.svg";
import ITSMIcon from "../../Assets/ITSMIcon.svg";
import TravelIcon from "../../Assets/TravelIcon.svg";
import Reports from "../../Assets/Reports.svg";
import HomeIcon from "../../Assets/HomeIcon.svg";
import TaxIcon from "../../Assets/TaxIcon.svg";
import ForwardArrow from "../../Assets/ForwardArrow.svg";
import { closeMenu, loggedOut, showToast } from "../../Features";
import { useNavigate } from "react-router-dom";
import { GroupBy } from "../../Utilities";
import { getRequest, postRequest } from "../../Services/axios";
import {
  setFeatureMenuModuleList,
  setMenuModuleList,
  setSubMenuModuleList,
} from "../../Features/MenuSlice";
const SideNavigation = () => {
  const { menu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [comingSoonActive, setComingSoonActive] = useState(false);
  const [paidActive, setPaidActive] = useState(false);
  const [activeMenuID, setActiveMenuId] = useState(0);
  const [activeSubMenuID, setActiveSubMenuID] = useState(0);
  const [menuModuleGroup, setMenuModuleGroup] = useState(false);
  const [subMenuList, setSubMenuList] = useState(false);
  const [featureMenuList, setFeatureMenuList] = useState(false);
  const logout = async () => {
    try {
      await postRequest("Settings/Logout");
      dispatch(loggedOut());
    } catch (error) {
      dispatch(loggedOut());
    }
  };
  const updateMenu = (menuFromApi) => {
    let tempMenu = Object.values(GroupBy(menuFromApi, "menuId"));
    setMenuModuleGroup(tempMenu);
    tempMenu.map((item) => {
      try {
        const tempGroup = GroupBy(item, "subMenuId");
        let tempSubMenu = Object.values(tempGroup);
        setSubMenuList((prev) =>
          prev !== false ? [...prev, tempSubMenu] : [tempSubMenu]
        );
        tempSubMenu.map((feature) => {
          if (feature.length > 1)
            setFeatureMenuList((prev) =>
              prev !== false ? [...prev, feature] : [feature]
            );
          return null;
        });
      } catch (error) {}
      return null;
    });
  };
  const getMenu = async () => {
    try {
      const result = await getRequest("Employees/GetPermissions");
      const menuFromApi = result?.data;
      updateMenu(menuFromApi);
    } catch (error) {
      dispatch(
        showToast({
          text: "Some error occured in fetching Data",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    getMenu();
    return () => {};
  }, []);
  useEffect(() => {
    if (menuModuleGroup) dispatch(setMenuModuleList(menuModuleGroup));
    if (subMenuList) dispatch(setSubMenuModuleList(subMenuList));
    if (featureMenuList) dispatch(setFeatureMenuModuleList(featureMenuList));
  }, [featureMenuList, subMenuList, menuModuleGroup]);

  const menuActiveHandler = (menu) => {
    if (!menu[0].isMenuActive && menu[0].menuName === "ATS")
      setComingSoonActive(true);
    else setComingSoonActive(false);
    if (!menu[0].isMenuActive && menu[0].menuName !== "ATS")
      setPaidActive(true);
    else setPaidActive(false);
    setActiveMenuId(menu[0].menuId);
    setActiveSubMenuID(0);
  };
  const subMenuActiveHandler = (subMenuId) => {
    setActiveSubMenuID(subMenuId);
  };

  const handleMenuIcon = (menuName, active) => {
    if (menuName === "Home") return HomeIcon;
    else if (menuName === "HRMS") return HRMSIcon;
    else if (menuName === "Payroll") return PayrollIcon;
    else if (menuName === "Tax") return TaxIcon;
    else if (menuName === "ATS") return ATSIcon;
    else if (menuName === "PMS") return PMSIcon;
    else if (menuName === "L&D") return LDIcon;
    else if (menuName === "Office") return OfficeAdminIcon;
    else if (menuName === "ITSM") return ITSMIcon;
    else if (menuName === "Travel") return TravelIcon;
    else if (menuName === "Reports & KPI") return Reports;
  };

  const handleHoverStyle = (menuId) => {
    if (menuId === "Home") return "homeTileActive";
    else if (menuId === "HRMS") return "hrmsTileActive";
    else if (menuId === "Payroll") return "payrollTileActive";
    else if (menuId === "Tax") return "taxTileActive";
    else if (menuId === "ATS") return "atsTileActive";
    else if (menuId === "PMS") return "pmsTileActive";
    else if (menuId === "L&D") return "ldTileActive";
    else if (menuId === "Office") return "adminTileActive";
    else if (menuId === "ITSM") return "itsmTileActive";
    else if (menuId === "Travel") return "travelTileActive";
    else if (menuId === "Reports & KPI") return "reportsTileActive";
    else return false;
  };

  const handleTileStyle = (menuId) => {
    if (menuId === "Home") return "home";
    else if (menuId === "HRMS") return "hrms";
    else if (menuId === "Payroll") return "payroll";
    else if (menuId === "Tax") return "tax";
    else if (menuId === "ATS") return "ats";
    else if (menuId === "PMS") return "pms";
    else if (menuId === "L&D") return "ld";
    else if (menuId === "Office") return "admin";
    else if (menuId === "ITSM") return "itsm";
    else if (menuId === "Travel") return "travel";
    else if (menuId === "Reports & KPI") return "reports";
    else return false;
  };

  const handleActiveFirstMenu = (menuId) => {
    if (menuId === "Home") return "homeActive";
    else if (menuId === "HRMS") return "hrmsActive";
    else if (menuId === "Payroll") return "payrollActive";
    else if (menuId === "Tax") return "taxActive";
    else if (menuId === "ATS") return "atsActive";
    else if (menuId === "PMS") return "pmsActive";
    else if (menuId === "L&D") return "ldActive";
    else if (menuId === "Office") return "adminActive";
    else if (menuId === "ITSM") return "itsmActive";
    else if (menuId === "Travel") return "travelActive";
    else if (menuId === "Reports & KPI") return "reportsActive";
    else return false;
  };
  const handlesubMenuHoverStyle = (menuId) => {
    if (menuId === "Home") return "homeTileActive";
    else if (menuId === "HRMS") return "hrmsTileActive";
    else if (menuId === "Payroll") return "payrollTileActive";
    else if (menuId === "Tax") return "taxTileActive";
    else if (menuId === "ATS") return "atsTileActive";
    else if (menuId === "PMS") return "pmsTileActive";
    else if (menuId === "L&D") return "ldTileActive";
    else if (menuId === "Office") return "adminTileActive";
    else if (menuId === "ITSM") return "itsmTileActive";
    else if (menuId === "Travel") return "travelTileActive";
    else if (menuId === "Reports & KPI") return "reportsTileActive";
    else return false;
  };
  const handleActiveSecondMenu = (menuId) => {
    if (menuId === "Home") return "homeSubmenuActive";
    else if (menuId === "HRMS") return "hrmsSubmenuActive";
    else if (menuId === "Payroll") return "payrollSubmenuActive";
    else if (menuId === "Tax") return "taxSubmenuActive";
    else if (menuId === "ATS") return "atsSubmenuActive";
    else if (menuId === "PMS") return "pmsSubmenuActive";
    else if (menuId === "L&D") return "ldSubmenuActive";
    else if (menuId === "Office") return "adminSubmenuActive";
    else if (menuId === "ITSM") return "itsmSubmenuActive";
    else if (menuId === "Travel") return "travelSubmenuActive";
    else if (menuId === "Reports & KPI") return "reportskpiSubmenuActive";
    else return false;
  };
  const handlefeatureHoverStyle = (menuId) => {
    if (menuId === "Home") return "homeSubtile";
    else if (menuId === "HRMS") return "hrmsSubtile";
    else if (menuId === "Payroll") return "payrollSubtile";
    else if (menuId === "Tax") return "taxSubtile";
    else if (menuId === "ATS") return "atsSubtile";
    else if (menuId === "PMS") return "pmsSubtile";
    else if (menuId === "L&D") return "ldSubtile";
    else if (menuId === "Office") return "adminSubtile";
    else if (menuId === "ITSM") return "itsmSubtile";
    else if (menuId === "Travel") return "travelSubtile";
    else if (menuId === "Reports & KPI") return "reportskpiSubtile";
    else return false;
  };
  return (
    <div className="side-navigation">
      <div className="mainMenuMainContainer">
        <div className="mainMenuContainer">
          <div className="mainMenuTileContainer">
            <div className="mainMenuHorizontalLine" />
            {menuModuleGroup &&
              menuModuleGroup
                .slice()
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0]?.menuOrder) - parseFloat(b[0]?.menuOrder)
                )
                ?.map((item, index) => {
                  return (
                    <div
                      // href={item[0]?.isMenuActive &&
                      //       `/${item[0].menuName
                      //         ?.replaceAll(" ", "")
                      //         ?.replaceAll("&", "")
                      //         ?.toLowerCase()}`
                      // }
                      style={{
                        textDecoration: "none",
                        width: "100%",
                        color: "red",
                      }}
                      onClick={(e) => {
                        if (item[0]?.isMenuActive) {
                          navigate(
                            `/${item[0].menuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        } else if (!item[0]?.isMenuActive && paidActive) {
                          navigate(
                            `/premium/${item[0].menuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }
                        else{
                          return null
                        }
                        dispatch(closeMenu());
                      }}
                      
                    >
                      <div
                        key={index}
                        title={item[0].menuDisplayName}
                        className={`mainMenuTile cursor ${handleTileStyle(
                          item[0].menuName
                        )} ${
                          activeMenuID === item[0].menuId &&
                          handleHoverStyle(item[0].menuName)
                        } ${!item[0]?.isMenuActive && "inactiveMenu"}`}
                        onMouseEnter={() => menuActiveHandler(item)}
                      >
                        <div className="mainMenuVerticalBar" />
                        <div className="mainMenuIconContainer">
                          {item[0].isMenuActive === false && (
                            <div className="premSv">
                              <svg
                                width="43"
                                height="43"
                                viewBox="0 0 43 43"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g filter="url(#filter0_d_581_501)">
                                  <rect
                                    x="11.5605"
                                    y="9.53223"
                                    width="20"
                                    height="20"
                                    rx="2.4"
                                    fill="white"
                                  />
                                </g>
                                <path
                                  d="M16.7109 24.9493L16.6416 24.6067H26.5337L26.4637 24.9493H16.7109Z"
                                  fill="#FFC329"
                                  stroke="#FFC329"
                                  stroke-width="0.82271"
                                />
                                <path
                                  d="M27.8367 18.0709L26.666 22.4896H16.5334L15.3105 18.0926L17.8453 19.475C18.3214 19.7346 18.9176 19.5706 19.1939 19.1039L21.5913 15.0542L23.8817 19.0867L24.4711 18.7519L23.8817 19.0867C24.1522 19.5629 24.7537 19.7356 25.2357 19.4754L27.8367 18.0709Z"
                                  fill="#FFC329"
                                  stroke="#FFC329"
                                  stroke-width="1.35571"
                                />
                                <defs>
                                  <filter
                                    id="filter0_d_581_501"
                                    x="0.591077"
                                    y="0.75665"
                                    width="41.9389"
                                    height="41.9389"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="2.19389" />
                                    <feGaussianBlur stdDeviation="5.48474" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_581_501"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_581_501"
                                      result="shape"
                                    />
                                  </filter>
                                </defs>
                              </svg>
                            </div>
                          )}
                          {item[0].isMenuActive === false &&
                            item[0].menuName === "ATS" && (
                              <div className="premSv">
                                <svg
                                  width="43"
                                  height="43"
                                  viewBox="0 0 43 43"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g filter="url(#filter0_d_581_537)">
                                    <rect
                                      x="11.8931"
                                      y="9.12598"
                                      width="20"
                                      height="20"
                                      rx="2.4"
                                      fill="white"
                                    />
                                  </g>
                                  <path
                                    d="M19.728 12.1512C19.728 11.9598 19.804 11.7763 19.9393 11.641C20.0747 11.5057 20.2582 11.4297 20.4495 11.4297H23.3356C23.527 11.4297 23.7105 11.5057 23.8458 11.641C23.9811 11.7763 24.0571 11.9598 24.0571 12.1512C24.0571 12.3426 23.9811 12.5261 23.8458 12.6614C23.7105 12.7967 23.527 12.8727 23.3356 12.8727H22.6141V13.8347L22.6131 13.8742C23.8324 14.0091 24.9881 14.4879 25.9456 15.2547L25.9523 15.2489L26.6738 14.5274C26.762 14.4324 26.8742 14.363 26.9985 14.3264C27.1229 14.2898 27.2548 14.2875 27.3803 14.3196C27.5059 14.3517 27.6205 14.4171 27.712 14.5088C27.8035 14.6006 27.8686 14.7154 27.9004 14.841C27.9325 14.9664 27.9303 15.0982 27.8939 15.2225C27.8575 15.3467 27.7883 15.4589 27.6936 15.5471L26.972 16.2687L26.9663 16.2754C27.7294 17.2308 28.2073 18.3822 28.3449 19.5972C28.4826 20.8122 28.2744 22.0414 27.7443 23.1433C27.2142 24.2452 26.3837 25.175 25.3485 25.8258C24.3133 26.4765 23.1153 26.8218 21.8926 26.8218C20.6698 26.8218 19.4719 26.4765 18.4366 25.8258C17.4014 25.175 16.571 24.2452 16.0409 23.1433C15.5108 22.0414 15.3026 20.8122 15.4402 19.5972C15.5778 18.3822 16.0557 17.2308 16.8189 16.2754L16.8131 16.2687L16.0916 15.5471C15.9642 15.4103 15.8948 15.2294 15.8981 15.0424C15.9013 14.8555 15.9769 14.6771 16.1089 14.5447C16.2413 14.4127 16.4197 14.3371 16.6066 14.3339C16.7936 14.3306 16.9745 14.4 17.1113 14.5274L17.8328 15.2489L17.8396 15.2547C18.7966 14.4878 19.9521 14.009 21.1711 13.8742V12.8727H20.4495C20.2582 12.8727 20.0747 12.7967 19.9393 12.6614C19.804 12.5261 19.728 12.3426 19.728 12.1512ZM21.8926 25.379C23.2322 25.3789 24.5169 24.8466 25.4641 23.8992C26.4113 22.9519 26.9433 21.667 26.9432 20.3274C26.9431 18.9878 26.4108 17.7031 25.4634 16.7559C24.5161 15.8087 23.2312 15.2767 21.8916 15.2768C20.552 15.2769 19.2673 15.8092 18.3201 16.7566C17.3729 17.7039 16.8409 18.9887 16.841 20.3284C16.8411 21.668 17.3734 22.9527 18.3208 23.8999C19.2681 24.8471 20.5529 25.3791 21.8926 25.379Z"
                                    fill="#73BCFF"
                                  />
                                  <circle
                                    cx="18.9275"
                                    cy="20.2293"
                                    r="0.948999"
                                    fill="#73BCFF"
                                  />
                                  <circle
                                    cx="21.9109"
                                    cy="20.2293"
                                    r="0.948999"
                                    fill="#73BCFF"
                                  />
                                  <circle
                                    cx="24.8582"
                                    cy="20.2293"
                                    r="0.948999"
                                    fill="#73BCFF"
                                  />
                                  <defs>
                                    <filter
                                      id="filter0_d_581_537"
                                      x="0.923596"
                                      y="0.3504"
                                      width="41.9389"
                                      height="41.9389"
                                      filterUnits="userSpaceOnUse"
                                      color-interpolation-filters="sRGB"
                                    >
                                      <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                      />
                                      <feOffset dy="2.19389" />
                                      <feGaussianBlur stdDeviation="5.48474" />
                                      <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                      />
                                      <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_581_537"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_581_537"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                              </div>
                            )}
                          <img
                            className="mainMenuTileIcon"
                            src={handleMenuIcon(
                              item[0].menuName,
                              item[0].isMenuActive
                            )}
                            alt={item[0].menuDisplayName}
                          />
                          <label
                            className={`cursor sideLabel ${
                              !item[0]?.isMenuActive && "inactiveMenu"
                            }`}
                          >
                            {item[0].menuDisplayName}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
            {subMenuList &&
              subMenuList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`firstMenu ${
                      activeMenuID === item[0][0].menuId &&
                      handleActiveFirstMenu(item[0][0].menuName)
                    }`}
                  >
                    <div className="firstMenuTileMasterContainer">
                      <div class="mainMenuHorizontalLine"></div>
                      <div className="firstMenuTileContainer">
                        {item
                          ?.slice()
                          ?.sort(
                            (a, b) =>
                              parseFloat(a[0]?.subMenuOrder) -
                              parseFloat(b[0]?.subMenuOrder)
                          )
                          ?.map((subItem, subIndex) => {
                            let flag = subItem.length > 1 ? true : false;
                            return (
                              <>
                                {subItem[0].isSubMenuActive && (
                                  <a
                                    href={`/${subItem[0].menuName
                                      ?.replaceAll(" ", "")
                                      ?.replaceAll("&", "")
                                      ?.toLowerCase()}/${subItem[0].subMenuName
                                      ?.replaceAll(" ", "")
                                      ?.replaceAll("&", "")
                                      ?.toLowerCase()}`}
                                    style={{
                                      textDecoration: "none",
                                      width: "100%",
                                    }}
                                    onClick={
                                      subItem[0]?.isMenuActive
                                        ? (e) => {
                                            e.preventDefault();
                                            navigate(
                                              `/${subItem[0].menuName
                                                ?.replaceAll(" ", "")
                                                ?.replaceAll("&", "")
                                                ?.toLowerCase()}/${subItem[0].subMenuName
                                                ?.replaceAll(" ", "")
                                                ?.replaceAll("&", "")
                                                ?.toLowerCase()}`
                                            );
                                            dispatch(closeMenu());
                                          }
                                        : null
                                    }
                                  >
                                    <div
                                      key={subIndex}
                                      className={`firstMenuTile cursor ${
                                        activeSubMenuID ===
                                          subItem[0].subMenuId &&
                                        handlesubMenuHoverStyle(
                                          subItem[0].menuName
                                        )
                                      } ${
                                        !subItem[0]?.isMenuActive &&
                                        "inactiveMenu"
                                      }`}
                                      onMouseEnter={() =>
                                        subMenuActiveHandler(
                                          subItem[0].subMenuId
                                        )
                                      }
                                    >
                                      <label
                                        className={`cursor ${
                                          !subItem[0]?.isMenuActive &&
                                          "inactiveMenu"
                                        }`}
                                      >
                                        {subItem[0].subMenuDisplayName}
                                      </label>
                                      {flag ? (
                                        <img
                                          src={ForwardArrow}
                                          alt="ForwardArrow"
                                        />
                                      ) : null}
                                    </div>
                                  </a>
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                    <div className="verticalLine singlePixel" />
                  </div>
                );
              })}
            {featureMenuList &&
              featureMenuList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`secondMenu ${
                      activeSubMenuID === item[0].subMenuId &&
                      handleActiveSecondMenu(item[0].menuName)
                    }`}
                  >
                    <div className="firstMenuTileContainer">
                      <div class="mainMenuHorizontalLine"></div>
                      {item
                        ?.slice()
                        ?.sort(
                          (a, b) =>
                            parseFloat(a?.featureOrder) -
                            parseFloat(b?.featureOrder)
                        )
                        .map((feature, featureIndex) => {
                          return (
                            feature.isFeatureActive && (
                              <a
                                href={`${feature?.menuName
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll("&", "")
                                  .toLowerCase()}/${feature.subMenuName
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll("&", "")
                                  .toLowerCase()}/${feature.featureName
                                  ?.replaceAll(" ", "")
                                  ?.replaceAll("&", "")
                                  .toLowerCase()}`}
                                style={{
                                  textDecoration: "none",
                                  width: "100%",
                                }}
                                onClick={
                                  feature?.isMenuActive
                                    ? (e) => {
                                        e.preventDefault();
                                        navigate(
                                          `${feature.menuName
                                            ?.replaceAll(" ", "")
                                            ?.replaceAll("&", "")
                                            .toLowerCase()}/${feature.subMenuName
                                            ?.replaceAll(" ", "")
                                            ?.replaceAll("&", "")
                                            .toLowerCase()}/${feature.featureName
                                            ?.replaceAll(" ", "")
                                            ?.replaceAll("&", "")
                                            .toLowerCase()}`
                                        );
                                        dispatch(closeMenu());
                                      }
                                    : null
                                }
                              >
                                <div
                                  key={featureIndex}
                                  className={`firstMenuTile ${handlefeatureHoverStyle(
                                    feature.menuName
                                  )} ${
                                    !feature.isMenuActive && "inactiveMenu"
                                  }`}
                                >
                                  <label
                                    className={`${
                                      !feature.isMenuActive && "inactiveMenu"
                                    }`}
                                  >
                                    {feature.featureDisplayName}
                                  </label>
                                </div>
                              </a>
                            )
                          );
                        })}
                    </div>
                    <div className="verticalLine singlePixel" />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="verticalLine" />
      </div>
      <div className="sidebarBackground" onClick={() => dispatch(closeMenu())}>
        {comingSoonActive && (
          <h4 style={{ fontSize: "72px", marginRight: "102px" }}>
            {"Coming Soon..."}
          </h4>
        )}
        {paidActive && (
          <div
            style={{
              fontSize: "72px",
              marginRight: "72px",
              position: "absolute",
              left: "122px",
              bottom: "30px",
            }}
          >
            <button
              className="primaryButton button"
              onClick={() =>
                window.open(`https://aesssolutions.com/pricing.html`)
              }
            >
              Upgrade
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export { SideNavigation };
