/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Vehiclepic from "../../Assets/Vehiclepic.svg";
import OfficehousekeepingPic from "../../Assets/OfficehousekeepingPic.svg";
import OfficehotelsPic from "../../Assets/OfficehotelsPic.svg";
import OfficemeetingroomPic from "../../Assets/OfficemeetingroomPic.svg";
import "./OfficeMaster.css";
import { useLocation, useNavigate } from "react-router-dom";
import { HouseKeeping } from "./Components/HouseKeeping";
import { MeetingRoom } from "./Components/MeetingRoom";
import { Vehicle } from "./Components/Vehicle";
import { Hotels } from "./Components/Hotels";
import { CreateVehicle } from "./Components/CreateVehicle";
import { CreateCategories } from "./Components/CreateCategories";
import { CreateHotels } from "./Components/CreateHotels";
import { CreateMeetingRoom } from "./Components/CreateMeetingRoom";
import { CreateTypes } from "./Components/CreateTypes";
import { ModuleMasterDetailsPage } from "../HRMSMaster/Components";
import { DetailsMeeting } from "./Components/DetailsMeeting";
import { GroupBy } from "../../Utilities";
import { useDispatch } from "react-redux";
import { loggedOut } from "../../Features";
import Skeleton from "react-loading-skeleton";
import { getRequest, postRequest } from "../../Services/axios";

const OfficeMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("");
  const [activeFeature, setActiveFeature] = useState("");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "office"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {
             
          }
        });
      }
    } catch (err) {
       
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("officemaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "officemaster");
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
  }, [location.pathname, subMenuList]);
  const getFeatureMenu = (submenu) => {
    if (!subMenuList) return null;
    return subMenuList?.find(
      (obj) =>
        obj?.[0]?.subMenuName
          ?.replaceAll(" ", "")
          ?.replaceAll("&", "")
          ?.toLowerCase() === submenu
    );
  };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "meetingroom") return OfficemeetingroomPic;
    else if (subMenuName === "vehicle") return OfficehotelsPic;
    else if (subMenuName === "hotels") return OfficehousekeepingPic;
    else if (subMenuName === "housekeeping") return Vehiclepic;
    return OfficemeetingroomPic;
  };

  return (
    <div className="moduleContainer">
      {activeSubmodule === "housekeeping" && (
        <>
          {activeFeature === "categories" && (
            <>
              {createActive && <CreateCategories title={"Create Categories"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "types" && (
            <>
              {createActive && <CreateTypes title={"Create Types"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "hotels" && (
        <>
          {createActive && <CreateHotels title={"Create Hotels"} />}
          {detailsActive && <ModuleMasterDetailsPage />}
        </>
      )}
      {activeSubmodule === "meetingroom" && (
        <>
          {createActive && <CreateMeetingRoom title={"Create Meeting Room"} />}
          {detailsActive && <DetailsMeeting />}
        </>
      )}
      {activeSubmodule === "vehicle" && (
        <>
          {createActive && <CreateVehicle title={"Create Vehicle"} />}
          {detailsActive && <ModuleMasterDetailsPage />}
        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/officemaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "meetingroom" ? (
              <MeetingRoom
                title={"Meeting room"}
                featureMenu={getFeatureMenu("meetingroom")}
              />
            ) : null}
            {activeSubmodule === "hotels" ? (
              <Hotels title={"Hotels"} featureMenu={getFeatureMenu("hotels")} />
            ) : null}
            {activeSubmodule === "vehicle" ? (
              <Vehicle
                title={"Vehicle"}
                featureMenu={getFeatureMenu("vehicle")}
              />
            ) : null}
            {activeSubmodule === "housekeeping" ? (
              <HouseKeeping
                title={"HouseKeeping"}
                featureMenu={getFeatureMenu("housekeeping")}
              />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};
export { OfficeMaster };
