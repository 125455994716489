import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";

import { isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import { DatePickerCustom } from "../../../Components/FormComponent/DatePickerCustom";
import { DatePickerFormiks } from "../../../Components/FormComponent/DatePickerFormiks";
import { format } from "date-fns";

export const SendCouriorDispatch = ({ data, type, id, color, handleClose }) => {
  const validationSchema = Yup.object({
    dispatchDate: Yup.date().required("Please Select Date And Time"),
    trackingNumber: Yup.string().required("Please Enter Tracking Number"),
    deliveryServiceProvider: Yup.string().required(
      "Please Enter Delivery Service Provider"
    ),
  });
  const dispatch = useDispatch();
  const [detailsData, setDetailsData] = useState(false);
  const [hotels, setHotels] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(true);

  useEffect(() => {
    document.title = `PeopleSol - Send Courier`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const onSubmit = async (values) => {
    data(
      type,
      id,
      "Remark",
      format(values?.dispatchDate, "yyyy-MM-dd'T'HH:mm:ss"),
      // values.dispatchDate,
      values.trackingNumber,
      values.deliveryServiceProvider,
      values.rate
    );
    // approveapi(listId.id, values.updatedHotelId);
    handleClose();
  };

  // formValues.preferredHotel = detailsData?.hotel?.name;
  // console.log("values hospitality approve ", formValues);
  if (loading) return "";
  else
    return (
      <Formik
        initialValues={{
          dispatchDate: "",
          trackingNumber: "",
          deliveryServiceProvider: "",
          rate: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <LabelCustom labelName={"Dispatch Date"} mandatory={true} />
          <DatePickerFormiks name="dispatchDate" showTimeSelect />
          <LabelCustom labelName={"Tracking Number"} mandatory={true} />
          <InputCustomFormik name="trackingNumber" />
          <LabelCustom
            labelName={"Delivery Service Provider"}
            mandatory={true}
          />
          <InputCustomFormik name="deliveryServiceProvider" />
          <LabelCustom labelName={"Cost"} />
          <InputCustomFormik name="rate" />

          <div className="createFootCust">
            <button
              type="button"
              className="button secondaryButton"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="button primaryButton"
              type="submit"
              style={{ background: color, color: "white" }}
            >
              {type}
            </button>
          </div>
        </Form>
      </Formik>
    );
};
