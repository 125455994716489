/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { CreateCompOffComp } from "./FormComp/CreateCompOffComp";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";

const CreateCompOff = (props) => {
  const { state } = useLocation();

  const data = state?.id;
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();

  const [detailsform, setDetailsform] = useState(null);
  const [bannerList, setBannerList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [compList, setCompList] = useState(null);
  const location = useLocation();

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("CompoffRequests/Create", values);

      dispatch(
        showToast({
          text: "Comp off Request has been created successfully",
          severity: "success",
        })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: "Sorry, You are already created the request with the same date!",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Comp Off`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const Edit = async (values) => {
    try {
      const result = await postRequest("CompoffRequests/Edit", values);

      if (result) {
        if (result.data.isSuccess === false) {
          dispatch(showToast({ text: result.data.message, severity: "error" }));
        } else {
          dispatch(
            showToast({ text: result.data.message, severity: "success" })
          );
        }
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    }
  };

  const getProfileData = async () => {
    try {
      const result = await getRequest("Employees/GetBannerProfile");
      setBannerList(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    if (location.pathname.toLowerCase().includes("create")) getProfileData();
  }, [location.pathname]);

  const getId = bannerList?.Id;
  const dateStart = dateFormat(formValues?.date, "yyyy-mm-dd");

  const getAttendanceData = async () => {
    try {
      const result = await getRequest(
        `CompoffRequests/GetData?StartDate=${dateStart}&UserId=${getId}`
      );
      setCompList(result?.data);
    } catch (error) {
    } finally {
    }
  };
  React.useEffect(() => {
    if (formValues?.date) {
      getAttendanceData();
    }
  }, [formValues?.date]);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`CompoffRequests/Details/${data}`);
     // console.log("CompoffRequestsrequests", result);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      getFormDetails();
    }
  }, []);

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  const initialvalue = {
    date: compList ? formValues?.date : "",
    type: "Weekly Off",
    from: "",
    to: "",
    reason: "",
    starttime: compList?.starttime,
    endtime: compList?.endtime,
  };

  const validationSchema = Yup.object({
    date: Yup.date()
      .max(
        new Date(),
        `Date field must be earlier than ${dateFormat("dd-mm-yyyy")}`
      )
      .required("Please enter the Date"),
    type: Yup.string().required("Please select Type"),
    from: Yup.string().required("Please enter the From Time"),
    to: Yup.string().required("Please enter the To Time"),
    reason: Yup.string()
      .max(499, "Reason must be less than 500 characters")
      .required("Please enter the Reason"),
    starttime: Yup.string().required("Try another date"),
    endtime: Yup.string().required("Try another date"),
  });

  const onSubmit = (values) => {
    const edit = {
      Id: detailsform?.data?.id,
      Date: dateFormat(values?.date, "yyyy-mm-dd"),
      From: dateFormat(values?.from, "h:MM TT"),
      To: dateFormat(values?.to, "h:MM TT"),
      Type: values?.type,
      Reason: values?.reason,
    };

    const create = {
      ...values,
      id: 0,
      date: dateFormat(values?.date, "yyyy-mm-dd"),
      type: values?.type,
      from: dateFormat(values?.from, "h:MM TT"),
      to: dateFormat(values?.to, "h:MM TT"),
      reason: values?.reason,
      starttime: compList?.starttime,
      endtime: compList?.endtime,
    };

    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  return (
    <FormCustomFormik
      loading={loading}
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Update Comp Off Request" : props.title}
      ButtonLabel={data ? "Update Comp Off Request" : "Create Comp Off Request"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        detailsform
          ? {
              ...initialvalue,

              date: detailsform?.data?.date
                ? new Date(detailsform?.data?.date)
                : "",
              type: detailsform?.data?.type,
              to: detailsform?.data?.to
                ? new Date(`12/12/12 ${detailsform?.data?.to}`)
                : "",
              from: detailsform?.data?.from
                ? new Date(`12/12/12 ${detailsform?.data?.from}`)
                : "",
              reason: detailsform?.data?.reason,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        <CreateCompOffComp formValues={formValues} apiValues={compList} />
      }
    />
  );
};

export { CreateCompOff };
