/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../../Services/axios";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { Loader } from "../../../../../Components/FormComponent/Loader";
// export const StyledTableCell = styled(TableCell)({
//     padding: "5px",
//     border: "1px solid #bdbdbd5c",
//   });

const type = [
  { label: "Temporary", value: "Temporary" },
  { label: "Permanent", value: "Permanent" },
];

const CreateFacilitiesComp = ({ formValues }) => {
  const [option, setOption] = useState([]);
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  useEffect(() => {
    document.title = `PeopleSol - Create Facilities `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getFacility = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        "FacilityCategories/GetFacilityCategories"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setEmployeeList(listData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getLocation = async () => {
    try {
      setLoading(true);
      const result = await getRequest("LeaveTypes/GetLocationName");
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setLocation(listData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
 
  const getOption = async () => {
    try {
      setLoading(true);
      const result = await getRequest("RelocationRequests/GetDepartments?i=1");
      let listData = [];
      result?.data?.data?.results.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFacility();
    getOption();
    getLocation();
  }, []);
  return (
    <>
      <div className="formcustom">
        <div>
          <LabelCustom labelName={"Select Facility"} mandatory={true} />
          <SelectForm
            name={"facilityCategoryId"}
            options={employeeList}
            placeholder={" Select Facility"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Need for"} mandatory={true} />
          <SelectForm name={"type"} options={type} placeholder={" Select Type "} />
        </div>
          {formValues?.type!=="Permanent"&&
          <>
           <div>
           <LabelCustom labelName={"From Date "} mandatory={true} />
           <DatePickerFormiks name={"fromDate"} placeholder={" Select Date "} maxDate={formValues?.toDate}/>
         </div>
         <div>
           <LabelCustom labelName={"To Date  "} mandatory={true} />
           <DatePickerFormiks name={"toDate"} placeholder={" Select Date "} minDate={formValues?.fromDate}/>
         </div>
          </>
 
          }
        <div>
          <LabelCustom labelName={"Reason "} mandatory={true} />
          <TextAreaFormik name={"reason"}  maxLength={500} placeholder={" Enter Reason  "} />
        </div>
      </div>
    </>
  );
};

export { CreateFacilitiesComp };
