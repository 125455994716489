import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from 'react-redux';
import { showToast, isLoading } from '../../../Features';
import { postRequest } from '../../../Services/axios';
import { useLocation } from "react-router-dom";
import { CreateQuestionSetComp } from "./FormComp/CreateQuestionSetComp";
import { useEffect } from "react";





const CreateQuestionSet = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Question Sets`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const { state } = useLocation();

  const data = state?.dataEdit;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const createPageData = async (values) => {
    try {

      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest('TrainingQuestionsSets/Create', values);
      dispatch(showToast({ text: "Successfully Created", severity: "success" }));

    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "TrainingQuestionsSets/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) { }
  };


  const initialvalue = {
    name: ""
  };
  const validationSchema = Yup.object({
    name: Yup.string().max(149," Name must be less than 150 characters").required("Please Enter Name")
  });

  const onSubmit = (values) => {

    const edit = {
      ...values,
      Id: data?.Id
    };

    const create = {
      ...values,
      Id: 0
    }
    data ? Edit(edit) : createPageData(create);
    returnPage()
  }


  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };
  return (
    <FormCustomFormik FormHeading={data?'Edit Question Sets':'Create Question Sets'} ButtonLabel={data ? 'Update Question Sets' : 'Create Question Sets'} onSubmit={onSubmit} validationSchema={validationSchema} initialvalue={
      data
        ? {
          ...initialvalue,
          name: data?.Name,
          parentCategoriesId: data?.Parentcategory,
          color: data?.Color,
          isDisplayInCandidatePage: data?.IsDisplayInCandidatePage === true ? "Yes" : "No",
        }
        : initialvalue
    }
      returnPage={returnPage} propsDiv={<CreateQuestionSetComp data={data} />} />
  );
};

export { CreateQuestionSet };