/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, Form } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../../Services/axios";

const AddAssignee = ({ data, id, color,type ,typeId }) => {
  const [Employee, setEmployee] = useState([]);
  const getEmployee = async () => {
    try {
      const result = await getRequest(`HrHelpdesks/GetTeamEmployees?ID=${typeId}`);
      let listData = [];
      result?.data?.data?.results?.map((item) => {
      return  listData?.push({ label: item.text, value: item.id });
      });
      setEmployee(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Add Assignee`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const onSubmit = async (values) => {
    const value = { ...values, TicketId: id };
    data(value);
  };
  useEffect(() => {
    getEmployee();
  }, []);
  return (
    <Formik
    enableReinitialize
      initialValues={{ AssgineID:type==="Update Assignee"?color:"" }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <div>
            <div>
              <LabelCustom labelName={"Select Assignee"} mandatory={true} />
              <SelectForm name="AssgineID" options={Employee} />
            </div>
          </div>
          <div className="createFootCust">
            <div>
              <div
                className="button secondaryButton"
                onClick={() => formik.resetForm({ values:{AssgineID:""} })}
              >
                Reset
              </div>
            </div>
            <div>
              <button
                className="button primaryButton"
                type="submit"
                style={{  color: "white" }}
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { AddAssignee };
