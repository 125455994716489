import React from "react";
import { HRDeskTab } from "./HRDeskComponents/HRDeskTab";
import { useEffect } from "react";
const HRDesk = () => {
  useEffect(() => {
    document.title = `PeopleSol - HR Desk`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <HRDeskTab title={"HR Desk "} />
    </div>
  );
};
export { HRDesk };
