/* eslint-disable no-undef */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../../../Features";
import { getRequest } from "../../../../../Services/axios";
import { DetailsStep } from "../../../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../../../Utilities/getStatusColor";
const DetailsPageFacilitiesAllocatedMine = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };
  const closeDetails = () => {
    returnPage();
  };
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);

  const getDetails = async () => {
    try {
      setLoading(true);
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `FacilityRequests/AllDetails?ID=${selectedId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Details Page Facilitiest Allocated`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // //console.log("data", data);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>My Facilites Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            <DetailsStep
              detailTitle={"Facility Reference Number "}
              Details={data?.assets?.id}
            />
            <DetailsStep
              detailTitle={"Status"}
              Details={
                <div
                  style={{
                    backgroundColor: getStatusColor(
                      data?.assets?.category?.status
                    )?.split(",")?.[0],
                    color: getStatusColor(
                      data?.assets?.category?.status
                    )?.split(",")?.[1],
                    cursor: "default",
                    padding: "6px",
                    borderRadius: "6px",
                    width: "fit-content",
                  }}
                >
                  {data?.assets?.status}
                </div>
              }
            />
            <DetailsStep
              detailTitle={"Facility  Category "}
              Details={data?.assets?.category?.name}
            />
            <DetailsStep
              detailTitle={" Facility Material"}
              Details={data?.assets?.facilityMaterial?.name}
            />
            <DetailsStep
              detailTitle={" Facility Description"}
              Details={data?.assets?.description}
            />
            <DetailsStep
              detailTitle={"Created On"}
              Details={data?.assets?.category?.createdOn?.replace("T", " ")}
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={data?.assets?.category?.lastUpdatedOn?.replace("T", " ")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsPageFacilitiesAllocatedMine };
