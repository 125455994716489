import React from "react";
import { FileUploadFormik } from "../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../Components/FormComponent/LabelCustom";
import UploadDetail from "../../../Components/UploadDetail/UploadDetail";
import { useEffect } from "react";

const SubmissionDetails = ({ formFields, detailsform, formValues }) => {
  const rentDetails = [
    { label: "Start Date", value: "StartDate" },
    { label: "End Date", value: "EndDate" },
    { label: "Amount", value: "Amount" },
    {
      label: "LandLord Address Of Property",
      value: "LandLordAddressOfProperty",
    },
    { label: "Landlord Name", value: "tenantName" },
    { label: "is Metro", value: "isMetro" },
    { label: "Landlord Pan", value: "LandlordPan" },
  ];
  const homeLoanDetails = [
    { label: "House Loan Principal", value: "HouseLoanPrincipal" },
    { label: "House Loan Interest", value: "HouseLoanInterest" },
  ];
  const rentalPropertyDetails = [
    { label: "Rent Received", value: "rentReceived" },
    { label: "Tax Paid", value: "taxPaid" },
  ];
  const isRentDetailsEmpty = rentDetails.every(
    (item) => !formFields[item.value]
  );
  useEffect(() => {
    document.title = `PeopleSol -  Submission`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
    {detailsform?.houseRentPaid  &&
      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>House Rent Details</label>
        </div>

        <>
          <div className="detailsBodyViewer">
            <label style={{ width: "49%", flex: "4" }}>House Rent Amount</label>
            <label
              style={{
                
                lineHeight: "16px",
                paddingBottom: "10px",
                flex: "3",
              }}
            >
              {detailsform?.houseRentPaid}
            </label>
            <label
              style={{
                
                flex: "3",
                alignItems: "center",
              }}
            >
              <UploadDetail
                filepath={`tax/${detailsform?.uploadHouseRentPaid}`}
              />
            </label>
          </div>
        </>
      </div>
      }
    {detailsform?.houseLoanInterest  &&
      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>Home Loan Details</label>
        </div>
        <>
          <div className="detailsBodyViewer">
            <label style={{ width: "49%", flex: "4" }}>Interest paid on Home Loan</label>
            <label
              style={{
                
                lineHeight: "16px",
                paddingBottom: "10px",
                flex: "3",
              }}
            >
              {detailsform?.houseLoanInterest}
            </label>
            <label
              style={{
                
                flex: "3",
                alignItems: "center",
              }}
            >
            </label>
          </div>
        </>
      </div>
      }
      {(detailsform?.dividendIncome || detailsform?.interestfromsavingaccount || detailsform?.interestfromfixeddeposit || detailsform?.otherIncome ) &&

      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>
            Income From Sources Other Than Salary
          </label>
        </div>

        <>
          {detailsform?.dividendIncome && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>Dividend Income</label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.dividendIncome}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              ></label>
            </div>
          )}
          {detailsform?.interestfromsavingaccount && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Interest from saving account
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.interestfromsavingaccount}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              ></label>
            </div>
          )}
          {detailsform?.interestfromfixeddeposit && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Interest from fixed Deposit
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.interestfromfixeddeposit}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              ></label>
            </div>
          )}
          {detailsform?.otherIncome && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Any other income(other than rent)
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.otherIncome}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              ></label>
            </div>
          )}
        </>
      </div>
      }
      {(detailsform?.carReimpursement || detailsform?.driverSalaryReimpursement || detailsform?.booksAndPeriodicals || detailsform?.gadgetReimpursement || detailsform?.medicalExpensesReimpursement) &&

      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>
            Reimpursement forming part of salary
          </label>
        </div>

        <>
          {detailsform?.carReimpursement && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Car Reimpursement
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.carReimpursement}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.carReimpursementUpload}`}
                />
              </label>
            </div>
          )}
          {detailsform?.driverSalaryReimpursement && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Driver Salary Reimpursement
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.driverSalaryReimpursement}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.driverSalaryUpload}`}
                />
              </label>
            </div>
          )}
          {detailsform?.booksAndPeriodicals && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Books and Periodicals
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.booksAndPeriodicals}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.booksandPeriodicalsUpload}`}
                />
              </label>
            </div>
          )}
          {detailsform?.gadgetReimpursement && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Gadget Reimpursement
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.gadgetReimpursement}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail filepath={`tax/${detailsform?.gadgetUpload}`} />
              </label>
            </div>
          )}
          {detailsform?.medicalExpensesReimpursement && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Medical Expenses Reimpursement
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.medicalExpensesReimpursement}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.medicalReimpursementUpload}`}
                />
              </label>
            </div>
          )}
        </>
      </div>
      }
      {(detailsform?.termDeposit || detailsform?.educationFee || detailsform?.sukanyaSamriddhiAccount || detailsform?.savingScheme|| detailsform?.mutualFund || detailsform?.insurance || detailsform?.ppf || detailsform?.houseLoanPrincipal) &&

      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>INVESTMENTS U/S 80C, 80CC</label>
        </div>

        <>
          {detailsform?.houseLoanPrincipal && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Housing Loan Repayment Principle
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.houseLoanPrincipal}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.housingLoanUpload}`}
                />
              </label>
            </div>
          )}
          {detailsform?.ppf && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Public Provident Fund(PPF)
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.ppf}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail filepath={`tax/${detailsform?.ppfupload}`} />
              </label>
            </div>
          )}
          {detailsform?.insurance && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Insurance Premium
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.insurance}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.insuranceUpload}`}
                />
              </label>
            </div>
          )}
          {detailsform?.mutualFund && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>Mutual Fund</label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.mutualFund}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.uploadMutualFund}`}
                />
              </label>
            </div>
          )}
          {detailsform?.savingScheme && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                National Saving Scheme / Certificate
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.savingScheme}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.uploadSavingScheme}`}
                />
              </label>
            </div>
          )}
          {detailsform?.sukanyaSamriddhiAccount && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Sukanya Samriddhi Account
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.sukanyaSamriddhiAccount}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.samriddhiAccountUpload}`}
                />
              </label>
            </div>
          )}
          {detailsform?.educationFee && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Children Education Fees (only School tuition fees)
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.educationFee}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.uploadEducationFee}`}
                />
              </label>
            </div>
          )}
          {detailsform?.termDeposit && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Allowable Term Deposit
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.termDeposit}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.allowableTermUpload}`}
                />
              </label>
            </div>
          )}
        </>
      </div>
      }
      {(detailsform?.employeesContribution) &&

      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>INVESTMENTS U/S 80CCD</label>
        </div>

        <>
          <div className="detailsBodyViewer">
            <label style={{ width: "49%", flex: "4" }}>
              National Pension Scheme
            </label>
            <label
              style={{
                
                lineHeight: "16px",
                paddingBottom: "10px",
                flex: "3",
              }}
            >
              {detailsform?.employeesContribution}
            </label>
            <label
              style={{
                
                flex: "3",
                alignItems: "center",
              }}
            >
              <UploadDetail
                filepath={`tax/${detailsform?.employeeContribution80ccdUpload}`}
              />
            </label>
          </div>
        </>
      </div>
      }
      {( detailsform?.insurance80d || detailsform?.expenditure80ddb || detailsform?.deduction80dd) &&
      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>INVESTMENTS U/S 80D</label>
        </div>

        <>
          {detailsform?.insurance80d && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Medical Insurance Premium for Senior Citizen
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.insurance80d}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.uploadInsurance80d}`}
                />
              </label>
            </div>
          )}
          {detailsform?.expenditure80ddb && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Expenditure on Medical Treatment for specified disease
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.expenditure80ddb}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.uploadExpenditure80ddb}`}
                />
              </label>
            </div>
          )}
          {detailsform?.deduction80dd && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Deduction in maintenance of medical treatment of a handicapped
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.deduction80dd}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.maintenanceUpload}`}
                />
              </label>
            </div>
          )}
        </>
      </div>
      }
      {(detailsform?.education80e) &&
      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>INVESTMENTS U/S 80E</label>
        </div>

        <>
          {detailsform?.education80e && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Repayment of loan taken for Higher Education
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.education80e}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.uploadEducation80e}`}
                />
              </label>
            </div>
          )}
        </>
      </div>
}
      {/* <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>Any other Investment qualify for IT Deduction</label>
        </div>

        <>
          <div className="detailsBodyViewer">
            <label style={{ width: "49%", flex: "4" }}>National Pension Scheme</label>
            <label
              style={{
                
                lineHeight: "16px",
                paddingBottom: "10px",
                flex: "3",
              }}
            >
              {detailsform?.employeesContribution}
            </label>
            <label
              style={{
                
                flex: "4",
                alignItems: "center",
              }}
            >
              <UploadDetail
                filepath={`tax/${detailsform?.fileUploadEmployeeContribution}`}
              />
            </label>
          </div>
        </>
      </div> */}
        {(detailsform?.leaveTravelAssisatant || detailsform?.ppd80u || detailsform?.otherInvestmentQualify) &&
      <div className="particularDetailsViewer">
        <div className="detailsHeadingViewer">
          <label style={{ fontWeight: "500" }}>INVESTMENTS U/S 80D</label>
        </div>

        <>
          {detailsform?.leaveTravelAssisatant && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Leave Travel Assistance (LTA)
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.leaveTravelAssisatant}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail filepath={`tax/${detailsform?.leaveUpload}`} />
              </label>
            </div>
          )}
          {detailsform?.ppd80u && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Permanent Physical Disability
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.ppd80u}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.physcialDisabiliyUpload}`}
                />
              </label>
            </div>
          )}
          {detailsform?.otherInvestmentQualify && (
            <div className="detailsBodyViewer">
              <label style={{ width: "49%", flex: "4" }}>
                Any other Investment
              </label>
              <label
                style={{
                  
                  lineHeight: "16px",
                  paddingBottom: "10px",
                  flex: "3",
                }}
              >
                {detailsform?.otherInvestmentQualify}
              </label>
              <label
                style={{
                  
                  flex: "3",
                  alignItems: "center",
                }}
              >
                <UploadDetail
                  filepath={`tax/${detailsform?.otherInvestmentQualifyUpload}`}
                />
              </label>
            </div>
          )}
        </>
      </div>
      }
       
    </>
  );
};

export { SubmissionDetails };
