import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { StepsHierarchy } from "../../../Components/FormComponent/StepsHierarchy";
import { WorkflowStepsComp } from "../../../Components/FormComponent/WorkflowStepsComp";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { format } from "date-fns";
import { Loader } from "../../../Components/FormComponent/Loader";

const DetailsShift = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Shifts/Details/${selectedId}`);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Details Shift`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  // //console.log("eventtData", eventtData);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
        <div className="moduleMasterDetailsPageBody">
          {/* <div className="moduleMasterDetailsBox"> */}
          <DetailsStep detailTitle={"Name"} Details={eventtData?.title} />
          <DetailsStep
            detailTitle={"Shift Code"}
            Details={eventtData?.shiftCode}
          />
          <DetailsStep
            detailTitle={"Is Active?"}
            Details={eventtData?.isActive === true ? "Yes" : "No"}
          />
          <DetailsStep
            detailTitle={"Week Off Days"}
            Details={eventtData?.weekOffDays}
          />
          <DetailsStep detailTitle={"Location "} Details={eventtData?.area} />
          <DetailsStep
            detailTitle={"Created On"}
            Details={eventtData?.createdOn && format(
              new Date(eventtData?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={eventtData?.createdOn && format(
              new Date(eventtData?.lastUpdatedOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
          {eventtData?.weekDays?.length > 0 && (
            <>
              <div>
                <h5 style={{ margin: "10px 0px" }}>Week Days</h5>
              </div>
              <div style={{ width: "100%" }}>
                <table className="tabless tabless-bordered">
                  <thead
            
                    style={{
                      backgroundColor: "var(--primary-hover)",
                    }}
                  >
                    <tr>
                      <td>S.no</td>
                      <td>Title</td>
                      <td>Days</td>

                      <td>From</td>
                      <td>To </td>
                      {/* <td>Duration</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {eventtData?.weekDays?.length > 0
                      ? eventtData?.weekDays?.map((item, index) => {
                          return (
                            <tr className="trStyle">
                              <td>{index + 1}</td>
                              <td>{item.title}</td>
                              <td>{item.days}</td>
                              <td>
                                {item.fromTime
                                  ? item.fromTime !== ""
                                    ? format(
                                        new Date(item.fromTime),
                                        "hh:mm a"
                                      )
                                    : null
                                  : null}
                              </td>
                              <td>
                                {item.toTime
                                  ? item.toTime !== ""
                                    ? format(
                                        new Date(item.toTime),
                                        "hh:mm a"
                                      )
                                    : null
                                  : null}
                              </td>
                            </tr>
                          );
                        })
                      : "No Data Found"}
                  </tbody>
                </table>
              </div>
            </>
          )}
          {eventtData?.additionalWeekoffs?.length > 0 && (
            <>
              <div>
                <h5 style={{ margin: "10px 0px" }}>Additional Weekoffs</h5>
              </div>
              <div style={{ width: "100%" }}>
                <table className="tabless tabless-bordered">
                  <thead
            
                    style={{
                      backgroundColor: "var(--primary-hover)",
                    }}
                  >
                    <tr>
                      <td>S.no</td>
                      <td>Date</td>
                    </tr>
                  </thead>
                  <tbody>
                    {eventtData?.additionalWeekoffs?.length > 0
                      ? eventtData?.additionalWeekoffs?.map((item, index) => {
                          return (
                            <tr className="trStyle">
                              <td>{index + 1}</td>
                              <td>
                                {item.weekOffDates
                                  ? item.weekOffDates !== ""
                                    ? format(
                                        new Date(item.weekOffDates),
                                        "dd/MM/yyyy"
                                      )
                                    : null
                                  : null}
                              </td>
                            </tr>
                          );
                        })
                      : "No Data Found"}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>)}
      </div>
    </div>
  );
};

export { DetailsShift };
