import React, { useState, useEffect } from "react";
import PayrollmydashboardPic from "../../Assets/PayrollmydashboardPic.svg";
import PayrollcompanydashboardPic from "../../Assets/PayrollcompanydashboardPic.svg";
import PayrollpreparepayrollPic from "../../Assets/PayrollpreparepayrollPic.svg";
import PayrollpayrollapprovalPic from "../../Assets/PayrollpayrollapprovalPic.svg";
import PayrollrunpayrollPic from "../../Assets/PayrollrunpayrollPic.svg";
import PayrollsalaryslipPic from "../../Assets/PayrollsalaryslipPic.svg";
import PayrollemployeePic from "../../Assets/PayrollemployeePic.svg";
import PayrollreportskpiPic from "../../Assets/PayrollreportskpiPic.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dashboard } from "./Components/Dashboard";
import { PreparePayroll } from "./Components/PreparePayroll";
import PayrollPrepare from "./Components/PreparePayrollComp/PayrollPrepare";
import CheckAttendance from "./Components/PreparePayrollComp/CheckAttendance";
import CheckSalary from "./Components/PreparePayrollComp/CheckSalary";
import { SalaryStructureDetail } from "./Components/PreparePayrollComp/SalaryStructureDetail";
import { EmployeePayroll } from "./Components/EmployeePayroll";
import EmployeePayrollDetails from "./Components/EmployeePayrollComp/EmployeePayrollDetails";
import { ReviseSalary } from "./Components/EmployeePayrollComp/ReviseSalary";
import { PayrollApproval } from "./Components/PayrollApproval/PayrollApproval";
import SalaryMapEmployee from "./Components/EmployeePayrollComp/SalaryMapEmployee";
import { LoanDetails } from "./Components/EmployeePayrollComp/LoanDetails";
import { RunPayroll } from "./Components/RunPayroll/RunPayroll";
import PayrollRun from "./Components/RunPayroll/PayrollRun";
import { SalarySlipList } from "./Components/SalarySlip/SalarySlipList";
import SalarySlip from "./Components/SalarySlip/SalarySlip";
import { CompanyDashboard } from "./Components/CompanyDashboard";
import { PayrollReports } from "./Components/PayrollReports";
import DetailsReports from "./Components/ReportsKpi/DetailsReports";
import PrepareIncentive from "./Components/PreparePayrollComp/PrepareIncentive";
import PayrollApprovalDetails from "./Components/PayrollApproval/PayrollApprovalDetails";
const Payroll = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { subMenuList } = useSelector((state) => state.menu);
  const [submoduleMenu, setSubmoduleMenu] = useState(null);
  useEffect(() => {
    if (subMenuList) {
      subMenuList.map((menu) => {
        if (
          menu[0][0]?.menuName
            ?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase() === "payroll"
        )
          setSubmoduleMenu(menu);
        return null;
      });
    }
  }, [subMenuList]);

  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    setCheckAttendanceActive(false);
    setLoanActive(false);
    setReviseSalaryActive(false);
    seteditSalaryActive(false);
    setSalaryStructureActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("payroll")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "payroll");
      if (splitArray?.length > submoduleIndex + 1) {
        if (submoduleMenu) {
          if (
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              submoduleMenu
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        navigate(
          submoduleMenu
            ?.slice()
            ?.filter((x) => x[0].isSubMenuActive)
            ?.sort(
              (a, b) =>
                parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
            )[0][0]
            .subMenuName?.replaceAll(" ", "")
            ?.replaceAll("&", "")
            ?.toLowerCase()
        );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
          setCheckSalaryActive(false);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
          setCheckSalaryActive(false);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (
          splitArray[submoduleIndex + 3] === "details" ||
          splitArray[submoduleIndex + 3] === "incentive"
        ) {
          setDetailsActive(true);
        } else if (
          splitArray[submoduleIndex + 3] === "create" ||
          splitArray[submoduleIndex + 3] === "prepare" ||
          splitArray[submoduleIndex + 3] === "run"
        ) {
          setCreateActive(true);
          setCheckSalaryActive(false);
        } else if (splitArray[submoduleIndex + 3] === "checkattendance") {
          setCheckAttendanceActive(true);
        } else if (splitArray[submoduleIndex + 3] === "loan") {
          setLoanActive(true);
        } else if (splitArray[submoduleIndex + 3] === "checksalary") {
          setCheckSalaryActive(true);
        } else if (splitArray[submoduleIndex + 3] === "revisesalary") {
          setReviseSalaryActive(true);
          setDetailsActive(false);
        } else if (splitArray[submoduleIndex + 3] === "editsalary") {
          seteditSalaryActive(true);
          setDetailsActive(false);
        }
      }
      if (splitArray?.length > submoduleIndex + 4) {
        if (splitArray[submoduleIndex + 3] === "prepare") {
          if (splitArray[submoduleIndex + 4] === "empsalary")
            setSalaryStructureActive(true);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, submoduleMenu]);
  const [activeSubmodule, setActiveSubmodule] = useState("mydashboard");
  const [activeFeature, setActiveFeature] = useState("home");
  const [detailsActive, setDetailsActive] = useState(false);
  const [createActive, setCreateActive] = useState(false);
  const [checkAttendanceActive, setCheckAttendanceActive] = useState(false);
  const [loanActive, setLoanActive] = useState(false);
  const [checkSalaryActive, setCheckSalaryActive] = useState(false);
  const [reviseSalaryActive, setReviseSalaryActive] = useState(false);
  const [editSalaryActive, seteditSalaryActive] = useState(false);
  const [salaryStructureActive, setSalaryStructureActive] = useState(false);
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "mydashboard") return PayrollmydashboardPic;
    else if (subMenuName === "companydashboard")
      return PayrollcompanydashboardPic;
    else if (subMenuName === "preparepayroll") return PayrollpreparepayrollPic;
    else if (subMenuName === "payrollapproval")
      return PayrollpayrollapprovalPic;
    else if (subMenuName === "runpayroll") return PayrollrunpayrollPic;
    else if (subMenuName === "salaryslip") return PayrollsalaryslipPic;
    else if (subMenuName === "employee") return PayrollemployeePic;
    else if (subMenuName === "reportskpi") return PayrollreportskpiPic;
  };
  const getPreparePayroll = () => {
    if (activeSubmodule === "preparepayroll") {
      if (activeFeature === "ongoing")
        if (createActive) return <PayrollPrepare />;
      if (detailsActive) return <PrepareIncentive title='Incentive'/>;
      else if (checkAttendanceActive) return <CheckAttendance />;
      else if (checkSalaryActive) return <CheckSalary />;
      return <PreparePayroll />;
    }
    return null;
  };
  const getpayrollapproval = () => {
    if (activeSubmodule === "payrollapproval") {
      if (detailsActive) return <PayrollApprovalDetails title='Incentive'/>;
      return <PayrollApproval />;
    }
    return null;
  };
  const getRunPayroll = () => {
    if (activeSubmodule === "runpayroll") {
      if (activeFeature === "ongoing") if (createActive) return <PayrollRun />;
      // else if (checkAttendanceActive) return <CheckAttendance />;
      // else if (checkSalaryActive) return <CheckSalary />;
      return <RunPayroll />;
    }
    return null;
  };
  const getSalaryslip = () => {
    if (activeSubmodule === "salaryslip") {
      if (detailsActive) return <SalarySlip />;
      // else if (checkSalaryActive) return <CheckSalary />;
      return <SalarySlipList />;
    }
    return null;
  };
  const getReportsKpi = () => {
    if (activeSubmodule === "reportskpi") {
      if (detailsActive) return <DetailsReports />;
      // else if (checkSalaryActive) return <CheckSalary />;
      return <PayrollReports />;
    }
    return null;
  };
  const getEmployee = () => {
    if (activeSubmodule === "employee") {
      if (editSalaryActive) return <SalaryMapEmployee />;
      else if (reviseSalaryActive) return <ReviseSalary />;
      else if (detailsActive) return <EmployeePayrollDetails />;
      return <EmployeePayroll />;
    }
    return null;
  };
  console.log("activeFeature", activeFeature);
  return (
    <div className="moduleContainer">
      {activeSubmodule === "preparepayroll" && (
        <>
          {activeFeature === "ongoing" && (
            <>{salaryStructureActive && <SalaryStructureDetail />}</>
          )}
        </>
      )}
      {activeSubmodule === "employee" && (
        <>
          <>{loanActive && <LoanDetails />}</>
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {submoduleMenu &&
            submoduleMenu
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )
              ?.map((subMenu, index) => {
                return (
                  <div
                    key={index}
                    className={`menuOptions ${
                      activeSubmodule ===
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase() && "menuOptionsActive"
                    }`}
                    onClick={() => {
                      setActiveSubmodule(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      );
                      navigate(
                        `/payroll/${subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()}`
                      );
                    }}
                  >
                    <div className="verticalLine"></div>
                    <img
                      src={handleMenuIcon(
                        subMenu[0]?.subMenuName
                          ?.replaceAll(" ", "")
                          ?.replaceAll("&", "")
                          ?.toLowerCase()
                      )}
                      alt={subMenu[0]?.subMenuName}
                    />
                    <label>{subMenu[0]?.subMenuDisplayName}</label>
                  </div>
                );
              })}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {activeSubmodule === "mydashboard" ? <Dashboard /> : null}
        {activeSubmodule === "companydashboard" ? <CompanyDashboard /> : null}
        {activeSubmodule === "preparepayroll" ? getPreparePayroll() : null}
        {activeSubmodule === "payrollapproval" ? getpayrollapproval() : null}
        {activeSubmodule === "runpayroll" ? getRunPayroll() : null}
        {activeSubmodule === "salaryslip" ? getSalaryslip() : null}
        {activeSubmodule === "reportskpi" ? getReportsKpi() : null}
        {getEmployee()}
      </div>
    </div>
  );
};

export { Payroll };
