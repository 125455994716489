import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { CategoryForm, ReqCategoryForm } from "./Form/CategoryForm";
import { CategorySchema } from "./Schema";
import { useEffect } from "react";

const CreateCategories = (props) => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const data = state?.id;
  const dispatch = useDispatch();
  const [detail, setDetail] = React.useState([]);
  const postFormData = async (values) => {
    try {
      setLoading(true);

      const result = await postRequest("ExceptionCatalogues/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`ExceptionCatalogues/Details/${data}`);
      setDetail(result.data);
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);
  useEffect(() => {
    document.title = `PeopleSol - Create Category`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const initialvalue = {
    name: data ? detail?.exceptionCatalogue?.name : "",
    type: data ? detail?.exceptionCatalogue?.type : "",
    ownerEmployeeId: data ? detail?.exceptionCatalogue?.ownerEmployeeId : "",
  };
  const Edit = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("ExceptionCatalogues/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    const edit = {
      ...values,
      id: data,
      isActive: true,
    };

    const create = {
      ...values,
    };

    data ? Edit(edit) : postFormData(create);
  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      ButtonLabel={
        data ? "Update Exception Category" : "Create Exception Category"
      }
      FormHeading={
        data ? "Edit Exception Category" : "Create Exception Category"
      }
      validationSchema={CategorySchema}
      onSubmit={onSubmit}
      initialvalue={initialvalue}
      loading={loading}
      returnPage={returnPage}
      propsDiv={<CategoryForm data={detail} editID={data ? true : false} />}
    />
  );
};

export { CreateCategories };
