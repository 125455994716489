import React from "react";
import { CategoriesTab } from "./CategoriesTab";
import { useEffect } from "react";

const Categories = () => {
  useEffect(() => {
    document.title = `PeopleSol - Categories`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMainContainer">
      <CategoriesTab title=" Learning Course" />
    </div>
  );
};

export { Categories };
