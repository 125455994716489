import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getRequest,
  getRequestXlsx,
} from "../../../../../../../Services/axios";
import { showToast } from "../../../../../../../Features";
import ArrowBackButton from "../../../../../../../Assets/ArrowBackButton.svg";
import SurveyDownload from "../../../../../../../Assets/SurveyDownload.svg";
import { IconButton } from "@mui/material";
import Report from "./Report";

function SurveyReportPage() {
  const [surveyReportData, setSurveyReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let { data } = location.state;
  let { type } = location.state;
  let { ansId } = location.state;
  let { title } = location.state;
  const [reportType, setReportType] = useState(type);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const getSurveyDetails = async (t) => {
    setLoading(true);
    try {
      let result = null;
      if (ansId > 0) {
        result = await getRequest(
          `Survey/Reports?ID=${id}&Type=${t}&OptionId=${ansId}`
        );
      } else {
        result = await getRequest(`Survey/Reports?ID=${id}&Type=${t}`);
      }

      setSurveyReportData(result?.data?.summary);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const downloadReport = async () => {
    setLoading(true);
    try {
      let result = null;
      if (ansId) {
        result = await getRequestXlsx(
          `Survey/Reports?ID=${id}&Type=${type}&OptionId=${ansId}&IsExport=${true}`
        );
      } else {
        result = await getRequestXlsx(
          `Survey/Reports?ID=${id}&Type=${type}&IsExport=${true}`
        );
      }
      const blob = new Blob([result.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a download link and click it programmatically
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Survey Report.xlsx"); // Name the file
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSurveyDetails(reportType);
  }, [reportType]);

  return (
    <div
      className="SurveyReportPageContainer"
      style={{ height: "600px", overflow: "auto", width: "100%" }}
    >
      <div className="SurveyReportChartTitleContainer">
        <div className="surveyDetailsHeader">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="surveyDetailsHeaderImg">
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img
                  src={ArrowBackButton}
                  alt=""
                  style={{ margin: "4px 1px", cursor: "pointer" }}
                />
              </IconButton>
              <span className="surveyDetailsHeaderText">{title}</span>
            </div>

            <div className="downloadSurveyBtn">
              <IconButton
                onClick={() => {
                  downloadReport();
                }}
                style={{
                  padding: "5px 10px",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
              >
                <span>Download Report</span>
                <img
                  src={SurveyDownload}
                  alt=""
                  style={{ margin: "4px 1px", cursor: "pointer" }}
                />
              </IconButton>
            </div>
          </div>
        </div>
        {ansId <= 0 && (
          <div>
            <div className="surveyReportTabs">
              <div
                className={`allReportHeading ${
                  reportType === "All" ? "allReportActive" : ""
                }`}
                onClick={() => {
                  setReportType("All");
                }}
              >
                All {data?.allCount}
              </div>
              <div
                className={`viewReportHeading ${
                  reportType === "Viewed" ? "viewReportActive" : ""
                }`}
                onClick={() => {
                  setReportType("Viewed");
                }}
              >
                View by {data?.viewCount}
              </div>
              <div
                className={`respondReportHeading ${
                  reportType === "Respond" ? "respondReportActive" : ""
                }`}
                onClick={() => {
                  setReportType("Respond");
                }}
              >
                Respond by {data?.respondCount}
              </div>
              <div
                className={`notRespondReportHeading ${
                  reportType === "NotRespond" ? "notRespondReportActive" : ""
                }`}
                onClick={() => {
                  setReportType("NotRespond");
                }}
              >
                Not responed {data?.notRespondCount}
              </div>
            </div>
          </div>
        )}
        <Report surveyReportData={surveyReportData} />
      </div>
    </div>
  );
}

export default SurveyReportPage;
