import "../../Pages/HRMS/Components/Create.css";
import { Formik, Form } from "formik";
import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "../../Assets/CloseIcon.svg";
import { Loader } from "./Loader";
import { useEffect } from "react";
const FormCustomFormik = ({
  FormHeading,
  returnPage,
  propsDiv,
  ButtonLabel,
  validationSchema,
  initialvalue,
  onSubmit,
  ref,
  chooseFormValues,
  loading,
  styles,
  className,
  btnLabel,
}) => {
  return (
    <div className="createPageContainer side-navigation ">
      <div className="createProjectContainer requsitionForm" style={styles}>
        <div className="createHeader">
          <label>{FormHeading}</label>
          <IconButton
            className="closeIconHolder"
            onClick={returnPage}
            title="Close"
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </IconButton>
        </div>
        <div className="horizontalLine createLine"></div>
        <div className="bodyContainer ">
          {loading ? (
            <Loader />
          ) : (
            <Formik
              innerRef={ref}
              enableReinitialize={true}
              initialValues={initialvalue}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => {
                chooseFormValues && chooseFormValues(formik.values);
                return (
                  <Form
                    style={{
                      padding: " 15px 15px 0 15px ",
                      position: "relative",
                      height: "100%",
                      flexGrow: 1,
                    }}
                    className={className}
                  >
                    {/* {propsDiv} */}
                    {React.cloneElement(propsDiv, { formik: formik })}
                    <div className="createFootCust">
                      <div>
                        <div
                          onClick={returnPage}
                          className={`button secondaryButton ${btnLabel}`}
                        >
                          Cancel
                        </div>
                      </div>
                      <div>
                        <button className="button primaryButton" type="submit">
                          {ButtonLabel}
                        </button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};
export { FormCustomFormik };
