import { IconButton, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { StepsHierarchy } from '../../../Components/FormComponent/StepsHierarchy';
import { WorkflowStepsComp } from '../../../Components/FormComponent/WorkflowStepsComp';
import { isLoading, showToast } from '../../../Features';
import { getRequest } from '../../../Services/axios';
import { Details } from '../../HRMS/Components/DetailsComp/Details';
import { format } from 'date-fns';
import { Loader } from '../../../Components/FormComponent/Loader';

// import { DetailData } from './DetailData';

const DetailsWorkflow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")))
  }
  const [loading, setLoading] = useState(true);
  const [workflowData, setWorkflowData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`GeneralWorkflows/Details/${selectedId}`);
      setWorkflowData(result.data)
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  }
  useEffect(() => {
    getEmployeeData();
    return () => {
    }
  }, [])

  useEffect(()=>{
    document.title = `PeopleSol - Details Workflow`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex)
  }
 // //console.log("workflowData",workflowData)
 if (loading) return <Loader/>;
 else
  return (
    <div className="moduleMasterDetailsPage">
      <div className="moduleMasterDetailsBackground" onClick={() => closeDetails()}></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Workflow Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.7579 0.56897L0.757935 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M0.757935 0.56897L12.7579 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          <div className="tabContainer">
            <Tabs value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}>
              <Tab label={<span className={`tabHeading ${tabIndex === 0 && "tabActive"}`}>Details</span>} />
              <Tab label={<span className={`tabHeading ${tabIndex === 1 && "tabActive"}`}>Workflow Steps</span>} />

              </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {
            workflowData &&
            <>
            {tabIndex === 0 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer">
                <div style={{display:"flex"}}>
                <div style={{flex:"5"}}>
            <div style={{display:'flex',flexWrap:"wrap",height:"fit-content",gap:"24px"}}>
                  <Details detailTitle={"Name"} Details={workflowData?.name}/>
                  <Details detailTitle={"Notification Flow"} Details={workflowData?.notifications}/>
                  <Details detailTitle={"Description"} Details={workflowData?.description}/>
                 
                  <Details
                            detailTitle={"Created On"}
                            Details={format(
                              new Date(workflowData?.createdOn),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={format(
                              new Date(workflowData?.lastUpdatedOn),
                              "dd/MM/yyyy hh:mm a"
                            )}
                          />




                </div>
            </div>
            <div style={{flex:"2"}}>
              <div className='detatime'>
              <StepsHierarchy workflowData={workflowData}/>
              </div>
            </div>
                </div>
                </div>
              </>)}

              {tabIndex === 1 && (
              <>
                <div className="tabularMasterDetailsPageTabBodyContainer" style={{flexGrow:"initial"}}>
                  
                   <WorkflowStepsComp data={workflowData}/>
                </div>
              </>)}
              </>
              }
        </div>
      </div>
    </div>
  )
}

export { DetailsWorkflow }