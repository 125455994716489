import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { format } from "date-fns";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import { useEffect } from "react";
import { Loader } from "../../../../../Components/FormComponent/Loader";

const Option = [
  { label: "Due not Cleared", value: "Due not Cleared" 

  },
  { label: "No Due", value: "No Due" },
];
export const CreateNoDue = ({ id, data, type, loading, handleClose }) => {;
  const dispatch = useDispatch();
  const [detailsform, setDetailsform] = useState(false)

  const getFormDetails = async () => {
    try {

      const result = await getRequest(`Resignations/DueClearance?ID=${id}`);
      setDetailsform(result?.data?.data);
    } catch (error) {
      dispatch(showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" }));
    } finally {

    }
  }
  useEffect(() => {
    document.title = `PeopleSol - Create No Due`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    if (type==="Update No Due Clearance") {
      getFormDetails()
    }
  }, [])
  // //console.log("det",detailsform?.noDueClearanceForms)
  const initial = {
    Status: detailsform?detailsform?.noDueClearanceForms?.status:"",
    Remarks: detailsform?detailsform?.noDueClearanceForms?.remarks:"",
    PayableAmount:  detailsform?detailsform?.noDueClearanceForms?.payableAmount:null,
    RecoverAmount: detailsform?detailsform?.noDueClearanceForms?.recoverAmount:null
  };
  const validationSchema = Yup.object({
    Status: Yup.string().required("Please Select Status"),
  });
  const onSubmit = async (values) => {
    const create={
        ...values,
        id:id
    }
    data(create)
  };
  if(loading) return <Loader/>
  else return (
    <Formik initialValues={initial} enableReinitialize onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form>
        <div className="formcustom twoColumn">
        <div >
          <LabelCustom labelName={"Payable Amount	"} />
          <InputCustomFormik name={"PayableAmount"} placeholder="Enter Payable Amount" maxLength={150}/>
        </div>
        <div>
          <LabelCustom labelName={"Recover Amount	"} />
          <InputCustomFormik name={"RecoverAmount"} placeholder="Enter Recover Amount" maxLength={150}/>
        </div>
        <div>
          <LabelCustom labelName={"Status"} mandatory={true} />
          <SelectForm options={Option} name={"Status"} placeholder="Select Status" />
        </div>
        <div>
          <LabelCustom labelName={"Remark"} />
          <TextAreaFormik style={{ height: "80px" }} name={"Remarks"} maxLength={500} placeholder="Enter Remark" />
        </div>
        </div>
        <div className="createFootCust" >
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ color: "white" }}
          >
            {type}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
