import React from 'react';

const ReimbursementsComponent = ({ reimbursements,employeeSummary }) => {
  // Calculate the total reimbursements
  const totalReimbursements = reimbursements.reduce((total, reimbursement) => total + reimbursement.amount, 0);

  // Filter reimbursements by type
  const reimbursementsByType = reimbursements.reduce((accumulator, reimbursement) => {
    if (!accumulator[reimbursement.type]) {
      accumulator[reimbursement.type] = 0;
    }
    accumulator[reimbursement.type] += reimbursement.amount;
    return accumulator;
  }, {});

  return (
    <div className="paySummaryComponentContainer" style={{ marginTop: "24px" }}>
      <div className="paySummaryComponentHeader">
        <h6>REIMBURSEMENTS</h6>
        <h6>AMOUNT</h6>
      </div>
      <div className="paySummaryComponentMain">
        {Object.keys(reimbursementsByType).map((reimbursementType, index) => (
          <div className="paySummaryComponentList" key={index}>
            <p className="empheadingSlip">{reimbursementType}</p>
            <p className="empBodySlip">₹{reimbursementsByType[reimbursementType]}</p>
          </div>
        ))}
      </div>
      <div className="paySummaryComponentFooter">
        <p className="empheadingSlip">Total Reimbursement</p>
        <p className="empBodySlip">₹{employeeSummary.totalReimbursement}</p>
      </div>
    </div>
  );
};

export  {ReimbursementsComponent};
