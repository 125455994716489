import React, { } from 'react';
import { PerformanceCycleTab } from './PerformanceCycleTab';
import { useEffect } from 'react';

const PerformanceCycle = () => {
    useEffect(() => {
        document.title = `PeopleSol -  Create Performance`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
            <PerformanceCycleTab title="Performance Cycle" />
        </div>
    )
}
export { PerformanceCycle }