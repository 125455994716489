import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailsRewards = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(false);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`Awards/Details/${selectedId}`);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Reward Details  `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    getEmployeeData();
  }, []);
    return (
      <div className="moduleMasterDetailsPage">
        <div
          className="moduleMasterDetailsBackground"
          onClick={() => closeDetails()}
        ></div>
        <div className="moduleMasterDetailsPageContainer">
          <div className="headingContainer">
            <h3>Details</h3>
            <IconButton onClick={() => closeDetails()} title="close">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.7579 0.56897L0.757935 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 0.56897L12.7579 12.569"
                  stroke="#F71D1D"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="moduleMasterDetailsPageBody">
              <DetailsStep detailTitle={"Name"} Details={eventtData?.title} />
              <DetailsStep
                detailTitle={"Award Cycle"}
                Details={eventtData?.cycle}
              />
              <DetailsStep
                detailTitle={"Award For"}
                Details={eventtData?.awardFor}
              />
              <DetailsStep
                detailTitle={"Location"}
                Details={eventtData?.locationname}
              />
              <DetailsStep
                detailTitle={"No. of Winners"}
                Details={eventtData?.noOfWinners}
              />
              <DetailsStep
                detailTitle={"Nominations can be Submitted By"}
                Details={eventtData?.nominationsCanBeSubmittedBy}
              />
              <DetailsStep
                detailTitle={"Eligibilty Criteria"}
                Details={eventtData?.eligibilityCriteria}
              />
              <DetailsStep
                detailTitle={"Is Active"}
                Details={eventtData?.isActive === true ? "Yes" : "No"}
              />{" "}
              
              <DetailsStep
                detailTitle={"Created On"}
                Details={eventtData.createdOn && format(
                  new Date(eventtData.createdOn),
                  "dd/MM/yyyy hh:mm a"
                )}
              />
              <DetailsStep
                detailTitle={"Last Updated On"}
                Details={eventtData.lastUpdatedOn && format(
                  new Date(eventtData.lastUpdatedOn),
                  "dd/MM/yyyy hh:mm a"
                )}
              />
              <div>
                <h4 style={{ margin: "10px 0px" }}>Questions</h4>
              </div>
              <div style={{ width: "100%" }}>
                <table className="tabless tabless-bordered">
                  <thead
                    style={{
                      backgroundColor: "var(--primary-hover)",
                    }}
                  >
                    <tr>
                      <td>Sl. No.</td>
                      <td>Question</td>
                      <td>Is Mandatory?</td>
                    </tr>
                  </thead>
                  <tbody>
                    {eventtData?.requestlist?.length > 0
                      ? eventtData?.requestlist?.map((item, index) => {
                          return (
                            <tr className="trStyle">
                              <td>{index + 1}</td>
                              <td>{item.question}</td>
                              <td>
                                {item.isMandatory === true ? "Yes" : "No"}
                              </td>
                            </tr>
                          );
                        })
                      : "No Data Found"}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export { DetailsRewards };
