import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";

const SelectCustomEmployeeAttendence = ({
  options,
  name,
  onChange,
  styles,
  styl,
  customClass,
  fullWidth,
  isDisabled,
  onMenuScrollToBottom,
  values,
  multi,
  deepStyle,
  ...props
}) => {
  const [clear, setClear] = useState(false);
  useEffect(() => {
    if (values) {
      if (values.length > 0) setClear(true);
      else setClear(false);
    } else setClear(false);
  }, [values]);
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      // Adjust the height of the dropdown menu itself
      // maxHeight: "100px", // Example height, adjust as needed
    }),
    option: (provided, state) => ({
      ...provided,
      // Adjust the height of individual options
      // height: "50px", // Example height, adjust as needed
      // padding: "10px", // Adjust padding to vertically center the text if needed
      //   backgroundColor: state.isFocused ? "rgba(18, 150, 176, 0.1)" : "#CBE8EE",
      // color: "black",
      // "&:hover": {
      //   backgroundColor: "lightblue",
      // },
    }),
    // You might want to adjust the container of the options to ensure proper scrolling
    menuList: (provided) => ({
      ...provided,
      maxHeight: "236px",
      // Adjust this if you change the max-height of the menu or height of the options to ensure smooth scrolling
      // This might not need adjustment if your changes to menu and option heights are sufficient
    }),
  };
  return (
    <div className={`selectCustom ${customClass}`} style={styles}>
      <Select
        {...props}
        // isClearable={true}
        onMenuScrollToBottom={onMenuScrollToBottom}
        className="react-select-container home-css-pagination"
        // className="react-select-container"
        classNamePrefix="react-select"
        menuShouldBlockScroll={true}
        menuPortalTarget={document.body}
        // clearable={props?.searchable ? clear : false}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "var(--primary-hover)",
            // primary: "var(--primary)",
            primary: "#CBE8EE",
            primary50: "var(--primary-hover)",
          },
        })}
        isMulti={multi}
        // color={"#1296b038"}
        styles={customStyles} // className={`inputSelect inputDropdown ${fullWidth}`}
        value={values}
        options={options}
        name={name}
        onChange={onChange}
        disabled={isDisabled ?? false}
      />
    </div>
  );
};
export { SelectCustomEmployeeAttendence };
