import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useEffect } from "react";
import { showToast } from "../../../../../Features";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { SelectFormikMulti } from "../../../../../Components/FormComponent/SelectFormikMulti";
import { Loader } from "../../../../../Components/FormComponent/Loader";
const option = [
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
];
export const ProblemForm = ({ id, data, type, color, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [optionArray, setOptionArray] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [incident, setIncident] = useState([]);
  const [dataDetails, setDataDetails] = useState(false);
  const getDetailsProblem = async () => {
    try {
      setLoading(true);
      const result = await getRequest(`problemRequests/details?ID=${id}`);
      setDataDetails(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (id !== 0) {
      if (color !== "Change") getDetailsProblem();
    }
  }, []);
  //console.log("data",dataDetails)
  const getEmployeeData = async () => {
    try {
      const result = await getRequest(
        "problemrequests/GetProblemRequestOwners"
      );
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      // problemrequests/GetProblemRequestOwners
      setEmployeeList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Problem`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getIncidentTicket = async () => {
    try {
      const result = await getRequest("problemrequests/GetIncidentTickets");
      let listData = [];
      result?.data?.results?.map((item) => {
        listData.push({ label: item.text, value: item.id });
      });
      //  problemrequests/GetIncidentTickets
      setIncident(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const validationSchema = Yup.object({
    Description: Yup.string()
    .max(499, "Must be less than 500 characters")
    .required("Please Enter  Description"),
    Priority: Yup.string().required("Please Select Priority"),
    BusinessImpact: Yup.string().required("Please Select Business Impact"),
    TechnologyImpact: Yup.string().required("Please Select Technology Impact"),
    CostImpact: Yup.string().required("Please Select CostImpact"),
    IncidentTickets: Yup.array()
      .min(1, `Please Select IncidentTickets `)
      .required(" Please Select Incident Tickets"),
    ProblemOwnerEmployeeId: Yup.string().required("Please Select Employee"),
  });
  const ProblemCreate = async (value) => {
    try {
      setLoading(true);
      if (color === "Change")
        await postRequest(
          `problemrequests/Create?changeRequestID=${id}`,
          value
        );
      else await postRequest(`problemrequests/Create`, value);
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };
  const ProblemEdit = async (value) => {
    try {
      setLoading(true);
      if (color === "Change")
        await postRequest(
          `problemrequests/Create?changeRequestID=${id}`,
          value
        );
      else await postRequest(`problemrequests/Edit`, value);

      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      handleClose();
      data();
    }
  };

  useEffect(() => {
    getEmployeeData();
    getIncidentTicket();
  }, []);
  const onSubmit = async (values) => {
    const create = {
      ...values,
      IncidentTickets: values?.IncidentTickets?.map((item) => String(item)),
    };
    const edit = {
      ...values,
      id: Number(id),
      IncidentTickets: values?.IncidentTickets?.map((item) => String(item)),
    };
    const edits = {
      ...values,
      changeRequestID: Number(id),
      IncidentTickets: values?.IncidentTickets?.map((item) => String(item)),
    };
    if (id !== 0) {
      if (color === "Change") ProblemEdit(edits);
      else ProblemEdit(edit);
    } else ProblemCreate(create);
  };
  let arrSpec = [];
  dataDetails?.incidentTickets?.map((items) => {
    arrSpec.push({ label: items.text, value: items.id });
  });


  //console.log("dataDetails?.IncidentTickets",dataDetails)
  if (loading) {
    return <Loader />;
  }
 else return (
    <Formik
      enableReinitialize
      initialValues={
        id !== 0
          ? {
              Description: dataDetails?.problemRequest?.description,
              Priority: dataDetails?.problemRequest?.priority,
              BusinessImpact: dataDetails?.problemRequest?.businessImpact,
              TechnologyImpact: dataDetails?.problemRequest?.technologyImpact,
              CostImpact: dataDetails?.problemRequest?.costImpact,
              IncidentTickets:dataDetails?.incidentTickets?.map((item) => {
                return item.incidentTicketId;
              }),
              ProblemOwnerEmployeeId:
                dataDetails?.problemRequest?.problemOwnerEmployeeId,
            }
          : {
              Description: "",
              Priority: "",
              BusinessImpact: "",
              TechnologyImpact: "",
              CostImpact: "",
              IncidentTickets: [],
              ProblemOwnerEmployeeId: "",
            }
      }
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <div>
              <LabelCustom
                labelName={"Problem Description "}
                mandatory={true}
                maxLength={500}
              />
              <TextAreaFormik maxlength="500" style={{ height: "100px" }} name="Description" placeholder={" Enter Description"}/>
            </div>
            <div className="formcustom twoColumn">
              <div>
                <LabelCustom labelName={"Priority"} mandatory={true} />
                <SelectForm
                  name="Priority"
                  options={option}
                  placeholder="Select Change Type"
                />
              </div>
              <div>
                <LabelCustom labelName={"Business Impact"} mandatory={true} />
                <SelectForm name="BusinessImpact" options={option} />
              </div>
              <div>
                <LabelCustom labelName={"Technology Impact"} mandatory={true} />
                <SelectForm name="TechnologyImpact" options={option} />
              </div>
              <div>
                <LabelCustom labelName={"Cost Impact"} mandatory={true} />
                <SelectForm name="CostImpact" options={option} />
              </div>
              <div>
                <LabelCustom
                  labelName={"Incident Ticket(s)"}
                  mandatory={true}
                />
                <SelectFormikMulti
                  name="IncidentTickets"
                  options={incident}
                  placeholder={"Select Incident Tickets"}
                />
              </div>
              <div>
                <LabelCustom labelName={"Problem Owner"} mandatory={true} />
                <SelectForm
                  placeholder={"Employee"}
                  name="ProblemOwnerEmployeeId"
                  options={employeeList}
                />
              </div>
            </div>

            <div className="createFootCust">
              <button
                type="button"
                className="button secondaryButton"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="button primaryButton"
                type="submit"
                style={{ color: "white" }}
              >
               { dataDetails ?"Update Problem Request":"Raise Problem Request"}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
