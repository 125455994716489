import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../Features";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";

export const ServiceMySkillCreateComp = ({ data, formValues }) => {
  const [skillSet, setSkillSet] = useState();
  const [skill, setSkill] = useState();
  const [department, setDepartment] = useState();
  const proficiencyLevel = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
  ];
  const getSkillSet = async (id) => {
    try {
      const result = await getRequest(
        `CompetencyEmployeeController/GetSkillset?Id=${id}`
      );
      // console.log("resultLocationGrp", result)
      let listData = [];
      result.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setSkillSet(listData);
      formValues.SkillsetId = listData[0]?.value;
    } catch (error) {
    } finally {
    }
  };
  const getSkills = async (id) => {
    try {
      const result = await getRequest(
        `CompetencyEmployeeController/GetSkills?Skillsetid=${id}`
      );
      // console.log("resultLocationGrp", result)
      let listData = [];
      result.data?.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setSkill(listData);
      formValues.CompetencyId = listData[0]?.value;
    } catch (error) {
    } finally {
    }
  };
  const getDepartment = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetDepartments");
      // console.log("resultLocationGrp", result)
      let listData = [];
      result.data?.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setDepartment(listData);
      formValues.DepartmentId = listData[0]?.value;
    } catch (error) {
    } finally {
    }
  };

  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getDepartment();
  }, []);
  useEffect(() => {
    if (formValues?.DepartmentId) {
      getSkillSet(formValues?.DepartmentId);
    }
  }, [formValues?.DepartmentId]);
  useEffect(() => {
    if (formValues?.SkillsetId) {
      getSkills(formValues?.SkillsetId);
    }
  }, [formValues?.SkillsetId]);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Department"} mandatory={true} />
        <SelectForm
          placeholder={"Select Department"}
          name="DepartmentId"
          options={department}
        />
      </div>
      <div>
        <LabelCustom labelName={"Skill Set"} mandatory={true} />
        <SelectForm
          placeholder={"Select Skill Set"}
          name="SkillsetId"
          options={skillSet}
        />
      </div>
      <div>
        <LabelCustom labelName={"Skill "} mandatory={true} />
        <SelectForm
          placeholder={"Select Skill "}
          name="skillId"
          options={skill}
        />
      </div>
      <div>
        <LabelCustom labelName={"Proficiency Level"} mandatory={true} />
        <SelectForm
          placeholder={"Select Proficiency Level"}
          name="ProficiencyLevel"
          options={proficiencyLevel}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload Attachment"} mandatory={true} />
        <FileUploadFormik
          name="upload"
          //   attachTextLabel={data?.image?.toString()}
        />
      </div>
      <div>
        <LabelCustom labelName={"Description"} />
        <TextAreaFormik
          name="Description"
          placeholder={" Enter Description"}
          maxLength={500}
        />
      </div>
    </div>
  );
};
