/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import * as Yup from "yup";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../Components/FormComponent/TextAreaFormik";
import { Form, Formik } from "formik";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { selfRatingObtain } from "../../../../Utilities";
const SubmitAppraiserReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const location = useLocation();
  const selectedId = id[xy].substring(n + 1);
  const selectedIds = location?.state?.item;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRow = (index) => {
    const expandedIndex = expandedRows.indexOf(index);
    const newExpandedRows = [...expandedRows];

    if (expandedIndex > -1) {
      newExpandedRows.splice(expandedIndex, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };
  const expandAllButtonStyle = {
    backgroundColor: expandedRows.length > 0 ? "rgb(143 143 213)" : "#FFFFFF",
    color: expandedRows.length > 0 ? "white" : "black",
    border: expandedRows.length > 0 ? "none" : "1px solid black",
  };

  const collapseAllButtonStyle = {
    backgroundColor:
      expandedRows.length === 0 ? "rgb(241 86 86 / 97%)" : "#FFFFFF",
    color: expandedRows.length === 0 ? "white" : "black",
    border: expandedRows.length === 0 ? "none" : "1px solid black",
  };
  const expandAllRows = () => {
    const allRowIndexes = data?._pmssettings?.goalslist?.map(
      (_, index) => index
    );
    setExpandedRows(allRowIndexes);
  };

  const collapseAllRows = () => {
    setExpandedRows([]);
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };
  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `goals/details/?GoalSettingId=${selectedIds?.appraisalCycle?.goalSettingId}&&EmployeeId=${selectedIds?.employeeId}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol -  Submit Appraisal  `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const getGoalslist = () => {
    let returnArray = [];
    data?._pmssettings?.goalslist?.map((item, index) => {
      returnArray.push({
        Name: item.name,
        Id: item.id,
        Description: item.description,
        StartDate: item.startDate ? new Date(item.startDate) : null,
        ExpectedEndDate: item.expectedEndDate
          ? new Date(item.expectedEndDate)
          : null,
        Kra: item.kra,
        Weightage: item.weightage,
        Achievement:
          data?._pmssettings?.pmsGoals?.[index]?.pmsReviewFeedbacksUpdates[0]
            ?.employeeAchievement ?? 0,
        Remark:
          data?._pmssettings?.pmsGoals?.[index]?.pmsReviewFeedbacksUpdates[0]
            ?.employeeRemarks ?? "",
      });
      return null;
    });

    return returnArray;
  };
  const initialValues = {
    Name: data?._pmssettings?.name,
    AppraiserReviewNotes: selectedIds?.notes ?? "",
    dateSub: "",
    totalWeightage: 0,
    Goalslist: getGoalslist(),
  };

  useEffect(() => {
    getDetails();
  }, []);
  const createFunction = async (values) => {
    try {
      setLoading(true);
      const result = await postRequest("PMSGoalReviews/RoReviews", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.data?.response ?? "Some error occured",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
      navigate(-1);
      getDetails();
    }
  };
  function parseAchievementValue(achievement) {
    const parsedValue = parseInt(achievement);
    return isNaN(parsedValue) ? 0 : parsedValue;
  }
console.log("rev",data?._pmssettings?.pmsGoals[0].pmsReviewFeedbacksUpdates[0]?.reviewId)
  const onSubmit = async (values) => {
    const create = {
      ...values,
      ReviewId: Number(
        data?._pmssettings?.pmsGoals[0].pmsReviewFeedbacksUpdates[0]?.reviewId
      ),
      Id: selectedId,
    };
    createFunction(create);
  };
  return (
    <div className="bgCreate">
      <div style={{ height: "100%" }}>
        <div className="modulecontGoal">
          {loading ? (
            <Loader />
          ) : data ? (
            <Formik
              
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={Yup.object({
                Goalslist: Yup.array().of(
                  Yup.object().shape({
                    Achievement: Yup.number()
                      .max(149, "Weightage must be less than 150 characters")
                      .required("Please enter the Achieved Percent"),
                  })
                ),
                totalWeightage: Yup.number()
                  .required("Please enter the Total Weightage")
                  .test(
                    "Is 100 percent",
                    "Total Weightage % should be less than 120",
                    (value) => Number(value) <= 120
                  ),
              })}
            >
              {(formik) => {
               // console.log("formik",formik?.errors)
                formik.values.totalWeightage = formik.values.Goalslist?.reduce(
                  (sum, card) => sum + card?.Achievement,
                  0
                );
                //console.log("ds", formik.values.Goalslist);
                return (
                  <Form style={{ height: "100%" }}>
                    <div className="selfParent">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.04)",
                          alignItems: "center",
                          padding: "4px 0px",
                        }}
                      >
                        <div
                          onClick={() => navigate(-1)}
                          style={{ display: "flex" }}
                          className="returnToDashboard cursor"
                        >
                          <div>
                            <svg
                              width="20"
                              height="8"
                              viewBox="0 0 20 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.03829 3.35738C0.84303 3.55265 0.84303 3.86923 1.03829 4.06449L4.22027 7.24647C4.41553 7.44173 4.73212 7.44173 4.92738 7.24647C5.12264 7.05121 5.12264 6.73463 4.92738 6.53936L2.09895 3.71094L4.92738 0.88251C5.12264 0.687248 5.12264 0.370666 4.92738 0.175404C4.73212 -0.0198586 4.41553 -0.0198586 4.22027 0.175404L1.03829 3.35738ZM19.4187 4.21094C19.6949 4.21094 19.9187 3.98708 19.9187 3.71094C19.9187 3.4348 19.6949 3.21094 19.4187 3.21094V4.21094ZM1.39185 4.21094H19.4187V3.21094H1.39185V4.21094Z"
                                fill="#1296B0"
                              />
                            </svg>
                          </div>
                          <label className="returnLabelHolder">
                            {selectedIds?.employee?.nameWithPersonNumber}
                          </label>
                        </div>
                        <div>
                          <button
                            className="btnSelf"
                            onClick={togglePopup}
                            type="button"
                          >
                            View Rating Slab
                          </button>
                          {data?._pmssettings?.pmsGoals[0]
                            ?.pmsReviewFeedbacksUpdates[0]?.status !==
                            "Review Pending" && (
                            <button type="submit" className="btnSelf">
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "12px",
                        }}
                      >
                        <div className="ratingSelf">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div style={{ marignTop: "12px" }}>
                              Self Rating :
                            </div>
                            <div>
                              {" "}
                              <StarRatings
                                rating={selfRatingObtain(
                                  selectedIds?.pmsReviewFeedbacksUpdates.reduce(
                                    (sum, card) =>
                                      sum + card.employeeAchievement,
                                    0
                                  )
                                )}
                                starRatedColor="#5BC4BF"
                                starSpacing="2px"
                                starEmptyColor="#FFFFFF"
                                numberOfStars={5}
                                starHoverColor="var(--primary)"
                                starDimension="28px"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <div>Self Achievement % :</div>
                            <div>
                              <p
                                style={{
                                  color: "#5BC4BF",
                                  fontSize: "30px",
                                  fontWeight: "500",
                                  marginTop: "5px",
                                }}
                              >
                                {selectedIds?.pmsReviewFeedbacksUpdates.reduce(
                                  (sum, card) => sum + card.employeeAchievement,
                                  0
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        {data?.pmsReviews?.status ===
                          "Appraiser Review Pending" ||
                        data?.pmsReviews?.status === "Review Pending" ? (
                          <div
                            className="ratingSelf"
                            style={{ backgroundColor: "#FFEEF2" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ marignTop: "12px" }}>
                                Appraiser Rating :
                              </div>
                              <div>
                                {" "}
                                <StarRatings
                                  rating={selfRatingObtain(
                                    selectedIds?.pmsReviewFeedbacksUpdates.reduce(
                                      (sum, card) =>
                                        sum + card.appraiserAchievement,
                                      0
                                    )
                                  )}
                                  starRatedColor="#DE64A1"
                                  starSpacing="2px"
                                  starEmptyColor="#FFFFFF"
                                  numberOfStars={5}
                                  starHoverColor="var(--primary)"
                                  starDimension="28px"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>Appraiser Achievement % :</div>
                              <div>
                                <p
                                  style={{
                                    color: "#DE64A1",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {selectedIds?.pmsReviewFeedbacksUpdates.reduce(
                                    (sum, card) =>
                                      sum + card.appraiserAchievement,
                                    0
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="ratingSelf"
                            style={{ backgroundColor: "#FFEEF2" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div style={{ marignTop: "12px" }}>
                                Appraiser Rating :
                              </div>
                              <div>
                                {" "}
                                <StarRatings
                                  rating={selfRatingObtain(
                                    formik?.values?.Goalslist?.reduce(
                                      (sum, goal) =>
                                        sum +
                                        parseAchievementValue(
                                          goal?.Achievement
                                        ),
                                      0
                                    )
                                  )}
                                  starRatedColor="#DE64A1"
                                  starSpacing="2px"
                                  starEmptyColor="#FFFFFF"
                                  numberOfStars={5}
                                  starHoverColor="var(--primary)"
                                  starDimension="28px"
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div>Appraiser Achievement % :</div>
                              <div>
                                <p
                                  style={{
                                    color:
                                      formik.values.Goalslist?.reduce(
                                        (sum, card) => sum + card?.Achievement,
                                        0
                                      ) > 120
                                        ? "red"
                                        : "#DE64A1",
                                    fontSize: "30px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                  }}
                                >
                                  {formik.values.Goalslist?.reduce(
                                    (sum, card) => sum + card?.Achievement,
                                    0
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="reviewNotes">
                        {" "}
                        {/* <button
                          type="button"
                          className="button "
                          onClick={expandAllRows}
                          style={expandAllButtonStyle}
                        >
                          Expand All Rows
                        </button>
                        <button
                          type="button"
                          className="button "
                          onClick={collapseAllRows}
                          style={collapseAllButtonStyle}
                        >
                          Collapse All Rows
                        </button> */}
                      </div>
                      {data?._pmssettings?.goalslist?.length > 0 ? (
                        <div style={{ width: "100%", overflow: "auto" }}>
                          <table
                            className="tabself tabself-bordered"
                            style={{ width: "100%" }}
                          >
                            <thead
                              style={{
                                backgroundColor: "#FBFBFB",
                                top: 0,
                                position: "sticky",
                                zIndex: 2,
                              }}
                            >
                              <tr>
                                <td>
                                  <LabelCustom labelName={"S.no"} />
                                </td>
                                <td>
                                  <LabelCustom labelName={"Name"} />
                                </td>
                                <td>
                                  <LabelCustom labelName={"Description"} />
                                </td>
                                <td>
                                  <LabelCustom labelName={"Start Date"} />
                                </td>
                                <td>
                                  <LabelCustom
                                    labelName={"Expected End Date"}
                                  />
                                </td>
                                <td>
                                  <LabelCustom labelName={"KRA"} />
                                </td>
                                <td>
                                  <LabelCustom labelName={"Weightage"} />
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {data?._pmssettings?.goalslist?.map(
                                (items, index) => {
                                  const isRowExpanded =
                                    expandedRows.includes(index);

                                  return (
                                    <React.Fragment key={index}>
                                      <tr
                                        className={`trStyles`}
                                        key={index}
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <td>
                                          <LabelCustom labelName={index + 1} />
                                        </td>
                                        <td>{items.name}</td>
                                        <td>
                                          <LabelCustom
                                            labelName={items.description}
                                          />

                                          <IconButton
                                            onClick={() => toggleRow(index)}
                                            sx={{
                                              display: isRowExpanded && "none",
                                              position: "absolute",
                                              bottom: "-18px",
                                              left: "50%",
                                              zIndex: "1",
                                              boxShadow:
                                                "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                            }}
                                          >
                                            {!isRowExpanded && (
                                              <>
                                                <svg
                                                  width="14"
                                                  height="9"
                                                  viewBox="0 0 14 9"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M12.8765 0.898926L6.82826 6.94713L0.780053 0.898925"
                                                    stroke="#1296B0"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                  />
                                                </svg>
                                              </>
                                            )}
                                          </IconButton>
                                        </td>
                                        <td>
                                          <LabelCustom
                                            labelName={
                                              items.startDate &&
                                              format(
                                                new Date(items.startDate),
                                                "dd/MM/yyyy"
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <LabelCustom
                                            labelName={
                                              items.expectedEndDate &&
                                              format(
                                                new Date(items.expectedEndDate),
                                                "dd/MM/yyyy"
                                              )
                                            }
                                          />
                                        </td>
                                        <td>
                                          <LabelCustom labelName={items.kra} />
                                        </td>
                                        <td>
                                          <LabelCustom
                                            labelName={items.weightage}
                                          />
                                        </td>
                                      </tr>
                                      {isRowExpanded && (
                                        <tr>
                                          <td
                                            colSpan="7"
                                            style={{
                                              padding: "0",
                                            }}
                                          >
                                            <>
                                              <div className="formSelfReview">
                                                <div className="centertd">
                                                  <div>Self</div>
                                                  <div>Review</div>
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Notes"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?._pmssettings
                                                        ?.pmsGoals[index]
                                                        ?.pmsReviewFeedbacksUpdates[0]
                                                        ?.employeeRemarks
                                                    }
                                                  />
                                                </div>
                                                <div style={{ flex: "1" }}>
                                                  <label
                                                    style={{
                                                      paddingLeft: "12px",
                                                    }}
                                                  >
                                                    {"Achievement %"}
                                                  </label>
                                                  <LabelCustom
                                                    labelName={
                                                      data?._pmssettings
                                                        ?.pmsGoals[index]
                                                        ?.pmsReviewFeedbacksUpdates[0]
                                                        ?.employeeAchievement
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {data?._pmssettings?.pmsGoals[
                                                index
                                              ]?.pmsReviewFeedbacksUpdates[0]
                                                ?.status ===
                                              "Review Pending" ? (
                                                <div
                                                  className="formSelfReview"
                                                  style={{
                                                    backgroundColor: "#FFEEF2",
                                                  }}
                                                >
                                                  <div
                                                    className="centertd"
                                                    style={{
                                                      backgroundColor:
                                                        "#DE64A1",
                                                    }}
                                                  >
                                                    <div>Appraiser</div>
                                                    <div>Review</div>
                                                  </div>
                                                  <div style={{ flex: "1" }}>
                                                    <label
                                                      style={{
                                                        paddingLeft: "12px",
                                                      }}
                                                    >
                                                      {"Notes"}
                                                    </label>
                                                    <LabelCustom
                                                      labelName={
                                                        data?._pmssettings
                                                          ?.pmsGoals[index]
                                                          ?.pmsReviewFeedbacksUpdates[0]
                                                          ?.appraiserRemarks
                                                      }
                                                    />
                                                  </div>
                                                  <div style={{ flex: "1" }}>
                                                    <label
                                                      style={{
                                                        paddingLeft: "12px",
                                                      }}
                                                    >
                                                      {"Achievement %"}
                                                    </label>
                                                    <LabelCustom
                                                      labelName={
                                                        data?._pmssettings
                                                          ?.pmsGoals[index]
                                                          ?.pmsReviewFeedbacksUpdates[0]
                                                          ?.appraiserAchievement
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  className="formSelfReview"
                                                  style={{
                                                    backgroundColor: "#FFEEF2",
                                                  }}
                                                >
                                                  <div
                                                    className="centertd"
                                                    style={{
                                                      backgroundColor:
                                                        "#DE64A1",
                                                    }}
                                                  >
                                                    <div>Appraiser</div>
                                                    <div>Review</div>
                                                  </div>
                                                  <div style={{ flex: "1" }}>
                                                    <label
                                                      style={{
                                                        paddingLeft: "12px",
                                                      }}
                                                    >
                                                      {"Notes"}
                                                    </label>
                                                    <TextAreaFormik
                                                      style={{
                                                        minWidth: "110px",
                                                      }}
                                                      placeholder={
                                                        "Enter Description "
                                                      }
                                                      type="textarea"
                                                      name={`Goalslist[${index}].Remark`}
                                                      maxLength={500}
                                                    />
                                                  </div>
                                                  <div style={{ flex: "1" }}>
                                                    <label
                                                      style={{
                                                        paddingLeft: "12px",
                                                      }}
                                                    >
                                                      {"Achievement %"}
                                                    </label>
                                                    <InputCustomFormik
                                                      maxLength={150}
                                                      selectBoxOptions="Employee Onboarding;Candidate Onboarding;Finland;Germany;Mexico"
                                                      name={`Goalslist[${index}].Achievement`}
                                                      type={"number"}
                                                      placeholder={
                                                        "Enter Type "
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </>

                                            <IconButton
                                              onClick={() => toggleRow(index)}
                                              sx={{
                                                left: "50%",
                                                zIndex: "1",
                                                boxShadow:
                                                  "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                              }}
                                            >
                                              {isRowExpanded && (
                                                <>
                                                  <svg
                                                    width="15"
                                                    height="9"
                                                    viewBox="0 0 15 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      d="M13.3755 7.62805L7.32728 1.57985L1.27908 7.62805"
                                                      stroke="#1296B0"
                                                      stroke-width="1.5"
                                                      stroke-linecap="round"
                                                    />
                                                  </svg>
                                                </>
                                              )}
                                            </IconButton>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <InputCustomFormik
                              name={"totalWeightage"}
                              type="hidden"
                              step="0.01"
                              disabled
                            />
                            {data?._pmssettings?.pmsGoals[0]
                              ?.pmsReviewFeedbacksUpdates[0]?.status ===
                            "Review Pending" ? (
                              <LabelCustom
                                style={{
                                  color:
                                    formik.values.Goalslist?.reduce(
                                      (sum, card) => sum + card?.Achievement,
                                      0
                                    ) > 120
                                      ? "red"
                                      : "var(--primary)",
                                }}
                                labelName={`Total Achievement (%) : ${selectedIds?.pmsReviewFeedbacksUpdates.reduce(
                                  (sum, card) =>
                                    sum + card?.appraiserAchievement,
                                  0
                                )}`}
                              />
                            ) : (
                              <LabelCustom
                                style={{
                                  color:
                                    formik.values.Goalslist?.reduce(
                                      (sum, card) => sum + card?.Achievement,
                                      0
                                    ) > 120
                                      ? "red"
                                      : "var(--primary)",
                                }}
                                labelName={`Total Achievement (%) : ${formik.values.Goalslist?.reduce(
                                  (sum, card) => sum + card?.Achievement,
                                  0
                                )}`}
                              />
                            )}
                          </div>
                          <div className="reviewNotes">
                            <div className="notesSelf">
                              <div className="headingSelf">
                                <p>Self Notes</p>
                              </div>
                              <div className="detailSelf">
                                <div>
                                  <LabelCustom labelName={selectedIds?.notes} />
                                </div>
                              </div>
                            </div>
                            {data?._pmssettings?.pmsGoals[0]
                              ?.pmsReviewFeedbacksUpdates[0]?.status !==
                            "Review Pending" ? (
                              <div
                                className="notesSelf"
                                style={{ backgroundColor: "#FFEEF2" }}
                              >
                                <div
                                  className="headingSelf"
                                  style={{ background: "#DE64A1" }}
                                >
                                  <p>Appraiser Notes</p>
                                </div>
                                <div className="detailSelf">
                                  <div>
                                    <TextAreaFormik
                                      name="AppraiserReviewNotes"
                                      style={{ minHeight: "50px" }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="notesSelf"
                                style={{ backgroundColor: "#FFEEF2" }}
                              >
                                <div
                                  className="headingSelf"
                                  style={{ background: "#DE64A1" }}
                                >
                                  <p>Appraiser Notes</p>
                                </div>
                                <div className="detailSelf">
                                  <div>
                                    <LabelCustom
                                      labelName={
                                        selectedIds?.appraiserReviewNotes
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <Popup
          popupHeading={"Rating Slab"}
          content={
            <>
              <InstructionSelf handleClose={togglePopup} />
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </div>
  );
};

export { SubmitAppraiserReview };
