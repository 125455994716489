import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FileUploadFormik } from "../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { SelectFormikMulti } from "../../../../Components/FormComponent/SelectFormikMulti";
import { SelectFormikMultiString } from "../../../../Components/FormComponent/SelectFormikMultiString";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";

const opt = [{ label: "select", value: null }];
const FilesForm = ({ data, formValues }) => {
  const [parent, setParent] = React.useState([]);
  const [department, setDepartment] = React.useState([]);
  const dispatch = useDispatch();
  const getParent = async () => {
    try {
      const result = await getRequest("Documents/GetFolders");
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setParent(listData);
    } catch (error) {
    } finally {
    }
  };
  const GetDepartments = async () => {
    try {
      const result = await getRequest(
        `Documents/GetSubFolders/${formValues.FolderId}`
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setDepartment(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getParent();
  }, []);
  useEffect(() => {
    if(data){
      GetDepartments();
    }
  }, [formValues?.FolderId]);

  return (
    <div>
      <div>
        <LabelCustom labelName={"Folder "} mandatory={true} />
        <SelectForm
          values={
            data
              ? parent.filter((options) => options.value === data?.folderId)
              : opt
          }
          options={parent}
          name="FolderId"
          placeholder={"Enter Folder "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Sub Folder"} />
        <SelectForm 
          onDropdownOpen={
            formValues?.FolderId == ""
              ? () =>
                  dispatch(
                    showToast({
                      text: "Please select Folder first",
                      severity: "error",
                    })
                  )
              : GetDepartments
          }
          values={
            data
              ? department.filter((options) => options.value === data?.subFolderId)
              : opt
          }
          options={department}
          name="SubFolderId"
          placeholder={" Select Sub Folder "}
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload File"} mandatory={true} />
        <FileUploadFormik name="Upload" attachTextLabel={data?.name?.toString()} />
        <span style={{paddingLeft:"12px",color:"var(--primary)"}}>Size: 2 MB</span>
       <span style={{paddingLeft:"12px",color:"var(--primary)"}}>Format: JPEG, PNG, PDF</span>
      </div>
    </div>
  );
};
export { FilesForm };
