import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useRef } from "react";
import Calendarprev from "../../../Assets/calendarprev.svg";
import Calendarnext from "../../../Assets/calendarnext.svg";
import Nodata from "../../../Assets/Nodata.svg";
import { format, parseISO } from "date-fns";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { CalendarPopup } from "./CalendarPopup";
import { useMemo } from "react";
import { Loader } from "../../../Components/FormComponent/Loader";
import { IconButton } from "@mui/material";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import InfiniteScroll from "react-infinite-scroll-component";
const CalendarComponent = ({
  isList,
  calendarRoomId,
  togglePopupDate,
  setfilteredDatapopup,
}) => {
  const [isActiveDay, setIsActiveDay] = useState(false);
  const [isActiveWeek, setIsActiveWeek] = useState(false);
  const [isActiveMonth, setIsActiveMonth] = useState(true);
  const [isActiveList, setIsActiveList] = useState(false);
  const [calTitle, setCalTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [eventsData, setEventsData] = useState([]);
  const [events, setEvents] = useState([]);
  const [listData, setListData] = useState([]);
  const [listPresentData, setListPresentData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [eventDatad, setEventData] = useState([]);
  const [monthArray, setMonthArray] = useState([]);
  const [eventArrayd, setEventArray] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [meetingRoomCounts, setMeetingRoomCounts] = useState();
  const [listLoading, setListLoading] = useState(false);
  const [endCalendarDate, setEndCalendarDate] = useState();
  const [calanderDateDisplay, setcalanderDateDisplay] = useState();
  const [startCalendarDate, setStartCalendarDate] = useState();
  const [meetingDataForDatePopup, setmeetingDataForDatePopup] = useState();
  // const [liststartdate, setliststartdate] = useState(

  // );

  // const [filteredData, setFilteredData] = useState([]);
  const calendarRef = useRef(null);
  // console.log("calendarRef fdg", calendarRef);
  const dispatch = useDispatch();

  const handleTitleUpdate = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setCalTitle(calendarApi.view.title);
      setcalanderDateDisplay(calendarApi.view.title);
    }
  };
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      handleTitleUpdate();
    }
  }, []);

  function getMonthStartAndEndDates(inputDateL) {
    let inputDate;
    if (inputDateL.includes("-")) {
      const dateString = inputDateL;
      const dateParts = dateString.split("–"); // Split the string at the hyphen

      // Trim any extra spaces and get the first part (date before the hyphen)
      const inputDate1 = dateParts[0].trim();
      const yearstring = dateParts[1].split(",");
      const yearstring1 = yearstring[1];
      inputDate = inputDate1 + " ," + yearstring1;
    } else {
      inputDate = inputDateL;
    }

    // console.log("inputDate", inputDate); // This will print "Aug
    const date = new Date(inputDate); // Create a new Date object based on the inputDate
    const year = date.getFullYear();
    const month = date.getMonth();

    // Calculate the first day of the month
    const monthStartDate = new Date(year, month, 1);
    monthStartDate.setDate(monthStartDate.getDate() - 7);

    // Calculate the last day of the month
    const nextMonth = month === 11 ? 0 : month + 1; // Handle December by rolling over to January
    const nextYear = month === 11 ? year + 1 : year;
    const monthEndDate = new Date(nextYear, nextMonth, 0);
    monthEndDate.setDate(monthEndDate.getDate() + 7);

    // Format the dates as strings in ISO format (e.g., 'yyyy-mm-dd')
    const formattedStartDate = monthStartDate.toISOString().split("T")[0];
    const formattedEndDate = monthEndDate.toISOString().split("T")[0];

    return {
      start: formattedStartDate,
      end: formattedEndDate,
    };
  }

  const getListData = async (date) => {
    try {
      // debugger;

      const today = new Date(date);
      // today.setDate(today.getDate() + 365);
      const dateparam = format(new Date(today), "yyyy-MM-dd");
      // setListLoading(true);
      const result = await postRequest(
        `MeetingRoomBookings/CalendarViewdate?roomId=${calendarRoomId}&start=${dateparam}&page=10`
      );
      // setListData(result?.data?.data);
      setListPresentData(result?.data?.data);
      setListData((prevData) => [...prevData, ...result?.data?.data]);
      // const mergedata = [...listData, ...result?.data?.data];
      // setListData(mergedata);
      // setliststartdate(result?.data?.data[result?.data?.data?.length-1]?.date)
    } catch (error) {
      // dispatch();
      // showToast({
      //   text: error?.response?.data?.title ?? "Error in fetching Data",
      //   severity: "error",
      // })
    } finally {
      // setListLoading(false);
    }
  };
  // console.log("first0,listData", listData);
  // console.log("first0,listPresentData", listPresentData);
  const fetchData = () => {
    // debugger;
    const dater = new Date(listData[listData?.length - 1]?.date);
    dater.setDate(dater.getDate() - 1);

    getListData(dater);
  };
  const getMonthData = async (date) => {
    try {
      const { start, end } = getMonthStartAndEndDates(date);
      // setCalendarLoading(true);
      const result = await postRequest(
        `MeetingRoomBookings/CalendarViewdate?roomId=${calendarRoomId}&start=${start}&end=${end}`
      );
      const monthArray = result?.data?.data?.map((item) => ({
        title: `${item?.numberOfMeetings} Meetings`,
        start: format(new Date(item?.date), "yyyy-MM-dd'T'HH:mm:ss"),
        end: format(new Date(item?.date), "yyyy-MM-dd'T'HH:mm:ss"),
      }));
      setMonthArray(monthArray);
      setMonthData(result?.data?.data);
    } catch (error) {
      // dispatch();
      // showToast({
      //   text: error?.response?.data?.title ?? "Error in fetching Data",
      //   severity: "error",
      // })
    } finally {
      // setCalendarLoading(false);
    }
  };
  const getEventData = async () => {
    try {
      // const { start, end } = getMonthStartAndEndDates(date);
      // setCalendarLoading(true);
      const result = await postRequest(
        `MeetingRoomBookings/CalendarViewFilterd?roomId=${calendarRoomId}`
      );
      const eventArray = result?.data?.summary?.map((item) => ({
        title: `${item?.title}`,
        start: format(new Date(item?.startTime), "yyyy-MM-dd'T'HH:mm:ss"),
        end: format(new Date(item?.endTime), "yyyy-MM-dd'T'HH:mm:ss"),
      }));
      setEventArray(eventArray);
      setEventData(result?.data?.summary);
    } catch (error) {
      // dispatch();
      // showToast({
      //   text: error?.response?.data?.title ?? "Error in fetching Data",
      //   severity: "error",
      // })
    } finally {
      // setCalendarLoading(false);
    }
  };
  // if (setfilteredDatapopup)
  setfilteredDatapopup(meetingDataForDatePopup);
  // console.log("first eventArrayd eventArrayd eventArrayd", eventArrayd);
  useEffect(() => {
    if (calendarRoomId) {
      getMonthData(calanderDateDisplay);
      getEventData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRoomId, calanderDateDisplay]);
  useEffect(() => {
    if (calendarRoomId && isActiveMonth) {
      getMonthData(calanderDateDisplay);
      // getEventData();
    }
  }, [calendarRoomId, calanderDateDisplay]);
  useEffect(() => {
    if (calendarRoomId && (isActiveWeek || isActiveDay)) {
      // getMonthData(calanderDateDisplay);
      getEventData();
    }
  }, [calendarRoomId, calanderDateDisplay]);

  useEffect(() => {
    if (calendarRoomId && isActiveList) {
      setListData([]);
      const today = new Date();
      today.setDate(today.getDate() + 365);
      getListData(format(new Date(today), "yyyy-MM-dd"));
    }
    //  else setCalendarLoading(true);
    // setTimeout(() => {
    //   setCalendarLoading(false); // This will execute after a 2-second delay
    // }, 100);
  }, [isActiveList]);
  useEffect(() => {
    if (calendarRoomId && isActiveList) {
      const today = new Date();

      today.setDate(today.getDate() + 365);
      getListData(format(new Date(today), "yyyy-MM-dd"));
      setListData([]);
    }
  }, [calendarRoomId]);

  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.today();
      handleTitleUpdate();
    }
  };
  const handleViewButtonClick = (view, varname) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(view);

      setIsActiveDay(varname === "day");
      setIsActiveWeek(varname === "week");
      setIsActiveMonth(varname === "month");
      setIsActiveList(varname === "list");

      handleTitleUpdate();
    }
  };
  const handleViewButtonListClick = () => {
    setIsActiveDay(false);
    setIsActiveWeek(false);
    setIsActiveMonth(false);
    setIsActiveList(true);
  };
  const handlePrevButtonClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      handleTitleUpdate();
    }
  };

  const handleNextButtonClick = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      handleTitleUpdate();
    }
  };
  const eventContent = (eventInfo) => (
    <div className="custom-event">
      <div>{eventInfo.event.title} </div>
    </div>
  );

  const todayDate = new Date();
  const listDate = format(todayDate, "MM dd, yyyy");
  const listDay = format(todayDate, "EEEE");
  return (
    <>
      <div
        className={
          isActiveList
            ? "calanderDisplayNone calendarTitleDiv"
            : "calendarTitleDiv"
        }
      >
        <IconButton style={{ width: "32px" }} onClick={handlePrevButtonClick}>
          <img className="calendarTitleBtn" src={Calendarprev} alt="previous" />
        </IconButton>
        {/* <div className='calendarTitleBtn' onClick={handlePrevButtonClick}> hi </div> */}
        <div id="calendarTitle" style={{ margin: " 0px 10px" }}>
          {calanderDateDisplay}
        </div>
        <IconButton style={{ width: "32px" }} onClick={handleNextButtonClick}>
          <img className="calendarTitleBtn" src={Calendarnext} alt="next" />
        </IconButton>

        {/* <div className='calendarTitleBtn' onClick={handleNextButtonClick}> ih </div> */}
      </div>
      <div
        className={
          isActiveList
            ? "calendarHeader textstickbottomheight"
            : "calendarHeader"
        }
      >
        <div className="textstickbottom">
          <div
            className={`${
              isList === true
                ? "calendarHeaderTodayList"
                : "calendarHeaderToday"
            }`}
            onClick={handleTodayButtonClick}
          >
            Today
          </div>
        </div>
        <div
          className={`${
            isList === true
              ? "ContainerList textstickbottom calendarSubHeader"
              : " textstickbottom calendarSubHeader"
          }`}
        >
          <div
            className={
              isActiveDay
                ? "calendarHeaderDay headeractive "
                : "calendarHeaderDay "
            }
            onClick={() => handleViewButtonClick("timeGridDay", "day")}
          >
            Day
          </div>
          <div
            className={
              isActiveWeek
                ? "calendarHeaderDay headeractive "
                : "calendarHeaderDay "
            }
            onClick={() => handleViewButtonClick("timeGridWeek", "week")}
          >
            Week
          </div>
          <div
            className={
              isActiveMonth
                ? "calendarHeaderDay headeractive "
                : "calendarHeaderDay "
            }
            onClick={() => handleViewButtonClick("dayGridMonth", "month")}
          >
            Month
          </div>
          <div
            className={
              isActiveList
                ? "calendarHeaderDay headeractive "
                : "calendarHeaderDay "
            }
            onClick={() => handleViewButtonListClick("list")}
          >
            List
          </div>
        </div>
      </div>

      <div className={isActiveList ? "calanderDisplayNone" : ""}>
        {calendarLoading ? (
          <div style={{ marginTop: "50px" }}>
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            // initialDate={new Date()}
            headerToolbar={{
              start: "",
              center: "",
              end: "",
            }}
            dateClick={(info) => {
              if (isActiveMonth) {
                let meetingDataForDate;

                setmeetingDataForDatePopup(meetingDataForDate);
                if (meetingDataForDate) togglePopupDate();
              } else {
                // debugger;
                let meetingDataForDate;
                let lowerLimit;
                let upperLimit;

                if (
                  Number(format(new Date(info.date), "mm")) >= 0 &&
                  Number(format(new Date(info.date), "mm")) < 30
                ) {
                  lowerLimit = new Date(info.date);
                  lowerLimit.setHours(
                    Number(format(new Date(info.date), "HH")),
                    0,
                    0,
                    0
                  ); // Set the lower limit to 7:00 AM

                  upperLimit = new Date(info.date);
                  upperLimit.setHours(
                    Number(format(new Date(info.date), "HH")),
                    30,
                    0,
                    0
                  ); // Set the upper limit to 8:00 AM
                } else {
                  lowerLimit = new Date(info.date);
                  lowerLimit.setHours(
                    Number(format(new Date(info.date), "HH")),
                    30,
                    0,
                    0
                  ); // Set the lower limit to 7:00 AM

                  upperLimit = new Date(info.date);
                  upperLimit.setHours(
                    Number(format(new Date(info.date), "HH")) + 1,
                    0,
                    0,
                    0
                  ); // Set the upper limit to 8:00 AM
                }
                let meetingStartTime;
                let meetingEndTime;
                let StartTime;
                let EndTime;

                meetingDataForDate = eventDatad.find((data) => {
                  // debugger;
                  StartTime = data.startTime
                    ? new Date(
                        new Date(data.startTime).setHours(
                          Number(format(new Date(data.startTime), "HH")),
                          Number(format(new Date(data.startTime), "mm")),
                          1,
                          0
                        )
                      )
                    : false;
                  EndTime = data.endTime
                    ? new Date(
                        new Date(data.endTime).setHours(
                          Number(format(new Date(data.endTime), "HH")) - 1,
                          59,
                          59,
                          0
                        )
                      )
                    : false;
                  // debugger;
                  meetingStartTime = StartTime
                    ? new Date(StartTime)?.getTime()
                    : false;
                  meetingEndTime = EndTime
                    ? new Date(EndTime)?.getTime()
                    : false;
                  if (
                    meetingEndTime >= lowerLimit.getTime() &&
                    meetingEndTime < upperLimit.getTime()
                  ) {
                    return true;
                  } else if (
                    meetingStartTime >= lowerLimit.getTime() &&
                    meetingStartTime < upperLimit.getTime()
                  ) {
                    return true;
                  } else if (
                    lowerLimit.getTime() >= meetingStartTime &&
                    lowerLimit.getTime() <= meetingEndTime &&
                    upperLimit.getTime() >= meetingStartTime &&
                    upperLimit.getTime() <= meetingEndTime
                  ) {
                    return true;
                  } else return false;
                });

                setmeetingDataForDatePopup(meetingDataForDate);
                if (meetingDataForDate) togglePopupDate();
              }
            }}
            events={isActiveMonth ? monthArray : eventArrayd}
            ref={calendarRef}
            eventContent={eventContent}
            eventClassNames="custom-event-class"
            datesSet={(start, end, view) => {
              setStartCalendarDate(start.start);
              setEndCalendarDate(start.end);
            }}
          />
        )}
      </div>
      <div
        className={
          isActiveDay || isActiveWeek || isActiveMonth
            ? "calanderDisplayNone"
            : "listDisplay"
        }
      >
        {isActiveList && (
          <div>
            <div
              className={`${
                isList === true
                  ? "eventListContainerList"
                  : "eventListContainer"
              }`}
              id="scrollableDiv"
            >
              {!calendarRoomId ? (
                <div style={{ marginTop: "50px" }}>
                  {" "}
                  <>
                    <div className="noDatastyle">
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  </>{" "}
                </div>
              ) : listData ? (
                <InfiniteScroll
                  style={{ width: "100%" }}
                  dataLength={listData?.length} //This is important field to render the next data
                  next={fetchData}
                  hasMore={listPresentData?.length < 10 ? false : true}
                  // hasMore={false}
                  loader={<Loader />}
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {listData?.map((item) => (
                    <div className="">
                      <div className="listheadingdesign">
                        <div>
                          {item?.date
                            ? format(new Date(item?.date), "MMM dd ,yyyy")
                            : ""}
                        </div>
                        <div>
                          {item?.date
                            ? format(new Date(item?.date), "EEEE")
                            : ""}
                        </div>
                      </div>
                      {item?._rooms?.map((roomItem) => (
                        <div className="listbodydesign">
                          <div style={{ marginRight: "20px" }}>
                            {format(new Date(roomItem?.startTime), "hh:mm a")}-
                            {format(new Date(roomItem?.endTime), "hh:mm a")}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7"
                              height="7"
                              viewBox="0 0 7 7"
                              fill="none"
                            >
                              <circle
                                cx="3.5"
                                cy="3.5"
                                r="3.5"
                                fill="#52E2C3"
                              />
                            </svg>
                          </div>
                          <div>{roomItem?.title}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                </InfiniteScroll>
              ) : (
                <>
                  <img
                    style={{
                      display: "flex",
                      marginTop: "100px",
                      width: "50%",
                    }}
                    src={Nodata}
                    alt=""
                  />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CalendarComponent;
