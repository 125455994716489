/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import dateFormat from "dateformat";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
const opt = [{ label: "select", value: null }];
export const CreateLeaveComp = ({
  data,
  seteleaveType,
  formValues,
  seterr,
  setDays,
  sendDataToParent,
  detailsform,
}) => {
  const dispatch = useDispatch();
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [compList, setCompList] = useState([]);

  const [FutureDate, setFutureDate] = useState(null);
  const [PastDate, setPastDate] = useState(null);
  const [policyData, setPolicyData] = useState([]);
  const [restriction, setRestrication] = useState(false);
  const leaveOptions = [
    {
      label: "Full Day",
      value: "Full Day",
    },
    {
      label: "Half Day",
      value: "Half Day",
    },
  ];
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);

  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");

  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOffDayData = async () => {
    try {
      const result = await getRequest(
        "NewLeaveRequests/GetAvaliableOffdayworks "
      );
      let listData = [];
      result.data.map((item) => {
        return listData.push({
          label: format(new Date(item.date), "dd/MM/yyyy"),
          value: item.date,
        });
      });
      setCompList(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Leave`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const getLeaveTypesData = async () => {
    try {
      let darta = [];
      const result = await getRequest("LeaveTypes/GetLeaveTypeWithBalance");
      result?.data?.map((item) => {
        return darta.push({
          label: item.id,
          value: item.leaveName,
          restrictions: item?.restrictions,
        });
      });
      setPolicyData(darta);
      if (data) getRestric(darta);
      let listData = [];
      result?.data?.map((item) => {
        return listData.push({ label: item.name, value: item.leaveName });
      });

      setLeaveTypeList(listData);
      sendDataToParent(listData);
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  const getRestric = (data) => {
    const today = new Date();
    const futureDate = new Date();
    const pastDate = new Date();
    let maternityLeave;
    if (policyData.length) {
      maternityLeave = policyData?.find(
        (leave) =>
          leave?.value ===
          (formValues?.leaveType === undefined
            ? detailsform?.data?.leaveType
            : formValues?.leaveType)
      );
    } else {
      maternityLeave = data?.find(
        (leave) =>
          leave?.value ===
          (formValues?.leaveType === undefined
            ? detailsform?.data?.leaveType
            : formValues?.leaveType)
      );
    }
    setRestrication(maternityLeave);
    setFutureDate(
      futureDate?.setDate(
        today.getDate() + maternityLeave?.restrictions?.nextReqDays
      )
    );
    setPastDate(
      pastDate?.setDate(
        today.getDate() - maternityLeave?.restrictions?.pastReqDays
      )
    );
  };
  const getPolicyLeave = async () => {
    try {
      const result = await getRequest(
        `NewLeaveRequests/CheckRestriction?leaveTypeId=${
          restriction?.label
        }&from=${dateFormat(
          formValues?.startDate,
          "mm-dd-yyyy"
        )}&to=${dateFormat(formValues?.endDate, "mm-dd-yyyy")}`
      );

      setDays(result?.data?.numberOfDays);
      formValues.numberOfDays = result?.data?.numberOfDays;
      seterr("");
      seteleaveType( result?.data?.type)
    } catch (error) {
      setDays(0);
      formValues.numberOfDays = 0;
      seterr(error?.response?.data);
      dispatch(
        showToast({
          text: error?.response?.data,
          severity: "error",
        })
      );
    } finally {
    }
  };


  const getEmployeeData = async () => {
    try {
      const result = await getRequest("Employees/GetEmployeesDropDown");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.name });
      });
      // setPDepartmentList(listData);
      setEmployeeList(listData);
    } catch {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };

  useEffect(() => {
    getEmployeeData();
    getLeaveTypesData();
    getOffDayData();
  }, []);


  React.useEffect(() => {
    if (data) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);



  React.useEffect(() => {
    setListData([]);
  }, []);

  useEffect(() => {
    if (formValues?.leaveType) getRestric();
  }, [formValues?.leaveType]);
  useEffect(() => {
    if (!data) {
      if (
        formValues?.leaveType &&
        formValues?.startDate &&
        formValues?.endDate
      ) {
        getPolicyLeave();
      }
    } else {
      if (restriction) getPolicyLeave();
    }
  }, [formValues?.startDate, formValues?.endDate, formValues?.leaveType]);
  return (
    <div
      style={{
        display:
          formValues?.leaveType && restriction?.restrictions ? "flex" : "block",
      }}
    >
      <div style={{ flex: 3 }}>
        <div>
          <LabelCustom mandatory={true} labelName={"Leave Type"} />
          <SelectForm name="leaveType" options={leaveTypeList} />
        </div>

        <div>
          <LabelCustom mandatory={true} labelName={"Leave Duration"} />
          <SelectForm
            name="leaveDuration"
            options={leaveOptions}
            values={
              data
                ? leaveOptions.filter(
                    (options) => options.label === data?.LeaveDuration
                  )
                : opt
            }
          />
        </div>
        {formValues?.leaveType && (
          <>
            <div>
              <LabelCustom labelName={"Leave Start Date"} mandatory={true} />
              <DatePickerFormiks
                name={"startDate"}
                minDate={
                  restriction?.restrictions?.pastReqDays !== null && PastDate
                }
                maxDate={
                  restriction?.restrictions?.nextReqDays !== null && FutureDate
                }
              />
            </div>
            {formValues?.leaveDuration === "Half Day" ? (
              ""
            ) : (
              <div>
                <LabelCustom labelName={"Leave End Date"} mandatory={true} />
                <DatePickerFormiks
                  name={"endDate"}
                  minDate={formValues?.startDate ?? null}
                />
              </div>
            )}
          </>
        )}
        {formValues?.startDate &&
        formValues?.endDate &&
        formValues?.leaveDuration === "Full Day" &&
        formValues.numberOfDays > 0 ? (
          <div style={{ display: "flex" }}>
            <LabelCustom labelName={"No. Of Leave Days :"} />
            <label style={{ fontWeight: "600", paddingTop: "12px" }}>
              {formValues?.numberOfDays}
            </label>
          </div>
        ) : null}

        {formValues?.leaveType === "Comp Off" ? (
          <div>
            <LabelCustom
              mandatory={true}
              labelName={"Comp Off generated date"}
            />
            <SelectForm
              name="comboffdays"
              options={compList}
              values={
                data
                  ? compList.filter(
                      (options) => options.label === data?.Comboffdays
                    )
                  : opt
              }
            />
          </div>
        ) : null}
        <div>
          <LabelCustom labelName={"Leave Reason"} mandatory={true} />
          <TextAreaFormik
            maxLength={500}
            name="reason"
            placeholder={"Enter the reason"}
          />
        </div>
        <div>
          <LabelCustom labelName={"Contact Person In My Absence"} />
          <SelectForm
            dropdownPosition="top"
            name="contactPerson"
            options={employeeList}
            values={
              data
                ? employeeList.filter(
                    (options) => options.label === data?.ContactPerson
                  )
                : opt
            }
          />
        </div>
        <div>
          <LabelCustom labelName={"Contact Person Number"} />
          <InputCustomFormik
            name="contactNumber"
            placeholder={"Enter Leave Type Name"}
            type="number"
            step="0.01"
          />
        </div>
      </div>
      {formValues?.leaveType && restriction?.restrictions && (
        <div
          style={{
            flex: 2,
            marginTop: "50px",
            background: "var(--primary-hover)",
            padding: "24px",
            lineHeight: "36px",
          }}
        >
          <h4>{restriction?.value} Policy</h4>
          <div>
            <ul>
              <li>
                Weekends Between Leave Period :{" "}
                <strong>
                  {restriction?.restrictions?.weekends ?? "No Restriction"}
                </strong>
              </li>
              <li>
                Holidays Between Leave Period :{" "}
                <strong>
                  {restriction?.restrictions?.holidays ?? "No Restriction"}
                </strong>
              </li>
              <li>
                While Applying Leave Exceed Leave Balance :{" "}
                <strong>
                  {restriction?.restrictions?.exceedLeavebalance ??
                    "No Restriction"}
                </strong>
              </li>
              <li>
                Allowed Requests for Past Days :{" "}
                <strong>
                  {restriction?.restrictions?.pastReqDays == null
                    ? "No Restriction"
                    : restriction?.restrictions?.pastReqDays}
                </strong>
              </li>
              <li>
                Allowed Requests for Future Days :{" "}
                <strong>
                  {restriction?.restrictions?.nextReqDays == null
                    ? "No Restriction"
                    : restriction?.restrictions?.nextReqDays}
                </strong>
              </li>
              <li>
                Show Leave Type When Balance is Zero :{" "}
                <strong>
                  {restriction?.restrictions?.isZero === true ? "Yes" : "No"}
                </strong>
              </li>
              <li>
                Minimum leave that can be availed per application :{" "}
                <strong>
                  {restriction?.restrictions?.minAppLeave ?? "No Restriction"}
                </strong>
              </li>
              <li>
                Maximum leave that can be availed per application :{" "}
                <strong>
                  {restriction?.restrictions?.maxAppLeave ?? "No Restriction"}
                </strong>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};