import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import SocialPostWorkAnniversaryIcon from "../../../../../Assets/SocialPostWorkAnniversaryIcon.svg";
import SocialPostMarriageAnniversaryIcon from "../../../../../Assets/SocialPostMarriageAnniversaryIcon.svg";
import SocialPostBirthdayIcon from "../../../../../Assets/SocialPostBirthdayIcon.svg";
import dateFormat from "dateformat";
import {
  getContentLength,
} from "../../../../../Utilities/socialAllFunctions";
import "./ShowFullAttachments.css";
import "./Connect.css";
import { profileImages } from "../../../../../Utilities/profileAndUploadImages";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import SocialPostWelcomeIcon from "../../../../../Assets/SocialPostWelcomeIcon.svg";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import CommentPost from "./CommentPost";
import getDisplayName from "../../../../../Utilities/displayNameFormat";
import LikeDislike from "./LikeDislike";
import ContentSocialDisplay from "./ContentSocialDisplay";
import GreetingDetailsLikeAndDislikeComponent from "./GreetingDetailsLikeAndDislikeComponent";

function GreetingsPostDetails({
  isGreetings,
  getData,
  postData,
  setPostData,
  setCommentedPost,
  commentedPost,
  fullAtachmentData,
  setShowFullAttachmentActive,
}) {
  const [showContent, setShowContent] = useState(false);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const dispatch = useDispatch();

 
  return (
    <div className="showAttachmentsWithCommentContainer">
      <Popup
        firstClassName="showAttachmentsWithCommentPopupbox1"
        secondClassName="showAttachmentsWithCommentPopupbox2"
        createMyHead="showAttachmentsWithCommentCreateHead"
        content={
          <>
            <div
              className="showAttachmentsWithCommentWrapper"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {fullAtachmentData === "birthday" && (
                <div
                  className={"greetingsImgContainer"}
                  style={{ width: "537px" }}
                >
                  <div className="greetingsImgWrapper">
                    <img
                      src={SocialPostBirthdayIcon}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div className="greetingsBirthdayContent">
                      <img
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commentedPost?.mentionEmployee?.image
                        }
                        alt=""
                        style={{
                          top: "22%",
                          left: "11%",
                          width: "27%",
                          // height: "25%",
                        }}
                      />
                      <h4 style={{ top: "40%", left: "46.2%" }}>
                        {getDisplayName(
                          commentedPost?.mentionEmployee?.nameWithPersonNumber
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {fullAtachmentData === "marriageAnniversary" && (
                <div className={"greetingsImgContainer"}>
                  <div className="greetingsImgWrapper">
                    <img
                      src={SocialPostMarriageAnniversaryIcon}
                      alt=""
                      style={{ width: "500px", height: "100%" }}
                    />
                    <div className="greetingsMarriageContent">
                      <h4
                        style={{
                          top: "104px",
                          left: "236px",
                          fontSize: "24px",
                        }}
                      >
                        {getDisplayName(
                          commentedPost?.mentionEmployee?.nameWithPersonNumber
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
              )}
              {fullAtachmentData === "workAnniversary" && (
                <div className={"greetingsImgContainer"}>
                  <div className="greetingsImgWrapper">
                    <img
                      src={SocialPostWorkAnniversaryIcon}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div className="greetingsWorkContent">
                      <img
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commentedPost?.mentionEmployee?.image
                        }
                        alt=""
                        style={{ width: "27%", top: "12%", right: "9%" }}
                      />
                      <div className="greetingsWorkContentText">
                        <h4>
                          {getDisplayName(
                            commentedPost?.mentionEmployee?.nameWithPersonNumber
                          )}
                        </h4>
                        <p>{commentedPost?.mentionEmployee?.roleName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {fullAtachmentData === "newJoining" && (
                <div className={"greetingsImgContainer"}>
                  <div className="greetingsImgWrapper">
                    <img
                      src={SocialPostWelcomeIcon}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                    <div className="greetingsNewJoiningContent">
                      <img
                        src={
                          process.env.REACT_APP_.BASE_URL +
                          profileImages +
                          commentedPost?.mentionEmployee?.image
                        }
                        alt=""
                        style={{ width: "25%", top: "25%", left: "8%" }}
                      />
                      <div
                        className="greetingsNewJoiningContentText"
                        style={{ bottom: "13%", left: "54%" }}
                      >
                        <h4>
                          {getDisplayName(
                            commentedPost?.mentionEmployee?.nameWithPersonNumber
                          )}
                        </h4>
                        <p>{commentedPost?.mentionEmployee?.roleName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="showAttachmentsWithCommentListContainer">
              <div className="showAttachmentsWithCommentListWrapper">
                <div className="showAttachmentsWithCommentListHeaderContainer">
                  <div>
                    <article>
                      <label>{commentedPost?.companyName}</label>
                      <label>
                        {dateFormat(
                          commentedPost?.createdOn,
                          "hh:MM TT | dd mmm yyyy"
                        )}
                      </label>
                      <label>{commentedPost?.locationName}</label>
                    </article>
                  </div>
                  <IconButton
                    onClick={() => {
                      setShowFullAttachmentActive(false);
                    }}
                  >
                    <img src={CloseIcon} alt="" />
                  </IconButton>
                </div>
                <div className="showAttachmentsWithCommentListBodyContainer">
                  <div className="showAttachmentsWithCommentListBodyWrapper">
                    <div
                      className="postBodyTextContainer"
                      style={{ margin: "0" }}
                    >
                      {commentedPost?.content?.split(/\r\n|\r|\n/).length > 4 &&
                      !showContent ? (
                        <ContentSocialDisplay
                          content={getContentLength(commentedPost?.content)}
                        />
                      ) : (
                        <ContentSocialDisplay
                          content={commentedPost?.content?.trim()}
                        />
                      )}
                      {commentedPost?.content?.split(/\r\n|\r|\n/).length > 4 &&
                        !showContent && (
                          <span
                            onClick={() => {
                              setShowContent(true);
                            }}
                          >
                            ...View More
                          </span>
                        )}
                    </div>
                  </div>
                  <div
                    className="showAttachmentsWithCommentListActivityContainer"
                    style={{ position: "relative" }}
                  >
                    <GreetingDetailsLikeAndDislikeComponent
                      commentedPost={commentedPost}
                      setLikeId={setLikeId}
                      setTotalLikesWatching={setTotalLikesWatching}
                      setDislikeId={setDislikeId}
                      setTotalDisLikesWatching={setTotalDisLikesWatching}
                      setCommentedPost={setCommentedPost}
                      postData={postData}
                      setPostData={setPostData}
                    />
                  </div>
                  <CommentPost
                    commentedPost={commentedPost}
                    setCommentedPost={setCommentedPost}
                    getData={getData}
                    postData={postData}
                    setPostData={setPostData}
                  />
                </div>
              </div>
            </div>
          </>
        }
        handleClose={() => {
          setShowFullAttachmentActive(false);
        }}
      />

      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
    </div>
  );
}

export default GreetingsPostDetails;
