import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateGatePassComp } from "./FormComp/CreateGatePassComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { CreateMeetingRoomComp } from "./FormComp/CreateMeetingRoomComp";
import { format } from "date-fns";

const CreateMeetingRoom = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Meeting Room`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();

  const [detailsform, setDetailsform] = useState({});
  const [formValues, setFormValues] = useState(null);

  const data = state?.id;
  const dataVisit = state?.visitId;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`MeetingRoomBookings/Details?Id=${data}`);
      setDetailsform(result.data.data);
      // console.log("detailsformdtfrewtgerresult", result);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) getFormDetails();
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // console.log("createvalues", values);
      const result = await postRequest("MeetingRoomBookings/Create", values);
      if (result.data.isSuccess === false) {
        dispatch(showToast({ text: result.data.message, severity: "error" }));
      } else {
        dispatch(showToast({ text: result.data.message, severity: "success" }));
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("MeetingRoomBookings/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };
  const initialvalue = {
    Title: data ? detailsform._meetingRoomsBookings?.title : "",

    NumberofPersons: data
      ? detailsform._meetingRoomsBookings?.numberofPersons
      : "",
    AdditionalRequests: data
      ? detailsform._meetingRoomsBookings?.additionalRequests
      : "",
    Date: detailsform?._meetingRoomsBookings?.date
      ? new Date(detailsform?._meetingRoomsBookings?.date)
      : "",
    EndDate: detailsform?._meetingRoomsBookings?.endDate
      ? new Date(detailsform?._meetingRoomsBookings?.endDate)
      : "",
    StartTime: detailsform?._meetingRoomsBookings?.startTime
      ? "2023-12-08T" +
        format(
          new Date(detailsform?._meetingRoomsBookings?.startTime),
          "HH:mm:ss"
        )
      : "",
    EndTime: detailsform?._meetingRoomsBookings?.endTime
      ? "2023-12-08T" +
        format(
          new Date(detailsform?._meetingRoomsBookings?.endTime),
          "HH:mm:ss"
        )
      : "",
    RoomId: data ? detailsform?._meetingRoomsBookings?.roomId?.toString() : "",
    VisitId: data ? detailsform?._meetingRoomsBookings?.visitId : null,
  };
  // console.log("first initialvalue initialvalue initialvalue", initialvalue);
  //console.log("initialvalue", initialvalue);
  const validationSchema = Yup.object({
    Title: Yup.string()
      .matches(/^\S.*$/, "Title should not start with spaces")
      .max(249, "Title must be less than 150 characters")
      .required("Please enter the Title"),
    NumberofPersons: Yup.string()
      .matches(/^\S.*$/, "Number of Persons should not start with spaces")
      .required("Please enter the Number of Persons"),
    AdditionalRequests: Yup.string()
      // .matches(/^\s+/, "Additional Requests should not start with spaces")
      .max(999, "Title must be less than 1000 characters")
      .nullable()
      .notRequired(),
    Date: Yup.string().required("Please enter the Date"),
    EndDate: Yup.string()
      .required("Please enter the Date")
      .test(
        "is-greater",
        "End Date must be greater than or same as Start Date",
        function (value) {
          const startDate = formValues?.Date;
          const endDate = value;

          if (startDate && endDate) {
            return new Date(endDate) >= new Date(startDate);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),

    StartTime: Yup.string().required("Please enter the Time"),
    EndTime: Yup.string()
      .required("Please enter the Time")
      .test(
        "is-greater",
        "End Time must be greater than Start Time",
        function (value) {
          const startTime = formValues?.StartTime;
          const endTime = value;

          if (startTime && endTime) {
            return new Date(endTime) >= new Date(startTime);
          }

          return true; // Let other validations pass if Date or EndDate is not defined
        }
      ),

    RoomId: Yup.string().required("Please select the Room"),
  });
  const handleMeetingRoomError = () => {
    // if (e) {
    confirmAlert({
      customUI: () => {
        return (
          <div className="videowarningPopup">
            <div className="videowarning">Warning!!!</div>
            <div className="videowarningcontent">
              Meeting Room is already booked for the selected Date & Time
            </div>
          </div>
        );
      },
    });
    // }
  };
  function extractTime(date) {
    const daate = new Date(date);
    return (
      daate.getHours() * 60 * 60 + daate.getMinutes() * 60 + daate.getSeconds()
    );
  }

  function isTimeRangeOverlapping(startTime, endTime, sttime, entime) {
    const start = extractTime(startTime);
    const end = extractTime(endTime);
    const st = extractTime(sttime);
    const en = extractTime(entime);

    return (start <= st && st <= end) || (start <= en && en <= end);
  }
  const getValidation = async (room, stdate, endate, sttime, entime) => {
    let val;

    const varEndate1 = new Date(endate.getTime());
    const stdate1 = format(stdate, "yyyy-MM-dd");
    varEndate1.setDate(varEndate1.getDate() + 1);
    let endate1 = format(varEndate1, "yyyy-MM-dd");
    const result = await postRequest(
      `MeetingRoomBookings/CalendarViewdate?roomId=${room}&start=${stdate1}&end=${endate1}`
    );
    if (result.data?.data?.length > 0) {
      outerLoop: for (let i = 0; i < result.data.data.length; i++) {
        const item = result.data.data[i];
        if (item?._rooms) {
          for (let j = 0; j < item._rooms.length; j++) {
            const roomItem = item._rooms[j];
            if (
              new Date(roomItem?.date) <= endate &&
              new Date(roomItem?.date) >= stdate
            ) {
              if (
                isTimeRangeOverlapping(
                  roomItem.startTime,
                  roomItem.endTime,
                  sttime,
                  entime
                )
              ) {
                val = true; // its overlapping
                break outerLoop;
              } else {
                val = false; // its not overlapping
              }
            }
          }
        }
      }
    } else {
      val = false;
    }

    return val;
  };
  const getValidation2 = async (room, number) => {
    const result = await getRequest(`MeetingRooms/Details/${room}`);
    if (result?.data?._meetingRooms?.seatingCapacity < number) {
      confirmAlert({
        customUI: () => {
          return (
            <div className="videowarningPopup">
              <div className="videowarning">Warning!!!</div>
              <div className="videowarningcontent">
                The selected room has{" "}
                {result?.data?._meetingRooms?.seatingCapacity} seats. The number
                of people exceeds the total capacity of the selected room.
              </div>
            </div>
          );
        },
      });
      return false;
    } else {
      return true;
    }
  };
  // console.log("first formValuesformValues formValues", formValues);
  const onSubmit = async (values) => {
    const valueCheck = await getValidation(
      values?.RoomId,
      values?.Date,
      values?.EndDate,
      values?.StartTime,
      values?.EndTime
    );

    if (!valueCheck) {
      const valueCheck2 = await getValidation2(
        values?.RoomId,
        values?.NumberofPersons
      );
      if (valueCheck2) {
        const create = {
          ...values,
          Date: format(values?.Date, "yyyy-MM-dd"),
          EndDate: format(values?.EndDate, "yyyy-MM-dd"),
          StartTime:
            format(values?.Date, "yyyy-MM-dd") +
            "T" +
            format(new Date(values?.StartTime), "HH:mm:ss"),
          EndTime:
            format(values?.EndDate, "yyyy-MM-dd") +
            "T" +
            format(new Date(values?.EndTime), "HH:mm:ss"),
          VisitId: dataVisit ? dataVisit : null,
          // StartTime: values?.StartTime,
          // EndTime: values?.EndTime,
        };
        const edit = {
          ...values,
          id: data,
          Date: format(values?.Date, "yyyy-MM-dd"),
          EndDate: format(values?.EndDate, "yyyy-MM-dd"),
          StartTime:
            format(values?.Date, "yyyy-MM-dd") +
            "T" +
            format(new Date(values?.StartTime), "HH:mm:ss"),
          EndTime:
            format(values?.EndDate, "yyyy-MM-dd") +
            "T" +
            format(new Date(values?.EndTime), "HH:mm:ss"),
          VisitId: values?.VisitId
            ? values?.VisitId
            : dataVisit
            ? dataVisit
            : null,
        };
        // console.log("values", create);
        data ? Edit(edit) : createPageData(create);
      }
    } else {
      handleMeetingRoomError();
    }
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  return (
    <FormCustomFormik
      loading={loading}
      styles={{ width: "80%", maxWidth: "80%" }}
      chooseFormValues={chooseFormValues}
      FormHeading={
        data ? "Edit Meeting Room Request" : "Create Meeting Room Request"
      }
      ButtonLabel={data ? "Edit" : "Submit"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={
        <CreateMeetingRoomComp
          formValues={formValues}
          data={detailsform}
          dataVisitProp={dataVisit}
        />
      }
    />
  );
};

export { CreateMeetingRoom };
