import React from 'react'
import { PoliciesTab } from '../../HRMS/Components/PoliciesTab';
import { useEffect } from 'react';

const ServicePolicies = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Policies Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      <PoliciesTab styles={newHeight}/>
        
    </div>
  )
}

export {ServicePolicies};
