import React from 'react';

const EmployeeSummary = ({ employeeSummary }) => {
    console.log('employeeSummary',employeeSummary)
  const {
    name,
    personNumber,
    role,
    designation,
    doj,
    location,
    month,
    payDays,
    panNo,
    bankName,
    ifscCode,
  } = employeeSummary;

  return (
    <div className="payslipEmployeeSummaryContainer">
      <h5>Employee Summary</h5>
      <div className="payslipEmployeeMainSummaryContainer">
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Employee Name</p>
          <h6 className="empBodySlip">{name ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Employee Code</p>
          <h6 className="empBodySlip">{personNumber ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Employee Role</p>
          <h6 className="empBodySlip">{role ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Employee Designation</p>
          <h6 className="empBodySlip">{designation ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Date of Joining</p>
          <h6 className="empBodySlip">{new Date(doj).toLocaleDateString() ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Location</p>
          <h6 className="empBodySlip">{location ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Pay Period</p>
          <h6 className="empBodySlip">{month ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Pay Days</p>
          <h6 className="empBodySlip">{payDays} Days</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">PAN</p>
          <h6 className="empBodySlip">{panNo===""?"-":panNo}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">Bank Name</p>
          <h6 className="empBodySlip">{bankName ?? "-"}</h6>
        </div>
        <div className="employeeSummaryCell">
          <div className="horizontalLine" />
          <p className="empheadingSlip">IFSC</p>
          <h6 className="empBodySlip">{ifscCode ?? "-"}</h6>
        </div>
      </div>
    </div>
  );
};

export  {EmployeeSummary};
