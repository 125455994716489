import React, { useEffect, useState } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../Services/axios";

export const CreateCompetencyComp = ({ formValues }) => {
  const [competencySet, setCompetencySet] = useState();

  const getCompetencySet = async () => {
    try {
      const result = await getRequest(
        "CompetencyEmployeeController/GetCompetencySet"
      );
      // console.log("resultLocationGrp", result)
      let listData = [];
      result.data.results?.map((item) => {
        return listData.push({ label: item.text, value: item.id });
      });
      setCompetencySet(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getCompetencySet();
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Name"} mandatory={true} />
        <InputCustomFormik
          name="Name"
          type={"text"}
          placeholder={"Please Enter Name"}
        />
      </div>

      <div>
        <LabelCustom labelName={"Competency Set"} mandatory={true} />
        <SelectForm name="CompetencySetID" options={competencySet} />
      </div>
    </div>
  );
};
