import React, { useState, useEffect } from "react";
import Announcementspic from "../../Assets/Announcementspic.svg";
import Eventspic from "../../Assets/Eventspic.svg";
import Newspic from "../../Assets/Newspic.svg";
import Bannerpic from "../../Assets/Bannerpic.svg";
import ContactDirectorypic from "../../Assets/ContactDirectorypic.svg";
import Gallerypic from "../../Assets/Gallerypic.svg";
import "./HomeMaster.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Announcements } from "./Components/Announcements";
import { Events } from "./Components/Events";
import { Gallery } from "./Components/Gallery";
import { News } from "./Components/News";
import { Banner } from "./Components/Banner";
import { ContactDirectory } from "./Components/ContactDirectory";
import { CreateGallery } from "./Components/CreateGallery";
import { CreateAnnouncements } from "./Components/CreateAnnouncements";
import { CreateContactDirectory } from "./Components/CreateContactDirectory";
import { CreateBanners } from "./Components/CreateBanners";
import { ModuleMasterDetailsPage } from "../HRMSMaster/Components";
import { CreateNews } from "./Components/CreateNews";
import { CreateEvents } from "./Components/CreateEvents";
import { DetailsAnnouncement } from "./Components/DetailsAnnouncement";
import { DetailsEvents } from "./Components/DetailsEvents";
import { DetailsBanner } from "./Components/DetailsBanner";
import { DetailsGallery } from "./Components/DetailsGallery";
import { useDispatch } from "react-redux";
import { getRequest, postRequest } from "../../Services/axios";
import { loggedOut } from "../../Features";
import { GroupBy } from "../../Utilities";
import Skeleton from "react-loading-skeleton";

const HomeMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("taxconfig");
  const [activeFeature, setActiveFeature] = useState("");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "home"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {
             
          }
        });
      }
    } catch (err) {
       
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("homemaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "homemaster");
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        }
      }
    }
  }, [location.pathname, subMenuList]);
  // const getFeatureMenu = (submenu) => {
  //   if (!subMenuList) return null;
  //   return subMenuList?.find(
  //     (obj) =>
  //       obj?.[0]?.subMenuName
  //         ?.replaceAll(" ", "")
  //         ?.replaceAll("&", "")
  //         ?.toLowerCase() === submenu
  //   );
  // };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "announcements") return Announcementspic;
    if (subMenuName === "events") return Eventspic;
    if (subMenuName === "news") return Newspic;
    if (subMenuName === "notice") return Bannerpic;
    if (subMenuName === "contactdirectory") return ContactDirectorypic;
    if (subMenuName === "gallery") return Gallerypic;
    return Announcementspic;
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "announcements" && (
        <>
          {createActive && (
            <CreateAnnouncements title={"Create Announcements"} />
          )}
          {detailsActive && <DetailsAnnouncement />}
        </>
      )}
      {activeSubmodule === "events" && (
        <>
          {createActive && <CreateEvents title={"Create Events"} />}
          {detailsActive && <DetailsEvents />}
        </>
      )}
      {activeSubmodule === "news" && (
        <>
          {createActive && <CreateNews title={"Create News"} />}
          {detailsActive && <ModuleMasterDetailsPage />}
        </>
      )}
      {activeSubmodule === "notice" && (
        <>
          {createActive && <CreateBanners title={"Create Banners"} />}
          {detailsActive && <DetailsBanner />}
        </>
      )}
      {activeSubmodule === "contactdirectory" && (
        <>
          {createActive && (
            <CreateContactDirectory title={"Create Contact Directory"} />
          )}
          {detailsActive && <ModuleMasterDetailsPage />}
        </>
      )}
      {activeSubmodule === "gallery" && (
        <>
          {createActive && <CreateGallery title={"Create Gallery"} />}
          {detailsActive && <DetailsGallery />}
        </>
      )}

      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/homemaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "announcements" ? (
              <Announcements title={"Announcements"} />
            ) : null}
            {activeSubmodule === "events" ? <Events title={"Events"} /> : null}
            {activeSubmodule === "gallery" ? <Gallery title={"Gallery"} /> : null}
            {activeSubmodule === "news" ? <News title={"News"} /> : null}
            {activeSubmodule === "notice" ? (
              <Banner title={"Notice"} />
            ) : null}
            {activeSubmodule === "contactdirectory" ? (
              <ContactDirectory title={"Contact Directory"} />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export { HomeMaster };
