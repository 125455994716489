import React from 'react'
import { useEffect } from 'react';



const MyAttendanceComp = ({bgcolor, color, days, type, active, clickHandler}) => {
  useEffect(() => {
    document.title = `PeopleSol - My Attendance`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className={`attendanceBoxHolder cursor ${active && "attendanceBoxHolderActive"}`} onClick={clickHandler}>

        <div className="attendanceLeftBox" style={color}>{days}</div>
        <div className="attendanceRightBox">
            <div className="horizontalLinePrimary lineOverText" style={bgcolor}></div>
            <label className='dayLabel'>{type}</label>
            
        </div>
      
    </div>
  )
}

export {MyAttendanceComp}
