/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../../Components/FormComponent/FormCustomFormik";
import { useDispatch, useSelector } from "react-redux";
import { showToast, isLoading } from "../../../../Features";
import { getRequest, postRequest } from "../../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateRegularisationComp } from "./FormComp/CreateRegularisationComp";
import dateFormat from "dateformat";
import { EditRegularisationComp } from "./FormComp/EditRegularisationComp";
const CreateRegularisation = (props) => {
  const { state, pathname } = useLocation();
  const data = state?.id;
  const [detailsform, setDetailsform] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [formMessage, setFormMessage] = useState(null);

  const [regularizeData, setRegularizeData] = useState(null);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `AttendanceRegularizations/Details/${data}`
      );
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const [options, setOptions] = useState([
    { label: "Forgot to check-in", value: "Forgot to check-in" },
    { label: "Forgot my ID card", value: "Forgot my ID card" },
    { label: "Forgot to check-out", value: "Forgot to check-out" },
  ]);
  const getConfigData = async () => {
    // ;
    try {
      const configData = await postRequest(
        "AttendanceRegularizations/CheckRegularization",
        { from: formValues?.startDate, to: formValues?.endDate }
      );
    // console.log("configData", configData);
    // console.log("configData", configData?.data?.isSuccess);
      if (!configData?.data?.isSuccess) {
        navigate(-1);
        dispatch(
          showToast({
            text: configData?.data?.message,
            severity: "error",
          })
        );
      }
      setRegularizeData(configData);
      setOptions(
        configData?.data?.config?.reasonForRegularize.split(",").map((item) => {
          return { label: item, value: item };
        })
      );
    } catch (error) {
      // return;
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // getConfigData();
  }, []);

  const createPageData = async (values) => {
    let sDate = new Date(formValues.startDate);
    let eDate = new Date(formValues.endDate);
    if (regularizeData?.data?.config?.needInOut) {
      let isEligible = true;
      for (let i = 0; i < formValues?.Items.length; i++) {
        const item = formValues?.Items[i];
        if (!item?.oldEndTime || !item?.oldStartTime) {
          isEligible = false;
          break;
        }
      }
      if (!isEligible) {
        dispatch(
          showToast({
            text: "You are not eligible to create regularize!",
            severity: "error",
          })
        );
        return;
      }
    }
    let isEligible = true;

    for (let i = 0; i < values.length; i++) {
      const item = values[i];
      if (
        item?.newStartTime === "hh:mm" ||
        item?.newStartTime === null ||
        item?.newStartTime === undefined ||
        item?.newEndTime === "hh:mm" ||
        item?.newEndTime === null ||
        item?.newEndTime === undefined
      ) {
        isEligible = false;
        break;
      }
    }
    if (!isEligible) {
      dispatch(
        showToast({
          text: "New start time and new end time are required for all items!",
          severity: "error",
        })
      );
      return;
    }
    const diffDays = Math.ceil(Math.abs(sDate - eDate) / (1000 * 60 * 60 * 24));
    if (diffDays > regularizeData?.data?.config?.numberOfRegularizeDays) {
      dispatch(
        showToast({
          text: "You are not eligible to create regularize!",
          severity: "error",
        })
      );
      return;
    }

    for (let i = 0; i < values.length; i++) {
      const formattedNewStartTime = dateFormat(
        values[i].newStartTime,
        "yyyy-mm-dd hh:MM TT"
      );
      const formattedNewEndTime = dateFormat(
        values[i].newEndTime,
        "yyyy-mm-dd hh:MM TT"
      );
      const originalNewStartTime = new Date(formattedNewStartTime);
      const originalNewEndTime = new Date(formattedNewEndTime);
      values[i].newStartTime = dateFormat(originalNewStartTime, "hh:MM TT");
      values[i].newEndTime = dateFormat(originalNewEndTime, "hh:MM TT");
    }

    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "AttendanceRegularizations/Create",
        values
      );
      if (result) {
        if (values.length <= 0) {
          dispatch(
            showToast({
              text: "Choose another date and try again",
              severity: "error",
            })
          );
        } else {
          dispatch(
            showToast({ text: "Successfully Created", severity: "success" })
          );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      navigate(-1);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Regularisation`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const Edit = async (values) => {
    if (
      values?.newStartTime === "hh:mm" ||
      values?.newStartTime === null ||
      values?.newStartTime === undefined ||
      values?.newStartTime === "" ||
      values?.newEndTime === "hh:mm" ||
      values?.newEndTime === null ||
      values?.newEndTime === "" ||
      values?.newEndTime === undefined
    ) {
      dispatch(
        showToast({
          text: "New start time and new end time is required!",
          severity: "error",
        })
      );
      return;
    }
    values.newStartTime = dateFormat(values?.newStartTime, "hh:MM TT");
    values.newEndTime = dateFormat(values?.newEndTime, "hh:MM TT");
    try {
      setLoading(true);
      const result = await postRequest(
        "AttendanceRegularizations/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      setLoading(false);
      dispatch(showToast({ text: error?.response?.data, severity: "error" }));
      returnPage();
    }
  };

  const [option, setOption] = useState([]);

  const startingDate = dateFormat(formValues?.startDate, "yyyy-mm-dd");
  const endingDate = dateFormat(formValues?.endDate, "yyyy-mm-dd");

  const getAttendanceData = async () => {
    try {
      const result = await getRequest(
        `AttendanceRegularizations/GetData/${startingDate}/${endingDate}`
      );
      if (result.data.data === null) {
        dispatch(
          showToast({
            text:
              result?.data?.message === "No Content"
                ? "You are not eligible to create regularize!"
                : result?.data?.message,
            severity: "error",
          })
        );
        navigate(-1);
      } else {
        let listData = [];
        result?.data?.data?.map((item) => {
          return listData.push({
            date: dateFormat(item?.date, "yyyy-mm-dd"),
            oldStartTime: item?.oldStartTime,
            newStartTime: new Date(`12/12/12 ${LoggedInUser?.shift?.from}`),
            oldEndTime: item?.oldEndTime,
            newEndTime: new Date(`12/12/12 ${LoggedInUser?.shift?.to}`),
            Remarks: "",
          });
        });
        setOption(listData);
        setFormMessage(result?.data?.message);
      }
    } catch (error) {
    } finally {
    }
  };

  React.useEffect(() => {
    if (formValues?.startDate && formValues?.endDate) {
      getConfigData();
      getAttendanceData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.startDate, formValues?.endDate]);

  const getAttendanceValues = () => {
    let returnArray = [];
    data
      ? detailsform?.Items?.map((item) => {
          return returnArray.push({
            date: item.date,
            oldStartTime: item.oldStartTime,
            newStartTime: item.newStartTime,
            oldEndTime: item.oldEndTime,
            newEndTime: item.newEndTime,
            Remarks: item.Remarks,
          });
        })
      : (returnArray = option);

    return returnArray;
  };

  const initialvalue = {
    startDate: option.length > 0 ? formValues?.startDate : "",
    endDate: option.length > 0 ? formValues?.endDate : "",
    Items: getAttendanceValues(),
  };

  const validationSchema = Yup.object({
    startDate: Yup.date()
      .max(
        new Date(),
        `Start Date field must be earlier than ${dateFormat("dd-mm-yyyy")}`
      )
      .required("Please enter the Start Date"),
    endDate: Yup.date()
      .max(
        new Date(),
        `End Date field must be earlier than ${dateFormat("dd-mm-yyyy")}`
      )
      .required("Please enter the End Date"),
    Items: Yup.array().of(
      Yup.object().shape({
        Remarks: Yup.string()
          .max(499, "Remarks must be less than 500 characters")
          .required("Remarks required"),
      })
    ),
  });

  const valiSchema = Yup.object({
    remarks: Yup.string().required("Please enter Remarks"),
  });

  const onSubmit = (values) => {
    const edit = {
      id: detailsform?.data?.id,
      date: dateFormat(values?.date, "yyyy-mm-dd"),
      oldStartTime: values?.oldStartTime,
      oldEndTime: values?.oldEndTime,
      newStartTime: values?.newStartTime,
      newEndTime: values?.newEndTime,
      remarks: values?.remarks,
    };

    const requestArray = [];
    const create = {
      Items: values?.Items?.map((value) => {
        requestArray.push({
          date: value?.date,
          oldStartTime: value?.oldStartTime,
          newStartTime: value?.newStartTime,
          oldEndTime: value?.oldEndTime,
          newEndTime: value?.newEndTime,
          Remarks: value?.Remarks,
        });
        return null;
      }),
    };
    data ? Edit(edit) : createPageData(requestArray);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      FormHeading={data ? "Update Regularisation" : "Create Regularisation"}
      ButtonLabel={data ? "Update Regularisation" : "Create Regularisation"}
      onSubmit={onSubmit}
      loading={loading}
      validationSchema={data ? valiSchema : validationSchema}
      styles={{ width: "75%", maxWidth: "75%" }}
      initialvalue={
        detailsform
          ? {
              ...initialvalue,

              date: detailsform?.data?.date
                ? dateFormat(detailsform?.data?.date, "yyyy-mm-dd")
                : "",
              oldStartTime: detailsform?.data?.oldStartTime,
              newStartTime: detailsform?.data?.newStartTime
                ? new Date(`12/12/12 ${detailsform?.data?.newStartTime}`)
                : new Date(`12/12/12 ${LoggedInUser?.shift?.from}`),
              oldEndTime: detailsform?.data?.oldEndTime,
              newEndTime: detailsform?.data?.newEndTime
                ? new Date(`12/12/12 ${detailsform?.data?.newEndTime}`)
                : new Date(`12/12/12 ${LoggedInUser?.shift?.to}`),
              remarks: detailsform?.data?.remarks,
            }
          : initialvalue
      }
      returnPage={returnPage}
      propsDiv={
        data ? (
          <EditRegularisationComp
            detailsform={detailsform}
            regularizeData={options}
          />
        ) : (
          <CreateRegularisationComp formValues={formValues} loading={loading} />
        )
      }
    />
  );
};

export { CreateRegularisation };
