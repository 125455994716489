import React from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { useEffect } from "react";
import { InputCustomFormikLD } from "../../../../Components/FormComponent/InputCustomFormikLD";
import { FileUploadFormikLD } from "../../../../Components/FormComponent/FileUploadFormikLD";
import { SelectFormLD } from "../../../../Components/FormComponent/SelectFormLD";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SpinningCircleLoader } from "react-loaders-kit";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import { showToast } from "../../../../Features";
import { confirmAlert } from "react-confirm-alert";
import { useLocation } from "react-router-dom";

const opt = [
  { label: "Candidate Onboarding", value: "Candidate Onboarding" },
  { label: "Employee Onboarding", value: "Employee Onboarding" },
];
export const CandidateVideo = ({
  data,
  formValues,
  changeButton,
  setchangeButton,
  messaged,
  setVideoFileData,
  videoFileData,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [infos, setInfos] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(null);
  const [afterDelete, setAfterDelete] = useState(false);
  const [wrongFormat, setWrongFormat] = useState(false);

  function convertFileSize(bytes) {
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;

    if (megabytes >= 1) {
      return `${megabytes.toFixed(2)} MB`;
    } else {
      return `${kilobytes.toFixed(2)} KB`;
    }
  }

  const handleUpload = () => {
    if (changeButton) {
      handleVideoDeletion();
    } else {
      handleVideoUpoad();
    }
  };
  const loaderProps = {
    loading: true,
    size: 20,
    duration: 5,
    colors: ["#1296b0", "#ffffff"],
  };
  const handleVideoUpoad = async () => {
    let responseData;
    try {
      setLoadingUpload(true);
      const formData = new FormData();
      formData.append("files", formValues?.files);
      const response = await fetch(
        "https://media.solunxt.com/api/v1/Storage/Save?token=4681fc01-54cd-4506-bfc6-eddc2c04916c",
        {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: formData,
        }
      );
      responseData = await response.json();
      setVideoFileData(responseData?.data[0]);
      if (response.ok) {
        setchangeButton(true);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: `${responseData?.message}` ?? "Something went wrong",
          severity: "error",
        })
      );
    } finally {
      setLoadingUpload(false);
    }
  };

  // const handleVideoError = () => {
  //   // if (e) {
  //   confirmAlert({
  //     customUI: () => {
  //       return (
  //         <div className="videowarningPopup">
  //           <div className="videowarning">Warning!!!</div>
  //           <div className="videowarningcontent">
  //             Invalid URL. Please verify it or check for Ad Blockers or Faulty
  //             extensions.
  //           </div>
  //         </div>
  //       );
  //     },
  //   });
  //   // }
  // };
  const handleVideoDeletion = async () => {
    try {
      const url = videoFileData?.url;
      const stringWithoutLD = url.split("LD/")[1];
      const stringWithBackslashes = stringWithoutLD.replace(/\//g, "\\");
      setLoadingUpload(true);
      const response = await fetch(
        `https://media.solunxt.com/api/v1/Storage/Delete?token=4681fc01-54cd-4506-bfc6-eddc2c04916c&fileName=${stringWithBackslashes}`,
        {
          method: "DELETE",
          // headers: {
          //   "Content-Type": "application/json",
          // },
        }
      );
      if (response.ok) {
        setchangeButton(false);
        setAfterDelete(true);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "File Already Deleted",
          severity: "error",
        })
      );
    } finally {
      setLoadingUpload(false);
    }
  };
  // useEffect(() => {
  //   if (
  //     formValues?.videoUrl?.length > 0 &&
  //     !formValues?.videoUrl?.includes("https://www.youtube.com/")
  //   ) {
  //     handleVideoError();
  //   }
  // }, [formValues?.videoUrl]);
  const videoFormats = [
    ".mp4",
    ".avi",
    ".mov",
    ".wmv",
    ".flv",
    ".mkv",
    ".webm",
    ".mpeg",
    ".mpg",
    ".m4v",
    ".3gp",
    ".ogg",
    ".ogv",
    ".vob",
    ".gifv",
    ".asf",
  ];
  useEffect(() => {
    document.title = `PeopleSol - Candidate Video`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    if (
      !location.pathname?.includes("/hrmsmaster/onboarding/onboardingvideos")
    ) {
      if (changeButton) {
        handleVideoDeletion();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  useEffect(() => {
    if (formValues) {
      formValues.files = "";
      formValues.videoUrl = "";
      // formValues.duration = "";
    }
  }, [formValues?.SelectVideo]);
  useEffect(() => {
    setchangeButton(false);
  }, [formValues?.files]);
  useEffect(() => {
    if (formValues?.files) {
      if (
        !videoFormats.some((format) =>
          formValues?.files?.name?.endsWith(format)
        )
      ) {
        setWrongFormat(true);
      } else setWrongFormat(false);
    }
  }, [formValues?.files]);

  const videoOptions = [
    { label: "Video", value: "video" },
    { label: "URL", value: "url" },
  ];
  // if (formValues && data) {
  //   formValues.SelectVideo = "url";
  // }
  return (
    <div>
      <div>
        <LabelCustom labelName={"Title"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="title"
          type={"text"}
          placeholder={"Enter title"}
        />
      </div>
      <div>
        <LabelCustom labelName={"Type"} mandatory={true} />
        <SelectForm placeholder={"Select Type"} options={opt} name="type" />
      </div>
      <div style={{ marginBottom: "20px" }}>
        <div>
          <LabelCustom
            labelName={
              formValues?.SelectVideo === ""
                ? "Select Video from"
                : formValues?.SelectVideo === "url"
                ? "Video URL:"
                : "Video File"
            }
            mandatory={true}
          />
          <div
            className="SelectVideoLDMaster"
            style={{ position: "relative", marginBottom: "61px" }}
          >
            <SelectFormLD
              placeholder={"Select Video Source"}
              name={"SelectVideo"}
              options={videoOptions}
            />
            {formValues?.SelectVideo === "video" && (
              <div
                style={{
                  position: "absolute",
                  left: "2px",
                  top: "-9.5px",
                  width: "85%",
                }}
              >
                <FileUploadFormikLD
                  style={{ height: "39px", boxShadow: "none" }}
                  id="fileUp"
                  name={`files`}
                  setInfos={setInfos}
                  changeButton={changeButton}
                  afterDelete={afterDelete}
                  resetAfterDelete={() => setAfterDelete(false)}
                />
                <div className="FileuploadLDbutton">
                  <div className="FileuploadLDbuttonDiv1">
                    {loadingUpload && (
                      <div>
                        <SpinningCircleLoader
                          {...loaderProps}
                          // style={{
                          //   position: "absolute",
                          //   right: " 41px",
                          //   top: "9px",
                          // }}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        width: "100%",
                        paddingLeft: "12px",
                        color: "var(--secondary)",
                        fontSize: "12px",
                      }}
                    >
                      Size :
                      {`${
                        formValues?.files &&
                        convertFileSize(formValues?.files?.size)
                          ? convertFileSize(formValues?.files?.size)
                          : ""
                      }`}{" "}
                    </div>
                  </div>
                  <div>
                    {formValues?.files &&
                    formValues?.files?.size / 1048576 < 100 &&
                    !wrongFormat ? (
                      <div
                        onClick={
                          formValues?.files &&
                          formValues?.files?.size / 1048576 < 100 &&
                          handleUpload
                        }
                        className={"buttonLD primaryButton"}
                        style={changeButton ? { backgroundColor: "red" } : {}}
                      >
                        {changeButton ? "Delete" : "Upload"}
                      </div>
                    ) : (
                      <div
                        className={"buttonLD primaryLDButtonDisabled"}
                        style={changeButton ? { backgroundColor: "red" } : {}}
                      >
                        {changeButton ? "Delete" : "Upload"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {formValues?.SelectVideo === "url" && (
              <div
                style={{
                  position: "absolute",
                  left: "2px",
                  top: "-9.5px",
                  width: "85%",
                }}
              >
                <InputCustomFormikLD
                  style={{ height: "35px", boxShadow: "none" }}
                  maxLength={150}
                  name="videoUrl"
                  type={"text"}
                  placeholder={"Enter video URL"}
                />

                <div
                  style={{
                    width: "100%",
                    paddingLeft: "12px",
                    color: "var(--secondary)",
                    fontSize: "12px",
                  }}
                >
                  Note: Only YouTube video URLs are allowed.{" "}
                </div>
              </div>
            )}
            {messaged &&
              (formValues?.SelectVideo === "url"
                ? !formValues?.videoUrl
                : !formValues?.files) && (
                <div
                  className="form-error"
                  style={
                    formValues?.SelectVideo
                      ? { marginTop: " 32px" }
                      : { marginTop: "0px" }
                  }
                >
                  <GppMaybeOutlinedIcon
                    sx={{ height: "14px", width: "15px" }}
                    fontSize="small"
                    color="red"
                  />
                  Enter URL or Select File
                </div>
              )}
            {wrongFormat && (
              <div
                className="form-error"
                style={
                  formValues?.SelectVideo
                    ? { marginTop: " 32px" }
                    : { marginTop: "0px" }
                }
              >
                <GppMaybeOutlinedIcon
                  sx={{ height: "14px", width: "15px" }}
                  fontSize="small"
                  color="red"
                />
                Invalid file format. Please use a supported video format.
              </div>
            )}
          </div>
        </div>

        {formValues?.files && formValues?.files?.size / 1048576 >= 100 && (
          <div
            className="form-error"
            style={
              formValues?.SelectVideo
                ? { marginTop: " 32px" }
                : { marginTop: "0px" }
            }
          >
            <GppMaybeOutlinedIcon
              sx={{ height: "14px", width: "15px" }}
              fontSize="small"
              color="red"
            />
            File size exceeds 100 MB. Please upload a file below 100 MB.
          </div>
        )}
      </div>
      {/* <div>
        <LabelCustom labelName={"YouTube Video Url"} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="youTubeVideoUrl"
          type={"text"}
          placeholder={"Enter Video URL"}
        />
        <div
          style={{
            paddingLeft: "13px",
            fontSize: "12px",
            color: "var(--primary)",
          }}
        >
          http://www.youtube.com/watch?v=u8nQa1cJyX8
        </div>
      </div> */}
    </div>
  );
};
