import React, { useState } from "react";
import { Popup } from "../../../../../Components/FormComponent/PopupComponent/Popup";
import { useEffect } from "react";

import SocialTextLinkIcon from "../../../../../Assets/SocialTextLinkIcon.svg";
import SocialNewsEndLinkIcon from "../../../../../Assets/SocialNewsEndLinkIcon.svg";
import {
  docsArray,
  getAttachments,
  getExtension,
  imgArray,
  videoArray,
} from "../../../../../Utilities/socialAllFunctions";
import "./ShowFullAttachments.css";
import "./Connect.css";

import dateFormat from "dateformat";
import { socialPostImages } from "../../../../../Utilities/profileAndUploadImages";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import SocialPdfLeftArrow from "../../../../../Assets/SocialPdfLeftArrow.svg";
import SocialPdfRightArrow from "../../../../../Assets/SocialPdfRightArrow.svg";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { getRequest } from "../../../../../Services/axios";
import { showToast } from "../../../../../Features";
import SharePost from "./SharePost";
import CommentPost from "./CommentPost";
import LikeDislike from "./LikeDislike";
import { useNavigate, useParams } from "react-router-dom";
import PDFViewer from "./PDFViewer";
import ContentSocialDisplay from "./ContentSocialDisplay";
import { useSelector } from "react-redux";
import { setNewsData } from "../../../../../Features/SocialSlice";
import DetailsPostLikeDislikeComponent from "./DetailsPostLikeDislikeComponent";

function NewsPostDetails() {
  const [commentedPost, setCommentedPost] = useState(null);
  const [sharePostActive, setSharePostActive] = useState(false);
  const [sharePostData, setSharePostData] = useState();
  const [sharePostId, setSharePostId] = useState(0);
  const [totalLikesWatching, setTotalLikesWatching] = useState(false);
  const [totalDisLikesWatching, setTotalDisLikesWatching] = useState(false);
  const [likeId, setLikeId] = useState(0);
  const [dislikeId, setDislikeId] = useState(0);
  const [isImages, setIsImages] = useState(false);
  const [isVideos, setIsVideos] = useState(false);
  const [isDocs, setIsDocs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [index, setIndex] = useState(0);
  const [attachment, setAttachment] = useState(null);
  const { newsData } = useSelector((state) => state.social);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postid } = useParams();
  const getData = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`SocialPost/Details?PostId=${postid}`);
      const { data, summary } = result?.data;
      const mergedObject = { ...data, ...summary };
      setCommentedPost(mergedObject);
      setAttachment(getAttachments(result?.data?.data?.attachment));
      findAttach(getAttachments(result?.data?.data?.attachment));
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  const backToAnnouncement = () => {
    setAttachment(null);
    setCommentedPost(null);
    navigate("/home/social/news");
  };


  const findAttach = (attachment) => {
    if (attachment) {
      let x = getExtension(attachment[0]);
      if (imgArray.includes(x)) {
        setIsImages(true);
      } else if (videoArray.includes(x)) {
        setIsVideos(true);
      } else if (docsArray.includes(x)) {
        setIsDocs(true);
      }
      if (x !== null) {
        setNumPages(attachment?.length);
      }
    }
  };
  const nextSlide = (e) => {
    // e.stopPropagation();
    if (index < numPages - 1) {
      setIndex(index + 1);
      setPageNumber(pageNumber + 1);
    }
  };
  const prevSlide = (e) => {
    // e.stopPropagation();
    if (index > 0) {
      setIndex(index - 1);
      setPageNumber(pageNumber - 1);
    }
  };
  useEffect(() => {
    const keyHandler = (event) => {
      if (event.code === "ArrowRight") {
        nextSlide();
      } else if (event.code === "ArrowLeft") {
        prevSlide();
      }
    };
    document.addEventListener("keydown", keyHandler, false);
    return () => document.removeEventListener(keyHandler);
  }, []);
  useEffect(() => {
    getData();
  }, [postid]);
  useEffect(() => {
    return () => {
      setCommentedPost(null);
    };
  }, [postid]);
  return (
    <div className="showAttachmentsWithCommentContainer">
      {commentedPost !== null && !loading && (
        <Popup
          firstClassName="showAttachmentsWithCommentPopupbox1"
          secondClassName="showAttachmentsWithCommentPopupbox2"
          createMyHead="showAttachmentsWithCommentCreateHead"
          content={
            <>
              <div className="showAttachmentsWithCommentWrapper">
                {isImages && (
                  <div className="showAttachmentsWithCommentImgContainer">
                  <div >
                    <img
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        socialPostImages +
                        attachment[index]
                      }
                      alt=""
                    />

                  </div>
                  </div>
                )}
                {isVideos && (
                  <div
                    className="showFullAttachmentListBox"
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <video
                      src={
                        process.env.REACT_APP_.BASE_URL +
                        socialPostImages +
                        attachment[index]
                      }
                      controls
                      style={{ padding: "12px", width: "100%", height: "89%" }}
                    />
                  </div>
                )}
                {isDocs && (
                  <div
                    className="showFullAttachmentListBox"
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <PDFViewer
                      pdfUrl={
                        process.env.REACT_APP_.BASE_URL +
                        socialPostImages +
                        attachment[index]
                      }
                    />
                  </div>
                )}
                {!isImages && !isVideos && !isDocs && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                      color: "#bfbfbf",
                    }}
                  >
                    No Attachment Found!
                  </div>
                )}
                {attachment !== null && attachment?.length > 0 && (
                  <>
                    <div className="leftPdfArrow">
                      {index === 0 || (
                        <img
                          src={SocialPdfLeftArrow}
                          alt=""
                          onClick={(e) => {
                            prevSlide(e);
                          }}
                        />
                      )}
                    </div>
                    <div className="rightPdfArrow">
                      {index === numPages - 1 || (
                        <img
                          src={SocialPdfRightArrow}
                          alt=""
                          onClick={(e) => {
                            nextSlide(e);
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="showAttachmentsWithCommentListContainer">
                <div className="showAttachmentsWithCommentListWrapper">
                  <div
                    className="showAttachmentsWithCommentListHeaderContainer"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div className="socialPostTitleAndTimeContainer">
                      <label>
                        {commentedPost?.news?.title?.length > 40
                          ? commentedPost?.news?.title?.substring(0, 40) + "..."
                          : commentedPost?.news?.title}
                      </label>
                      <span className="postHrTime">
                        •{" "}
                        {dateFormat(
                          commentedPost?.createdOn,
                          "hh:MM TT | dd mmm yyyy"
                        )}
                      </span>
                    </div>
                    <IconButton
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <img src={CloseIcon} alt="" />
                    </IconButton>
                  </div>

                  <div className="showAttachmentsWithCommentListBodyContainer">
                    <div className="showAttachmentsWithCommentListBodyWrapper">
                      <div className="">
                        <p>
                          <ContentSocialDisplay
                            content={commentedPost?.content}
                          />
                        </p>
                      </div>
                      {commentedPost?.news && (
                        <div
                          className="newsLinkContainer"
                          style={{ margin: "20px 0" }}
                        >
                          <div className="newsLinkWrapper">
                            <div className="newsLink">
                              <img src={SocialTextLinkIcon} alt="" />{" "}
                              <a
                                href={commentedPost?.news?.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span>
                                  {" "}
                                  {commentedPost?.news?.link?.length > 25
                                    ? commentedPost?.news?.link?.substring(
                                        0,
                                        25
                                      ) + "..."
                                    : commentedPost?.news?.link}
                                </span>
                              </a>
                            </div>
                            <img
                              style={{ width: "29px" }}
                              src={SocialNewsEndLinkIcon}
                              alt=""
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="showAttachmentsWithCommentListActivityContainer"
                      style={{ position: "relative" }}
                    >
                      <DetailsPostLikeDislikeComponent
                        commentedPost={commentedPost}
                        setSharePostData={setSharePostData}
                        setSharePostId={setSharePostId}
                        setLikeId={setLikeId}
                        setTotalLikesWatching={setTotalLikesWatching}
                        setDislikeId={setDislikeId}
                        setTotalDisLikesWatching={setTotalDisLikesWatching}
                        setCommentedPost={setCommentedPost}
                        // setShareDetailsActive={setShareDetailsActive}
                        postData={newsData}
                        setPostData={setNewsData}
                        getData={getData}
                        setSharePostActive={setSharePostActive}
                      />
                    </div>
                    <CommentPost
                      commentedPost={commentedPost}
                      setCommentedPost={setCommentedPost}
                      getData={getData}
                      postData={newsData}
                      setPostData={setNewsData}
                    />
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={backToAnnouncement}
        />
      )}
      {sharePostActive && (
        <SharePost
          sharePostData={sharePostData}
          sharePostId={sharePostId}
          getData={getData}
          setSharePostActive={setSharePostActive}
          getExtension={getExtension}
          getAttachments={getAttachments}
        />
      )}
      {totalLikesWatching && (
        <LikeDislike
          id={likeId}
          setTotalLikesWatching={setTotalLikesWatching}
          totalLikesWatching={totalLikesWatching}
        />
      )}
      {totalDisLikesWatching && (
        <LikeDislike
          id={dislikeId}
          setTotalDisLikesWatching={setTotalDisLikesWatching}
          totalDisLikesWatching={totalDisLikesWatching}
        />
      )}
    </div>
  );
}

export default NewsPostDetails;
