import React from 'react'
const ProgressbarProf = ({bgcolor,progress}) => {
	
	const Parentdiv = {
		height: '4px',
    width: '218px',
    backgroundColor: '#BFBFBF',
    borderRadius: '54px',
   marginLeft:'3px',
   position:'absolute'
	}
	
	const Childdiv = {
		height: '100%',
		width: `${progress}%`,
		backgroundColor: bgcolor,
	  borderRadius:40,
		textAlign: 'right'
	}
	
	const progresstext = {
        
		padding: 10,
		color: 'black',
		fontWeight: 900
	}

	return (
	<div style={Parentdiv}>
	<div style={Childdiv}>
		<span style={progresstext}></span> <span style={{background: "var(--Secondery, #F7941D)",borderRadius:'6.5px',padding:'0 4px',fontSize:'10px',color:'white',right:'-50px'}} className={`countLabel progressCount`}>{`${progress}%`}</span>
	</div>
	</div>
	)
}
export {ProgressbarProf};