import OnboardingSidePanelBackground from "../../../../../Assets/OnboardingSidePanelBackground.svg";
import SeperationSidePanelImg from "../../../../../Assets/SeperationSidePanelImg.svg";
import AdminOnboardingSideImageActive from "../../../../../Assets/AdminOnboardingSideImageActive.svg";
import OnboardingSidePanelLine from "../../../../../Assets/OnboardingSidePanelLine.svg";
import { Form, Formik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./MasterAdminOnboarding.css";
import * as Yup from "yup";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { useLocation, useNavigate } from "react-router-dom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { DragFormik } from "../../../../../Components/FormComponent/Upload/DragFormik";
import {
  getRequest,
  postRequest,
  postRequestForm,
} from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { format } from "date-fns";
// import { MyDetailsResignation } from "./Details/MyDetailsResignation";
// import { ViewDue } from "./PopupForm/ViewDue";
// import { DetailsPageFinalSettlement } from "./PopupForm/DetailsPageFinalSettlement";
// import { ExitInterview } from "./PopupForm/ExitInterview";
// import { DetailsExitFeedback } from "./Details/DetailsExitFeedback";
import { Load } from "../../../../../Components/FormComponent/Loader";
import { CompanyOnboard } from "./CompanyOnboard";
import { DepartmentOnboard } from "./DepartmentOnboard";
import { BandOnboard } from "./BandOnboard";
import { DesignationOnboard } from "./DesignationOnboard";
import { RoleOnboard } from "./RoleOnboard";
import { PersonalAreaOnboard } from "./PersonalAreaOnboard";
import { PersonalSubAreaOnboard } from "./PersonalSubAreaOnboard";
import { AreaOnboard } from "./AreaOnboard";
import { EmployeeOnboard } from "./EmployeeOnboard";
import { Company } from "../../../../HRMSMaster/OrgStructureComponents/Company";
import { Department } from "../../../../HRMSMaster/OrgStructureComponents/Department";
import { Band } from "../../../../HRMSMaster/OrgStructureComponents/Band";
import { Designation } from "../../../../HRMSMaster/OrgStructureComponents/Designation";
import { Role } from "../../../../HRMSMaster/OrgStructureComponents/Role";
import { PersonalArea } from "../../../../HRMSMaster/OrgStructureComponents/PersonalArea";
import { PersonalSubArea } from "../../../../HRMSMaster/OrgStructureComponents/PersonalSubArea";
import { Area } from "../../../../HRMSMaster/OrgStructureComponents/Area";
import { Employee } from "../../../../HRMSMaster/OrgStructureComponents/Employee";
// import { ExitLetters } from "./PopupForm/ExitLetters";

const MasterAdminOnboarding = () => {

  useEffect(() => {
    document.title = `PeopleSol - Master Admin Onboard`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const handleTabChange = (event, newTabIndex) => {
    if (newTabIndex === 0) {
      navigate("adminonboarding/company");
    } else if (newTabIndex === 1) {
      navigate("adminonboarding/department");
    } else if (newTabIndex === 2) {
      navigate("adminonboarding/band");
    } 
    else if (newTabIndex === 3) {
      navigate("adminonboarding/designation");
    }
    else if (newTabIndex === 4) {
      navigate("adminonboarding/role");
    }
    else if (newTabIndex === 5) {
      navigate("adminonboarding/personalarea");
    }
    else if (newTabIndex === 6) {
      navigate("adminonboarding/personalsubarea");
    }
    else if (newTabIndex === 7) {
      navigate("adminonboarding/area");
    }
    else if (newTabIndex === 8) {
      navigate("adminonboarding/employee");
    }
  };
  const location = useLocation();

  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrms/adminonboarding")) {
      if (currentpath.includes("company")) {
        setTabIndex(0);
      } else if (currentpath.includes("department")) {
        setTabIndex(1);
      } else if (currentpath.includes("band")) {
        setTabIndex(2);
      } 
      else if (currentpath.includes("designation")) {
        setTabIndex(3);
      } 
      else if (currentpath.includes("role")) {
        setTabIndex(4);
      } 
      else if (currentpath.includes("personalarea")) {
        setTabIndex(5);
      } 
      else if (currentpath.includes("personalsubarea")) {
        setTabIndex(6);
      } 
      else if (currentpath.includes("area")) {
        setTabIndex(7);
      } 
      else if (currentpath.includes("employee")) {
        setTabIndex(8);
      } 
      else {
        navigate("adminonboarding/company");
      }
    }
    // getDashboard();
  }, [location.pathname]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  // const handleTabChange = (event, newTabIndex) => {
  //   setTabIndex(newTabIndex);
  // };
  const stepControlFunction = () => {};
  const onSubmit = (item) => {
    // //console.log(item)
  };
  return (
    <>
      <FormikStepper
        onSubmit={async (values) => {
          onSubmit(values);
        }}
        stepControlFunction={stepControlFunction}
      >
        <FormikStep label="Company">
          <Company header={"company"} />
        </FormikStep>

        <FormikStep label="Department">
          <Department header={"department"} width={"100%"} height={"100%"} />
        </FormikStep>

        <FormikStep label="Band">
          <Band header={"band"} width={"100%"} height={"100%"} />
        </FormikStep>

        <FormikStep label="Designation" style={{ position: "relative" }}>
          <Designation header={"designation"} width={"100%"} height={"100%"} />
        </FormikStep>

        <FormikStep label="Role">
          <Role header={"role"} width={"100%"} height={"100%"} />
        </FormikStep>

        <FormikStep label="Personal Area">
          <PersonalArea header={"personalarea"} width={"100%"} height={"100%"} />
        </FormikStep>

        <FormikStep label="Personal Sub Area">
          <PersonalSubArea header={"personalsubarea"} width={"100%"} height={"100%"} />
        </FormikStep>

        <FormikStep label="Area">
          <Area header={"area"} width={"100%"} height={"100%"} />
        </FormikStep>

        <FormikStep label="Employee">
          <Employee header={"employee"} width={"100%"} height={"100%"} />
        </FormikStep>
      </FormikStepper>
    </>
  );
};
export function FormikStep({ children }) {
  return <>{children}</>;
}
export const FormikStepper = forwardRef((props, ref) => {
  const childrenArray = React.Children.toArray(props.children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const headerClicked = async (clickedStep) => {
    setStep(clickedStep);
  };
  const stepFromParent = (step) => setStep(step);
  useImperativeHandle(ref, () => ({
    stepFromParent,
  }));
  function isLastStep() {
    return step === childrenArray.length - 1;
  }
  function InterViewStep() {
    return step === childrenArray.length - 2;
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stepOptions = [
    {label: 1, value: 1},
    {label: 2, value: 2},
    {label: 3, value: 3},
    {label: 4, value: 4},
    {label: 5, value: 5},
    {label: 6, value: 6},
    {label: 7, value: 7},
    {label: 8, value: 8},
    {label: 9, value: 9},
  ]

  return (
    <Formik {...props}>
      <Form style={{ height: "100%" }}>
        <div className="seperationInnerContainer">
          <div className="seperationSidePanel adminSidePanel">
            
            <div className="onboardingSidePanelContainer">
              <img
                style={{
                  height: "100%",
                  position: "absolute",
                  left: "22px",
                  zIndex: 1,
                }}
                src={OnboardingSidePanelLine}
                alt="OnboardingSidePanelLine"
              />
              {childrenArray.map((child, index) => (
                <div
                  className={`onboardingSidePanelTile ${
                    index === step && "onboardingSidePanelTileActive"
                  } ${index < step && "cursor"}`}
                  onClick={() => headerClicked(index)}
                  key={child.props.label}
                >
                  {index === step ? (
                    <img
                      src={AdminOnboardingSideImageActive}
                      alt="OnboardingSidePanelImg"
                    />
                  ) : (
                    <div className="sidePanelStepCount">
                    {index + 1}
                    </div>
                  )}
                  <label>{child.props.label}</label>
                </div>
              ))}
            </div>
            <div className="skipTextContainer">
            <img
              style={{
                width: "100%",
                bottom: 0,
                position: "absolute",
                right: 0,
                opacity:'0.5',
              }}
              src={OnboardingSidePanelBackground}
              alt="OnboardingSidePanelBackground"
            />
              <label className="cursor" style={{zIndex:'3'}} onClick={() => navigate("/")}>Skip & Do it later</label>
            </div>
          </div>
          <div className="onboardingMainContainer" style={{ gap: "0px" }}>
            <div
              className="seperationFormContainer"
              style={{
                height: "100%",
                background: "white",
                overflow: "auto",
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {currentChild}
            </div>
            <div
              className="createFootSep"
              style={{
                padding: "0px 12px",
                background: "!important",
              }}
            >
              <div style={{ display: "flex", gap: "12px" }}>
                {step >= 1 && ( <button
                  type="button"
                  className="button backButton noMargin"
                  onClick={() => setStep((s) => s - 1)}
                >
                  {"< Back"}
                </button>)}

                <button
                  className="button primaryButton noMargin"
                  type="button"
                  onClick={() => isLastStep() ? navigate("/") : setStep((s) => s + 1)}
                >
                  {isLastStep() ? "Onboarding Complete" : "Next >"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
});
export { MasterAdminOnboarding };
