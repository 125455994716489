import React, { useState, useEffect } from "react";

import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { SelectFormikMulti } from "../../../../../Components/FormComponent/SelectFormikMulti";
import { useLocation } from "react-router-dom";

export const CreateInductionComp = ({ data, formValues }) => {
  const dispatch = useDispatch();
  // const [employeeList, setEmployeeList] = useState([]);
  const [listData, setListData] = React.useState([]);
  const [option, setOption] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalListLength, setTotalListLength] = React.useState(1);
  const [searchParamemployee, setSearchParamemployee] = React.useState("");
  const [countNonExist, setCountNonExist] = React.useState("");
  const locationPath = useLocation();
  let currentUrl = locationPath?.pathname;
  const getOption = async (idArr, searchString) => {
    try {
      let result;
      let count = 0;
      if (searchString) {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?q=${searchString}`,
          idArr
        );
        let searchData = [];
        setTotalListLength(result?.data?.total);
        result.data.results?.map((item) => {
          return searchData.push({ label: item.text, value: item.id });
        });
        setOption(searchData);
      } else {
        result = await postRequest(
          `Employees/GetEmployeesDropDown?i=${page}`,
          idArr
        );
        setTotalListLength(result?.data?.total);
        let newItems = [];
        result.data.results?.forEach((item) => {
          const exists = listData.some(
            (existingItem) => existingItem.value === item.id
          );
          if (!exists) {
            return newItems.push({ label: item.text, value: item.id });
          } else {
            count = count + 1;
          }
        });
        setCountNonExist(count);
        setListData((prevData) => [...prevData, ...newItems]);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
    }
  };
  const handleScrollToBottom = () => {
    if (listData?.length < totalListLength) getOption([]);
  };

  React.useEffect(() => {
    if (searchParamemployee) {
      getOption([], searchParamemployee);
    }
  }, [searchParamemployee]);

  React.useEffect(() => {
    if (data) {
      setPage(0);
    } else setPage(1);
    setListData([]);
  }, [currentUrl]);

  React.useEffect(() => {
    if (data && data?.summary) {
      setPage(0);
      const idArray = [data?.summary?.employeeId];

      idArray?.length > 0 ? getOption(idArray) : getOption([]);
    } else getOption([]);
  }, [data?.summary]);

  React.useEffect(() => {
    setListData([]);
  }, []);
  React.useEffect(() => {
    if (countNonExist > 14 && listData?.length < totalListLength) getOption([]);
  }, [countNonExist]);

  useEffect(() => {
    document.title = `PeopleSol - Create Induction`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  return (
    <div className="formcustom twoColumn">
      <div>
        <LabelCustom labelName={"Employees"} mandatory={true} />
        {data ? (
          <SelectForm
            disabled={true}
            name="EmployeeIds"
            // options={employeeList}
            onMenuScrollToBottom={handleScrollToBottom}
            options={searchParamemployee ? option : listData}
            onInputChange={(e) => setSearchParamemployee(e)}
          />
        ) : (
          <SelectFormikMulti
            // values={empArr}
            name={`EmployeeIds`}
            // options={employeeList}
            onMenuScrollToBottom={handleScrollToBottom}
            options={searchParamemployee ? option : listData}
            onInputChange={(e) => setSearchParamemployee(e)}
          />
        )}
      </div>

      <div>
        <LabelCustom labelName={"Induction Date"} mandatory={true} />
        <DatePickerFormiks name="InductionDate" minDate={new Date()} />
      </div>
      <div>
        <LabelCustom labelName={"Second Induction Date"} mandatory={true} />
        <DatePickerFormiks
          name="SecondInductionDate"
          minDate={
            formValues?.InductionDate
              ? formValues?.InductionDate !== ""
                ? new Date(formValues?.InductionDate)
                : new Date()
              : new Date()
          }
        />
      </div>
      <div>
        <LabelCustom labelName={"Upload Attachment"} />
        <FileUploadFormik
          name="Upload"
          attachTextLabel={data?.attachment?.toString()}
        />
        <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
          Size: 1 MB
        </span>
        <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
          Format: PDF, Doc
        </span>
      </div>
    </div>
  );
};
