import { Form, Formik } from "formik";

import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import dateFormat from "dateformat";
import { useEffect } from "react";

export const ChangeDate = ({
  id,
  data,
  type,
  color,
  handleClose,
  Relieving,
}) => {
  const onSubmit = async (values) => {
    const val = {
      ...values,
      ApprovedRelievingDate: dateFormat(
        values?.ApprovedRelievingDate,
        "yyyy-mm-dd"
      ),
      id: id,
    };
    const res = {
      CreatedOn: dateFormat(values?.CreatedOn, "yyyy-mm-dd"),
      id: id,
    };
    if (type === "Change Resignation Date") data(type, res);
    else data(type, val);
  };
  useEffect(() => {
    document.title = `PeopleSol - Change Date`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <Formik
    enableReinitialize
      initialValues={{ ApprovedRelievingDate: new Date(Relieving) }}
      onSubmit={onSubmit}
    >
      <Form>
        <LabelCustom labelName={"Date"} mandatory={true} />
        <DatePickerFormiks name={"ApprovedRelievingDate"} />
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ color: "white" }}
          >
            {type}
          </button>
        </div>
      </Form>
    </Formik>
  );
};
