import { IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Popup } from "../../../Components/FormComponent/PopupComponent/Popup";
import { Remark } from "../../../Components/FormComponent/PopupComponent/Remark";
import { Notifications } from "../../HRMS/Components/DetailsComp/Notifications";
import { Timeline } from "../../HRMS/Components/DetailsComp/TimeLine";
import { Details } from "../../HRMS/Components/DetailsComp/Details";
import { Loader } from "../../../Components/FormComponent/Loader";
import { getStatusColor } from "../../../Utilities/getStatusColor";
import { useSelector } from "react-redux";
import { getBadgeCount } from "../../../Features/badgeUtils";
import { format } from "date-fns";

const DetailsPageGatePass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const closeDetails = () => {
    returnPage();
  };
  const currentUrl = window.location.href;
  function getIdFromDynamicUrl(url) {
    const urlObject = new URL(url);
    const segments = urlObject.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const idSegmentIndex = segments.indexOf("details");
    if (idSegmentIndex !== -1 && idSegmentIndex < segments.length - 1) {
      return segments[idSegmentIndex + 1];
    }
    return null; // Return null if id not found in the URL
  }
  const { LoggedInUser } = useSelector((state) => state.auth);

  function extractValuesFromURL(url) {
    //debugger;
    const regex = /\/details\/(\d+)\/\?action=(approve|reject|hold)&eid=(\d+)/;
    const matches = url.match(regex);

    if (matches) {
      const value1 = matches[1]; // "246"
      const approve = matches[2]; // "approve"
      const value2 = Number(matches[3]); // "16"

      return { value1, approve, value2 };
    } else {
      return { value1: null, approve: null, value2: null };
    }
  }
  const { value2, approve } = extractValuesFromURL(currentUrl);

  const [isOpen, setIsOpen] = useState(false);
  const [selId, setSelId] = useState("");

  const togglePopup = (id, type, color) => {
    //debugger;
    setSelId(id);
    setTypeAction(type);
    setColorAction(color);
    setIsOpen((prev) => !prev);
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const getDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `GatePassRequestsController/Details?Id=${getIdFromDynamicUrl(
          currentUrl
        )}`
      );
      setData(result.data);

      if (approve) {
        if (
          LoggedInUser?.id === value2 &&
          result.data?.summary?.actionList.length
        ) {
          if (approve === "reject" || approve === "hold") {
            togglePopup(
              result.data?.summary?.actionList[0]?.id,
              approve,
              result.data?.summary?.actionList[0]?.color
            );
          } else
            PostApRejHol(
              approve,
              result.data?.summary?.actionList[0]?.id,
              "Remark"
            );
        }
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - GatePass Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (type === "Cancel" || type === "Release") {
        await getRequest(`GatePassRequestsController/${type}?id=${id}`);
      } else if (type === "Mark as Closed") {
        await getRequest(
          `GatePassRequestsController/Close?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Approve") {
        await getRequest(`GatePassRequestsController/Approve?Id=${id}`);
      } else if (type === "approve") {
        await getRequest(`GatePassRequestsController/Approve?Id=${id}`);
      } else if (type === "Reject") {
        await postRequest(
          `GatePassRequestsController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "reject") {
        await postRequest(
          `GatePassRequestsController/Reject?Id=${id}&Remarks=${Remark}`
        );
      } else if (type === "Add out Time ") {
        await getRequest(`GatePassRequestsController/AddOutTime?ID=${id}`);
      } else if (type === "Add in Time ") {
        await getRequest(`GatePassRequestsController/AddInTime?ID=${id}`);
      } else {
        await getRequest(`GatePassRequestsController/${type}/${id}/${Remark}`);
      }
      dispatch(showToast({ text: "Success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      await getBadgeCount(dispatch);
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getDetails();
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes("gatepass")) getDetails();
  }, [LoggedInUser.id]);
  // console.log("data", data);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  // console.log("selectedId",selectedId);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Gate Pass Details</h3>
          <IconButton onClick={() => closeDetails()}>
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="tabularMasterDetailsPageBody">
            <div className="tabContainer">
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{ style: { background: "#1296B0" } }}
              >
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Details
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Timeline
                    </span>
                  }
                />
                <Tab
                  style={{ minWidth: 130 }}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Notifications
                    </span>
                  }
                />
              </Tabs>
              <div className="tabContainerLine"></div>
            </div>
            {data && (
              <>
                {tabIndex === 0 &&
                  (data != null ? (
                    <div
                      className="detailsEReq"
                      style={{ flexDirection: "column" }}
                    >
                      <div className="headingForm"> Request Details</div>
                      <div className="detailInner">
                        <>
                          <Details
                            detailTitle={"Request Number"}
                            Details={data?.data?.requestNumber}
                          />
                          <Details
                            detailTitle={"Type"}
                            Details={data?.data?.type}
                          />
                          <Details
                            detailTitle={"Employee"}
                            Details={data?.data?.employee?.nameWithPersonNumber}
                          />
                          <Details
                            detailTitle={"Department"}
                            Details={data?.data?.department}
                          />
                          <Details
                            detailTitle={"Place"}
                            Details={data?.data?.place}
                          />
                          <Details
                            detailTitle={"Return time to be captured?"}
                            Details={data?.data?.willReturn ? "Yes" : "No"}
                          />

                          <Details
                            detailTitle={"Date and Out time"}
                            Details={
                              data?.data?.date &&
                              format(
                                new Date(data?.data?.date?.replace("T", " ")),
                                "dd/MM/yyyy hh:mm a"
                              )
                            }
                          />

                          <Details
                            detailTitle={"Return Time"}
                            Details={
                              data?.data?.willReturn
                                ? data?.data?.returnTime &&
                                  format(
                                    new Date(data?.data?.returnTime),
                                    "hh:mm a"
                                  )
                                : "Will Not Return"
                            }
                          />

                          <Details
                            detailTitle={"Contact Person"}
                            Details={data?.data?.contactPerson}
                          />
                          <Details
                            detailTitle={"Alternate Phone Number"}
                            Details={data?.data?.alternatePhnNo}
                          />
                          <Details
                            detailTitle={"Phone Number"}
                            Details={data?.data?.phnNo}
                          />
                          <Details
                            detailTitle={"Purpose"}
                            Details={data?.data?.purpose}
                          />
                          <Details
                            detailTitle={"Out Time"}
                            Details={
                              data?.data?.outTime
                                ? format(
                                    new Date(
                                      data?.data?.outTime?.replace("T", " ")
                                    ),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                          <Details
                            detailTitle={"Guard who entered Out time"}
                            Details={
                              data?.summary?.outguard?.fullName
                                ? data?.summary?.outguard?.fullName
                                : "-"
                            }
                          />
                          {data?.data?.willReturn && (
                            <Details
                              detailTitle={"In Time"}
                              Details={
                                data?.data?.willReturn
                                  ? data?.data?.inTime
                                    ? format(
                                        new Date(
                                          data?.data?.inTime?.replace("T", " ")
                                        ),
                                        "dd/MM/yyyy hh:mm a"
                                      )
                                    : "-"
                                  : "Will Not Return"
                              }
                            />
                          )}
                          {data?.data?.willReturn && (
                            <Details
                              detailTitle={"Guard who entered In time"}
                              Details={
                                data?.summary?.inguard?.fullName
                                  ? data?.summary?.inguard?.fullName
                                  : "-"
                              }
                            />
                          )}

                          <Details
                            detailTitle={"Created On"}
                            Details={
                              data?.data?.createdOn
                                ? format(
                                    new Date(
                                      data?.data?.createdOn?.replace("T", " ")
                                    ),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />

                          <Details
                            detailTitle={"Status"}
                            Details={
                              data?.data?.status === "Intime Marked" ||
                              data?.data?.status === "OutTime Marked" ? (
                                <div
                                  style={{
                                    backgroundColor: getStatusColor(
                                      data?.data?.status
                                    )?.split(",")?.[0],
                                    color: getStatusColor(
                                      data?.data?.status
                                    )?.split(",")?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {data?.data?.status}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: getStatusColor(
                                      data?.summary?.statuslabel
                                    )?.split(",")?.[0],
                                    color: getStatusColor(
                                      data?.summary?.statuslabel
                                    )?.split(",")?.[1],
                                    cursor: "default",
                                    padding: "6px",
                                    borderRadius: "6px",
                                  }}
                                >
                                  {data?.summary?.statuslabel}
                                </div>
                              )
                            }
                          />
                          <Details
                            detailTitle={"Last Updated On"}
                            Details={
                              data?.data?.lastUpdatedOn
                                ? format(
                                    new Date(
                                      data?.data?.lastUpdatedOn?.replace(
                                        "T",
                                        " "
                                      )
                                    ),
                                    "dd/MM/yyyy hh:mm a"
                                  )
                                : "-"
                            }
                          />
                        </>
                      </div>
                      <div className="headingForm"> Requester's Details</div>
                      <div className="detailInner">
                        <Details
                          detailTitle={"Requester Name"}
                          Details={`${data?.data?.employee?.name} ${data?.data?.employee?.lastName}`}
                        />
                        <Details
                          detailTitle={"Requester's Manager"}
                          Details={data?.data?.employee?.rO_Name}
                        />
                        <Details
                          detailTitle={"Requester Designation"}
                          Details={data?.data?.employee?.designation}
                        />
                        <Details
                          detailTitle={"Requester Department"}
                          Details={data?.data?.employee?.department}
                        />
                        <Details
                          detailTitle={"Requester Email"}
                          Details={data?.data?.employee?.email}
                        />
                        <Details
                          detailTitle={"Contact Number"}
                          Details={data?.data?.employee?.personalPhoneNumber}
                        />

                        <Details
                          detailTitle={"Location"}
                          Details={data?.data?.employee?.locationName}
                        />
                      </div>
                      {data?.summary?.actionList ? (
                        <div
                          className="createFootDetails"
                          style={{ background: "white !important" }}
                        >
                          {data?.summary?.actionList?.map((item) => (
                            <div>
                              <div
                                className="button secondaryButton"
                                onClick={() => {
                                  item.type !== "Approve" &&
                                  item.type !== "Release" &&
                                  item.type !== "Cancel"
                                    ? togglePopup(
                                        item?.id,
                                        item?.type,
                                        item?.color
                                      )
                                    : PostApRejHol(item?.type, item?.id);
                                }}
                                style={{
                                  background: item?.color,
                                  color: "white",
                                }}
                              >
                                {item?.type}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        ""
                      )}
                      {isOpen && (
                        <Popup
                          popupHeading={typeAction}
                          content={
                            <>
                              <Remark
                                data={PostApRejHol}
                                type={typeAction}
                                id={selId}
                                color={colorAction}
                                handleClose={togglePopup}
                              />
                            </>
                          }
                          handleClose={togglePopup}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="noDatastyle">
                      <Loader />
                      <img
                        src={Nodata}
                        alt="No data"
                        style={{ height: "150px", width: "250px" }}
                      />
                      <h4>{"No Data Found"}</h4>
                    </div>
                  ))}
                {tabIndex === 1 && (
                  <>
                    <div className="tabularMasterDetailsPageTabBodyContainer">
                      <div style={{ display: "flex", width: "100%" }}>
                        <Timeline
                          id={getIdFromDynamicUrl(currentUrl)}
                          module={"Gate Pass Requests"}
                          requestNumber={data?.data?.requestNumber}
                        />
                      </div>
                    </div>
                  </>
                )}

                {tabIndex === 2 && (
                  <>
                    <div
                      className="tabularMasterDetailsPageTabBodyContainer"
                      style={{ flexGrow: "initial" }}
                    >
                      <Notifications
                        id={getIdFromDynamicUrl(currentUrl)}
                        module={"Gate Pass Requests"}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsPageGatePass };
