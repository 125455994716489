import React, { useEffect } from "react";
import "./TimeConfig.css";
import { getRequest, postRequest } from "../../../Services/axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Features";
import { SelectForm } from "../../../Components/FormComponent/SelectForm";
import { validateDate } from "@mui/x-date-pickers/internals";
import { SelectCustom } from "../../../Components/FormComponent/SelectCustom";
import { SelectMultiString } from "../../../Components/FormComponent/SelectMultiString";

function UpdateTimeAttendanceConfig({
  setIsTimeConfigUpdateActive,
  timeAttendanceInitialValues,
  getTimeAttendanceConfigData,
  isTimeConfigUpdateActive
}) {
  const [timeAttendanceValues, settimeAttendanceValues] = useState({
    mobileLogIn: timeAttendanceInitialValues.mobileLogIn,
    geofence: timeAttendanceInitialValues.geofence,
    faceReader: timeAttendanceInitialValues.faceReader,
    webLogIn: timeAttendanceInitialValues.webLogIn,
    captureLocation: timeAttendanceInitialValues.captureLocation,
    locationMandatory: timeAttendanceInitialValues.locationMandatory,
    ipAddressList: timeAttendanceInitialValues.ipAddressList,
    departmentIds: timeAttendanceInitialValues.departmentIds,
  });
  const [optionDepartment, setOptionDepartment] = React.useState([]);
  const [editData, setEditData] = React.useState(false);

  const [newDepartment, setNewDepartment] = React.useState([]);

  const dispatch = useDispatch();
  // const [enteredIPAddress, setEnteredIPAddress] = useState();
  const [ipAddress, setIpAddress] = useState(
    timeAttendanceInitialValues.ipAddressList
  );
  const [validIpAddress, setValidIpAddress] = useState(
    timeAttendanceInitialValues.ipAddressList
  );
  const [isValid, setIsValid] = useState(true);
  const validateIpAddress = (input) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/;
    const addresses = input.split(/,|\s|\n/);
    const isValidAddresses = addresses.every((address) =>
      ipRegex.test(address.trim())
    );
    setValidIpAddress(addresses);
    setIsValid(isValidAddresses);
  };
  console.log("timeAttendanceInitialValues",timeAttendanceInitialValues)
  const handleChange = (e) => {
    if (e.target.value === "") {
      setIpAddress([""]);
      setValidIpAddress([""]);
      setIsValid(true);
    } else {
      let input = e.target.value;
      input = input.replace(/,|\s|\n/g, "\n");
      setIpAddress(input);
      validateIpAddress(input);
    }
  };
  useEffect(() => {
if(isTimeConfigUpdateActive){getDepartment()}
  }, []);
  const [finalValue,setFinalValue]=useState([])
  useEffect(() => {
    if (timeAttendanceValues.departmentIds) {
      
      setFinalValue(
        optionDepartment?.filter((option) => {
          console.log('option',option);
          return (
            timeAttendanceValues.departmentIds?.split(",")?.indexOf(option.value.toString()) >= 0
          );
        })
      );
    } else {
      setFinalValue([]);
    }

  }, [editData]);
  console.log('finalValue',finalValue);
  const handleDepartment = (value) => {
    // console.log("value", value);
    setNewDepartment((newDepartment) => newDepartment, value);
    const x = value.map((d) => d?.value).join(",");
    // console.log("x",x);
    settimeAttendanceValues({ ...timeAttendanceValues, departmentIds: x });
  };
  const getDepartment = async () => {
    try {
      const result = await getRequest("Department/GetDepartments");
      // console.log("result", result);
      let listData = [];
      result.data.map((item) => {
        listData.push({ label: item.name, value: item.id });
        return null;
      });
      // console.log("listData", listData);
      setEditData(true)
      setOptionDepartment(listData);
    } catch (error) {
    } finally {
    }
  };

  const updateTimeAttendanceConfigData = async () => {
    // if(timeAttendanceValues.ipAddressList === null ){
    //   settimeAttendanceValues(
    //     timeAttendanceValues,
    //     (timeAttendanceValues.ipAddressList = [])
    //   );
    // }
    // else
    if (
      validIpAddress?.length > 1 &&
      validIpAddress[validIpAddress.length - 1] === ""
    ) {
      setValidIpAddress(validIpAddress.pop(validIpAddress.length - 1));
      settimeAttendanceValues(
        timeAttendanceValues,
        (timeAttendanceValues.ipAddressList = validIpAddress)
      );
      setIsValid(true);
    } else {
      if (!isValid) {
        dispatch(
          showToast({
            text: "Invalid IP Address",
            severity: "error",
          })
        );
        return;
      }
    }
    settimeAttendanceValues(
      timeAttendanceValues,
      (timeAttendanceValues.ipAddressList = validIpAddress)
    );
    try {
      const result = await postRequest(
        "TimeAttendanceConfig/Update",
        timeAttendanceValues
      );
      // dispatch(
      //   showToast({
      //     text: "Configuration updated successfully",
      //     severity: "succes",
      //   })
      // );
      getTimeAttendanceConfigData();
    } catch (error) {
      dispatch(
        showToast({
          text: "Error in fetching Data",
          severity: "error",
        })
      );
      getTimeAttendanceConfigData();
    } finally {
      setIsTimeConfigUpdateActive(false);
    }
  };
  return (
    <div>
      <div className="timeConfigContainer">
        <div className="timeConfigHeader">
          <h2>Attendance Configuration</h2>
        </div>
        <div className="timeConfigWrapper">
          <div className="configRow1">
            <div className="configBox configEditBox">
              <div className="configTxt">
                Allow employees to mark attendance from web browser ?
              </div>
              <div className="changeConfigValue">
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowFromWeb"
                      checked={timeAttendanceValues.webLogIn}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          webLogIn: true,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span>Yes</span>
                </div>
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowFromWeb"
                      checked={!timeAttendanceValues.webLogIn}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          webLogIn: false,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span className="no">No</span>
                </div>
              </div>
            </div>
            <div className="configBox configEditBox">
              <div className="configTxt">
                Allow employees to mark attendance from mobile App ?
              </div>
              <div className="changeConfigValue">
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowFromMob"
                      checked={timeAttendanceValues.mobileLogIn}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          mobileLogIn: true,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span>Yes</span>
                </div>
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowFromMob"
                      checked={!timeAttendanceValues.mobileLogIn}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          mobileLogIn: false,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span className="no">No</span>
                </div>
              </div>
            </div>
            <div className="configBox configEditBox">
              <div className="configTxt">
                {" "}
                Capture image when marking the attendance from mobile app ?
              </div>
              <div className="changeConfigValue">
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowFaceReader"
                      checked={timeAttendanceValues.faceReader}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          faceReader: true,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span>Yes</span>
                </div>
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowFaceReader"
                      checked={!timeAttendanceValues.faceReader}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          faceReader: false,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span className="no">No</span>
                </div>
              </div>
            </div>
          </div>
          <div className="configRow2">
            <div className="configBox configEditBox">
              <div className="configTxt">
                Enable geo fence to mark the attendance from mobile app ?
              </div>
              <div className="changeConfigValue">
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowGeoFence"
                      checked={timeAttendanceValues.geofence}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          geofence: true,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span>Yes</span>
                </div>
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowGeoFence"
                      checked={!timeAttendanceValues.geofence}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          geofence: false,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span className="no">No</span>
                </div>
              </div>
            </div>
            <div className="configBox configEditBox">
              <div className="configTxt">
                Capture user location when marking the attendance from mobile
                app ?
              </div>
              <div className="changeConfigValue">
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowCaptureLocation"
                      checked={timeAttendanceValues.captureLocation}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          captureLocation: true,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span>Yes</span>
                </div>
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowCaptureLocation"
                      checked={!timeAttendanceValues.captureLocation}
                      onChange={() => {
                        settimeAttendanceValues({
                          ...timeAttendanceValues,
                          captureLocation: false,
                          locationMandatory: false,
                        });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span className="no">No</span>
                </div>
              </div>
            </div>
            <div className="configBox configEditBox">
              <div className="configTxt">
                Location is mandatory when marking the attendance from mobile
                app ?
              </div>
              <div className="changeConfigValue">
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowLocationMandatory"
                      checked={timeAttendanceValues.locationMandatory}
                      onChange={() => {
                        if (timeAttendanceValues.captureLocation)
                          settimeAttendanceValues({
                            ...timeAttendanceValues,
                            locationMandatory: true,
                          });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span>Yes</span>
                </div>
                <div>
                  <label class="configContainer">
                    <input
                      type="radio"
                      name="timeAllowLocationMandatory"
                      checked={!timeAttendanceValues.locationMandatory}
                      onChange={() => {
                        if (timeAttendanceValues.captureLocation)
                          settimeAttendanceValues({
                            ...timeAttendanceValues,
                            locationMandatory: false,
                          });
                      }}
                    />
                    <span class="checkmark"></span>
                  </label>
                  <span className="no">No</span>
                </div>
              </div>
            </div>
          </div>
          <div className="configRow3">
            <div className="configBox configEditBox" style={{minWidth:"35%", maxWidth:"49%"}}>
              <div className="configTxt">IP Address</div>
              <div className="changeConfigValue">
                <textarea
                  value={ipAddress}
                  onChange={handleChange}
                  // rows="4"
                  // cols="15"
                ></textarea>
              </div>
            </div>
            <div className="configBox configEditBox" style={{minWidth:"35%", maxWidth:"49%"}}>
              <div className="configTxt">Department</div>
              <div className="dropdownChangeConfigValue" style={{flexDirection:"unset", width:"60%"}}>
                {/* <textarea
                  value={ipAddress}
                  onChange={handleChange}
                  rows="4"
                  cols="15"
                ></textarea> */}
                <SelectMultiString
                  // onDropdownOpen={() => {
                  //   getDepartment();
                  // }}
                  customClass={'departmentDropDownListContainer'}
                  fullWidth={'departmentDropDownList'}
                  options={optionDepartment}
                  values={finalValue}
                  onChange={handleDepartment}
                  isMulti={true}
                />
              </div>
            </div>
            {/* <div
              className="configBox configEditBox"
              style={{ visibility: "hidden" }}
            ></div> */}
          </div>
        </div>
      </div>
      <div className="btnContainer">
        <button
          className="button configCancelButton myCancelBtn"
          type="button"
          onClick={() => {
            setIsTimeConfigUpdateActive(false);
            getTimeAttendanceConfigData();
          }}
        >
          Cancel
        </button>
        <button
          className="button configUpdateButton"
          type="submit"
          onClick={() => {
            updateTimeAttendanceConfigData();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default UpdateTimeAttendanceConfig;
