/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import OrgStructurepic from "../../Assets/OrgStructurepic.svg";
import onboardingpic from "../../Assets/Onboardingpic.svg";
import Timepic from "../../Assets/Timepic.svg";
import ProjectTaskspic from "../../Assets/ProjectTaskspic.svg";
import HRDeskpic from "../../Assets/HRDeskpic.svg";
import Benefitspic from "../../Assets/Benefitspic.svg";
import Policiespic from "../../Assets/Policiespic.svg";
import "./HRMSMaster.css";
import { useLocation, useNavigate } from "react-router-dom";
import { CreateDepartment } from "./OrgStructureComponents/CreateDepartment";
import {
  Benefits,
  HrDesk,
  Onboarding,
  OrgStructure,
  Policies,
  ProjectTasks,
  Time,
  ModuleMasterDetailsPage,
  EmployeeModuleMasterDetailsPage,
} from "./Components";
import { CreateBand } from "./OrgStructureComponents/CreateBand";
import { CreateRole } from "./OrgStructureComponents/CreateRole";
import { CreateDesignation } from "./OrgStructureComponents/CreateDesignation";
import { CreatePersonalArea } from "./OrgStructureComponents/CreatePersonalArea";
import { CreatePersonalSubArea } from "./OrgStructureComponents/CreatePersonalSubArea";
import { CreateArea } from "./OrgStructureComponents/CreateArea";
import { CreateRequisitionConfig } from "./OnboardingComponents/CreateRequisitionConfig";
import { CreateOnboardingForms } from "./OnboardingComponents/CreateOnboardingForms";
import { CreateOnboardingDocuments } from "./OnboardingComponents/CreateOnboardingDocuments";
import { CreateOnboardingVideos } from "./OnboardingComponents/CreateOnboardingVideos";
import { CreateDay1Feedback } from "./OnboardingComponents/CreateDay1Feedback";
import { CreateDay30Feedback } from "./OnboardingComponents/CreateDay30Feedback";
import { CreateNewEmployeeFacilities } from "./OnboardingComponents/CreateNewEmployeeFacilities";
import { CreateCustomer } from "./Components/CreateCustomer";
import { CreateDay7Feedback } from "./OnboardingComponents/CreateDay7Feedback";
import { CreateRequestCategory } from "./HRDeskComponents/CreateRequestCategory";
import { CreateHolidays } from "./TimeComponents/CreateHolidays";
import { CreateRequestType } from "./HRDeskComponents/CreateRequestType";
import { CreateServiceCategory } from "./HRDeskComponents/CreateServiceCategory";
import { CreateServiceType } from "./HRDeskComponents/CreateServiceType";
import { CreateTeams } from "./HRDeskComponents/CreateTeams";
import { CreateTeamMembers } from "./HRDeskComponents/CreateTeamMembers";
import { CreateLeaveBalance } from "./TimeComponents/CreateLeaveBalance";
import { CreateLeaveTypeComp } from "./TimeComponents/FormComp/CreateLeaveTypeComp";
import { CreateCategory } from "./BenefitsComponents/CreateCategory";
import { CreateShift } from "./TimeComponents/CreateShift";
import { CreateItems } from "./BenefitsComponents/CreateItems";
import { CreateTypes } from "./BenefitsComponents/CreateTypes";
import { CreatePolicies } from "./Components/CreatePolicies";
import { CreateEmployeeN } from "./OrgStructureComponents/CreateEmployeeN";
import { CreateResetPassword } from "./OrgStructureComponents/CreateResetPassword";
import { DetailsShift } from "./TimeComponents/DetailsShift";
import { DetailArea } from "./OrgStructureComponents/DetailArea";
import { DetailServiceType } from "./HRDeskComponents/DetailServiceType";
import { DetailsTeam } from "./HRDeskComponents/DetailsTeam";
import { DetailsHrWorkflow } from "./HRDeskComponents/DetailsHrWorkflow";
import { DetailsFacilityCategorys } from "./BenefitsComponents/DetailsFacilityCategory";
import { DetailsPolicies } from "./Components/DetailsPolicies";
import { DetailLeaveType } from "./TimeComponents/DetailLeaveType";
import { DetailHolidays } from "./TimeComponents/DetailHolidays";
import { CreateWorkflowN } from "./HRDeskComponents/CreateWorkflowN";
import { DetailPersonalSubArea } from "./OrgStructureComponents/DetailsPersonalSubArea";
import { getRequest, postRequest } from "../../Services/axios";
import { GroupBy } from "../../Utilities";
import { useDispatch } from "react-redux";
import { loggedOut } from "../../Features";
import Skeleton from "react-loading-skeleton";
import { DetailOfficeLocation } from "./TimeComponents/DetailOfficeLocation";
import CreateOfficeLocation from "./TimeComponents/CreateOfficeLocation";
import { DetailLeaveBalance } from "./TimeComponents/DetailLeaveBalance";

const HRMSMaster = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [createResetPasswordVisible, setCreateResetPasswordVisible] =
    useState(false);
  const dispatch = useDispatch();

  const [subMenuList, setSubMenuList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeSubmodule, setActiveSubmodule] = useState("orgstructure");
  const [activeFeature, setActiveFeature] = useState("company");
  const [createActive, setCreateActive] = useState(false);
  const [detailsActive, setDetailsActive] = useState(false);
  const getConfigData = async () => {
    try {
      setLoading(true);
      let result = await getRequest("Settings/GetConfigMenu");
      if (result) {
        let tempMenu = Object.values(GroupBy(result?.data, "menuName"));
        tempMenu.map((item) => {
          try {
            if (
              item?.[0]?.menuName
                ?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase() !== "hrms"
            )
              return null;
            const tempGroup = GroupBy(item, "subMenuName");
            let tempSubMenu = Object.values(tempGroup);
            return setSubMenuList(tempSubMenu);
          } catch (error) {}
        });
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    setCreateActive(false);
    setDetailsActive(false);
    setCreateResetPasswordVisible(false);
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrmsmaster")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "hrmsmaster");
      if (splitArray?.length > submoduleIndex + 1) {
        if (subMenuList) {
          if (
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.some(
                (x) =>
                  x[0].subMenuName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setActiveSubmodule(splitArray[submoduleIndex + 1]);
          } else {
            navigate(
              subMenuList
                ?.slice()
                ?.filter((x) => x[0].isSubMenuActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a[0].subMenuOrder) -
                    parseFloat(b[0].subMenuOrder)
                )[0][0]
                .subMenuName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
          }
        }
      } else {
        if (subMenuList)
          navigate(
            subMenuList
              ?.slice()
              ?.filter((x) => x[0].isSubMenuActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a[0].subMenuOrder) - parseFloat(b[0].subMenuOrder)
              )[0][0]
              .subMenuName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
      }
      if (splitArray?.length > submoduleIndex + 2) {
        if (splitArray[submoduleIndex + 2] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 2] === "create") {
          setCreateActive(true);
        } else setActiveFeature(splitArray[submoduleIndex + 2]);
      }
      if (splitArray?.length > submoduleIndex + 3) {
        if (splitArray[submoduleIndex + 3] === "details") {
          setDetailsActive(true);
        } else if (splitArray[submoduleIndex + 3] === "create") {
          setCreateActive(true);
        } else if (splitArray[submoduleIndex + 3] === "resetpassword") {
          setCreateResetPasswordVisible(true);
        }
      }
    }
  }, [location.pathname, subMenuList]);
  const getFeatureMenu = (submenu) => {
    if (!subMenuList) return null;
    return subMenuList?.find(
      (obj) =>
        obj?.[0]?.subMenuName
          ?.replaceAll(" ", "")
          ?.replaceAll("&", "")
          ?.toLowerCase() === submenu
    );
  };
  const handleMenuIcon = (subMenuName) => {
    if (subMenuName === "onboarding") return onboardingpic;
    else if (subMenuName === "orgstructure") return OrgStructurepic;
    else if (subMenuName === "timeattendance") return Timepic;
    else if (subMenuName === "projecttask") return ProjectTaskspic;
    else if (subMenuName === "hrdesk") return HRDeskpic;
    else if (subMenuName === "benefits") return Benefitspic;
    else if (subMenuName === "policies") return Policiespic;
    return onboardingpic;
  };
  return (
    <div className="moduleContainer">
      {activeSubmodule === "orgstructure" && (
        <>
          {activeFeature === "employee" && (
            <>
              {createActive && <CreateEmployeeN title={"Create Employee"} />}
              {detailsActive && <EmployeeModuleMasterDetailsPage />}
              {createResetPasswordVisible && (
                <CreateResetPassword title={"Reset Password"} />
              )}
            </>
          )}
          {activeFeature === "department" && (
            <>
              {createActive && <CreateDepartment title={"Create Department"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "band" && (
            <>
              {createActive && <CreateBand title={"Create Band"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "role" && (
            <>
              {createActive && <CreateRole title={"Create Role"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "designation" && (
            <>
              {createActive && (
                <CreateDesignation title={"Create Designation"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "personalarea" && (
            <>
              {createActive && (
                <CreatePersonalArea title={"Create Personal Area"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "personalsubarea" && (
            <>
              {createActive && (
                <CreatePersonalSubArea title={"Create Personal Sub Area"} />
              )}
              {detailsActive && <DetailPersonalSubArea />}
            </>
          )}
          {activeFeature === "area" && (
            <>
              {createActive && <CreateArea title={"Create Area"} />}
              {detailsActive && <DetailArea />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "onboarding" && (
        <>
          {activeFeature === "requisitionconfig" && (
            <>
              {createActive && (
                <CreateRequisitionConfig title={"Edit Config"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "onboardingdocuments" && (
            <>
              {createActive && (
                <CreateOnboardingDocuments
                  title={"Create Onboarding Documents"}
                />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "onboardingforms" && (
            <>
              {createActive && (
                <CreateOnboardingForms title={"Create Onboarding Forms"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "onboardingvideos" && (
            <>
              {createActive && (
                <CreateOnboardingVideos title={"Create Onboarding Videos"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "day1feedback" && (
            <>
              {createActive && (
                <CreateDay1Feedback title={"Create Day 1 Feedback"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "day7feedback" && (
            <>
              {createActive && (
                <CreateDay7Feedback title={"Create Day 7 Feedback"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "day30feedback" && (
            <>
              {createActive && (
                <CreateDay30Feedback title={"Create Day 30 Feedback"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "newemployeefacilities" && (
            <>
              {createActive && (
                <CreateNewEmployeeFacilities
                  title={"Create New Employee Facilities"}
                />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "projecttasks" && (
        <>
          {activeFeature === "customer" && (
            <>
              {createActive && <CreateCustomer title={"Create Customer"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "timeattendance" && (
        <>
          {activeFeature === "shift" && (
            <>
              {createActive && <CreateShift title={"Create Shift"} />}
              {detailsActive && <DetailsShift />}
            </>
          )}
          {activeFeature === "officelocation" && (
            <>
              {createActive && <CreateOfficeLocation />}
              {detailsActive && <DetailOfficeLocation />}
            </>
          )}
          {activeFeature === "holidays" && (
            <>
              {createActive && <CreateHolidays title={"Create Holidays"} />}
              {detailsActive && <DetailHolidays />}
            </>
          )}
          {activeFeature === "leavebalance" && (
            <>
              {createActive && (
                <CreateLeaveBalance title={"Create Leave Balance"} />
              )}
              {detailsActive && <DetailLeaveBalance />}
            </>
          )}
          {activeFeature === "leavetype" && (
            <>
              {createActive && (
                <CreateLeaveTypeComp title={"Create Leave Type"} />
              )}
              {detailsActive && <DetailLeaveType />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "hrdesk" && (
        <>
          {activeFeature === "requestcategory" && (
            <>
              {createActive && (
                <CreateRequestCategory title={"Create  Request Category"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "requesttype" && (
            <>
              {createActive && (
                <CreateRequestType title={"Create  Request Type"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "servicecategory" && (
            <>
              {createActive && (
                <CreateServiceCategory title={"Create Service Category "} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "servicetype" && (
            <>
              {createActive && (
                <CreateServiceType title={"Create  Service Type"} />
              )}
              {detailsActive && <DetailServiceType />}
            </>
          )}
          {activeFeature === "workflow" && (
            <>
              {createActive && <CreateWorkflowN title={"Create Workflow"} />}
              {detailsActive && <DetailsHrWorkflow />}
            </>
          )}
          {activeFeature === "teams" && (
            <>
              {createActive && <CreateTeams title={"Create Teams"} />}
              {detailsActive && <DetailsTeam />}
            </>
          )}
          {activeFeature === "teammembers" && (
            <>
              {createActive && (
                <CreateTeamMembers title={"Create Team Members"} />
              )}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "benefits" && (
        <>
          {activeFeature === "items" && (
            <>
              {createActive && <CreateItems title={"Create Items"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "types" && (
            <>
              {createActive && <CreateTypes title={"Create Types"} />}
              {detailsActive && <ModuleMasterDetailsPage />}
            </>
          )}
          {activeFeature === "category" && (
            <>
              {createActive && <CreateCategory title={"Create Category"} />}
              {detailsActive && <DetailsFacilityCategorys />}
            </>
          )}
        </>
      )}
      {activeSubmodule === "policies" && (
        <>
          {createActive && <CreatePolicies title={"Create Policies"} />}
          {detailsActive && <DetailsPolicies />}
        </>
      )}
      <div className="homeEngageAside">
        <div className="homeEngageAsideMenuContainer">
          {loading ? (
            <Skeleton height={55} count={5} />
          ) : (
            <>
              {subMenuList &&
                subMenuList
                  ?.slice()
                  ?.filter((x) => x[0].isSubMenuActive)
                  ?.sort(
                    (a, b) =>
                      parseFloat(a[0].subMenuOrder) -
                      parseFloat(b[0].subMenuOrder)
                  )
                  ?.map((subMenu, index) => {
                    return (
                      <div
                        key={index}
                        className={`menuOptions ${
                          activeSubmodule ===
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "menuOptionsActive"
                        }`}
                        onClick={() => {
                          setActiveSubmodule(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          );
                          navigate(
                            `/hrmsmaster/${subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()}`
                          );
                        }}
                      >
                        <div className="verticalLine"></div>
                        <img
                          src={handleMenuIcon(
                            subMenu[0]?.subMenuName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase()
                          )}
                          alt={subMenu[0]?.subMenuDisplayName}
                        />
                        <label>{subMenu[0]?.subMenuDisplayName}</label>
                      </div>
                    );
                  })}
            </>
          )}
        </div>
      </div>
      <div className="homeEngageMainContainer">
        {loading ? (
          <div className="moduleMainContainer">
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {activeSubmodule === "orgstructure" ? (
              <OrgStructure featureMenu={getFeatureMenu("orgstructure")} />
            ) : null}
            {activeSubmodule === "onboarding" ? (
              <Onboarding featureMenu={getFeatureMenu("onboarding")} />
            ) : null}
            {activeSubmodule === "timeattendance" ? (
              <Time featureMenu={getFeatureMenu("timeattendance")} />
            ) : null}
            {activeSubmodule === "projecttasks" ? (
              <ProjectTasks featureMenu={getFeatureMenu("projecttasks")} />
            ) : null}
            {activeSubmodule === "hrdesk" ? (
              <HrDesk featureMenu={getFeatureMenu("hrdesk")} />
            ) : null}
            {activeSubmodule === "benefits" ? (
              <Benefits featureMenu={getFeatureMenu("benefits")} />
            ) : null}
            {activeSubmodule === "policies" ? <Policies /> : null}
          </>
        )}
      </div>
    </div>
  );
};
export { HRMSMaster };
