import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import { LabelCustom } from "../../../../Components/FormComponent/Index";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { useEffect } from "react";

const opt = [{ label: "select", value: null }];

const Entitlement = ({ data, formValues }) => {
  const effectiveOptions = [
    { label: "Year(s)", value: "Year(s)" },
    { label: "Month(s)", value: "Month(s)" },
    { label: "Day(s)", value: "Day(s)" },
  ];
  const dateOptions = [
    { label: "Date Of Joining", value: "Date Of Joining" },
    { label: "Date Of Confirmation", value: "Date Of Confirmation" },
  ];
  const accrualOptions = [
    { label: "Current Accrual", value: "Current Accrual" },
    { label: "Next Accrual", value: "Next Accrual" },
  ];
  const timeOptions = [
    { label: "One Time", value: "One Time" },
    { label: "Monthly", value: "Monthly" },
    { label: "Yearly", value: "Yearly" },
  ];
  const monthOptions = [
    { label: "Jan", value: "Jan" },
    { label: "Feb", value: "Feb" },
    { label: "Mar", value: "Mar" },
    { label: "Apr", value: "Apr" },
    { label: "May", value: "May" },
    { label: "Jun", value: "Jun" },
    { label: "Jul", value: "Jul" },
    { label: "Aug", value: "Aug" },
    { label: "Sep", value: "Sep" },
    { label: "Oct", value: "Oct" },
    { label: "Nov", value: "Nov" },
    { label: "Dec", value: "Dec" },
  ];
  const carryOptions = [{ label: "Carry Forward", value: "Carry Forward" }];
  const unitOptions = [
    { label: "Unit", value: "Unit" },
    { label: "Percentage", value: "Percentage" },
  ];
  const dayOptions = [
    { label: "1st", value: "1st" },
    { label: "2nd", value: "2nd" },
    { label: "3rd", value: "3rd" },
    { label: "4th", value: "4th" },
    { label: "5th", value: "5th" },
    { label: "6th", value: "6th" },
    { label: "7th", value: "7th" },
    { label: "8th", value: "8th" },
    { label: "9th", value: "9th" },
    { label: "10th", value: "10th" },
    { label: "11th", value: "11th" },
    { label: "12th", value: "12th" },
    { label: "13th", value: "13th" },
    { label: "14th", value: "14th" },
    { label: "15th", value: "15th" },
    { label: "16th", value: "16th" },
    { label: "17th", value: "17th" },
    { label: "18th", value: "18th" },
    { label: "19th", value: "19th" },
    { label: "20th", value: "20th" },
    { label: "21th", value: "21th" },
    { label: "22th", value: "22th" },
    { label: "23th", value: "23th" },
    { label: "24th", value: "24th" },
    { label: "25th", value: "25th" },
    { label: "26th", value: "26th" },
    { label: "27th", value: "27th" },
    { label: "28th", value: "28th" },
    { label: "29th", value: "29th" },
    { label: "30th", value: "30th" },
    { label: "31st", value: "31st" },
  ];

  useEffect(() => {
    document.title = `PeopleSol - Entitlement `;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  // console.log("object formValues", formValues);
  useEffect(() => {
    if (formValues?.balanceBasedOn === "Accrual") {
      formValues.resetType = null;
      formValues.resetMonth = null;
      formValues.resetDays = null;
      // formValues.entitlement.resetType = null;
      // formValues.entitlement.resetMonth = null;
      // formValues.entitlement.resetDays = null;
    } else if (formValues?.balanceBasedOn === "Fixed") {
      formValues.resetCarryForwardValue = null;
      formValues.resetCarryForwardUnitType = null;
      formValues.resetCarryForwardType = null;
      formValues.accrualType = null;
      formValues.accrualOnMonth = null;
      formValues.accrualOnDay = null;
      formValues.accrualIn = null;
      formValues.accrualDays = null;

      // formValues.entitlement.accrualType = null;
      // formValues.entitlement.accrualOnMonth = null;
      // formValues.entitlement.accrualOnDay = null;
      // formValues.entitlement.accrualIn = null;
      // formValues.entitlement.accrualDays = null;
      // formValues.entitlement.resetCarryForwardValue = null;
      // formValues.entitlement.resetCarryForwardUnitType = null;
      // formValues.entitlement.resetCarryForwardType = null;
    }
  }, [formValues?.balanceBasedOn]);
  return (
    <>
      <div
        className="formcustom"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div>
          <div className="headingForm">Effective After</div>
          <div style={{ display: "flex" }}>
            <InputCustomFormik
              name="effectiveAfterValue"
              step=".001"
              min={1}
              max={999}
              placeholder={"Please Enter No."}
            />
            <SelectForm
              name="effectiveAfterDuration"
              options={effectiveOptions}
              values={
                data
                  ? effectiveOptions.filter(
                      (options) =>
                        options.label ===
                        data?.entitlement?.effectiveAfterDuration
                    )
                  : opt
              }
            />
            <span className="formInnerText">From</span>
            <SelectForm
              name="effectiveAfterType"
              options={dateOptions}
              values={
                data
                  ? dateOptions.filter(
                      (options) =>
                        options.label === data?.entitlement?.effectiveAfterType
                    )
                  : opt
              }
            />
          </div>
        </div>
        {formValues?.balanceBasedOn === "Accrual" && (
          <div>
            <div className="headingForm">Accural</div>
            <div style={{ display: "flex" }}>
              <SelectForm
                name="accrualType"
                options={timeOptions}
                values={
                  data
                    ? timeOptions.filter(
                        (options) =>
                          options.label === data?.entitlement?.accrualType
                      )
                    : opt
                }
              />
              <span className="formInnerText">On</span>
              <SelectForm
                name="accrualOnDay"
                options={dayOptions}
                values={
                  data
                    ? dayOptions.filter(
                        (options) =>
                          options.label === data?.entitlement?.accrualOnDay
                      )
                    : opt
                }
              />

              <SelectForm
                name="accrualOnMonth"
                options={monthOptions}
                values={
                  data
                    ? monthOptions.filter(
                        (options) =>
                          options.label === data?.entitlement?.accrualOnMonth
                      )
                    : opt
                }
              />
              <span className="formInnerText">No. of Days</span>

              <InputCustomFormik
                name="accrualDays"
                step=".001"
                min={1}
                max={999}
                placeholder={"Please Enter No."}
              />

              <span className="formInnerText">In</span>

              <SelectForm
                name="accrualIn"
                options={accrualOptions}
                values={
                  data
                    ? accrualOptions.filter(
                        (options) =>
                          options.label === data?.entitlement?.accrualIn
                      )
                    : opt
                }
              />
            </div>
          </div>
        )}
        {formValues?.balanceBasedOn === "Fixed" && (
          <div>
            <div className="headingForm">Reset</div>
            <div style={{ display: "flex" }}>
              <SelectForm
                name="resetType"
                options={timeOptions}
                values={
                  data
                    ? timeOptions.filter(
                        (options) =>
                          options.label === data?.entitlement?.resetType
                      )
                    : opt
                }
              />
              <span className="formInnerText">On</span>

              <SelectForm
                name="resetDays"
                options={dayOptions}
                values={
                  data
                    ? dayOptions.filter(
                        (options) =>
                          options.label === data?.entitlement?.resetDays
                      )
                    : opt
                }
              />

              <SelectForm
                name="resetMonth"
                options={monthOptions}
                values={
                  data
                    ? monthOptions.filter(
                        (options) =>
                          options.label === data?.entitlement?.resetMonth
                      )
                    : opt
                }
              />
            </div>
          </div>
        )}
        {formValues?.balanceBasedOn === "Accrual" && (
          <div>
            <div className="headingForm">Carry Forward</div>
            <div style={{ display: "flex" }}>
              <SelectForm
                name="resetCarryForwardType"
                options={carryOptions}
                values={
                  data
                    ? carryOptions.filter(
                        (options) =>
                          options.label ===
                          data?.entitlement?.resetCarryForwardType
                      )
                    : opt
                }
              />
              <InputCustomFormik
                name="resetCarryForwardValue"
                step=".001"
                min={1}
                max={999}
                placeholder={"Please Enter No."}
              />
              <SelectForm
                name="resetCarryForwardUnitType"
                options={unitOptions}
                values={
                  data
                    ? unitOptions.filter(
                        (options) =>
                          options.label ===
                          data?.entitlement?.resetCarryForwardUnitType
                      )
                    : opt
                }
              />
            </div>
          </div>
        )}
        <div style={{ width: "50%" }}>
          <LabelCustom labelName={"Add Opening Balance"} />
          <InputCustomFormik
            name="addOpeningBalance"
            step=".001"
            max={999}
            placeholder={"Please Enter No."}
          />
        </div>
      </div>
    </>
  );
};

export { Entitlement };
