import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useDispatch } from "react-redux";
import { ServiceCategoryForm } from "../../HRMSMaster/HRDeskComponents/Form/ServiceCategoryForm";
import { reqCategorySchema } from "../../HRMSMaster/HRDeskComponents/RequestCategorySchemas";
import { ServiceCategoryFormIt } from "./Form/ServiceCategoryFormIt";
import { reqCategorySchemaIt } from "./Schema";
import { useEffect } from "react";




const CreateServiceCategoryIt = (props) => {
  const { state } = useLocation();
  const [detail, setDetail] = React.useState([]);
  const data = state?.id;

  const dispatch = useDispatch();
  const initialvalue = {
    name: data?detail?.name:"",
    changeOwnerEmployeeId:data?detail?.changeOwnerEmployeeId:"",
    itsmchangeWorkFlowId:data?detail?.itsmchangeWorkFlowId:null,
  };
  const postFormData = async (values) => {
    try {

      const result = await postRequest(
        "ServiceCategories/Create",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {}
  };
  const getDetails = async () => {
    try {
      const result = await getRequest(`ServiceCategories/Details/${data}`);
      setDetail(result.data);
    } catch (error) {        dispatch(
      showToast({ text: error.response.message, severity: "error" })
    ) 
    } finally {
    }
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Service Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  React.useEffect(() => {
    if(data){
      getDetails();
    }
  }, [data]);
  const Edit = async (values) => {
    try {
      const result = await postRequest(
        "ServiceCategories/Edit",
        values
      );
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) { dispatch(
      showToast({ text: error?.response?.data ?? "some error occured", severity: "error" })
    ) }
  };

  const onSubmit = async (values) => {

    const edit = {
      ...values,
      id: data,
      isActive:true
    };

    const create = {
      ...values,
      isActive:true
    };
    
    data ? Edit(edit) : postFormData(create);

  };

  const navigate = useNavigate();
  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
    ButtonLabel={data?'Update Service Category':'Create Service Category'}
    FormHeading={data?'Edit Service Category':'Create Service Category'}
      validationSchema={reqCategorySchemaIt}
      onSubmit={onSubmit}
      initialvalue={
       
          initialvalue
      }
      returnPage={returnPage}
      propsDiv={<ServiceCategoryFormIt data={data} />}    />
  );
};

export { CreateServiceCategoryIt };

