import React from "react";

const TableContent = ({
  Name,
  Category,
  Course,
  Chapter,
  Status,
  componentProp,
  Date,
  completedStyle,
}) => {
  return (
    <div style={completedStyle} className="completedHeadingld">
      <div className="columnwidth" style={{ marginLeft: "16px" }}>
        {Name}
      </div>
      <div className="columnwidth">{Category}</div>
      <div className="columnwidth">{Course}</div>
      <div className="columnwidth">{Chapter}</div>
      <div className="columnwidth">
        <div
          className={` ${Status === "Completed" && "completedStatusdesign"} ${
            Status === "In Progress" && "inprogressStatusdesign"
          }`}
        >
          {Status}
        </div>
      </div>
      {componentProp ? (
        <div className="columnwidth">{componentProp}</div>
      ) : (
        <div className="columnwidth">{Date}</div>
      )}
    </div>
  );
};

export default TableContent;
