import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreatePoliciesComp } from "./CreatePoliciesComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";

const CreatePolicies = (props) => {
  const { state } = useLocation();
  const [formValues, setFormValues] = useState(null);
  const data = state?.id;

  const [detailsform, setDetailsform] = useState({});

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Policies/Details/${data}`);
      setDetailsform(result?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      await postRequestForm("Policies/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      await postRequestForm("Policies/Edit", values);
      dispatch(
        showToast({ text: "Successfully Updated", severity: "success" })
      );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const initialValue = {
    Subject: "",
    StartDate: "",
    EndDate: "",
    ReviewDate: "",
    Description: null,
    locationIds: null,
    PolicyGroup: null,
    DisplayInOnboarding: "No",
    upload: null,
  };
  useEffect(() => {
    document.title = `PeopleSol - Create Policies`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const SUPPORTED_FORMATS = ["application/pdf"];

  const validationSchema = Yup.object({
    Subject: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please Enter Subject"),
    StartDate: Yup.string().required("Please Enter Start Date"),
    EndDate: Yup.string().required("Please Enter End Date"),
    ReviewDate: Yup.string().required("Please Enter Review Date"),
    locationIds: Yup.string().required("Please Select Location").nullable(),
    Description: Yup.string()
      .max(499, "Description must be less than 500 characters")
      .nullable(),
      upload: Yup.mixed()
      .nullable()
      .required()
      .test(
        "Fichier taille",
        "File Size must be less than 1 mb",
        (value) => !value || (value && value.size <= 2048 * 2048) || typeof value !== "object"
      )
      .test(
        "format",
        "File Format is Incorrect",
        (value) =>
          !value || (value && SUPPORTED_FORMATS.includes(value.type)) || typeof value !== "object"
      ),
  });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("id", data ? data : 0);
    if (values?.upload)
      if (typeof values?.upload === "object") {
        formData.append("upload", values?.upload);
        formData.append("Attachment", values?.upload?.name);
      }
    formData.append("Subject", values?.Subject);
    formData.append("StartDate", `${format(values?.StartDate, "MM/dd/yyyy")}`);
    formData.append("EndDate", `${format(values?.EndDate, "MM/dd/yyyy")}`);
    formData.append(
      "ReviewDate",
      `${format(values?.ReviewDate, "MM/dd/yyyy")}`
    );
    formData.append("Description", values?.Description);
    if(values?.locationIds) formData.append("locationIds", values?.locationIds);
    if(values?.PolicyGroup)  formData.append("PolicyGroup", values?.PolicyGroup);
    formData.append(
      "DisplayInOnboarding",
      values?.DisplayInOnboarding === "Yes" ? true : false
    );

    data ? Edit(formData) : createPageData(formData);
  };
  const chooseFormValues = (values) => {
    setFormValues(values);
  };
  const navigate = useNavigate();
  const returnPage = () => {
    navigate("/hrmsmaster/policies");
  };
  return (
    <FormCustomFormik
      chooseFormValues={chooseFormValues}
      loading={loading}
      FormHeading={data ? "Edit Policies" : "Create Policies"}
      ButtonLabel={data ? "Update Policies" : "Create Policies"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        JSON.stringify(detailsform) !== "{}"
          ? {
              ...initialValue,

              Subject: detailsform?._polices?.subject,
              StartDate: detailsform?._polices?.startDate
                ? new Date(detailsform?._polices?.startDate)
                : "",
              EndDate: detailsform?._polices?.endDate
                ? new Date(detailsform?._polices?.endDate)
                : "",
              ReviewDate: detailsform?._polices?.reviewDate
                ? new Date(detailsform?._polices?.reviewDate)
                : "",
              Description: detailsform?._polices?.description,
              upload: `Policies/${detailsform?._polices?.attachment}`,
              locationIds: detailsform?.locationIds
                ? detailsform?.locationIds.length > 0
                  ? detailsform?.locationIds[0]
                  : null
                : null,

              PolicyGroup: detailsform?._polices?.policyGroup,
              DisplayInOnboarding:
                detailsform?._polices?.displayInOnboarding === true
                  ? "Yes"
                  : "No",
            }
          : initialValue
      }
      returnPage={returnPage}
      propsDiv={<CreatePoliciesComp data={detailsform} />}
    />
  );
};

export { CreatePolicies };
