/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import "./QuickAccessCard.css";
import { Tabs, Tab } from "@mui/material";
import QuickAccessMCAIcon from "../../../../../Assets/QuickAccessMCAIcon.svg";
import QuickAccessMPonlineIcon from "../../../../../Assets/QuickAccessMPonlineIcon.svg";
import QuickAccessGSTIcon from "../../../../../Assets/QuickAccessGSTIcon.svg";
import QuickAccessEwayIcon from "../../../../../Assets/QuickAccessEwayIcon.svg";
import QuickAccessCourseraIcon from "../../../../../Assets/QuickAccessCourseraIcon.svg";
import QuickAccessIncomeTaxIcon from "../../../../../Assets/QuickAccessIncomeTaxIcon.svg";
import QuickAccessLinkedinIcon from "../../../../../Assets/QuickAccessLinkedinIcon.svg";
import QuickAccessNaukriIcon from "../../../../../Assets/QuickAccessNaukriIcon.svg";
import QuickAccessEventsIcon from "../../../../../Assets/QuickAccessEventsIcon.svg";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../../../Services/axios";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { useEffect } from "react";
import { openInNewTab } from "../../../../../Utilities/openInNewTab";

const QuickAccessCard = () => {
  const navigate = useNavigate();
  const [homeList, setHomeList] = useState(null);

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const getHomeData = async () => {
    try {
      const result = await getRequest("Settings/Home");
      setHomeList(result?.data?.data);
      // //console.log('result.data', result.data);
    } catch (error) {
    } finally {
    }
  };
  
  useEffect(() => {
    getHomeData();
  }, []);

  if (!homeList) return <Loader />;
  else
    return (
      <div className="dashboardCardContentHolder">
        <div className="tabContainer">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{ style: { background: "#F7941D" } }}
          >
            <Tab
              label={
                <span
                  className={`tabHeading ${
                    tabIndex === 0 && "tabActive tabActiveSecondary"
                  }`}
                >
                  Internal
                </span>
              }
            />
            <Tab
              label={
                <span
                  className={`tabHeading ${
                    tabIndex === 1 && "tabActive tabActiveSecondary"
                  }`}
                >
                  External
                </span>
              }
            />
          </Tabs>
          <div className="tabContainerLine"></div>
        </div>
        {tabIndex === 0 && (
          <div className="quickAccessContainer">
            {homeList?.applications &&
              homeList?.applications?.map((item) => {
                return (
                  <a
                    href={`${item?.url}`}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`${item?.url}`);
                    }}
                  >
                    <div className="quickAccessImgContainer cursor">
                      <img
                        className="quickAccessImg"
                        src={
                          item?.icon
                            ? `${process.env.REACT_APP_.BASE_URL}/Core/Uploads/ApplicationIcon/${item?.icon}`
                            : QuickAccessEventsIcon
                        }
                        alt="Quick Access Icon"
                      />
                      <label className="quickAccessImgText">{item?.name}</label>
                    </div>
                  </a>
                );
              })}
          </div>
        )}
        {tabIndex === 1 && (
          <div className="quickAccessContainer">
            <a
              href="https://www.mca.gov.in/content/mca/global/en/home.html"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab(
                  "https://www.mca.gov.in/content/mca/global/en/home.html"
                );
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "40px" }}
                  className="quickAccessImg"
                  src={QuickAccessMCAIcon}
                  alt="MCAIcon"
                />
                <label className="quickAccessImgText"> MCA</label>
              </div>
            </a>

            <a
              href="https://mpbse.mponline.gov.in/MPBSE/MPBSE"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab("https://mpbse.mponline.gov.in/MPBSE/MPBSE");
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "25px" }}
                  className="quickAccessImg"
                  src={QuickAccessMPonlineIcon}
                  alt="MPonlineIcon"
                />
                <label className="quickAccessImgText">MP online</label>
              </div>
            </a>

            <a
              href="https://www.gst.gov.in/"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab("https://www.gst.gov.in/");
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "63px" }}
                  className="quickAccessImg"
                  src={QuickAccessGSTIcon}
                  alt="GSTIcon"
                />
                <label className="quickAccessImgText"> GST</label>
              </div>
            </a>

            <a
              href="https://ewaybill.nic.in/"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab("https://ewaybill.nic.in/");
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "62px" }}
                  className="quickAccessImg"
                  src={QuickAccessEwayIcon}
                  alt="EwayIcon"
                />
                <label className="quickAccessImgText">Eway Bill</label>
              </div>
            </a>

            <a
              href="https://www.incometax.gov.in/iec/foportal/"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab("https://www.incometax.gov.in/iec/foportal/");
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "52px" }}
                  className="quickAccessImg"
                  src={QuickAccessIncomeTaxIcon}
                  alt="IncomeTaxIcon"
                />
                <label className="quickAccessImgText">Income Tax</label>
              </div>
            </a>

            <a
              href="https://www.coursera.org/"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab("https://www.coursera.org/");
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "58px" }}
                  className="quickAccessImg"
                  src={QuickAccessCourseraIcon}
                  alt="CourseraIcon"
                />
                <label className="quickAccessImgText">Coursera</label>
              </div>
            </a>

            <a
              href="https://www.linkedin.com/"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab("https://www.linkedin.com/");
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "58px" }}
                  className="quickAccessImg"
                  src={QuickAccessLinkedinIcon}
                  alt="LinkedinIcon"
                />
                <label className="quickAccessImgText">LinkedIn</label>
              </div>
            </a>

            <a
              href="https://www.naukri.com/"
              onClick={(e) => {
                e.preventDefault();
                openInNewTab("https://www.naukri.com/");
              }}
            >
              <div className="quickAccessImgContainer cursor">
                <img
                  style={{ height: "68px" }}
                  className="quickAccessImg"
                  src={QuickAccessNaukriIcon}
                  alt="NaukriIcon"
                />
                <label className="quickAccessImgText">Naukri</label>
              </div>
            </a>
          </div>
        )}
      </div>
    );
};

export { QuickAccessCard };
