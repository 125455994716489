import React from 'react';

import { VehicleTab } from './VehicleTab';
import { useEffect } from 'react';

const Vehicle = () => {
    useEffect(() => {
        document.title = `PeopleSol - Vehicle `;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
          
            
            <VehicleTab title={'Vehicle'}/>
            
        </div>
    )
}

export { Vehicle }