import { Table, TableBody, TableHead, TableRow } from "@mui/material";
import { FieldArray } from "formik";
import React, { useMemo, useState } from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import {
  StyledTableCell,
  StyledTableCont,
} from "../../../../../Components/FormComponent/Repeated";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { showToast } from "../../../../../Features";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
function KPI({ formValues }) {
  const dispatch = useDispatch();
  const [totalWeightage, setTotalWeightage] = useState(0);
  function updateWeightage() {
    let sum = 0;
    formValues?.KpiTemplatelist?.map((item) => {
      return (sum = sum + item.Weightage);
    });
    setTotalWeightage(sum);
  }
  useMemo(() => {
    updateWeightage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues?.KpiTemplatelist]);
  useEffect(() => {
    document.title = `PeopleSol - KPI`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <>
      <FieldArray name="KpiTemplatelist">
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values, setFieldValue } = form;
          const { KpiTemplatelist } = values;
          if (values?.totalWeightage !== totalWeightage)
            setFieldValue("totalWeightage", totalWeightage);
          return (
            <StyledTableCont>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom labelName={"Sl. No."} />
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom
                        labelName={"Name of the KPI"}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom
                        labelName={"Objective of the KPI"}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom
                        labelName={"Start Date of Achievement"}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom
                        labelName={"Expected Date of Achievement"}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom
                        labelName={"KPI Threshold"}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom
                        labelName={"Weightage %"}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      padding="0"
                      style={{
                        fontSize: "12px",
                      }}
                      align="center"
                    >
                      <LabelCustom labelName={"Add/Remove"} />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {KpiTemplatelist?.map((KpiTemplatelists, index) => (
                    <TableRow key={index}>
                      {/* 1st column */}
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>

                      {/* 2nd column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          maxLength={150}
                          name={`KpiTemplatelist[${index}].KPIname`}
                          placeholder={"Enter Name of KPI"}
                        />
                      </StyledTableCell>

                      {/* 3rd column */}
                      <StyledTableCell>
                        <TextAreaFormik
                          maxLength={500}
                          name={`KpiTemplatelist[${index}].Objective`}
                          placeholder={"Enter Objective of KPI"}
                        />
                      </StyledTableCell>

                      {/* 4th column */}
                      <StyledTableCell>
                        <DatePickerFormiks
                          name={`KpiTemplatelist[${index}].StartDate`}
                          minDate={new Date()}
                        />
                      </StyledTableCell>

                      {/* 5th column */}
                      <StyledTableCell>
                        <DatePickerFormiks
                          name={`KpiTemplatelist[${index}].ExpectedDateofAchievement`}
                          minDate={
                            KpiTemplatelist[index].StartDate
                              ? new Date(KpiTemplatelist[index].StartDate)
                              : new Date()
                          }
                        />
                      </StyledTableCell>

                      {/* 6th column */}
                      <StyledTableCell>
                        <InputCustomFormik
                          maxLength={150}
                          name={`KpiTemplatelist[${index}].Threshold`}
                          type="number"
                           step="0.01"
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <InputCustomFormik
                          maxLength={150}
                          name={`KpiTemplatelist[${index}].Weightage`}
                          type="number"
                           step="0.01"
                        />
                      </StyledTableCell>

                      {/* 7th column */}
                      <StyledTableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <button
                          type="button"
                          className="remove-btn"
                          onClick={() =>
                            KpiTemplatelist.length > 1
                              ? remove(index)
                              : dispatch(
                                  showToast({
                                    text: "Atleast One Item is Required ",
                                    severity: "error",
                                  })
                                )
                          }
                        >
                          -
                        </button>
                        <button
                          type="button"
                          className="add-btn"
                          onClick={() =>
                            push({
                              Objective: "",
                              KPIname: "",
                              StartDate: "",
                              ExpectedDateofAchievement: "",
                              Threshold: "",
                              Weightage: "",
                            })
                          }
                        >
                          +
                        </button>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    {/* 1st column */}
                    <StyledTableCell></StyledTableCell>

                    {/* 2nd column */}
                    <StyledTableCell></StyledTableCell>

                    {/* 3rd column */}
                    <StyledTableCell></StyledTableCell>

                    {/* 4th column */}
                    <StyledTableCell></StyledTableCell>

                    {/* 5th column */}
                    <StyledTableCell></StyledTableCell>

                    {/* 6th column */}
                    <StyledTableCell>
                      <LabelCustom labelName={"Total Weightage %"} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <InputCustomFormik
                        name={"totalWeightage"}
                        type="number"
                           step="0.01"
                        disabled
                        value={totalWeightage}
                      />
                    </StyledTableCell>

                    {/* 7th column */}
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableCont>
          );
        }}
      </FieldArray>
    </>
  );
}

export { KPI };
