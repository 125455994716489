import React from 'react'
import { Planning } from '../../HRMS/Components/OnboardingComponents/Planning';
import { useEffect } from 'react';

const ServicePlanning = ({newHeight}) => {
  useEffect(() => {
    document.title = `PeopleSol -  Planning Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer ">

      <Planning styles={newHeight}/>
        
    </div>
  )
}

export {ServicePlanning};
