import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateHotelsComp } from "../FormComp/CreateHotelsComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateHotels = (props) => {
  const [detail, setDetail] = React.useState([]);
  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = `PeopleSol - Create Hotels`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      // eslint-disable-next-line no-unused-vars
      const result = await postRequest("Hotels/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      const result = await postRequest("Hotels/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    }
  };

  const initialvalue = {
    name: data ? detail?.name : "",
    managerId: data ? detail?.managerId : "",
    isEnabled: data ? (detail?.isEnabled ? "Yes" : "No") : "Yes",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .max(149, " Name must be less than 150 characters")
      .required("Please Enter Name"),
    managerId: Yup.string().required("Please Select Manager"),
  });

  const getDetails = async () => {
    try {
      const result = await getRequest(`Hotels/Details/${data}`);
      result.data.map((item) => {
        return setDetail(item);
      });
    } catch (error) {
      dispatch(showToast({ text: error.response.message, severity: "error" }));
    } finally {
    }
  };
  React.useEffect(() => {
    if (data) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (values) => {
    const edit = {
      ...values,
      Id: data,

      isEnabled: values.isEnabled === "Yes" ? true : false,
    };

    const create = {
      ...values,
      id: 0,
      isEnabled: values.isEnabled === "Yes" ? true : false,
      isDataActive: true,
    };
    data ? Edit(edit) : createPageData(create);
    returnPage();
  };

  const navigate = useNavigate();

  const returnPage = () => {
    navigate(-1);
  };

  return (
    <FormCustomFormik
      loading={loading}
      FormHeading={data ? "Edit Hotels" : "Create Hotels"}
      ButtonLabel={data ? "Update Hotels" : "Create Hotels"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      propsDiv={<CreateHotelsComp data={detail} editID={data ? true : false} />}
    />
  );
};

export { CreateHotels };
