import React from 'react'

export const ProfileGroup = (props) => {
    const tempGoupData = [
        {
          name: "AESS Grphic UI/UX",
          lastMsg: "Hello, Jenny how are you",
          lastFromMsg: "Rahul",
          img: "https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/AESS%20Wallpaper%20-%20Desktop%20V2_1.jpg",
        },
        {
          name: "AESS Frontend Developer",
          lastMsg: "I am waiting for your reply",
          lastFromMsg: "You",
          img: "https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/AESS%20Wallpaper%20-%20Desktop%20V2_1.jpg",
        },
        {
          name: "AESS Backend Developer",
          lastMsg: "Okay, Sure!",
          lastFromMsg: "Kajal",
          img: "https://peoplesol.solunxt.com/v1/Core/Uploads/ProfileImageUploads/AESS%20Wallpaper%20-%20Desktop%20V2_1.jpg",
        },
      ];
    
  return (
    <div className="myProfileGroupWrapper">
            <div className="creteNewGroup" onClick={()=>{props.setNewGroup(true)}}>
              <svg
                width="31"
                height="16"
                viewBox="0 0 31 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5818 9.45162C24.2651 9.45162 25.6826 8.03411 25.6826 6.35081C25.6826 4.66751 24.2651 3.25 22.5818 3.25C20.8985 3.25 19.481 4.66751 19.481 6.35081C19.481 8.1227 20.8985 9.45162 22.5818 9.45162Z"
                  stroke="#1296B0"
                />
                <path
                  d="M25.1511 15.3875H17.4434V14.5901C17.4434 11.7551 19.7468 9.45166 22.5818 9.45166C23.738 9.45166 24.8058 9.83475 25.6649 10.4808"
                  stroke="#1296B0"
                  strokeLinecap="round"
                />
                <path
                  d="M6.13841 9.45162C7.8217 9.45162 9.23922 8.03411 9.23922 6.35081C9.23922 4.66751 7.8217 3.25 6.13841 3.25C4.45511 3.25 3.0376 4.66751 3.0376 6.35081C3.0376 8.1227 4.45511 9.45162 6.13841 9.45162Z"
                  stroke="#1296B0"
                />
                <path
                  d="M11.1884 15.3875H1V14.5901C1 11.7551 3.30345 9.45166 6.13848 9.45166C8.97351 9.45166 11.277 11.7551 11.277 14.5901L11.1884 15.3875Z"
                  stroke="#1296B0"
                />
                <path
                  d="M14.2851 8.3514C16.2805 8.3514 17.9608 6.67108 17.9608 4.6757C17.9608 2.68032 16.2805 1 14.2851 1C12.2897 1 10.6094 2.68032 10.6094 4.6757C10.6094 6.7761 12.2897 8.3514 14.2851 8.3514Z"
                  fill="white"
                  stroke="#1296B0"
                />
                <path
                  d="M20.2712 15.3879H8.19385V14.4427C8.19385 11.0821 10.9244 8.35156 14.285 8.35156C17.6456 8.35156 20.3762 11.0821 20.3762 14.4427L20.2712 15.3879Z"
                  fill="white"
                  stroke="#1296B0"
                />
                <line
                  x1="27.5679"
                  y1="10.6245"
                  x2="27.5679"
                  y2="15.6252"
                  stroke="#F7941D"
                  strokeWidth="0.75"
                  strokeLinecap="round"
                />
                <line
                  x1="30.0127"
                  y1="13.1802"
                  x2="25.012"
                  y2="13.1802"
                  stroke="#F7941D"
                  strokeWidth="0.75"
                  strokeLinecap="round"
                />
              </svg>
              <p>Crete New Group</p>
            </div>
            {tempGoupData.length > 0 &&
              tempGoupData?.map((item, index) => {
                return (
                  <div className="goupList" key={"item" + index}>
                    <div className="imageContainer">
                      <img src={item.img} alt="groupProfilePic" />
                    </div>
                    <div className="textContainer">
                      <h3>{item.name}</h3>
                      <p>
                        {item.lastFromMsg}: {item.lastMsg}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
  )
}
