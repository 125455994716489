import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { useLocation } from "react-router-dom";
import { CreateCompanyFoodComp } from "./FormComp/CreateCompanyFoodComp";
import { confirmAlert } from "react-confirm-alert";
import dateFormat from "dateformat";
import { format } from "date-fns";

const CreateCompanyFood = (props) => {
  useEffect(() => {
    document.title = `PeopleSol - Create Company Food`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const { state } = useLocation();

  const [detailsform, setDetailsform] = useState({});

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`FoodRequests/Details?ID=${data}`);
      // console.log("fooddetails", result);
      setDetailsform(result.data.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    // getFormDetails();
    if (data) getFormDetails();
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("FoodRequests/Create", values);
      dispatch(
        showToast({ text: "Successfully Created", severity: "success" })
      );
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("FoodRequests/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data?.title ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      returnPage();
    }
  };

  const getFoodValues = (item) => {
    let returnArray = [];
    data
      ? item?.map((item) => {
          returnArray.push({
            GuestNames: item.guestName ? item.guestName : "",
            FoodPreferences: item.foodPreference ? item.foodPreference : "",
            Remark: item.remarks ? item.remarks : "",
          });
        })
      : (returnArray = [{ GuestNames: "", FoodPreferences: "", Remark: "" }]);

    return returnArray;
  };

  const initialvalue = {
    Date: detailsform?.requests?.date
      ? new Date(detailsform?.requests?.date)
      : "",
    Time: detailsform?.requests?.time
      ? new Date(`12/12/12 ${detailsform?.requests?.time}`)
      : "",
    GuestType: detailsform?.requests?.guestType
      ? detailsform?.requests?.guestType
      : "",
    GuestOf: detailsform?.requests?.guestOf
      ? detailsform?.requests?.guestOf
      : "",
    Requestlist: getFoodValues(detailsform?.requests?.requestlist),
  };
  // console.log("initialvalue", initialvalue);
  const validationSchema = Yup.object({
    Date: Yup.date().required("Please select the Date "),
    Time: Yup.string().required("Please select the Time "),
    GuestType: Yup.string().required("Please select the Guest Type "),
    GuestOf: Yup.string().required("Please Select the Name "),
    Requestlist: Yup.array().of(
      Yup.object().shape({
        GuestNames: Yup.string()
          .matches(/^\S.*$/, "GuestNames should not start with spaces")
          .min(2, "Guest Name must be of minimum 2 characters")
          .max(149, "Guest Name must be less than 150 characters")
          .required("Please enter the Guest Name"),
        FoodPreferences: Yup.string().required("Food Preference is required"),
        Remark: Yup.string()
          // .matches(/^\S.*$/, "Remark should not start with spaces")
          .max(249, "Remark must be less than 250 characters")
          .notRequired()
          .nullable(),
      })
    ),
  });

  const onSubmit = (values) => {
    // console.log("values", values);
    const edit = {
      ...values,
      id: detailsform?.requests?.id,
      Date: format(new Date(values?.Date), "yyyy-MM-dd"),
      Time: format(new Date(values?.Time), "hh:mm a"),
    };
    // console.log("initialvalue", initialvalue);

    // var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const create = {
      ...values,
      // Date: new Date(values.Date - tzoffset)
      //   .toISOString()
      //   .slice(0, -1),
      // Time: new Date(values.Time - tzoffset)
      //   .toISOString()
      //   .slice(0, -1),
      Date: format(new Date(values?.Date), "yyyy-MM-dd"),
      Time: format(new Date(values?.Time), "hh:mm a"),
    };
    data ? Edit(edit) : createPageData(create);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormCustomFormik
      FormHeading={
        data ? "Edit Company Food Request" : "Create Company Food Request"
      }
      ButtonLabel={data ? "Edit" : "Submit"}
      loading={loading}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={initialvalue}
      returnPage={returnPage}
      styles={{ width: "80%", maxWidth: "80%" }}
      propsDiv={<CreateCompanyFoodComp data={detailsform} />}
    />
  );
};

export { CreateCompanyFood };
