import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormCustomFormik } from "../../../Components/FormComponent/FormCustomFormik";
import { CreateGalleryComp } from "../FormComp/CreateGalleryComp";
import { useDispatch } from "react-redux";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequestForm } from "../../../Services/axios";
import { useLocation } from "react-router-dom";

const CreateGallery = (props) => {
  const [detailsform, setDetailsform] = useState(null);

  const [formValues, setFormValues] = useState(null);

  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Albums/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    document.title = `PeopleSol - Create Gallery`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Albums/Create", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequestForm("Albums/Edit", values);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
      }
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const getGalleryValues = () => {
    let returnArray = [];
    data
      ? detailsform?.albumsPhotos?.map((item) => {
        return  returnArray.push({
            Description: item.description,
            Upload: `Albums/${item?.image}`,
          });
        })
      : (returnArray = [{ Description: "", Upload: "" }]);

    return returnArray;
  };

  const initialValue = {
    Name: "",
    CoverUpload: "",
    Description: "",
    AlbumsPhotoslist: getGalleryValues(),
  };

  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const validationSchema = data
    ? Yup.object({
        Name: Yup.string()
          .max(149, " Name must be less than 150 characters")
          .required("Please Enter Name"),
        CoverUpload: Yup.mixed()
          .nullable()
          .required()
          .test(
            "Fichier taille",
            "File Size must be less than 1 mb",
            (value) =>
              !value ||
              (value && value.size <= 2048 * 2048) ||
              typeof value !== "object"
          )
          .test(
            "format",
            "File Format is Incorrect",
            (value) =>
              !value ||
              (value && SUPPORTED_FORMATS.includes(value.type)) ||
              typeof value !== "object"
          ),
        Description: Yup.string()
          .max(499, "Description cannot be more than 500 characters")
          .required("Please Enter Description"),
        AlbumsPhotoslist: Yup.array().of(
          Yup.object().shape({
            Description: Yup.string()
              .max(499, "Description cannot be more than 500 characters")
              .required("Please Enter Description"),
              Upload: Yup.mixed().required()
              .test(
                "Fichier taille",
                "File Size should be less than 2 mb",
                (value) => {
                  return (
                    !value ||
                    (value && value.size <= 2048 * 2048) ||
                    typeof value !== "object"
                  );
                }
              )
              .test(
                "format",
                "File Format is Incorrect",
                (value) =>
                  !value ||
                  (value && SUPPORTED_FORMATS.includes(value.type)) ||
                  typeof value !== "object"
              ),
          })
        ),
      })
    : Yup.object({
        Name: Yup.string()
          .max(149, " Name must be less than 150 characters")
          .required("Please Enter Name"),
        CoverUpload: Yup.mixed()
          .nullable()
          .test(
            "Fichier taille",
            "File Size should be less than 2 mb",
            (value) => !value || (value && value.size <= 2 * 2048 * 2048)
          )
          .test(
            "format",
            "File Format is Incorrect",
            (value) =>
              !value || (value && SUPPORTED_FORMATS.includes(value.type))
          ),
        Description: Yup.string()
          .max(499, "Description cannot be more than 500 characters")
          .required("Please Enter Description"),
        AlbumsPhotoslist: Yup.array().of(
          Yup.object().shape({
            Description: Yup.string()
              .max(499, "Description cannot be more than 500 characters")
              .required("Please Enter Description"),
              Upload: Yup.mixed().required()
              .test(
                "Fichier taille",
                "File Size should be less than 2 mb",
                (value) => {
                  return (
                    !value ||
                    (value && value.size <= 2048 * 2048) ||
                    typeof value !== "object"
                  );
                }
              )
              .test(
                "format",
                "File Format is Incorrect",
                (value) =>
                  !value ||
                  (value && SUPPORTED_FORMATS.includes(value.type)) ||
                  typeof value !== "object"
              ),
          })
        ),
      });

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("Name", values?.Name);
    formData.append("Id", data ? data : 0);
    if (typeof values?.CoverUpload === "object") {
      formData.append("CoverUpload", values?.CoverUpload);
      formData.append("CoverImage", values?.CoverUpload.name);
    }
    formData.append("Description", values?.Description);
    if (data) {
      if (values?.AlbumsPhotoslist.length > 0) {
        values?.AlbumsPhotoslist.forEach(function (object, index) {
          if (detailsform?.albumsPhotos?.length > 0) {
            formData.append(
              `AlbumsPhotoslist[${index}].Id`,
              index===detailsform?.albumsPhotos?.length?0:
              detailsform?.albumsPhotos[index].id 
            );
          }
          formData.append(
            `AlbumsPhotoslist[${index}].Description`,
            object.Description
          );
          if (typeof object?.Upload === "object")
            formData.append(`AlbumsPhotoslist[${index}].Upload`, object.Upload);
        });
      }
    } else {
      if (values?.AlbumsPhotoslist.length > 0) {
        values?.AlbumsPhotoslist.forEach(function (object, index) {
          formData.append(`Albumlist[${index}].Key`, object.Description);
          if (typeof object?.Upload === "object")
            formData.append(`Albumlist[${index}].Value`, object.Upload);
        });
      }
    }

    data ? Edit(formData) : createPageData(formData);
  };

  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  const chooseFormValues = (values) => {
    setFormValues(values);
  };

  return (
    <FormCustomFormik
      FormHeading={data ? "Edit Gallery" : "Create Gallery"}
      loading={loading}
      chooseFormValues={chooseFormValues}
      ButtonLabel={data ? "Update Gallery" : "Create Gallery"}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialvalue={
        data
          ? {
              ...initialValue,
              Name: detailsform?.name,

              CoverUpload: `Albums/${detailsform?.coverImage}`,
              Description: detailsform?.description,
            }
          : initialValue
      }
      returnPage={returnPage}
      propsDiv={<CreateGalleryComp formValues={formValues} />}
    />
  );
};

export { CreateGallery };

// !== "" ?  values?.CoverUpload?.Name : detailsform?.CoverUpload?.toString()
