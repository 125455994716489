import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";

import * as Yup from "yup";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { getRequest, postRequest } from "../../../../../Services/axios";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { Loader } from "../../../../../Components/FormComponent/Loader";

 const SurrenderAsset = ({ data, id, type, color,handleClose,eid }) => {
  const [Employee, setEmployee] = useState([]);
  const [loading,setLoading]=useState(false)
  const dispatch=useDispatch()
  const getEmployee = async () => {
    try {
      const result = await getRequest(`LeaveTypes/GetEmployees`);
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setEmployee(listData);
    } catch (error) {
    } finally {
    }
  };
  const surrender = async (val) => {
    try {
     setLoading(true);
        const result=await postRequest(`ItAssets/Surrender?ID=${id}`, val);

      dispatch(showToast({ text: result?.data?.message, severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      setLoading(false);
      handleClose()
      data()
    }
  };
  useEffect(()=>{
  getEmployee()
  },[])
  const validationSchema = Yup.object({
    SurrenderedToEmployeeId: Yup.string().required("Please Select Employee"),
    SurrenderCondition: Yup.string().required("Please Enter Surrender Condition"),
  });
  const onSubmit = async (values) => {
    const create={
      ...values,
      ID:id,
    }
    surrender(create);
  };

  useEffect(() => {
    document.title = `PeopleSol - Surrenser Asset`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  if(loading)return <Loader/>

  else return (
    <Formik
      initialValues={{ SurrenderedToEmployeeId: "",SurrenderCondition:""}}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div>
          <LabelCustom labelName={"Surrendered To "} mandatory={true} />
          <SelectForm name="SurrenderedToEmployeeId" options={Employee} />
          <div></div>
          <LabelCustom labelName={"Condition "} mandatory={true} />
          <TextAreaFormik style={{ height: "80px" }} name={"SurrenderCondition"} maxLength={500}/>
        </div>
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ background: color, color: "white" }}
          >
            {type} 
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export  {SurrenderAsset}