/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest, getRequestZ } from "../../../Services/axios";
import Nodata from "../../../Assets/Nodata.svg";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { ParticularDetailsComp } from "../DeclarationComp/ParticularDetailsComp";
import { SubmissionDetails } from "./SubmissionDetails";
import { saveAs } from 'file-saver';
const formFields = [
  {
    id: 1,
    heading: "Annual income other than salary",
    compName: "incomeOtherThanSalary",
    body: [
      {
        id: 1,
        label: "Dividend Income",
        value: "dividendIncome",
      },
      {
        id: 2,
        label: "Interest from saving account",
        value: "interestfromsavingaccount",
      },
      {
        id: 3,
        label: "Interest from fixed Deposit",
        value: "interestfromfixeddeposit",
      },
      {
        id: 4,
        label: "Any other income(other than rent)",
        value: "otherIncome",
      },
    ],
  },
  {
    id: 2,
    heading: " Annual allowances paid as part of the salary ",
    compName: "reimbursementSalary",
    body: [
      {
        id: 1,
        label: "Car allowance",
        value: "carReimpursement",
      },
      {
        id: 2,
        label: "Driver Salary allowance",
        value: "driverSalaryReimpursement",
      },
      {
        id: 3,
        label: "Books and Periodicals",
        value: "booksAndPeriodicals",
      },
      {
        id: 4,
        label: "Gadget allowance",
        value: "gadgetReimpursement",
      },
      {
        id: 5,
        label: "Medical Expenses allowance",
        value: "medicalExpensesReimpursement",
      },
    ],
  },
  {
    noteText:
      "Declare your investments includes insuarance premium, mutual funds,housing loadn, PPF,etc.The maximum limit for this section is ₹1,50,000 ",
    id: 3,
    heading: "Investments under section 80C, 80CC (Investment)",
    compName: "investment80C",
    body: [
      {
        id: 4,
        label: "Mutual Fund",
        value: "mutualFund",
      },
      {
        id: 3,
        label: "Health insurance Premium",
        value: "insurance",
      },
      {
        id: 2,
        label: "Public Provident Fund(PPF)",
        value: "ppf",
      },
      {
        id: 1,
        label: "Housing Loan Repayment Principle",
        value: "houseLoanPrincipal",
      },
      {
        id: 5,
        label: "National Saving Scheme / Certificate",
        value: "savingScheme",
      },
      {
        id: 6,
        label: "Sukanya Samriddhi Account",
        value: "sukanyaSamriddhiAccount",
      },
      {
        id: 7,
        label: "Children Education Fees (only School tuition fees)",
        value: "educationFee",
      },
      {
        id: 8,
        label: "Allowable Term Deposit",
        value: "termDeposit",
      },
    ],
  },
  {
    noteText:
      "Declare your additional investments in NPS.The maximum limit for this section is ₹50,000",
    id: 4,
    heading: "Investments under section 80CCD (Pension Contribution)",
    compName: "investment80CCD",
    body: [
      {
        id: 1,
        label: "National Pension Scheme",
        value: "employeesContribution",
      },
    ],
    maxLimit: "₹50,000",
  },
  {
    noteText:
      "Declare medical insurance for self,spouse,children and parents. For self,spouse and children maximum limit is ₹25,000. For parents more than 60 years maximum limit is ₹50,000.",
    id: 5,
    heading: "Investments under section 80D (Medical Insurance)",
    compName: "investment80D",
    body: [
      {
        id: 1,
        label: "Medical Insurance Premium for Senior Citizen",
        value: "insurance80d",
      },
      {
        id: 2,
        label: "Expenditure on Medical Treatment for specified disease",
        value: "expenditure80ddb",
      },
      {
        id: 3,
        label: "Deduction in maintenance of medical treatment of a handicapped",
        value: "deduction80dd",
      },
    ],
    maxLimit: "₹25,000",
  },
  {
    noteText: "Interest paid for a duration of 8 years.",
    id: 6,
    heading: "Investments under section 80E (Education Loan Interest)",
    compName: "investment80E",
    body: [
      {
        id: 1,
        label: "Repayment of loan taken for Higher Education",
        value: "education80e",
      },
    ],
  },
  {
    id: 7,
    heading: "Any other Investment qualify for IT Deduction",
    compName: "investmentOther",
    body: [
      {
        id: 1,
        label: "Leave Travel Assistance (LTA)",
        value: "leaveTravelAssisatant",
      },
      {
        id: 2,
        label: "Permanent Physical Disability",
        value: "ppd80u",
      },
      {
        id: 3,
        label: "Any other Investment",
        value: "otherInvestmentQualify",
      },
    ],
  },
];
const DetailsPageDeclaredAndSubmitted = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const id = useParams();
  let xy = Object.keys(id);
  const n = id[xy].lastIndexOf("/");
  const selectedId = id[xy].substring(n + 1);
  const data = location?.state?.id;
  useEffect(() => {
    document.title = `PeopleSol - Declaration Services`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const closeDetails = () => {
    navigate(-1);
  };
  const exportTable = async () => {
    try {
      // Make the API call with responseType set to 'arraybuffer' for binary data handling
      const result = await getRequestZ(`taxdeclaration/downloadall/?ID=${selectedId}`, {
        responseType: 'arraybuffer',
      });

      // Create a Blob object with the binary data received from the API
      const blob = new Blob([result.data], { type: 'application/zip' });

      // Use FileSaver.js to trigger the download
      saveAs(blob, 'Tax.zip');

    } catch (error) {
      console.error(error);
    }
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(
        `taxdeclaration/details/?ID=${data}&SettingsId=${selectedId}`
      );
      setEventtData(result?.data?.summary?._taxDeclarartion);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);
  //console.log("event", data, selectedId);

  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Tax Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          {loading ? (
            <Loader />
          ) : eventtData ? (
            <div className="detailsEReq">
              <div
                className="headingContainer"
                style={{ padding: "12px 24px", width: "100%" }}
              >
                <div style={{ display: "flex" }}>
                  <h4>
                    {eventtData?.taxDeclarationSettings?.emailTemplate ===
                    "Document Submission"
                      ? "Submitted Tax Details"
                      : "Declared Tax Details"}
                  </h4>
                </div>
                <button
                  className="primary button"
                  style={{ backgroundColor: "var(--primary)", color: "white" }}
                  onClick={exportTable}
                >
                  {" "}
                  Download All
                </button>
              </div>
              <div className="detailInner">
                {eventtData?.taxDeclarationSettings?.emailTemplate ===
                "Document Submission" ? (
                  <SubmissionDetails
                    formFields={formFields}
                    detailsform={eventtData}
                  />
                ) : (
                  <ParticularDetailsComp
                    formFields={formFields}
                    formValues={eventtData}
                    detailsValues={eventtData}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { DetailsPageDeclaredAndSubmitted };
