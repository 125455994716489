import React from 'react'
import { InputCustomFormik } from '../../../../Components/FormComponent/InputCustomFormik';
import { LabelCustom } from '../../../../Components/FormComponent/LabelCustom';
import { useEffect } from 'react';

 const ServiceCategoryForm = () => {
  useEffect(() => {
    document.title = `PeopleSol - Service Category`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
    return (
        <div >
            <LabelCustom labelName={"Name"} mandatory={true} />
            <InputCustomFormik
            
            maxLength={150}
              name="name"
              type={"text"}
              placeholder={"Enter Name"}
            />
    
        </div>
      );
    };
    export {ServiceCategoryForm}