import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import "./Time.css";
import { useLocation, useNavigate } from "react-router-dom";
import { TTProjects } from "../../HRMS/Components/TTProjects";
import { Shift } from "../TimeComponents/Shift";
import { Holidays } from "../TimeComponents/Holidays";
import { LeaveBalance } from "../TimeComponents/LeaveBalance";
import { LeaveType } from "../TimeComponents/LeaveType";
import { TimeConfig } from "../TimeComponents/TimeConfig";
import { getRequest } from "../../../Services/axios";
import OfficeLocation from "../TimeComponents/OfficeLocation";

const Time = ({ featureMenu }) => {
  // const [timeAttandanceData, setTimeAttandanceData]=useState();
  const [tabIndex, setTabIndex] = useState(null);
  const handleTabChange = (event, newTabIndex) => {
    navigate(`timeattendance/${newTabIndex}`);
  };
 // console.log("tabIndex",tabIndex)
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = `PeopleSol - Time`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrmsmaster/timeattendance")) {
      let splitArray = currentpath.split("/");
      let submoduleIndex = splitArray.findIndex((x) => x === "timeattendance");
      if (featureMenu) {
        if (splitArray?.length > submoduleIndex + 1) {
          if (
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.some(
                (x) =>
                  x.featureName
                    ?.replaceAll(" ", "")
                    ?.replaceAll("&", "")
                    ?.toLowerCase() === splitArray[submoduleIndex + 1]
              )
          ) {
            setTabIndex(splitArray[submoduleIndex + 1]);
          } else {
            setTabIndex(
              featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()
            );
            navigate(
              `timeattendance/${featureMenu
                ?.slice()
                ?.filter((x) => x.isFeatureActive)
                ?.sort(
                  (a, b) =>
                    parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
                )[0]
                .featureName?.replaceAll(" ", "")
                ?.replaceAll("&", "")
                ?.toLowerCase()}`
            );
          }
        } else {
          setTabIndex(
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()
          );
          navigate(
            `timeattendance/${featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )[0]
              .featureName?.replaceAll(" ", "")
              ?.replaceAll("&", "")
              ?.toLowerCase()}`
          );
        }
      }
    }
  }, [location.pathname, featureMenu]);

  
//  useEffect(()=>{
//     const result = getRequest(`TimeAttendanceConfig/Details`);
//     if (result){
//      // console.log(result)
//       result.then((response)=>{
//        // console.log(response)
//         setTimeAttandanceData(response.data);
//       }).catch((error)=>{
//        // console.log(error)
//       })
      
//     }
// },[tabIndex==="timeattendanceconfig",]);


  return (
    <div className="moduleMainContainer">
      <div className="tabContainer">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { background: "#1296B0" } }}
        >
          {featureMenu &&
            featureMenu
              ?.slice()
              ?.filter((x) => x.isFeatureActive)
              ?.sort(
                (a, b) =>
                  parseFloat(a.featureOrder) - parseFloat(b.featureOrder)
              )
              ?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    value={item?.featureName
                      ?.replaceAll(" ", "")
                      ?.replaceAll("&", "")
                      ?.toLowerCase()}
                    label={
                      <span
                        className={`tabHeading ${
                          tabIndex ===
                            item?.featureName
                              ?.replaceAll(" ", "")
                              ?.replaceAll("&", "")
                              ?.toLowerCase() && "tabActive"
                        }`}
                      >
                        {item?.featureDisplayName}
                      </span>
                    }
                  />
                );
              })}
        </Tabs>
        <div className="tabContainerLine"></div>
      </div>
      {tabIndex === "shift" && (
        <>
          <Shift title="Shift" />
        </>
      )}
      {tabIndex === "holidays" && (
        <>
          <Holidays title="Holidays" />
        </>
      )}
      {tabIndex === "leavebalance" && (
        <>
          <LeaveBalance title="Leave Balance" />
        </>
      )}
      {tabIndex === "leavetype" && (
        <>
          <LeaveType title="Leave Type" />
        </>
      )}
      {tabIndex === "timeattendanceconfig" && (
        <>
          <TimeConfig title="Time" />
        </>
      )}
      {tabIndex === "officelocation" && (
        <>
         <OfficeLocation title="Office Location"/>
        </>
      )}
    </div>
  );
};

export { Time };
