import React, { useState } from "react";
import "./GoalsCard.css";
import { SelectCustom } from "../../../../../Components/FormComponent/SelectCustom";
import { getRequest } from "../../../../../Services/axios";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import NoRequestsFound from "./NoRequestsFound";
import Skeleton from "react-loading-skeleton";
import SelectCustomEmployeeCreateAttendance from "../../../../HRMS/Components/Time & Attendance/SelectCustomEmployeeCreateAttendance";

const GoalsCard = ({ isManager, navigate, teamList }) => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  const [selectedEmployee, setSelectedEmployee] = useState([
    { label: "Myself", value: LoggedInUser?.id },
  ]);
  const [idActive, setIdActive] = useState(LoggedInUser?.id);
  const [loading, setLoading] = useState(false);
  const [goalList, setGoalList] = useState(null);

  const getGoalsData = async (id) => {
    if (!id) return null;
    try {
      setLoading(true);
      const result = await getRequest(`Goals/EmployeeGoals?EmployeeId=${id}`);
      setGoalList(result?.data?.data?.pmsGoals);
    } catch (error) {
      setGoalList(null);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getGoalsData(idActive);
  }, [idActive]);
  useEffect(() => {
    if (LoggedInUser) {
      setIdActive(LoggedInUser?.id);
    }
  }, [LoggedInUser]);
  return (
    <>
      <div
        className="headingContainer cursor headingWithDropdown"
        style={{ marginBottom: "-12px", zIndex: "999" }}
      >
        <h3 className="cursor" onClick={() => navigate("/pms/goals/mygoals")}>
          Goals & Objectives
        </h3>
        {isManager && (
          <div style={{ width: "48%" }}>
            <SelectCustomEmployeeCreateAttendance
              styles={{
                minWidth: "100%",
                width: "100%",
                maxWidth: "100%",
              }}
              selectedEmployeeList={selectedEmployee}
              setSelectedEmployeeList={setSelectedEmployee}
              values={selectedEmployee}
              setIdActive={setIdActive}
              urlProp={"Employees/GetEmployeesForAttendance"}
              lgid={LoggedInUser?.id}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
              deepStyle={{ backgroundColor: "var(--primary-hover)" }}
            />
            {/* <SelectCustom
              dropdownHeight="236px"
              styles={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
              values={selectedEmployee}
              options={teamList}
              onChange={(value) => {
                setSelectedEmployee(value);
                setIdActive(value?.[0]?.value);
              }}
              fullWidth={true}
              styl={{
                backgroundColor: "var(--primary-hover)",
                margin: 0,
                minHeight: "40px",
                maxHeight: "40px",
                padding: "5px 10px",
              }}
            /> */}
          </div>
        )}
      </div>
      <div className="dashboardCardContentHolder">
        {loading ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
            }}
          >
            <Skeleton
              width="100%"
              height="100%"
              style={{ position: "absolute" }}
            />
          </div>
        ) : (
          <>
            {goalList?.length ? (
              <div
                className="approvalFrameTabData"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <table className="teamAttendanceCard" style={{ width: " 97%" }}>
                  <tr className="teamAttendanceTableHeader">
                    <th>Objective</th>
                    <th>KPI Name</th>
                    <th>KPI Threshold</th>
                    <th>Weightage %</th>
                  </tr>
                  {goalList &&
                    goalList?.map((val, key) => {
                      return (
                        <tr key={key} className="teamAttendanceItemContainer">
                          <td className="teamAttendanceNameItem">{val.name}</td>
                          <td className="teamAttendanceItem">
                            {val.description}
                          </td>
                          <td className="teamAttendanceItem">{val.kra}</td>
                          <td className="teamAttendanceItem">
                            <div className="progressHolder">
                              <label className="goalsText">
                                {val.weightage
                                  ? `${parseFloat(val.weightage).toFixed(0)}%`
                                  : "--"}
                              </label>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            ) : (
              <NoRequestsFound text={"No Goals"} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export { GoalsCard };
