import { Form, Formik } from "formik";
import React from "react";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import * as Yup from "yup";
import { useEffect } from "react";
export const Approve = ({ data, id, type, color, handleClose }) => {
  const validationSchema = Yup.object({
    Remarks: Yup.string().required("Required"),
  });
  const onSubmit = async (values) => {
    data(type, id, values.Remarks);
  };
  useEffect(() => {
    document.title = `PeopleSol - Approve `;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
  <div style={{width:"480px"}}>
      <Formik
      initialValues={{ Remarks: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div style={{ display: "flex",alignItems:'center',flexDirection:"column" }}>
          <h4> Are you sure you want to approve this request?</h4>

          <div className="textAp">
            By approving this, I am confirming that I have read and understood
            the exception details and assessed all possible consequences/risks
            arising due to the exception. I have identified the risks as
            mentioned in this form and alternative (compensatory/mitigating)
            controls to reduce the risk of exposure and have documented them as
            above as applicable. I ensure that this exception is used for the
            business needs, and that the responsibility for such risk lies with
            the requester employee and self.
          </div>
        </div>
        <TextAreaFormik style={{ height: "80px" }} name={"Remarks"} maxLength={500}/>
        <div className="createFootCust">
          <button
            type="button"
            className="button secondaryButton"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="button primaryButton"
            type="submit"
            style={{ background: color, color: "white" }}
          >
            {type}
          </button>
        </div>
      </Form>
    </Formik>
  </div>
  );
};
