import React from 'react';
import { AssetIt } from './AssetComponent/AssetIt';
import { useEffect } from 'react';
const AssetTab = () => {
    useEffect(() => {
        document.title = `PeopleSol - Asset`;
        return () => {
          document.title = 'PeopleSol';
        };
      }, []);
    return (
        <div className="moduleMainContainer">
                    <AssetIt title="Asset"/>

        </div>
    )
}

export { AssetTab }