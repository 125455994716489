import React, { useState } from "react";
import LoginPerson from "../../Assets/LoginPerson.png";
import LoginBack from "../../Assets/LoginBack.png";
import "../Styles.css";
import { Form, Formik } from "formik";
import { InputCustomFormik } from "../../Components/FormComponent/InputCustomFormik";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../Features";
import axios from "axios";
import { useEffect } from "react";
// import { useDispatch } from 'react-redux';
// import { loggedOut } from '../../Features';

const ForgotPassword = () => {
  useEffect(() => {
    document.title = `PeopleSol - Forgot Password`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  const [email, setEmail] = useState("");

  const [loading, setloading] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = async (payload) => {
    dispatch(isLoading(true));
    setloading(true);
    await axios("SubmitCandidate/Checkemail", {
      params: payload,
      baseURL: process.env.REACT_APP_.BASE_URL,
      method: "POST",
    })
      .then((response) => {
        if (response.data === "Invalid email address or user not found.") {
          return;
        }
        dispatch(
          showToast({
            text: "Otp has been sent to your email id",
            severity: "success",
          })
        );
        navigate("/verifyotp", {
          state: { email: payload.email, id: response.data.otpRecordId },
        });
      })
      .catch((error) => {
        if (
          error?.request?.responseText === "Provided Email is not Valid!" ||
          error?.request?.status === 406
        ) {
          dispatch(
            showToast({
              text: "Provided Email is not Valid!. Please try again later ",
              severity: "error",
            })
          );
          return;
        }
        dispatch(
          showToast({
            text: "There was an issue forgotting your password. Please try again later ",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(isLoading(false));
        setloading(false);
      });
  };
  return (
    <div className="landing">
      <img
        src={LoginBack}
        alt="LoginBack"
        style={{ position: "absolute", width: "100%", height: "100%" }}
      />
      <div className="loginGraphics">
        <img
          src={LoginPerson}
          alt="LoginPerson"
          className="newLoginGraphicImage"
        />
      </div>
      <div className="loginForm">
        <div className="loginFormBox">
          <div className="backtol" onClick={() => navigate("/login")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="8"
              viewBox="0 0 18 8"
              fill="none"
            >
              <path
                d="M16.5762 4.6748C16.8523 4.6748 17.0762 4.45095 17.0762 4.1748C17.0762 3.89866 16.8523 3.6748 16.5762 3.6748V4.6748ZM0.484619 3.82125C0.289358 4.01651 0.289358 4.3331 0.484619 4.52836L3.6666 7.71034C3.86186 7.9056 4.17844 7.9056 4.37371 7.71034C4.56897 7.51508 4.56897 7.19849 4.37371 7.00323L1.54528 4.1748L4.37371 1.34638C4.56897 1.15112 4.56897 0.834533 4.37371 0.639271C4.17844 0.444009 3.86186 0.444009 3.6666 0.639271L0.484619 3.82125ZM16.5762 3.6748L0.838173 3.6748V4.6748L16.5762 4.6748V3.6748Z"
                fill="#F7941D"
              />
            </svg>
            <span>Back to Login</span>
          </div>
          <div className="mainiconf">
            <div style={{ marginTop: "100px" }}>
              <svg
                width="153"
                height="153"
                viewBox="0 0 153 153"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse
                  cx="76.5884"
                  cy="76.7578"
                  rx="75.8228"
                  ry="76"
                  fill="#E3FDFC"
                />
                <rect
                  x="56.796"
                  y="39.5265"
                  width="38.8694"
                  height="45.4741"
                  rx="12.7762"
                  stroke="#FFDBA6"
                  stroke-width="2.83916"
                />
                <line
                  x1="80.0444"
                  y1="39.5265"
                  x2="72.4173"
                  y2="39.5265"
                  stroke="#F7941D"
                  stroke-width="2.83916"
                />
                <rect
                  x="43.5803"
                  y="63.7429"
                  width="65.3008"
                  height="51.2244"
                  rx="5.67833"
                  fill="#1296B0"
                />
                <ellipse
                  cx="57.2295"
                  cy="89.1798"
                  rx="2.23854"
                  ry="2.24378"
                  fill="#E2F8F6"
                />
                <ellipse
                  cx="70.2244"
                  cy="89.1796"
                  rx="2.23854"
                  ry="2.24378"
                  fill="#E2F8F6"
                />
                <ellipse
                  cx="83.219"
                  cy="89.1796"
                  rx="2.23854"
                  ry="2.24378"
                  fill="#E2F8F6"
                />
                <ellipse
                  cx="96.2132"
                  cy="89.1796"
                  rx="2.23854"
                  ry="2.24378"
                  fill="#E2F8F6"
                />
                <ellipse
                  cx="105.251"
                  cy="110.437"
                  rx="12.029"
                  ry="12.0571"
                  fill="#FFDBA6"
                />
                <path
                  d="M105.223 103.826C106.483 103.826 107.49 104.172 108.243 104.866C109.009 105.559 109.392 106.507 109.392 107.708C109.392 108.958 108.997 109.899 108.206 110.531C107.416 111.162 106.366 111.478 105.056 111.478L104.982 112.946H103.148L103.055 110.029H103.666C104.865 110.029 105.779 109.868 106.409 109.546C107.051 109.225 107.372 108.612 107.372 107.708C107.372 107.051 107.181 106.537 106.798 106.166C106.427 105.795 105.909 105.609 105.241 105.609C104.574 105.609 104.049 105.788 103.666 106.147C103.283 106.507 103.092 107.008 103.092 107.652H101.109C101.109 106.909 101.276 106.246 101.609 105.665C101.943 105.083 102.419 104.631 103.036 104.309C103.666 103.987 104.395 103.826 105.223 103.826ZM104.037 117.05C103.654 117.05 103.333 116.92 103.073 116.66C102.814 116.4 102.684 116.078 102.684 115.694C102.684 115.311 102.814 114.989 103.073 114.729C103.333 114.469 103.654 114.339 104.037 114.339C104.408 114.339 104.723 114.469 104.982 114.729C105.241 114.989 105.371 115.311 105.371 115.694C105.371 116.078 105.241 116.4 104.982 116.66C104.723 116.92 104.408 117.05 104.037 117.05Z"
                  fill="#1296B0"
                />
              </svg>
            </div>
            <div className="forgheaddiv">
              <p className="forgotHead">Forgot Password</p>
              <p className="headingTxt">
                Please enter your email address below{" "}
              </p>
              <p className="headingTxt">to reset your password</p>
            </div>
            <div style={{ width: "100%" }}>
              <Formik
                initialValues={{ email: "" }}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Required";
                  }
                  return errors;
                }}
                // onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
                onSubmit={(values) => {
                  onSubmit(values);
                }}
              >
                <Form>
                  <div className="forgotForm">
                    <div className="emfor">
                      <label htmlFor="email" style={{ marginLeft: "10px" }}>
                        Email{" "}
                        <span
                          style={{
                            color: "red",
                            paddingBottom: "5px",
                            paddingLeft: "5px",
                          }}
                        >
                          *
                        </span>
                      </label>
                    </div>
                    <InputCustomFormik
                      className="inputfor forgotInput"
                      type="email"
                      name="email"
                      placeholder="Enter your email "
                    />
                    <button
                      className="btnfor primaryButton "
                      type="submit"
                      disabled={loading ? true : false}
                      style={{ margin: "15px 0px 15px 10px", padding: "12px" }}
                    >
                      Send Email
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ForgotPassword };
