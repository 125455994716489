import React, { useRef, useState } from "react";
import "./Table.css";

const TimeSheetTable = ({ theadData, tbodyData, tfooterData }) => {
    const focusPoint = useRef(null);
    const [isTableScrolled, setIsTableScrolled] = useState(false);
    const onScroll = () => {
        let scrollX = focusPoint.current.scrollLeft;
        if (scrollX > 0) {
            setIsTableScrolled(true);
        }
        else {
            setIsTableScrolled(false);
        }
    }
    return (
        <table className={isTableScrolled ? "table timesheet tableScrolled" : "table timesheet"} onScroll={onScroll} ref={focusPoint}>
            <colgroup>
                <col style={{ width: "100%" }} />
                {theadData.slice(1).map((h, index) => {
                    return (
                        <col key={index} />
                    );
                })}
            </colgroup>
            <thead className="thead">
                <tr>
                    <td title={theadData[0]} key={theadData[0]}>
                        <span className="firstCellHeader">{theadData[0].split("-")[0]}-</span>
                        <span className="firstCellHeader">{theadData[0].split("-")[1]}-</span>
                        <span className="firstCellHeader">{theadData[0].split("-")[2]}</span>
                        <span className="firstCellHeader">{theadData[0].split("-")[3]}</span>
                    </td>
                    <td title={theadData[1]} key={theadData[1]}>
                        {theadData[1]}
                    </td>
                    {theadData.slice(2, -1).map((h) => {
                        return (
                            <td title={h} key={h}>
                                {h.substring(0, h.indexOf(' '))}
                                <br />
                                <span className="dateOfDay">{h.substring(h.indexOf(' ') + 1)}</span>
                            </td>
                        );
                    })}
                    <td title={theadData[theadData.length - 1]} key={theadData[theadData.length - 1]}>
                        {theadData[theadData.length - 1]}
                    </td>
                </tr>
            </thead>
            <tbody>
                {tbodyData.map((item) => {
                    return (
                        <tr key={item.id}>
                            <td key={item.items[0]}>
                                <span className="firstCellHeader">{item.items[0].split("-")[0]}-</span>
                                <span className="firstCellHeader">{item.items[0].split("-")[1]}-</span>
                                <span className="firstCellHeader">{item.items[0].split("-")[2]}</span>
                                <span className="firstCellHeader">{item.items[0].split("-")[3]}</span>
                            </td>
                            <td key={item.items[1]}>{item.items[1]}</td>
                            {item.items.slice(2, -1).map((item) => {
                                return <td key={item}>{item}</td>;
                            })}
                            <td key={item.items[item.items.length - 1]}>
                                {item.items[item.items.length - 1]}
                                {/* <div className="skillLineBackground timesheetHourBar"><div className="skillBarLine sixty"></div></div> */}
                            </td>
                        </tr>
                    );
                })}
                <tr className="footerRow">
                    {tfooterData.map((item) => {
                        return (
                            <td className="footerCell" key={item}>
                                {item}
                            </td>
                        )
                    })}
                </tr>
            </tbody>
        </table>
    );
};

export { TimeSheetTable };