import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import "./Separation.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Resignation } from "./SeparationComponents/Resignation";
import { DueClearance } from "./SeparationComponents/DueClearance";
import { FinalSettlements } from "./SeparationComponents/FinalSettlements";
import { ExitLetters } from "./SeparationComponents/ExitLetters";
import Nodata from "../../../Assets/Nodata.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Separation = () => {
  const { LoggedInUser } = useSelector((state) => state.auth);
  useEffect(() => {
    isHrManagers();
  }, [LoggedInUser]);
  const HRDepartments = [
    "Human Resource",
    "Talent Acquisition",
    "Talent Management",
    "L&OD",
    "Performance Management Sys",
    "Initiatives, Audit & Budgeting",
    "HR- Business Partners",
    "HR- Operations",
    "Payroll",
    "Compliance",
    "Employee Lifecycle",
    "Compensation & Benefits",
    "Plant-HR",
    "Farm-HR",
    "AESS Solutions Private Limited",
  ];
  const isHrManagers = () => {
    if (
      LoggedInUser?.teamnames?.includes("Offboarding Team") ||
      LoggedInUser?.permissions?.includes("Can Access Due Clearance?") ||
      LoggedInUser?.hrbpLocationIds?.length ||
      LoggedInUser?.hrbpDepartmentIds?.length ||
      LoggedInUser?.isHRDirector ||
      LoggedInUser?.isHRHead
    )
      setIsHRManager(true);
  };
  const [isHRManager, setIsHRManager] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    if (newTabIndex === 0) {
      navigate("separation/resignation");
    } else if (newTabIndex === 1) {
      navigate("separation/dueclearance");
    } else if (newTabIndex === 2) {
      navigate("separation/finalsettlement");
    } else if (newTabIndex === 3) {
      navigate("separation/exitletters");
    }
  };
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const currentpath = location.pathname.toLowerCase();
    if (currentpath.includes("hrms/separation")) {
      if (currentpath.includes("resignation")) {
        setTabIndex(0);
      } else if (currentpath.includes("dueclearance")) {
        setTabIndex(1);
      } else if (currentpath.includes("finalsettlement")) {
        setTabIndex(2);
      } else if (currentpath.includes("exitletters")) {
        setTabIndex(3);
      } else {
        navigate("separation/resignation");
      }
    }
  }, [location.pathname]);
  const getDuePermission = () => {
    if (
      LoggedInUser?.teamnames?.includes("Offboarding Team")||
      LoggedInUser?.hrbpLocationIds?.length ||
      LoggedInUser?.hrbpDepartmentIds?.length ||
      LoggedInUser?.isHRDirector ||
      LoggedInUser?.isHRHead ||
      LoggedInUser?.permissions?.includes("Can Access Due Clearance?")
    )
      return true;
    else return false;
  };
  const ViewAllReq = () => {
    if (
    
      LoggedInUser?.teamnames?.includes("Offboarding Team") ||
      LoggedInUser?.hrbpLocationIds?.length ||
      LoggedInUser?.hrbpDepartmentIds?.length ||
      LoggedInUser?.permissions?.includes("Can Access Due Clearance?")
    )
      return true;
    else return false;
  };
  useEffect(() => {
    document.title = `PeopleSol - Separation`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMainContainer" style={{overflow:'none'}}>
      {isHRManager ? (
        <>
          <div className="tabContainer">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: "#1296B0" } }}
            >
              {
                ViewAllReq() && (
                <Tab
                  value={0}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 0 && "tabActive"}`}
                    >
                      Resignation
                    </span>
                  }
                />
              )}
              {getDuePermission() === true && (
                <Tab
                  value={1}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 1 && "tabActive"}`}
                    >
                      Due Clearance
                    </span>
                  }
                />
              )}
              {(LoggedInUser?.teamnames?.includes("Offboarding Team") ||
                LoggedInUser?.hrbpLocationIds?.length ||
                LoggedInUser?.hrbpDepartmentIds?.length ||
                LoggedInUser?.isHRDirector ||
                LoggedInUser?.isHRHead) && (
                <Tab
                  value={2}
                  label={
                    <span
                      className={`tabHeading ${tabIndex === 2 && "tabActive"}`}
                    >
                      Final Settlement
                    </span>
                  }
                />
              )}
             {(LoggedInUser?.teamnames?.includes("Offboarding Team") ||
                LoggedInUser?.hrbpLocationIds?.length ||
                LoggedInUser?.hrbpDepartmentIds?.length ||
                LoggedInUser?.isHRDirector ||
                LoggedInUser?.isHRHead) && ( <Tab
                value={3}
                label={
                  <span
                    className={`tabHeading ${tabIndex === 3 && "tabActive"}`}
                  >
                    Exit Feedback
                  </span>
                }
              />)}
            </Tabs>
            <div className="tabContainerLine"></div>
          </div>
          {tabIndex === 0 && (
            <>
              <Resignation title={"Resignation"} />
            </>
          )}
          {tabIndex === 1 && (
            <>
              <DueClearance title={"Due Clearance"} />
            </>
          )}
          {tabIndex === 2 && (
            <>
              <FinalSettlements title={"Final Settlement"} />
            </>
          )}
          {tabIndex === 3 && (
            <>
              <ExitLetters title={"Exit Feedback"} />
            </>
          )}
        </>
      ) : (
        <div className="noDatastyle">
          <img
            src={Nodata}
            alt="No data"
            style={{ height: "150px", width: "250px" }}
          />
          <h4>{"You don't have permission to access seperation module  "}</h4>
        </div>
      )}
    </div>
  );
};

export { Separation };
