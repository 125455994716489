import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { showToast, isLoading } from "../../../Features";
import { getRequest, postRequest } from "../../../Services/axios";
import { TitleComp } from "./FormComp/TitleComp";
import dateFormat from "dateformat";
import { WeekdayOff } from "./FormComp/WeekdayOff";
import {
  FormikMultiStepForm,
  FormikMultiStepFormStep,
} from "../../../Components/FormikMultiStepForm/FormikMultiStepForm";
import { format } from "date-fns";

const CreateShift = (props) => {
  const [detailsform, setDetailsform] = useState({});
  const { state } = useLocation();

  const data = state?.id;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const getFormDetails = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Shifts/Details/${data}`);
      setDetailsform(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      getFormDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createPageData = async (values) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("Shifts/Create", values);
      if (result)
        dispatch(
          showToast({ text: "Successfully Created", severity: "success" })
        );
      returnPage();
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Some error occured in creating shift",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };

  const Edit = async (detailsform) => {
    try {
      const result = await postRequest("Shifts/Edit", detailsform);
      if (result) {
        dispatch(
          showToast({ text: "Successfully Updated", severity: "success" })
        );
        returnPage();
      }
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Some error occured in editing shift",
          severity: "error",
        })
      );
    }
  };

  const getInitalWeekdayValues = () => {
    let returnArray = [{ days: "", title: "", fromTime: "", toTime: "" }];
    if (data)
      if (detailsform)
        if (detailsform.weekDays) {
          returnArray = [];
          detailsform?.weekDays?.map((item) => {
            return returnArray.push({
              days: item.days,
              title: item.title,
              fromTime: item.fromTime,
              toTime: item.toTime,
            });
          });
        }
    return returnArray;
  };

  const getInitalWeekoffsValues = () => {
    let returnArray = [{ weekOffDates: null }];
    if (data)
      if (detailsform)
        if (detailsform.additionalWeekoffs) {
          returnArray = [];
          detailsform?.additionalWeekoffs?.map((item) => {
            return returnArray.push({
              weekOffDates: item.weekOffDates
                ? new Date(item.weekOffDates)
                : null,
            });
          });
        }
    return returnArray;
  };

  const initialValues = {
    title: data ? detailsform?.title : "",
    shiftCode: data ? detailsform?.shiftCode : "",
    areaIds: data ? detailsform?.areaIds : "",
    From: detailsform?.from ? new Date(`12/12/12 ${detailsform?.from}`) : null,
    To: detailsform?.to ? new Date(`12/12/12 ${detailsform?.to}`) : null,
    weekOffDays: data ? detailsform?.weekOffDays : "",
    isActive: data ? (detailsform?.isActive ? "Yes" : "No") : "Yes",
    weekDays: getInitalWeekdayValues(),
    additionalWeekoffs: getInitalWeekoffsValues(),
  };
  const getApiAdditionalWeekoffsValues = (additionalWeekoffs) => {
    let returnArray = null;
    if (additionalWeekoffs) {
      if (additionalWeekoffs[0]?.weekOffDates) {
        returnArray = [];
        additionalWeekoffs.map((item) => {
          if (item.weekOffDates)
            return returnArray.push({
              weekOffDates: item.weekOffDates
                ? format(new Date(item.weekOffDates), "yyyy-MM-dd")
                : null,
            });
          return null;
        });
      }
    }
    return returnArray;
  };
  const onSubmit = (values) => {
    const edit = {
      ...values,
      From: values?.From && dateFormat(values?.From, "h:MM TT"),
      To: dateFormat(values?.To, "h:MM TT"),
      isActive: values.isActive === "Yes" ? true : false,
      additionalWeekoffs: getApiAdditionalWeekoffsValues(
        values?.additionalWeekoffs
      ),
      id: detailsform?.id,
    };
console.log("edit",edit)
    const create = {
      ...values,
      From: dateFormat(values?.From, "h:MM TT"),
      To: dateFormat(values?.To, "h:MM TT"),
      isActive: true,
      additionalWeekoffs: getApiAdditionalWeekoffsValues(
        values?.additionalWeekoffs
      ),
      id: 0,
    };

    data ? Edit(edit) : createPageData(create);
  };
  const [areaOptions, setAreaOptions] = useState([]);

  const getOption = async () => {
    try {
      const result = await getRequest("Area/GetAreas");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setAreaOptions(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOption();
  }, []);
  const navigate = useNavigate();

  const returnPage = () => {
    if (state?.returnUrl) navigate(state?.returnUrl);
    else navigate(-1);
  };

  return (
    <FormikMultiStepForm
      initialValues={initialValues}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      returnPage={returnPage}
      heading={data ? "Edit Shift" : "Create Shift"}
      submitText={data ? "Update Shift" : "Create Shift"}
      loading={loading}
    >
      <FormikMultiStepFormStep
        label="Shift Details"
        validationSchema={Yup.object({
          title: Yup.string()
            .max(49, "Title must be less than 50 characters")
            .required("Please Enter Title"),
          shiftCode: Yup.string()
            .max(6, "Code must be less than 6 characters")
            .required("Please Enter Code"),
          areaIds: Yup.string().required("Please Enter Area"),
        })}
      >
        <TitleComp
          areaOptions={areaOptions}
          data={detailsform}
          edit={data ? true : false}
        />
      </FormikMultiStepFormStep>
      <FormikMultiStepFormStep
        label="Week Days"
        validationSchema={Yup.object({
          weekDays: Yup.array().of(
            Yup.object().shape({
              days: Yup.string().required("Please Enter Number Of Days"),
              title: Yup.string()
                .max(49, "Title must be less than 50 characters")
                .required(" Please Enter Title"),
              fromTime: Yup.string().required(" Please Enter From Time"),
              toTime: Yup.string().required("To Time is required"),
            })
          ),
        })}
      >
        <WeekdayOff />
      </FormikMultiStepFormStep>
    </FormikMultiStepForm>
  );
};

export { CreateShift };
