import React, { useEffect } from "react";
import { InputCustomFormik } from "../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../Components/FormComponent/SelectForm";
import { getRequest } from "../../../../Services/axios";
const opt = [{ label: "select", value: null }];
const type = [
  { label: "IT Infra", value: "IT Infra" },
  { label: "SAP", value: "SAP" },
  { label: "IT Application ", value: "IT Application " },
  { label: "My Assets ", value: "My Assets " },
];
const MaterialForm = ({ data }) => {
  const [option, setOption] = React.useState([]);
  const getOptionReq = async () => {
    try {
      const result = await getRequest("AssetCategories/GetAssetCategories");
      let listData = [];
      result.data.map((item) => {
        return listData.push({ label: item.name, value: item.id });
      });
      setOption(listData);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    getOptionReq();
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Material`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div>
      <div>
        <LabelCustom labelName={"Asset Material "} mandatory={true} />
        <InputCustomFormik
          maxLength={150}
          name="name"
          type={"text"}
          placeholder={"Enter Asset Material"}
        />
        {!data && (
          <div
            style={{
              color: "var(--primary)",
              fontSize: "14px",
              marginLeft: "13px",
            }}
          >
            {" "}
            For multiple entries seperate Names with ; Eg: Name1;Name2;Name3
          </div>
        )}
      </div>
      <div>
        <LabelCustom labelName={"Asset Category"} mandatory={true} />
        <SelectForm
          values={
            data
              ? option.filter((options) => options.label === data?.Category)
              : opt
          }
          name={"assetCategoryId"}
          options={option}
          placeholder={" Select Asset Category "}
        />
      </div>
    </div>
  );
};
export { MaterialForm };
