import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailsPolicies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState([]);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`Policies/Details/${selectedId}`);
      // //console.log("id", result.data);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  }, []);
  useEffect(() => {
    document.title = `PeopleSol - Details Policies`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Policies Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            <DetailsStep
              detailTitle={"Subject"}
              Details={eventtData?._polices?.subject}
            />

            <DetailsStep
              detailTitle={" Review Date"}
              Details={format(
                new Date(eventtData?._polices?.reviewDate),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            <DetailsStep
              detailTitle={"Description"}
              Details={eventtData?._polices?.description}
            />
            <DetailsStep
              detailTitle={"Uploded File"}
              Details={
                <a
                  href={`${process.env.REACT_APP_.BASE_URL}/Core/Uploads/Policies/${eventtData?._polices?.attachment}`}
                  target="blank"
                >
                  {eventtData?._polices?.attachment}
                </a>
              }
            />
            <DetailsStep
              detailTitle={"Display in On Boarding?"}
              Details={
                eventtData?._polices?.displayInOnboarding === true
                  ? "Yes"
                  : "No"
              }
            />
            <DetailsStep
              detailTitle={"Location Groups"}
              Details={eventtData?.locationgroups?.map((item) => item?.name)}
            />
            <DetailsStep
              detailTitle={"Policy Groups"}
              Details={eventtData?._polices?.policyGroup}
            />

            <DetailsStep
              detailTitle={"Start Date"}
              Details={format(
                new Date(eventtData?._polices?.startDate),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            <DetailsStep
              detailTitle={"End Date"}
              Details={format(
                new Date(eventtData?._polices?.endDate),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            <DetailsStep
              detailTitle={"Created On"}
              Details={format(
                new Date(eventtData?._polices?.createdOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={format(
                new Date(eventtData?._polices?.lastUpdatedOn),
                "dd/MM/yyyy hh:mm a"
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailsPolicies };
