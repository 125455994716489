import { IconButton } from "@mui/material";
import React from "react";
import "./Day30Feedback.css";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "../../../../../Assets/CloseIcon.svg";
import FeedbackIcon from "../../../../../Assets/FeedbackIcon.svg";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { Day30Form } from "./Day30Form";
import { closePopup } from "../../../../../Features";
import { useDispatch } from "react-redux";

const Day30Feedback = () => {
  const { popup } = useSelector((state) => state.popup ?? false);
  const { LoggedInUser } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [day30FormVisible, setDay30FormVisible] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const [isSeven, setIsSeven] = useState(false);
  const [isThirty, setIsThirty] = useState(false);

  const dispatch = useDispatch();
  const returnPage = () => {
    // navigate('/home/dashboard')
    dispatch(closePopup());
  };

  useEffect(() => {
    document.title = `PeopleSol - Day 30 Feedback`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const location = useLocation();

  const getFeedbackStatus = () => {
    if (LoggedInUser.isFirstDayfeedbackCreated === false) {
      setIsFirst(true);
      setIsSeven(false);
      setIsThirty(false);
    } else if (LoggedInUser.isFirstSevenDayfeedbackCreated === false) {
      setIsFirst(false);
      setIsSeven(true);
      setIsThirty(false);
    } else if (LoggedInUser.isFirstMonthfeedbackCreated === false) {
      setIsFirst(false);
      setIsSeven(false);
      setIsThirty(true);
    }
  };

  const dayIdentifier = () => {
    if (isFirst) return "Day";
    else if (isSeven) return "Week";
    else if (isThirty) return "Month";
  };

  useEffect(
    () => {
      if (location.pathname.toLowerCase().includes("home")) {
        getFeedbackStatus();
      }
      const currentpath = location.pathname.toLowerCase();
      if (currentpath.includes("feedback")) {
        if (currentpath.includes("form")) {
          setDay30FormVisible(true);
        } else {
          setDay30FormVisible(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const clickHandler = () => {
    navigate("/feedback/form");
    dispatch(closePopup());
  };

  return (
    <>
      {day30FormVisible ? <Day30Form /> : null}

      <div
        className={`createPageContainer `}
        style={{ gap: "0", overflow: "hidden" }}
      >
        <div className="createProjectContainer popupContainer">
          {/* <div className="returnHolder" onClick={() => returnPage()}>
        <svg class="closeIcon cursor" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7579 0.56897L0.757935 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M0.757935 0.56897L12.7579 12.569" stroke="#F71D1D" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        </div> */}

          <div className="busyIconHolder" style={{ marginTop: "30px" }}>
            <svg
              width="280"
              height="180"
              viewBox="0 0 239 155"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M179.066 69.1446C192.735 69.1446 203.815 58.0899 203.815 44.4532C203.815 30.8165 192.735 19.7617 179.066 19.7617C165.396 19.7617 154.316 30.8165 154.316 44.4532C154.316 58.0899 165.396 69.1446 179.066 69.1446Z"
                fill="#F0F0FF"
              />
              <path
                d="M219.983 154.09C225.212 150.068 229.843 145.408 233.316 139.793C240.342 128.403 241.14 112.314 232.158 102.397C222.178 91.3658 205.013 91.6844 190.203 93.158C175.393 94.6315 158.507 95.7864 147.609 85.6709C138.707 77.4271 136.831 64.2451 135.913 52.1781C134.995 40.1112 134.236 27.0884 126.691 17.6101C116.472 4.78648 95.9934 3.23331 82.2212 12.0346C68.4491 20.8359 61.2636 37.6819 61.1039 53.9702C61.0241 60.9396 61.4632 69.3427 55.6749 73.2853C51.0842 76.4315 44.737 74.8783 39.787 72.3295C34.837 69.7807 30.1665 66.2363 24.6576 65.4796C17.3125 64.484 9.9673 68.9444 5.89553 75.1173C1.82376 81.2901 0.506425 88.8967 0.266909 96.3041C-0.3718 117.332 7.65198 138.28 21.504 154.05H219.983V154.09Z"
                fill="#F0F0FF"
              />
              <path
                d="M123.271 105.129H153.244C157.268 105.129 160.532 101.865 160.532 97.8407V36.2337C160.532 32.2097 157.268 28.9453 153.244 28.9453H129.456C125.432 28.9453 122.167 32.2097 122.167 36.2337V91.9887L115.983 97.8526C115.983 101.877 119.247 105.129 123.271 105.129Z"
                fill="#E7F3F5"
              />
              <path
                d="M157.103 91.8099H149.636C147.986 71.5947 152.912 41.2422 152.96 40.9455C152.972 40.8505 152.912 40.7556 152.817 40.7437C152.722 40.7318 152.627 40.7912 152.616 40.8861C152.568 41.1948 147.642 71.5591 149.292 91.8099H145.208C145.114 91.8099 145.03 91.893 145.03 91.9879C145.03 92.0829 145.114 92.166 145.208 92.166H157.103C157.198 92.166 157.281 92.0829 157.281 91.9879C157.281 91.893 157.209 91.8099 157.103 91.8099Z"
                fill="#0E2549"
              />
              <path
                d="M125.963 16.7758C125.963 16.7758 129.073 15.933 129.702 19.6366C129.702 19.6366 132.824 17.2862 133.513 21.3103C133.513 21.3103 137.513 18.4733 139.649 23.72C139.649 23.72 141.846 23.4944 142.7 26.4145C142.7 26.4145 147.211 26.4501 146.356 30.581C146.356 30.581 149.443 32.0411 147.365 35.982C147.365 35.982 144.481 39.8755 142.403 39.0208C142.403 39.0208 140.385 38.4273 138.308 36.2432C138.308 36.2432 136.243 38.9021 133.726 37.4539C131.21 35.9939 132.812 34.6169 132.812 34.6169C132.812 34.6169 130.497 36.2788 129.892 34.3914C129.287 32.504 130.343 31.7206 130.343 31.7206C130.343 31.7206 128.076 32.5634 126.521 32.4684C124.966 32.3616 124.111 29.4296 124.111 29.4296C124.111 29.4296 120.835 30.854 120.598 29.3346C120.372 27.8152 121.072 25.2156 121.072 25.2156C121.072 25.2156 120.609 25.6073 120.265 23.7081C119.933 21.8088 122.105 20.7642 122.105 20.7642L125.963 16.7758Z"
                fill="#0E2549"
              />
              <path
                d="M116.965 0.41896C116.965 0.41896 111.267 0.0865904 107.136 4.82285C103.006 9.55912 105.617 14.6752 107.801 17.8209C113.926 26.6643 125.298 22.4028 126.105 20.5392C127.696 16.8594 128.693 15.3281 129.429 11.8264C129.833 9.93897 129.251 -0.115205 116.965 0.41896Z"
                fill="#0E2549"
              />
              <path
                d="M122.96 16.2656C122.96 16.2656 128.242 25.7144 128.883 26.0112C138.166 30.3794 140.457 33.9168 141.406 33.6438C142.356 33.3589 126.616 39.7333 126.616 39.7333L117.772 40.5523L115.612 34.8427C115.612 34.8427 120.111 31.2697 120.467 29.6791C120.823 28.0885 115.921 21.2393 115.921 21.2393L122.96 16.2656Z"
                fill="white"
              />
              <path
                d="M117.642 40.7417L115.387 34.7828L115.493 34.6997C115.541 34.6641 119.945 31.1505 120.289 29.6429C120.574 28.3965 117.12 23.2329 115.778 21.3456L115.672 21.2031L123.031 16.0039L123.126 16.182C125.132 19.7787 128.503 25.5833 128.966 25.8563C134.818 28.6102 137.94 31.0674 139.614 32.385C140.504 33.0853 141.086 33.5483 141.359 33.4652C141.549 33.4058 141.608 33.5364 141.62 33.572C141.679 33.7382 141.537 33.7975 141.359 33.8806C141.252 33.9281 141.086 33.9993 140.884 34.0824C140.504 34.2486 139.934 34.486 139.21 34.7828C137.976 35.2932 136.267 35.9936 134.106 36.872C130.438 38.3676 126.711 39.8752 126.675 39.887L126.652 39.8989H126.628L117.642 40.7417ZM115.814 34.9015L117.879 40.3618L126.568 39.5547C130.118 38.1183 138.688 34.6403 140.848 33.7144C140.492 33.5364 140.041 33.1803 139.388 32.6699C137.727 31.3522 134.628 28.9188 128.8 26.1768C128.171 25.88 123.969 18.4373 122.901 16.5262L116.158 21.2862C116.989 22.4614 120.978 28.171 120.633 29.7141C120.289 31.2335 116.502 34.3436 115.814 34.9015Z"
                fill="#0E2549"
              />
              <path
                d="M123.886 18.0114C124.527 16.6226 125.548 13.9993 124.728 12.5511C123.909 11.091 122.39 12.2543 122.034 12.7766C121.797 13.1327 117.784 5.79688 117.784 5.79688L106.413 11.9338C106.413 11.9338 108.514 19.056 111.315 22.7358C114.116 26.4394 116.55 26.3563 119.506 24.7301C120.966 23.9229 121.891 22.9376 122.509 21.9405L123.886 18.0114Z"
                fill="white"
              />
              <path
                d="M116.099 25.9992C115.826 25.9992 115.564 25.9754 115.315 25.9279C113.891 25.6668 112.526 24.6697 111.161 22.8654C108.383 19.1975 106.258 12.0753 106.234 12.004L106.199 11.8616L117.855 5.57031L117.938 5.72463C119.256 8.12243 121.428 11.9447 121.986 12.5619C122.307 12.1821 123.019 11.6479 123.731 11.6717C124.064 11.6835 124.538 11.8378 124.895 12.467C125.749 13.9745 124.728 16.6453 124.052 18.0935C124.004 18.1885 123.909 18.2241 123.814 18.1766C123.719 18.1291 123.684 18.0342 123.731 17.9392C124.384 16.5504 125.357 14.0101 124.586 12.645C124.36 12.2533 124.075 12.0396 123.719 12.0278C123.078 11.9922 122.39 12.5857 122.188 12.8824C122.152 12.9418 122.081 12.9774 122.01 12.9774C121.856 12.9655 121.654 12.9655 119.6 9.39256C118.781 7.96812 117.986 6.51994 117.725 6.057L106.626 12.0396C106.935 13.0486 108.929 19.3162 111.457 22.6517C114.271 26.3553 116.668 26.1179 119.422 24.5985C120.681 23.8981 121.678 22.9841 122.354 21.8683C122.402 21.7852 122.52 21.7614 122.604 21.8089C122.687 21.8564 122.71 21.9751 122.663 22.0582C121.951 23.2215 120.918 24.183 119.6 24.9071C118.235 25.643 117.131 25.9992 116.099 25.9992Z"
                fill="#0E2549"
              />
              <path
                d="M106.116 12.3364C106.116 12.3364 116.882 9.14332 121.808 12.7994C121.808 12.7994 122.259 12.1346 122.734 11.8972C123.34 11.5886 123.66 11.6598 123.66 11.6598L118.045 3.88477L106.116 12.3364Z"
                fill="#0E2549"
              />
              <path
                d="M111.837 18.7356C111.695 18.7356 111.576 18.6525 111.517 18.522L111.351 18.1302C111.267 17.9522 111.351 17.7385 111.529 17.6554C111.707 17.5723 111.92 17.6554 112.003 17.8335L112.17 18.2252C112.253 18.4033 112.17 18.6169 111.992 18.7C111.944 18.7237 111.885 18.7356 111.837 18.7356Z"
                fill="#0E2549"
              />
              <path
                d="M116.87 15.9563C116.728 15.9563 116.609 15.8732 116.55 15.7427L116.384 15.3509C116.301 15.1729 116.384 14.9592 116.562 14.8761C116.74 14.793 116.953 14.8761 117.036 15.0542L117.203 15.4459C117.286 15.624 117.203 15.8376 117.025 15.9207C116.965 15.9445 116.918 15.9563 116.87 15.9563Z"
                fill="#0E2549"
              />
              <path
                d="M113.419 13.7321C113.34 13.786 113.322 13.885 113.366 13.97C113.41 14.0549 113.526 14.0763 113.611 14.0323C113.63 14.0188 115.638 12.8111 117.041 12.3792C117.132 12.345 117.184 12.252 117.159 12.1536C117.125 12.0619 117.032 12.0106 116.934 12.035C115.492 12.4938 113.507 13.6716 113.419 13.7321Z"
                fill="#0E2549"
              />
              <path
                d="M113.938 21.8561C113.891 22.1647 115.634 22.5323 116.37 22.0338C118.234 20.7992 117.757 19.4609 117.211 19.5796C116.903 19.639 117.336 20.761 116.09 21.4494C114.843 22.1379 113.986 21.5 113.938 21.8561Z"
                fill="#0E2549"
              />
              <path
                d="M113.748 19.9448C113.582 19.9448 113.452 19.9092 113.38 19.8261C113.238 19.66 113.262 19.4463 113.297 19.1139C113.345 18.6747 113.428 17.9506 113.202 16.7636C112.941 15.3748 112.383 14.8169 112.075 14.7812C111.98 14.7694 111.944 14.8169 111.92 14.8525C111.267 16.2413 109.855 16.7636 109.796 16.7873C109.701 16.8229 109.606 16.7755 109.57 16.6805C109.534 16.5855 109.582 16.4906 109.677 16.455C109.689 16.455 111.018 15.9564 111.6 14.71C111.695 14.5082 111.885 14.4014 112.122 14.4251C112.633 14.4845 113.274 15.2205 113.558 16.6924C113.796 17.9388 113.713 18.6985 113.665 19.1495C113.642 19.3632 113.618 19.5413 113.665 19.5769C113.796 19.6481 114.508 19.4582 115.197 19.1733C115.291 19.1377 115.386 19.1851 115.434 19.2682C115.47 19.3632 115.434 19.4582 115.339 19.5056C114.793 19.7312 114.164 19.9448 113.748 19.9448Z"
                fill="#0E2549"
              />
              <path
                d="M122.343 18.5574C122.26 18.5574 122.188 18.4981 122.165 18.415C120.586 11.7082 115.826 10.5806 114.401 10.3906C114.366 10.3906 114.342 10.3788 114.33 10.3788C114.235 10.355 114.176 10.2601 114.2 10.1651C114.223 10.0701 114.318 10.0108 114.413 10.0345C114.425 10.0345 114.437 10.0345 114.449 10.0345C115.933 10.2245 120.883 11.4115 122.509 18.3319C122.533 18.4269 122.473 18.5218 122.378 18.5456C122.366 18.5574 122.355 18.5574 122.343 18.5574Z"
                fill="#0E2549"
              />
              <path
                d="M131.186 17.5485C131.15 17.5485 131.127 17.5366 131.091 17.5247C129.014 16.2428 127.245 17.1686 127.162 17.2042C127.079 17.2517 126.972 17.2161 126.925 17.133C126.877 17.0499 126.913 16.9431 126.996 16.8956C127.079 16.8481 129.026 15.8273 131.281 17.228C131.364 17.2755 131.388 17.3942 131.34 17.4773C131.305 17.5129 131.245 17.5485 131.186 17.5485Z"
                fill="#0E2549"
              />
              <path
                d="M143.151 22.9242C143.116 22.9242 143.092 22.9124 143.056 22.9005C140.172 21.2149 134.035 22.2358 133.976 22.2476C133.881 22.2595 133.786 22.2001 133.774 22.1052C133.762 22.0102 133.821 21.9153 133.916 21.9034C134.177 21.8559 140.255 20.8469 143.246 22.6037C143.329 22.6512 143.365 22.7581 143.306 22.853C143.27 22.8886 143.211 22.9242 143.151 22.9242Z"
                fill="#0E2549"
              />
              <path
                d="M135.685 21.3462C135.626 21.3462 135.578 21.3224 135.542 21.2749C135.483 21.1919 135.495 21.085 135.578 21.0257C135.673 20.9544 138 19.2214 139.602 19.6012C139.697 19.625 139.756 19.7199 139.733 19.8149C139.709 19.9099 139.614 19.9692 139.519 19.9455C138.083 19.6012 135.815 21.2868 135.792 21.3106C135.756 21.3343 135.72 21.3462 135.685 21.3462Z"
                fill="#0E2549"
              />
              <path
                d="M148.078 37.6689C147.971 37.6689 147.9 37.5858 147.9 37.4908C147.923 35.8646 146.867 34.725 146.855 34.7131C146.784 34.6419 146.796 34.5232 146.867 34.4639C146.938 34.3926 147.057 34.4045 147.116 34.4757C147.164 34.5232 148.291 35.734 148.256 37.5027C148.256 37.5858 148.184 37.6689 148.078 37.6689Z"
                fill="#0E2549"
              />
              <path
                d="M149.217 34.7597C149.134 34.7597 149.063 34.7003 149.039 34.6172C148.742 33.2403 146.582 31.9345 146.558 31.9227C146.475 31.8752 146.451 31.7684 146.499 31.6734C146.546 31.5903 146.653 31.5666 146.748 31.614C146.843 31.6734 149.075 33.0147 149.395 34.5341C149.419 34.6291 149.36 34.7241 149.253 34.7478C149.241 34.7597 149.229 34.7597 149.217 34.7597Z"
                fill="#0E2549"
              />
              <path
                d="M105.285 17.3707C105.213 17.3707 105.142 17.3233 105.118 17.252C103.813 13.1924 105.071 10.2604 105.13 10.1417C105.166 10.0467 105.273 10.0111 105.368 10.0467C105.463 10.0824 105.498 10.1892 105.463 10.2842C105.451 10.3079 104.204 13.228 105.474 17.1452C105.51 17.2402 105.451 17.3351 105.356 17.3707C105.32 17.3707 105.296 17.3707 105.285 17.3707Z"
                fill="#0E2549"
              />
              <path
                d="M103.374 11.9812C103.279 11.9812 103.196 11.91 103.196 11.8151C103.148 10.8654 105.309 8.70504 105.558 8.45576C105.629 8.38454 105.736 8.38454 105.807 8.45576C105.878 8.52698 105.878 8.63382 105.807 8.70504C104.917 9.58344 103.516 11.1978 103.552 11.7913C103.552 11.8863 103.481 11.9694 103.374 11.9812Z"
                fill="#0E2549"
              />
              <path
                d="M125.393 1.53567C125.369 1.53567 125.346 1.53567 125.322 1.5238C123.577 0.799707 118.413 1.03711 118.354 1.03711C118.259 1.03711 118.176 0.965891 118.164 0.870928C118.164 0.775966 118.235 0.692873 118.33 0.681003C118.544 0.669133 123.624 0.431726 125.452 1.19143C125.547 1.22704 125.583 1.33387 125.547 1.42883C125.535 1.50006 125.464 1.53567 125.393 1.53567Z"
                fill="#0E2549"
              />
              <path
                d="M129.109 5.20316C129.061 5.20316 129.002 5.17942 128.966 5.13194C126.972 2.66291 123.672 2.09313 123.637 2.08126C123.542 2.06939 123.47 1.97443 123.494 1.87947C123.506 1.7845 123.601 1.71328 123.696 1.73702C123.838 1.76076 127.174 2.33054 129.251 4.9064C129.311 4.97762 129.299 5.09633 129.227 5.15568C129.192 5.19129 129.144 5.20316 129.109 5.20316Z"
                fill="#0E2549"
              />
              <path
                d="M153.244 105.308H123.271C119.152 105.308 115.805 101.96 115.805 97.8411V97.7698L121.989 91.9059V36.234C121.989 32.115 125.336 28.7676 129.455 28.7676H153.244C157.363 28.7676 160.71 32.115 160.71 36.234V97.8411C160.71 101.96 157.363 105.308 153.244 105.308ZM116.161 97.9241C116.196 101.806 119.377 104.951 123.271 104.951H153.244C157.161 104.951 160.354 101.758 160.354 97.8411V36.234C160.354 32.3168 157.161 29.1237 153.244 29.1237H129.455C125.538 29.1237 122.345 32.3168 122.345 36.234V92.0602L116.161 97.9241Z"
                fill="#0E2549"
              />
              <path
                d="M98.1303 135.338L99.5785 149.416L107.092 148.752L106.392 135.006L98.1303 135.338Z"
                fill="white"
              />
              <path
                d="M99.4116 149.607L97.9278 135.161L106.546 134.805L107.27 148.907L99.4116 149.607ZM98.3195 135.505L99.7321 149.215L106.902 148.574L106.213 135.173L98.3195 135.505Z"
                fill="#0E2549"
              />
              <path
                d="M108.731 136.537L108.933 149.286L115.901 148.669L116.898 135.766L108.731 136.537Z"
                fill="white"
              />
              <path
                d="M108.754 149.488L108.553 136.383L117.087 135.576L116.066 148.847L108.754 149.488ZM108.92 136.704L109.11 149.096L115.734 148.503L116.707 135.956L108.92 136.704Z"
                fill="#0E2549"
              />
              <path
                d="M126.345 86.1949L103.329 105.128L107.092 138.139H117.763C117.763 138.139 118.155 132.928 119.378 108.036C119.378 108.036 145.279 96.8069 146.952 90.3139C149.374 80.9244 141.931 71.6418 141.931 71.6418L141.468 67.3448L119.259 66.3477L121.585 73.2443C121.585 73.2443 94.3667 93.0678 91.9096 100.962C91.0193 103.822 97.0732 137.938 97.0732 137.938L107.531 137.736C107.531 137.736 104.718 108.737 105.133 107.846C108.362 100.867 129.254 90.6937 129.254 90.6937L126.345 86.1949Z"
                fill="#0E2549"
              />
              <path
                d="M132.246 27.2474C134.738 25.9417 151.298 43.8659 155.001 51.2492C162.574 66.3601 141.659 62.8584 141.659 62.8584L144.496 44.8511L143.071 71.1202L120.862 72.5921L115.639 48.4953C115.639 48.4953 115.034 62.9177 106.997 62.9177C102.178 62.9177 95.3646 62.9177 95.3646 62.9177L94.6642 56.3416L106.036 55.024C106.036 55.024 107.959 39.9487 112.814 34.9156C115.105 32.5416 116.992 31.1409 117.978 30.9035C120.957 30.1912 124.328 31.3783 132.246 27.2474Z"
                fill="#E2474B"
              />
              <path
                d="M99.6493 146.77C99.6493 146.77 89.0372 148.443 88.7642 153.013C88.7286 153.619 88.8829 153.726 88.8829 153.726H107.151L107.507 148.218C107.507 148.218 100.635 149.761 99.6493 146.77Z"
                fill="#E2474B"
              />
              <path
                d="M108.564 146.438C108.564 146.438 98.6405 148.076 97.7621 152.563C97.6078 153.334 97.6909 153.643 97.6909 153.643H115.959L116.232 148.277C116.232 148.277 109.549 149.417 108.564 146.438Z"
                fill="#E2474B"
              />
              <path
                d="M210.649 153.903H23.5369C23.442 153.903 23.3589 153.82 23.3589 153.725C23.3589 153.63 23.442 153.547 23.5369 153.547H210.649C210.744 153.547 210.827 153.63 210.827 153.725C210.827 153.82 210.744 153.903 210.649 153.903Z"
                fill="#0E2549"
              />
              <path
                d="M128.435 72.2606C128.364 72.2606 128.292 72.2131 128.269 72.13C125.717 63.8682 123.77 30.5957 123.758 30.2633C123.758 30.1684 123.829 30.0853 123.924 30.0734C124.019 30.0615 124.102 30.1446 124.114 30.2396C124.138 30.572 126.073 63.797 128.613 72.0232C128.637 72.1181 128.589 72.2131 128.494 72.2487C128.47 72.2606 128.447 72.2606 128.435 72.2606Z"
                fill="#0E2549"
              />
              <path
                d="M116.268 152.562H97.2871V153.619H116.268V152.562Z"
                fill="#0E2549"
              />
              <path
                d="M107.507 152.562H88.5266V153.619H107.507V152.562Z"
                fill="#0E2549"
              />
              <path
                d="M107.092 135.944C106.997 135.944 106.914 135.873 106.914 135.778L104.944 101.686L105.003 101.627L127.474 78.6101C127.545 78.5388 127.652 78.5388 127.723 78.6101C127.794 78.6813 127.794 78.7881 127.723 78.8593L105.312 101.828L107.27 135.766C107.27 135.849 107.199 135.944 107.092 135.944Z"
                fill="#FFEEF2"
              />
              <path
                d="M114.725 59.9259L108.897 59.3086L106.997 59.8071L106.724 60.5075C106.724 60.5075 109.834 60.3294 111.674 60.8873C113.514 61.4453 114.915 61.2672 114.915 61.2672L115.01 60.8399L114.725 59.9259Z"
                fill="white"
              />
              <path
                d="M112.012 60.7196C114.311 60.5192 124.16 61.8585 124.16 61.8585L123.327 56.5332C123.327 56.5332 112.582 58.3575 111.77 58.1255C110.241 57.6826 108.216 57.419 106.413 57.3452C104.03 57.2503 101.805 57.8197 101.836 58.4735C101.857 58.8215 105.074 58.5473 105.896 58.8742C108.227 59.8127 110.378 60.8567 112.012 60.7196Z"
                fill="white"
              />
              <path
                d="M124.35 62.0369L124.139 62.0053C124.045 61.9947 114.28 60.6766 112.023 60.877C110.61 61.0035 108.828 60.2653 106.94 59.4745C106.582 59.3268 106.213 59.1687 105.833 59.021C105.464 58.8734 104.483 58.8523 103.629 58.8418C102.195 58.8207 101.699 58.789 101.678 58.4832C101.668 58.3356 101.731 58.2091 101.858 58.072C102.427 57.492 104.431 57.1124 106.424 57.1862C108.364 57.26 110.378 57.5553 111.812 57.9665C112.382 58.1247 118.993 57.1018 123.296 56.3637L123.454 56.332L124.35 62.0369ZM112.593 60.5395C115.376 60.5395 122.61 61.4886 123.971 61.6678L123.201 56.7117C121.545 56.9964 112.519 58.5043 111.728 58.2723C110.315 57.8611 108.322 57.5764 106.413 57.5025C104.304 57.4182 102.522 57.8505 102.09 58.2934C102.047 58.3356 102.016 58.3778 102.005 58.42C102.216 58.5043 103.028 58.5149 103.64 58.5254C104.568 58.536 105.527 58.557 105.96 58.7258C106.329 58.8734 106.708 59.0316 107.067 59.1792C108.912 59.949 110.663 60.6661 112.002 60.5501C112.16 60.5395 112.371 60.5395 112.593 60.5395Z"
                fill="#0E2549"
              />
              <path
                d="M110.642 59.326L105.274 58.5352L105.517 58.6195C105.517 58.6195 100.423 59.4631 107.921 60.0853C109.64 60.2224 111.032 60.0642 110.947 60.2435L110.895 60.1486L110.642 59.326Z"
                fill="white"
              />
              <path
                d="M109.914 60.5809C109.903 60.5809 109.903 60.5809 109.893 60.5809C109.26 60.486 108.691 60.3594 108.184 60.2434C107.604 60.1063 107.109 59.9903 106.698 59.9903C104.694 59.9692 104.03 59.8638 103.84 59.5475C103.787 59.4631 103.756 59.326 103.861 59.1256C104.072 58.746 105.411 58.3242 106.476 58.3242C106.487 58.3242 106.508 58.3242 106.518 58.3242C107.794 58.3242 108.902 58.746 108.944 58.7566C109.028 58.7882 109.07 58.8831 109.039 58.9569C109.007 59.0413 108.912 59.0835 108.838 59.0518C108.828 59.0518 107.71 58.63 106.487 58.6406C105.411 58.6511 104.262 59.0729 104.146 59.2838C104.125 59.3155 104.104 59.3682 104.125 59.3893C104.272 59.6529 105.981 59.674 106.719 59.6845C107.162 59.6951 107.678 59.8111 108.269 59.9482C108.764 60.0642 109.323 60.1907 109.945 60.2856C110.03 60.2962 110.093 60.3805 110.083 60.4649C110.061 60.5281 109.998 60.5809 109.914 60.5809Z"
                fill="#0E2549"
              />
              <path
                d="M144.816 52.3066L124.174 55.9983L126.583 63.1086C126.583 63.1086 140.875 63.5241 143.938 63.1086C149.635 62.3371 155.725 57.3159 155.725 57.3159L144.816 52.3066Z"
                fill="#E2474B"
              />
              <path
                d="M198.815 68.2115H30.5886C29.8052 68.2115 29.1642 67.5705 29.1642 66.7871V64.5436C29.1642 63.7601 29.8052 63.1191 30.5886 63.1191H198.815C199.598 63.1191 200.239 63.7601 200.239 64.5436V66.7871C200.239 67.5705 199.598 68.2115 198.815 68.2115Z"
                fill="#F0F0FF"
              />
              <path
                d="M198.815 68.3899H30.5883C29.7099 68.3899 28.9858 67.6658 28.9858 66.7874V64.5439C28.9858 63.6655 29.7099 62.9414 30.5883 62.9414H198.815C199.693 62.9414 200.417 63.6655 200.417 64.5439V66.7874C200.417 67.6658 199.693 68.3899 198.815 68.3899ZM30.5883 63.2856C29.8999 63.2856 29.3419 63.8436 29.3419 64.532V66.7755C29.3419 67.464 29.8999 68.0219 30.5883 68.0219H198.815C199.503 68.0219 200.061 67.464 200.061 66.7755V64.532C200.061 63.8436 199.503 63.2856 198.815 63.2856H30.5883Z"
                fill="#0E2549"
              />
              <path
                d="M43.0397 153.656H37.9473L51.1115 68.2129H56.2039L43.0397 153.656Z"
                fill="#F0F0FF"
              />
              <path
                d="M43.1943 153.832H37.7339L37.7695 153.63L50.9575 68.0332H56.4178L56.3822 68.235L43.1943 153.832ZM38.1494 153.476H42.8856L55.9905 68.3893H51.2542L38.1494 153.476Z"
                fill="#0E2549"
              />
              <path
                d="M184.654 153.656H189.746L176.582 68.2129H171.489L184.654 153.656Z"
                fill="#F0F0FF"
              />
              <path
                d="M189.959 153.832H184.499L184.475 153.678L171.275 68.0332H176.735L176.759 68.1875L189.959 153.832ZM184.795 153.476H189.532L176.427 68.3893H171.691L184.795 153.476Z"
                fill="#0E2549"
              />
              <path
                d="M59.9199 38.666L65.4396 60.9941H97.6438L91.127 38.666H59.9199Z"
                fill="#D8F0F2"
              />
              <path
                d="M97.8814 61.1725H65.2973L65.2617 61.0419L59.6945 38.4883H91.2578L91.2934 38.6189L97.8814 61.1725ZM65.5822 60.8164H97.4185L90.9966 38.8444H60.1456L65.5822 60.8164Z"
                fill="#0E2549"
              />
              <path
                d="M107.306 60.9941H65.4627V63.1071H107.306V60.9941Z"
                fill="#D8F0F2"
              />
              <path
                d="M107.483 63.2854H65.2844V60.8164H107.483V63.2854ZM65.6405 62.9293H107.127V61.1725H65.6405V62.9293Z"
                fill="#0E2549"
              />
              <path
                d="M78.9712 54.015C78.3065 54.015 77.6655 53.7301 77.1432 53.196C76.5853 52.6143 76.2292 51.819 76.1579 50.9406C76.0036 49.1126 77.0957 47.522 78.6032 47.3914C80.1108 47.2608 81.464 48.6378 81.6183 50.4658C81.7726 52.2938 80.6805 53.8845 79.173 54.015C79.1018 54.015 79.0424 54.015 78.9712 54.015ZM78.805 47.7356C78.7457 47.7356 78.6982 47.7356 78.6388 47.7475C77.3331 47.8543 76.3835 49.2788 76.514 50.905C76.5853 51.7003 76.8939 52.4244 77.4043 52.9467C77.9029 53.4571 78.5201 53.7064 79.1493 53.6589C80.455 53.5521 81.4046 52.1276 81.274 50.5014C81.1316 48.9345 80.0395 47.7356 78.805 47.7356Z"
                fill="#0E2549"
              />
              <path
                d="M68.158 58.7046C68.0749 58.7046 68.0037 58.6453 67.9799 58.5741L63.7659 41.3146C63.7422 41.2196 63.8016 41.1247 63.8965 41.1009C63.9915 41.0772 64.0864 41.1365 64.1102 41.2315L68.3242 58.491C68.3479 58.5859 68.2885 58.6809 68.1936 58.7046C68.1817 58.7046 68.1698 58.7046 68.158 58.7046Z"
                fill="white"
              />
              <path
                d="M126.441 62.9185C126.369 62.9185 126.298 62.871 126.274 62.7998L123.924 55.8675L142.762 52.8762L137.717 44.377C137.67 44.2939 137.694 44.1871 137.777 44.1277C137.86 44.0802 137.967 44.104 138.026 44.1871L143.344 53.1373L124.399 56.1405L126.607 62.681C126.642 62.776 126.583 62.871 126.5 62.9066C126.488 62.9066 126.464 62.9185 126.441 62.9185Z"
                fill="#0E2549"
              />
              <path
                d="M139.831 105.129H131.89C131.89 107.016 133.207 108.595 134.976 108.999V147.672H136.756V108.999C138.513 108.595 139.831 107.016 139.831 105.129Z"
                fill="#0E2549"
              />
              <path
                d="M144.52 153.655H127.189V151.209C127.189 149.263 128.768 147.672 130.726 147.672H140.982C142.929 147.672 144.52 149.251 144.52 151.209V153.655Z"
                fill="#0E2549"
              />
              <path
                d="M191.443 46.7734H164.758V62.929H191.443V46.7734Z"
                fill="#FFEEF2"
              />
              <path
                d="M196.465 30.6191H169.78V46.7747H196.465V30.6191Z"
                fill="#FFEEF2"
              />
              <path
                d="M196.642 30.4414H169.602V46.5969H164.58V63.1086H191.621V46.953H196.642V30.4414ZM191.277 62.7525H164.937V60.6751H191.265V62.7525H191.277ZM191.277 60.319H164.937V58.5147H191.265V60.319H191.277ZM191.277 58.1586H164.937V56.3543H191.265V58.1586H191.277ZM191.277 55.9864H164.937V54.1821H191.265V55.9864H191.277ZM191.277 53.826H164.937V51.8199H191.265V53.826H191.277ZM191.277 51.4638H164.937V49.4221H191.265V51.4638H191.277ZM191.277 49.0541H164.937V46.953H169.602H191.277V49.0541ZM196.286 46.5969H191.621H169.946V44.5196H196.274V46.5969H196.286ZM196.286 44.1635H169.958V42.3592H196.286V44.1635ZM196.286 42.0031H169.958V40.1988H196.286V42.0031ZM196.286 39.8308H169.958V38.0265H196.286V39.8308ZM196.286 37.6704H169.958V35.6644H196.286V37.6704ZM196.286 35.3082H169.958V33.2665H196.286V35.3082ZM196.286 32.8986H169.958V30.7975H196.286V32.8986Z"
                fill="#0E2549"
              />
              <path
                d="M31.7505 63.1133H58.435V46.9578H31.7505L31.7505 63.1133Z"
                fill="#FFEEF2"
              />
              <path
                d="M31.5737 46.7734V63.2851H58.6144V46.7734H31.5737ZM58.2582 47.1295V49.2306H31.9298V47.1295H58.2582ZM58.2582 51.9964V54.0025H31.9298V51.9964H58.2582ZM31.9298 54.3586H58.2582V56.1629H31.9298V54.3586ZM58.2582 60.4599H31.9298V58.6913H58.2582V60.4599ZM31.9298 58.3351V56.5309H58.2582V58.3351H31.9298ZM58.2582 51.6403H31.9298V49.5986H58.2582V51.6403ZM31.9298 62.929V60.8042H58.2582V62.929H31.9298Z"
                fill="#0E2549"
              />
              <path
                d="M134.821 27.8172L130.69 25.7637L123.924 30.2625L126.868 32.601L134.821 27.8172Z"
                fill="#E2474B"
              />
              <path
                d="M115.805 32.0185L117.751 28.8848L123.924 30.2617L122.321 33.3005L115.805 32.0185Z"
                fill="#E2474B"
              />
              <path
                d="M135.201 27.806L130.691 25.5625L123.901 30.0732L117.681 28.6725L115.532 32.1387L122.441 33.4919L124.008 30.5243L126.88 32.8034L135.201 27.806ZM122.227 33.1002L116.09 31.9013L117.835 29.088L123.663 30.3937L122.227 33.1002ZM130.703 25.9661L134.454 27.8297L126.88 32.3879L124.233 30.275L130.703 25.9661Z"
                fill="#0E2549"
              />
              <path
                d="M41.4253 114.577C41.4253 114.577 31.0863 112.773 30.1841 114.577C30.1841 114.577 36.5229 128.869 37.9236 130.305C37.9236 130.305 45.1526 126.21 45.6512 126.115C46.1616 126.008 41.4253 114.577 41.4253 114.577Z"
                fill="#FFEEF2"
              />
              <path
                d="M37.9008 130.52L37.8058 130.425C36.3933 128.988 30.2919 115.231 30.0308 114.649L29.9951 114.578L30.0308 114.507C30.9685 112.631 40.3817 114.222 41.4619 114.412L41.5569 114.424L41.5925 114.518C41.6043 114.542 42.7439 117.296 43.8241 120.074C46.0557 125.772 45.9133 126.033 45.8302 126.175C45.7946 126.234 45.7471 126.27 45.6877 126.282C45.3197 126.365 40.904 128.822 38.0195 130.448L37.9008 130.52ZM30.3987 114.59C30.9092 115.741 36.512 128.288 37.9839 130.08C39.0878 129.451 44.5957 126.353 45.5334 125.973C45.4859 125.107 42.9694 118.732 41.3194 114.744C37.2835 114.032 31.2059 113.45 30.3987 114.59Z"
                fill="#0E2549"
              />
              <path
                d="M43.3721 108.215L51.2422 116.797C51.2422 116.797 52.0849 129.688 48.2746 133.107C48.2746 133.107 39.265 126.08 37.3539 126.281C37.342 126.27 44.3811 114.506 43.3721 108.215Z"
                fill="#FFEEF2"
              />
              <path
                d="M48.2745 133.332L48.1558 133.237C45.663 131.29 38.8732 126.293 37.3538 126.447L36.9977 126.483L37.1757 126.174C37.247 126.055 44.1674 114.387 43.1821 108.238L43.0872 107.656L51.3964 116.713V116.773C51.432 117.307 52.2036 129.794 48.3695 133.225L48.2745 133.332ZM37.6505 126.115C39.9653 126.494 47.1943 132.038 48.2626 132.869C51.7169 129.533 51.1234 117.829 51.064 116.868L43.6213 108.748C44.1317 114.672 38.6595 124.382 37.6505 126.115Z"
                fill="#0E2549"
              />
              <path
                d="M97.7619 152.74C97.667 152.74 97.5839 152.657 97.5839 152.562C97.5839 152.396 97.6788 148.526 107.792 146.402C107.887 146.378 107.982 146.437 108.006 146.544C108.03 146.639 107.97 146.734 107.864 146.758C98.0706 148.823 97.9281 152.539 97.9281 152.574C97.94 152.669 97.8569 152.74 97.7619 152.74Z"
                fill="#0E2549"
              />
              <path
                d="M33.5256 153.543L29.2778 120.245H56.4717L52.4009 153.625L33.5256 153.543Z"
                fill="#6AB1BF"
                stroke="black"
                stroke-width="0.2"
              />
            </svg>
          </div>
          <div className="busyTitleHolder">
            <label className="busyLabel">You Seem Busy</label>
          </div>
          <div className="busyPermission">
            <label className="commentText">
              {" "}
              We are waiting to know your&nbsp;
              <label style={{ color: "var(--primary)", fontWeight: "600" }}>
                1<sup style={{ fontSize: "10px" }}>st</sup> {dayIdentifier()}
              </label>
            </label>
            <label className="commentText">experience with us </label>
          </div>
          <div className="submitPermission">
            <label className="commentText">Kindly submit the form</label>
          </div>
          <div className="submitFeedbackButton">
            <div className="feedbackButton cursor" onClick={clickHandler}>
              <label style={{ color: "white" }}>Click here</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Day30Feedback };
