import React, { useCallback, useEffect, useState } from "react";
import "./Community.css";
import SocialCommunityPlusIcon from "../../../../../Assets/SocialCommunityPlusIcon.svg";
import SocialCommunityGroupIcon from "../../../../../Assets/SocialCommunityGroupIcon.svg";
import SocialNoCommunityFoundIcon from "../../../../../Assets/SocialNoCommunityFoundIcon.svg";
import { postRequest } from "../../../../../Services/axios";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../../Features";
import { useNavigate } from "react-router";
import CreateCommunity from "./CreateCommunity";
import { communityProfileImages } from "../../../../../Utilities/profileAndUploadImages";
import { Tooltip } from "@mui/material";
import { setCommunityData } from "../../../../../Features/SocialSlice";
import { Loader } from "../../../../../Components/FormComponent/Loader";
import { getUserInitials } from "../../../../../Utilities/socialAllFunctions";
function Community() {
  // const [allCommunityList, setAllCommunityList] = useState([]);
  const [isCreateCommunityActive, setIsCreateCommunityActive] = useState(false);
  const { communityData } = useSelector((state) => state.social);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postDataCount, setPostDataCount] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    per_page: 2,
  });
  const [scrollEventFired, setScrollEventFired] = useState(0);
  const [totaloffset, setTotalOffset] = useState();
  const [offset, setOffset] = useState();

  const scrollEventHandler = useCallback(() => {
    let innerHeight = document.querySelector(
      ".communityBodyContainer"
    ).clientHeight;
    let scrollTop = document.querySelector(".communityBodyContainer").scrollTop;
    let scrollHeight = document.querySelector(
      ".communityBodyContainer"
    ).scrollHeight;
    if (
      innerHeight + scrollTop < scrollHeight - 10 ||
      loading ||
      (postDataCount !== 0 && communityData?.length >= postDataCount)
    ) {
      return;
    }
    setOffset((offset) => offset + 10);
  }, [loading, communityData, postDataCount]);

  useEffect(() => {
    const handleScrollEvent = () => {
      setScrollEventFired(new Date().getTime());
    };
    document
      .querySelector(".communityBodyContainer")
      .addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  useEffect(() => {
    const debounce = setTimeout(scrollEventHandler, 1000);
    return () => clearTimeout(debounce);
  }, [scrollEventFired, scrollEventHandler]);

  useEffect(() => {
    if (
      ((typeof offset === "number" &&
        typeof totaloffset === "undefined" &&
        offset === 0) ||
        (typeof offset === "number" &&
          typeof totaloffset === "number" &&
          offset === totaloffset + 10)) &&
      !loading &&
      (postDataCount === -1 || communityData?.length < postDataCount)
    ) {
      getCommunityData();
    } else if (offset > totaloffset + 10) {
      setOffset(totaloffset + 10);
    }
  }, [offset, totaloffset, loading]);

  useEffect(() => {
    setOffset(0);
    return () => dispatch(setCommunityData([]));
  }, []);

  const getCommunityData = async () => {
    setLoading(true);
    try {
      const result = await postRequest("Community/AllList", {
        createdOn: null,
        lastUpdatedOn: null,
        dataTableRequest: {
          iDisplayLength:
            postDataCount === -1 || offset + 10 <= postDataCount
              ? 10
              : postDataCount - offset,
          iDisplayStart: offset,
          sSearch: null,
          sorting: "Id",
          sSortDir_0: "descending",
          isExport: false,
        },
      });
      dispatch(setCommunityData([...communityData, ...result?.data?.data]));
      setPostDataCount(result?.data?.summary?.allCount);
      setTotalOffset(offset);
      setCurrentIndex((currentIndex) => currentIndex + 10);
      setPaginationData({
        ...paginationData,
        total: result?.data?.summary.allCount,
        per_page: paginationData.per_page,
      });
    } catch (error) {
      dispatch(
        showToast({
          text: "some error occured in fetching data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div
        className="homeEngageMainAsideContainer"
        style={{ marginBottom: "0" }}
      >
        <div className="homeEngageMainAsideContainerHolder">
          <div className="engageMainAsideGroupsContainer">
            <div className="communityHeaderContainer">
              <Tooltip arrow title="My Community" placement="top">
                <span
                  onClick={() => {
                    navigate("/home/social/community");
                  }}
                >
                  Community
                </span>
              </Tooltip>

              <Tooltip arrow title="Create Community" placement="top">
                <div
                  className="communityHeaderImgContainer"
                  onClick={() => {
                    setIsCreateCommunityActive(true);
                  }}
                >
                  <img src={SocialCommunityPlusIcon} alt="" />
                </div>
              </Tooltip>
            </div>
            <div
              className="communityBodyContainer"
              style={{ display: communityData?.length > 0 ? "block" : "none" }}
            >
              {communityData?.length > 0 &&
                communityData.map((d, i) => {
                  return (
                    <div className="communityBodyWrapper">
                      <div className="communityListContainer">
                        <div
                          className="communityListWrapper"
                          onClick={() => {
                            navigate(`/home/social/community/${d?.item?.id}`);
                          }}
                        >
                          <div className="communityListImgContainer">
                            <div
                              onClick={(e) => {
                                // handleUserProfileClick(LoggedInUser?.id);
                              }}
                              className={`${
                                d?.item?.communityImage
                                  ? "socialCommunityProfilePicContainer"
                                  : "socialCommunityCustomProfilePic"
                              }`}
                            >
                              {!d?.item?.communityImage &&
                              d?.item?.communityName ? (
                                getUserInitials(d?.item?.communityName)
                              ) : (
                                <img
                                  className="socialProfilePic"
                                  src={
                                    process.env.REACT_APP_.BASE_URL +
                                    communityProfileImages +
                                    d?.item?.communityImage
                                  }
                                  alt="Profile"
                                  onError={(e) => {
                                    e.target.src = SocialCommunityGroupIcon;
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="communityListTextContainer">
                            <h5>{d?.item?.communityName}</h5>
                            <p>{d?.item?.communityType}</p>
                            {/* <p>{d?.item?.about?.substring(0, 50)}</p> */}
                          </div>
                        </div>
                        <div className="CommunityListDateTimeWrapper">
                          {/* <span>{d.communityDateTime}</span> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* {loading && <Loader />} */}
            </div>
            {communityData?.length <= 0 && !loading && (
              <div className="noCommunityFoundContainer">
                <img src={SocialNoCommunityFoundIcon} alt="" />
                <div className="noCommunityFoundWrapper">
                  <h5>No Community Found</h5>
                  <span>No one has created community.</span>
                </div>
              </div>
            )}
            {loading && communityData?.length <= 0 && <Loader />}
            {loading && communityData?.length > 0 && <Loader />}
          </div>
        </div>
      </div>
      {isCreateCommunityActive && (
        <CreateCommunity
          getCommunityData={getCommunityData}
          setIsCreateCommunityActive={setIsCreateCommunityActive}
        />
      )}
    </div>
  );
}

export default Community;
