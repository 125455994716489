import React from "react";
import { DatePickerFormiks } from "../../../../../Components/FormComponent/DatePickerFormiks";
import { FileUploadFormik } from "../../../../../Components/FormComponent/FileUploadFormik";
import { InputCustomFormik } from "../../../../../Components/FormComponent/InputCustomFormik";
import { LabelCustom } from "../../../../../Components/FormComponent/LabelCustom";
import { SelectForm } from "../../../../../Components/FormComponent/SelectForm";
import { TextAreaFormik } from "../../../../../Components/FormComponent/TextAreaFormik";
import { useEffect } from "react";
import { getRequest } from "../../../../../Services/axios";
import { useState } from "react";

function PositionDetails({
  locationList,
  managmentList,
  designationList,
  employeeList,
}) {
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const SpecializationOptions = async () => {
    try {
      let listData = [];
      const result = await getRequest("ConfigValues/GetMpi");
      const specializationvar = result.data?.filter(
        (item) => item?.key === "MPI_Specialization"
      );
      const specializationvarArr =
        specializationvar[0]?.value?.split("\t|\r\n");
      specializationvarArr?.map((item) => {
        listData?.push({ label: item, value: item });
      });
      setSpecializationOptions(listData);
    } catch (error) {
    } finally {
    }
  };
  const EducationOptions = async () => {
    try {
      let listData = [];
      const result = await getRequest("ConfigValues/GetMpi");
      const specializationvar = result.data?.filter(
        (item) => item?.key === "MPI_EducationLevel"
      );
      const specializationvarArr = specializationvar[0]?.value?.split("|");
      specializationvarArr?.map((item) => {
        listData?.push({ label: item, value: item });
      });
      setEducationOptions(listData);
    } catch (error) {
    } finally {
    }
  };
  const ExperienceOptions = async () => {
    try {
      let listData = [];
      const result = await getRequest("ConfigValues/GetMpi");
      const specializationvar = result.data?.filter(
        (item) => item?.key === "MPI_RelevantExperience"
      );
      const specializationvarArr = specializationvar[0]?.value?.split("|");
      specializationvarArr?.map((item) => {
        listData?.push({ label: item, value: item });
      });
      setExperienceOptions(listData);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    document.title = `PeopleSol - Position Details`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  useEffect(() => {
    SpecializationOptions();
    EducationOptions();
    ExperienceOptions();
  }, []);
  return (
    <>
      <div className="headingForm">Position Details</div>
      <div className="formcustom threeColumn">
        <div>
          <LabelCustom labelName={"Position Role"} mandatory={true} />
          <SelectForm
            placeholder={"Select Position Role"}
            name="VacanctDesigination"
            options={designationList}
          />
        </div>
        <div>
          <LabelCustom labelName={"Position Level"} mandatory={true} />
          <SelectForm
            placeholder={"Select Position Level"}
            name="ManagementLevel"
            options={managmentList}
          />
        </div>
        <div>
          <LabelCustom labelName={"Position Location"} mandatory={true} />
          <SelectForm
            placeholder={"Select Position Location"}
            name="VacanctLocation"
            options={locationList}
          />
        </div>
        <div>
          <LabelCustom labelName={"Will Report to"} mandatory={true} />
          <SelectForm
            placeholder={"Select Reporting Manager"}
            name="ReportingManager"
            options={employeeList}
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Expected Date of Joining"}
            mandatory={true}
          />
          <DatePickerFormiks
            placeholder={"Select Expected Date of Joining"}
            name="ExpectedDoj"
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Proposed Annual CTC ( in Lakhs )"}
            mandatory={true}
          />
          <InputCustomFormik
            maxLength={10}
            placeholder={"Enter Proposed Annual CTC (e.g. 8)"}
            name="ProposedCtc"
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Number of Open Positions"}
            mandatory={true}
          />
          <InputCustomFormik
            maxLength={4}
            name="NumberOfPositions"
            placeholder={"Enter Number of Open Positions"}
            type="number"
            step="0.01"
          />
        </div>
        <div>
          <LabelCustom labelName={"Minimun Work Experience"} mandatory={true} />
          <SelectForm
            placeholder={"Select Minimun Work Experience"}
            name="ReleventExpirience"
            options={experienceOptions}
          />
        </div>

        <div>
          <LabelCustom labelName={"Minimum Education Level"} mandatory={true} />
          <SelectForm
            placeholder={"Select Minimum Education Level"}
            name="EducationLevel"
            options={educationOptions}
          />
        </div>
        <div>
          <LabelCustom
            labelName={"Required Education Specialization"}
            mandatory={true}
          />
          <SelectForm
            placeholder={"Select Required Education Specialization"}
            name="Specialization"
            options={specializationOptions}
          />
        </div>

        <div>
          <LabelCustom
            labelName={"Required Functional Skills"}
            mandatory={true}
          />
          <TextAreaFormik
            maxLength={300}
            placeholder={"Write Functional Skills Required"}
            name="SkilsRequired"
          />
        </div>
        <div>
          <LabelCustom labelName={"Required Technical Skills"} />
          <TextAreaFormik
            maxLength={150}
            placeholder={"Write Technical Education Required"}
            name="TechnicalEducation"
          />
        </div>
        <div>
          <LabelCustom labelName={"Preferred Industry"} />
          <InputCustomFormik
            maxLength={150}
            placeholder={"Write Preferred Industry"}
            name="PrefferdIndustry"
          />
        </div>
      </div>
      <div className="headingForm">Job Description</div>
      <div className="formcustom twoColumn">
        <div>
          <LabelCustom labelName={"Job Description"} />
          <TextAreaFormik
            maxLength={500}
            placeholder={"Write Job Description"}
            name="JobDescription"
          />
        </div>
        <div>
          <LabelCustom labelName={"Upload Job Description"} mandatory={true} />
          <FileUploadFormik
            placeholder={"Upload Job Description"}
            name="upload"
          />
          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
            Size: 2 MB
          </span>
          <span style={{ paddingLeft: "12px", color: "var(--secondary)" }}>
            Format: JPG, PNG, PDF , Doc ,Docx
          </span>
        </div>
      </div>
    </>
  );
}

export { PositionDetails };
