/* eslint-disable no-unused-vars */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailLeaveBalance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LeaveBalances/Details/${selectedId}`);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // //console.log("eventtData", eventtData);
  useEffect(() => {
    document.title = `PeopleSol - Details Leave Balance`;
    return () => {
      document.title = "PeopleSol";
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="moduleMasterDetailsPageBody">
            {/* <div className="moduleMasterDetailsBox"> */}
            <h4> Details</h4>
            <DetailsStep
              detailTitle={"Employee Name"}
              Details={
                eventtData?.leaveBalances?.employee?.nameWithPersonNumber
                  ? eventtData?.leaveBalances?.employee?.nameWithPersonNumber
                  : "-"
              }
            />

            <DetailsStep
              detailTitle={"Leave Type Name"}
              Details={
                eventtData?.leaveBalances?.leaveTypeName
                  ? eventtData?.leaveBalances?.leaveTypeName
                  : "-"
              }
            />
            <DetailsStep
              detailTitle={"Opening Balance"}
              Details={
                eventtData?.leaveBalances
                  ? eventtData?.leaveBalances?.openingBalance
                  : "-"
              }
            />

            <DetailsStep
              detailTitle={"Created By"}
              Details={
                eventtData?.createdbyname ? eventtData?.createdbyname : "-"
              }
            />

            <DetailsStep
              detailTitle={"Updated By"}
              Details={
                eventtData?.updatedbyname ? eventtData?.updatedbyname : "-"
              }
            />
            <DetailsStep
              detailTitle={"Is Enabled"}
              Details={eventtData?.leaveBalances?.isEnabled ? "Yes" : "No"}
            />
            <DetailsStep
              detailTitle={"Created On"}
              Details={
                eventtData?.leaveBalances?.createdOn
                  ? format(
                      new Date(eventtData?.leaveBalances?.createdOn),
                      "dd/MM/yyyy hh:mm a"
                    )
                  : "-"
              }
            />
            <DetailsStep
              detailTitle={"Last Updated On"}
              Details={
                eventtData?.leaveBalances?.lastUpdatedOn
                  ? format(
                      new Date(eventtData?.leaveBalances?.lastUpdatedOn),
                      "dd/MM/yyyy hh:mm a"
                    )
                  : "-"
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { DetailLeaveBalance };
