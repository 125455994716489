/* eslint-disable no-unused-vars */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { isLoading, showToast } from "../../../Features";
import { getRequest } from "../../../Services/axios";
import { DetailsStep } from "../../../Components/FormComponent/DetailsStep";
import { Loader } from "../../../Components/FormComponent/Loader";
import { format } from "date-fns";

const DetailLeaveType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedId = location?.state?.id;
  // //console.log("id", selectedId);
  const closeDetails = () => {
    let currentUrl = location?.pathname;
    navigate(currentUrl?.substring(0, currentUrl?.lastIndexOf("/")));
  };
  const [loading, setLoading] = useState(true);
  const [eventtData, setEventtData] = useState(false);
  const getEmployeeData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await getRequest(`LeaveTypes/Details/${selectedId}`);
      setEventtData(result.data);
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  useEffect(() => {
    getEmployeeData();
    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // //console.log("eventtData", eventtData);
  useEffect(() => {
    document.title = `PeopleSol - Details Leave Type`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div className="moduleMasterDetailsPageContainer">
        <div className="headingContainer">
          <h3>Details</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        {loading ? (
          <Loader />
        ) : (
        <div className="moduleMasterDetailsPageBody">
          {/* <div className="moduleMasterDetailsBox"> */}
          <h4>General Details</h4>
          <DetailsStep detailTitle={"Leave Type Name"} Details={eventtData?.leaveTypes?.name} />
          
          <DetailsStep detailTitle={"Leave Type Code"} Details={eventtData?.leaveTypes?.code} />
          <DetailsStep detailTitle={"Leave Type Types"} Details={eventtData?.leaveTypes?.type} />

          <DetailsStep detailTitle={"Leave Type Unit"} Details={eventtData?.leaveTypes?.unit}/>
          
          <DetailsStep detailTitle={"Leave Type Balance Based On"} Details={eventtData?.leaveTypes?.balanceBasedOn}/>
          <DetailsStep detailTitle={"Leave Type valid Form"} Details={eventtData?.leaveTypes?.validityFrom}/>
          <DetailsStep detailTitle={"Leave Type Valid To"} Details={eventtData?.leaveTypes?.validityTo}/>
          <DetailsStep detailTitle={"Created By"} Details={eventtData?.createdBy}/>
          <DetailsStep detailTitle={"Updated By"} Details={eventtData?.updatedBy}/>

          <DetailsStep
            detailTitle={"Created On"}
            Details={format(
              new Date(eventtData?.leaveTypes?.createdOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
          <DetailsStep
            detailTitle={"Last Updated On"}
            Details={format(
              new Date(eventtData?.leaveTypes?.lastUpdatedOn),
              "dd/MM/yyyy hh:mm a"
            )}
          />
      
         
          <DetailsStep detailTitle={"Active"} Details={eventtData?.leaveTypes?.isActive===true?"True":"False"}/>

          <h4>Entitlements Details</h4>

          <DetailsStep detailTitle={"Effective After"} Details={
            <p>{eventtData?.leaveTypes?.entitlement?.effectiveAfterValue}{eventtData?.leaveTypes?.entitlement?.effectiveAfterDuration}{eventtData?.leaveTypes?.entitlement?.effectiveAfterType}</p>
          } />
          
          <DetailsStep detailTitle={"Prorate Accrual"} Details={eventtData?.leaveTypes?.entitlement?.prorateAccural} />
          <DetailsStep detailTitle={"Opening Balance"} Details={eventtData?.leaveTypes?.entitlement?.addOpeningBalance} />
          <DetailsStep detailTitle={"Deductible Holidays"} Details={eventtData?.leaveTypes?.entitlement?.deductibleHolidays}/>

          <h4>Applicable Details</h4>
          <DetailsStep detailTitle={"Gender"} Details={eventtData?.leaveTypes?.applicable?.gender} />
          <DetailsStep detailTitle={"Marital Status"} Details={eventtData?.leaveTypes?.applicable?.maritalStatus}/>
          <DetailsStep detailTitle={"Department"} Details={eventtData?.leaveTypes?.applicable?.department}/>
          <DetailsStep detailTitle={"Designation"} Details={eventtData?.leaveTypes?.applicable?.designation}/>
          <DetailsStep detailTitle={"Location"} Details={eventtData?.leaveTypes?.applicable?.locationName}/>
          <DetailsStep detailTitle={"Role"} Details={eventtData?.leaveTypes?.applicable?.role}/>
          <DetailsStep detailTitle={"Employement Type "} Details={eventtData?.leaveTypes?.applicable?.employmentType}/>
          <DetailsStep detailTitle={"Source of Hire"} Details={eventtData?.leaveTypes?.applicable?.sourceOfHire}/>
          <DetailsStep detailTitle={"Onboarding Status"} Details={eventtData?.leaveTypes?.applicable?.onboardingStatus}/>

          <h4>Restrictions Details</h4>
          <DetailsStep detailTitle={"Weekend Between leave Period"} Details={eventtData?.leaveTypes?.restrictions?.weekends}/>
          <DetailsStep detailTitle={"Maximum Leave"} Details={eventtData?.leaveTypes?.restrictions?.maxAppLeave}/>
          <DetailsStep detailTitle={"Minimum Leave"} Details={eventtData?.leaveTypes?.restrictions?.minAppLeave}/>
          <DetailsStep detailTitle={"Holidays Between Leave Period"} Details={eventtData?.leaveTypes?.restrictions?.holidays}/>
          <DetailsStep detailTitle={"While Applying Leave Exceed Leave Balance"} Details={eventtData?.leaveTypes?.restrictions?.exceedLeavebalance}/>
          <DetailsStep detailTitle={"Duration(s) Allowed"} Details={eventtData?.leaveTypes?.restrictions?.duratiuonAllowed}/>
        
        


          

          
          
           
          

          
          
          

 
      
        </div>)}
      </div>
    </div>
  );
};

export { DetailLeaveType };
