import React from 'react';
import { WhatsNewContent } from './WhatsNewContent';
import { SelectCustom } from '../../../../../Components/FormComponent/SelectCustom';
import { useState } from 'react';
import { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../Features";
import { postRequest } from "../../../../../Services/axios";
const noOfRecords = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
];

const EnhancementWhatsNew = () => {
  useEffect(() => {
    document.title = `PeopleSol - Enhancement`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[1]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [idActive, setIdActive] = useState(0);
  const [reqData, setReqData] = useState({
    createdOn: null,
    catagory: "Enhancement",
    lastUpdatedOn: null,
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const getListData = async () => {
    try {
      setLoading(true);
      const result = await postRequest("WhatsNewFeature/AllList", reqData);
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result?.data?.data.map((item) =>
            tempData.push({
              ...item.item,

            })
          );
          const finalArray = tempData.map(
            ({
              id,
              ...rest
            }) => ({
              id,
              ...rest,
            })
          );
          setTbodyData(finalArray);
          // //console.log("tbodydata",tbodyData);
          setCount(result.data.summary.allCount);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
      setCount(result.data.count);
      setSelectedRows([]);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
      setTbodyData(false);
      setCount(0);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // if (location.pathname.toLowerCase().includes("requesttype"))
    getListData();
  }, []);

  // //console.log("tbodys", tbodyData);
  function getDate(d){
    const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
    let date = new Date(d);
    let newDate=date.getDate();
    let month = monthNames[date.getMonth()];
    return month+" "+newDate;
  }

  //   const allOptions = [
  //     {
  //       heading: 'Features: HRMS Core HR Functions',
  //       sideText:"Enhancement",
  //       sideColor: '#5BC4BF',
  //       data: ['Requisition',
  //           'Employee and Candidate Onboarding',
  //           'Employee Induction',
  //           'Probation and Extension',
  //           'Onboarding Feedback',
  //           'Attendance',
  //           'Regularization',
  //           'Late Coming',
  //           'Early Going',
  //           'Leave',
  //           'Holiday',
  //           'Comp Off',
  //           'Outdoor',
  //          ' HR Desk',
  //           'Benefits',
  //           'Loan and Advance',
  //           'Deputation',
  //           'Relocation',
  //           'Separation',
  //           'Due Clearance',
  //           'Full & Final Settlement',
  //           'Exit Interviews',
  //           'Exit Feedback']
  //   }
      
  
  // ]

  // const monthOptions = [
  //   {label:'Jan', value:'Jan'},
  //   {label:'Feb', value:'Feb'},
  //   {label:'Mar', value:'Mar'},
  //   {label:'Apr', value:'Apr'},
  //   {label:'May', value:'May'},
  //   {label:'Jun', value:'Jun'},
  //   {label:'Jul', value:'Jul'},
  //   {label:'Aug', value:'Aug'},
  //   {label:'Sep', value:'Sep'},
  //   {label:'Oct', value:'Oct'},
  //   {label:'Nov', value:'Nov'},
  //   {label:'Dec', value:'Dec'},
  // ]

  return (
    <div className='allMainContainer'>
        {/* <div className="allContentHolder leftContent">
        <div className="ddHolderLeft">
          
        <div className='ddMainContainer'>

          <div className={`ddContent cursor ${idActive === 1 ? "borderClass" : null}`} onClick={() => setIdActive(1)}>
            <label>2022</label>
            <div className="ddIconContainer">
            {idActive === 1 ? (<svg width="15" height="10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.95752 5.40234L5.1488 1.21094L9.34021 5.40234" stroke="white" stroke-linecap="round"/>
            </svg>
            ) : (
            <svg width="15" height="10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.95752 0.770508L5.1488 4.96191L9.34021 0.770508" stroke="white" stroke-linecap="round"/>
            </svg>)}
            </div>
          </div>

          <div className={`ddContentDisplayer ${idActive === 1 ? "visibleClass" : null}`}>
            {monthOptions && monthOptions?.map((item) => {
              return(
                <label className='cursor' onClick={() => setIdActive(0)}>{item.value}</label>
              )
            })}
          </div>
        </div>
        <div className='ddMainContainer'>
          <div className={`ddContent cursor ${idActive === 2 ? "borderClass" : null}`} onClick={() => setIdActive(2)}>
            <label>2023</label>
            <div className="ddIconContainer">
            {idActive === 2 ? (<svg width="15" height="10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.95752 5.40234L5.1488 1.21094L9.34021 5.40234" stroke="white" stroke-linecap="round"/>
            </svg>
            ) : (
            <svg width="15" height="10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.95752 0.770508L5.1488 4.96191L9.34021 0.770508" stroke="white" stroke-linecap="round"/>
            </svg>)}
            </div>
          </div>
          <div className={`ddContentDisplayer ${idActive === 2 ? "visibleClass" : null}`}>
            {monthOptions && monthOptions?.map((item) => {
              return(
                <label className='cursor' onClick={() => setIdActive(0)}>{item.value}</label>
              )
            })}
          </div>
        </div>
        <div className='ddMainContainer'>
          <div className={`ddContent cursor ${idActive === 3 ? "borderClass" : null}`} onClick={() => setIdActive(3)}>
            <label>2024</label>
            <div className="ddIconContainer">
            {idActive === 3 ? (<svg width="15" height="10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.95752 5.40234L5.1488 1.21094L9.34021 5.40234" stroke="white" stroke-linecap="round"/>
            </svg>
            ) : (
            <svg width="15" height="10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.95752 0.770508L5.1488 4.96191L9.34021 0.770508" stroke="white" stroke-linecap="round"/>
            </svg>)}
            </div>
          </div>
          <div className={`ddContentDisplayer ${idActive === 3 ? "visibleClass" : null}`}>
            {monthOptions && monthOptions?.map((item) => {
              return(
                <label className='cursor' onClick={() => setIdActive(0)}>{item.value}</label>
              )
            })}
          </div>
        </div>
       
        </div>
        
        </div> */}

        <div className="allContentHolder rightContent">

        <div className="horizontalGreyLine" style={{width:"13px"}}></div>

        <div className="rightInfoHolder">
        {tbodyData && tbodyData?.map((item) => {
          return(
            <div style={{display:'flex'}}>
            <div className="leftInfoHolder">
            <label className="leftInfo">{getDate(item.createdOn)}</label>
            </div>
            <WhatsNewContent heading={item.feature} sideText={item.catagory} sideColor={item.sideColor} data={item.subFeatures} /> 
            </div>
            )
        })}
        </div>
        </div>
      
    </div>
  )
}

export {EnhancementWhatsNew}

