/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router";
import { showToast } from "../../../../Features";
import { getRequest } from "../../../../Services/axios";
import "../../../HRMSMaster/Components/ModuleMasterDetailsPage.css";
import Nodata from "../../../../Assets/Nodata.svg";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { format } from "date-fns";
import { LabelCustom } from "../../../../Components/FormComponent/LabelCustom";
import StarRatings from "react-star-ratings";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";
import { InstructionSelf } from "./InstructionSelf";
import { useSelector } from "react-redux";
const DetailsMySubmittedReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const closeDetails = () => {
    navigate(-1);
  };
  const { LoggedInUser } = useSelector((state) => state.auth);
  const { state } = useLocation();
  const selectedId = state?.item;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    document.title = `PeopleSol -  My Submitted Review Details`;
    return () => {
      document.title = 'PeopleSol';
    };
  }, []);
  const toggleRow = (index) => {
    const expandedIndex = expandedRows.indexOf(index);
    const newExpandedRows = [...expandedRows];

    if (expandedIndex > -1) {
      newExpandedRows.splice(expandedIndex, 1);
    } else {
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };
  const selfRatingObtain = (total) => {
    if (total >= 111 && total <= 120) {
      return 5;
    }
    if (total >= 101 && total <= 110) {
      return 4;
    }
    if (total >= 91 && total <= 100) {
      return 3;
    }
    if (total >= 81 && total <= 90) {
      return 2;
    }
    if (total < 80) {
      return 1;
    }
  };
  const togglePopup = () => {
    setIsOpen((prev) => !prev);
  };

  const getDetails = async () => {
    try {
      setLoading(true);
      const result = await getRequest(
        `PMSGoalReviews/ViewDetails/?ID=${selectedId.id}&EmployeeId=${LoggedInUser?.id}`
      );
      setData(result?.data?.data);
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "Error in fetching Data",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  //console.log("selectedId", selectedId);
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div className="moduleMasterDetailsPage">
      <div
        className="moduleMasterDetailsBackground"
        onClick={() => closeDetails()}
      ></div>
      <div
        className="moduleMasterDetailsPageContainer tabularMasterDetailsPageContainer"
        style={{ width: "90% !important" }}
      >
        <div className="headingContainer">
          <h3>{`Details (${selectedId?.kpiName})`}</h3>
          <IconButton onClick={() => closeDetails()} title="close">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7579 0.56897L0.757935 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 0.56897L12.7579 12.569"
                stroke="#F71D1D"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </IconButton>
        </div>
        <div className="tabularMasterDetailsPageBody">
          {loading ? (
            <Loader />
          ) : data ? (
            <div className="selfParent">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "12px",
                }}
              >
                <div className="ratingSelf">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ marignTop: "12px" }}>
                      Self Rating :
                    </div>
                    <div>
                      {" "}
                      <StarRatings
                        rating={selfRatingObtain(
                          data?.pmsReviews?.pmsReviewFeedbacksUpdates.reduce(
                            (sum, card) => sum + card.employeeAchievement,
                            0
                          )
                        )}
                        starRatedColor="#5BC4BF"
                        starSpacing="2px"
                        starEmptyColor="#FFFFFF"
                        numberOfStars={5}
                        starHoverColor="var(--primary)"
                        starDimension="28px"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>Self Achievement %</div>
                    <div>
                      <p
                        style={{
                          color: "#5BC4BF",
                          fontSize: "28px",
                          fontWeight: "500",
                          marginTop: "5px",
                        }}
                      >
                        {data?.pmsReviews?.pmsReviewFeedbacksUpdates.reduce(
                          (sum, card) => sum + card.employeeAchievement,
                          0
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                {/* {(data?.pmsReviews?.status === "Review Pending" ||
                  data?.pmsReviews?.status === "Review Completed") && ( */}
                  <div
                    className="ratingSelf"
                    style={{ backgroundColor: "#FFEEF2" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ marignTop: "12px" }}>
                        Appraiser Rating :
                      </div>
                      <div>
                        {" "}
                        <StarRatings
                          rating={selfRatingObtain(
                            data?.pmsReviews?.pmsReviewFeedbacksUpdates.reduce(
                              (sum, card) => sum + card.appraiserAchievement,
                              0
                            )
                          )}
                          starRatedColor="#DE64A1"
                          starSpacing="2px"
                          starEmptyColor="#FFFFFF"
                          numberOfStars={5}
                          starHoverColor="var(--primary)"
                          starDimension="28px"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div>Appraiser Achievement %</div>
                      <div>
                        <p
                          style={{
                            color: "#DE64A1",
                            fontSize: "28px",
                            fontWeight: "500",
                            marginTop: "5px",
                          }}
                        >
                          {data?.pmsReviews?.pmsReviewFeedbacksUpdates.reduce(
                            (sum, card) => sum + card.appraiserAchievement,
                            0
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                 {/* )} */}
                {data?.pmsReviews?.status === "Review Completed" && (
                  <div
                    className="ratingSelf"
                    style={{ backgroundColor: "#F0F0FF" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ marignTop: "12px" }}>
                        Reviewer Rating :
                      </div>
                      <div>
                        {" "}
                        <StarRatings
                          rating={selfRatingObtain(
                            data?.pmsReviews?.pmsReviewFeedbacksUpdates.reduce(
                              (sum, card) => sum + card.reviewerAchievement,
                              0
                            )
                          )}
                          starRatedColor="#8A88ED"
                          starSpacing="2px"
                          starEmptyColor="#FFFFFF"
                          numberOfStars={5}
                          starHoverColor="var(--primary)"
                          starDimension="28px"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div>Reviewer Achievement %</div>
                      <div>
                        <p
                          style={{
                            color: "#8A88ED",
                            fontSize: "28px",
                            fontWeight: "500",
                            marginTop: "5px",
                          }}
                        >
                          {data?.pmsReviews?.pmsReviewFeedbacksUpdates.reduce(
                            (sum, card) => sum + card.reviewerAchievement,
                            0
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {data?._pmsAppraisalCycles?.goalslist?.length > 0 ? (
                <div style={{ width: "100%", overflow: "auto" }}>
                  <table
                    className="tabself tabself-bordered"
                    style={{ width: "100%" }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#FBFBFB",
                        top: 0,
                        position: "sticky",
                        zIndex: 2,
                      }}
                    >
                      <tr>
                        <td>
                          <LabelCustom labelName={"S.no"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Name"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Description"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Start Date"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Expected End Date"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"KRA"} />
                        </td>
                        <td>
                          <LabelCustom labelName={"Weightage"} />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {data?._pmsAppraisalCycles?.goalslist?.map(
                        (items, index) => {
                          const isRowExpanded = expandedRows.includes(index);

                          return (
                            <React.Fragment key={index}>
                              <tr
                                className={`trStyles`}
                                key={index}
                                style={{
                                  position: "relative",
                                }}
                              >
                                <td>
                                  <LabelCustom labelName={index + 1} />
                                </td>
                                <td>{items.name}</td>
                                <td>
                                  <LabelCustom labelName={items.description} />

                                  <IconButton
                                    onClick={() => toggleRow(index)}
                                    sx={{
                                      display: isRowExpanded && "none",
                                      position: "absolute",
                                      bottom: "-18px",
                                      left: "50%",
                                      zIndex: "1",
                                      boxShadow:
                                        "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                    }}
                                  >
                                    {!isRowExpanded && (
                                      <>
                                        <svg
                                          width="14"
                                          height="9"
                                          viewBox="0 0 14 9"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M12.8765 0.898926L6.82826 6.94713L0.780053 0.898925"
                                            stroke="#1296B0"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      </>
                                    )}
                                  </IconButton>
                                </td>
                                <td>
                                  <LabelCustom
                                    labelName={
                                      items.startDate &&
                                      format(
                                        new Date(items.startDate),
                                        "dd/MM/yyyy"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <LabelCustom
                                    labelName={
                                      items.expectedEndDate &&
                                      format(
                                        new Date(items.expectedEndDate),
                                        "dd/MM/yyyy"
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <LabelCustom labelName={items.kra} />
                                </td>
                                <td>
                                  <LabelCustom labelName={items.weightage} />
                                </td>
                              </tr>
                              {isRowExpanded && (
                                <tr>
                                  <td
                                    colSpan="7"
                                    style={{
                                      padding: "0",
                                    }}
                                  >
                                    <div className="formSelfReview">
                                      <div className="centertd">
                                        <div>Self</div>
                                        <div>Review</div>
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Notes"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            data?.pmsReviews
                                              ?.pmsReviewFeedbacksUpdates[index]
                                              ?.employeeRemarks
                                          }
                                        />
                                      </div>
                                      <div style={{ flex: "1" }}>
                                        <label
                                          style={{
                                            paddingLeft: "12px",
                                          }}
                                        >
                                          {"Achievement %"}
                                        </label>
                                        <LabelCustom
                                          labelName={
                                            data?.pmsReviews
                                              ?.pmsReviewFeedbacksUpdates[index]
                                              ?.employeeAchievement
                                          }
                                        />
                                      </div>
                                    </div>
                                    {(data?.pmsReviews?.status ===
                                      "Review Pending" ||
                                      data?.pmsReviews?.status ===
                                        "Review Completed") && (
                                      <div
                                        className="formSelfReview"
                                        style={{ backgroundColor: "#FFEEF2" }}
                                      >
                                        <div
                                          className="centertd"
                                          style={{ backgroundColor: "#DE64A1" }}
                                        >
                                          <div>Appraiser</div>
                                          <div>Review</div>
                                        </div>
                                        <div style={{ flex: "1" }}>
                                          <label
                                            style={{
                                              paddingLeft: "12px",
                                            }}
                                          >
                                            {"Notes"}
                                          </label>
                                          <LabelCustom
                                            labelName={
                                              data?.pmsReviews
                                                ?.pmsReviewFeedbacksUpdates[
                                                index
                                              ]?.appraiserRemarks
                                            }
                                          />
                                        </div>

                                        <div style={{ flex: "1" }}>
                                          <label
                                            style={{
                                              paddingLeft: "12px",
                                            }}
                                          >
                                            {"Achievement %"}
                                          </label>
                                          <LabelCustom
                                            labelName={
                                              data?.pmsReviews
                                                ?.pmsReviewFeedbacksUpdates[
                                                index
                                              ]?.appraiserAchievement
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {data?.pmsReviews?.status ===
                                      "Review Completed" && (
                                      <div
                                        className="formSelfReview"
                                        style={{
                                          backgroundColor: "#F0F0FF",
                                        }}
                                      >
                                        <div
                                          className="centertd"
                                          style={{
                                            backgroundColor: "#8A88ED",
                                          }}
                                        >
                                          <div>Reviewer</div>
                                          <div>Review</div>
                                        </div>
                                        <div style={{ flex: "1" }}>
                                          <label
                                            style={{
                                              paddingLeft: "12px",
                                            }}
                                          >
                                            {"Notes"}
                                          </label>
                                          <LabelCustom
                                            labelName={
                                              data?.pmsReviews
                                                ?.pmsReviewFeedbacksUpdates[
                                                index
                                              ]?.reviewerRemarks
                                            }
                                          />
                                        </div>
                                        <div style={{ flex: "1" }}>
                                          <label
                                            style={{
                                              paddingLeft: "12px",
                                            }}
                                          >
                                            {"Achievement %"}
                                          </label>
                                          <LabelCustom
                                            labelName={
                                              data?.pmsReviews
                                                ?.pmsReviewFeedbacksUpdates[
                                                index
                                              ]?.reviewerAchievement
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}

                                    <IconButton
                                      onClick={() => toggleRow(index)}
                                      sx={{
                                        left: "50%",
                                        zIndex: "9999",
                                        boxShadow:
                                          "0px 1.89445px 10px rgba(0, 0, 0, 0.04);",
                                      }}
                                    >
                                      {isRowExpanded && (
                                        <>
                                          <svg
                                            width="15"
                                            height="9"
                                            viewBox="0 0 15 9"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13.3755 7.62805L7.32728 1.57985L1.27908 7.62805"
                                              stroke="#1296B0"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                            />
                                          </svg>
                                        </>
                                      )}
                                    </IconButton>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                  <div
                   className="reviewNotes"
                  >
                             <div className="notesSelf">
                    <div className="headingSelf">
                      <p>Self Notes</p>
                    </div>
                    <div className="detailSelf">
                      <div>
                        <LabelCustom labelName={data?.pmsReviews?.notes} />
                      </div>
                    </div>
                  </div>
                  {(data?.pmsReviews?.status === "Review Pending" ||
                    data?.pmsReviews?.status === "Review Completed") && (
                    <div
                      className="notesSelf"
                      style={{ backgroundColor: "#FFEEF2" }}
                    >
                      <div
                        className="headingSelf"
                        style={{ background: "#DE64A1" }}
                      >
                        <p>Appraiser Notes</p>
                      </div>
                      <div className="detailSelf">
                        <div>
                          <LabelCustom
                            labelName={data?.pmsReviews?.appraiserReviewNotes}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {data?.pmsReviews?.status === "Review Completed" && (
                    <div
                      className="notesSelf"
                      style={{ backgroundColor: "rgb(240, 240, 255)" }}
                    >
                      <div
                        className="headingSelf"
                        style={{ background: "rgb(138, 136, 237)" }}
                      >
                        <p>Reviewer Notes</p>
                      </div>
                      <div className="detailSelf">
                        <div>
                          <LabelCustom
                            labelName={data?.pmsReviews?.reviewerReviewNotes}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  </div>
         
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="noDatastyle">
              <img
                src={Nodata}
                alt="No data"
                style={{ height: "150px", width: "250px" }}
              />
              <h4>{"No Data Found"}</h4>
            </div>
          )}
        </div>
        {isOpen && (
          <Popup
            popupHeading={"Rating Slab"}
            content={
              <>
                <InstructionSelf handleClose={togglePopup} />
              </>
            }
            handleClose={togglePopup}
          />
        )}
      </div>
    </div>
  );
};
export { DetailsMySubmittedReview };
