import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { isLoading, showToast } from "../../../../Features";
import {
  deleteWithPayloadRequest,
  getRequest,
  postRequest,
} from "../../../../Services/axios";
import { useDeferredValue } from "react";
import { useMemo } from "react";
import { SelectCustom } from "../../../../Components/FormComponent/SelectCustom";
import { DateRangePicker } from "../../../../Components/FormComponent/DateRangePicker";
import { format } from "date-fns";
import MoreActivityContainer from "../../../../Components/Table/MoreActivityContainer";
import ApproveIcon from "../../../../Assets/ApproveIcon.svg";
import RejectIcon from "../../../../Assets/RejectIcon.svg";
import HoldIcon from "../../../../Assets/HoldIcon.svg";
import CancelIcon from "../../../../Assets/CancelIcon.svg";
import { TransactionTable } from "../../../../Components/Table/TransactionTable";
import { Popup } from "../../../../Components/FormComponent/PopupComponent/Popup";

import { useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { DatePickerCustom } from "../../../../Components/FormComponent/DatePickerCustom";
import { ApprovePopup } from "../../../../Components/FormComponent/PopupComponent/ApprovePopup";
import { Remark } from "../../../../Components/FormComponent/PopupComponent/Remark";
import OutsideClickHandler from "../../../../Components/OutsideClickHandler/OutsideClickHandler";
import { Loader } from "../../../../Components/FormComponent/Loader";
import { AdminUpdate } from "../../../HRMS/Components/BenefitsComponents/Form/AdminUpdate";
import { AdminCreate } from "../../../HRMS/Components/BenefitsComponents/Form/AdminCreate";
import { Allocation } from "../../../HRMS/Components/BenefitsComponents/Form/Allocation";
import { Surrender } from "../../../HRMS/Components/BenefitsComponents/Form/Surrender";

const RejectedFacilities = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = [
    { label: "All", value: "All" },
    { label: "Open", value: "Open" },
    { label: "Closed", value: "Closed" },
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Hold", value: "Hold" },
    { label: "Cancelled", value: "Cancelled" },
  ];
  const transfer = [
    { label: "Employee Loan", value: "Employee Loan" },
    { label: "Term Advance", value: "Term Advance" },
    { label: "Salary Advance", value: "Salary Advance" },
  ];

  useEffect(()=>{
    document.title = `PeopleSol - Rejected Facilities`;
    return () => {
      document.title = 'PeopleSol';
    };
  })

  const noOfRecords = [
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
  ];
  const [loading, setLoading] = useState(true);
  const [tbodyData, setTbodyData] = useState(false);
  const [originalData, setOriginalData] = useState(false);
  const [count, setCount] = useState(false);
  const [recordsSelected, setRecordsSelected] = useState(noOfRecords[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortParam, setSortParam] = useState("Id");
  const [sortType, setSortType] = useState("descending");
  const [searchParam, setSearchParam] = useState(null);
  const [createdOnStartDate, setCreatedOnStartDate] = useState(false);
  const [dateOfJoning, setDateOfJoining] = useState(false);
  const [createdOnEndDate, setCreatedOnEndDate] = useState(false);
  const [lastUpdatedOnStartDate, setLastUpdatedOnStartDate] = useState(false);
  const [lastUpdatedOnEndDate, setLastUpdatedOnEndDate] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [selectedTransferTypeList, setSelectedTransferTypeList] = useState([]);
  const [selectedfacilityTypeList, setSelectedfacilityTypeList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [optionEmployees, setOptionEmployees] = React.useState([]);
  const [optionEmployee, setOptionEmployee] = React.useState([]);
  const [optionfacilityType, setOptionFacilityType] = React.useState([]);
  const [badge, setBadge] = useState(0);
  const [typeAll, setTypeAll] = useState("");
  const [typeAction, setTypeAction] = useState("");
  const [colorAction, setColorAction] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isApprovePopup, setIsApprovePopup] = useState(false);
  const [selId, setSelId] = useState(false);
  const [dateSelect, setDateSelect] = useState(false);
  const [extra, setExtra] = useState("");
  const togglePopup = (type, color, id, extra) => {
    // //console.log("type", type);
    setTypeAction(type);
    setColorAction(color);
    setSelId(id);
    setExtra(extra);
    setIsOpen((prev) => !prev);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const togglePopupapprove = (type) => {
    setTypeAll(type);
    setIsApprovePopup((prev) => !prev);
  };

  const clickHandlerFunction = (id, type, color, extra) => {
    if (
      type !== "Approve" &&
      type !== "Release" &&
      type !== "Cancel" &&
      type !== "Accept" &&
      type !== "Complete"
    ) {
      togglePopup(type, color, id, extra);
    } else {
      PostApRejHol(type, id);
    }
  };
  const PostApRejHol = async (type, id, Remark) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      if (
        type === "Approve" ||
        type === "Cancel" ||
        type === "Release" ||
        type === "Confirm"
      ) {
        const result = await postRequest(`FacilityRequests/${type}?Id=${id}`);
        if (result) {
          dispatch(
            showToast({ text: result?.data?.message, severity: "success" })
          );
        }
      } else if (type === "Accept") {
        const result = await postRequest(`FacilityRequests/Complete?Id=${id}`);
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      } else if (type === "Complete") {
        const result = await postRequest(
          `FacilityRequests/ModificationApprove?ID=${id}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
        getMaiintenenceListData();
      } else {
        const result = await postRequest(
          `FacilityRequests/${type}?Id=${id}&Remarks=${Remark}`
        );
        dispatch(
          showToast({ text: result?.data?.message, severity: "success" })
        );
      }
    } catch (error) {
      dispatch(
        showToast({ text: error?.response?.data ?? "Error in fetching Data", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setIsOpen(false);
      getAllListData();
    }
  };

  const [reqData, setReqData] = useState({
    createdOn: null,
    lastUpdatedOn: null,
    status:"Rejected",
    dataTableRequest: {
      iDisplayLength: recordsSelected.value,
      iDisplayStart: 0,
      sSearch: searchParam,
      sorting: sortParam,
      sSortDir_0: sortType,
      isExport: false,
    },
  });
  const [filterSearchToogle, setFilterSearchToogle] = useState(false);
  const calculateTotalPages = (count) => {
    if (count > 0) {
      let numberOfPages = parseInt(count / recordsSelected.value);
      if (count % recordsSelected.value !== 0) {
        setTotalPages(numberOfPages + 1);
      } else {
        setTotalPages(numberOfPages);
      }
    } else {
      setTotalPages(1);
    }
  };
  const exportTable = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const downloadReqData = {
        dataTableRequest: {
          isExport: true,
        },
        status:"Rejected",
      };
      var element = document.createElement("a");

      var result = await postRequest(
        "FacilityRequests/MyApprovalList",
        downloadReqData
      );
      element.setAttribute(
        "href",
        `data:text/csv;charset=utf-8,${encodeURI(result.data)}`
      );

      element.setAttribute("download", `${props.title}`);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      dispatch(
        showToast({ text: "Error in donwloading file", severity: "error" })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getStaticActions = (data) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    if (
      data.status !== "Cancelled" &&
      data.status !== "Rejected" &&
      data.status !== "Closed" &&
      data.status !== "Released" &&
      data.status !== "Amount Received"
    ) {
      returnActionArray.push({
        img: <img src={CancelIcon} alt="Cancel" />,
        action: "Cancel Request",
        clickAction: (id) => {
          cancelRequest(id);
        },
      });
    }
    return returnActionArray;
  };
  const getAllListCheckStatus = (data) => {
    let returnValue = false;
    data?.actions?.map((item) => {
      if (item.type === "Approve" || item.type === "Reject") returnValue = true;
        return null
    });
    return returnValue;
  };
  const getActions = (actionList, item) => {
    // //console.log("actionList", actionList);
    // //console.log("item", item.item.id);
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (action.type === "Approve") {
          returnActionArray.push({
            img: <img src={ApproveIcon} alt="Approve" />,
            action: "Approve Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Approve", "rgb(255, 128, 0)"),
          });
        }
        if (action.type === "Reject") {
          returnActionArray.push({
            img: <img src={RejectIcon} alt="Reject" />,
            action: "Reject Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Reject", "red"),
          });
        }
        if (action.type === "Hold") {
          returnActionArray.push({
            img: <img src={HoldIcon} alt="Hold" />,
            action: "Hold Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Hold", "rgb(255, 128, 0)"),
          });
        }
      });
    }
    return returnActionArray;
  };
  const getMoreActions = (actionList, item) => {
    // //console.log("emp", item?.item?.id);
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              action.type === "Readiness"
                ? clickHandlerFunction(
                    item?.item?.id,
                    action?.type,
                    action?.color
                  )
                : action.type === "Accept" || action.type === "Complete"
                ? clickHandlerFunction(action?.id, action?.type, action?.color)
                : clickHandlerFunction(
                    item?.item?.assetcategoryId,
                    action?.type,
                    item?.item?.employeeId
                  ),
          });
        }
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getAllListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/MyApprovalList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              status: item?.status.replace(/(<([^>]+)>)/gi, ""),
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions, item),
              moreActions: getMoreActions(item?.actions),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              referenceNo,
              employee,
              status,
              facility,
              type,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              referenceNo,
              employee,
              status,
              facility,
              type,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.rejectedCount);
          calculateTotalPages(result.data.summary.rejectedCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getEmployeeAdminFacility = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/EmployeeFacilitiesList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              facilityMaterial: item?.item?.facilitymaterialname,
              facilityCategory: item?.item?.categoryName,
              facilityName: item?.item?.facilityname,
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions, item),
              moreActions: getMoreAction(item?.actions, item),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              employeeName,
              status,
              facilityName,
              facilityCategory,
              facilityMaterial,
              allocatedOn,
              createdOn,
            }) => ({
              id,
              employeeName,
              status,
              facilityName,
              facilityCategory,
              facilityMaterial,
              allocatedOn,
              createdOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getMaiintenenceListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/ModificationFacilitiyRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result?.data?.data?.map((item) => {
            // //console.log("first", item?.item);
            return tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              facilityMaterial: item?.item?.facility?.facilityMaterial,
              facilityCategory: item?.item?.facility?.category,
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getAction(item?.actions, item),
              moreActions: getMoreActions(item?.actions),
            });
          });
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, facility, moreActions, ...rest }) => ({
              ...rest,
            })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              status,
              facilityId,
              employeeName,
              type,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              status,
              facilityId,
              employeeName,
              type,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getTeamListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest("FacilityRequests/MyTeamList", reqData);
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions, item),
              moreActions: getMoreActions(item?.actions),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              referenceNo,
              status,
              employee,
              facility,
              type,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              referenceNo,
              status,
              employee,
              facility,
              type,
              fromDate,
              toDate,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getAction = (actionList) => {
    let returnActionArray = [
      {
        img: (
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.15"
              d="M0.172119 4.84375C0.172119 2.63461 1.96298 0.84375 4.17212 0.84375H16.1721C18.3813 0.84375 20.1721 2.63461 20.1721 4.84375V16.8438C20.1721 19.0529 18.3813 20.8438 16.1721 20.8438H4.17212C1.96298 20.8438 0.172119 19.0529 0.172119 16.8438V4.84375Z"
              fill="#0DB53C"
            />
            <line
              x1="14.7295"
              y1="7.43701"
              x2="7.72949"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="7.43701"
              x2="5.61462"
              y2="7.43701"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="11.3437"
              x2="7.72949"
              y2="11.3437"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="11.3437"
              x2="5.61462"
              y2="11.3438"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="14.7295"
              y1="15.2505"
              x2="7.72949"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
            <line
              x1="5.72949"
              y1="15.2505"
              x2="5.61462"
              y2="15.2505"
              stroke="#0DB53C"
              strokeLinecap="round"
            />
          </svg>
        ),
        action: "Request Details",
        clickAction: (id) => getFormDetails(id),
      },
    ];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (action.type === "Approve") {
          returnActionArray.push({
            img: <img src={ApproveIcon} alt="Approve" />,
            action: "Approve Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Approve", "rgb(255, 128, 0)"),
          });
        }
        if (action.type === "Reject") {
          returnActionArray.push({
            img: <img src={RejectIcon} alt="Reject" />,
            action: "Reject Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Reject", "red"),
          });
        }
        if (action.type === "Hold") {
          returnActionArray.push({
            img: <img src={HoldIcon} alt="Hold" />,
            action: "Hold Request",
            clickAction: () =>
              clickHandlerFunction(action?.id, "Hold", "rgb(255, 128, 0)"),
          });
        }
      });
    }
    return returnActionArray;
  };
  const getMoreAction = (actionList, item) => {
    // //console.log("item",item?.item?.status)
    let returnActionArray = [];
    if (actionList.length > 0) {
      actionList.map((action) => {
        if (
          action.type !== "Approve" &&
          action.type !== "Reject" &&
          action.type !== "Hold"
        ) {
          returnActionArray.push({
            actionTitle: action.type,
            clickAction: () =>
              clickHandlerFunction(
                action?.id,
                action?.type,
                action?.color,
                item?.item?.status
              ),
          });
        }
      });
    }
    return returnActionArray.length > 0 ? returnActionArray : null;
  };
  const getSurrenderListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/surrenderRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getAction(item?.actions),
              moreActions: getMoreActions(item?.actions),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, facility, ...rest }) => ({
              ...rest,
            })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              requestNumber,
              status,
              employee,
              facilityID,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              requestNumber,
              status,
              employee,
              facilityID,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const dispatchAdminListData = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/dispatchedFacilityRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            // //console.log("first", item);
            tempData.push({
              ...item.item,
              facilityType: item.item.name,
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(26, 29) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(26, 29) === "new"
                  ? "#755681"
                  : item.status?.slice(26, 29) === "dan"
                  ? "red"
                  : item.status?.slice(26, 29) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(26, 29) === "suc"
                  ? "#99131D"
                  : item.status?.slice(26, 29) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(26, 29) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              // actions: getActions(item?.actions),
              moreActions: getMoreActions(item?.actions, item),
            });
          });
          // //console.log("tem", tempData);
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              facilityId,
              status,
              facilityType,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              facilityId,
              status,
              facilityType,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const facilitiesSummary = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/FacilitiesSummaryList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            // //console.log("first", item);
            tempData.push({
              ...item.item,
              facilityType: item.item.name,
              allocated: item.item._allocated,
              maintenance: item.item._maintenance,
              checkActive: false,
              actions: getAction(item?.actions),
            });
          });
          // //console.log("tem", tempData);
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({ id, facilityType, stockInHand, maintenance, allocated }) => ({
              id,
              facilityType,
              stockInHand,
              maintenance,
              allocated,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const facilitiesCategory = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/FacilityCategorySummaryList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) => {
            // //console.log("first", item);
            tempData.push({
              ...item.item,
              facilityCategory: item.item.name,
              allocated: item.item._allocated,
              maintenance: item.item._maintenance,
              checkActive: false,
              // statusColor:
              //   item.status?.slice(26, 29) === "war"
              //     ? "#ed6c02"
              //     : item.status?.slice(26, 29) === "new"
              //     ? "#755681"
              //     : item.status?.slice(26, 29) === "dan"
              //     ? "red"
              //     : item.status?.slice(26, 29) === "res"
              //     ? "rgb(40, 180, 99)"
              //     : item.status?.slice(26, 29) === "suc"
              //     ? "#99131D"
              //     : item.status?.slice(26, 29) === "inf"
              //     ? "#40D6F1"
              //     : item.status?.slice(26, 29) === "hol"
              //     ? "#ffaa1d"
              //     : "rgb(52, 152, 219)",
              // TAT: item?.tat[0]?.value,
              // tatColor: item?.tat[0]?.color,
              actions: getAction(item?.actions),
              moreActions: getMoreActions(item?.actions, item),
            });
          });
          // //console.log("tem", tempData);
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              facilityCategory,
              stockInHand,
              maintenance,
              allocated,
            }) => ({
              id,
              facilityCategory,
              stockInHand,
              maintenance,
              allocated,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getAllocateList = async () => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      const result = await postRequest(
        "FacilityRequests/AllocateRequestsList",
        reqData
      );
      if (result?.data) {
        if (result?.data?.data) {
          let tempData = [];
          result.data.data.map((item) =>
            tempData.push({
              ...item.item,
              checkActive: getAllListCheckStatus(item),
              statusColor:
                item.status?.slice(25, 28) === "war"
                  ? "#ed6c02"
                  : item.status?.slice(25, 28) === "new"
                  ? "#755681"
                  : item.status?.slice(25, 28) === "dan"
                  ? "red"
                  : item.status?.slice(25, 28) === "res"
                  ? "rgb(40, 180, 99)"
                  : item.status?.slice(25, 28) === "suc"
                  ? "#99131D"
                  : item.status?.slice(25, 28) === "inf"
                  ? "#40D6F1"
                  : item.status?.slice(25, 28) === "hol"
                  ? "#ffaa1d"
                  : "rgb(52, 152, 219)",
              TAT: item?.tat[0]?.value,
              tatColor: item?.tat[0]?.color,
              actions: getActions(item?.actions, item),
              moreActions: getMoreActions(item?.actions, item),
            })
          );
          setOriginalData(tempData);
          const clippedArray = tempData.map(
            ({ createdBy, actions, moreActions, ...rest }) => ({ ...rest })
          );
          const finalArray = clippedArray.map(
            ({
              id,
              ticketNumber,
              requestNumber,
              status,
              assetcategory,
              employee,
              stockInHand,
              createdOn,
              lastUpdatedOn,
            }) => ({
              id,
              ticketNumber,
              requestNumber,
              status,
              assetcategory,
              employee,
              stockInHand,
              createdOn,
              lastUpdatedOn,
            })
          );
          setTbodyData(finalArray);
          setCount(result.data.summary.allCount);
          calculateTotalPages(result.data.summary.allCount);
        } else {
          setTbodyData(false);
          setCount(0);
        }
      } else {
        setTbodyData(false);
        setCount(0);
      }
    } catch (error) {
      setTbodyData(false);
      setCount(0);
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
    }
  };
  const getCheckStatus = (data) => {
    if (
      data.status !== "Cancelled" &&
      data.status !== "Rejected" &&
      data.status !== "Closed"
    ) {
      return true;
    }
    return false;
  };
  const getFacility = async () => {
    try {
      const result = await getRequest(
        "FacilityCategories/GetFacilityCategories"
      );
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOptionEmployee(listData);
    } catch (error) {
    } finally {
    }
  };
  const getEmployee = async () => {
    try {
      const result = await getRequest("LeaveTypes/GetEmployees");
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOptionEmployees(listData);
    } catch (error) {
    } finally {
    }
  };
  const getFacilityType = async () => {
    try {
      const result = await getRequest("FacilityTypes/GetFacilityType");
      let listData = [];
      result.data.map((item) => {
     return   listData.push({ label: item.name, value: item.id });
      });
      setOptionFacilityType(listData);
    } catch (error) {
    } finally {
    }
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.toLowerCase().includes("facilities"))
      getAllListData();
  }, [location.pathname, reqData]);

  const paginationChanged = (e) => {
    setCurrentPage(1);
    setRecordsSelected(e[0]);
    if (e[0].value > recordsSelected.value) {
      if (count > recordsSelected.value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    } else {
      if (count > e[0].value) {
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            iDisplayLength: e[0].value,
            iDisplayStart: 0,
          },
        });
      }
    }
  };
  const pageChangedNext = () => {
    setCurrentPage((prev) => prev + 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: currentPage * recordsSelected.value,
      },
    });
  };
  const pageChangedPrev = () => {
    setCurrentPage((prev) => prev - 1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: (currentPage - 2) * recordsSelected.value,
      },
    });
  };
  const deferredText = useDeferredValue(searchParam);
  useMemo(() => {
    setCurrentPage(1);
    setReqData({
      ...reqData,
      dataTableRequest: {
        ...reqData.dataTableRequest,
        iDisplayStart: 0,
        sSearch: deferredText,
      },
    });
  }, [deferredText]);
  const getFormDetails = (id) => {
    navigate(`/hrms/benefits/facilities/details/${id}`);
  };
  const cancelRequest = (id) => {
    clickHandlerFunction(id, "Cancel", "red");
  };

  const sortUpdated = (heading) => {
    if (heading === sortParam) {
      if (sortType === "ascending") {
        setSortType("descending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "descending",
          },
        });
      } else {
        setSortType("ascending");
        setReqData({
          ...reqData,
          dataTableRequest: {
            ...reqData.dataTableRequest,
            sSortDir_0: "ascending",
          },
        });
      }
    } else {
      setSortType("ascending");
      setSortParam(heading);
      setReqData({
        ...reqData,
        dataTableRequest: {
          ...reqData.dataTableRequest,
          sorting: heading,
          sSortDir_0: "ascending",
        },
      });
    }
  };
  const applyFilters = () => {
    setReqData({
      ...reqData,
      status: selectedStatusList[0]?.value,
      FacilityTypeID: selectedfacilityTypeList[0]?.value,
      facilityCategoryId: selectedTransferTypeList[0]?.value,
      employeeId: selectedEmployeeList[0]?.value,
      createdOn: createdOnStartDate
        ? `${format(createdOnStartDate, "dd/MM/yyyy")} ${
            createdOnEndDate
              ? format(createdOnEndDate, "dd/MM/yyyy")
              : format(createdOnStartDate, "dd/MM/yyyy")
          }`
        : null,
      lastUpdatedOn: lastUpdatedOnStartDate
        ? `${format(lastUpdatedOnStartDate, "dd/MM/yyyy")} ${
            lastUpdatedOnEndDate
              ? format(lastUpdatedOnEndDate, "dd/MM/yyyy")
              : format(lastUpdatedOnStartDate, "dd/MM/yyyy")
          }`
        : null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const resetFilters = () => {
    setCreatedOnStartDate(false);
    setCreatedOnEndDate(false);
    setLastUpdatedOnStartDate(false);
    setSelectedStatusList([]);
    setDateSelect(false);
    setSelectedTransferTypeList([]);
    setSelectedfacilityTypeList([]);
    setSelectedEmployeeList([]);
    setLastUpdatedOnEndDate(false);
    setReqData({
      ...reqData,
      createdOn: null,
      lastUpdatedOn: null,
      status:"Rejected",
      date: null,
      facilityCategoryId: null,
      FacilityTypeID: null,
      employeeId: null,
    });
    setFilterSearchToogle((prev) => !prev);
  };
  const addToSelectedRows = (id) => {
    let arr = [...selectedRows];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedRows(arr);
      return;
    }
    arr.push(id);
    setSelectedRows(arr);
  };
  const selectAll = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
    } else {
      let arr = [];
      tbodyData?.map((item) => {
        if (
          originalData.find((x) => (x.id ?? x.Id) === (item.id ?? item.Id))
            .actions?.length > 1
        )
          arr.push(item.Id ?? item.id);
          return null
      });
      setSelectedRows(arr);
    }
  };

  const handleApprove = async (selectedRows, typeAll) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
          return   tempIds.push(
          originalData.find((x) => x.Id ?? x.id === item)?.nextRequestId
        );
      });
      await deleteWithPayloadRequest(`FacilityRequests/${typeAll}`, tempIds);
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      if (isApprovePopup) togglePopupapprove();
      getAllListData();
    }
  };
  const rejectAll = async (typeAll, selectedRows, remarks) => {
    try {
      dispatch(isLoading(true));
      setLoading(true);
      let tempIds = [];
      selectedRows.map((item) => {
          return   tempIds.push(
          originalData.find((x) => x.Id ?? x.id === item)?.nextRequestId
        );
      });
      await deleteWithPayloadRequest(
        `FacilityRequests/${typeAll}?Remarks=${remarks}`,
        tempIds
      );
      dispatch(showToast({ text: "success", severity: "success" }));
    } catch (error) {
      dispatch(
        showToast({
          text: error?.response?.data ?? "some error occured",
          severity: "error",
        })
      );
    } finally {
      dispatch(isLoading(false));
      setLoading(false);
      setSelectedRows([]);
      if (isApprovePopup) togglePopupapprove();
      getAllListData();
    }
  };

  return (
    <div className="fullTableContainer">
      <OutsideClickHandler
        onOutsideClick={() => {
          setFilterSearchToogle((prev) => {
            if (prev) return false;
            else return prev;
          });
        }}
      >
        <div
          className={`${
            filterSearchToogle
              ? "filterSearchContainerActive filterSearchContainer"
              : "filterSearchContainer"
          }`}
        >
          <div className="headingContainer">
            <h3>Filter</h3>
            <IconButton
              onClick={() => setFilterSearchToogle((prev) => !prev)}
              title="close"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7579 1.56897L0.757935 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.757935 1.56897L18.7579 19.569"
                  stroke="#F71D1D"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </IconButton>
          </div>
          <h5>Fields</h5>

          <div className="filterListContainer">
            <div className="filterContainer">
            <div className="filterContainer">
                    <p> Employee </p>
                    <SelectCustom
                      onDropdownOpen={() => {
                        getEmployee();
                      }}
                      options={optionEmployees}
                      values={selectedEmployeeList}
                      onChange={(value) => {
                        setSelectedEmployeeList(value);
                      }}
                    />
                  </div>
              <p> Facility Category </p>
              <SelectCustom
                placeholder="Select Date"
                onDropdownOpen={() => {
                  getFacility();
                }}
                options={optionEmployee}
                values={selectedTransferTypeList}
                onChange={(value) => {
                  setSelectedTransferTypeList(value);
                }}
              />
            </div>

            <div className="filterContainer">
              <p>Created On</p>
              <DateRangePicker
                selectedEnd={createdOnEndDate}
                selectedStart={createdOnStartDate}
                onChangeStart={(date) => setCreatedOnStartDate(date)}
                onChangeEnd={(date) => setCreatedOnEndDate(date)}
              />
            </div>
            <div className="filterContainer">
              <p>Last Updated On</p>
              <DateRangePicker
                selectedEnd={lastUpdatedOnEndDate}
                selectedStart={lastUpdatedOnStartDate}
                onChangeStart={(date) => setLastUpdatedOnStartDate(date)}
                onChangeEnd={(date) => setLastUpdatedOnEndDate(date)}
              />
            </div>
          </div>

          <div className="filterSearchFooterContainer">
            <button className="button secondaryButton" onClick={resetFilters}>
              Reset
            </button>
            <button className="button primaryButton" onClick={applyFilters}>
              Apply
            </button>
          </div>
        </div>
      </OutsideClickHandler>
      <div className="tableHeaderContainer">
        {selectedRows.length > 0 && (
          <>
            <button
              className="button primaryButton"
              onClick={() => togglePopupapprove("ApproveAll")}
            >
              <span className="deletebtnflex">
                <p>Approve</p>
                <span>{`(${selectedRows.length})`}</span>
              </span>
            </button>
            <button
              className="deletebtn"
              onClick={() => togglePopupapprove("RejectAll")}
            >
              <span className="deletebtnflex">
                <p>Reject</p>
                <span>{`(${selectedRows.length})`}</span>
              </span>
            </button>
          </>
        )}
        <div className="search-box" title="Search">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            onChange={(e) => setSearchParam(e.target.value)}
          />
          <a className="search-btn" title="Search">
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.7514 11.1946L16.0236 15.4668C16.1737 15.6171 16.258 15.8209 16.258 16.0333C16.2579 16.2457 16.1734 16.4494 16.0232 16.5996C15.8729 16.7498 15.6691 16.8341 15.4567 16.834C15.2443 16.8339 15.0406 16.7495 14.8904 16.5992L10.6183 12.3269C9.34114 13.3161 7.73517 13.7816 6.12703 13.6288C4.5189 13.4759 3.02941 12.7161 1.96157 11.504C0.893729 10.2918 0.327755 8.71841 0.378784 7.10379C0.429812 5.48917 1.09401 3.95463 2.23626 2.81236C3.37851 1.67008 4.91301 1.00586 6.52758 0.954834C8.14216 0.903804 9.71554 1.46979 10.9276 2.53766C12.1397 3.60553 12.8995 5.09505 13.0524 6.70323C13.2053 8.3114 12.7406 9.91742 11.7514 11.1946ZM6.72885 12.0689C7.99247 12.0689 9.20433 11.5669 10.0978 10.6733C10.9914 9.77981 11.4933 8.56791 11.4933 7.30426C11.4933 6.04061 10.9914 4.82871 10.0978 3.93518C9.20433 3.04164 7.99247 2.53966 6.72885 2.53966C5.46523 2.53966 4.25336 3.04164 3.35985 3.93518C2.46633 4.82871 1.96436 6.04061 1.96436 7.30426C1.96436 8.56791 2.46633 9.77981 3.35985 10.6733C4.25336 11.5669 5.46523 12.0689 6.72885 12.0689Z"
                fill="#1296B0"
              />
            </svg>
          </a>
        </div>
        <IconButton
          title={`${filterSearchToogle ? "Cancel" : "Filter"}`}
          color="primary"
          onClick={() => {
            setFilterSearchToogle((prev) => !prev);
          }}
        >
          {!filterSearchToogle ? (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2782 9.65917L18.9655 2.41479L19.9179 1.20166H18.3756H3.37561H1.83329L2.78569 2.41479L8.47307 9.65917V17.9517V19.6584L9.7303 18.5041L13.0354 15.4696L13.2782 15.2467V14.9172V9.65917Z"
                stroke="#1296B0"
                strokeWidth="1.5"
              />
            </svg>
          ) : (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.7579 1.56897L0.757935 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.757935 1.56897L18.7579 19.569"
                stroke="#F71D1D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </IconButton>
        <IconButton
          title="Export"
          color="primary"
          onClick={exportTable}
        >
        <svg
              width="20"
              height="20"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4552 11.6183V0.95166M10.4552 11.6183L6.89962 8.06277M10.4552 11.6183L14.0107 8.06277M1.56628 13.3961L2.11828 15.605C2.21442 15.9896 2.43635 16.331 2.7488 16.575C3.06125 16.819 3.44629 16.9516 3.84273 16.9517H17.0676C17.4641 16.9516 17.8491 16.819 18.1615 16.575C18.474 16.331 18.6959 15.9896 18.7921 15.605L19.3441 13.3961"
                stroke="#1296B0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
        </IconButton>
      </div>
      <>
        {loading ? (
          <div className="noDataFound">
            <Loader />
          </div>
        ) : (
          <>
            <TransactionTable
              selectAll={selectAll}
              tableFilterName="facilitiesAllListFilter"
              tbodyData={tbodyData}
              headerAction={sortUpdated}
              sortParam={sortParam}
              sortType={sortType}
              selectedRows={selectedRows}
              addToSelectedRows={addToSelectedRows}
              originalData={originalData}
              containStatus={true}
              containTat={true}
            />
          </>
        )}
        <div className="horizontalLine"></div>
        <div className="tableFooterContainer">
          <label>Total Record Count: </label>
          <span>{count}</span>
          <SelectCustom
            searchable={false}
            options={noOfRecords} dropdownPosition="top"
             
            values={[recordsSelected]}
            onChange={(e) => paginationChanged(e)}
          />
          <label>Records Per Page</label>
          {currentPage > 1 ? (
            <IconButton
              onClick={() => pageChangedPrev()}
              color="primary"
              className="pageChangerIcon pageChangerIconActive"
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton disabled color="primary" className="pageChangerIcon">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
          {currentPage} - {totalPages}
          {totalPages > currentPage ? (
            <IconButton
              onClick={() => pageChangedNext()}
              color="primary"
              className={`pageChangerIcon pageChangerIconNext pageChangerIconActive`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          ) : (
            <IconButton
              disabled
              color="primary"
              className={`pageChangerIcon pageChangerIconNext`}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.61462 11.0632L1.61462 6.06323L6.61462 1.06323"
                  stroke="#BFBFBF"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
          )}
        </div>
      </>

      {isOpen &&
        (loading ? (
          ""
        ) : (
          <Popup
            popupHeading={typeAction}
            content={
              <>
                {typeAction === "Allocate" ? (
                  <Allocation
                    data={getAllocateList}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                ) : typeAction === "Admin Facilities" ? (
                  <AdminCreate
                    data={dispatchAdminListData}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                ) : typeAction === "Readiness" ? (
                  <AdminUpdate
                    data={dispatchAdminListData}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                ) : typeAction === "Initiate Surrender" ? (
                  <Surrender
                    data={getEmployeeAdminFacility}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={togglePopup}
                    eid={extra}
                  />
                ) : (
                  <Remark
                    data={PostApRejHol}
                    type={typeAction}
                    id={selId}
                    color={colorAction}
                    handleClose={handleClose}
                  />
                )}
              </>
            }
            handleClose={handleClose}
          />
        ))}
      {isApprovePopup && (
        <ApprovePopup
          popupHeading={typeAll}
          selectedRows={selectedRows}
          type={typeAll}
          content={
            <div style={{ display: "flex" }}>
              {typeAll === "ApproveAll" ? (
                <>
                  <button
                    className="button"
                    onClick={() => {
                      handleApprove(selectedRows, typeAll);
                    }}
                  >
                    Confirm
                  </button>
                  <button className="button">Cancel</button>
                </>
              ) : (
                <div style={{ width: "100%" }}>
                  <Remark
                    data={rejectAll}
                    type={typeAll}
                    id={selectedRows}
                    color={"red"}
                    handleClose={togglePopupapprove}
                  />
                </div>
              )}
            </div>
          }
          handleClose={togglePopupapprove}
        />
      )}
    </div>
  );
};

export { RejectedFacilities };
